const boxingBadgerMetadata = {
    "3": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/3_1681445717.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "3",
                "name": "Condemned Badger Bowtruckle #3",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445707,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_3_1681445717.png",
                "rarity_score": 91.55064959084393,
                "rarity_level": "RARE"
            }
        }
    },
    "9": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/9_1681445718.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "9",
                "name": "Odd Badger Nicolas #9",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_9_1681445718.png",
                "rarity_score": 55.882028139182935,
                "rarity_level": "COMMON"
            }
        }
    },
    "16": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/16_1681445718.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "16",
                "name": "Nasty Badger Kai #16",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_16_1681445718.png",
                "rarity_score": 54.93147836696364,
                "rarity_level": "COMMON"
            }
        }
    },
    "12": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/12_1681445718.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "12",
                "name": "Real Badger Cannon #12",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_12_1681445718.png",
                "rarity_score": 59.82507844662849,
                "rarity_level": "COMMON"
            }
        }
    },
    "1": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1_1681445718.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1",
                "name": "Whale Badger Thestral #1",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445706,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1_1681445718.png",
                "rarity_score": 268.86580816366484,
                "rarity_level": "EPIC"
            }
        }
    },
    "29": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/29_1681445718.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "29",
                "name": "Friendly Badger Dorian #29",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_29_1681445718.png",
                "rarity_score": 58.27027939268027,
                "rarity_level": "COMMON"
            }
        }
    },
    "28": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/28_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "28",
                "name": "Creepy Badger Dursley #28",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_28_1681445719.png",
                "rarity_score": 75.84082430128967,
                "rarity_level": "COMMON"
            }
        }
    },
    "19": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/19_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "19",
                "name": "Awful Badger Fang #19",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_19_1681445719.png",
                "rarity_score": 56.145848931593946,
                "rarity_level": "COMMON"
            }
        }
    },
    "32": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/32_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "32",
                "name": "Asthmatic Badger Canelo #32",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_32_1681445719.png",
                "rarity_score": 72.13601337454188,
                "rarity_level": "COMMON"
            }
        }
    },
    "5": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/5_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "5",
                "name": "Real Badger Hippogriff #5",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445708,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_5_1681445719.png",
                "rarity_score": 102.34890139771389,
                "rarity_level": "RARE"
            }
        }
    },
    "14": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/14_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "14",
                "name": "Ugly Badger Albus #14",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_14_1681445720.png",
                "rarity_score": 62.19809566782763,
                "rarity_level": "COMMON"
            }
        }
    },
    "4": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/4_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "4",
                "name": "Carebear Badger Vasyl #4",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445708,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_4_1681445719.png",
                "rarity_score": 108.43931807498035,
                "rarity_level": "RARE"
            }
        }
    },
    "11": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/11_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "11",
                "name": "Unsightly Badger Basilisk #11",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445708,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_11_1681445719.png",
                "rarity_score": 65.77166874376445,
                "rarity_level": "COMMON"
            }
        }
    },
    "25": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/25_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "25",
                "name": "Healthy Badger Salazar #25",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_25_1681445719.png",
                "rarity_score": 160.16962681992004,
                "rarity_level": "RARE"
            }
        }
    },
    "30": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/30_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "30",
                "name": "Gorgeous Badger Toby #30",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_30_1681445720.png",
                "rarity_score": 73.07175572630071,
                "rarity_level": "COMMON"
            }
        }
    },
    "24": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/24_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "24",
                "name": "Rich Badger Godric #24",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_24_1681445719.png",
                "rarity_score": 221.4493772999736,
                "rarity_level": "RARE"
            }
        }
    },
    "10": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/10_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "10",
                "name": "Fierce Badger Cayden #10",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_10_1681445719.png",
                "rarity_score": 75.12733424368479,
                "rarity_level": "COMMON"
            }
        }
    },
    "15": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/15_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "15",
                "name": "Ill Badger Cecil #15",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_15_1681445720.png",
                "rarity_score": 70.52236198477712,
                "rarity_level": "COMMON"
            }
        }
    },
    "38": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/38_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "38",
                "name": "Good Badger Gulliver #38",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_38_1681445720.png",
                "rarity_score": 324.009550524277,
                "rarity_level": "EPIC"
            }
        }
    },
    "35": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/35_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "35",
                "name": "Upset Badger Errol #35",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_35_1681445720.png",
                "rarity_score": 98.65036923860453,
                "rarity_level": "RARE"
            }
        }
    },
    "6": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/6_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "6",
                "name": "Cute Badger Godric #6",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445708,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_6_1681445720.png",
                "rarity_score": 75.74737065445218,
                "rarity_level": "COMMON"
            }
        }
    },
    "2": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2_1681445721.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2",
                "name": "Rich Badger Filch #2",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445706,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2_1681445721.png",
                "rarity_score": 149.85792240292375,
                "rarity_level": "RARE"
            }
        }
    },
    "31": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/31_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "31",
                "name": "Testy Badger Griphook #31",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_31_1681445720.png",
                "rarity_score": 106.16580963232569,
                "rarity_level": "RARE"
            }
        }
    },
    "17": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/17_1681445719.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "17",
                "name": "Talented Badger Longbottom #17",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_17_1681445719.png",
                "rarity_score": 72.98924283455287,
                "rarity_level": "COMMON"
            }
        }
    },
    "36": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/36_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "36",
                "name": "Jealous Badger Flitwick #36",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_36_1681445720.png",
                "rarity_score": 66.518710233261,
                "rarity_level": "COMMON"
            }
        }
    },
    "7": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/7_1681445721.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "7",
                "name": "Dull Badger Cecil #7",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_7_1681445721.png",
                "rarity_score": 119.65345265116967,
                "rarity_level": "RARE"
            }
        }
    },
    "23": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/23_1681445721.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "23",
                "name": "Faithful Badger Pettigrew #23",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_23_1681445721.png",
                "rarity_score": 124.6052238232363,
                "rarity_level": "RARE"
            }
        }
    },
    "37": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/37_1681445722.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "37",
                "name": "Wen Badger Goyle #37",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_37_1681445722.png",
                "rarity_score": 104.74874535399735,
                "rarity_level": "RARE"
            }
        }
    },
    "20": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/20_1681445720.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "20",
                "name": "Charming Badger Fudge #20",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_20_1681445720.png",
                "rarity_score": 116.5018056209766,
                "rarity_level": "RARE"
            }
        }
    },
    "26": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/26_1681445721.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "26",
                "name": "Quaint Badger Quentin #26",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_26_1681445721.png",
                "rarity_score": 97.64730520251453,
                "rarity_level": "RARE"
            }
        }
    },
    "8": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/8_1681445722.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "8",
                "name": "Angry Badger Jarvis #8",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445708,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_8_1681445722.png",
                "rarity_score": 73.80939579086126,
                "rarity_level": "COMMON"
            }
        }
    },
    "33": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/33_1681445721.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "33",
                "name": "Strange Badger Cesar #33",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_33_1681445721.png",
                "rarity_score": 105.67380978111643,
                "rarity_level": "RARE"
            }
        }
    },
    "22": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/22_1681445721.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "22",
                "name": "Sleepy Badger Heathcliff #22",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_22_1681445721.png",
                "rarity_score": 128.16764720144926,
                "rarity_level": "RARE"
            }
        }
    },
    "34": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/34_1681445722.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "34",
                "name": "Gentle Badger Sawyer #34",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_34_1681445722.png",
                "rarity_score": 272.1820475597849,
                "rarity_level": "EPIC"
            }
        }
    },
    "72": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/72_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "72",
                "name": "Nasty Badger Brawley #72",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_72_1681445732.png",
                "rarity_score": 92.11286089327379,
                "rarity_level": "RARE"
            }
        }
    },
    "71": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/71_1681445735.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "71",
                "name": "Misty Badger Lucius #71",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_71_1681445735.png",
                "rarity_score": 80.31966141618206,
                "rarity_level": "COMMON"
            }
        }
    },
    "41": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/41_1681445725.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "41",
                "name": "Nutty Badger Gringot #41",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445720,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_41_1681445725.png",
                "rarity_score": 56.96409580469911,
                "rarity_level": "COMMON"
            }
        }
    },
    "21": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/21_1681445723.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "21",
                "name": "Helpless Badger Thestral #21",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_21_1681445723.png",
                "rarity_score": 114.99244909798963,
                "rarity_level": "RARE"
            }
        }
    },
    "73": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/73_1681445733.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "73",
                "name": "Misty Badger Crookshanks #73",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_73_1681445733.png",
                "rarity_score": 86.35056932914205,
                "rarity_level": "COMMON"
            }
        }
    },
    "75": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/75_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "75",
                "name": "Lambo Badger Toby #75",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_75_1681445734.png",
                "rarity_score": 105.74280719999675,
                "rarity_level": "RARE"
            }
        }
    },
    "39": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/39_1681445724.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "39",
                "name": "Ashamed Badger Flint #39",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445718,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_39_1681445724.png",
                "rarity_score": 64.27174622813348,
                "rarity_level": "COMMON"
            }
        }
    },
    "13": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/13_1681445723.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "13",
                "name": "Clean Badger Beckham #13",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_13_1681445723.png",
                "rarity_score": 108.82144208730007,
                "rarity_level": "RARE"
            }
        }
    },
    "18": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/18_1681445724.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "18",
                "name": "Carebear Badger Riddle #18",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_18_1681445724.png",
                "rarity_score": 134.19979802321458,
                "rarity_level": "RARE"
            }
        }
    },
    "27": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/27_1681445723.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "27",
                "name": "Determined Badger Crabbe #27",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445709,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_27_1681445723.png",
                "rarity_score": 121.77469064806823,
                "rarity_level": "RARE"
            }
        }
    },
    "40": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/40_1681445726.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "40",
                "name": "Nutty Badger Castiel #40",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445719,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_40_1681445726.png",
                "rarity_score": 86.22151386198426,
                "rarity_level": "COMMON"
            }
        }
    },
    "80": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/80_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "80",
                "name": "Frail Badger Flitwick #80",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_80_1681445732.png",
                "rarity_score": 79.93240204052657,
                "rarity_level": "COMMON"
            }
        }
    },
    "112": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/112_1681445742.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "112",
                "name": "Magnificent Badger Jake #112",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445736,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_112_1681445742.png",
                "rarity_score": 99.81058855581819,
                "rarity_level": "RARE"
            }
        }
    },
    "147": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/147_1681445751.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "147",
                "name": "Upset Badger Latham #147",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_147_1681445751.png",
                "rarity_score": 58.988296551669926,
                "rarity_level": "COMMON"
            }
        }
    },
    "44": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/44_1681445726.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "44",
                "name": "Grumpy Badger Draco #44",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445721,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_44_1681445726.png",
                "rarity_score": 58.16634426763618,
                "rarity_level": "COMMON"
            }
        }
    },
    "79": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/79_1681445733.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "79",
                "name": "Glamorous Badger Sirius #79",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_79_1681445733.png",
                "rarity_score": 75.46307042969904,
                "rarity_level": "COMMON"
            }
        }
    },
    "113": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/113_1681445742.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "113",
                "name": "Dangerous Badger Elfie #113",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445736,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_113_1681445742.png",
                "rarity_score": 96.56227840743969,
                "rarity_level": "RARE"
            }
        }
    },
    "146": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/146_1681445752.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "146",
                "name": "Gifted Badger Mike #146",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_146_1681445752.png",
                "rarity_score": 87.57991170639303,
                "rarity_level": "COMMON"
            }
        }
    },
    "46": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/46_1681445726.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "46",
                "name": "Prickly Badger Croix #46",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445721,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_46_1681445726.png",
                "rarity_score": 53.90984562584329,
                "rarity_level": "COMMON"
            }
        }
    },
    "81": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/81_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "81",
                "name": "Shill Badger Cayden #81",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445728,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_81_1681445734.png",
                "rarity_score": 120.18338713904865,
                "rarity_level": "RARE"
            }
        }
    },
    "118": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/118_1681445743.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "118",
                "name": "Gifted Badger Leonard #118",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_118_1681445743.png",
                "rarity_score": 99.44180267616358,
                "rarity_level": "RARE"
            }
        }
    },
    "148": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/148_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "148",
                "name": "Real Badger Minerva #148",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_148_1681445753.png",
                "rarity_score": 112.29079308198006,
                "rarity_level": "RARE"
            }
        }
    },
    "42": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/42_1681445726.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "42",
                "name": "Determined Badger Aragog #42",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445720,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_42_1681445726.png",
                "rarity_score": 260.43886984965525,
                "rarity_level": "EPIC"
            }
        }
    },
    "82": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/82_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "82",
                "name": "Modern Badger Tibbles #82",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445728,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_82_1681445734.png",
                "rarity_score": 109.52306876964158,
                "rarity_level": "RARE"
            }
        }
    },
    "122": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/122_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "122",
                "name": "Courageous Badger Ogre #122",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_122_1681445745.png",
                "rarity_score": 107.17792890112645,
                "rarity_level": "RARE"
            }
        }
    },
    "160": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/160_1681445754.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "160",
                "name": "Dead Badger Asher #160",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_160_1681445754.png",
                "rarity_score": 97.23323944399684,
                "rarity_level": "RARE"
            }
        }
    },
    "43": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/43_1681445727.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "43",
                "name": "Encouraging Badger Felix #43",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445721,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_43_1681445727.png",
                "rarity_score": 64.58726280208921,
                "rarity_level": "COMMON"
            }
        }
    },
    "83": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/83_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "83",
                "name": "Naughty Badger Alastor #83",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445728,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_83_1681445734.png",
                "rarity_score": 75.58960637486271,
                "rarity_level": "COMMON"
            }
        }
    },
    "114": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/114_1681445743.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "114",
                "name": "Silly Badger Draco #114",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_114_1681445743.png",
                "rarity_score": 64.55163273294073,
                "rarity_level": "COMMON"
            }
        }
    },
    "154": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/154_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "154",
                "name": "Enchanting Badger Evander #154",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_154_1681445753.png",
                "rarity_score": 105.91219000040704,
                "rarity_level": "RARE"
            }
        }
    },
    "50": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/50_1681445728.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "50",
                "name": "Helpless Badger Leopold #50",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445722,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_50_1681445728.png",
                "rarity_score": 73.46554890759333,
                "rarity_level": "COMMON"
            }
        }
    },
    "84": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/84_1681445736.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "84",
                "name": "Doubtful Badger Bogrod #84",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445729,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_84_1681445736.png",
                "rarity_score": 101.52382296156324,
                "rarity_level": "RARE"
            }
        }
    },
    "128": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/128_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "128",
                "name": "Fantastic Badger Bridger #128",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445739,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_128_1681445745.png",
                "rarity_score": 73.39670474487777,
                "rarity_level": "COMMON"
            }
        }
    },
    "164": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/164_1681445756.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "164",
                "name": "Famous Badger Winky #164",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_164_1681445756.png",
                "rarity_score": 100.66846621615804,
                "rarity_level": "RARE"
            }
        }
    },
    "47": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/47_1681445728.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "47",
                "name": "Jolly Badger Antonio #47",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445722,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_47_1681445728.png",
                "rarity_score": 104.74758574543165,
                "rarity_level": "RARE"
            }
        }
    },
    "85": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/85_1681445736.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "85",
                "name": "Dark Badger Tyrion #85",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445729,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_85_1681445736.png",
                "rarity_score": 64.88234377353716,
                "rarity_level": "COMMON"
            }
        }
    },
    "126": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/126_1681445744.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "126",
                "name": "Terrible Badger Wilder #126",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445739,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_126_1681445744.png",
                "rarity_score": 76.90055948811474,
                "rarity_level": "COMMON"
            }
        }
    },
    "158": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/158_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "158",
                "name": "Lonely Badger Leonard #158",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_158_1681445753.png",
                "rarity_score": 75.08400465096743,
                "rarity_level": "COMMON"
            }
        }
    },
    "54": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/54_1681445728.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "54",
                "name": "Faithful Badger Hermione #54",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_54_1681445728.png",
                "rarity_score": 55.22327402005747,
                "rarity_level": "COMMON"
            }
        }
    },
    "86": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/86_1681445737.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "86",
                "name": "Attractive Badger Fox #86",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445729,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_86_1681445737.png",
                "rarity_score": 157.32189099335488,
                "rarity_level": "RARE"
            }
        }
    },
    "129": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/129_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "129",
                "name": "Quaint Badger Evander #129",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_129_1681445745.png",
                "rarity_score": 57.72702596335049,
                "rarity_level": "COMMON"
            }
        }
    },
    "157": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/157_1681445751.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "157",
                "name": "Brainy Badger Jem #157",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_157_1681445751.png",
                "rarity_score": 73.63378818387658,
                "rarity_level": "COMMON"
            }
        }
    },
    "51": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/51_1681445729.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "51",
                "name": "Precious Badger Chavez #51",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445722,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_51_1681445729.png",
                "rarity_score": 64.66949379870684,
                "rarity_level": "COMMON"
            }
        }
    },
    "87": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/87_1681445737.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "87",
                "name": "Moon Badger Amos #87",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445730,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_87_1681445737.png",
                "rarity_score": 109.23653171383073,
                "rarity_level": "RARE"
            }
        }
    },
    "125": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/125_1681445749.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "125",
                "name": "Cruel Badger Macaulay #125",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445738,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_125_1681445749.png",
                "rarity_score": 87.08641690905365,
                "rarity_level": "COMMON"
            }
        }
    },
    "179": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/179_1681445757.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "179",
                "name": "Rich Badger Niffler #179",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445750,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_179_1681445757.png",
                "rarity_score": 77.09719672499948,
                "rarity_level": "COMMON"
            }
        }
    },
    "56": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/56_1681445729.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "56",
                "name": "Fragile Badger Jones #56",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_56_1681445729.png",
                "rarity_score": 55.89868561415344,
                "rarity_level": "COMMON"
            }
        }
    },
    "88": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/88_1681445735.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "88",
                "name": "Busy Badger Fireball #88",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445730,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_88_1681445735.png",
                "rarity_score": 69.8872457711416,
                "rarity_level": "COMMON"
            }
        }
    },
    "120": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/120_1681445743.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "120",
                "name": "Obnoxious Badger Cesar #120",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_120_1681445743.png",
                "rarity_score": 96.76695258117226,
                "rarity_level": "RARE"
            }
        }
    },
    "149": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/149_1681445751.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "149",
                "name": "Yucky Badger Jake #149",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_149_1681445751.png",
                "rarity_score": 63.242407402524805,
                "rarity_level": "COMMON"
            }
        }
    },
    "63": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/63_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "63",
                "name": "Clean Badger Yaxley #63",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445725,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_63_1681445730.png",
                "rarity_score": 62.476557836249256,
                "rarity_level": "COMMON"
            }
        }
    },
    "89": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/89_1681445737.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "89",
                "name": "Clever Badger Archie #89",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445731,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_89_1681445737.png",
                "rarity_score": 71.90564420830913,
                "rarity_level": "COMMON"
            }
        }
    },
    "127": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/127_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "127",
                "name": "Witty Badger Aberforth #127",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445739,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_127_1681445745.png",
                "rarity_score": 78.35865389530532,
                "rarity_level": "COMMON"
            }
        }
    },
    "163": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/163_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "163",
                "name": "Disgusted Badger Thestral #163",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_163_1681445753.png",
                "rarity_score": 89.18666591605292,
                "rarity_level": "RARE"
            }
        }
    },
    "52": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/52_1681445729.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "52",
                "name": "Rekt Badger Bronn #52",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445723,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_52_1681445729.png",
                "rarity_score": 102.6603616898833,
                "rarity_level": "RARE"
            }
        }
    },
    "90": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/90_1681445738.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "90",
                "name": "Dull Badger Joe #90",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445731,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_90_1681445738.png",
                "rarity_score": 105.48770299771202,
                "rarity_level": "RARE"
            }
        }
    },
    "134": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/134_1681445749.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "134",
                "name": "Degen Badger Grimmauld #134",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_134_1681445749.png",
                "rarity_score": 121.79332831659633,
                "rarity_level": "RARE"
            }
        }
    },
    "178": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/178_1681445756.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "178",
                "name": "Condemned Badger Ronan #178",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445750,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_178_1681445756.png",
                "rarity_score": 74.84210426366737,
                "rarity_level": "COMMON"
            }
        }
    },
    "53": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/53_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "53",
                "name": "Unsightly Badger Chester #53",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445723,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_53_1681445730.png",
                "rarity_score": 87.3646416200373,
                "rarity_level": "COMMON"
            }
        }
    },
    "91": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/91_1681445736.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "91",
                "name": "Dumb Badger Ranger #91",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445731,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_91_1681445736.png",
                "rarity_score": 62.26717085970057,
                "rarity_level": "COMMON"
            }
        }
    },
    "123": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/123_1681445742.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "123",
                "name": "Frail Badger Kingston #123",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_123_1681445742.png",
                "rarity_score": 81.01912568306011,
                "rarity_level": "COMMON"
            }
        }
    },
    "150": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/150_1681445750.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "150",
                "name": "Kind Badger Maxton #150",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_150_1681445750.png",
                "rarity_score": 55.63689586027716,
                "rarity_level": "COMMON"
            }
        }
    },
    "55": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/55_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "55",
                "name": "Cute Badger Binns #55",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_55_1681445730.png",
                "rarity_score": 98.98612234217572,
                "rarity_level": "RARE"
            }
        }
    },
    "92": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/92_1681445736.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "92",
                "name": "Determined Badger Blaise #92",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445731,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_92_1681445736.png",
                "rarity_score": 66.47252004249975,
                "rarity_level": "COMMON"
            }
        }
    },
    "124": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/124_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "124",
                "name": "Sore Badger Hogwart #124",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445738,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_124_1681445745.png",
                "rarity_score": 76.93692140916416,
                "rarity_level": "COMMON"
            }
        }
    },
    "161": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/161_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "161",
                "name": "Real Badger Mandrake #161",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_161_1681445753.png",
                "rarity_score": 105.88027850283123,
                "rarity_level": "RARE"
            }
        }
    },
    "48": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/48_1681445729.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "48",
                "name": "Aggressive Badger Felix #48",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445722,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_48_1681445729.png",
                "rarity_score": 275.5301946752118,
                "rarity_level": "EPIC"
            }
        }
    },
    "93": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/93_1681445737.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "93",
                "name": "Depressed Badger Newt #93",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445731,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_93_1681445737.png",
                "rarity_score": 64.3821335196269,
                "rarity_level": "COMMON"
            }
        }
    },
    "131": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/131_1681445747.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "131",
                "name": "Hungry Badger Shields #131",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_131_1681445747.png",
                "rarity_score": 76.68648742403659,
                "rarity_level": "COMMON"
            }
        }
    },
    "171": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/171_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "171",
                "name": "Fancy Badger Santiago #171",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445748,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_171_1681445753.png",
                "rarity_score": 66.71183783423871,
                "rarity_level": "COMMON"
            }
        }
    },
    "60": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/60_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "60",
                "name": "Tense Badger Cal #60",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_60_1681445730.png",
                "rarity_score": 66.77963849016481,
                "rarity_level": "COMMON"
            }
        }
    },
    "94": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/94_1681445738.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "94",
                "name": "Adventurous Badger Kenrick #94",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445732,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_94_1681445738.png",
                "rarity_score": 88.16753340931703,
                "rarity_level": "COMMON"
            }
        }
    },
    "132": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/132_1681445748.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "132",
                "name": "Horrible Badger Braham #132",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_132_1681445748.png",
                "rarity_score": 85.84434746073097,
                "rarity_level": "COMMON"
            }
        }
    },
    "177": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/177_1681445758.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "177",
                "name": "Exuberant Badger Gornuk #177",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445749,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_177_1681445758.png",
                "rarity_score": 71.52619143781524,
                "rarity_level": "COMMON"
            }
        }
    },
    "61": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/61_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "61",
                "name": "Tired Badger Connelly #61",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445725,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_61_1681445730.png",
                "rarity_score": 217.965093396816,
                "rarity_level": "RARE"
            }
        }
    },
    "95": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/95_1681445738.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "95",
                "name": "Worried Badger Holyfield #95",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445732,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_95_1681445738.png",
                "rarity_score": 70.54895621873304,
                "rarity_level": "COMMON"
            }
        }
    },
    "133": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/133_1681445747.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "133",
                "name": "Hungry Badger Gulliver #133",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_133_1681445747.png",
                "rarity_score": 117.20489491612366,
                "rarity_level": "RARE"
            }
        }
    },
    "172": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/172_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "172",
                "name": "Bad Badger Fitzwilliam #172",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445748,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_172_1681445753.png",
                "rarity_score": 54.995907463118655,
                "rarity_level": "COMMON"
            }
        }
    },
    "57": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/57_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "57",
                "name": "Tense Badger Ranger #57",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_57_1681445730.png",
                "rarity_score": 59.41778974136479,
                "rarity_level": "COMMON"
            }
        }
    },
    "96": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/96_1681445739.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "96",
                "name": "Sleepy Badger Alastor #96",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445733,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_96_1681445739.png",
                "rarity_score": 62.72468996599404,
                "rarity_level": "COMMON"
            }
        }
    },
    "135": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/135_1681445746.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "135",
                "name": "Bloody Badger Connell #135",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_135_1681445746.png",
                "rarity_score": 62.95636218568585,
                "rarity_level": "COMMON"
            }
        }
    },
    "168": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/168_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "168",
                "name": "Ludicrous Badger Lawrence #168",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_168_1681445753.png",
                "rarity_score": 57.81377955730151,
                "rarity_level": "COMMON"
            }
        }
    },
    "67": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/67_1681445730.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "67",
                "name": "Panda Badger Manny #67",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_67_1681445730.png",
                "rarity_score": 59.22994652406416,
                "rarity_level": "COMMON"
            }
        }
    },
    "97": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/97_1681445740.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "97",
                "name": "Quaint Badger Fabian #97",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445733,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_97_1681445740.png",
                "rarity_score": 70.71974520785021,
                "rarity_level": "COMMON"
            }
        }
    },
    "141": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/141_1681445750.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "141",
                "name": "Enthusiastic Badger Fitzwilliam #141",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445741,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_141_1681445750.png",
                "rarity_score": 79.33492067026137,
                "rarity_level": "COMMON"
            }
        }
    },
    "181": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/181_1681445755.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "181",
                "name": "Helpful Badger Potter #181",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445750,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_181_1681445755.png",
                "rarity_score": 73.33749749768464,
                "rarity_level": "COMMON"
            }
        }
    },
    "58": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/58_1681445731.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "58",
                "name": "Grieving Badger Latham #58",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_58_1681445731.png",
                "rarity_score": 63.3521230405665,
                "rarity_level": "COMMON"
            }
        }
    },
    "98": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/98_1681445742.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "98",
                "name": "Gleaming Badger Julius #98",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445733,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_98_1681445742.png",
                "rarity_score": 122.90208676684922,
                "rarity_level": "RARE"
            }
        }
    },
    "155": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/155_1681445750.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "155",
                "name": "Energetic Badger Wladimir #155",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_155_1681445750.png",
                "rarity_score": 57.918722552505,
                "rarity_level": "COMMON"
            }
        }
    },
    "184": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/184_1681445758.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "184",
                "name": "Clever Badger Gauge #184",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445751,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_184_1681445758.png",
                "rarity_score": 89.70077592777648,
                "rarity_level": "RARE"
            }
        }
    },
    "45": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/45_1681445731.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "45",
                "name": "Bored Badger Fox #45",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445721,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_45_1681445731.png",
                "rarity_score": 82.40954869440934,
                "rarity_level": "COMMON"
            }
        }
    },
    "99": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/99_1681445743.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "99",
                "name": "Tense Badger Remus #99",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445733,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_99_1681445743.png",
                "rarity_score": 132.4008439168863,
                "rarity_level": "RARE"
            }
        }
    },
    "151": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/151_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "151",
                "name": "Hilarious Badger Santiago #151",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_151_1681445753.png",
                "rarity_score": 87.65466332443157,
                "rarity_level": "COMMON"
            }
        }
    },
    "199": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/199_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "199",
                "name": "Cute Badger Peter #199",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_199_1681445763.png",
                "rarity_score": 84.2067160149743,
                "rarity_level": "COMMON"
            }
        }
    },
    "62": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/62_1681445731.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "62",
                "name": "Glamorous Badger Benedick #62",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445725,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_62_1681445731.png",
                "rarity_score": 68.10122763713788,
                "rarity_level": "COMMON"
            }
        }
    },
    "100": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/100_1681445738.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "100",
                "name": "Amused Badger Cruz #100",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445733,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_100_1681445738.png",
                "rarity_score": 56.05657932743654,
                "rarity_level": "COMMON"
            }
        }
    },
    "130": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/130_1681445747.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "130",
                "name": "Naughty Badger Horace #130",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_130_1681445747.png",
                "rarity_score": 129.36925077684555,
                "rarity_level": "RARE"
            }
        }
    },
    "170": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/170_1681445757.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "170",
                "name": "Jolly Badger Deontay #170",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445748,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_170_1681445757.png",
                "rarity_score": 168.4832487806259,
                "rarity_level": "RARE"
            }
        }
    },
    "59": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/59_1681445731.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "59",
                "name": "Annoying Badger Leonardo #59",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445724,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_59_1681445731.png",
                "rarity_score": 95.98206154760055,
                "rarity_level": "RARE"
            }
        }
    },
    "103": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/103_1681445739.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "103",
                "name": "Ludicrous Badger Vasyl #103",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445734,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_103_1681445739.png",
                "rarity_score": 74.9740915978456,
                "rarity_level": "COMMON"
            }
        }
    },
    "136": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/136_1681445748.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "136",
                "name": "Hilarious Badger Rufus #136",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445740,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_136_1681445748.png",
                "rarity_score": 79.16401866482185,
                "rarity_level": "COMMON"
            }
        }
    },
    "173": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/173_1681445759.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "173",
                "name": "Naughty Badger Dirk #173",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445748,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_173_1681445759.png",
                "rarity_score": 149.61282681093525,
                "rarity_level": "RARE"
            }
        }
    },
    "66": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/66_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "66",
                "name": "Quaint Badger Errol #66",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_66_1681445732.png",
                "rarity_score": 58.90978921114991,
                "rarity_level": "COMMON"
            }
        }
    },
    "101": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/101_1681445740.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "101",
                "name": "Elated Badger Jack #101",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445734,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_101_1681445740.png",
                "rarity_score": 68.31505105288375,
                "rarity_level": "COMMON"
            }
        }
    },
    "138": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/138_1681445749.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "138",
                "name": "Nutty Badger Cullen #138",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445741,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_138_1681445749.png",
                "rarity_score": 81.51562318438286,
                "rarity_level": "COMMON"
            }
        }
    },
    "180": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/180_1681445760.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "180",
                "name": "Adventurous Badger Everett #180",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445750,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_180_1681445760.png",
                "rarity_score": 122.90735015845392,
                "rarity_level": "RARE"
            }
        }
    },
    "49": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/49_1681445731.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "49",
                "name": "Scary Badger Gilderoy #49",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445722,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_49_1681445731.png",
                "rarity_score": 113.33214994030305,
                "rarity_level": "RARE"
            }
        }
    },
    "102": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/102_1681445739.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "102",
                "name": "Asthmatic Badger Cullen #102",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445734,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_102_1681445739.png",
                "rarity_score": 53.97760367970253,
                "rarity_level": "COMMON"
            }
        }
    },
    "137": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/137_1681445748.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "137",
                "name": "Fine Badger Arther #137",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445741,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_137_1681445748.png",
                "rarity_score": 96.49377900123173,
                "rarity_level": "RARE"
            }
        }
    },
    "174": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/174_1681445758.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "174",
                "name": "Modern Badger Marcus #174",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445749,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_174_1681445758.png",
                "rarity_score": 143.3063411930259,
                "rarity_level": "RARE"
            }
        }
    },
    "68": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/68_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "68",
                "name": "Eager Badger Dempsey #68",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_68_1681445732.png",
                "rarity_score": 83.68528047773331,
                "rarity_level": "COMMON"
            }
        }
    },
    "104": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/104_1681445741.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "104",
                "name": "Famous Badger Sir Nicholaus #104",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445735,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_104_1681445741.png",
                "rarity_score": 98.83427036882996,
                "rarity_level": "RARE"
            }
        }
    },
    "142": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/142_1681445748.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "142",
                "name": "Beautiful Badger Bludger #142",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445742,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_142_1681445748.png",
                "rarity_score": 350.92952285211646,
                "rarity_level": "EPIC"
            }
        }
    },
    "176": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/176_1681445758.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "176",
                "name": "Energetic Badger Jarvis #176",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445749,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_176_1681445758.png",
                "rarity_score": 76.2613813538365,
                "rarity_level": "COMMON"
            }
        }
    },
    "70": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/70_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "70",
                "name": "Dead Badger Edward #70",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_70_1681445732.png",
                "rarity_score": 77.14915907263858,
                "rarity_level": "COMMON"
            }
        }
    },
    "105": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/105_1681445741.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "105",
                "name": "Jolly Badger Peter #105",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445735,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_105_1681445741.png",
                "rarity_score": 69.31910716297334,
                "rarity_level": "COMMON"
            }
        }
    },
    "143": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/143_1681445750.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "143",
                "name": "Disturbed Badger Creevy #143",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445742,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_143_1681445750.png",
                "rarity_score": 81.04519697042126,
                "rarity_level": "COMMON"
            }
        }
    },
    "183": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/183_1681445757.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "183",
                "name": "Troubled Badger Caelan #183",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445751,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_183_1681445757.png",
                "rarity_score": 86.73701298701299,
                "rarity_level": "COMMON"
            }
        }
    },
    "69": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/69_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "69",
                "name": "Puzzled Badger Rocky #69",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445726,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_69_1681445732.png",
                "rarity_score": 379.08714473931866,
                "rarity_level": "EPIC"
            }
        }
    },
    "106": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/106_1681445742.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "106",
                "name": "Glorious Badger Moore #106",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445735,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_106_1681445742.png",
                "rarity_score": 73.53848032221616,
                "rarity_level": "COMMON"
            }
        }
    },
    "152": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/152_1681445750.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "152",
                "name": "Moon Badger Tyrion #152",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445745,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_152_1681445750.png",
                "rarity_score": 57.12836232831485,
                "rarity_level": "COMMON"
            }
        }
    },
    "182": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/182_1681445756.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "182",
                "name": "Troubled Badger Caelan #182",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445750,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_182_1681445756.png",
                "rarity_score": 59.050343433414604,
                "rarity_level": "COMMON"
            }
        }
    },
    "64": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/64_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "64",
                "name": "Comfy Badger Nimbus #64",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445725,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_64_1681445732.png",
                "rarity_score": 91.81886206902567,
                "rarity_level": "RARE"
            }
        }
    },
    "109": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/109_1681445743.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "109",
                "name": "Handsome Badger Albus #109",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445736,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_109_1681445743.png",
                "rarity_score": 108.21047411685991,
                "rarity_level": "RARE"
            }
        }
    },
    "159": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/159_1681445752.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "159",
                "name": "Dumb Badger Hippogriff #159",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_159_1681445752.png",
                "rarity_score": 301.97735291735194,
                "rarity_level": "EPIC"
            }
        }
    },
    "191": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/191_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "191",
                "name": "Whale Badger Johnson #191",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445753,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_191_1681445761.png",
                "rarity_score": 228.37673895683815,
                "rarity_level": "EPIC"
            }
        }
    },
    "78": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/78_1681445733.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "78",
                "name": "Fierce Badger Gulliver #78",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_78_1681445733.png",
                "rarity_score": 64.69751451556753,
                "rarity_level": "COMMON"
            }
        }
    },
    "110": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/110_1681445741.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "110",
                "name": "Contagious Badger Khan #110",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445736,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_110_1681445741.png",
                "rarity_score": 299.52352472089314,
                "rarity_level": "EPIC"
            }
        }
    },
    "144": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/144_1681445752.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "144",
                "name": "Annoying Badger Bryce #144",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445742,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_144_1681445752.png",
                "rarity_score": 147.8062154771494,
                "rarity_level": "RARE"
            }
        }
    },
    "190": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/190_1681445758.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "190",
                "name": "Gifted Badger Muhammad #190",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445752,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_190_1681445758.png",
                "rarity_score": 63.57721347910625,
                "rarity_level": "COMMON"
            }
        }
    },
    "65": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/65_1681445732.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "65",
                "name": "Bookworm Badger Colt #65",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445725,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_65_1681445732.png",
                "rarity_score": 75.81095335189919,
                "rarity_level": "COMMON"
            }
        }
    },
    "111": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/111_1681445744.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "111",
                "name": "Muddy Badger Crookshanks #111",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445736,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_111_1681445744.png",
                "rarity_score": 78.60474317513169,
                "rarity_level": "COMMON"
            }
        }
    },
    "156": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/156_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "156",
                "name": "Itchy Badger Greyback #156",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_156_1681445753.png",
                "rarity_score": 63.95048791756243,
                "rarity_level": "COMMON"
            }
        }
    },
    "194": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/194_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "194",
                "name": "Panda Badger Chester #194",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445753,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_194_1681445761.png",
                "rarity_score": 78.47915942355564,
                "rarity_level": "COMMON"
            }
        }
    },
    "76": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/76_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "76",
                "name": "Silly Badger Kai #76",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_76_1681445734.png",
                "rarity_score": 88.60556286505974,
                "rarity_level": "COMMON"
            }
        }
    },
    "115": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/115_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "115",
                "name": "Disgusted Badger Jack #115",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_115_1681445745.png",
                "rarity_score": 107.07131324235864,
                "rarity_level": "RARE"
            }
        }
    },
    "162": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/162_1681445752.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "162",
                "name": "Daft Badger Phineas #162",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_162_1681445752.png",
                "rarity_score": 59.37084311260908,
                "rarity_level": "COMMON"
            }
        }
    },
    "193": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/193_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "193",
                "name": "Precious Badger Thestral #193",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445753,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_193_1681445761.png",
                "rarity_score": 85.10132569653166,
                "rarity_level": "COMMON"
            }
        }
    },
    "74": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/74_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "74",
                "name": "Amused Badger Sirius Black #74",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_74_1681445734.png",
                "rarity_score": 77.944806379462,
                "rarity_level": "COMMON"
            }
        }
    },
    "116": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/116_1681445745.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "116",
                "name": "Encouraging Badger Christer #116",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_116_1681445745.png",
                "rarity_score": 114.52548832609438,
                "rarity_level": "RARE"
            }
        }
    },
    "165": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/165_1681445754.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "165",
                "name": "Glamorous Badger Hermes #165",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_165_1681445754.png",
                "rarity_score": 80.49192879800388,
                "rarity_level": "COMMON"
            }
        }
    },
    "206": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/206_1681445760.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "206",
                "name": "Aggressive Badger Cesar #206",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_206_1681445760.png",
                "rarity_score": 279.88212373813684,
                "rarity_level": "EPIC"
            }
        }
    },
    "77": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/77_1681445734.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "77",
                "name": "Tense Badger Mundungus #77",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445727,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_77_1681445734.png",
                "rarity_score": 105.25047551135042,
                "rarity_level": "RARE"
            }
        }
    },
    "117": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/117_1681445742.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "117",
                "name": "Modern Badger David #117",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_117_1681445742.png",
                "rarity_score": 59.88503685581294,
                "rarity_level": "COMMON"
            }
        }
    },
    "145": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/145_1681445752.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "145",
                "name": "Faithful Badger Sphynx #145",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445744,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_145_1681445752.png",
                "rarity_score": 86.6729349506052,
                "rarity_level": "COMMON"
            }
        }
    },
    "197": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/197_1681445760.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "197",
                "name": "Precious Badger Neville #197",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445754,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_197_1681445760.png",
                "rarity_score": 90.03527288435485,
                "rarity_level": "RARE"
            }
        }
    },
    "108": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/108_1681445740.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "108",
                "name": "Bad Badger Tiberius #108",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445735,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_108_1681445740.png",
                "rarity_score": 60.01374039574379,
                "rarity_level": "COMMON"
            }
        }
    },
    "139": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/139_1681445746.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "139",
                "name": "Asthmatic Badger Watson #139",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445741,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_139_1681445746.png",
                "rarity_score": 54.165970498913545,
                "rarity_level": "COMMON"
            }
        }
    },
    "166": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/166_1681445753.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "166",
                "name": "Concerned Badger Mary #166",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_166_1681445753.png",
                "rarity_score": 57.20924474369388,
                "rarity_level": "COMMON"
            }
        }
    },
    "195": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/195_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "195",
                "name": "Bright Badger Julius #195",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445754,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_195_1681445763.png",
                "rarity_score": 158.9481901012366,
                "rarity_level": "RARE"
            }
        }
    },
    "107": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/107_1681445740.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "107",
                "name": "Wild Badger Prongs #107",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445735,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_107_1681445740.png",
                "rarity_score": 61.43403042245446,
                "rarity_level": "COMMON"
            }
        }
    },
    "140": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/140_1681445746.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "140",
                "name": "Nutty Badger Graham #140",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445741,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_140_1681445746.png",
                "rarity_score": 74.49844601272595,
                "rarity_level": "COMMON"
            }
        }
    },
    "169": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/169_1681445757.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "169",
                "name": "Scary Badger Augustus #169",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445748,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_169_1681445757.png",
                "rarity_score": 112.82946266259535,
                "rarity_level": "RARE"
            }
        }
    },
    "219": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/219_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "219",
                "name": "Lambo Badger Dumbledore #219",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445758,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_219_1681445763.png",
                "rarity_score": 74.03944291538191,
                "rarity_level": "COMMON"
            }
        }
    },
    "119": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/119_1681445743.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "119",
                "name": "Dizzy Badger Deontay #119",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_119_1681445743.png",
                "rarity_score": 62.29771858428906,
                "rarity_level": "COMMON"
            }
        }
    },
    "153": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/153_1681445752.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "153",
                "name": "Fine Badger Dirk #153",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445746,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_153_1681445752.png",
                "rarity_score": 95.16540663622794,
                "rarity_level": "RARE"
            }
        }
    },
    "192": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/192_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "192",
                "name": "Tired Badger Dursley #192",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445753,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_192_1681445761.png",
                "rarity_score": 64.10641423646297,
                "rarity_level": "COMMON"
            }
        }
    },
    "236": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/236_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "236",
                "name": "Stupid Badger Cal #236",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445762,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_236_1681445768.png",
                "rarity_score": 78.59223473972585,
                "rarity_level": "COMMON"
            }
        }
    },
    "121": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/121_1681445746.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "121",
                "name": "Glorious Badger Coleman #121",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445737,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_121_1681445746.png",
                "rarity_score": 78.24276694122297,
                "rarity_level": "COMMON"
            }
        }
    },
    "175": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/175_1681445755.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "175",
                "name": "Bashful Badger Greyback #175",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445749,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_175_1681445755.png",
                "rarity_score": 69.0626096232574,
                "rarity_level": "COMMON"
            }
        }
    },
    "167": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/167_1681445756.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "167",
                "name": "Poor Badger Rufus #167",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445747,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_167_1681445756.png",
                "rarity_score": 79.95938299013675,
                "rarity_level": "COMMON"
            }
        }
    },
    "213": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/213_1681445762.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "213",
                "name": "HODL Badger Charlie #213",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445757,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_213_1681445762.png",
                "rarity_score": 61.06108650765797,
                "rarity_level": "COMMON"
            }
        }
    },
    "185": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/185_1681445755.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "185",
                "name": "Rekt Badger Tyson #185",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445751,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_185_1681445755.png",
                "rarity_score": 76.24201931846902,
                "rarity_level": "COMMON"
            }
        }
    },
    "210": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/210_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "210",
                "name": "Expensive Badger Zane #210",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445756,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_210_1681445763.png",
                "rarity_score": 213.39062206209914,
                "rarity_level": "RARE"
            }
        }
    },
    "1450": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1450_1681446002.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1450",
                "name": "Curious Badger Remus #1450",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445996,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1450_1681446002.png",
                "rarity_score": 63.19224899180267,
                "rarity_level": "COMMON"
            }
        }
    },
    "1489": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1489_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1489",
                "name": "Busy Badger Bridger #1489",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446003,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1489_1681446012.png",
                "rarity_score": 98.92704444180777,
                "rarity_level": "RARE"
            }
        }
    },
    "189": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/189_1681445757.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "189",
                "name": "Embarrassed Badger Hermes #189",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445752,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_189_1681445757.png",
                "rarity_score": 56.41953758218683,
                "rarity_level": "COMMON"
            }
        }
    },
    "216": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/216_1681445764.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "216",
                "name": "Wandering Badger Leopold #216",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445758,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_216_1681445764.png",
                "rarity_score": 91.77301407843542,
                "rarity_level": "RARE"
            }
        }
    },
    "1456": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1456_1681446002.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1456",
                "name": "Terrible Badger Minerva #1456",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1456_1681446002.png",
                "rarity_score": 62.034430875013754,
                "rarity_level": "COMMON"
            }
        }
    },
    "1490": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1490_1681446008.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1490",
                "name": "Crazy Badger Kostya #1490",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446003,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1490_1681446008.png",
                "rarity_score": 54.09563468136333,
                "rarity_level": "COMMON"
            }
        }
    },
    "187": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/187_1681445759.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "187",
                "name": "Elegant Badger Shields #187",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445752,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_187_1681445759.png",
                "rarity_score": 95.35492382538462,
                "rarity_level": "RARE"
            }
        }
    },
    "226": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/226_1681445764.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "226",
                "name": "Dull Badger Marcus #226",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_226_1681445764.png",
                "rarity_score": 73.7702497718741,
                "rarity_level": "COMMON"
            }
        }
    },
    "1453": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1453_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1453",
                "name": "HODL Badger Tszyu #1453",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445996,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1453_1681446003.png",
                "rarity_score": 97.36856841586999,
                "rarity_level": "RARE"
            }
        }
    },
    "1491": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1491_1681446010.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1491",
                "name": "Handsome Badger Manny #1491",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446003,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1491_1681446010.png",
                "rarity_score": 63.34805782953812,
                "rarity_level": "COMMON"
            }
        }
    },
    "200": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/200_1681445759.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "200",
                "name": "Friendly Badger Cayman #200",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_200_1681445759.png",
                "rarity_score": 57.92523077859013,
                "rarity_level": "COMMON"
            }
        }
    },
    "227": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/227_1681445764.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "227",
                "name": "Embarrassed Badger Cullen #227",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_227_1681445764.png",
                "rarity_score": 52.94418329384594,
                "rarity_level": "COMMON"
            }
        }
    },
    "1445": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1445_1681446002.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1445",
                "name": "Dopey Badger Ishmael #1445",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445994,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1445_1681446002.png",
                "rarity_score": 75.85452723792925,
                "rarity_level": "COMMON"
            }
        }
    },
    "1492": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1492_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1492",
                "name": "Bewildered Badger Christer #1492",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446003,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1492_1681446012.png",
                "rarity_score": 90.37065697756175,
                "rarity_level": "RARE"
            }
        }
    },
    "202": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/202_1681445759.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "202",
                "name": "Real Badger Remus #202",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_202_1681445759.png",
                "rarity_score": 55.59612292507936,
                "rarity_level": "COMMON"
            }
        }
    },
    "228": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/228_1681445765.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "228",
                "name": "Comfy Badger Fireball #228",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_228_1681445765.png",
                "rarity_score": 57.84183384596551,
                "rarity_level": "COMMON"
            }
        }
    },
    "1458": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1458_1681446002.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1458",
                "name": "Gorgeous Badger Bellatrix #1458",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1458_1681446002.png",
                "rarity_score": 56.710871814815064,
                "rarity_level": "COMMON"
            }
        }
    },
    "1493": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1493_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1493",
                "name": "Energetic Badger Havelock #1493",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446003,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1493_1681446012.png",
                "rarity_score": 158.99104257865463,
                "rarity_level": "RARE"
            }
        }
    },
    "205": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/205_1681445759.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "205",
                "name": "Cruel Badger Mike #205",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_205_1681445759.png",
                "rarity_score": 278.23482335804954,
                "rarity_level": "EPIC"
            }
        }
    },
    "229": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/229_1681445767.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "229",
                "name": "Famous Badger Lennox #229",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_229_1681445767.png",
                "rarity_score": 80.94015023587598,
                "rarity_level": "COMMON"
            }
        }
    },
    "1464": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1464_1681446002.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1464",
                "name": "Itchy Badger Gideon #1464",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445998,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1464_1681446002.png",
                "rarity_score": 58.14402524205975,
                "rarity_level": "COMMON"
            }
        }
    },
    "1494": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1494_1681446010.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1494",
                "name": "Angry Badger Langston #1494",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446003,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1494_1681446010.png",
                "rarity_score": 121.40119685151514,
                "rarity_level": "RARE"
            }
        }
    },
    "203": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/203_1681445760.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "203",
                "name": "Comfy Badger Jacob #203",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_203_1681445760.png",
                "rarity_score": 60.8564154436952,
                "rarity_level": "COMMON"
            }
        }
    },
    "233": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/233_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "233",
                "name": "Wild Badger Robinson #233",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445761,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_233_1681445768.png",
                "rarity_score": 98.57055419369667,
                "rarity_level": "RARE"
            }
        }
    },
    "1454": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1454_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1454",
                "name": "FUD Badger Seamus #1454",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445996,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1454_1681446003.png",
                "rarity_score": 59.633037031717514,
                "rarity_level": "COMMON"
            }
        }
    },
    "1495": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1495_1681446010.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1495",
                "name": "Brave Badger Godric #1495",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1495_1681446010.png",
                "rarity_score": 61.88215130512233,
                "rarity_level": "COMMON"
            }
        }
    },
    "186": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/186_1681445760.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "186",
                "name": "Nice Badger Blaise #186",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445751,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_186_1681445760.png",
                "rarity_score": 79.39396779317703,
                "rarity_level": "COMMON"
            }
        }
    },
    "1459": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1459_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1459",
                "name": "Anal Badger Blaise #1459",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1459_1681446003.png",
                "rarity_score": 72.08057707568587,
                "rarity_level": "COMMON"
            }
        }
    },
    "1496": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1496_1681446010.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1496",
                "name": "Clumsy Badger Winky #1496",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1496_1681446010.png",
                "rarity_score": 75.36141059035484,
                "rarity_level": "COMMON"
            }
        }
    },
    "1533": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1533_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1533",
                "name": "Repulsive Badger Wladimir #1533",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1533_1681446018.png",
                "rarity_score": 145.24630814904734,
                "rarity_level": "RARE"
            }
        }
    },
    "204": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/204_1681445760.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "204",
                "name": "Tired Badger Firebolt #204",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_204_1681445760.png",
                "rarity_score": 66.02275296118847,
                "rarity_level": "COMMON"
            }
        }
    },
    "235": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/235_1681445766.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "235",
                "name": "Dopey Badger Archie #235",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445761,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_235_1681445766.png",
                "rarity_score": 73.2977383418779,
                "rarity_level": "COMMON"
            }
        }
    },
    "1462": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1462_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1462",
                "name": "FUD Badger Horace #1462",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1462_1681446003.png",
                "rarity_score": 60.68689624550728,
                "rarity_level": "COMMON"
            }
        }
    },
    "1497": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1497_1681446010.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1497",
                "name": "Talented Badger Lennie #1497",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1497_1681446010.png",
                "rarity_score": 112.59226928526522,
                "rarity_level": "RARE"
            }
        }
    },
    "198": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/198_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "198",
                "name": "Enchanting Badger Bones #198",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445754,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_198_1681445761.png",
                "rarity_score": 67.75906040199472,
                "rarity_level": "COMMON"
            }
        }
    },
    "237": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/237_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "237",
                "name": "Pleasant Badger Fred #237",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445762,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_237_1681445768.png",
                "rarity_score": 72.4065862994561,
                "rarity_level": "COMMON"
            }
        }
    },
    "1448": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1448_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1448",
                "name": "Annoying Badger Fang #1448",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445995,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1448_1681446003.png",
                "rarity_score": 78.87655252654889,
                "rarity_level": "COMMON"
            }
        }
    },
    "1498": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1498_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1498",
                "name": "Wicked Badger Yaxley #1498",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1498_1681446012.png",
                "rarity_score": 73.84923020372547,
                "rarity_level": "COMMON"
            }
        }
    },
    "208": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/208_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "208",
                "name": "Proud Badger Macaulay #208",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_208_1681445761.png",
                "rarity_score": 125.58523036775232,
                "rarity_level": "RARE"
            }
        }
    },
    "239": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/239_1681445769.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "239",
                "name": "Embarrassed Badger Ishmael #239",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445762,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_239_1681445769.png",
                "rarity_score": 113.52763668705535,
                "rarity_level": "RARE"
            }
        }
    },
    "1469": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1469_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1469",
                "name": "Ludicrous Badger Credence #1469",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1469_1681446003.png",
                "rarity_score": 58.60995747265041,
                "rarity_level": "COMMON"
            }
        }
    },
    "1499": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1499_1681446009.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1499",
                "name": "Itchy Badger Henry #1499",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1499_1681446009.png",
                "rarity_score": 55.279764607213195,
                "rarity_level": "COMMON"
            }
        }
    },
    "212": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/212_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "212",
                "name": "Ugly Badger Argus #212",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445757,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_212_1681445761.png",
                "rarity_score": 53.96907321055928,
                "rarity_level": "COMMON"
            }
        }
    },
    "240": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/240_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "240",
                "name": "Daft Badger Diggory #240",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445762,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_240_1681445768.png",
                "rarity_score": 108.26099767272244,
                "rarity_level": "RARE"
            }
        }
    },
    "1460": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1460_1681446003.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1460",
                "name": "Unsightly Badger Sugar #1460",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1460_1681446003.png",
                "rarity_score": 129.08734941084452,
                "rarity_level": "RARE"
            }
        }
    },
    "1500": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1500_1681446013.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1500",
                "name": "Filthy Badger Shields #1500",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1500_1681446013.png",
                "rarity_score": 83.87218348426626,
                "rarity_level": "COMMON"
            }
        }
    },
    "188": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/188_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "188",
                "name": "Horrible Badger Grey #188",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445752,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_188_1681445761.png",
                "rarity_score": 123.63323278927487,
                "rarity_level": "RARE"
            }
        }
    },
    "1461": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1461_1681446004.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1461",
                "name": "Nice Badger Nargle #1461",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1461_1681446004.png",
                "rarity_score": 154.43475117827313,
                "rarity_level": "RARE"
            }
        }
    },
    "1501": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1501_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1501",
                "name": "Aggressive Badger Arlo #1501",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446004,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1501_1681446012.png",
                "rarity_score": 133.372551090657,
                "rarity_level": "RARE"
            }
        }
    },
    "1543": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1543_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1543",
                "name": "Rekt Badger Colin #1543",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446013,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1543_1681446022.png",
                "rarity_score": 365.34441141943216,
                "rarity_level": "EPIC"
            }
        }
    },
    "196": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/196_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "196",
                "name": "Worried Badger Latham #196",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445754,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_196_1681445761.png",
                "rarity_score": 107.90523073189559,
                "rarity_level": "RARE"
            }
        }
    },
    "244": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/244_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "244",
                "name": "Repulsive Badger Binns #244",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445763,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_244_1681445768.png",
                "rarity_score": 69.73000683455439,
                "rarity_level": "COMMON"
            }
        }
    },
    "1476": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1476_1681446004.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1476",
                "name": "Handsome Badger Maximus #1476",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1476_1681446004.png",
                "rarity_score": 56.58093184256982,
                "rarity_level": "COMMON"
            }
        }
    },
    "1502": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1502_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1502",
                "name": "Courageous Badger Elfie #1502",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446005,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1502_1681446012.png",
                "rarity_score": 98.49624221426295,
                "rarity_level": "RARE"
            }
        }
    },
    "201": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/201_1681445761.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "201",
                "name": "Dumb Badger Gauge #201",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_201_1681445761.png",
                "rarity_score": 474.0766283524904,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1452": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1452_1681446005.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1452",
                "name": "Cute Badger Fawkes #1452",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445996,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1452_1681446005.png",
                "rarity_score": 112.79053402877233,
                "rarity_level": "RARE"
            }
        }
    },
    "1503": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1503_1681446011.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1503",
                "name": "Average Badger Lee #1503",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446005,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1503_1681446011.png",
                "rarity_score": 70.21930688301846,
                "rarity_level": "COMMON"
            }
        }
    },
    "1534": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1534_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1534",
                "name": "Lavish Badger Draco #1534",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1534_1681446018.png",
                "rarity_score": 189.67803155747976,
                "rarity_level": "RARE"
            }
        }
    },
    "218": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/218_1681445762.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "218",
                "name": "Talented Badger Tiberius #218",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445758,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_218_1681445762.png",
                "rarity_score": 55.752397391842415,
                "rarity_level": "COMMON"
            }
        }
    },
    "1475": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1475_1681446005.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1475",
                "name": "Courageous Badger Bludger #1475",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1475_1681446005.png",
                "rarity_score": 54.27397986221515,
                "rarity_level": "COMMON"
            }
        }
    },
    "1504": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1504_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1504",
                "name": "Anal Badger Draco #1504",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446005,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1504_1681446012.png",
                "rarity_score": 66.25568979971375,
                "rarity_level": "COMMON"
            }
        }
    },
    "1541": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1541_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1541",
                "name": "Glamorous Badger Scimgeour #1541",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446013,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1541_1681446018.png",
                "rarity_score": 64.17446805322894,
                "rarity_level": "COMMON"
            }
        }
    },
    "207": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/207_1681445762.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "207",
                "name": "Concerned Badger Fluffy #207",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445755,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_207_1681445762.png",
                "rarity_score": 97.52126405675736,
                "rarity_level": "RARE"
            }
        }
    },
    "249": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/249_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "249",
                "name": "Brave Badger Gornuk #249",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445764,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_249_1681445768.png",
                "rarity_score": 61.756706479505205,
                "rarity_level": "COMMON"
            }
        }
    },
    "1466": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1466_1681446005.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1466",
                "name": "Famous Badger Dallas #1466",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445998,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1466_1681446005.png",
                "rarity_score": 108.96087530543386,
                "rarity_level": "RARE"
            }
        }
    },
    "1505": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1505_1681446013.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1505",
                "name": "Bloody Badger George #1505",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681446006,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1505_1681446013.png",
                "rarity_score": 176.12386636694828,
                "rarity_level": "RARE"
            }
        }
    },
    "211": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/211_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "211",
                "name": "Perfect Badger Wrackspurt #211",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445756,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_211_1681445763.png",
                "rarity_score": 126.5257886927761,
                "rarity_level": "RARE"
            }
        }
    },
    "251": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/251_1681445769.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "251",
                "name": "Bewildered Badger Shep #251",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_251_1681445769.png",
                "rarity_score": 60.27364000263659,
                "rarity_level": "COMMON"
            }
        }
    },
    "1455": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1455_1681446005.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1455",
                "name": "Disgusted Badger Caelan #1455",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445996,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1455_1681446005.png",
                "rarity_score": 97.92711391146483,
                "rarity_level": "RARE"
            }
        }
    },
    "1506": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1506_1681446011.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1506",
                "name": "Encouraging Badger Malfoy #1506",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446006,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1506_1681446011.png",
                "rarity_score": 59.12427053462985,
                "rarity_level": "COMMON"
            }
        }
    },
    "224": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/224_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "224",
                "name": "Helpless Badger Antonio #224",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445759,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_224_1681445763.png",
                "rarity_score": 61.10969858715008,
                "rarity_level": "COMMON"
            }
        }
    },
    "1472": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1472_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1472",
                "name": "Dangerous Badger Wrackspurt #1472",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1472_1681446006.png",
                "rarity_score": 72.14696951322983,
                "rarity_level": "COMMON"
            }
        }
    },
    "1507": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1507_1681446014.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1507",
                "name": "Wen Badger Latham #1507",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446006,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1507_1681446014.png",
                "rarity_score": 118.5547128270417,
                "rarity_level": "RARE"
            }
        }
    },
    "1558": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1558_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1558",
                "name": "Poised Badger Ollivander #1558",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446015,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1558_1681446022.png",
                "rarity_score": 70.33390522065287,
                "rarity_level": "COMMON"
            }
        }
    },
    "221": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/221_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "221",
                "name": "Gifted Badger Larkin #221",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445758,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_221_1681445763.png",
                "rarity_score": 58.34940160682734,
                "rarity_level": "COMMON"
            }
        }
    },
    "1481": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1481_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1481",
                "name": "Clammy Badger Niffler #1481",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1481_1681446006.png",
                "rarity_score": 300.90931262656306,
                "rarity_level": "EPIC"
            }
        }
    },
    "1509": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1509_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1509",
                "name": "Good Badger Khan #1509",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1509_1681446012.png",
                "rarity_score": 57.868643948834645,
                "rarity_level": "COMMON"
            }
        }
    },
    "1542": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1542_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1542",
                "name": "Fragile Badger Ron #1542",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446013,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1542_1681446018.png",
                "rarity_score": 58.8831679964557,
                "rarity_level": "COMMON"
            }
        }
    },
    "209": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/209_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "209",
                "name": "Silly Badger Gringot #209",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445756,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_209_1681445763.png",
                "rarity_score": 70.8672340094512,
                "rarity_level": "COMMON"
            }
        }
    },
    "1465": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1465_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1465",
                "name": "Asthmatic Badger Credence #1465",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445998,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1465_1681446006.png",
                "rarity_score": 80.53955520477874,
                "rarity_level": "COMMON"
            }
        }
    },
    "1508": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1508_1681446013.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1508",
                "name": "Terrible Badger Jasper #1508",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1508_1681446013.png",
                "rarity_score": 206.3445648186418,
                "rarity_level": "RARE"
            }
        }
    },
    "1551": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1551_1681446021.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1551",
                "name": "Fierce Badger Boggart #1551",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1551_1681446021.png",
                "rarity_score": 178.83758068813765,
                "rarity_level": "RARE"
            }
        }
    },
    "217": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/217_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "217",
                "name": "Lambo Badger Salazar #217",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445758,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_217_1681445763.png",
                "rarity_score": 61.46135678011526,
                "rarity_level": "COMMON"
            }
        }
    },
    "1463": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1463_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1463",
                "name": "Charming Badger Nicolas #1463",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1463_1681446006.png",
                "rarity_score": 111.56867409532359,
                "rarity_level": "RARE"
            }
        }
    },
    "1511": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1511_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1511",
                "name": "Upset Badger Gulliver #1511",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1511_1681446012.png",
                "rarity_score": 57.538280179290986,
                "rarity_level": "COMMON"
            }
        }
    },
    "1544": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1544_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1544",
                "name": "Bloody Badger Major #1544",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1544_1681446018.png",
                "rarity_score": 56.286545035401,
                "rarity_level": "COMMON"
            }
        }
    },
    "222": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/222_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "222",
                "name": "Impressive Badger Colin #222",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445759,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_222_1681445763.png",
                "rarity_score": 58.26658153297027,
                "rarity_level": "COMMON"
            }
        }
    },
    "1477": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1477_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1477",
                "name": "Glorious Badger Werner #1477",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1477_1681446006.png",
                "rarity_score": 58.041185085051254,
                "rarity_level": "COMMON"
            }
        }
    },
    "1510": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1510_1681446017.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1510",
                "name": "Horrible Badger Ronan #1510",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1510_1681446017.png",
                "rarity_score": 138.1489343641343,
                "rarity_level": "RARE"
            }
        }
    },
    "1571": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1571_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1571",
                "name": "Evil Badger Archie #1571",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446018,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1571_1681446022.png",
                "rarity_score": 56.52920163505554,
                "rarity_level": "COMMON"
            }
        }
    },
    "220": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/220_1681445764.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "220",
                "name": "Busy Badger Christer #220",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445758,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_220_1681445764.png",
                "rarity_score": 73.45131849070955,
                "rarity_level": "COMMON"
            }
        }
    },
    "1479": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1479_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1479",
                "name": "Dumb Badger Lily #1479",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1479_1681446006.png",
                "rarity_score": 56.62509016561133,
                "rarity_level": "COMMON"
            }
        }
    },
    "1512": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1512_1681446011.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1512",
                "name": "Uptight Badger Vernon #1512",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1512_1681446011.png",
                "rarity_score": 61.514359538399766,
                "rarity_level": "COMMON"
            }
        }
    },
    "1537": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1537_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1537",
                "name": "Wandering Badger Sirius Black #1537",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446012,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1537_1681446018.png",
                "rarity_score": 73.06822013264352,
                "rarity_level": "COMMON"
            }
        }
    },
    "214": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/214_1681445763.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "214",
                "name": "Glamorous Badger Rubin #214",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445757,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_214_1681445763.png",
                "rarity_score": 83.66349934254748,
                "rarity_level": "COMMON"
            }
        }
    },
    "1467": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1467_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1467",
                "name": "Repulsive Badger Voldemort #1467",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445998,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1467_1681446006.png",
                "rarity_score": 58.78902107392172,
                "rarity_level": "COMMON"
            }
        }
    },
    "1514": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1514_1681446014.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1514",
                "name": "Charming Badger Weasley #1514",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1514_1681446014.png",
                "rarity_score": 167.47138009049192,
                "rarity_level": "RARE"
            }
        }
    },
    "1555": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1555_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1555",
                "name": "Energetic Badger Jacob #1555",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1555_1681446022.png",
                "rarity_score": 94.87420413354914,
                "rarity_level": "RARE"
            }
        }
    },
    "215": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/215_1681445764.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "215",
                "name": "Defiant Badger Darcy #215",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445757,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_215_1681445764.png",
                "rarity_score": 77.09662855887291,
                "rarity_level": "COMMON"
            }
        }
    },
    "1468": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1468_1681446006.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1468",
                "name": "Lovely Badger Amos #1468",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1468_1681446006.png",
                "rarity_score": 74.58435658175138,
                "rarity_level": "COMMON"
            }
        }
    },
    "1513": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1513_1681446014.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1513",
                "name": "Grumpy Badger Orlando #1513",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1513_1681446014.png",
                "rarity_score": 70.08421680340854,
                "rarity_level": "COMMON"
            }
        }
    },
    "1556": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1556_1681446021.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1556",
                "name": "Gleaming Badger Edward #1556",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446015,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1556_1681446021.png",
                "rarity_score": 69.96508717092905,
                "rarity_level": "COMMON"
            }
        }
    },
    "223": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/223_1681445765.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "223",
                "name": "Proud Badger Floyd #223",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445759,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_223_1681445765.png",
                "rarity_score": 75.03768735154539,
                "rarity_level": "COMMON"
            }
        }
    },
    "1483": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1483_1681446007.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1483",
                "name": "Good Badger Graham #1483",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1483_1681446007.png",
                "rarity_score": 59.33710844999267,
                "rarity_level": "COMMON"
            }
        }
    },
    "1515": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1515_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1515",
                "name": "Itchy Badger Scorpius #1515",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446007,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1515_1681446012.png",
                "rarity_score": 52.44636954350145,
                "rarity_level": "COMMON"
            }
        }
    },
    "1540": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1540_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1540",
                "name": "Proud Badger Goyle #1540",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446013,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1540_1681446018.png",
                "rarity_score": 101.87899542939708,
                "rarity_level": "RARE"
            }
        }
    },
    "231": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/231_1681445765.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "231",
                "name": "Awful Badger Dudley #231",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_231_1681445765.png",
                "rarity_score": 62.39492942516751,
                "rarity_level": "COMMON"
            }
        }
    },
    "1482": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1482_1681446007.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1482",
                "name": "Jealous Badger Colt #1482",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1482_1681446007.png",
                "rarity_score": 75.9616650587992,
                "rarity_level": "COMMON"
            }
        }
    },
    "1516": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1516_1681446015.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1516",
                "name": "Funny Badger Miguel #1516",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1516_1681446015.png",
                "rarity_score": 94.6199180856858,
                "rarity_level": "RARE"
            }
        }
    },
    "1562": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1562_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1562",
                "name": "Odd Badger Bones #1562",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446016,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1562_1681446022.png",
                "rarity_score": 85.14387573211103,
                "rarity_level": "COMMON"
            }
        }
    },
    "225": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/225_1681445765.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "225",
                "name": "Bloody Badger Bellatrix #225",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_225_1681445765.png",
                "rarity_score": 75.90615266543935,
                "rarity_level": "COMMON"
            }
        }
    },
    "1457": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1457_1681446007.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1457",
                "name": "Hilarious Badger Holly #1457",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445997,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1457_1681446007.png",
                "rarity_score": 107.64265582058637,
                "rarity_level": "RARE"
            }
        }
    },
    "1517": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1517_1681446014.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1517",
                "name": "Muddy Badger Crabbe #1517",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1517_1681446014.png",
                "rarity_score": 112.49451914150636,
                "rarity_level": "RARE"
            }
        }
    },
    "1559": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1559_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1559",
                "name": "Awful Badger Toby #1559",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446015,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1559_1681446022.png",
                "rarity_score": 157.4129425163238,
                "rarity_level": "RARE"
            }
        }
    },
    "230": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/230_1681445766.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "230",
                "name": "Confused Badger Julio #230",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445760,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_230_1681445766.png",
                "rarity_score": 123.18136985264272,
                "rarity_level": "RARE"
            }
        }
    },
    "1478": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1478_1681446007.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1478",
                "name": "Creepy Badger Rocky #1478",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1478_1681446007.png",
                "rarity_score": 69.40852901860275,
                "rarity_level": "COMMON"
            }
        }
    },
    "1518": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1518_1681446013.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1518",
                "name": "Envious Badger Percy #1518",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1518_1681446013.png",
                "rarity_score": 61.926903750316335,
                "rarity_level": "COMMON"
            }
        }
    },
    "1552": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1552_1681446022.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1552",
                "name": "Clammy Badger Macaulay #1552",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1552_1681446022.png",
                "rarity_score": 123.37982476863075,
                "rarity_level": "RARE"
            }
        }
    },
    "238": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/238_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "238",
                "name": "Calm Badger Hermes #238",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445762,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_238_1681445768.png",
                "rarity_score": 93.0312209003842,
                "rarity_level": "RARE"
            }
        }
    },
    "1486": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1486_1681446007.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1486",
                "name": "Kind Badger Langston #1486",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446002,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1486_1681446007.png",
                "rarity_score": 79.19280633784619,
                "rarity_level": "COMMON"
            }
        }
    },
    "1519": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1519_1681446013.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1519",
                "name": "Whale Badger Rocky #1519",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1519_1681446013.png",
                "rarity_score": 115.6695731510684,
                "rarity_level": "RARE"
            }
        }
    },
    "1553": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1553_1681446020.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1553",
                "name": "Byzantine Badger Cornelius #1553",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1553_1681446020.png",
                "rarity_score": 69.02385914249012,
                "rarity_level": "COMMON"
            }
        }
    },
    "232": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/232_1681445768.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "232",
                "name": "Panda Badger Bryce #232",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445761,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_232_1681445768.png",
                "rarity_score": 74.10671892531703,
                "rarity_level": "COMMON"
            }
        }
    },
    "1480": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1480_1681446008.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1480",
                "name": "Amused Badger Marcus #1480",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446000,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1480_1681446008.png",
                "rarity_score": 313.232852075157,
                "rarity_level": "EPIC"
            }
        }
    },
    "1520": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1520_1681446014.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1520",
                "name": "Happy Badger Remus #1520",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1520_1681446014.png",
                "rarity_score": 71.65131996980163,
                "rarity_level": "COMMON"
            }
        }
    },
    "1557": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1557_1681446020.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1557",
                "name": "Nice Badger Crabbe #1557",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446015,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1557_1681446020.png",
                "rarity_score": 81.56215065325175,
                "rarity_level": "COMMON"
            }
        }
    },
    "243": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/243_1681445770.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "243",
                "name": "Poor Badger Calen #243",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445763,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_243_1681445770.png",
                "rarity_score": 68.96217256238734,
                "rarity_level": "COMMON"
            }
        }
    },
    "245": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/245_1681445770.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "245",
                "name": "Poised Badger Maxton #245",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445763,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_245_1681445770.png",
                "rarity_score": 181.6544577027759,
                "rarity_level": "RARE"
            }
        }
    },
    "234": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/234_1681445770.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "234",
                "name": "Handsome Badger Newt #234",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445761,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_234_1681445770.png",
                "rarity_score": 76.49622634300735,
                "rarity_level": "COMMON"
            }
        }
    },
    "254": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/254_1681445769.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "254",
                "name": "Kind Badger Fireball #254",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_254_1681445769.png",
                "rarity_score": 255.51525873507296,
                "rarity_level": "EPIC"
            }
        }
    },
    "261": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/261_1681445770.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "261",
                "name": "Bashful Badger Seamus #261",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_261_1681445770.png",
                "rarity_score": 59.30439140568332,
                "rarity_level": "COMMON"
            }
        }
    },
    "246": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/246_1681445770.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "246",
                "name": "Jealous Badger Finnigan #246",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445764,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_246_1681445770.png",
                "rarity_score": 67.14646839813109,
                "rarity_level": "COMMON"
            }
        }
    },
    "248": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/248_1681445770.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "248",
                "name": "Lovely Badger Wulfric #248",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445764,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_248_1681445770.png",
                "rarity_score": 87.15210255738582,
                "rarity_level": "COMMON"
            }
        }
    },
    "247": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/247_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "247",
                "name": "Ashamed Badger Calen #247",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445764,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_247_1681445771.png",
                "rarity_score": 110.77418407667699,
                "rarity_level": "RARE"
            }
        }
    },
    "259": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/259_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "259",
                "name": "Puzzled Badger Romeo #259",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_259_1681445771.png",
                "rarity_score": 63.64364765711458,
                "rarity_level": "COMMON"
            }
        }
    },
    "258": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/258_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "258",
                "name": "Homeless Badger Calen #258",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_258_1681445771.png",
                "rarity_score": 68.26282429267242,
                "rarity_level": "COMMON"
            }
        }
    },
    "256": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/256_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "256",
                "name": "Lucky Badger Fireball #256",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_256_1681445771.png",
                "rarity_score": 92.42849101783534,
                "rarity_level": "RARE"
            }
        }
    },
    "242": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/242_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "242",
                "name": "Encouraging Badger Harry Potter #242",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445763,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_242_1681445771.png",
                "rarity_score": 115.3100017782072,
                "rarity_level": "RARE"
            }
        }
    },
    "269": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/269_1681445772.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "269",
                "name": "Degen Badger Boot #269",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445767,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_269_1681445772.png",
                "rarity_score": 77.61920510239474,
                "rarity_level": "COMMON"
            }
        }
    },
    "241": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/241_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "241",
                "name": "Mysterious Badger Dallas #241",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445763,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_241_1681445771.png",
                "rarity_score": 113.59004730799829,
                "rarity_level": "RARE"
            }
        }
    },
    "263": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/263_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "263",
                "name": "Dark Badger Sirius #263",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_263_1681445771.png",
                "rarity_score": 63.46230518548998,
                "rarity_level": "COMMON"
            }
        }
    },
    "250": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/250_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "250",
                "name": "Clever Badger Harry Potter #250",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_250_1681445771.png",
                "rarity_score": 90.74388941376571,
                "rarity_level": "RARE"
            }
        }
    },
    "253": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/253_1681445772.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "253",
                "name": "Uptight Badger Peter #253",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_253_1681445772.png",
                "rarity_score": 98.78656582448346,
                "rarity_level": "RARE"
            }
        }
    },
    "257": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/257_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "257",
                "name": "Glorious Badger Ludo #257",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_257_1681445771.png",
                "rarity_score": 211.01976882940062,
                "rarity_level": "RARE"
            }
        }
    },
    "265": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/265_1681445772.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "265",
                "name": "Average Badger Langston #265",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_265_1681445772.png",
                "rarity_score": 58.510599732339976,
                "rarity_level": "COMMON"
            }
        }
    },
    "260": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/260_1681445772.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "260",
                "name": "Depressed Badger Louis #260",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_260_1681445772.png",
                "rarity_score": 97.93456116776451,
                "rarity_level": "RARE"
            }
        }
    },
    "252": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/252_1681445771.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "252",
                "name": "Delightful Badger Rubeus #252",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_252_1681445771.png",
                "rarity_score": 67.66047701815113,
                "rarity_level": "COMMON"
            }
        }
    },
    "255": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/255_1681445772.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "255",
                "name": "Helpful Badger Klitschko #255",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445765,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_255_1681445772.png",
                "rarity_score": 73.99622326550603,
                "rarity_level": "COMMON"
            }
        }
    },
    "268": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/268_1681445772.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "268",
                "name": "Fantastic Badger Flitwick #268",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_268_1681445772.png",
                "rarity_score": 61.460966599813204,
                "rarity_level": "COMMON"
            }
        }
    },
    "274": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/274_1681445773.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "274",
                "name": "Good Badger Viktor #274",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445768,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_274_1681445773.png",
                "rarity_score": 68.1443767405836,
                "rarity_level": "COMMON"
            }
        }
    },
    "272": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/272_1681445773.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "272",
                "name": "Bashful Badger Colin #272",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445768,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_272_1681445773.png",
                "rarity_score": 56.02279955392177,
                "rarity_level": "COMMON"
            }
        }
    },
    "262": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/262_1681445773.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "262",
                "name": "Embarrassed Badger Macaulay #262",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_262_1681445773.png",
                "rarity_score": 108.51928931873061,
                "rarity_level": "RARE"
            }
        }
    },
    "271": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/271_1681445773.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "271",
                "name": "Lovely Badger Moore #271",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445768,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_271_1681445773.png",
                "rarity_score": 66.10561138074567,
                "rarity_level": "COMMON"
            }
        }
    },
    "267": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/267_1681445773.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "267",
                "name": "Happy Badger Beckham #267",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_267_1681445773.png",
                "rarity_score": 120.32623716291472,
                "rarity_level": "RARE"
            }
        }
    },
    "276": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/276_1681445774.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "276",
                "name": "Uptight Badger Mike #276",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445769,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_276_1681445774.png",
                "rarity_score": 74.00932176149148,
                "rarity_level": "COMMON"
            }
        }
    },
    "277": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/277_1681445774.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "277",
                "name": "Impressive Badger Beckham #277",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445769,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_277_1681445774.png",
                "rarity_score": 57.35593005315229,
                "rarity_level": "COMMON"
            }
        }
    },
    "264": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/264_1681445774.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "264",
                "name": "Jolly Badger Romeo #264",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_264_1681445774.png",
                "rarity_score": 114.05061136410067,
                "rarity_level": "RARE"
            }
        }
    },
    "270": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/270_1681445774.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "270",
                "name": "Adventurous Badger Norbert #270",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445767,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_270_1681445774.png",
                "rarity_score": 89.97554300408933,
                "rarity_level": "RARE"
            }
        }
    },
    "275": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/275_1681445775.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "275",
                "name": "Gifted Badger Stone #275",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445769,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_275_1681445775.png",
                "rarity_score": 234.5461594983526,
                "rarity_level": "EPIC"
            }
        }
    },
    "279": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/279_1681445775.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "279",
                "name": "Depressed Badger Bellatrix #279",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445769,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_279_1681445775.png",
                "rarity_score": 59.90892446107266,
                "rarity_level": "COMMON"
            }
        }
    },
    "266": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/266_1681445775.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "266",
                "name": "Elated Badger Khan #266",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445766,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_266_1681445775.png",
                "rarity_score": 127.74548311659099,
                "rarity_level": "RARE"
            }
        }
    },
    "273": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/273_1681445776.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "273",
                "name": "Brainy Badger Weasley #273",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445768,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_273_1681445776.png",
                "rarity_score": 73.45033765718837,
                "rarity_level": "COMMON"
            }
        }
    },
    "284": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/284_1681445777.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "284",
                "name": "Bright Badger Logan #284",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445771,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_284_1681445777.png",
                "rarity_score": 106.84635558271157,
                "rarity_level": "RARE"
            }
        }
    },
    "318": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/318_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "318",
                "name": "Funny Badger Fabian #318",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445778,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_318_1681445782.png",
                "rarity_score": 57.64373194806107,
                "rarity_level": "COMMON"
            }
        }
    },
    "348": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/348_1681445791.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "348",
                "name": "Daft Badger Jamieson #348",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_348_1681445791.png",
                "rarity_score": 109.4718304780894,
                "rarity_level": "RARE"
            }
        }
    },
    "397": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/397_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "397",
                "name": "Yucky Badger Wrackspurt #397",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_397_1681445801.png",
                "rarity_score": 64.88335717676757,
                "rarity_level": "COMMON"
            }
        }
    },
    "292": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/292_1681445777.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "292",
                "name": "Annoying Badger Brawley #292",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_292_1681445777.png",
                "rarity_score": 54.01073132486154,
                "rarity_level": "COMMON"
            }
        }
    },
    "319": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/319_1681445785.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "319",
                "name": "Confused Badger Elfie #319",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445778,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_319_1681445785.png",
                "rarity_score": 101.71564338173158,
                "rarity_level": "RARE"
            }
        }
    },
    "363": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/363_1681445792.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "363",
                "name": "Eager Badger Dallas #363",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_363_1681445792.png",
                "rarity_score": 88.38457245607648,
                "rarity_level": "COMMON"
            }
        }
    },
    "393": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/393_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "393",
                "name": "Bored Badger David #393",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_393_1681445802.png",
                "rarity_score": 66.88697267371319,
                "rarity_level": "COMMON"
            }
        }
    },
    "285": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/285_1681445777.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "285",
                "name": "Crazy Badger Bronn #285",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445772,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_285_1681445777.png",
                "rarity_score": 61.95955345915332,
                "rarity_level": "COMMON"
            }
        }
    },
    "320": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/320_1681445785.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "320",
                "name": "Cute Badger Finnigan #320",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445778,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_320_1681445785.png",
                "rarity_score": 107.91232613154645,
                "rarity_level": "RARE"
            }
        }
    },
    "361": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/361_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "361",
                "name": "Delightful Badger Lawrence #361",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_361_1681445790.png",
                "rarity_score": 73.38055284537926,
                "rarity_level": "COMMON"
            }
        }
    },
    "382": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/382_1681445798.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "382",
                "name": "Naughty Badger Minerva #382",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445792,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_382_1681445798.png",
                "rarity_score": 71.49195928985714,
                "rarity_level": "COMMON"
            }
        }
    },
    "290": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/290_1681445778.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "290",
                "name": "Jittery Badger Castiel #290",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445772,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_290_1681445778.png",
                "rarity_score": 60.9555383505714,
                "rarity_level": "COMMON"
            }
        }
    },
    "282": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/282_1681445778.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "282",
                "name": "Silly Badger Maxim #282",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445771,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_282_1681445778.png",
                "rarity_score": 106.58112975818639,
                "rarity_level": "RARE"
            }
        }
    },
    "322": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/322_1681445783.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "322",
                "name": "Lavish Badger Willow #322",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445778,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_322_1681445783.png",
                "rarity_score": 54.03113121375712,
                "rarity_level": "COMMON"
            }
        }
    },
    "321": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/321_1681445785.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "321",
                "name": "Precious Badger Rowan #321",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445778,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_321_1681445785.png",
                "rarity_score": 78.0940670820448,
                "rarity_level": "COMMON"
            }
        }
    },
    "289": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/289_1681445778.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "289",
                "name": "Lazy Badger Corin #289",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445772,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_289_1681445778.png",
                "rarity_score": 68.29942569172061,
                "rarity_level": "COMMON"
            }
        }
    },
    "323": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/323_1681445785.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "323",
                "name": "Lovely Badger Miguel #323",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445779,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_323_1681445785.png",
                "rarity_score": 97.68775889021309,
                "rarity_level": "RARE"
            }
        }
    },
    "362": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/362_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "362",
                "name": "Bookworm Badger Ishmael #362",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_362_1681445793.png",
                "rarity_score": 130.6836522529551,
                "rarity_level": "RARE"
            }
        }
    },
    "409": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/409_1681445804.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "409",
                "name": "Aggressive Badger Bogrod #409",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_409_1681445804.png",
                "rarity_score": 88.66126993013191,
                "rarity_level": "COMMON"
            }
        }
    },
    "293": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/293_1681445779.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "293",
                "name": "Homeless Badger Quentin #293",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_293_1681445779.png",
                "rarity_score": 61.400418626635606,
                "rarity_level": "COMMON"
            }
        }
    },
    "324": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/324_1681445787.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "324",
                "name": "Encouraging Badger Grindewald #324",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445780,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_324_1681445787.png",
                "rarity_score": 105.54267778832266,
                "rarity_level": "RARE"
            }
        }
    },
    "367": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/367_1681445792.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "367",
                "name": "Impressive Badger Ryker #367",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445787,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_367_1681445792.png",
                "rarity_score": 59.791423361403076,
                "rarity_level": "COMMON"
            }
        }
    },
    "392": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/392_1681445799.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "392",
                "name": "Healthy Badger Riddick #392",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_392_1681445799.png",
                "rarity_score": 52.23794249997063,
                "rarity_level": "COMMON"
            }
        }
    },
    "286": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/286_1681445779.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "286",
                "name": "Beautiful Badger Gatsby #286",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445772,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_286_1681445779.png",
                "rarity_score": 117.56017543953934,
                "rarity_level": "RARE"
            }
        }
    },
    "278": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/278_1681445778.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "278",
                "name": "Gifted Badger Cornelius #278",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445769,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_278_1681445778.png",
                "rarity_score": 294.7304641992253,
                "rarity_level": "EPIC"
            }
        }
    },
    "280": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/280_1681445779.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "280",
                "name": "Crazy Badger Norbert #280",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445770,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_280_1681445779.png",
                "rarity_score": 131.32323679987087,
                "rarity_level": "RARE"
            }
        }
    },
    "327": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/327_1681445784.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "327",
                "name": "Odd Badger Max #327",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445780,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_327_1681445784.png",
                "rarity_score": 60.73295525894822,
                "rarity_level": "COMMON"
            }
        }
    },
    "299": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/299_1681445779.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "299",
                "name": "Envious Badger Rubin #299",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_299_1681445779.png",
                "rarity_score": 60.42192107409499,
                "rarity_level": "COMMON"
            }
        }
    },
    "281": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/281_1681445779.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "281",
                "name": "Calm Badger Pettigrew #281",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445770,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_281_1681445779.png",
                "rarity_score": 111.29532130166248,
                "rarity_level": "RARE"
            }
        }
    },
    "328": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/328_1681445787.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "328",
                "name": "Contagious Badger Jones #328",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445780,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_328_1681445787.png",
                "rarity_score": 80.34958051776482,
                "rarity_level": "COMMON"
            }
        }
    },
    "329": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/329_1681445788.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "329",
                "name": "Determined Badger Leonardo #329",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445780,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_329_1681445788.png",
                "rarity_score": 190.70512696448708,
                "rarity_level": "RARE"
            }
        }
    },
    "287": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/287_1681445780.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "287",
                "name": "Grumpy Badger Argus #287",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445772,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_287_1681445780.png",
                "rarity_score": 71.03845263783344,
                "rarity_level": "COMMON"
            }
        }
    },
    "330": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/330_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "330",
                "name": "HODL Badger Boot #330",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445781,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_330_1681445790.png",
                "rarity_score": 106.51019630289345,
                "rarity_level": "RARE"
            }
        }
    },
    "384": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/384_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "384",
                "name": "Tense Badger Mundungus #384",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445793,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_384_1681445797.png",
                "rarity_score": 60.83446135612956,
                "rarity_level": "COMMON"
            }
        }
    },
    "419": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/419_1681445803.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "419",
                "name": "Calm Badger Percy #419",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_419_1681445803.png",
                "rarity_score": 56.46796642738057,
                "rarity_level": "COMMON"
            }
        }
    },
    "291": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/291_1681445780.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "291",
                "name": "Fantastic Badger Basilisk #291",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_291_1681445780.png",
                "rarity_score": 249.050802042673,
                "rarity_level": "EPIC"
            }
        }
    },
    "331": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/331_1681445789.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "331",
                "name": "Funny Badger Moby #331",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445781,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_331_1681445789.png",
                "rarity_score": 111.87973511861159,
                "rarity_level": "RARE"
            }
        }
    },
    "376": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/376_1681445795.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "376",
                "name": "Mysterious Badger Trevor #376",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445790,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_376_1681445795.png",
                "rarity_score": 74.20679149261272,
                "rarity_level": "COMMON"
            }
        }
    },
    "410": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/410_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "410",
                "name": "Energetic Badger Boot #410",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_410_1681445801.png",
                "rarity_score": 53.4059959681511,
                "rarity_level": "COMMON"
            }
        }
    },
    "294": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/294_1681445780.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "294",
                "name": "Quaint Badger Sugar Ray #294",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_294_1681445780.png",
                "rarity_score": 61.63679243402876,
                "rarity_level": "COMMON"
            }
        }
    },
    "332": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/332_1681445788.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "332",
                "name": "Annoying Badger Centaur #332",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445781,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_332_1681445788.png",
                "rarity_score": 128.5751991300825,
                "rarity_level": "RARE"
            }
        }
    },
    "369": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/369_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "369",
                "name": "Precious Badger Marcus #369",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445789,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_369_1681445793.png",
                "rarity_score": 221.48835678338554,
                "rarity_level": "RARE"
            }
        }
    },
    "403": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/403_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "403",
                "name": "Easy Badger Aberforth #403",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_403_1681445801.png",
                "rarity_score": 91.77085379606388,
                "rarity_level": "RARE"
            }
        }
    },
    "307": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/307_1681445780.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "307",
                "name": "Blushing Badger Kingston #307",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_307_1681445780.png",
                "rarity_score": 63.46947384138797,
                "rarity_level": "COMMON"
            }
        }
    },
    "333": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/333_1681445786.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "333",
                "name": "Quaint Badger Archer #333",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445781,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_333_1681445786.png",
                "rarity_score": 56.78008095064011,
                "rarity_level": "COMMON"
            }
        }
    },
    "365": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/365_1681445791.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "365",
                "name": "Helpful Badger Jasper #365",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_365_1681445791.png",
                "rarity_score": 55.58484025394629,
                "rarity_level": "COMMON"
            }
        }
    },
    "388": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/388_1681445799.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "388",
                "name": "Jittery Badger Joe #388",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445793,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_388_1681445799.png",
                "rarity_score": 78.90004287036894,
                "rarity_level": "COMMON"
            }
        }
    },
    "312": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/312_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "312",
                "name": "Wen Badger Noah #312",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445775,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_312_1681445781.png",
                "rarity_score": 60.50622090932879,
                "rarity_level": "COMMON"
            }
        }
    },
    "334": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/334_1681445788.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "334",
                "name": "Quaint Badger Jarvis #334",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445782,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_334_1681445788.png",
                "rarity_score": 57.779864377318994,
                "rarity_level": "COMMON"
            }
        }
    },
    "370": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/370_1681445795.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "370",
                "name": "Shy Badger Lomachenko #370",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445789,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_370_1681445795.png",
                "rarity_score": 75.83941187582495,
                "rarity_level": "COMMON"
            }
        }
    },
    "412": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/412_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "412",
                "name": "Expensive Badger Gilderoy #412",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_412_1681445802.png",
                "rarity_score": 65.21147961213089,
                "rarity_level": "COMMON"
            }
        }
    },
    "311": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/311_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "311",
                "name": "Lonely Badger Deontay #311",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445775,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_311_1681445781.png",
                "rarity_score": 64.4421877621894,
                "rarity_level": "COMMON"
            }
        }
    },
    "336": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/336_1681445788.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "336",
                "name": "Super Badger Christer #336",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445783,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_336_1681445788.png",
                "rarity_score": 70.0016251462432,
                "rarity_level": "COMMON"
            }
        }
    },
    "374": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/374_1681445798.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "374",
                "name": "Helpful Badger Rhett #374",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445789,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_374_1681445798.png",
                "rarity_score": 90.21052483127059,
                "rarity_level": "RARE"
            }
        }
    },
    "422": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/422_1681445806.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "422",
                "name": "Clammy Badger Benedick #422",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445799,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_422_1681445806.png",
                "rarity_score": 100.05963151033575,
                "rarity_level": "RARE"
            }
        }
    },
    "301": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/301_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "301",
                "name": "Bewildered Badger Errol #301",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_301_1681445781.png",
                "rarity_score": 99.80496862160243,
                "rarity_level": "RARE"
            }
        }
    },
    "335": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/335_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "335",
                "name": "Fine Badger Peverell #335",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445782,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_335_1681445790.png",
                "rarity_score": 110.63347420527708,
                "rarity_level": "RARE"
            }
        }
    },
    "387": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/387_1681445799.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "387",
                "name": "Happy Badger Jack #387",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445793,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_387_1681445799.png",
                "rarity_score": 95.71522993272262,
                "rarity_level": "RARE"
            }
        }
    },
    "424": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/424_1681445804.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "424",
                "name": "Daft Badger Troy #424",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445800,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_424_1681445804.png",
                "rarity_score": 55.08636900325015,
                "rarity_level": "COMMON"
            }
        }
    },
    "302": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/302_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "302",
                "name": "Wicked Badger Fudge #302",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_302_1681445781.png",
                "rarity_score": 85.92567762916858,
                "rarity_level": "COMMON"
            }
        }
    },
    "337": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/337_1681445788.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "337",
                "name": "FUD Badger Nimbus #337",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445783,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_337_1681445788.png",
                "rarity_score": 92.82250784719756,
                "rarity_level": "RARE"
            }
        }
    },
    "372": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/372_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "372",
                "name": "Confused Badger Credence #372",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445789,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_372_1681445797.png",
                "rarity_score": 84.63971354116262,
                "rarity_level": "COMMON"
            }
        }
    },
    "417": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/417_1681445804.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "417",
                "name": "Delightful Badger Horace #417",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_417_1681445804.png",
                "rarity_score": 243.39891230051413,
                "rarity_level": "EPIC"
            }
        }
    },
    "288": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/288_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "288",
                "name": "Ill Badger Lomachenko #288",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445772,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_288_1681445781.png",
                "rarity_score": 77.16501870139288,
                "rarity_level": "COMMON"
            }
        }
    },
    "339": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/339_1681445791.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "339",
                "name": "Uptight Badger Aberforth #339",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445783,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_339_1681445791.png",
                "rarity_score": 81.84203460815691,
                "rarity_level": "COMMON"
            }
        }
    },
    "389": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/389_1681445800.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "389",
                "name": "Obnoxious Badger Brawley #389",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445793,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_389_1681445800.png",
                "rarity_score": 72.85838138622107,
                "rarity_level": "COMMON"
            }
        }
    },
    "432": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/432_1681445809.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "432",
                "name": "Fantastic Badger Cullen #432",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445802,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_432_1681445809.png",
                "rarity_score": 79.86124542025226,
                "rarity_level": "COMMON"
            }
        }
    },
    "296": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/296_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "296",
                "name": "Clean Badger Ishmael #296",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_296_1681445781.png",
                "rarity_score": 161.28418665917505,
                "rarity_level": "RARE"
            }
        }
    },
    "338": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/338_1681445791.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "338",
                "name": "Gifted Badger Clay #338",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445783,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_338_1681445791.png",
                "rarity_score": 103.44791600767127,
                "rarity_level": "RARE"
            }
        }
    },
    "395": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/395_1681445803.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "395",
                "name": "Envious Badger Bones #395",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_395_1681445803.png",
                "rarity_score": 135.91413962607362,
                "rarity_level": "RARE"
            }
        }
    },
    "452": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/452_1681445814.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "452",
                "name": "Dopey Badger Manny #452",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_452_1681445814.png",
                "rarity_score": 263.08363713874525,
                "rarity_level": "EPIC"
            }
        }
    },
    "305": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/305_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "305",
                "name": "Delightful Badger Cruz #305",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_305_1681445781.png",
                "rarity_score": 67.15133579859433,
                "rarity_level": "COMMON"
            }
        }
    },
    "340": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/340_1681445789.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "340",
                "name": "Cryptic Badger Bertie #340",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445783,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_340_1681445789.png",
                "rarity_score": 61.9768516799032,
                "rarity_level": "COMMON"
            }
        }
    },
    "375": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/375_1681445795.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "375",
                "name": "Daft Badger Killian #375",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445790,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_375_1681445795.png",
                "rarity_score": 58.208963488010355,
                "rarity_level": "COMMON"
            }
        }
    },
    "407": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/407_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "407",
                "name": "Misty Badger Aragog #407",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_407_1681445801.png",
                "rarity_score": 61.26910256643777,
                "rarity_level": "COMMON"
            }
        }
    },
    "283": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/283_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "283",
                "name": "Cute Badger Rudy #283",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445771,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_283_1681445781.png",
                "rarity_score": 122.46182990587752,
                "rarity_level": "RARE"
            }
        }
    },
    "341": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/341_1681445792.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "341",
                "name": "Unusual Badger Dursley #341",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445783,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_341_1681445792.png",
                "rarity_score": 406.2412226934791,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "390": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/390_1681445799.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "390",
                "name": "Kind Badger Max #390",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445794,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_390_1681445799.png",
                "rarity_score": 50.46660555683112,
                "rarity_level": "COMMON"
            }
        }
    },
    "425": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/425_1681445806.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "425",
                "name": "Unsightly Badger Jess #425",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445800,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_425_1681445806.png",
                "rarity_score": 114.913863882946,
                "rarity_level": "RARE"
            }
        }
    },
    "304": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/304_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "304",
                "name": "Dead Badger Trevor #304",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_304_1681445782.png",
                "rarity_score": 79.08139517527154,
                "rarity_level": "COMMON"
            }
        }
    },
    "342": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/342_1681445792.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "342",
                "name": "Crazy Badger Dursley #342",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_342_1681445792.png",
                "rarity_score": 172.44470231310305,
                "rarity_level": "RARE"
            }
        }
    },
    "398": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/398_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "398",
                "name": "Elegant Badger Dursley #398",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_398_1681445801.png",
                "rarity_score": 161.465755815142,
                "rarity_level": "RARE"
            }
        }
    },
    "436": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/436_1681445807.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "436",
                "name": "Bloody Badger Maxton #436",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445802,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_436_1681445807.png",
                "rarity_score": 54.19258899818859,
                "rarity_level": "COMMON"
            }
        }
    },
    "298": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/298_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "298",
                "name": "Anal Badger Julius #298",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_298_1681445781.png",
                "rarity_score": 78.6237248341074,
                "rarity_level": "COMMON"
            }
        }
    },
    "343": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/343_1681445788.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "343",
                "name": "Wicked Badger Ely #343",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_343_1681445788.png",
                "rarity_score": 59.0551306982077,
                "rarity_level": "COMMON"
            }
        }
    },
    "371": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/371_1681445795.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "371",
                "name": "Confused Badger Cruz #371",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445789,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_371_1681445795.png",
                "rarity_score": 71.96582337297242,
                "rarity_level": "COMMON"
            }
        }
    },
    "408": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/408_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "408",
                "name": "Precious Badger Bridger #408",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_408_1681445801.png",
                "rarity_score": 72.23486131309308,
                "rarity_level": "COMMON"
            }
        }
    },
    "297": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/297_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "297",
                "name": "Fierce Badger Wentworth #297",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_297_1681445781.png",
                "rarity_score": 132.04193454959395,
                "rarity_level": "RARE"
            }
        }
    },
    "344": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/344_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "344",
                "name": "Cruel Badger Jones #344",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_344_1681445790.png",
                "rarity_score": 130.69152932906348,
                "rarity_level": "RARE"
            }
        }
    },
    "385": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/385_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "385",
                "name": "Moon Badger Grimmauld #385",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445793,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_385_1681445797.png",
                "rarity_score": 80.42243815921138,
                "rarity_level": "COMMON"
            }
        }
    },
    "420": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/420_1681445805.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "420",
                "name": "Disgusted Badger Moby #420",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_420_1681445805.png",
                "rarity_score": 102.31234646728689,
                "rarity_level": "RARE"
            }
        }
    },
    "314": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/314_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "314",
                "name": "Kind Badger Quirrel #314",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445776,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_314_1681445782.png",
                "rarity_score": 71.33582429609831,
                "rarity_level": "COMMON"
            }
        }
    },
    "345": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/345_1681445789.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "345",
                "name": "Real Badger Krum #345",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_345_1681445789.png",
                "rarity_score": 65.3871311273529,
                "rarity_level": "COMMON"
            }
        }
    },
    "377": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/377_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "377",
                "name": "Confused Badger Gulliver #377",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445791,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_377_1681445797.png",
                "rarity_score": 64.00310144827633,
                "rarity_level": "COMMON"
            }
        }
    },
    "416": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/416_1681445805.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "416",
                "name": "Unsightly Badger Mayweather #416",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_416_1681445805.png",
                "rarity_score": 128.76750080385625,
                "rarity_level": "RARE"
            }
        }
    },
    "313": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/313_1681445781.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "313",
                "name": "Lively Badger Lumos #313",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445775,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_313_1681445781.png",
                "rarity_score": 81.80127254149431,
                "rarity_level": "COMMON"
            }
        }
    },
    "346": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/346_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "346",
                "name": "Magnificent Badger Rubin #346",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_346_1681445790.png",
                "rarity_score": 93.35132766453489,
                "rarity_level": "RARE"
            }
        }
    },
    "380": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/380_1681445798.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "380",
                "name": "Tired Badger Leonard #380",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445792,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_380_1681445798.png",
                "rarity_score": 84.57203625044895,
                "rarity_level": "COMMON"
            }
        }
    },
    "423": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/423_1681445805.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "423",
                "name": "Homeless Badger Gannon #423",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445799,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_423_1681445805.png",
                "rarity_score": 74.97437050850183,
                "rarity_level": "COMMON"
            }
        }
    },
    "315": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/315_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "315",
                "name": "Clammy Badger David #315",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445776,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_315_1681445782.png",
                "rarity_score": 256.6704319752316,
                "rarity_level": "EPIC"
            }
        }
    },
    "347": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/347_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "347",
                "name": "Pleasant Badger Cruz #347",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_347_1681445790.png",
                "rarity_score": 72.65224555049838,
                "rarity_level": "COMMON"
            }
        }
    },
    "383": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/383_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "383",
                "name": "Tired Badger Leonardo #383",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445792,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_383_1681445801.png",
                "rarity_score": 84.07229467350864,
                "rarity_level": "COMMON"
            }
        }
    },
    "435": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/435_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "435",
                "name": "Nice Badger Muggle #435",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445802,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_435_1681445808.png",
                "rarity_score": 118.43164674685822,
                "rarity_level": "RARE"
            }
        }
    },
    "317": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/317_1681445783.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "317",
                "name": "Awful Badger Sphynx #317",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445777,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_317_1681445783.png",
                "rarity_score": 76.74187570198583,
                "rarity_level": "COMMON"
            }
        }
    },
    "349": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/349_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "349",
                "name": "Gleaming Badger Tiberius #349",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_349_1681445790.png",
                "rarity_score": 63.923182931162714,
                "rarity_level": "COMMON"
            }
        }
    },
    "379": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/379_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "379",
                "name": "Crazy Badger Grey #379",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445792,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_379_1681445797.png",
                "rarity_score": 59.463968676969166,
                "rarity_level": "COMMON"
            }
        }
    },
    "414": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/414_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "414",
                "name": "Clean Badger Sugar #414",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_414_1681445802.png",
                "rarity_score": 53.07284464696221,
                "rarity_level": "COMMON"
            }
        }
    },
    "309": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/309_1681445783.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "309",
                "name": "Happy Badger Sir Nicholaus #309",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445775,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_309_1681445783.png",
                "rarity_score": 63.360039734726605,
                "rarity_level": "COMMON"
            }
        }
    },
    "350": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/350_1681445791.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "350",
                "name": "Proud Badger Gulliver #350",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445784,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_350_1681445791.png",
                "rarity_score": 78.89235260133407,
                "rarity_level": "COMMON"
            }
        }
    },
    "396": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/396_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "396",
                "name": "Grieving Badger Jack #396",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_396_1681445801.png",
                "rarity_score": 91.86524711209907,
                "rarity_level": "RARE"
            }
        }
    },
    "434": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/434_1681445809.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "434",
                "name": "Dizzy Badger Lee #434",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445802,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_434_1681445809.png",
                "rarity_score": 83.87769181039535,
                "rarity_level": "COMMON"
            }
        }
    },
    "295": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/295_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "295",
                "name": "Powerful Badger Minerva #295",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445773,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_295_1681445782.png",
                "rarity_score": 312.87536329141903,
                "rarity_level": "EPIC"
            }
        }
    },
    "351": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/351_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "351",
                "name": "Bookworm Badger Troy #351",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_351_1681445793.png",
                "rarity_score": 153.4159757673875,
                "rarity_level": "RARE"
            }
        }
    },
    "402": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/402_1681445800.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "402",
                "name": "Doubtful Badger Dobby #402",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_402_1681445800.png",
                "rarity_score": 60.45903742208175,
                "rarity_level": "COMMON"
            }
        }
    },
    "429": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/429_1681445807.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "429",
                "name": "Ashamed Badger Neville #429",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445801,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_429_1681445807.png",
                "rarity_score": 69.21263486295376,
                "rarity_level": "COMMON"
            }
        }
    },
    "310": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/310_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "310",
                "name": "Odd Badger Felix #310",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445775,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_310_1681445782.png",
                "rarity_score": 195.31918711163993,
                "rarity_level": "RARE"
            }
        }
    },
    "352": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/352_1681445792.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "352",
                "name": "Arrogant Badger Charlie #352",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_352_1681445792.png",
                "rarity_score": 68.80426558603234,
                "rarity_level": "COMMON"
            }
        }
    },
    "394": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/394_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "394",
                "name": "Ill Badger Peverell #394",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_394_1681445802.png",
                "rarity_score": 110.56869246188913,
                "rarity_level": "RARE"
            }
        }
    },
    "442": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/442_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "442",
                "name": "Determined Badger Krum #442",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_442_1681445808.png",
                "rarity_score": 59.361259655377296,
                "rarity_level": "COMMON"
            }
        }
    },
    "316": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/316_1681445782.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "316",
                "name": "Dull Badger Jamieson #316",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445776,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_316_1681445782.png",
                "rarity_score": 188.52735105125709,
                "rarity_level": "RARE"
            }
        }
    },
    "355": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/355_1681445792.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "355",
                "name": "Faithful Badger Richard #355",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_355_1681445792.png",
                "rarity_score": 117.75028506335693,
                "rarity_level": "RARE"
            }
        }
    },
    "391": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/391_1681445799.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "391",
                "name": "Charming Badger Centaur #391",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_391_1681445799.png",
                "rarity_score": 53.50366966833555,
                "rarity_level": "COMMON"
            }
        }
    },
    "426": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/426_1681445805.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "426",
                "name": "Depressed Badger Mary #426",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445800,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_426_1681445805.png",
                "rarity_score": 58.90379885068934,
                "rarity_level": "COMMON"
            }
        }
    },
    "308": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/308_1681445783.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "308",
                "name": "Courageous Badger Mary #308",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445775,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_308_1681445783.png",
                "rarity_score": 152.47331874922617,
                "rarity_level": "RARE"
            }
        }
    },
    "354": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/354_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "354",
                "name": "Encouraging Badger Basilisk #354",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_354_1681445793.png",
                "rarity_score": 140.61775107130347,
                "rarity_level": "RARE"
            }
        }
    },
    "401": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/401_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "401",
                "name": "FUD Badger Roy #401",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_401_1681445801.png",
                "rarity_score": 84.4922360399018,
                "rarity_level": "COMMON"
            }
        }
    },
    "446": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/446_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "446",
                "name": "Clammy Badger Brawley #446",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_446_1681445808.png",
                "rarity_score": 68.69175668542454,
                "rarity_level": "COMMON"
            }
        }
    },
    "306": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/306_1681445783.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "306",
                "name": "Homeless Badger Peverell #306",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_306_1681445783.png",
                "rarity_score": 121.14314357415364,
                "rarity_level": "RARE"
            }
        }
    },
    "356": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/356_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "356",
                "name": "Foolish Badger Hermione #356",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_356_1681445793.png",
                "rarity_score": 86.78168876389559,
                "rarity_level": "COMMON"
            }
        }
    },
    "404": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/404_1681445800.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "404",
                "name": "Nice Badger Marcus #404",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_404_1681445800.png",
                "rarity_score": 56.13423419619104,
                "rarity_level": "COMMON"
            }
        }
    },
    "431": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/431_1681445807.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "431",
                "name": "Helpless Badger Cecil #431",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445801,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_431_1681445807.png",
                "rarity_score": 102.24164012832483,
                "rarity_level": "RARE"
            }
        }
    },
    "303": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/303_1681445783.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "303",
                "name": "Gleaming Badger Grey #303",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_303_1681445783.png",
                "rarity_score": 101.41156089360777,
                "rarity_level": "RARE"
            }
        }
    },
    "357": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/357_1681445794.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "357",
                "name": "Disgusted Badger Sphynx #357",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_357_1681445794.png",
                "rarity_score": 165.40860263069123,
                "rarity_level": "RARE"
            }
        }
    },
    "411": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/411_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "411",
                "name": "Happy Badger Evander #411",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_411_1681445802.png",
                "rarity_score": 85.11169768177332,
                "rarity_level": "COMMON"
            }
        }
    },
    "451": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/451_1681445813.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "451",
                "name": "Prickly Badger Mandrake #451",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_451_1681445813.png",
                "rarity_score": 446.1459427636992,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "300": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/300_1681445784.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "300",
                "name": "Disgusted Badger Sugar #300",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445774,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_300_1681445784.png",
                "rarity_score": 138.1804596150964,
                "rarity_level": "RARE"
            }
        }
    },
    "359": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/359_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "359",
                "name": "Uptight Badger Niffler #359",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_359_1681445790.png",
                "rarity_score": 53.84386699839249,
                "rarity_level": "COMMON"
            }
        }
    },
    "381": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/381_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "381",
                "name": "Jittery Badger Hufflepuff #381",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445792,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_381_1681445797.png",
                "rarity_score": 55.81915308343302,
                "rarity_level": "COMMON"
            }
        }
    },
    "415": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/415_1681445804.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "415",
                "name": "Wandering Badger Atticus #415",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_415_1681445804.png",
                "rarity_score": 61.23471638457643,
                "rarity_level": "COMMON"
            }
        }
    },
    "326": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/326_1681445785.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "326",
                "name": "Evil Badger Harry Potter #326",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445780,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_326_1681445785.png",
                "rarity_score": 278.6659190992026,
                "rarity_level": "EPIC"
            }
        }
    },
    "360": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/360_1681445794.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "360",
                "name": "Wicked Badger Lucius #360",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_360_1681445794.png",
                "rarity_score": 107.89525742277499,
                "rarity_level": "RARE"
            }
        }
    },
    "406": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/406_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "406",
                "name": "Silly Badger Joe #406",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_406_1681445802.png",
                "rarity_score": 70.05268023671329,
                "rarity_level": "COMMON"
            }
        }
    },
    "443": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/443_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "443",
                "name": "Lambo Badger Klitschko #443",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_443_1681445808.png",
                "rarity_score": 57.047402186681126,
                "rarity_level": "COMMON"
            }
        }
    },
    "325": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/325_1681445787.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "325",
                "name": "Amused Badger Rufus #325",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445780,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_325_1681445787.png",
                "rarity_score": 163.7606673193265,
                "rarity_level": "RARE"
            }
        }
    },
    "366": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/366_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "366",
                "name": "Confused Badger Salem #366",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445787,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_366_1681445793.png",
                "rarity_score": 112.98639461649668,
                "rarity_level": "RARE"
            }
        }
    },
    "399": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/399_1681445801.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "399",
                "name": "Brave Badger Grindewald #399",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_399_1681445801.png",
                "rarity_score": 75.13067827359697,
                "rarity_level": "COMMON"
            }
        }
    },
    "441": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/441_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "441",
                "name": "Magnificent Badger Augustus #441",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445803,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_441_1681445810.png",
                "rarity_score": 79.63217642984493,
                "rarity_level": "COMMON"
            }
        }
    },
    "358": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/358_1681445790.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "358",
                "name": "Clever Badger Diggory #358",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_358_1681445790.png",
                "rarity_score": 58.41918953679177,
                "rarity_level": "COMMON"
            }
        }
    },
    "378": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/378_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "378",
                "name": "Colorful Badger Calvin #378",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445792,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_378_1681445797.png",
                "rarity_score": 73.99111283677387,
                "rarity_level": "COMMON"
            }
        }
    },
    "418": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/418_1681445803.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "418",
                "name": "Disgusted Badger Tiberius #418",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445798,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_418_1681445803.png",
                "rarity_score": 56.13868092691622,
                "rarity_level": "COMMON"
            }
        }
    },
    "449": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/449_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "449",
                "name": "Shill Badger Croix #449",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_449_1681445810.png",
                "rarity_score": 72.80654375530428,
                "rarity_level": "COMMON"
            }
        }
    },
    "364": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/364_1681445791.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "364",
                "name": "Wicked Badger Wladimir #364",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445786,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_364_1681445791.png",
                "rarity_score": 69.74684697757746,
                "rarity_level": "COMMON"
            }
        }
    },
    "386": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/386_1681445800.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "386",
                "name": "Unusual Badger Christopher #386",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445793,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_386_1681445800.png",
                "rarity_score": 144.5076562114147,
                "rarity_level": "RARE"
            }
        }
    },
    "430": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/430_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "430",
                "name": "Dangerous Badger Muggle #430",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445801,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_430_1681445808.png",
                "rarity_score": 102.33242432039748,
                "rarity_level": "RARE"
            }
        }
    },
    "470": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/470_1681445814.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "470",
                "name": "Repulsive Badger Braham #470",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445808,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_470_1681445814.png",
                "rarity_score": 65.34947076347363,
                "rarity_level": "COMMON"
            }
        }
    },
    "353": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/353_1681445793.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "353",
                "name": "Yucky Badger Rhett #353",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445785,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_353_1681445793.png",
                "rarity_score": 228.53755776759775,
                "rarity_level": "EPIC"
            }
        }
    },
    "400": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/400_1681445802.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "400",
                "name": "Crazy Badger Holden #400",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445795,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_400_1681445802.png",
                "rarity_score": 73.64181106087551,
                "rarity_level": "COMMON"
            }
        }
    },
    "444": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/444_1681445811.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "444",
                "name": "Hungry Badger Rubeus #444",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_444_1681445811.png",
                "rarity_score": 334.4818018598006,
                "rarity_level": "EPIC"
            }
        }
    },
    "496": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/496_1681445818.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "496",
                "name": "Contagious Badger Deontay #496",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445813,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_496_1681445818.png",
                "rarity_score": 66.48196405907245,
                "rarity_level": "COMMON"
            }
        }
    },
    "368": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/368_1681445794.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "368",
                "name": "Impressive Badger Edmund #368",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445788,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_368_1681445794.png",
                "rarity_score": 64.55499557308876,
                "rarity_level": "COMMON"
            }
        }
    },
    "405": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/405_1681445803.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "405",
                "name": "Dead Badger Dean #405",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445796,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_405_1681445803.png",
                "rarity_score": 106.91490823590816,
                "rarity_level": "RARE"
            }
        }
    },
    "447": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/447_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "447",
                "name": "Evil Badger Dudley #447",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_447_1681445808.png",
                "rarity_score": 56.053915666341176,
                "rarity_level": "COMMON"
            }
        }
    },
    "476": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/476_1681445814.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "476",
                "name": "Dizzy Badger Sphynx #476",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445810,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_476_1681445814.png",
                "rarity_score": 55.819322120226126,
                "rarity_level": "COMMON"
            }
        }
    },
    "373": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/373_1681445797.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "373",
                "name": "Scary Badger Diggory #373",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445789,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_373_1681445797.png",
                "rarity_score": 94.73565380085958,
                "rarity_level": "RARE"
            }
        }
    },
    "413": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/413_1681445804.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "413",
                "name": "Fantastic Badger Quillon #413",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445797,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_413_1681445804.png",
                "rarity_score": 71.70837051120577,
                "rarity_level": "COMMON"
            }
        }
    },
    "456": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/456_1681445811.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "456",
                "name": "Horrible Badger Cannon #456",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_456_1681445811.png",
                "rarity_score": 64.38830018513929,
                "rarity_level": "COMMON"
            }
        }
    },
    "493": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/493_1681445818.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "493",
                "name": "Bloody Badger Duncan #493",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_493_1681445818.png",
                "rarity_score": 62.09470267241756,
                "rarity_level": "COMMON"
            }
        }
    },
    "427": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/427_1681445806.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "427",
                "name": "Embarrassed Badger Johnson #427",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445800,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_427_1681445806.png",
                "rarity_score": 259.8637316561845,
                "rarity_level": "EPIC"
            }
        }
    },
    "465": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/465_1681445813.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "465",
                "name": "Stupid Badger Trevor #465",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445807,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_465_1681445813.png",
                "rarity_score": 61.67236396797044,
                "rarity_level": "COMMON"
            }
        }
    },
    "499": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/499_1681445819.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "499",
                "name": "Asthmatic Badger Pettigrew #499",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445813,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_499_1681445819.png",
                "rarity_score": 59.5978835978836,
                "rarity_level": "COMMON"
            }
        }
    },
    "1470": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1470_1681446008.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1470",
                "name": "Fair Badger Grimmauld #1470",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1470_1681446008.png",
                "rarity_score": 92.83731136049597,
                "rarity_level": "RARE"
            }
        }
    },
    "440": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/440_1681445807.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "440",
                "name": "Bewildered Badger Quentin #440",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445803,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_440_1681445807.png",
                "rarity_score": 69.90290789397497,
                "rarity_level": "COMMON"
            }
        }
    },
    "469": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/469_1681445815.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "469",
                "name": "Carebear Badger Marcus #469",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445808,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_469_1681445815.png",
                "rarity_score": 75.85108092878565,
                "rarity_level": "COMMON"
            }
        }
    },
    "1471": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1471_1681446008.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1471",
                "name": "Dead Badger Cruz #1471",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1471_1681446008.png",
                "rarity_score": 233.865898463599,
                "rarity_level": "EPIC"
            }
        }
    },
    "1522": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1522_1681446015.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1522",
                "name": "Rekt Badger Johnson #1522",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1522_1681446015.png",
                "rarity_score": 63.18749125497407,
                "rarity_level": "COMMON"
            }
        }
    },
    "438": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/438_1681445808.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "438",
                "name": "Lively Badger Lomachenko #438",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445803,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_438_1681445808.png",
                "rarity_score": 89.91456059706906,
                "rarity_level": "RARE"
            }
        }
    },
    "474": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/474_1681445817.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "474",
                "name": "Doubtful Badger Khan #474",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445809,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_474_1681445817.png",
                "rarity_score": 70.58843801214235,
                "rarity_level": "COMMON"
            }
        }
    },
    "1474": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1474_1681446008.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1474",
                "name": "Bewildered Badger Severus #1474",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1474_1681446008.png",
                "rarity_score": 117.57321135852382,
                "rarity_level": "RARE"
            }
        }
    },
    "1523": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1523_1681446016.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1523",
                "name": "Creepy Badger Bingley #1523",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446009,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1523_1681446016.png",
                "rarity_score": 92.93694649125818,
                "rarity_level": "RARE"
            }
        }
    },
    "433": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/433_1681445809.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "433",
                "name": "Dead Badger Muggle #433",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445802,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_433_1681445809.png",
                "rarity_score": 80.72933157528225,
                "rarity_level": "COMMON"
            }
        }
    },
    "478": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/478_1681445818.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "478",
                "name": "Lively Badger Fox #478",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445810,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_478_1681445818.png",
                "rarity_score": 85.97332445377242,
                "rarity_level": "COMMON"
            }
        }
    },
    "1484": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1484_1681446009.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1484",
                "name": "Unsightly Badger Foreman #1484",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446001,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1484_1681446009.png",
                "rarity_score": 117.23900969279693,
                "rarity_level": "RARE"
            }
        }
    },
    "1525": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1525_1681446016.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1525",
                "name": "Nervous Badger Winston #1525",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446009,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1525_1681446016.png",
                "rarity_score": 79.42609764766311,
                "rarity_level": "COMMON"
            }
        }
    },
    "421": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/421_1681445809.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "421",
                "name": "Disgusted Badger Miguel #421",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445799,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_421_1681445809.png",
                "rarity_score": 165.05989794042387,
                "rarity_level": "RARE"
            }
        }
    },
    "480": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/480_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "480",
                "name": "Precious Badger Rudy #480",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445811,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_480_1681445820.png",
                "rarity_score": 227.00734863869005,
                "rarity_level": "EPIC"
            }
        }
    },
    "1487": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1487_1681446009.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1487",
                "name": "Depressed Badger Collin #1487",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446002,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1487_1681446009.png",
                "rarity_score": 77.5872384479791,
                "rarity_level": "COMMON"
            }
        }
    },
    "1526": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1526_1681446017.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1526",
                "name": "Terrible Badger Scorpius #1526",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446010,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1526_1681446017.png",
                "rarity_score": 95.23412707664329,
                "rarity_level": "RARE"
            }
        }
    },
    "445": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/445_1681445809.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "445",
                "name": "Elegant Badger Fawkes #445",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_445_1681445809.png",
                "rarity_score": 59.404860096003766,
                "rarity_level": "COMMON"
            }
        }
    },
    "481": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/481_1681445817.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "481",
                "name": "Bashful Badger Tiberius #481",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445811,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_481_1681445817.png",
                "rarity_score": 94.16597049891354,
                "rarity_level": "RARE"
            }
        }
    },
    "1473": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1473_1681446010.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1473",
                "name": "Depressed Badger Thestral #1473",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445999,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1473_1681446010.png",
                "rarity_score": 121.72958702110421,
                "rarity_level": "RARE"
            }
        }
    },
    "1528": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1528_1681446016.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1528",
                "name": "Strange Badger Errol #1528",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1528_1681446016.png",
                "rarity_score": 189.7136868354679,
                "rarity_level": "RARE"
            }
        }
    },
    "428": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/428_1681445809.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "428",
                "name": "Rich Badger Carter #428",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445800,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_428_1681445809.png",
                "rarity_score": 115.33617289939309,
                "rarity_level": "RARE"
            }
        }
    },
    "483": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/483_1681445818.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "483",
                "name": "Envious Badger Lewis #483",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445811,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_483_1681445818.png",
                "rarity_score": 89.73973953552472,
                "rarity_level": "RARE"
            }
        }
    },
    "1488": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1488_1681446011.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1488",
                "name": "Attractive Badger Cayman #1488",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446002,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1488_1681446011.png",
                "rarity_score": 117.85928747908592,
                "rarity_level": "RARE"
            }
        }
    },
    "1535": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1535_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1535",
                "name": "Bot Badger Landon #1535",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1535_1681446018.png",
                "rarity_score": 113.72374947617966,
                "rarity_level": "RARE"
            }
        }
    },
    "439": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/439_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "439",
                "name": "Dead Badger Asher #439",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445803,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_439_1681445810.png",
                "rarity_score": 93.7366048941679,
                "rarity_level": "RARE"
            }
        }
    },
    "484": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/484_1681445819.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "484",
                "name": "Cute Badger Jamieson #484",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445811,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_484_1681445819.png",
                "rarity_score": 296.94081527894275,
                "rarity_level": "EPIC"
            }
        }
    },
    "1485": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1485_1681446012.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1485",
                "name": "Troubled Badger Latham #1485",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446001,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1485_1681446012.png",
                "rarity_score": 102.3731678481932,
                "rarity_level": "RARE"
            }
        }
    },
    "1546": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1546_1681446019.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1546",
                "name": "Bashful Badger Gornuk #1546",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1546_1681446019.png",
                "rarity_score": 95.54376741269782,
                "rarity_level": "RARE"
            }
        }
    },
    "457": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/457_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "457",
                "name": "Yucky Badger Lewis #457",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_457_1681445810.png",
                "rarity_score": 59.906584643426754,
                "rarity_level": "COMMON"
            }
        }
    },
    "482": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/482_1681445819.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "482",
                "name": "Byzantine Badger Sirius Black #482",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445811,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_482_1681445819.png",
                "rarity_score": 194.68778919243314,
                "rarity_level": "RARE"
            }
        }
    },
    "1521": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1521_1681446013.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1521",
                "name": "Fantastic Badger Tyson #1521",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446008,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1521_1681446013.png",
                "rarity_score": 60.06971837362147,
                "rarity_level": "COMMON"
            }
        }
    },
    "1550": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1550_1681446019.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1550",
                "name": "Lively Badger Jones #1550",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1550_1681446019.png",
                "rarity_score": 83.71810901550677,
                "rarity_level": "COMMON"
            }
        }
    },
    "437": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/437_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "437",
                "name": "Worried Badger Diggory #437",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445803,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_437_1681445810.png",
                "rarity_score": 104.97019336758807,
                "rarity_level": "RARE"
            }
        }
    },
    "1527": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1527_1681446015.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1527",
                "name": "Grieving Badger Crookshanks #1527",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446010,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1527_1681446015.png",
                "rarity_score": 72.70081543203881,
                "rarity_level": "COMMON"
            }
        }
    },
    "1560": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1560_1681446021.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1560",
                "name": "Ludicrous Badger Amir #1560",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446015,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1560_1681446021.png",
                "rarity_score": 77.22618552563297,
                "rarity_level": "COMMON"
            }
        }
    },
    "1593": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1593_1681446026.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1593",
                "name": "Beautiful Badger Shields #1593",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446021,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1593_1681446026.png",
                "rarity_score": 76.93417213401473,
                "rarity_level": "COMMON"
            }
        }
    },
    "450": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/450_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "450",
                "name": "Hilarious Badger Boot #450",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_450_1681445810.png",
                "rarity_score": 63.26173052953239,
                "rarity_level": "COMMON"
            }
        }
    },
    "1524": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1524_1681446016.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1524",
                "name": "Impressive Badger Gringot #1524",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446009,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1524_1681446016.png",
                "rarity_score": 119.17132812344514,
                "rarity_level": "RARE"
            }
        }
    },
    "1563": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1563_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1563",
                "name": "Gentle Badger Floyd #1563",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446016,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1563_1681446024.png",
                "rarity_score": 116.19527398030509,
                "rarity_level": "RARE"
            }
        }
    },
    "1614": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1614_1681446029.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1614",
                "name": "Testy Badger Mundungus #1614",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1614_1681446029.png",
                "rarity_score": 72.06454478407021,
                "rarity_level": "COMMON"
            }
        }
    },
    "453": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/453_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "453",
                "name": "Byzantine Badger Fang #453",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_453_1681445810.png",
                "rarity_score": 100.79575951361844,
                "rarity_level": "RARE"
            }
        }
    },
    "487": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/487_1681445817.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "487",
                "name": "Colorful Badger Peter #487",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_487_1681445817.png",
                "rarity_score": 56.71335200746966,
                "rarity_level": "COMMON"
            }
        }
    },
    "1531": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1531_1681446016.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1531",
                "name": "Witty Badger Longbottom #1531",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1531_1681446016.png",
                "rarity_score": 98.7703103092442,
                "rarity_level": "RARE"
            }
        }
    },
    "1565": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1565_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1565",
                "name": "Comfy Badger Hippogriff #1565",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446017,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1565_1681446024.png",
                "rarity_score": 92.67616407523764,
                "rarity_level": "RARE"
            }
        }
    },
    "454": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/454_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "454",
                "name": "Unusual Badger Phineas #454",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_454_1681445810.png",
                "rarity_score": 74.48068889812623,
                "rarity_level": "COMMON"
            }
        }
    },
    "489": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/489_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "489",
                "name": "Funny Badger Weston #489",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_489_1681445820.png",
                "rarity_score": 87.56821665755207,
                "rarity_level": "COMMON"
            }
        }
    },
    "1532": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1532_1681446016.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1532",
                "name": "Expensive Badger Georgie #1532",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1532_1681446016.png",
                "rarity_score": 77.69529432521125,
                "rarity_level": "COMMON"
            }
        }
    },
    "1568": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1568_1681446023.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1568",
                "name": "Powerful Badger Connelly #1568",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446017,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1568_1681446023.png",
                "rarity_score": 76.32139184944839,
                "rarity_level": "COMMON"
            }
        }
    },
    "460": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/460_1681445810.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "460",
                "name": "Helpful Badger Fox #460",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445806,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_460_1681445810.png",
                "rarity_score": 59.69973073390804,
                "rarity_level": "COMMON"
            }
        }
    },
    "490": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/490_1681445819.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "490",
                "name": "Mysterious Badger Watson #490",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_490_1681445819.png",
                "rarity_score": 82.09132502213808,
                "rarity_level": "COMMON"
            }
        }
    },
    "1539": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1539_1681446017.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1539",
                "name": "Delightful Badger Firebolt #1539",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446012,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1539_1681446017.png",
                "rarity_score": 60.38666876135614,
                "rarity_level": "COMMON"
            }
        }
    },
    "1570": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1570_1681446023.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1570",
                "name": "Bright Badger Prongs #1570",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446017,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1570_1681446023.png",
                "rarity_score": 60.12124624565053,
                "rarity_level": "COMMON"
            }
        }
    },
    "455": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/455_1681445811.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "455",
                "name": "Dull Badger Mad-Eye Moody #455",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_455_1681445811.png",
                "rarity_score": 240.20940458264897,
                "rarity_level": "EPIC"
            }
        }
    },
    "492": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/492_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "492",
                "name": "Bashful Badger Nargle #492",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_492_1681445820.png",
                "rarity_score": 73.92134908288207,
                "rarity_level": "COMMON"
            }
        }
    },
    "1536": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1536_1681446017.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1536",
                "name": "Mysterious Badger Cesar #1536",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446012,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1536_1681446017.png",
                "rarity_score": 61.06060047434343,
                "rarity_level": "COMMON"
            }
        }
    },
    "1572": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1572_1681446025.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1572",
                "name": "Beautiful Badger Gringot #1572",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446018,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1572_1681446025.png",
                "rarity_score": 81.98257139291381,
                "rarity_level": "COMMON"
            }
        }
    },
    "459": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/459_1681445811.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "459",
                "name": "Upset Badger Muggle #459",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_459_1681445811.png",
                "rarity_score": 97.21524847818094,
                "rarity_level": "RARE"
            }
        }
    },
    "495": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/495_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "495",
                "name": "Happy Badger Joe #495",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_495_1681445820.png",
                "rarity_score": 106.7418654621229,
                "rarity_level": "RARE"
            }
        }
    },
    "1529": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1529_1681446018.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1529",
                "name": "Clumsy Badger Harry Potter #1529",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1529_1681446018.png",
                "rarity_score": 247.4409286936392,
                "rarity_level": "EPIC"
            }
        }
    },
    "1574": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1574_1681446023.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1574",
                "name": "Super Badger Charlie #1574",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446018,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1574_1681446023.png",
                "rarity_score": 68.91859800632719,
                "rarity_level": "COMMON"
            }
        }
    },
    "448": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/448_1681445811.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "448",
                "name": "Bloody Badger Thestral #448",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445804,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_448_1681445811.png",
                "rarity_score": 115.44845817931082,
                "rarity_level": "RARE"
            }
        }
    },
    "1530": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1530_1681446019.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1530",
                "name": "Nervous Badger Clay #1530",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446011,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1530_1681446019.png",
                "rarity_score": 112.48247950945844,
                "rarity_level": "RARE"
            }
        }
    },
    "1582": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1582_1681446025.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1582",
                "name": "Concerned Badger Ronan #1582",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1582_1681446025.png",
                "rarity_score": 71.71991317438778,
                "rarity_level": "COMMON"
            }
        }
    },
    "1621": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1621_1681446031.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1621",
                "name": "Dildo Badger Asher #1621",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446026,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1621_1681446031.png",
                "rarity_score": 69.84523021045149,
                "rarity_level": "COMMON"
            }
        }
    },
    "461": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/461_1681445811.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "461",
                "name": "Sore Badger Greyback #461",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445806,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_461_1681445811.png",
                "rarity_score": 68.71772751525779,
                "rarity_level": "COMMON"
            }
        }
    },
    "1538": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1538_1681446019.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1538",
                "name": "Bot Badger Caelan #1538",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446012,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1538_1681446019.png",
                "rarity_score": 132.23938612634004,
                "rarity_level": "RARE"
            }
        }
    },
    "1583": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1583_1681446026.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1583",
                "name": "Arrogant Badger Georgie #1583",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446020,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1583_1681446026.png",
                "rarity_score": 96.92011611115964,
                "rarity_level": "RARE"
            }
        }
    },
    "1626": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1626_1681446033.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1626",
                "name": "Carebear Badger Tiberius #1626",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446027,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1626_1681446033.png",
                "rarity_score": 84.32639009454455,
                "rarity_level": "COMMON"
            }
        }
    },
    "458": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/458_1681445812.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "458",
                "name": "FUD Badger Georgie #458",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445805,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_458_1681445812.png",
                "rarity_score": 212.9159527842906,
                "rarity_level": "RARE"
            }
        }
    },
    "1545": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1545_1681446019.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1545",
                "name": "Proud Badger Dumbledore #1545",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1545_1681446019.png",
                "rarity_score": 149.41909019860822,
                "rarity_level": "RARE"
            }
        }
    },
    "1584": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1584_1681446026.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1584",
                "name": "Modern Badger Keir #1584",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446020,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1584_1681446026.png",
                "rarity_score": 110.94928593652224,
                "rarity_level": "RARE"
            }
        }
    },
    "1627": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1627_1681446034.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1627",
                "name": "Unsightly Badger Wulfric #1627",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446027,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1627_1681446034.png",
                "rarity_score": 79.03625207123078,
                "rarity_level": "COMMON"
            }
        }
    },
    "464": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/464_1681445813.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "464",
                "name": "Tough Badger Lily #464",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445807,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_464_1681445813.png",
                "rarity_score": 325.90180239000296,
                "rarity_level": "EPIC"
            }
        }
    },
    "1554": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1554_1681446020.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1554",
                "name": "Testy Badger Elfie #1554",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1554_1681446020.png",
                "rarity_score": 72.72951495065891,
                "rarity_level": "COMMON"
            }
        }
    },
    "1588": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1588_1681446028.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1588",
                "name": "Wicked Badger Dash #1588",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446021,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1588_1681446028.png",
                "rarity_score": 95.77929947779434,
                "rarity_level": "RARE"
            }
        }
    },
    "1639": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1639_1681446035.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1639",
                "name": "Fragile Badger Riddick #1639",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1639_1681446035.png",
                "rarity_score": 79.39779275954844,
                "rarity_level": "COMMON"
            }
        }
    },
    "466": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/466_1681445813.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "466",
                "name": "Stupid Badger Albus #466",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445807,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_466_1681445813.png",
                "rarity_score": 103.05131145399226,
                "rarity_level": "RARE"
            }
        }
    },
    "1549": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1549_1681446020.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1549",
                "name": "Elegant Badger Kelpie #1549",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1549_1681446020.png",
                "rarity_score": 104.74544552953608,
                "rarity_level": "RARE"
            }
        }
    },
    "1590": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1590_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1590",
                "name": "Stupid Badger Ron #1590",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446021,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1590_1681446027.png",
                "rarity_score": 62.23564131103863,
                "rarity_level": "COMMON"
            }
        }
    },
    "1630": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1630_1681446033.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1630",
                "name": "Ugly Badger Beckham #1630",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446028,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1630_1681446033.png",
                "rarity_score": 62.93560580368525,
                "rarity_level": "COMMON"
            }
        }
    },
    "462": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/462_1681445814.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "462",
                "name": "Healthy Badger Pigwidgeon #462",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445807,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_462_1681445814.png",
                "rarity_score": 94.34526295476101,
                "rarity_level": "RARE"
            }
        }
    },
    "1548": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1548_1681446020.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1548",
                "name": "Eager Badger Fury #1548",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1548_1681446020.png",
                "rarity_score": 83.32883402405096,
                "rarity_level": "COMMON"
            }
        }
    },
    "1591": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1591_1681446028.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1591",
                "name": "Blushing Badger Lawrence #1591",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446021,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1591_1681446028.png",
                "rarity_score": 68.59168990584841,
                "rarity_level": "COMMON"
            }
        }
    },
    "1636": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1636_1681446033.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1636",
                "name": "Exuberant Badger Chavez #1636",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1636_1681446033.png",
                "rarity_score": 53.77702403521669,
                "rarity_level": "COMMON"
            }
        }
    },
    "467": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/467_1681445814.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "467",
                "name": "Wild Badger Maximus #467",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445808,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_467_1681445814.png",
                "rarity_score": 148.12924434938176,
                "rarity_level": "RARE"
            }
        }
    },
    "1564": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1564_1681446021.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1564",
                "name": "Nasty Badger Joe #1564",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446016,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1564_1681446021.png",
                "rarity_score": 71.19659625799511,
                "rarity_level": "COMMON"
            }
        }
    },
    "1592": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1592_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1592",
                "name": "Obnoxious Badger Holyfield #1592",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446021,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1592_1681446027.png",
                "rarity_score": 68.25432743660653,
                "rarity_level": "COMMON"
            }
        }
    },
    "1629": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1629_1681446038.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1629",
                "name": "Degen Badger Bowtruckle #1629",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446028,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1629_1681446038.png",
                "rarity_score": 83.327535547323,
                "rarity_level": "COMMON"
            }
        }
    },
    "471": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/471_1681445815.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "471",
                "name": "Uptight Badger Marciano #471",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445809,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_471_1681445815.png",
                "rarity_score": 256.9390405118069,
                "rarity_level": "EPIC"
            }
        }
    },
    "1561": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1561_1681446021.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1561",
                "name": "Grumpy Badger Maximus #1561",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446016,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1561_1681446021.png",
                "rarity_score": 102.49054429034291,
                "rarity_level": "RARE"
            }
        }
    },
    "1595": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1595_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1595",
                "name": "Curious Badger Dorian #1595",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446022,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1595_1681446027.png",
                "rarity_score": 67.03113491548703,
                "rarity_level": "COMMON"
            }
        }
    },
    "1634": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1634_1681446034.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1634",
                "name": "Fair Badger Rowan #1634",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446028,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1634_1681446034.png",
                "rarity_score": 62.773322868265645,
                "rarity_level": "COMMON"
            }
        }
    },
    "472": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/472_1681445815.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "472",
                "name": "Arrogant Badger David #472",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445809,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_472_1681445815.png",
                "rarity_score": 64.11942962798447,
                "rarity_level": "COMMON"
            }
        }
    },
    "1547": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1547_1681446021.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1547",
                "name": "Annoyed Badger Cash #1547",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446014,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1547_1681446021.png",
                "rarity_score": 120.49191445120046,
                "rarity_level": "RARE"
            }
        }
    },
    "1597": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1597_1681446031.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1597",
                "name": "Proud Badger Cayden #1597",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446022,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1597_1681446031.png",
                "rarity_score": 134.00713293984282,
                "rarity_level": "RARE"
            }
        }
    },
    "1661": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1661_1681446043.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1661",
                "name": "Tense Badger Ferdinand #1661",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1661_1681446043.png",
                "rarity_score": 123.62300447580442,
                "rarity_level": "RARE"
            }
        }
    },
    "463": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/463_1681445815.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "463",
                "name": "Whale Badger Creevy #463",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445807,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_463_1681445815.png",
                "rarity_score": 112.83760658792104,
                "rarity_level": "RARE"
            }
        }
    },
    "1566": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1566_1681446023.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1566",
                "name": "Elegant Badger Mad-Eye Moody #1566",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446017,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1566_1681446023.png",
                "rarity_score": 96.88750934775197,
                "rarity_level": "RARE"
            }
        }
    },
    "1607": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1607_1681446028.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1607",
                "name": "Brainy Badger Holly #1607",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446024,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1607_1681446028.png",
                "rarity_score": 56.146111445272574,
                "rarity_level": "COMMON"
            }
        }
    },
    "1635": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1635_1681446037.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1635",
                "name": "Colorful Badger Moore #1635",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1635_1681446037.png",
                "rarity_score": 263.6653243425033,
                "rarity_level": "EPIC"
            }
        }
    },
    "475": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/475_1681445815.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "475",
                "name": "Fancy Badger Weasley #475",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445809,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_475_1681445815.png",
                "rarity_score": 72.72164169485609,
                "rarity_level": "COMMON"
            }
        }
    },
    "1567": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1567_1681446023.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1567",
                "name": "Nasty Badger Ely #1567",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446017,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1567_1681446023.png",
                "rarity_score": 83.10382716954116,
                "rarity_level": "COMMON"
            }
        }
    },
    "1609": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1609_1681446029.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1609",
                "name": "Upset Badger Percival #1609",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446024,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1609_1681446029.png",
                "rarity_score": 65.3580975981411,
                "rarity_level": "COMMON"
            }
        }
    },
    "1644": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1644_1681446034.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1644",
                "name": "Uptight Badger Amos #1644",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446030,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1644_1681446034.png",
                "rarity_score": 54.364062427781384,
                "rarity_level": "COMMON"
            }
        }
    },
    "477": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/477_1681445815.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "477",
                "name": "Condemned Badger Gulliver #477",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445810,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_477_1681445815.png",
                "rarity_score": 227.1136981566852,
                "rarity_level": "EPIC"
            }
        }
    },
    "1573": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1573_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1573",
                "name": "Real Badger Mayweather #1573",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446018,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1573_1681446024.png",
                "rarity_score": 61.16968113672518,
                "rarity_level": "COMMON"
            }
        }
    },
    "1610": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1610_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1610",
                "name": "Dark Badger Albus #1610",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446024,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1610_1681446030.png",
                "rarity_score": 90.16890941039549,
                "rarity_level": "RARE"
            }
        }
    },
    "1647": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1647_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1647",
                "name": "Embarrassed Badger Thestral #1647",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446030,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1647_1681446039.png",
                "rarity_score": 99.81943875474872,
                "rarity_level": "RARE"
            }
        }
    },
    "468": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/468_1681445816.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "468",
                "name": "Exuberant Badger Hippogriff #468",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445808,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_468_1681445816.png",
                "rarity_score": 106.14035605418135,
                "rarity_level": "RARE"
            }
        }
    },
    "1581": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1581_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1581",
                "name": "Jolly Badger Sirius #1581",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1581_1681446024.png",
                "rarity_score": 61.35910871320255,
                "rarity_level": "COMMON"
            }
        }
    },
    "1611": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1611_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1611",
                "name": "Powerful Badger Chester #1611",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1611_1681446030.png",
                "rarity_score": 59.50271545739841,
                "rarity_level": "COMMON"
            }
        }
    },
    "1652": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1652_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1652",
                "name": "Uptight Badger Filch #1652",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446031,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1652_1681446039.png",
                "rarity_score": 61.82545802861491,
                "rarity_level": "COMMON"
            }
        }
    },
    "473": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/473_1681445817.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "473",
                "name": "Healthy Badger Brawley #473",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445809,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_473_1681445817.png",
                "rarity_score": 93.25176739247763,
                "rarity_level": "RARE"
            }
        }
    },
    "1580": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1580_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1580",
                "name": "Lucky Badger Colt #1580",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1580_1681446024.png",
                "rarity_score": 54.72521665769453,
                "rarity_level": "COMMON"
            }
        }
    },
    "1612": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1612_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1612",
                "name": "Evil Badger Rudy #1612",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1612_1681446030.png",
                "rarity_score": 80.29439595705479,
                "rarity_level": "COMMON"
            }
        }
    },
    "1655": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1655_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1655",
                "name": "Upset Badger Huck #1655",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1655_1681446039.png",
                "rarity_score": 67.47552906716903,
                "rarity_level": "COMMON"
            }
        }
    },
    "488": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/488_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "488",
                "name": "Anxious Badger Gauge #488",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_488_1681445820.png",
                "rarity_score": 65.54521324835858,
                "rarity_level": "COMMON"
            }
        }
    },
    "1578": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1578_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1578",
                "name": "Clumsy Badger Georgie #1578",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1578_1681446024.png",
                "rarity_score": 53.26437798266695,
                "rarity_level": "COMMON"
            }
        }
    },
    "1613": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1613_1681446032.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1613",
                "name": "Ashamed Badger Mundungus #1613",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1613_1681446032.png",
                "rarity_score": 86.49072612427535,
                "rarity_level": "COMMON"
            }
        }
    },
    "1662": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1662_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1662",
                "name": "Dumb Badger Sebastian #1662",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446033,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1662_1681446039.png",
                "rarity_score": 68.07626430030453,
                "rarity_level": "COMMON"
            }
        }
    },
    "505": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/505_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "505",
                "name": "Poor Badger Grawp #505",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445815,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_505_1681445821.png",
                "rarity_score": 53.53555967841682,
                "rarity_level": "COMMON"
            }
        }
    },
    "479": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/479_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "479",
                "name": "Shill Badger Jasper #479",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445810,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_479_1681445821.png",
                "rarity_score": 126.43806568387016,
                "rarity_level": "RARE"
            }
        }
    },
    "501": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/501_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "501",
                "name": "Dead Badger Sirius #501",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445814,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_501_1681445820.png",
                "rarity_score": 69.62379061143561,
                "rarity_level": "COMMON"
            }
        }
    },
    "500": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/500_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "500",
                "name": "Disturbed Badger Gauge #500",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445813,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_500_1681445821.png",
                "rarity_score": 91.20127861011068,
                "rarity_level": "RARE"
            }
        }
    },
    "486": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/486_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "486",
                "name": "Annoying Badger Carter #486",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_486_1681445821.png",
                "rarity_score": 104.93585516392665,
                "rarity_level": "RARE"
            }
        }
    },
    "503": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/503_1681445820.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "503",
                "name": "Shy Badger Willow #503",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445815,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_503_1681445820.png",
                "rarity_score": 76.09562966346886,
                "rarity_level": "COMMON"
            }
        }
    },
    "507": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/507_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "507",
                "name": "Lovely Badger Hendrix #507",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445815,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_507_1681445821.png",
                "rarity_score": 58.510456141334004,
                "rarity_level": "COMMON"
            }
        }
    },
    "504": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/504_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "504",
                "name": "Bot Badger Fitzwilliam #504",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445815,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_504_1681445821.png",
                "rarity_score": 75.67245952847261,
                "rarity_level": "COMMON"
            }
        }
    },
    "491": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/491_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "491",
                "name": "Embarrassed Badger Snape #491",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_491_1681445821.png",
                "rarity_score": 217.2137797664397,
                "rarity_level": "RARE"
            }
        }
    },
    "516": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/516_1681445822.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "516",
                "name": "Anal Badger Benedick #516",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445817,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_516_1681445822.png",
                "rarity_score": 51.17500757664668,
                "rarity_level": "COMMON"
            }
        }
    },
    "485": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/485_1681445821.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "485",
                "name": "Lonely Badger Lewis #485",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445811,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_485_1681445821.png",
                "rarity_score": 324.0581862471835,
                "rarity_level": "EPIC"
            }
        }
    },
    "502": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/502_1681445822.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "502",
                "name": "Envious Badger Potter #502",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445814,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_502_1681445822.png",
                "rarity_score": 143.0298738790686,
                "rarity_level": "RARE"
            }
        }
    },
    "497": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/497_1681445822.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "497",
                "name": "Gleaming Badger Bludger #497",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445813,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_497_1681445822.png",
                "rarity_score": 79.18892107193315,
                "rarity_level": "COMMON"
            }
        }
    },
    "494": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/494_1681445822.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "494",
                "name": "Angry Badger Puff #494",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445812,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_494_1681445822.png",
                "rarity_score": 87.19920842160802,
                "rarity_level": "COMMON"
            }
        }
    },
    "506": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/506_1681445822.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "506",
                "name": "Dolphin Badger Arther #506",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445815,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_506_1681445822.png",
                "rarity_score": 122.59647389283654,
                "rarity_level": "RARE"
            }
        }
    },
    "512": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/512_1681445823.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "512",
                "name": "Glorious Badger Credence #512",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_512_1681445823.png",
                "rarity_score": 209.0397301718488,
                "rarity_level": "RARE"
            }
        }
    },
    "498": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/498_1681445823.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "498",
                "name": "Elegant Badger Cedric #498",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445813,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_498_1681445823.png",
                "rarity_score": 80.99904660610974,
                "rarity_level": "COMMON"
            }
        }
    },
    "518": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/518_1681445823.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "518",
                "name": "Awful Badger Flitwick #518",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445818,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_518_1681445823.png",
                "rarity_score": 64.85164781475822,
                "rarity_level": "COMMON"
            }
        }
    },
    "511": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/511_1681445824.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "511",
                "name": "Excited Badger Jem #511",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_511_1681445824.png",
                "rarity_score": 213.84166910507628,
                "rarity_level": "RARE"
            }
        }
    },
    "513": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/513_1681445824.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "513",
                "name": "Creepy Badger David #513",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_513_1681445824.png",
                "rarity_score": 79.5683194144831,
                "rarity_level": "COMMON"
            }
        }
    },
    "514": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/514_1681445824.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "514",
                "name": "Bored Badger Winky #514",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_514_1681445824.png",
                "rarity_score": 115.75900947571608,
                "rarity_level": "RARE"
            }
        }
    },
    "509": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/509_1681445824.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "509",
                "name": "Unsightly Badger Gulliver #509",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_509_1681445824.png",
                "rarity_score": 88.27987699019833,
                "rarity_level": "COMMON"
            }
        }
    },
    "520": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/520_1681445825.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "520",
                "name": "Moon Badger Landon #520",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445819,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_520_1681445825.png",
                "rarity_score": 65.50980167283883,
                "rarity_level": "COMMON"
            }
        }
    },
    "521": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/521_1681445825.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "521",
                "name": "Excited Badger Fabian #521",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445819,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_521_1681445825.png",
                "rarity_score": 274.59337923033536,
                "rarity_level": "EPIC"
            }
        }
    },
    "523": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/523_1681445825.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "523",
                "name": "Poor Badger Finnigan #523",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445819,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_523_1681445825.png",
                "rarity_score": 115.14699537465981,
                "rarity_level": "RARE"
            }
        }
    },
    "510": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/510_1681445826.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "510",
                "name": "Bot Badger Troy #510",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_510_1681445826.png",
                "rarity_score": 113.0070315622756,
                "rarity_level": "RARE"
            }
        }
    },
    "527": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/527_1681445826.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "527",
                "name": "Real Badger Asher #527",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445820,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_527_1681445826.png",
                "rarity_score": 82.20399311882883,
                "rarity_level": "COMMON"
            }
        }
    },
    "519": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/519_1681445826.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "519",
                "name": "Disgusted Badger Tyson #519",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445819,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_519_1681445826.png",
                "rarity_score": 81.89609029255595,
                "rarity_level": "COMMON"
            }
        }
    },
    "522": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/522_1681445826.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "522",
                "name": "Lambo Badger Edward #522",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445819,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_522_1681445826.png",
                "rarity_score": 91.45058687687981,
                "rarity_level": "RARE"
            }
        }
    },
    "524": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/524_1681445826.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "524",
                "name": "Clammy Badger Klitschko #524",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445820,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_524_1681445826.png",
                "rarity_score": 67.31821567935596,
                "rarity_level": "COMMON"
            }
        }
    },
    "525": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/525_1681445827.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "525",
                "name": "Clean Badger Lennie #525",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445820,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_525_1681445827.png",
                "rarity_score": 91.99378409510769,
                "rarity_level": "RARE"
            }
        }
    },
    "517": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/517_1681445827.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "517",
                "name": "Fierce Badger Calen #517",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445818,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_517_1681445827.png",
                "rarity_score": 108.25497064976852,
                "rarity_level": "RARE"
            }
        }
    },
    "515": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/515_1681445827.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "515",
                "name": "Fancy Badger Julius #515",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445817,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_515_1681445827.png",
                "rarity_score": 130.0302873102483,
                "rarity_level": "RARE"
            }
        }
    },
    "564": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/564_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "564",
                "name": "Contagious Badger Albus #564",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445828,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_564_1681445835.png",
                "rarity_score": 75.77321798979045,
                "rarity_level": "COMMON"
            }
        }
    },
    "603": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/603_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "603",
                "name": "Happy Badger Henry #603",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_603_1681445844.png",
                "rarity_score": 77.15220218440457,
                "rarity_level": "COMMON"
            }
        }
    },
    "647": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/647_1681445850.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "647",
                "name": "Faithful Badger Zane #647",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_647_1681445850.png",
                "rarity_score": 77.84249254713588,
                "rarity_level": "COMMON"
            }
        }
    },
    "534": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/534_1681445827.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "534",
                "name": "Asthmatic Badger Connelly #534",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_534_1681445827.png",
                "rarity_score": 72.87645503005375,
                "rarity_level": "COMMON"
            }
        }
    },
    "531": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/531_1681445827.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "531",
                "name": "Strange Badger Cash #531",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445822,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_531_1681445827.png",
                "rarity_score": 72.75524860469602,
                "rarity_level": "COMMON"
            }
        }
    },
    "565": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/565_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "565",
                "name": "Prickly Badger Joe #565",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445828,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_565_1681445833.png",
                "rarity_score": 59.73293081116258,
                "rarity_level": "COMMON"
            }
        }
    },
    "566": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/566_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "566",
                "name": "Easy Badger Paws #566",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445828,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_566_1681445835.png",
                "rarity_score": 106.93570742797806,
                "rarity_level": "RARE"
            }
        }
    },
    "541": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/541_1681445828.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "541",
                "name": "Asthmatic Badger Thestral #541",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_541_1681445828.png",
                "rarity_score": 67.39800050614662,
                "rarity_level": "COMMON"
            }
        }
    },
    "530": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/530_1681445828.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "530",
                "name": "Bad Badger Filch #530",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445821,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_530_1681445828.png",
                "rarity_score": 172.18030032898298,
                "rarity_level": "RARE"
            }
        }
    },
    "569": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/569_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "569",
                "name": "Fierce Badger Firebolt #569",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_569_1681445835.png",
                "rarity_score": 148.81685538428536,
                "rarity_level": "RARE"
            }
        }
    },
    "568": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/568_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "568",
                "name": "Anal Badger Galen #568",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_568_1681445835.png",
                "rarity_score": 90.7747905302476,
                "rarity_level": "RARE"
            }
        }
    },
    "533": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/533_1681445828.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "533",
                "name": "Tense Badger Ranger #533",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445822,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_533_1681445828.png",
                "rarity_score": 64.25576882568997,
                "rarity_level": "COMMON"
            }
        }
    },
    "532": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/532_1681445828.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "532",
                "name": "Lonely Badger Viktor #532",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445822,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_532_1681445828.png",
                "rarity_score": 55.95812293700635,
                "rarity_level": "COMMON"
            }
        }
    },
    "528": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/528_1681445828.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "528",
                "name": "Bright Badger Maximus #528",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445821,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_528_1681445828.png",
                "rarity_score": 71.57722093731385,
                "rarity_level": "COMMON"
            }
        }
    },
    "571": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/571_1681445836.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "571",
                "name": "Muddy Badger Evander #571",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_571_1681445836.png",
                "rarity_score": 77.74303292153112,
                "rarity_level": "COMMON"
            }
        }
    },
    "542": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/542_1681445829.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "542",
                "name": "Anal Badger Troy #542",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_542_1681445829.png",
                "rarity_score": 64.6717745073086,
                "rarity_level": "COMMON"
            }
        }
    },
    "573": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/573_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "573",
                "name": "Anxious Badger Floyd #573",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_573_1681445835.png",
                "rarity_score": 74.63481412501227,
                "rarity_level": "COMMON"
            }
        }
    },
    "606": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/606_1681445845.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "606",
                "name": "Tired Badger Sphynx #606",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_606_1681445845.png",
                "rarity_score": 70.71971313471869,
                "rarity_level": "COMMON"
            }
        }
    },
    "658": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/658_1681445857.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "658",
                "name": "Dangerous Badger Leonardo #658",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445847,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_658_1681445857.png",
                "rarity_score": 112.06543963548407,
                "rarity_level": "RARE"
            }
        }
    },
    "544": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/544_1681445829.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "544",
                "name": "Glamorous Badger Wilder #544",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_544_1681445829.png",
                "rarity_score": 54.59683135541678,
                "rarity_level": "COMMON"
            }
        }
    },
    "508": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/508_1681445828.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "508",
                "name": "Stupid Badger Werner #508",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445816,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_508_1681445828.png",
                "rarity_score": 161.19372324662268,
                "rarity_level": "RARE"
            }
        }
    },
    "574": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/574_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "574",
                "name": "Cunnilingus Badger Ryker #574",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_574_1681445835.png",
                "rarity_score": 54.22612200982833,
                "rarity_level": "COMMON"
            }
        }
    },
    "575": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/575_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "575",
                "name": "Naughty Badger Maxton #575",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_575_1681445835.png",
                "rarity_score": 58.680610636121635,
                "rarity_level": "COMMON"
            }
        }
    },
    "545": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/545_1681445830.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "545",
                "name": "Cautious Badger Grimmauld #545",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_545_1681445830.png",
                "rarity_score": 105.02386451949076,
                "rarity_level": "RARE"
            }
        }
    },
    "576": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/576_1681445837.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "576",
                "name": "Nutty Badger Creevy #576",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445831,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_576_1681445837.png",
                "rarity_score": 71.85576347108395,
                "rarity_level": "COMMON"
            }
        }
    },
    "612": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/612_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "612",
                "name": "Clever Badger Dorian #612",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_612_1681445843.png",
                "rarity_score": 64.16098823987649,
                "rarity_level": "COMMON"
            }
        }
    },
    "646": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/646_1681445850.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "646",
                "name": "Amused Badger Sebastian #646",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_646_1681445850.png",
                "rarity_score": 206.1911911911912,
                "rarity_level": "RARE"
            }
        }
    },
    "547": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/547_1681445830.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "547",
                "name": "Dopey Badger Godric #547",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445824,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_547_1681445830.png",
                "rarity_score": 73.53103671339993,
                "rarity_level": "COMMON"
            }
        }
    },
    "577": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/577_1681445837.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "577",
                "name": "Nervous Badger Gulliver #577",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445831,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_577_1681445837.png",
                "rarity_score": 57.94477011989981,
                "rarity_level": "COMMON"
            }
        }
    },
    "613": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/613_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "613",
                "name": "Whale Badger Felix #613",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_613_1681445844.png",
                "rarity_score": 120.85709818217558,
                "rarity_level": "RARE"
            }
        }
    },
    "656": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/656_1681445855.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "656",
                "name": "Calm Badger Brawley #656",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445846,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_656_1681445855.png",
                "rarity_score": 109.69959299026233,
                "rarity_level": "RARE"
            }
        }
    },
    "538": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/538_1681445830.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "538",
                "name": "Charming Badger Moore #538",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_538_1681445830.png",
                "rarity_score": 109.87300978879438,
                "rarity_level": "RARE"
            }
        }
    },
    "578": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/578_1681445838.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "578",
                "name": "Clever Badger Percival #578",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445831,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_578_1681445838.png",
                "rarity_score": 61.320961939716646,
                "rarity_level": "COMMON"
            }
        }
    },
    "621": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/621_1681445845.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "621",
                "name": "Disturbed Badger Sirius Black #621",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445839,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_621_1681445845.png",
                "rarity_score": 78.512495188223,
                "rarity_level": "COMMON"
            }
        }
    },
    "653": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/653_1681445855.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "653",
                "name": "Doubtful Badger Ronald #653",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445846,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_653_1681445855.png",
                "rarity_score": 257.8910531856163,
                "rarity_level": "EPIC"
            }
        }
    },
    "536": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/536_1681445831.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "536",
                "name": "Naughty Badger Darcy #536",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_536_1681445831.png",
                "rarity_score": 91.54934709416568,
                "rarity_level": "RARE"
            }
        }
    },
    "579": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/579_1681445837.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "579",
                "name": "Poor Badger Ryker #579",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_579_1681445837.png",
                "rarity_score": 59.83452519958991,
                "rarity_level": "COMMON"
            }
        }
    },
    "616": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/616_1681445846.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "616",
                "name": "Healthy Badger Ishmael #616",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_616_1681445846.png",
                "rarity_score": 117.67677201796975,
                "rarity_level": "RARE"
            }
        }
    },
    "659": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/659_1681445853.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "659",
                "name": "Disgusted Badger Connelly #659",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445847,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_659_1681445853.png",
                "rarity_score": 77.41365141183138,
                "rarity_level": "COMMON"
            }
        }
    },
    "535": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/535_1681445829.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "535",
                "name": "Horrible Badger Arlo #535",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_535_1681445829.png",
                "rarity_score": 93.6558979270547,
                "rarity_level": "RARE"
            }
        }
    },
    "580": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/580_1681445838.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "580",
                "name": "Defiant Badger Mad-Eye Moody #580",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_580_1681445838.png",
                "rarity_score": 64.92810643576428,
                "rarity_level": "COMMON"
            }
        }
    },
    "620": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/620_1681445852.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "620",
                "name": "Uptight Badger Elfie #620",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445839,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_620_1681445852.png",
                "rarity_score": 163.07328102160483,
                "rarity_level": "RARE"
            }
        }
    },
    "691": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/691_1681445861.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "691",
                "name": "Foolish Badger Peeves #691",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445853,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_691_1681445861.png",
                "rarity_score": 106.73535122396825,
                "rarity_level": "RARE"
            }
        }
    },
    "543": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/543_1681445830.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "543",
                "name": "Scary Badger Joe #543",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_543_1681445830.png",
                "rarity_score": 71.58148666538104,
                "rarity_level": "COMMON"
            }
        }
    },
    "581": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/581_1681445840.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "581",
                "name": "Delightful Badger Jake #581",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_581_1681445840.png",
                "rarity_score": 107.10148049186697,
                "rarity_level": "RARE"
            }
        }
    },
    "629": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/629_1681445848.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "629",
                "name": "Faithful Badger Duncan #629",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_629_1681445848.png",
                "rarity_score": 82.32905604247756,
                "rarity_level": "COMMON"
            }
        }
    },
    "669": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/669_1681445855.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "669",
                "name": "Real Badger Voldemort #669",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445849,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_669_1681445855.png",
                "rarity_score": 74.89101834921125,
                "rarity_level": "COMMON"
            }
        }
    },
    "549": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/549_1681445831.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "549",
                "name": "Witty Badger Evander #549",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445824,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_549_1681445831.png",
                "rarity_score": 67.35847526438675,
                "rarity_level": "COMMON"
            }
        }
    },
    "582": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/582_1681445837.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "582",
                "name": "Perfect Badger Creevy #582",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_582_1681445837.png",
                "rarity_score": 59.311860859526604,
                "rarity_level": "COMMON"
            }
        }
    },
    "618": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/618_1681445845.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "618",
                "name": "Lively Badger Corin #618",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_618_1681445845.png",
                "rarity_score": 74.24970972244932,
                "rarity_level": "COMMON"
            }
        }
    },
    "654": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/654_1681445854.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "654",
                "name": "Enthusiastic Badger Moore #654",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445846,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_654_1681445854.png",
                "rarity_score": 452.9265738647871,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "546": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/546_1681445831.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "546",
                "name": "Enthusiastic Badger Fallon #546",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_546_1681445831.png",
                "rarity_score": 94.48365496757958,
                "rarity_level": "RARE"
            }
        }
    },
    "583": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/583_1681445839.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "583",
                "name": "Dull Badger Hermes #583",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_583_1681445839.png",
                "rarity_score": 104.90193418505544,
                "rarity_level": "RARE"
            }
        }
    },
    "624": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/624_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "624",
                "name": "Determined Badger Fireball #624",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_624_1681445844.png",
                "rarity_score": 54.50191171359055,
                "rarity_level": "COMMON"
            }
        }
    },
    "650": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/650_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "650",
                "name": "Lambo Badger Bogrod #650",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_650_1681445851.png",
                "rarity_score": 78.87296094105413,
                "rarity_level": "COMMON"
            }
        }
    },
    "555": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/555_1681445831.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "555",
                "name": "Embarrassed Badger Carter #555",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445826,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_555_1681445831.png",
                "rarity_score": 56.322086448474565,
                "rarity_level": "COMMON"
            }
        }
    },
    "526": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/526_1681445830.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "526",
                "name": "Carebear Badger Jacob #526",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445820,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_526_1681445830.png",
                "rarity_score": 108.96024868146607,
                "rarity_level": "RARE"
            }
        }
    },
    "585": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/585_1681445838.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "585",
                "name": "Quaint Badger Dash #585",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_585_1681445838.png",
                "rarity_score": 217.61141828753935,
                "rarity_level": "RARE"
            }
        }
    },
    "584": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/584_1681445839.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "584",
                "name": "Proud Badger Galen #584",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445832,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_584_1681445839.png",
                "rarity_score": 85.01104661190158,
                "rarity_level": "COMMON"
            }
        }
    },
    "540": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/540_1681445832.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "540",
                "name": "Angry Badger Nargle #540",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_540_1681445832.png",
                "rarity_score": 67.29467114735712,
                "rarity_level": "COMMON"
            }
        }
    },
    "586": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/586_1681445841.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "586",
                "name": "Energetic Badger Manny #586",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445833,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_586_1681445841.png",
                "rarity_score": 139.9826528482866,
                "rarity_level": "RARE"
            }
        }
    },
    "633": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/633_1681445849.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "633",
                "name": "Worried Badger Filius #633",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445841,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_633_1681445849.png",
                "rarity_score": 152.56714832565825,
                "rarity_level": "RARE"
            }
        }
    },
    "673": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/673_1681445861.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "673",
                "name": "Wandering Badger Abernathy #673",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445850,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_673_1681445861.png",
                "rarity_score": 81.56120328011194,
                "rarity_level": "COMMON"
            }
        }
    },
    "548": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/548_1681445832.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "548",
                "name": "Glorious Badger Dragon #548",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445824,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_548_1681445832.png",
                "rarity_score": 118.73332317275295,
                "rarity_level": "RARE"
            }
        }
    },
    "587": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/587_1681445839.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "587",
                "name": "Mysterious Badger Lomachenko #587",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445833,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_587_1681445839.png",
                "rarity_score": 106.34485418580624,
                "rarity_level": "RARE"
            }
        }
    },
    "623": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/623_1681445847.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "623",
                "name": "Determined Badger Walden #623",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445839,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_623_1681445847.png",
                "rarity_score": 65.50777499543881,
                "rarity_level": "COMMON"
            }
        }
    },
    "664": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/664_1681445853.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "664",
                "name": "Tired Badger Ollivander #664",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445848,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_664_1681445853.png",
                "rarity_score": 70.99185128596893,
                "rarity_level": "COMMON"
            }
        }
    },
    "537": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/537_1681445831.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "537",
                "name": "Bloody Badger Henry #537",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_537_1681445831.png",
                "rarity_score": 84.26732036443526,
                "rarity_level": "COMMON"
            }
        }
    },
    "529": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/529_1681445832.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "529",
                "name": "Clever Badger Severus #529",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445821,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_529_1681445832.png",
                "rarity_score": 121.77239777574957,
                "rarity_level": "RARE"
            }
        }
    },
    "588": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/588_1681445838.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "588",
                "name": "Shy Badger Hippogriff #588",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445833,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_588_1681445838.png",
                "rarity_score": 70.76316925224613,
                "rarity_level": "COMMON"
            }
        }
    },
    "589": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/589_1681445840.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "589",
                "name": "Sore Badger Peverell #589",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445833,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_589_1681445840.png",
                "rarity_score": 67.00906278095151,
                "rarity_level": "COMMON"
            }
        }
    },
    "556": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/556_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "556",
                "name": "Prickly Badger Theodore #556",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445826,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_556_1681445833.png",
                "rarity_score": 64.94039944534994,
                "rarity_level": "COMMON"
            }
        }
    },
    "590": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/590_1681445839.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "590",
                "name": "Whale Badger Clay #590",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445834,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_590_1681445839.png",
                "rarity_score": 74.31638473984779,
                "rarity_level": "COMMON"
            }
        }
    },
    "626": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/626_1681445848.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "626",
                "name": "Clean Badger Ogre #626",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_626_1681445848.png",
                "rarity_score": 65.54433368173277,
                "rarity_level": "COMMON"
            }
        }
    },
    "666": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/666_1681445858.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "666",
                "name": "Evil Badger Darcy #666",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445848,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_666_1681445858.png",
                "rarity_score": 80.20017250740794,
                "rarity_level": "COMMON"
            }
        }
    },
    "551": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/551_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "551",
                "name": "Precious Badger Cayman #551",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445825,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_551_1681445833.png",
                "rarity_score": 209.69198647479476,
                "rarity_level": "RARE"
            }
        }
    },
    "591": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/591_1681445841.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "591",
                "name": "Tired Badger Cayman #591",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445834,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_591_1681445841.png",
                "rarity_score": 101.07274599736263,
                "rarity_level": "RARE"
            }
        }
    },
    "631": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/631_1681445848.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "631",
                "name": "Excited Badger Braham #631",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445841,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_631_1681445848.png",
                "rarity_score": 70.31133732607715,
                "rarity_level": "COMMON"
            }
        }
    },
    "667": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/667_1681445855.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "667",
                "name": "Charming Badger Everett #667",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445848,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_667_1681445855.png",
                "rarity_score": 58.13250517598344,
                "rarity_level": "COMMON"
            }
        }
    },
    "553": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/553_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "553",
                "name": "Clever Badger Frederick #553",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445825,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_553_1681445833.png",
                "rarity_score": 73.20764075028025,
                "rarity_level": "COMMON"
            }
        }
    },
    "592": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/592_1681445839.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "592",
                "name": "Brave Badger Godric #592",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445834,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_592_1681445839.png",
                "rarity_score": 96.69776933385782,
                "rarity_level": "RARE"
            }
        }
    },
    "628": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/628_1681445847.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "628",
                "name": "Nervous Badger Cesar #628",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_628_1681445847.png",
                "rarity_score": 105.03643234296698,
                "rarity_level": "RARE"
            }
        }
    },
    "662": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/662_1681445856.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "662",
                "name": "Lovely Badger Hermes #662",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445847,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_662_1681445856.png",
                "rarity_score": 131.19003366974448,
                "rarity_level": "RARE"
            }
        }
    },
    "539": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/539_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "539",
                "name": "Clean Badger Foreman #539",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445823,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_539_1681445833.png",
                "rarity_score": 135.46301130398498,
                "rarity_level": "RARE"
            }
        }
    },
    "593": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/593_1681445841.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "593",
                "name": "Dolphin Badger Lumos #593",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445834,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_593_1681445841.png",
                "rarity_score": 76.7934320380667,
                "rarity_level": "COMMON"
            }
        }
    },
    "632": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/632_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "632",
                "name": "Hungry Badger Grindylow #632",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445841,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_632_1681445851.png",
                "rarity_score": 94.79553070022546,
                "rarity_level": "RARE"
            }
        }
    },
    "686": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/686_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "686",
                "name": "Super Badger Canelo #686",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445853,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_686_1681445860.png",
                "rarity_score": 78.0900585341553,
                "rarity_level": "COMMON"
            }
        }
    },
    "550": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/550_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "550",
                "name": "Lovely Badger Antonin #550",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445825,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_550_1681445833.png",
                "rarity_score": 171.4014699633177,
                "rarity_level": "RARE"
            }
        }
    },
    "595": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/595_1681445839.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "595",
                "name": "Nutty Badger Buckbeak #595",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_595_1681445839.png",
                "rarity_score": 80.8910600686821,
                "rarity_level": "COMMON"
            }
        }
    },
    "627": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/627_1681445849.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "627",
                "name": "Silly Badger Ranger #627",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_627_1681445849.png",
                "rarity_score": 292.4287100851805,
                "rarity_level": "EPIC"
            }
        }
    },
    "672": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/672_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "672",
                "name": "Cryptic Badger Vasyl #672",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445850,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_672_1681445860.png",
                "rarity_score": 71.74121045879662,
                "rarity_level": "COMMON"
            }
        }
    },
    "559": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/559_1681445834.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "559",
                "name": "Gentle Badger Nox #559",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445827,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_559_1681445834.png",
                "rarity_score": 396.83934055277194,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "597": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/597_1681445842.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "597",
                "name": "Bored Badger Georgie #597",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_597_1681445842.png",
                "rarity_score": 104.80767757888817,
                "rarity_level": "RARE"
            }
        }
    },
    "635": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/635_1681445848.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "635",
                "name": "Cryptic Badger Colt #635",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445842,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_635_1681445848.png",
                "rarity_score": 71.3350719337173,
                "rarity_level": "COMMON"
            }
        }
    },
    "668": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/668_1681445855.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "668",
                "name": "Lovely Badger Beckham #668",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445849,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_668_1681445855.png",
                "rarity_score": 98.912029321028,
                "rarity_level": "RARE"
            }
        }
    },
    "563": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/563_1681445833.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "563",
                "name": "Nervous Badger Krum #563",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445828,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_563_1681445833.png",
                "rarity_score": 274.88795530863445,
                "rarity_level": "EPIC"
            }
        }
    },
    "596": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/596_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "596",
                "name": "Elegant Badger Caelan #596",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_596_1681445844.png",
                "rarity_score": 65.47504770775312,
                "rarity_level": "COMMON"
            }
        }
    },
    "648": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/648_1681445856.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "648",
                "name": "Moon Badger Pacquiao #648",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_648_1681445856.png",
                "rarity_score": 139.54843720201416,
                "rarity_level": "RARE"
            }
        }
    },
    "703": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/703_1681445862.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "703",
                "name": "Healthy Badger Newt #703",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445856,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_703_1681445862.png",
                "rarity_score": 58.47595351000831,
                "rarity_level": "COMMON"
            }
        }
    },
    "562": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/562_1681445834.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "562",
                "name": "Sleepy Badger Maxim #562",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445828,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_562_1681445834.png",
                "rarity_score": 70.52141929250018,
                "rarity_level": "COMMON"
            }
        }
    },
    "598": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/598_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "598",
                "name": "Terrible Badger Severus #598",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_598_1681445843.png",
                "rarity_score": 114.1015918947337,
                "rarity_level": "RARE"
            }
        }
    },
    "642": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/642_1681445855.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "642",
                "name": "Scary Badger Bowtruckle #642",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445844,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_642_1681445855.png",
                "rarity_score": 466.49956387857895,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "702": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/702_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "702",
                "name": "Dark Badger Wilbur #702",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445856,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_702_1681445864.png",
                "rarity_score": 96.59308476661255,
                "rarity_level": "RARE"
            }
        }
    },
    "554": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/554_1681445834.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "554",
                "name": "Elegant Badger Albus #554",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445826,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_554_1681445834.png",
                "rarity_score": 138.28219846424304,
                "rarity_level": "RARE"
            }
        }
    },
    "599": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/599_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "599",
                "name": "Bloody Badger Lysander #599",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_599_1681445843.png",
                "rarity_score": 198.84804380779565,
                "rarity_level": "RARE"
            }
        }
    },
    "643": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/643_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "643",
                "name": "Carebear Badger Finn #643",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445844,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_643_1681445851.png",
                "rarity_score": 63.44799582519869,
                "rarity_level": "COMMON"
            }
        }
    },
    "682": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/682_1681445862.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "682",
                "name": "Asthmatic Badger Foreman #682",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_682_1681445862.png",
                "rarity_score": 81.8921335981844,
                "rarity_level": "COMMON"
            }
        }
    },
    "557": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/557_1681445834.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "557",
                "name": "Poor Badger Werner #557",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445826,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_557_1681445834.png",
                "rarity_score": 136.91088209189024,
                "rarity_level": "RARE"
            }
        }
    },
    "600": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/600_1681445841.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "600",
                "name": "Tough Badger Henry #600",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_600_1681445841.png",
                "rarity_score": 343.724779693731,
                "rarity_level": "EPIC"
            }
        }
    },
    "634": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/634_1681445849.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "634",
                "name": "Itchy Badger Remus #634",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445842,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_634_1681445849.png",
                "rarity_score": 101.7581360727564,
                "rarity_level": "RARE"
            }
        }
    },
    "674": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/674_1681445858.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "674",
                "name": "Cautious Badger Alvarez #674",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445850,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_674_1681445858.png",
                "rarity_score": 67.47550570562316,
                "rarity_level": "COMMON"
            }
        }
    },
    "558": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/558_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "558",
                "name": "Aggressive Badger Castiel #558",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445827,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_558_1681445835.png",
                "rarity_score": 87.7913968849743,
                "rarity_level": "COMMON"
            }
        }
    },
    "601": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/601_1681445846.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "601",
                "name": "Healthy Badger Peeves #601",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_601_1681445846.png",
                "rarity_score": 186.94512252797023,
                "rarity_level": "RARE"
            }
        }
    },
    "657": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/657_1681445852.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "657",
                "name": "Jealous Badger Johnson #657",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445847,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_657_1681445852.png",
                "rarity_score": 67.55423982018283,
                "rarity_level": "COMMON"
            }
        }
    },
    "689": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/689_1681445862.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "689",
                "name": "Pleasant Badger Finnigan #689",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445853,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_689_1681445862.png",
                "rarity_score": 104.36434967282975,
                "rarity_level": "RARE"
            }
        }
    },
    "561": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/561_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "561",
                "name": "Poor Badger Caelan #561",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445827,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_561_1681445835.png",
                "rarity_score": 77.15095894053192,
                "rarity_level": "COMMON"
            }
        }
    },
    "605": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/605_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "605",
                "name": "Tough Badger Nox #605",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_605_1681445843.png",
                "rarity_score": 77.00112327394017,
                "rarity_level": "COMMON"
            }
        }
    },
    "640": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/640_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "640",
                "name": "Shy Badger Finn #640",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445844,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_640_1681445851.png",
                "rarity_score": 116.29383496983887,
                "rarity_level": "RARE"
            }
        }
    },
    "685": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/685_1681445857.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "685",
                "name": "Brave Badger Jamieson #685",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_685_1681445857.png",
                "rarity_score": 60.891238341319294,
                "rarity_level": "COMMON"
            }
        }
    },
    "552": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/552_1681445835.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "552",
                "name": "Poor Badger Crouch #552",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445825,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_552_1681445835.png",
                "rarity_score": 309.6684851354227,
                "rarity_level": "EPIC"
            }
        }
    },
    "610": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/610_1681445842.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "610",
                "name": "Degen Badger Binns #610",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445837,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_610_1681445842.png",
                "rarity_score": 98.79620116031009,
                "rarity_level": "RARE"
            }
        }
    },
    "638": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/638_1681445849.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "638",
                "name": "Bewildered Badger Rhett #638",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445843,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_638_1681445849.png",
                "rarity_score": 81.25749096795634,
                "rarity_level": "COMMON"
            }
        }
    },
    "671": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/671_1681445861.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "671",
                "name": "Wen Badger Bronn #671",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445849,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_671_1681445861.png",
                "rarity_score": 125.7980867690253,
                "rarity_level": "RARE"
            }
        }
    },
    "572": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/572_1681445837.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "572",
                "name": "Clammy Badger Troy #572",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_572_1681445837.png",
                "rarity_score": 85.71490685631161,
                "rarity_level": "COMMON"
            }
        }
    },
    "614": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/614_1681445842.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "614",
                "name": "Talented Badger Floyd #614",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_614_1681445842.png",
                "rarity_score": 72.89375800571484,
                "rarity_level": "COMMON"
            }
        }
    },
    "639": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/639_1681445850.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "639",
                "name": "Bright Badger Atticus #639",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445843,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_639_1681445850.png",
                "rarity_score": 58.86103903166359,
                "rarity_level": "COMMON"
            }
        }
    },
    "675": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/675_1681445857.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "675",
                "name": "Condemned Badger Kingsley #675",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445851,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_675_1681445857.png",
                "rarity_score": 81.44101184257767,
                "rarity_level": "COMMON"
            }
        }
    },
    "560": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/560_1681445836.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "560",
                "name": "Courageous Badger Canelo #560",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445827,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_560_1681445836.png",
                "rarity_score": 128.41254697027927,
                "rarity_level": "RARE"
            }
        }
    },
    "615": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/615_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "615",
                "name": "Grieving Badger Chester #615",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_615_1681445843.png",
                "rarity_score": 69.02104676256756,
                "rarity_level": "COMMON"
            }
        }
    },
    "649": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/649_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "649",
                "name": "Bewildered Badger Bogrod #649",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_649_1681445851.png",
                "rarity_score": 60.370854031170516,
                "rarity_level": "COMMON"
            }
        }
    },
    "680": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/680_1681445858.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "680",
                "name": "Enchanting Badger Khan #680",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_680_1681445858.png",
                "rarity_score": 59.645702134564694,
                "rarity_level": "COMMON"
            }
        }
    },
    "570": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/570_1681445837.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "570",
                "name": "Glamorous Badger Kom #570",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445829,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_570_1681445837.png",
                "rarity_score": 88.16339254700648,
                "rarity_level": "COMMON"
            }
        }
    },
    "617": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/617_1681445845.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "617",
                "name": "Proud Badger Rubeus #617",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445838,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_617_1681445845.png",
                "rarity_score": 63.90403881142975,
                "rarity_level": "COMMON"
            }
        }
    },
    "655": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/655_1681445852.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "655",
                "name": "Real Badger Galen #655",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445846,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_655_1681445852.png",
                "rarity_score": 106.87157248525295,
                "rarity_level": "RARE"
            }
        }
    },
    "690": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/690_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "690",
                "name": "Dark Badger Lily #690",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445853,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_690_1681445864.png",
                "rarity_score": 103.43811006057427,
                "rarity_level": "RARE"
            }
        }
    },
    "594": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/594_1681445842.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "594",
                "name": "Friendly Badger Dragon #594",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445835,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_594_1681445842.png",
                "rarity_score": 93.59944189122531,
                "rarity_level": "RARE"
            }
        }
    },
    "636": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/636_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "636",
                "name": "Naughty Badger Tszyu #636",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445843,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_636_1681445851.png",
                "rarity_score": 143.4677671093424,
                "rarity_level": "RARE"
            }
        }
    },
    "684": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/684_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "684",
                "name": "Glorious Badger Stone #684",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_684_1681445860.png",
                "rarity_score": 65.26775294182993,
                "rarity_level": "COMMON"
            }
        }
    },
    "719": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/719_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "719",
                "name": "Crazy Badger Boot #719",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445860,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_719_1681445865.png",
                "rarity_score": 69.70583410078645,
                "rarity_level": "COMMON"
            }
        }
    },
    "608": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/608_1681445842.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "608",
                "name": "Cunnilingus Badger Diggory #608",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445837,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_608_1681445842.png",
                "rarity_score": 62.536579407701,
                "rarity_level": "COMMON"
            }
        }
    },
    "637": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/637_1681445850.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "637",
                "name": "Filthy Badger Ronan #637",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445843,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_637_1681445850.png",
                "rarity_score": 57.19038932262925,
                "rarity_level": "COMMON"
            }
        }
    },
    "676": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/676_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "676",
                "name": "Helpful Badger Walden #676",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445851,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_676_1681445860.png",
                "rarity_score": 96.20047818467677,
                "rarity_level": "RARE"
            }
        }
    },
    "723": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/723_1681445866.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "723",
                "name": "Modern Badger Heathcliff #723",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445861,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_723_1681445866.png",
                "rarity_score": 67.8471086507364,
                "rarity_level": "COMMON"
            }
        }
    },
    "611": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/611_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "611",
                "name": "Witty Badger Longbottom #611",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445837,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_611_1681445843.png",
                "rarity_score": 92.25525375938543,
                "rarity_level": "RARE"
            }
        }
    },
    "641": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/641_1681445848.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "641",
                "name": "Depressed Badger Leonard #641",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445844,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_641_1681445848.png",
                "rarity_score": 55.46282194640436,
                "rarity_level": "COMMON"
            }
        }
    },
    "670": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/670_1681445857.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "670",
                "name": "Depressed Badger Keir #670",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445849,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_670_1681445857.png",
                "rarity_score": 74.88237663998,
                "rarity_level": "COMMON"
            }
        }
    },
    "708": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/708_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "708",
                "name": "Depressed Badger Hermes #708",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445858,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_708_1681445867.png",
                "rarity_score": 100.60044719778197,
                "rarity_level": "RARE"
            }
        }
    },
    "607": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/607_1681445843.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "607",
                "name": "Dangerous Badger Cian #607",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_607_1681445843.png",
                "rarity_score": 63.450589344365774,
                "rarity_level": "COMMON"
            }
        }
    },
    "644": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/644_1681445851.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "644",
                "name": "Odd Badger Atticus #644",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445844,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_644_1681445851.png",
                "rarity_score": 56.86539019240261,
                "rarity_level": "COMMON"
            }
        }
    },
    "683": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/683_1681445859.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "683",
                "name": "Ugly Badger Chester #683",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_683_1681445859.png",
                "rarity_score": 70.62832996708971,
                "rarity_level": "COMMON"
            }
        }
    },
    "716": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/716_1681445869.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "716",
                "name": "Defiant Badger Connelly #716",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445860,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_716_1681445869.png",
                "rarity_score": 149.4980616857407,
                "rarity_level": "RARE"
            }
        }
    },
    "622": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/622_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "622",
                "name": "Determined Badger Fitzwilliam #622",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445839,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_622_1681445844.png",
                "rarity_score": 58.06506997465014,
                "rarity_level": "COMMON"
            }
        }
    },
    "645": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/645_1681445852.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "645",
                "name": "Clever Badger Caelan #645",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445844,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_645_1681445852.png",
                "rarity_score": 61.3477708684663,
                "rarity_level": "COMMON"
            }
        }
    },
    "688": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/688_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "688",
                "name": "Gentle Badger Collin #688",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445853,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_688_1681445860.png",
                "rarity_score": 68.35106320237487,
                "rarity_level": "COMMON"
            }
        }
    },
    "717": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/717_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "717",
                "name": "Clever Badger Bastian #717",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445860,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_717_1681445865.png",
                "rarity_score": 53.96843814008618,
                "rarity_level": "COMMON"
            }
        }
    },
    "604": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/604_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "604",
                "name": "Odd Badger Lysander #604",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_604_1681445844.png",
                "rarity_score": 64.87782367825629,
                "rarity_level": "COMMON"
            }
        }
    },
    "651": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/651_1681445852.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "651",
                "name": "Cute Badger Chester #651",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_651_1681445852.png",
                "rarity_score": 71.94872663231043,
                "rarity_level": "COMMON"
            }
        }
    },
    "687": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/687_1681445861.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "687",
                "name": "Gifted Badger Antonio #687",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445853,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_687_1681445861.png",
                "rarity_score": 83.31487125844737,
                "rarity_level": "COMMON"
            }
        }
    },
    "727": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/727_1681445870.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "727",
                "name": "Fierce Badger Jean #727",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445863,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_727_1681445870.png",
                "rarity_score": 119.06865872656078,
                "rarity_level": "RARE"
            }
        }
    },
    "609": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/609_1681445844.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "609",
                "name": "Determined Badger Lumos #609",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445837,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_609_1681445844.png",
                "rarity_score": 150.20632543828503,
                "rarity_level": "RARE"
            }
        }
    },
    "652": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/652_1681445850.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "652",
                "name": "Dizzy Badger Deontay #652",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445845,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_652_1681445850.png",
                "rarity_score": 61.7191440175699,
                "rarity_level": "COMMON"
            }
        }
    },
    "678": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/678_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "678",
                "name": "Lonely Badger Arther #678",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445851,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_678_1681445860.png",
                "rarity_score": 203.17510681349162,
                "rarity_level": "RARE"
            }
        }
    },
    "720": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/720_1681445868.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "720",
                "name": "Delightful Badger Jamieson #720",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445861,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_720_1681445868.png",
                "rarity_score": 93.6804580405061,
                "rarity_level": "RARE"
            }
        }
    },
    "602": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/602_1681445846.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "602",
                "name": "Exuberant Badger Wrackspurt #602",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445836,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_602_1681445846.png",
                "rarity_score": 296.0034269767924,
                "rarity_level": "EPIC"
            }
        }
    },
    "660": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/660_1681445858.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "660",
                "name": "HODL Badger Muggle #660",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445847,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_660_1681445858.png",
                "rarity_score": 123.87522518504814,
                "rarity_level": "RARE"
            }
        }
    },
    "711": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/711_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "711",
                "name": "Nasty Badger Kai #711",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445859,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_711_1681445867.png",
                "rarity_score": 70.57922744259817,
                "rarity_level": "COMMON"
            }
        }
    },
    "758": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/758_1681445876.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "758",
                "name": "Anal Badger Viktor #758",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_758_1681445876.png",
                "rarity_score": 78.92441272836709,
                "rarity_level": "COMMON"
            }
        }
    },
    "619": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/619_1681445847.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "619",
                "name": "Dildo Badger Lewis #619",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445839,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_619_1681445847.png",
                "rarity_score": 80.70966853969868,
                "rarity_level": "COMMON"
            }
        }
    },
    "661": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/661_1681445856.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "661",
                "name": "Odd Badger Clay #661",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445847,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_661_1681445856.png",
                "rarity_score": 315.50083211982525,
                "rarity_level": "EPIC"
            }
        }
    },
    "705": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/705_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "705",
                "name": "Terrible Badger Marciano #705",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445857,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_705_1681445867.png",
                "rarity_score": 111.90237355635182,
                "rarity_level": "RARE"
            }
        }
    },
    "759": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/759_1681445874.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "759",
                "name": "Fair Badger Hedwig #759",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_759_1681445874.png",
                "rarity_score": 65.57181124981052,
                "rarity_level": "COMMON"
            }
        }
    },
    "630": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/630_1681445847.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "630",
                "name": "Glorious Badger Collin #630",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_630_1681445847.png",
                "rarity_score": 54.26071890572362,
                "rarity_level": "COMMON"
            }
        }
    },
    "663": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/663_1681445854.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "663",
                "name": "Encouraging Badger Argus #663",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445848,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_663_1681445854.png",
                "rarity_score": 81.01262076258028,
                "rarity_level": "COMMON"
            }
        }
    },
    "698": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/698_1681445863.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "698",
                "name": "Witty Badger Deontay #698",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_698_1681445863.png",
                "rarity_score": 61.72595623580344,
                "rarity_level": "COMMON"
            }
        }
    },
    "736": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/736_1681445869.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "736",
                "name": "Bright Badger Noah #736",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_736_1681445869.png",
                "rarity_score": 54.758783670816406,
                "rarity_level": "COMMON"
            }
        }
    },
    "625": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/625_1681445847.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "625",
                "name": "Dead Badger Shep #625",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445840,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_625_1681445847.png",
                "rarity_score": 234.09587630938177,
                "rarity_level": "EPIC"
            }
        }
    },
    "665": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/665_1681445854.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "665",
                "name": "HODL Badger Cian #665",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445848,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_665_1681445854.png",
                "rarity_score": 72.19059606732823,
                "rarity_level": "COMMON"
            }
        }
    },
    "693": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/693_1681445861.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "693",
                "name": "Upset Badger Amir #693",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_693_1681445861.png",
                "rarity_score": 78.72055069760684,
                "rarity_level": "COMMON"
            }
        }
    },
    "728": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/728_1681445869.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "728",
                "name": "Fancy Badger Wulfric #728",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445863,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_728_1681445869.png",
                "rarity_score": 75.40424437064503,
                "rarity_level": "COMMON"
            }
        }
    },
    "681": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/681_1681445857.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "681",
                "name": "Nutty Badger Klitschko #681",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_681_1681445857.png",
                "rarity_score": 75.25818718912606,
                "rarity_level": "COMMON"
            }
        }
    },
    "710": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/710_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "710",
                "name": "Annoying Badger Bowtruckle #710",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445858,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_710_1681445864.png",
                "rarity_score": 161.4709744320134,
                "rarity_level": "RARE"
            }
        }
    },
    "742": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/742_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "742",
                "name": "Confused Badger Cesar #742",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445866,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_742_1681445873.png",
                "rarity_score": 72.95086553998496,
                "rarity_level": "COMMON"
            }
        }
    },
    "781": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/781_1681445880.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "781",
                "name": "Shill Badger Goyle #781",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445873,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_781_1681445880.png",
                "rarity_score": 105.32339215919248,
                "rarity_level": "RARE"
            }
        }
    },
    "677": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/677_1681445859.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "677",
                "name": "Beautiful Badger Grey #677",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445851,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_677_1681445859.png",
                "rarity_score": 60.29819496406541,
                "rarity_level": "COMMON"
            }
        }
    },
    "715": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/715_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "715",
                "name": "Disgusted Badger Elfie #715",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445859,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_715_1681445867.png",
                "rarity_score": 84.75998647781462,
                "rarity_level": "COMMON"
            }
        }
    },
    "753": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/753_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "753",
                "name": "Lonely Badger Leonard #753",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445868,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_753_1681445875.png",
                "rarity_score": 96.87752999849754,
                "rarity_level": "RARE"
            }
        }
    },
    "1576": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1576_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1576",
                "name": "Creepy Badger Arther #1576",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1576_1681446024.png",
                "rarity_score": 70.70662931128167,
                "rarity_level": "COMMON"
            }
        }
    },
    "679": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/679_1681445860.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "679",
                "name": "Homeless Badger Sebastian #679",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445852,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_679_1681445860.png",
                "rarity_score": 63.11656658119042,
                "rarity_level": "COMMON"
            }
        }
    },
    "721": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/721_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "721",
                "name": "Bot Badger Fawkes #721",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445861,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_721_1681445867.png",
                "rarity_score": 67.26330607861266,
                "rarity_level": "COMMON"
            }
        }
    },
    "752": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/752_1681445874.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "752",
                "name": "Bad Badger Fawkes #752",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445868,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_752_1681445874.png",
                "rarity_score": 95.80012501867806,
                "rarity_level": "RARE"
            }
        }
    },
    "788": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/788_1681445881.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "788",
                "name": "Lonely Badger Lucius #788",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445875,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_788_1681445881.png",
                "rarity_score": 98.3474682006,
                "rarity_level": "RARE"
            }
        }
    },
    "701": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/701_1681445861.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "701",
                "name": "Cute Badger Alvarez #701",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445856,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_701_1681445861.png",
                "rarity_score": 272.32487922705315,
                "rarity_level": "EPIC"
            }
        }
    },
    "726": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/726_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "726",
                "name": "Envious Badger Gilderoy #726",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445863,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_726_1681445867.png",
                "rarity_score": 56.68308705169611,
                "rarity_level": "COMMON"
            }
        }
    },
    "760": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/760_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "760",
                "name": "Enthusiastic Badger Salem #760",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_760_1681445873.png",
                "rarity_score": 53.00466182593166,
                "rarity_level": "COMMON"
            }
        }
    },
    "1579": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1579_1681446025.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1579",
                "name": "Hungry Badger Duncan #1579",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1579_1681446025.png",
                "rarity_score": 90.36133679075263,
                "rarity_level": "RARE"
            }
        }
    },
    "704": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/704_1681445862.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "704",
                "name": "Sleepy Badger Buckbeak #704",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445856,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_704_1681445862.png",
                "rarity_score": 64.19919283551184,
                "rarity_level": "COMMON"
            }
        }
    },
    "729": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/729_1681445869.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "729",
                "name": "Good Badger Holden #729",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_729_1681445869.png",
                "rarity_score": 91.8139493923446,
                "rarity_level": "RARE"
            }
        }
    },
    "769": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/769_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "769",
                "name": "Ludicrous Badger Connelly #769",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_769_1681445875.png",
                "rarity_score": 53.85074093686236,
                "rarity_level": "COMMON"
            }
        }
    },
    "1569": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1569_1681446024.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1569",
                "name": "Magnificent Badger Georgie #1569",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446017,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1569_1681446024.png",
                "rarity_score": 245.1109942779468,
                "rarity_level": "EPIC"
            }
        }
    },
    "700": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/700_1681445862.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "700",
                "name": "Rich Badger Huck #700",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445856,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_700_1681445862.png",
                "rarity_score": 67.62539762102169,
                "rarity_level": "COMMON"
            }
        }
    },
    "733": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/733_1681445870.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "733",
                "name": "Annoyed Badger Logan #733",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_733_1681445870.png",
                "rarity_score": 125.6050156979382,
                "rarity_level": "RARE"
            }
        }
    },
    "772": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/772_1681445878.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "772",
                "name": "Jittery Badger Ravenclaw #772",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_772_1681445878.png",
                "rarity_score": 236.2599127613732,
                "rarity_level": "EPIC"
            }
        }
    },
    "1577": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1577_1681446025.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1577",
                "name": "Bored Badger Vasyl #1577",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446019,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1577_1681446025.png",
                "rarity_score": 77.71548435822449,
                "rarity_level": "COMMON"
            }
        }
    },
    "695": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/695_1681445863.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "695",
                "name": "Nasty Badger Noah #695",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_695_1681445863.png",
                "rarity_score": 64.80645943802445,
                "rarity_level": "COMMON"
            }
        }
    },
    "734": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/734_1681445870.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "734",
                "name": "Shill Badger Thestral #734",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_734_1681445870.png",
                "rarity_score": 66.22734969705074,
                "rarity_level": "COMMON"
            }
        }
    },
    "770": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/770_1681445877.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "770",
                "name": "Ugly Badger Floyd #770",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_770_1681445877.png",
                "rarity_score": 56.13946568541603,
                "rarity_level": "COMMON"
            }
        }
    },
    "1589": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1589_1681446025.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1589",
                "name": "Unsightly Badger Tszyu #1589",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446021,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1589_1681446025.png",
                "rarity_score": 59.33238036789908,
                "rarity_level": "COMMON"
            }
        }
    },
    "699": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/699_1681445863.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "699",
                "name": "Jolly Badger Fox #699",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445856,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_699_1681445863.png",
                "rarity_score": 66.57955413662587,
                "rarity_level": "COMMON"
            }
        }
    },
    "737": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/737_1681445872.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "737",
                "name": "Bloody Badger Jasper #737",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_737_1681445872.png",
                "rarity_score": 119.5055937088389,
                "rarity_level": "RARE"
            }
        }
    },
    "779": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/779_1681445880.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "779",
                "name": "Blushing Badger Maxim #779",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445873,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_779_1681445880.png",
                "rarity_score": 77.56224341056159,
                "rarity_level": "COMMON"
            }
        }
    },
    "1587": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1587_1681446026.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1587",
                "name": "Curious Badger Louis #1587",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446020,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1587_1681446026.png",
                "rarity_score": 76.74417654527299,
                "rarity_level": "COMMON"
            }
        }
    },
    "694": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/694_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "694",
                "name": "Creepy Badger Wilbur #694",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_694_1681445864.png",
                "rarity_score": 85.06674807455039,
                "rarity_level": "COMMON"
            }
        }
    },
    "738": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/738_1681445872.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "738",
                "name": "Sleepy Badger Peeves #738",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445865,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_738_1681445872.png",
                "rarity_score": 85.65228576797348,
                "rarity_level": "COMMON"
            }
        }
    },
    "780": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/780_1681445879.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "780",
                "name": "Unusual Badger Fallon #780",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445873,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_780_1681445879.png",
                "rarity_score": 65.04660738183289,
                "rarity_level": "COMMON"
            }
        }
    },
    "1575": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1575_1681446026.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1575",
                "name": "Wild Badger Bingley #1575",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446018,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1575_1681446026.png",
                "rarity_score": 106.07660565185962,
                "rarity_level": "RARE"
            }
        }
    },
    "706": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/706_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "706",
                "name": "Asthmatic Badger Ethan #706",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445857,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_706_1681445864.png",
                "rarity_score": 70.65452780881968,
                "rarity_level": "COMMON"
            }
        }
    },
    "740": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/740_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "740",
                "name": "Sleepy Badger Floyd #740",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445866,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_740_1681445875.png",
                "rarity_score": 91.7862400604297,
                "rarity_level": "RARE"
            }
        }
    },
    "1585": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1585_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1585",
                "name": "Glamorous Badger Robinson #1585",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446020,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1585_1681446027.png",
                "rarity_score": 101.94551816639341,
                "rarity_level": "RARE"
            }
        }
    },
    "1628": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1628_1681446033.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1628",
                "name": "Repulsive Badger Wladimir #1628",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446027,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1628_1681446033.png",
                "rarity_score": 67.58324699633347,
                "rarity_level": "COMMON"
            }
        }
    },
    "707": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/707_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "707",
                "name": "Curious Badger Noah #707",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445857,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_707_1681445864.png",
                "rarity_score": 63.38160821297105,
                "rarity_level": "COMMON"
            }
        }
    },
    "741": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/741_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "741",
                "name": "Dumb Badger Galen #741",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445866,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_741_1681445873.png",
                "rarity_score": 103.47564822613151,
                "rarity_level": "RARE"
            }
        }
    },
    "787": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/787_1681445881.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "787",
                "name": "Perfect Badger Niffler #787",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445875,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_787_1681445881.png",
                "rarity_score": 90.41500566190265,
                "rarity_level": "RARE"
            }
        }
    },
    "1586": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1586_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1586",
                "name": "Enchanting Badger Cruz #1586",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446020,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1586_1681446027.png",
                "rarity_score": 266.30859815681384,
                "rarity_level": "EPIC"
            }
        }
    },
    "696": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/696_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "696",
                "name": "Fine Badger Landon #696",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_696_1681445865.png",
                "rarity_score": 210.8243143569451,
                "rarity_level": "RARE"
            }
        }
    },
    "743": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/743_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "743",
                "name": "Whale Badger Rubin #743",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445866,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_743_1681445873.png",
                "rarity_score": 134.5020978198425,
                "rarity_level": "RARE"
            }
        }
    },
    "1599": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1599_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1599",
                "name": "Fancy Badger Kostya #1599",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1599_1681446027.png",
                "rarity_score": 54.78638404380978,
                "rarity_level": "COMMON"
            }
        }
    },
    "1632": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1632_1681446032.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1632",
                "name": "Terrible Badger Abernathy #1632",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446028,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1632_1681446032.png",
                "rarity_score": 68.41015390452722,
                "rarity_level": "COMMON"
            }
        }
    },
    "697": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/697_1681445864.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "697",
                "name": "Doubtful Badger Newt #697",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_697_1681445864.png",
                "rarity_score": 70.50690906604449,
                "rarity_level": "COMMON"
            }
        }
    },
    "744": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/744_1681445872.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "744",
                "name": "Glorious Badger Crabbe #744",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445866,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_744_1681445872.png",
                "rarity_score": 68.65501336801627,
                "rarity_level": "COMMON"
            }
        }
    },
    "778": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/778_1681445878.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "778",
                "name": "Wild Badger Ranger #778",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445873,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_778_1681445878.png",
                "rarity_score": 71.5357293787394,
                "rarity_level": "COMMON"
            }
        }
    },
    "1601": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1601_1681446027.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1601",
                "name": "Funny Badger Snape #1601",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1601_1681446027.png",
                "rarity_score": 55.83030192804732,
                "rarity_level": "COMMON"
            }
        }
    },
    "718": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/718_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "718",
                "name": "Tame Badger Marciano #718",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445860,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_718_1681445865.png",
                "rarity_score": 57.54793921872624,
                "rarity_level": "COMMON"
            }
        }
    },
    "748": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/748_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "748",
                "name": "Evil Badger Grindewald #748",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445867,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_748_1681445873.png",
                "rarity_score": 100.0711070796542,
                "rarity_level": "RARE"
            }
        }
    },
    "1598": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1598_1681446028.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1598",
                "name": "Bot Badger Holden #1598",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1598_1681446028.png",
                "rarity_score": 78.4662526570456,
                "rarity_level": "COMMON"
            }
        }
    },
    "1637": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1637_1681446038.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1637",
                "name": "Calm Badger Scorpius #1637",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1637_1681446038.png",
                "rarity_score": 132.6283379428906,
                "rarity_level": "RARE"
            }
        }
    },
    "709": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/709_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "709",
                "name": "Anxious Badger Buckbeak #709",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445858,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_709_1681445865.png",
                "rarity_score": 72.48230209205734,
                "rarity_level": "COMMON"
            }
        }
    },
    "747": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/747_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "747",
                "name": "Poised Badger Sugar Ray #747",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445867,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_747_1681445875.png",
                "rarity_score": 118.24983805894243,
                "rarity_level": "RARE"
            }
        }
    },
    "798": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/798_1681445881.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "798",
                "name": "Powerful Badger Gilderoy #798",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_798_1681445881.png",
                "rarity_score": 58.15919876191873,
                "rarity_level": "COMMON"
            }
        }
    },
    "1594": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1594_1681446028.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1594",
                "name": "Panda Badger Tszyu #1594",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446022,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1594_1681446028.png",
                "rarity_score": 72.46912991404216,
                "rarity_level": "COMMON"
            }
        }
    },
    "713": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/713_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "713",
                "name": "Bad Badger Huck #713",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445859,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_713_1681445865.png",
                "rarity_score": 94.23875489377212,
                "rarity_level": "RARE"
            }
        }
    },
    "750": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/750_1681445874.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "750",
                "name": "Comfy Badger Jett #750",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445867,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_750_1681445874.png",
                "rarity_score": 256.6241985209805,
                "rarity_level": "EPIC"
            }
        }
    },
    "790": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/790_1681445880.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "790",
                "name": "Dopey Badger Colin #790",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445875,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_790_1681445880.png",
                "rarity_score": 54.86770057424373,
                "rarity_level": "COMMON"
            }
        }
    },
    "1604": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1604_1681446029.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1604",
                "name": "Moon Badger Sawyer #1604",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1604_1681446029.png",
                "rarity_score": 124.60286468287717,
                "rarity_level": "RARE"
            }
        }
    },
    "712": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/712_1681445865.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "712",
                "name": "Courageous Badger Severus #712",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445859,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_712_1681445865.png",
                "rarity_score": 77.6710126122129,
                "rarity_level": "COMMON"
            }
        }
    },
    "751": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/751_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "751",
                "name": "Friendly Badger Holly #751",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445867,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_751_1681445873.png",
                "rarity_score": 69.90316210094483,
                "rarity_level": "COMMON"
            }
        }
    },
    "1603": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1603_1681446029.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1603",
                "name": "Cunnilingus Badger Werner #1603",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1603_1681446029.png",
                "rarity_score": 79.01953113706138,
                "rarity_level": "COMMON"
            }
        }
    },
    "1641": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1641_1681446037.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1641",
                "name": "Scary Badger Bridger #1641",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1641_1681446037.png",
                "rarity_score": 70.56454036079532,
                "rarity_level": "COMMON"
            }
        }
    },
    "714": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/714_1681445866.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "714",
                "name": "Jealous Badger Frederick #714",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445859,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_714_1681445866.png",
                "rarity_score": 115.91301621250885,
                "rarity_level": "RARE"
            }
        }
    },
    "755": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/755_1681445874.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "755",
                "name": "Annoyed Badger Archer #755",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445868,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_755_1681445874.png",
                "rarity_score": 97.57204243852286,
                "rarity_level": "RARE"
            }
        }
    },
    "1608": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1608_1681446029.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1608",
                "name": "Angry Badger Roy #1608",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446024,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1608_1681446029.png",
                "rarity_score": 66.68635736867924,
                "rarity_level": "COMMON"
            }
        }
    },
    "1642": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1642_1681446037.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1642",
                "name": "Magnificent Badger Draco #1642",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446030,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1642_1681446037.png",
                "rarity_score": 66.89647367780543,
                "rarity_level": "COMMON"
            }
        }
    },
    "692": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/692_1681445866.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "692",
                "name": "Fancy Badger Peter #692",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445855,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_692_1681445866.png",
                "rarity_score": 111.19770222455946,
                "rarity_level": "RARE"
            }
        }
    },
    "756": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/756_1681445874.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "756",
                "name": "Clammy Badger Wladimir #756",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445868,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_756_1681445874.png",
                "rarity_score": 86.10213036184274,
                "rarity_level": "COMMON"
            }
        }
    },
    "1606": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1606_1681446029.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1606",
                "name": "Moon Badger Sebastian #1606",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446024,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1606_1681446029.png",
                "rarity_score": 71.27009442590287,
                "rarity_level": "COMMON"
            }
        }
    },
    "1643": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1643_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1643",
                "name": "Gleaming Badger Wladimir #1643",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446030,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1643_1681446039.png",
                "rarity_score": 74.15270147313491,
                "rarity_level": "COMMON"
            }
        }
    },
    "725": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/725_1681445867.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "725",
                "name": "Disgusted Badger Wilbur #725",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445862,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_725_1681445867.png",
                "rarity_score": 56.17004273540616,
                "rarity_level": "COMMON"
            }
        }
    },
    "757": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/757_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "757",
                "name": "Annoying Badger Fallon #757",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445868,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_757_1681445875.png",
                "rarity_score": 95.0495546305971,
                "rarity_level": "RARE"
            }
        }
    },
    "1602": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1602_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1602",
                "name": "Arrogant Badger Holly #1602",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1602_1681446030.png",
                "rarity_score": 104.44225022860567,
                "rarity_level": "RARE"
            }
        }
    },
    "1646": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1646_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1646",
                "name": "Upset Badger Dorian #1646",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446030,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1646_1681446039.png",
                "rarity_score": 178.64436794325246,
                "rarity_level": "RARE"
            }
        }
    },
    "724": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/724_1681445868.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "724",
                "name": "Curious Badger Creevy #724",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445862,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_724_1681445868.png",
                "rarity_score": 102.67034297570251,
                "rarity_level": "RARE"
            }
        }
    },
    "762": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/762_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "762",
                "name": "Broken Badger Flitwick #762",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_762_1681445873.png",
                "rarity_score": 58.70821100911501,
                "rarity_level": "COMMON"
            }
        }
    },
    "789": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/789_1681445881.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "789",
                "name": "Unusual Badger Nimbus #789",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445875,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_789_1681445881.png",
                "rarity_score": 303.3165019253902,
                "rarity_level": "EPIC"
            }
        }
    },
    "1619": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1619_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1619",
                "name": "Fine Badger Ishmael #1619",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1619_1681446030.png",
                "rarity_score": 59.5978835978836,
                "rarity_level": "COMMON"
            }
        }
    },
    "730": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/730_1681445868.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "730",
                "name": "Annoying Badger Rocky #730",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_730_1681445868.png",
                "rarity_score": 60.26165636498809,
                "rarity_level": "COMMON"
            }
        }
    },
    "763": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/763_1681445876.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "763",
                "name": "Lonely Badger Bronn #763",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_763_1681445876.png",
                "rarity_score": 97.91715488182618,
                "rarity_level": "RARE"
            }
        }
    },
    "1596": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1596_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1596",
                "name": "Misty Badger Cayden #1596",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446022,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1596_1681446030.png",
                "rarity_score": 88.47197854561658,
                "rarity_level": "COMMON"
            }
        }
    },
    "1649": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1649_1681446040.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1649",
                "name": "Good Badger Philip #1649",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446031,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1649_1681446040.png",
                "rarity_score": 153.7229644939839,
                "rarity_level": "RARE"
            }
        }
    },
    "722": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/722_1681445869.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "722",
                "name": "Beautiful Badger Jones #722",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445861,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_722_1681445869.png",
                "rarity_score": 117.31824182799072,
                "rarity_level": "RARE"
            }
        }
    },
    "768": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/768_1681445876.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "768",
                "name": "Clever Badger Cullen #768",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_768_1681445876.png",
                "rarity_score": 60.01589189206121,
                "rarity_level": "COMMON"
            }
        }
    },
    "1620": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1620_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1620",
                "name": "Jolly Badger Clay #1620",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446026,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1620_1681446030.png",
                "rarity_score": 230.34060376165638,
                "rarity_level": "EPIC"
            }
        }
    },
    "1650": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1650_1681446040.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1650",
                "name": "Repulsive Badger Harry Potter #1650",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446031,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1650_1681446040.png",
                "rarity_score": 85.90883621871286,
                "rarity_level": "COMMON"
            }
        }
    },
    "731": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/731_1681445871.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "731",
                "name": "Lavish Badger Gellert #731",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_731_1681445871.png",
                "rarity_score": 110.35058558308909,
                "rarity_level": "RARE"
            }
        }
    },
    "773": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/773_1681445876.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "773",
                "name": "Tired Badger Max #773",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_773_1681445876.png",
                "rarity_score": 70.52083555060014,
                "rarity_level": "COMMON"
            }
        }
    },
    "1622": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1622_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1622",
                "name": "Disturbed Badger Richard #1622",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446026,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1622_1681446030.png",
                "rarity_score": 51.233324882792154,
                "rarity_level": "COMMON"
            }
        }
    },
    "1651": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1651_1681446037.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1651",
                "name": "Concerned Badger Quirrel #1651",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446031,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1651_1681446037.png",
                "rarity_score": 55.98211787126857,
                "rarity_level": "COMMON"
            }
        }
    },
    "735": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/735_1681445871.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "735",
                "name": "Cautious Badger Ray #735",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_735_1681445871.png",
                "rarity_score": 109.71034889948933,
                "rarity_level": "RARE"
            }
        }
    },
    "774": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/774_1681445878.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "774",
                "name": "Talented Badger Bridger #774",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445872,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_774_1681445878.png",
                "rarity_score": 75.68170903586564,
                "rarity_level": "COMMON"
            }
        }
    },
    "1605": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1605_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1605",
                "name": "Asthmatic Badger Scabbers #1605",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1605_1681446030.png",
                "rarity_score": 166.155132006116,
                "rarity_level": "RARE"
            }
        }
    },
    "1653": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1653_1681446037.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1653",
                "name": "Handsome Badger Lewis #1653",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446031,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1653_1681446037.png",
                "rarity_score": 59.92810038813732,
                "rarity_level": "COMMON"
            }
        }
    },
    "732": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/732_1681445871.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "732",
                "name": "Horrible Badger Bartemius #732",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445864,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_732_1681445871.png",
                "rarity_score": 154.74110807080422,
                "rarity_level": "RARE"
            }
        }
    },
    "775": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/775_1681445877.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "775",
                "name": "Unsightly Badger Draco #775",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445872,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_775_1681445877.png",
                "rarity_score": 67.74170274170274,
                "rarity_level": "COMMON"
            }
        }
    },
    "1600": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1600_1681446030.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1600",
                "name": "Energetic Badger Archer #1600",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446023,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1600_1681446030.png",
                "rarity_score": 138.16566030460814,
                "rarity_level": "RARE"
            }
        }
    },
    "1654": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1654_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1654",
                "name": "Friendly Badger Draco #1654",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1654_1681446039.png",
                "rarity_score": 151.42448260783038,
                "rarity_level": "RARE"
            }
        }
    },
    "739": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/739_1681445871.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "739",
                "name": "Sleepy Badger Jett #739",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445865,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_739_1681445871.png",
                "rarity_score": 65.61071155844765,
                "rarity_level": "COMMON"
            }
        }
    },
    "776": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/776_1681445879.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "776",
                "name": "Bored Badger Albus #776",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445872,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_776_1681445879.png",
                "rarity_score": 71.9663035521737,
                "rarity_level": "COMMON"
            }
        }
    },
    "1623": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1623_1681446031.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1623",
                "name": "Pleasant Badger Gideon #1623",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446026,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1623_1681446031.png",
                "rarity_score": 58.5189514906187,
                "rarity_level": "COMMON"
            }
        }
    },
    "1656": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1656_1681446039.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1656",
                "name": "Condemned Badger Gatsby #1656",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1656_1681446039.png",
                "rarity_score": 56.70096584573602,
                "rarity_level": "COMMON"
            }
        }
    },
    "749": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/749_1681445872.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "749",
                "name": "Homeless Badger Ravenclaw #749",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445867,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_749_1681445872.png",
                "rarity_score": 60.963443771580984,
                "rarity_level": "COMMON"
            }
        }
    },
    "777": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/777_1681445877.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "777",
                "name": "Sore Badger Wulfric #777",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445872,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_777_1681445877.png",
                "rarity_score": 59.57810702818797,
                "rarity_level": "COMMON"
            }
        }
    },
    "1615": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1615_1681446031.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1615",
                "name": "Grumpy Badger Quentin #1615",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1615_1681446031.png",
                "rarity_score": 227.1691320892875,
                "rarity_level": "EPIC"
            }
        }
    },
    "1657": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1657_1681446042.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1657",
                "name": "Glorious Badger Cruz #1657",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1657_1681446042.png",
                "rarity_score": 95.50715093121048,
                "rarity_level": "RARE"
            }
        }
    },
    "745": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/745_1681445873.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "745",
                "name": "Naughty Badger Rudy #745",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445866,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_745_1681445873.png",
                "rarity_score": 118.01495599654477,
                "rarity_level": "RARE"
            }
        }
    },
    "1617": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1617_1681446031.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1617",
                "name": "Good Badger Shields #1617",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1617_1681446031.png",
                "rarity_score": 69.76450559895859,
                "rarity_level": "COMMON"
            }
        }
    },
    "1658": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1658_1681446040.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1658",
                "name": "Helpful Badger George #1658",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1658_1681446040.png",
                "rarity_score": 61.85137353582259,
                "rarity_level": "COMMON"
            }
        }
    },
    "1696": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1696_1681446046.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1696",
                "name": "Charming Badger Pettigrew #1696",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446041,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1696_1681446046.png",
                "rarity_score": 95.7199430223626,
                "rarity_level": "RARE"
            }
        }
    },
    "754": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/754_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "754",
                "name": "Itchy Badger Logan #754",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445868,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_754_1681445875.png",
                "rarity_score": 71.28524535987931,
                "rarity_level": "COMMON"
            }
        }
    },
    "1618": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1618_1681446031.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1618",
                "name": "Lonely Badger Coleman #1618",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1618_1681446031.png",
                "rarity_score": 177.5366738381361,
                "rarity_level": "RARE"
            }
        }
    },
    "1660": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1660_1681446040.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1660",
                "name": "Eager Badger Crookshanks #1660",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1660_1681446040.png",
                "rarity_score": 99.73846533062122,
                "rarity_level": "RARE"
            }
        }
    },
    "1697": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1697_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1697",
                "name": "Rekt Badger Fluffy #1697",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446041,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1697_1681446045.png",
                "rarity_score": 73.87804086381678,
                "rarity_level": "COMMON"
            }
        }
    },
    "761": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/761_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "761",
                "name": "Helpless Badger Niffler #761",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_761_1681445875.png",
                "rarity_score": 77.21019078216163,
                "rarity_level": "COMMON"
            }
        }
    },
    "1624": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1624_1681446033.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1624",
                "name": "Grumpy Badger Cruz #1624",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446027,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1624_1681446033.png",
                "rarity_score": 102.00989864979076,
                "rarity_level": "RARE"
            }
        }
    },
    "1668": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1668_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1668",
                "name": "Unusual Badger Rufus #1668",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446034,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1668_1681446045.png",
                "rarity_score": 100.46906376227197,
                "rarity_level": "RARE"
            }
        }
    },
    "1727": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1727_1681446053.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1727",
                "name": "Lovely Badger Khan #1727",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1727_1681446053.png",
                "rarity_score": 249.9996106331128,
                "rarity_level": "EPIC"
            }
        }
    },
    "765": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/765_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "765",
                "name": "Depressed Badger Sugar #765",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_765_1681445875.png",
                "rarity_score": 65.93743539586998,
                "rarity_level": "COMMON"
            }
        }
    },
    "1625": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1625_1681446034.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1625",
                "name": "Nervous Badger Quirrel #1625",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446027,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1625_1681446034.png",
                "rarity_score": 111.89384208988217,
                "rarity_level": "RARE"
            }
        }
    },
    "1669": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1669_1681446044.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1669",
                "name": "Fine Badger Leonard #1669",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446034,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1669_1681446044.png",
                "rarity_score": 109.95546778387765,
                "rarity_level": "RARE"
            }
        }
    },
    "1717": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1717_1681446052.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1717",
                "name": "Clammy Badger Langston #1717",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446045,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1717_1681446052.png",
                "rarity_score": 86.57549581591269,
                "rarity_level": "COMMON"
            }
        }
    },
    "746": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/746_1681445875.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "746",
                "name": "Mysterious Badger Kom #746",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445867,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_746_1681445875.png",
                "rarity_score": 75.02434279326035,
                "rarity_level": "COMMON"
            }
        }
    },
    "1638": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1638_1681446035.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1638",
                "name": "Panda Badger Roy #1638",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1638_1681446035.png",
                "rarity_score": 155.624618447423,
                "rarity_level": "RARE"
            }
        }
    },
    "1673": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1673_1681446042.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1673",
                "name": "Wild Badger Morrie #1673",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446035,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1673_1681446042.png",
                "rarity_score": 78.40180890999127,
                "rarity_level": "COMMON"
            }
        }
    },
    "1705": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1705_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1705",
                "name": "Bot Badger Pigwidgeon #1705",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1705_1681446051.png",
                "rarity_score": 339.4572781600321,
                "rarity_level": "EPIC"
            }
        }
    },
    "764": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/764_1681445876.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "764",
                "name": "Evil Badger Cayden #764",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445869,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_764_1681445876.png",
                "rarity_score": 90.23296296261901,
                "rarity_level": "RARE"
            }
        }
    },
    "1616": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1616_1681446035.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1616",
                "name": "Wandering Badger Antonin #1616",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446025,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1616_1681446035.png",
                "rarity_score": 137.48132006326972,
                "rarity_level": "RARE"
            }
        }
    },
    "1674": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1674_1681446041.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1674",
                "name": "Real Badger Calvin #1674",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446035,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1674_1681446041.png",
                "rarity_score": 280.8882993562514,
                "rarity_level": "EPIC"
            }
        }
    },
    "1703": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1703_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1703",
                "name": "Tired Badger Ethan #1703",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446042,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1703_1681446047.png",
                "rarity_score": 286.4724943163605,
                "rarity_level": "EPIC"
            }
        }
    },
    "766": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/766_1681445877.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "766",
                "name": "Ill Badger Toby #766",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445870,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_766_1681445877.png",
                "rarity_score": 71.88114392042608,
                "rarity_level": "COMMON"
            }
        }
    },
    "1633": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1633_1681446035.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1633",
                "name": "Naughty Badger Johnson #1633",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446028,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1633_1681446035.png",
                "rarity_score": 137.49889279249362,
                "rarity_level": "RARE"
            }
        }
    },
    "1675": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1675_1681446042.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1675",
                "name": "Cruel Badger Morrie #1675",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446036,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1675_1681446042.png",
                "rarity_score": 79.87585774935367,
                "rarity_level": "COMMON"
            }
        }
    },
    "1709": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1709_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1709",
                "name": "Elated Badger Ronan #1709",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1709_1681446051.png",
                "rarity_score": 70.80686794989252,
                "rarity_level": "COMMON"
            }
        }
    },
    "767": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/767_1681445878.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "767",
                "name": "Clean Badger Khan #767",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_767_1681445878.png",
                "rarity_score": 115.24950468992137,
                "rarity_level": "RARE"
            }
        }
    },
    "1640": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1640_1681446035.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1640",
                "name": "Filthy Badger Joe #1640",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446029,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1640_1681446035.png",
                "rarity_score": 63.908803210832204,
                "rarity_level": "COMMON"
            }
        }
    },
    "1676": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1676_1681446043.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1676",
                "name": "Horrible Badger Horace #1676",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446036,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1676_1681446043.png",
                "rarity_score": 125.82451124091855,
                "rarity_level": "RARE"
            }
        }
    },
    "1713": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1713_1681446052.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1713",
                "name": "Helpless Badger Grey #1713",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446044,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1713_1681446052.png",
                "rarity_score": 83.23741941969863,
                "rarity_level": "COMMON"
            }
        }
    },
    "771": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/771_1681445880.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "771",
                "name": "Scary Badger Sir Nicholaus #771",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445871,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_771_1681445880.png",
                "rarity_score": 132.56183217600466,
                "rarity_level": "RARE"
            }
        }
    },
    "786": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/786_1681445881.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "786",
                "name": "Mysterious Badger Cornelius #786",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445875,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_786_1681445881.png",
                "rarity_score": 106.6477512367625,
                "rarity_level": "RARE"
            }
        }
    },
    "785": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/785_1681445882.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "785",
                "name": "Foolish Badger Winston #785",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445875,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_785_1681445882.png",
                "rarity_score": 68.49765967274593,
                "rarity_level": "COMMON"
            }
        }
    },
    "783": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/783_1681445881.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "783",
                "name": "Worried Badger Fury #783",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445874,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_783_1681445881.png",
                "rarity_score": 65.65818594252595,
                "rarity_level": "COMMON"
            }
        }
    },
    "784": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/784_1681445882.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "784",
                "name": "Ill Badger Albus #784",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445874,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_784_1681445882.png",
                "rarity_score": 117.89070111581617,
                "rarity_level": "RARE"
            }
        }
    },
    "782": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/782_1681445882.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "782",
                "name": "Wicked Badger Holyfield #782",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445874,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_782_1681445882.png",
                "rarity_score": 248.04653419570866,
                "rarity_level": "EPIC"
            }
        }
    },
    "795": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/795_1681445882.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "795",
                "name": "Kind Badger Rufus #795",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445876,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_795_1681445882.png",
                "rarity_score": 62.199832106914016,
                "rarity_level": "COMMON"
            }
        }
    },
    "797": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/797_1681445882.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "797",
                "name": "Strange Badger Noah #797",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_797_1681445882.png",
                "rarity_score": 352.3395623068484,
                "rarity_level": "EPIC"
            }
        }
    },
    "792": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/792_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "792",
                "name": "Filthy Badger Castiel #792",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445876,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_792_1681445883.png",
                "rarity_score": 119.52817492299974,
                "rarity_level": "RARE"
            }
        }
    },
    "811": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/811_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "811",
                "name": "Annoying Badger Jay #811",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445878,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_811_1681445883.png",
                "rarity_score": 61.796511472838375,
                "rarity_level": "COMMON"
            }
        }
    },
    "799": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/799_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "799",
                "name": "Real Badger Watson #799",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_799_1681445883.png",
                "rarity_score": 131.49441342615683,
                "rarity_level": "RARE"
            }
        }
    },
    "802": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/802_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "802",
                "name": "Sore Badger Dorian #802",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_802_1681445883.png",
                "rarity_score": 392.7459176707153,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "804": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/804_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "804",
                "name": "Carebear Badger Edward #804",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_804_1681445883.png",
                "rarity_score": 99.21915177137134,
                "rarity_level": "RARE"
            }
        }
    },
    "805": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/805_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "805",
                "name": "Poised Badger Jem #805",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_805_1681445883.png",
                "rarity_score": 59.95597418219116,
                "rarity_level": "COMMON"
            }
        }
    },
    "793": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/793_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "793",
                "name": "Arrogant Badger Filius #793",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445876,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_793_1681445883.png",
                "rarity_score": 81.59075333935269,
                "rarity_level": "COMMON"
            }
        }
    },
    "796": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/796_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "796",
                "name": "Attractive Badger Blaise #796",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445876,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_796_1681445883.png",
                "rarity_score": 117.46598379131626,
                "rarity_level": "RARE"
            }
        }
    },
    "807": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/807_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "807",
                "name": "Depressed Badger Cayman #807",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445878,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_807_1681445883.png",
                "rarity_score": 65.84379473348999,
                "rarity_level": "COMMON"
            }
        }
    },
    "806": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/806_1681445883.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "806",
                "name": "Enchanting Badger Aragog #806",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445878,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_806_1681445883.png",
                "rarity_score": 175.62124546443158,
                "rarity_level": "RARE"
            }
        }
    },
    "809": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/809_1681445884.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "809",
                "name": "Proud Badger Carter #809",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445878,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_809_1681445884.png",
                "rarity_score": 78.48964431210761,
                "rarity_level": "COMMON"
            }
        }
    },
    "801": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/801_1681445884.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "801",
                "name": "Quaint Badger Lomachenko #801",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_801_1681445884.png",
                "rarity_score": 97.99325069443636,
                "rarity_level": "RARE"
            }
        }
    },
    "816": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/816_1681445884.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "816",
                "name": "Exuberant Badger Louis #816",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445880,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_816_1681445884.png",
                "rarity_score": 55.40542013648229,
                "rarity_level": "COMMON"
            }
        }
    },
    "803": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/803_1681445884.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "803",
                "name": "Tired Badger Fudge #803",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_803_1681445884.png",
                "rarity_score": 109.5026920026776,
                "rarity_level": "RARE"
            }
        }
    },
    "791": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/791_1681445884.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "791",
                "name": "Wicked Badger Fawkes #791",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445876,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_791_1681445884.png",
                "rarity_score": 361.21286855580354,
                "rarity_level": "EPIC"
            }
        }
    },
    "813": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/813_1681445884.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "813",
                "name": "Sleepy Badger Abernathy #813",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445879,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_813_1681445884.png",
                "rarity_score": 62.23482629740905,
                "rarity_level": "COMMON"
            }
        }
    },
    "812": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/812_1681445885.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "812",
                "name": "Embarrassed Badger Cruz #812",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445879,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_812_1681445885.png",
                "rarity_score": 93.506266463761,
                "rarity_level": "RARE"
            }
        }
    },
    "808": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/808_1681445885.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "808",
                "name": "Super Badger Phineas #808",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445878,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_808_1681445885.png",
                "rarity_score": 107.72575377766975,
                "rarity_level": "RARE"
            }
        }
    },
    "800": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/800_1681445885.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "800",
                "name": "Jolly Badger Lachlan #800",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445877,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_800_1681445885.png",
                "rarity_score": 79.23069730021402,
                "rarity_level": "COMMON"
            }
        }
    },
    "814": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/814_1681445885.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "814",
                "name": "Rich Badger Max #814",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445879,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_814_1681445885.png",
                "rarity_score": 91.39501957844075,
                "rarity_level": "RARE"
            }
        }
    },
    "815": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/815_1681445885.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "815",
                "name": "Tough Badger Heathcliff #815",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445879,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_815_1681445885.png",
                "rarity_score": 103.29734178818074,
                "rarity_level": "RARE"
            }
        }
    },
    "794": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/794_1681445885.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "794",
                "name": "Famous Badger Logan #794",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445876,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_794_1681445885.png",
                "rarity_score": 98.4419077635703,
                "rarity_level": "RARE"
            }
        }
    },
    "810": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/810_1681445886.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "810",
                "name": "Happy Badger Leonard #810",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445878,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_810_1681445886.png",
                "rarity_score": 111.83143421632552,
                "rarity_level": "RARE"
            }
        }
    },
    "819": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/819_1681445887.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "819",
                "name": "Rich Badger Marciano #819",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445881,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_819_1681445887.png",
                "rarity_score": 220.4950499316978,
                "rarity_level": "RARE"
            }
        }
    },
    "828": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/828_1681445887.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "828",
                "name": "Curious Badger Severus #828",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_828_1681445887.png",
                "rarity_score": 61.258070475967045,
                "rarity_level": "COMMON"
            }
        }
    },
    "818": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/818_1681445887.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "818",
                "name": "Nutty Badger Hogwart #818",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445880,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_818_1681445887.png",
                "rarity_score": 74.76039558414222,
                "rarity_level": "COMMON"
            }
        }
    },
    "821": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/821_1681445888.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "821",
                "name": "Silly Badger Chavez #821",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445881,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_821_1681445888.png",
                "rarity_score": 65.55831645350506,
                "rarity_level": "COMMON"
            }
        }
    },
    "825": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/825_1681445888.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "825",
                "name": "Delightful Badger Carter #825",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445882,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_825_1681445888.png",
                "rarity_score": 64.19309616283347,
                "rarity_level": "COMMON"
            }
        }
    },
    "824": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/824_1681445888.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "824",
                "name": "Bloody Badger Sir Nicholaus #824",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445882,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_824_1681445888.png",
                "rarity_score": 99.67388828665129,
                "rarity_level": "RARE"
            }
        }
    },
    "861": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/861_1681445896.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "861",
                "name": "Anal Badger Dudley #861",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445889,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_861_1681445896.png",
                "rarity_score": 278.99309466526114,
                "rarity_level": "EPIC"
            }
        }
    },
    "908": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/908_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "908",
                "name": "Blushing Badger Grey #908",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445898,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_908_1681445903.png",
                "rarity_score": 246.55340397028374,
                "rarity_level": "EPIC"
            }
        }
    },
    "945": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/945_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "945",
                "name": "Filthy Badger Hippogriff #945",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_945_1681445911.png",
                "rarity_score": 82.29210887390654,
                "rarity_level": "COMMON"
            }
        }
    },
    "826": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/826_1681445888.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "826",
                "name": "Frail Badger Lee #826",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_826_1681445888.png",
                "rarity_score": 78.73425709878715,
                "rarity_level": "COMMON"
            }
        }
    },
    "862": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/862_1681445894.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "862",
                "name": "Bookworm Badger Kom #862",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445889,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_862_1681445894.png",
                "rarity_score": 56.71475431747428,
                "rarity_level": "COMMON"
            }
        }
    },
    "893": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/893_1681445905.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "893",
                "name": "Funny Badger Bogrod #893",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_893_1681445905.png",
                "rarity_score": 102.54248417372558,
                "rarity_level": "RARE"
            }
        }
    },
    "950": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/950_1681445913.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "950",
                "name": "Charming Badger Mary #950",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445906,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_950_1681445913.png",
                "rarity_score": 447.52798410807736,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "829": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/829_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "829",
                "name": "Doubtful Badger Mandrake #829",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_829_1681445889.png",
                "rarity_score": 57.31520926166246,
                "rarity_level": "COMMON"
            }
        }
    },
    "817": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/817_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "817",
                "name": "Mushy Badger Sirius Black #817",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445880,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_817_1681445889.png",
                "rarity_score": 148.2971318165965,
                "rarity_level": "RARE"
            }
        }
    },
    "864": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/864_1681445895.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "864",
                "name": "Dildo Badger Nicolas #864",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445890,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_864_1681445895.png",
                "rarity_score": 64.88580328137546,
                "rarity_level": "COMMON"
            }
        }
    },
    "863": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/863_1681445895.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "863",
                "name": "Creepy Badger Boot #863",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445889,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_863_1681445895.png",
                "rarity_score": 88.8372413529054,
                "rarity_level": "COMMON"
            }
        }
    },
    "830": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/830_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "830",
                "name": "Dead Badger Collin #830",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_830_1681445889.png",
                "rarity_score": 63.670397349152886,
                "rarity_level": "COMMON"
            }
        }
    },
    "865": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/865_1681445894.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "865",
                "name": "Daft Badger Flint #865",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445890,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_865_1681445894.png",
                "rarity_score": 57.367630780631266,
                "rarity_level": "COMMON"
            }
        }
    },
    "895": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/895_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "895",
                "name": "Clever Badger Fallon #895",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_895_1681445902.png",
                "rarity_score": 80.0430663506894,
                "rarity_level": "COMMON"
            }
        }
    },
    "936": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/936_1681445910.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "936",
                "name": "Carebear Badger Ryker #936",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445904,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_936_1681445910.png",
                "rarity_score": 105.11492422055088,
                "rarity_level": "RARE"
            }
        }
    },
    "836": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/836_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "836",
                "name": "Odd Badger Fawkes #836",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445884,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_836_1681445889.png",
                "rarity_score": 60.8928512143394,
                "rarity_level": "COMMON"
            }
        }
    },
    "866": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/866_1681445896.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "866",
                "name": "Troubled Badger Cian #866",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445890,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_866_1681445896.png",
                "rarity_score": 66.70176757245217,
                "rarity_level": "COMMON"
            }
        }
    },
    "905": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/905_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "905",
                "name": "Gifted Badger Argus #905",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_905_1681445902.png",
                "rarity_score": 69.13618856801395,
                "rarity_level": "COMMON"
            }
        }
    },
    "931": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/931_1681445912.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "931",
                "name": "Attractive Badger Draco #931",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445903,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_931_1681445912.png",
                "rarity_score": 118.5901236336924,
                "rarity_level": "RARE"
            }
        }
    },
    "837": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/837_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "837",
                "name": "Blushing Badger Kenrick #837",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_837_1681445889.png",
                "rarity_score": 71.680671602117,
                "rarity_level": "COMMON"
            }
        }
    },
    "820": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/820_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "820",
                "name": "Lucky Badger Maxim #820",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445881,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_820_1681445889.png",
                "rarity_score": 132.43358474514434,
                "rarity_level": "RARE"
            }
        }
    },
    "822": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/822_1681445889.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "822",
                "name": "Amused Badger Maxim #822",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445881,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_822_1681445889.png",
                "rarity_score": 121.5816489006869,
                "rarity_level": "RARE"
            }
        }
    },
    "869": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/869_1681445896.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "869",
                "name": "Determined Badger Fox #869",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445891,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_869_1681445896.png",
                "rarity_score": 54.064010961153144,
                "rarity_level": "COMMON"
            }
        }
    },
    "831": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/831_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "831",
                "name": "Frail Badger Manny #831",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_831_1681445890.png",
                "rarity_score": 76.30258433519845,
                "rarity_level": "COMMON"
            }
        }
    },
    "870": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/870_1681445896.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "870",
                "name": "Troubled Badger Evander #870",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445891,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_870_1681445896.png",
                "rarity_score": 56.86334772484965,
                "rarity_level": "COMMON"
            }
        }
    },
    "904": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/904_1681445904.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "904",
                "name": "Puzzled Badger Holly #904",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_904_1681445904.png",
                "rarity_score": 168.98020043144248,
                "rarity_level": "RARE"
            }
        }
    },
    "944": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/944_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "944",
                "name": "Wandering Badger Malfoy #944",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_944_1681445911.png",
                "rarity_score": 61.31851610039514,
                "rarity_level": "COMMON"
            }
        }
    },
    "834": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/834_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "834",
                "name": "Annoying Badger Filch #834",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445884,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_834_1681445890.png",
                "rarity_score": 67.64908246895975,
                "rarity_level": "COMMON"
            }
        }
    },
    "871": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/871_1681445897.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "871",
                "name": "Shy Badger Oliver #871",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445891,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_871_1681445897.png",
                "rarity_score": 63.38633085245515,
                "rarity_level": "COMMON"
            }
        }
    },
    "912": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/912_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "912",
                "name": "Envious Badger Aberforth #912",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445898,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_912_1681445907.png",
                "rarity_score": 336.7981283257271,
                "rarity_level": "EPIC"
            }
        }
    },
    "967": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/967_1681445915.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "967",
                "name": "FUD Badger Errol #967",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_967_1681445915.png",
                "rarity_score": 115.39885201579546,
                "rarity_level": "RARE"
            }
        }
    },
    "827": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/827_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "827",
                "name": "Sleepy Badger Dumbledore #827",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_827_1681445890.png",
                "rarity_score": 307.1477820846441,
                "rarity_level": "EPIC"
            }
        }
    },
    "873": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/873_1681445897.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "873",
                "name": "Dull Badger Khan #873",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445891,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_873_1681445897.png",
                "rarity_score": 64.00246918135396,
                "rarity_level": "COMMON"
            }
        }
    },
    "913": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/913_1681445905.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "913",
                "name": "Mysterious Badger Ronan #913",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445898,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_913_1681445905.png",
                "rarity_score": 126.82878820809304,
                "rarity_level": "RARE"
            }
        }
    },
    "955": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/955_1681445914.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "955",
                "name": "Beautiful Badger Gauge #955",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445907,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_955_1681445914.png",
                "rarity_score": 98.85315496665727,
                "rarity_level": "RARE"
            }
        }
    },
    "838": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/838_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "838",
                "name": "Poor Badger Darcy #838",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_838_1681445890.png",
                "rarity_score": 92.74535335517896,
                "rarity_level": "RARE"
            }
        }
    },
    "823": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/823_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "823",
                "name": "Quaint Badger Bowtruckle #823",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445882,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_823_1681445890.png",
                "rarity_score": 118.96717791703068,
                "rarity_level": "RARE"
            }
        }
    },
    "874": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/874_1681445899.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "874",
                "name": "Unusual Badger Marcus #874",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_874_1681445899.png",
                "rarity_score": 112.67828020341734,
                "rarity_level": "RARE"
            }
        }
    },
    "872": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/872_1681445899.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "872",
                "name": "Fragile Badger Hagrid #872",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445891,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_872_1681445899.png",
                "rarity_score": 347.46657221217515,
                "rarity_level": "EPIC"
            }
        }
    },
    "846": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/846_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "846",
                "name": "Unusual Badger Major #846",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_846_1681445890.png",
                "rarity_score": 69.64886046798998,
                "rarity_level": "COMMON"
            }
        }
    },
    "876": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/876_1681445898.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "876",
                "name": "Clumsy Badger Longbottom #876",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_876_1681445898.png",
                "rarity_score": 60.40604741017908,
                "rarity_level": "COMMON"
            }
        }
    },
    "916": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/916_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "916",
                "name": "Wicked Badger Vernon #916",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445899,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_916_1681445907.png",
                "rarity_score": 75.8344829314215,
                "rarity_level": "COMMON"
            }
        }
    },
    "960": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/960_1681445914.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "960",
                "name": "Dead Badger Greyback #960",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445908,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_960_1681445914.png",
                "rarity_score": 103.18001242820833,
                "rarity_level": "RARE"
            }
        }
    },
    "847": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/847_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "847",
                "name": "Anxious Badger Marciano #847",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_847_1681445890.png",
                "rarity_score": 55.5642511226487,
                "rarity_level": "COMMON"
            }
        }
    },
    "875": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/875_1681445896.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "875",
                "name": "Daft Badger Ray #875",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_875_1681445896.png",
                "rarity_score": 59.71406289014282,
                "rarity_level": "COMMON"
            }
        }
    },
    "906": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/906_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "906",
                "name": "Charming Badger Cian #906",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_906_1681445903.png",
                "rarity_score": 67.13861625614885,
                "rarity_level": "COMMON"
            }
        }
    },
    "937": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/937_1681445912.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "937",
                "name": "Bewildered Badger Fury #937",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445904,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_937_1681445912.png",
                "rarity_score": 128.8082990746104,
                "rarity_level": "RARE"
            }
        }
    },
    "840": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/840_1681445890.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "840",
                "name": "Dark Badger Jacob #840",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_840_1681445890.png",
                "rarity_score": 70.67533943967172,
                "rarity_level": "COMMON"
            }
        }
    },
    "877": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/877_1681445898.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "877",
                "name": "Dildo Badger Laila #877",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_877_1681445898.png",
                "rarity_score": 98.79852064222192,
                "rarity_level": "RARE"
            }
        }
    },
    "915": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/915_1681445906.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "915",
                "name": "Yucky Badger Ethan #915",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445899,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_915_1681445906.png",
                "rarity_score": 111.60506705992867,
                "rarity_level": "RARE"
            }
        }
    },
    "954": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/954_1681445915.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "954",
                "name": "Good Badger Diggory #954",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445907,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_954_1681445915.png",
                "rarity_score": 116.68629426418954,
                "rarity_level": "RARE"
            }
        }
    },
    "851": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/851_1681445891.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "851",
                "name": "Crazy Badger Grey #851",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445887,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_851_1681445891.png",
                "rarity_score": 61.929379050006666,
                "rarity_level": "COMMON"
            }
        }
    },
    "878": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/878_1681445897.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "878",
                "name": "Dull Badger Robinson #878",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_878_1681445897.png",
                "rarity_score": 65.53735253792486,
                "rarity_level": "COMMON"
            }
        }
    },
    "911": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/911_1681445904.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "911",
                "name": "Obnoxious Badger Phineas #911",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445898,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_911_1681445904.png",
                "rarity_score": 92.26721321861808,
                "rarity_level": "RARE"
            }
        }
    },
    "949": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/949_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "949",
                "name": "Precious Badger Toby #949",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445906,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_949_1681445911.png",
                "rarity_score": 175.23453642643216,
                "rarity_level": "RARE"
            }
        }
    },
    "835": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/835_1681445891.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "835",
                "name": "Gentle Badger Maxim #835",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445884,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_835_1681445891.png",
                "rarity_score": 111.40078880086338,
                "rarity_level": "RARE"
            }
        }
    },
    "879": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/879_1681445898.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "879",
                "name": "Prickly Badger Quentin #879",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_879_1681445898.png",
                "rarity_score": 61.63995357207121,
                "rarity_level": "COMMON"
            }
        }
    },
    "917": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/917_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "917",
                "name": "Obnoxious Badger Brawley #917",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445899,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_917_1681445907.png",
                "rarity_score": 103.63638261430506,
                "rarity_level": "RARE"
            }
        }
    },
    "962": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/962_1681445915.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "962",
                "name": "Courageous Badger Binns #962",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_962_1681445915.png",
                "rarity_score": 87.77327497478963,
                "rarity_level": "COMMON"
            }
        }
    },
    "845": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/845_1681445891.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "845",
                "name": "Real Badger Cayden #845",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_845_1681445891.png",
                "rarity_score": 68.97619844798089,
                "rarity_level": "COMMON"
            }
        }
    },
    "880": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/880_1681445898.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "880",
                "name": "Gifted Badger Ronan #880",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_880_1681445898.png",
                "rarity_score": 158.42019551042108,
                "rarity_level": "RARE"
            }
        }
    },
    "918": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/918_1681445905.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "918",
                "name": "Attractive Badger Blaise #918",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445899,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_918_1681445905.png",
                "rarity_score": 68.47804468160356,
                "rarity_level": "COMMON"
            }
        }
    },
    "948": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/948_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "948",
                "name": "Anxious Badger Morrie #948",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445906,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_948_1681445911.png",
                "rarity_score": 72.11949775572612,
                "rarity_level": "COMMON"
            }
        }
    },
    "832": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/832_1681445892.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "832",
                "name": "Upset Badger Sirius #832",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445883,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_832_1681445892.png",
                "rarity_score": 262.0639505775355,
                "rarity_level": "EPIC"
            }
        }
    },
    "881": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/881_1681445897.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "881",
                "name": "Shill Badger Ronald #881",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_881_1681445897.png",
                "rarity_score": 55.05765271540626,
                "rarity_level": "COMMON"
            }
        }
    },
    "910": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/910_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "910",
                "name": "Modern Badger Tyson #910",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445898,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_910_1681445902.png",
                "rarity_score": 73.50201934454905,
                "rarity_level": "COMMON"
            }
        }
    },
    "933": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/933_1681445909.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "933",
                "name": "Creepy Badger Aragog #933",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445903,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_933_1681445909.png",
                "rarity_score": 63.62671042219505,
                "rarity_level": "COMMON"
            }
        }
    },
    "833": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/833_1681445891.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "833",
                "name": "Enthusiastic Badger Rhett #833",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445884,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_833_1681445891.png",
                "rarity_score": 120.0780036962419,
                "rarity_level": "RARE"
            }
        }
    },
    "882": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/882_1681445899.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "882",
                "name": "Elated Badger Muggle #882",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445892,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_882_1681445899.png",
                "rarity_score": 68.1729067448776,
                "rarity_level": "COMMON"
            }
        }
    },
    "922": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/922_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "922",
                "name": "Concerned Badger Fluffy #922",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445900,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_922_1681445907.png",
                "rarity_score": 84.85122419015093,
                "rarity_level": "COMMON"
            }
        }
    },
    "959": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/959_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "959",
                "name": "Blushing Badger Bellatrix #959",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445908,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_959_1681445919.png",
                "rarity_score": 110.52556522067728,
                "rarity_level": "RARE"
            }
        }
    },
    "852": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/852_1681445892.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "852",
                "name": "Elated Badger Noah #852",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445887,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_852_1681445892.png",
                "rarity_score": 109.50301807621534,
                "rarity_level": "RARE"
            }
        }
    },
    "883": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/883_1681445900.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "883",
                "name": "Gleaming Badger Ollivander #883",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445893,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_883_1681445900.png",
                "rarity_score": 97.36149235610176,
                "rarity_level": "RARE"
            }
        }
    },
    "924": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/924_1681445906.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "924",
                "name": "Troubled Badger Peverell #924",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445901,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_924_1681445906.png",
                "rarity_score": 68.13342060189562,
                "rarity_level": "COMMON"
            }
        }
    },
    "951": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/951_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "951",
                "name": "Testy Badger Albus #951",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445906,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_951_1681445911.png",
                "rarity_score": 252.83621821934517,
                "rarity_level": "EPIC"
            }
        }
    },
    "843": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/843_1681445892.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "843",
                "name": "Attractive Badger Crouch #843",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_843_1681445892.png",
                "rarity_score": 205.34254160464872,
                "rarity_level": "RARE"
            }
        }
    },
    "884": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/884_1681445899.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "884",
                "name": "Exuberant Badger Amos #884",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445893,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_884_1681445899.png",
                "rarity_score": 79.82456417409327,
                "rarity_level": "COMMON"
            }
        }
    },
    "921": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/921_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "921",
                "name": "HODL Badger Lily #921",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445900,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_921_1681445907.png",
                "rarity_score": 76.99899339807592,
                "rarity_level": "COMMON"
            }
        }
    },
    "957": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/957_1681445912.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "957",
                "name": "Adventurous Badger Salazar #957",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445907,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_957_1681445912.png",
                "rarity_score": 62.70105444421969,
                "rarity_level": "COMMON"
            }
        }
    },
    "858": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/858_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "858",
                "name": "Helpless Badger Griphook #858",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445888,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_858_1681445893.png",
                "rarity_score": 59.1961935525603,
                "rarity_level": "COMMON"
            }
        }
    },
    "885": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/885_1681445900.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "885",
                "name": "Ludicrous Badger Cullen #885",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445894,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_885_1681445900.png",
                "rarity_score": 81.00456974543667,
                "rarity_level": "COMMON"
            }
        }
    },
    "925": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/925_1681445908.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "925",
                "name": "Panda Badger Holm #925",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445901,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_925_1681445908.png",
                "rarity_score": 102.62758470800154,
                "rarity_level": "RARE"
            }
        }
    },
    "965": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/965_1681445917.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "965",
                "name": "Elegant Badger Rowan #965",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_965_1681445917.png",
                "rarity_score": 77.47898441777292,
                "rarity_level": "COMMON"
            }
        }
    },
    "853": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/853_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "853",
                "name": "Terrible Badger Dursley #853",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445887,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_853_1681445893.png",
                "rarity_score": 103.02987387906859,
                "rarity_level": "RARE"
            }
        }
    },
    "886": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/886_1681445901.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "886",
                "name": "Lucky Badger Sirius Black #886",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445894,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_886_1681445901.png",
                "rarity_score": 73.04039538307002,
                "rarity_level": "COMMON"
            }
        }
    },
    "927": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/927_1681445908.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "927",
                "name": "Anxious Badger Atticus #927",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445901,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_927_1681445908.png",
                "rarity_score": 96.47988590645247,
                "rarity_level": "RARE"
            }
        }
    },
    "969": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/969_1681445917.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "969",
                "name": "Famous Badger Cornelius #969",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445910,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_969_1681445917.png",
                "rarity_score": 78.41714888056583,
                "rarity_level": "COMMON"
            }
        }
    },
    "850": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/850_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "850",
                "name": "Real Badger Horace #850",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_850_1681445893.png",
                "rarity_score": 112.3535472357268,
                "rarity_level": "RARE"
            }
        }
    },
    "887": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/887_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "887",
                "name": "Defiant Badger Jake #887",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_887_1681445903.png",
                "rarity_score": 138.97276692801987,
                "rarity_level": "RARE"
            }
        }
    },
    "946": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/946_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "946",
                "name": "Homeless Badger Troy #946",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_946_1681445911.png",
                "rarity_score": 94.30621361013645,
                "rarity_level": "RARE"
            }
        }
    },
    "981": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/981_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "981",
                "name": "Fair Badger Bones #981",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445913,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_981_1681445919.png",
                "rarity_score": 87.14853156382839,
                "rarity_level": "COMMON"
            }
        }
    },
    "844": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/844_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "844",
                "name": "Ugly Badger Hermes #844",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_844_1681445893.png",
                "rarity_score": 92.7775683046152,
                "rarity_level": "RARE"
            }
        }
    },
    "888": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/888_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "888",
                "name": "Fair Badger Noah #888",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_888_1681445902.png",
                "rarity_score": 133.55435367703484,
                "rarity_level": "RARE"
            }
        }
    },
    "930": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/930_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "930",
                "name": "Excited Badger Phineas #930",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445903,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_930_1681445907.png",
                "rarity_score": 77.21177329000506,
                "rarity_level": "COMMON"
            }
        }
    },
    "961": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/961_1681445917.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "961",
                "name": "Sore Badger Watson #961",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_961_1681445917.png",
                "rarity_score": 64.11401463982406,
                "rarity_level": "COMMON"
            }
        }
    },
    "848": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/848_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "848",
                "name": "Perfect Badger George #848",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_848_1681445893.png",
                "rarity_score": 98.6376680050682,
                "rarity_level": "RARE"
            }
        }
    },
    "889": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/889_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "889",
                "name": "Foolish Badger Ollivander #889",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_889_1681445903.png",
                "rarity_score": 95.613465879488,
                "rarity_level": "RARE"
            }
        }
    },
    "938": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/938_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "938",
                "name": "Good Badger Diggory #938",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445904,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_938_1681445911.png",
                "rarity_score": 68.17689839841837,
                "rarity_level": "COMMON"
            }
        }
    },
    "982": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/982_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "982",
                "name": "Gorgeous Badger Draco #982",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445914,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_982_1681445921.png",
                "rarity_score": 119.16696321594623,
                "rarity_level": "RARE"
            }
        }
    },
    "854": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/854_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "854",
                "name": "Dildo Badger Fireball #854",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445887,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_854_1681445893.png",
                "rarity_score": 242.550575966981,
                "rarity_level": "EPIC"
            }
        }
    },
    "890": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/890_1681445901.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "890",
                "name": "Clever Badger Marcus #890",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_890_1681445901.png",
                "rarity_score": 97.92886045742716,
                "rarity_level": "RARE"
            }
        }
    },
    "926": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/926_1681445906.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "926",
                "name": "Tired Badger Gatsby #926",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445901,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_926_1681445906.png",
                "rarity_score": 61.384203252595036,
                "rarity_level": "COMMON"
            }
        }
    },
    "953": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/953_1681445914.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "953",
                "name": "Clean Badger Archie #953",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445907,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_953_1681445914.png",
                "rarity_score": 69.0106550592977,
                "rarity_level": "COMMON"
            }
        }
    },
    "855": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/855_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "855",
                "name": "Helpful Badger Scimgeour #855",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445887,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_855_1681445893.png",
                "rarity_score": 99.18073437677565,
                "rarity_level": "RARE"
            }
        }
    },
    "891": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/891_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "891",
                "name": "Perfect Badger Scimgeour #891",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_891_1681445903.png",
                "rarity_score": 108.41061066178223,
                "rarity_level": "RARE"
            }
        }
    },
    "943": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/943_1681445914.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "943",
                "name": "Cautious Badger Filch #943",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_943_1681445914.png",
                "rarity_score": 116.12370196773938,
                "rarity_level": "RARE"
            }
        }
    },
    "989": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/989_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "989",
                "name": "Degen Badger Lennie #989",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445915,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_989_1681445923.png",
                "rarity_score": 157.68779930424847,
                "rarity_level": "RARE"
            }
        }
    },
    "841": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/841_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "841",
                "name": "Bright Badger Fudge #841",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_841_1681445893.png",
                "rarity_score": 108.49358478291062,
                "rarity_level": "RARE"
            }
        }
    },
    "892": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/892_1681445901.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "892",
                "name": "Wild Badger Slughorn #892",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_892_1681445901.png",
                "rarity_score": 91.51175239884685,
                "rarity_level": "RARE"
            }
        }
    },
    "928": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/928_1681445909.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "928",
                "name": "Condemned Badger Bludger #928",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445902,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_928_1681445909.png",
                "rarity_score": 96.17807708493626,
                "rarity_level": "RARE"
            }
        }
    },
    "970": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/970_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "970",
                "name": "Bookworm Badger Griphook #970",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445910,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_970_1681445919.png",
                "rarity_score": 159.2573632605802,
                "rarity_level": "RARE"
            }
        }
    },
    "856": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/856_1681445893.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "856",
                "name": "Prickly Badger Dumbledore #856",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445887,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_856_1681445893.png",
                "rarity_score": 63.52336624528367,
                "rarity_level": "COMMON"
            }
        }
    },
    "894": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/894_1681445900.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "894",
                "name": "Bewildered Badger Mary #894",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445895,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_894_1681445900.png",
                "rarity_score": 71.49651770640196,
                "rarity_level": "COMMON"
            }
        }
    },
    "923": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/923_1681445908.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "923",
                "name": "Funny Badger Colin #923",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445901,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_923_1681445908.png",
                "rarity_score": 73.77675462970056,
                "rarity_level": "COMMON"
            }
        }
    },
    "963": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/963_1681445916.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "963",
                "name": "Tense Badger Neville #963",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_963_1681445916.png",
                "rarity_score": 69.37327165291343,
                "rarity_level": "COMMON"
            }
        }
    },
    "839": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/839_1681445894.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "839",
                "name": "Contagious Badger Lawrence #839",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_839_1681445894.png",
                "rarity_score": 128.8994401808062,
                "rarity_level": "RARE"
            }
        }
    },
    "896": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/896_1681445901.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "896",
                "name": "Mysterious Badger Mundungus #896",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445896,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_896_1681445901.png",
                "rarity_score": 76.57748220003494,
                "rarity_level": "COMMON"
            }
        }
    },
    "929": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/929_1681445908.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "929",
                "name": "Encouraging Badger Kai #929",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445902,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_929_1681445908.png",
                "rarity_score": 70.53356018557257,
                "rarity_level": "COMMON"
            }
        }
    },
    "968": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/968_1681445920.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "968",
                "name": "HODL Badger Filch #968",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_968_1681445920.png",
                "rarity_score": 139.48717580171882,
                "rarity_level": "RARE"
            }
        }
    },
    "842": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/842_1681445894.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "842",
                "name": "Aggressive Badger Paws #842",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445885,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_842_1681445894.png",
                "rarity_score": 100.07697589488053,
                "rarity_level": "RARE"
            }
        }
    },
    "897": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/897_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "897",
                "name": "Shy Badger Jess #897",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445896,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_897_1681445903.png",
                "rarity_score": 105.24296369221918,
                "rarity_level": "RARE"
            }
        }
    },
    "942": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/942_1681445911.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "942",
                "name": "Wicked Badger Jett #942",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_942_1681445911.png",
                "rarity_score": 59.31098250371822,
                "rarity_level": "COMMON"
            }
        }
    },
    "976": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/976_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "976",
                "name": "Excited Badger Errol #976",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445911,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_976_1681445919.png",
                "rarity_score": 107.3786672911969,
                "rarity_level": "RARE"
            }
        }
    },
    "859": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/859_1681445895.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "859",
                "name": "Bloody Badger Dorian #859",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445889,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_859_1681445895.png",
                "rarity_score": 67.65228200239379,
                "rarity_level": "COMMON"
            }
        }
    },
    "898": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/898_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "898",
                "name": "Charming Badger Ali #898",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445896,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_898_1681445903.png",
                "rarity_score": 150.40295268216767,
                "rarity_level": "RARE"
            }
        }
    },
    "941": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/941_1681445912.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "941",
                "name": "Unusual Badger Bridger #941",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_941_1681445912.png",
                "rarity_score": 67.97567311640981,
                "rarity_level": "COMMON"
            }
        }
    },
    "985": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/985_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "985",
                "name": "Witty Badger Dirk #985",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445914,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_985_1681445921.png",
                "rarity_score": 82.69488192838422,
                "rarity_level": "COMMON"
            }
        }
    },
    "857": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/857_1681445895.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "857",
                "name": "Carebear Badger Newt #857",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445888,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_857_1681445895.png",
                "rarity_score": 105.17309632179882,
                "rarity_level": "RARE"
            }
        }
    },
    "899": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/899_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "899",
                "name": "Quaint Badger Collin #899",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_899_1681445902.png",
                "rarity_score": 104.62009566450298,
                "rarity_level": "RARE"
            }
        }
    },
    "935": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/935_1681445908.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "935",
                "name": "Tough Badger Bridger #935",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445904,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_935_1681445908.png",
                "rarity_score": 58.06093321020082,
                "rarity_level": "COMMON"
            }
        }
    },
    "966": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/966_1681445917.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "966",
                "name": "Brave Badger Scimgeour #966",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_966_1681445917.png",
                "rarity_score": 85.17759962151378,
                "rarity_level": "COMMON"
            }
        }
    },
    "860": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/860_1681445895.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "860",
                "name": "Disgusted Badger Rufus #860",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445889,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_860_1681445895.png",
                "rarity_score": 110.27141545601876,
                "rarity_level": "RARE"
            }
        }
    },
    "901": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/901_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "901",
                "name": "Aggressive Badger Grawp #901",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_901_1681445902.png",
                "rarity_score": 62.97366948916994,
                "rarity_level": "COMMON"
            }
        }
    },
    "934": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/934_1681445910.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "934",
                "name": "Faithful Badger Harry #934",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445904,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_934_1681445910.png",
                "rarity_score": 101.14155042744525,
                "rarity_level": "RARE"
            }
        }
    },
    "973": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/973_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "973",
                "name": "Jolly Badger Bartemius #973",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445910,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_973_1681445919.png",
                "rarity_score": 106.28579911826029,
                "rarity_level": "RARE"
            }
        }
    },
    "849": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/849_1681445896.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "849",
                "name": "Dumb Badger Rubin #849",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445886,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_849_1681445896.png",
                "rarity_score": 150.44544051062582,
                "rarity_level": "RARE"
            }
        }
    },
    "907": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/907_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "907",
                "name": "Daft Badger Wilder #907",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_907_1681445903.png",
                "rarity_score": 174.64167053850815,
                "rarity_level": "RARE"
            }
        }
    },
    "939": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/939_1681445910.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "939",
                "name": "Lively Badger Max #939",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_939_1681445910.png",
                "rarity_score": 215.3307532754876,
                "rarity_level": "RARE"
            }
        }
    },
    "975": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/975_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "975",
                "name": "Ill Badger Ogre #975",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445911,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_975_1681445921.png",
                "rarity_score": 166.78073920929862,
                "rarity_level": "RARE"
            }
        }
    },
    "868": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/868_1681445897.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "868",
                "name": "Fierce Badger Bertie #868",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445891,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_868_1681445897.png",
                "rarity_score": 64.73973057912555,
                "rarity_level": "COMMON"
            }
        }
    },
    "909": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/909_1681445906.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "909",
                "name": "Jealous Badger Dursley #909",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445898,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_909_1681445906.png",
                "rarity_score": 132.70523722141576,
                "rarity_level": "RARE"
            }
        }
    },
    "956": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/956_1681445912.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "956",
                "name": "Proud Badger Philip #956",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445907,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_956_1681445912.png",
                "rarity_score": 54.94516884385968,
                "rarity_level": "COMMON"
            }
        }
    },
    "983": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/983_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "983",
                "name": "Mysterious Badger Augustus #983",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445914,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_983_1681445921.png",
                "rarity_score": 72.17390846116258,
                "rarity_level": "COMMON"
            }
        }
    },
    "867": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/867_1681445898.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "867",
                "name": "Arrogant Badger Cayman #867",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445890,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_867_1681445898.png",
                "rarity_score": 110.8457415354963,
                "rarity_level": "RARE"
            }
        }
    },
    "914": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/914_1681445905.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "914",
                "name": "Excited Badger Latham #914",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445899,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_914_1681445905.png",
                "rarity_score": 100.0718958569676,
                "rarity_level": "RARE"
            }
        }
    },
    "952": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/952_1681445915.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "952",
                "name": "Anxious Badger Rudy #952",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445906,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_952_1681445915.png",
                "rarity_score": 125.29408957556987,
                "rarity_level": "RARE"
            }
        }
    },
    "999": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/999_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "999",
                "name": "Friendly Badger Bronn #999",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445917,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_999_1681445923.png",
                "rarity_score": 102.38637958064541,
                "rarity_level": "RARE"
            }
        }
    },
    "900": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/900_1681445902.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "900",
                "name": "Degen Badger Noah #900",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_900_1681445902.png",
                "rarity_score": 103.35888155560288,
                "rarity_level": "RARE"
            }
        }
    },
    "932": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/932_1681445909.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "932",
                "name": "Tired Badger Archer #932",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445903,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_932_1681445909.png",
                "rarity_score": 86.07313329737656,
                "rarity_level": "COMMON"
            }
        }
    },
    "971": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/971_1681445917.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "971",
                "name": "Mysterious Badger Frederick #971",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445910,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_971_1681445917.png",
                "rarity_score": 98.42709941607879,
                "rarity_level": "RARE"
            }
        }
    },
    "1005": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1005_1681445926.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1005",
                "name": "Terrible Badger Ali #1005",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445918,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1005_1681445926.png",
                "rarity_score": 83.28688233178451,
                "rarity_level": "COMMON"
            }
        }
    },
    "903": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/903_1681445903.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "903",
                "name": "Upset Badger Tyrion #903",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_903_1681445903.png",
                "rarity_score": 70.80434314143005,
                "rarity_level": "COMMON"
            }
        }
    },
    "940": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/940_1681445913.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "940",
                "name": "Cunnilingus Badger Nox #940",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_940_1681445913.png",
                "rarity_score": 124.82728965006515,
                "rarity_level": "RARE"
            }
        }
    },
    "993": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/993_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "993",
                "name": "Depressed Badger Horace #993",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_993_1681445925.png",
                "rarity_score": 95.91529281629,
                "rarity_level": "RARE"
            }
        }
    },
    "1048": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1048_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1048",
                "name": "Misty Badger Malcolm #1048",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1048_1681445931.png",
                "rarity_score": 78.31337758345244,
                "rarity_level": "COMMON"
            }
        }
    },
    "902": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/902_1681445904.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "902",
                "name": "Dumb Badger Larkin #902",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445897,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_902_1681445904.png",
                "rarity_score": 92.69504522639528,
                "rarity_level": "RARE"
            }
        }
    },
    "947": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/947_1681445914.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "947",
                "name": "Modern Badger Malfoy #947",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445905,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_947_1681445914.png",
                "rarity_score": 84.70270158182615,
                "rarity_level": "COMMON"
            }
        }
    },
    "994": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/994_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "994",
                "name": "Misty Badger Fred #994",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_994_1681445923.png",
                "rarity_score": 80.19045704156503,
                "rarity_level": "COMMON"
            }
        }
    },
    "1032": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1032_1681445930.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1032",
                "name": "Charming Badger Dash #1032",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445923,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1032_1681445930.png",
                "rarity_score": 84.33923891083145,
                "rarity_level": "COMMON"
            }
        }
    },
    "919": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/919_1681445907.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "919",
                "name": "Bored Badger Dempsey #919",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445900,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_919_1681445907.png",
                "rarity_score": 264.1388457494861,
                "rarity_level": "EPIC"
            }
        }
    },
    "958": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/958_1681445914.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "958",
                "name": "Lonely Badger Nimbus #958",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445908,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_958_1681445914.png",
                "rarity_score": 70.40594141702198,
                "rarity_level": "COMMON"
            }
        }
    },
    "991": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/991_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "991",
                "name": "Annoying Badger Moore #991",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_991_1681445923.png",
                "rarity_score": 79.70028315939048,
                "rarity_level": "COMMON"
            }
        }
    },
    "1031": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1031_1681445932.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1031",
                "name": "Dangerous Badger Snape #1031",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445923,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1031_1681445932.png",
                "rarity_score": 126.68273163836439,
                "rarity_level": "RARE"
            }
        }
    },
    "920": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/920_1681445908.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "920",
                "name": "Foolish Badger Carter #920",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445900,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_920_1681445908.png",
                "rarity_score": 100.83569687415606,
                "rarity_level": "RARE"
            }
        }
    },
    "964": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/964_1681445917.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "964",
                "name": "Nice Badger Christer #964",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445909,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_964_1681445917.png",
                "rarity_score": 77.83706722929946,
                "rarity_level": "COMMON"
            }
        }
    },
    "1007": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1007_1681445924.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1007",
                "name": "Average Badger Crookshanks #1007",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445918,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1007_1681445924.png",
                "rarity_score": 61.523528729370604,
                "rarity_level": "COMMON"
            }
        }
    },
    "1043": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1043_1681445930.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1043",
                "name": "Delightful Badger Nargle #1043",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445925,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1043_1681445930.png",
                "rarity_score": 55.20353982123053,
                "rarity_level": "COMMON"
            }
        }
    },
    "972": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/972_1681445916.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "972",
                "name": "Clever Badger Corin #972",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445910,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_972_1681445916.png",
                "rarity_score": 73.62843137254902,
                "rarity_level": "COMMON"
            }
        }
    },
    "1001": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1001_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1001",
                "name": "Famous Badger Slughorn #1001",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445917,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1001_1681445923.png",
                "rarity_score": 68.85162868564473,
                "rarity_level": "COMMON"
            }
        }
    },
    "1038": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1038_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1038",
                "name": "Witty Badger Blaise #1038",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445924,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1038_1681445931.png",
                "rarity_score": 96.69528764558196,
                "rarity_level": "RARE"
            }
        }
    },
    "1077": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1077_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1077",
                "name": "Gifted Badger Hendrix #1077",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445932,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1077_1681445939.png",
                "rarity_score": 89.42699012801923,
                "rarity_level": "RARE"
            }
        }
    },
    "984": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/984_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "984",
                "name": "Rich Badger Crouch #984",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445914,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_984_1681445919.png",
                "rarity_score": 54.91832573200622,
                "rarity_level": "COMMON"
            }
        }
    },
    "1011": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1011_1681445927.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1011",
                "name": "Witty Badger Rubeus #1011",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445919,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1011_1681445927.png",
                "rarity_score": 118.6066761599568,
                "rarity_level": "RARE"
            }
        }
    },
    "1057": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1057_1681445932.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1057",
                "name": "Bloody Badger Felix #1057",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445928,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1057_1681445932.png",
                "rarity_score": 57.262730863670335,
                "rarity_level": "COMMON"
            }
        }
    },
    "1084": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1084_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1084",
                "name": "Kind Badger Max #1084",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1084_1681445939.png",
                "rarity_score": 81.96581057238417,
                "rarity_level": "COMMON"
            }
        }
    },
    "974": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/974_1681445919.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "974",
                "name": "Bright Badger Canelo #974",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445911,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_974_1681445919.png",
                "rarity_score": 139.6240013155981,
                "rarity_level": "RARE"
            }
        }
    },
    "1012": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1012_1681445926.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1012",
                "name": "Grumpy Badger Ollivander #1012",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445920,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1012_1681445926.png",
                "rarity_score": 64.19721963122349,
                "rarity_level": "COMMON"
            }
        }
    },
    "1053": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1053_1681445933.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1053",
                "name": "Disturbed Badger Werner #1053",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445927,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1053_1681445933.png",
                "rarity_score": 107.35830203378498,
                "rarity_level": "RARE"
            }
        }
    },
    "1093": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1093_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1093",
                "name": "Anal Badger Kingston #1093",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1093_1681445939.png",
                "rarity_score": 53.0621542155799,
                "rarity_level": "COMMON"
            }
        }
    },
    "979": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/979_1681445920.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "979",
                "name": "Hilarious Badger Fireball #979",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445913,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_979_1681445920.png",
                "rarity_score": 100.6326602356092,
                "rarity_level": "RARE"
            }
        }
    },
    "1017": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1017_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1017",
                "name": "Cryptic Badger Archer #1017",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445921,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1017_1681445925.png",
                "rarity_score": 83.0571477706331,
                "rarity_level": "COMMON"
            }
        }
    },
    "1051": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1051_1681445932.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1051",
                "name": "Expensive Badger Percy #1051",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1051_1681445932.png",
                "rarity_score": 117.01581506817705,
                "rarity_level": "RARE"
            }
        }
    },
    "1082": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1082_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1082",
                "name": "Panda Badger Connell #1082",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1082_1681445940.png",
                "rarity_score": 108.48331517506557,
                "rarity_level": "RARE"
            }
        }
    },
    "980": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/980_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "980",
                "name": "Famous Badger Viktor #980",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445913,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_980_1681445921.png",
                "rarity_score": 102.30184878151672,
                "rarity_level": "RARE"
            }
        }
    },
    "1019": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1019_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1019",
                "name": "Crazy Badger Riddle #1019",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445921,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1019_1681445931.png",
                "rarity_score": 146.46053561167324,
                "rarity_level": "RARE"
            }
        }
    },
    "1079": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1079_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1079",
                "name": "Ludicrous Badger Ray #1079",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1079_1681445939.png",
                "rarity_score": 104.81661303120805,
                "rarity_level": "RARE"
            }
        }
    },
    "1648": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1648_1681446037.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1648",
                "name": "Talented Badger Colin #1648",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446031,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1648_1681446037.png",
                "rarity_score": 68.93544496656898,
                "rarity_level": "COMMON"
            }
        }
    },
    "990": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/990_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "990",
                "name": "Elated Badger Grey #990",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_990_1681445921.png",
                "rarity_score": 103.93221672260623,
                "rarity_level": "RARE"
            }
        }
    },
    "1021": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1021_1681445929.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1021",
                "name": "Glamorous Badger Lewis #1021",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1021_1681445929.png",
                "rarity_score": 103.49326925018887,
                "rarity_level": "RARE"
            }
        }
    },
    "1064": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1064_1681445936.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1064",
                "name": "Colorful Badger Hippogriff #1064",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445929,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1064_1681445936.png",
                "rarity_score": 70.1409431813837,
                "rarity_level": "COMMON"
            }
        }
    },
    "1107": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1107_1681445941.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1107",
                "name": "Naughty Badger Muhammad #1107",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445937,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1107_1681445941.png",
                "rarity_score": 75.38063684016143,
                "rarity_level": "COMMON"
            }
        }
    },
    "986": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/986_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "986",
                "name": "Adventurous Badger Remus #986",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445915,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_986_1681445921.png",
                "rarity_score": 58.75185084055481,
                "rarity_level": "COMMON"
            }
        }
    },
    "1022": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1022_1681445928.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1022",
                "name": "Curious Badger Rubeus #1022",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1022_1681445928.png",
                "rarity_score": 196.50517503893622,
                "rarity_level": "RARE"
            }
        }
    },
    "1058": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1058_1681445934.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1058",
                "name": "Carebear Badger Kingsley #1058",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445928,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1058_1681445934.png",
                "rarity_score": 81.89181128663611,
                "rarity_level": "COMMON"
            }
        }
    },
    "1096": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1096_1681445941.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1096",
                "name": "Unsightly Badger Basilisk #1096",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445935,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1096_1681445941.png",
                "rarity_score": 71.44984809165187,
                "rarity_level": "COMMON"
            }
        }
    },
    "977": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/977_1681445921.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "977",
                "name": "Jealous Badger Holm #977",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445912,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_977_1681445921.png",
                "rarity_score": 606.3923306640737,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1024": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1024_1681445928.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1024",
                "name": "Handsome Badger Scimgeour #1024",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1024_1681445928.png",
                "rarity_score": 103.58134126871296,
                "rarity_level": "RARE"
            }
        }
    },
    "1060": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1060_1681445934.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1060",
                "name": "Nice Badger Landon #1060",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445929,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1060_1681445934.png",
                "rarity_score": 175.62066355246486,
                "rarity_level": "RARE"
            }
        }
    },
    "1099": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1099_1681445941.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1099",
                "name": "Funny Badger Gulliver #1099",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445935,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1099_1681445941.png",
                "rarity_score": 64.84752360471762,
                "rarity_level": "COMMON"
            }
        }
    },
    "978": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/978_1681445922.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "978",
                "name": "Real Badger Percival #978",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445913,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_978_1681445922.png",
                "rarity_score": 69.67031655335163,
                "rarity_level": "COMMON"
            }
        }
    },
    "1027": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1027_1681445930.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1027",
                "name": "Healthy Badger Harry #1027",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1027_1681445930.png",
                "rarity_score": 118.55011375661164,
                "rarity_level": "RARE"
            }
        }
    },
    "1072": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1072_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1072",
                "name": "Modern Badger Brawley #1072",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445931,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1072_1681445935.png",
                "rarity_score": 57.06383747722652,
                "rarity_level": "COMMON"
            }
        }
    },
    "1104": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1104_1681445942.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1104",
                "name": "Disturbed Badger Kelpie #1104",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445936,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1104_1681445942.png",
                "rarity_score": 75.20593754816056,
                "rarity_level": "COMMON"
            }
        }
    },
    "1000": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1000_1681445922.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1000",
                "name": "Brainy Badger Cesar #1000",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445917,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1000_1681445922.png",
                "rarity_score": 102.77612592838942,
                "rarity_level": "RARE"
            }
        }
    },
    "1028": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1028_1681445928.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1028",
                "name": "Eager Badger Wilder #1028",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445923,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1028_1681445928.png",
                "rarity_score": 62.54974415607377,
                "rarity_level": "COMMON"
            }
        }
    },
    "1062": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1062_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1062",
                "name": "Healthy Badger Jean #1062",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445929,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1062_1681445935.png",
                "rarity_score": 215.41596549021028,
                "rarity_level": "RARE"
            }
        }
    },
    "1659": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1659_1681446038.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1659",
                "name": "Busy Badger Dudley #1659",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446032,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1659_1681446038.png",
                "rarity_score": 57.610343553636106,
                "rarity_level": "COMMON"
            }
        }
    },
    "998": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/998_1681445922.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "998",
                "name": "Sore Badger Larkin #998",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445917,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_998_1681445922.png",
                "rarity_score": 269.02860487606256,
                "rarity_level": "EPIC"
            }
        }
    },
    "1029": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1029_1681445929.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1029",
                "name": "Cunnilingus Badger Bridger #1029",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445923,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1029_1681445929.png",
                "rarity_score": 119.07185680418183,
                "rarity_level": "RARE"
            }
        }
    },
    "1063": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1063_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1063",
                "name": "Cute Badger Duncan #1063",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445929,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1063_1681445935.png",
                "rarity_score": 64.37152744198279,
                "rarity_level": "COMMON"
            }
        }
    },
    "1102": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1102_1681445941.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1102",
                "name": "Hungry Badger Jarvis #1102",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445936,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1102_1681445941.png",
                "rarity_score": 77.02156975671166,
                "rarity_level": "COMMON"
            }
        }
    },
    "1002": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1002_1681445922.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1002",
                "name": "Rich Badger Jack #1002",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445917,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1002_1681445922.png",
                "rarity_score": 96.29814718549162,
                "rarity_level": "RARE"
            }
        }
    },
    "1030": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1030_1681445929.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1030",
                "name": "Embarrassed Badger Voldemort #1030",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445923,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1030_1681445929.png",
                "rarity_score": 101.10208928523235,
                "rarity_level": "RARE"
            }
        }
    },
    "1065": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1065_1681445936.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1065",
                "name": "Adventurous Badger Cullen #1065",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445929,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1065_1681445936.png",
                "rarity_score": 90.09589330405373,
                "rarity_level": "RARE"
            }
        }
    },
    "1645": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1645_1681446038.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1645",
                "name": "Testy Badger Lewis #1645",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446030,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1645_1681446038.png",
                "rarity_score": 66.44552039067713,
                "rarity_level": "COMMON"
            }
        }
    },
    "1004": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1004_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1004",
                "name": "Sore Badger Ogre #1004",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445918,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1004_1681445923.png",
                "rarity_score": 63.765437133871735,
                "rarity_level": "COMMON"
            }
        }
    },
    "1033": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1033_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1033",
                "name": "Wandering Badger Garrick #1033",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445924,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1033_1681445931.png",
                "rarity_score": 78.32883002692826,
                "rarity_level": "COMMON"
            }
        }
    },
    "1074": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1074_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1074",
                "name": "Glorious Badger Connell #1074",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445932,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1074_1681445940.png",
                "rarity_score": 133.50113365253878,
                "rarity_level": "RARE"
            }
        }
    },
    "1631": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1631_1681446040.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1631",
                "name": "Degen Badger Voldemort #1631",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446028,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1631_1681446040.png",
                "rarity_score": 170.10559330480513,
                "rarity_level": "RARE"
            }
        }
    },
    "987": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/987_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "987",
                "name": "Fantastic Badger Abernathy #987",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445915,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_987_1681445923.png",
                "rarity_score": 129.68055177305752,
                "rarity_level": "RARE"
            }
        }
    },
    "1035": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1035_1681445930.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1035",
                "name": "Helpful Badger Asher #1035",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445924,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1035_1681445930.png",
                "rarity_score": 90.29042343779186,
                "rarity_level": "RARE"
            }
        }
    },
    "1070": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1070_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1070",
                "name": "Evil Badger Manny #1070",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445930,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1070_1681445938.png",
                "rarity_score": 119.95488092963797,
                "rarity_level": "RARE"
            }
        }
    },
    "1671": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1671_1681446040.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1671",
                "name": "Jittery Badger Riddick #1671",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446035,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1671_1681446040.png",
                "rarity_score": 57.192216735104154,
                "rarity_level": "COMMON"
            }
        }
    },
    "1006": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1006_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1006",
                "name": "Itchy Badger Fawkes #1006",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445918,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1006_1681445923.png",
                "rarity_score": 75.82798678393036,
                "rarity_level": "COMMON"
            }
        }
    },
    "1037": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1037_1681445932.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1037",
                "name": "Super Badger Gringot #1037",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445924,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1037_1681445932.png",
                "rarity_score": 80.32312040046206,
                "rarity_level": "COMMON"
            }
        }
    },
    "1087": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1087_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1087",
                "name": "Powerful Badger Ronald #1087",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1087_1681445939.png",
                "rarity_score": 71.508142956932,
                "rarity_level": "COMMON"
            }
        }
    },
    "1663": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1663_1681446041.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1663",
                "name": "Dangerous Badger Walden #1663",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446033,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1663_1681446041.png",
                "rarity_score": 67.08379635909247,
                "rarity_level": "COMMON"
            }
        }
    },
    "988": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/988_1681445923.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "988",
                "name": "Grieving Badger Lee #988",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445915,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_988_1681445923.png",
                "rarity_score": 176.1070317548394,
                "rarity_level": "RARE"
            }
        }
    },
    "1009": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1009_1681445924.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1009",
                "name": "Dangerous Badger Latham #1009",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445919,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1009_1681445924.png",
                "rarity_score": 69.78723712399734,
                "rarity_level": "COMMON"
            }
        }
    },
    "1040": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1040_1681445929.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1040",
                "name": "Glorious Badger Kostya #1040",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445925,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1040_1681445929.png",
                "rarity_score": 53.53213029071571,
                "rarity_level": "COMMON"
            }
        }
    },
    "1039": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1039_1681445933.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1039",
                "name": "Annoyed Badger Hufflepuff #1039",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445925,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1039_1681445933.png",
                "rarity_score": 138.03935251269252,
                "rarity_level": "RARE"
            }
        }
    },
    "996": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/996_1681445924.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "996",
                "name": "Elated Badger Peter #996",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_996_1681445924.png",
                "rarity_score": 260.80123684978525,
                "rarity_level": "EPIC"
            }
        }
    },
    "1041": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1041_1681445930.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1041",
                "name": "Clumsy Badger Muhammad #1041",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445925,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1041_1681445930.png",
                "rarity_score": 83.28094266287764,
                "rarity_level": "COMMON"
            }
        }
    },
    "1073": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1073_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1073",
                "name": "Contagious Badger Chester #1073",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445931,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1073_1681445938.png",
                "rarity_score": 82.88208929343295,
                "rarity_level": "COMMON"
            }
        }
    },
    "1665": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1665_1681446042.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1665",
                "name": "Eager Badger Lysander #1665",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446034,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1665_1681446042.png",
                "rarity_score": 64.11557245838888,
                "rarity_level": "COMMON"
            }
        }
    },
    "1003": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1003_1681445924.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1003",
                "name": "Yucky Badger Binns #1003",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445918,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1003_1681445924.png",
                "rarity_score": 130.7994092172849,
                "rarity_level": "RARE"
            }
        }
    },
    "1042": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1042_1681445932.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1042",
                "name": "Clumsy Badger Salem #1042",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445925,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1042_1681445932.png",
                "rarity_score": 242.30462742677753,
                "rarity_level": "EPIC"
            }
        }
    },
    "1085": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1085_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1085",
                "name": "Poor Badger Amir #1085",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1085_1681445939.png",
                "rarity_score": 94.69661925877901,
                "rarity_level": "RARE"
            }
        }
    },
    "1667": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1667_1681446042.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1667",
                "name": "Fragile Badger Argus #1667",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446034,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1667_1681446042.png",
                "rarity_score": 66.8843285801135,
                "rarity_level": "COMMON"
            }
        }
    },
    "1013": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1013_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1013",
                "name": "Bright Badger Romeo #1013",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445920,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1013_1681445925.png",
                "rarity_score": 57.59514687100894,
                "rarity_level": "COMMON"
            }
        }
    },
    "1044": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1044_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1044",
                "name": "Dead Badger Niffler #1044",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445925,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1044_1681445931.png",
                "rarity_score": 61.3510455372955,
                "rarity_level": "COMMON"
            }
        }
    },
    "1076": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1076_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1076",
                "name": "Gifted Badger Fudge #1076",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445932,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1076_1681445940.png",
                "rarity_score": 118.88354720160554,
                "rarity_level": "RARE"
            }
        }
    },
    "1666": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1666_1681446042.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1666",
                "name": "Dopey Badger Buckbeak #1666",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446034,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1666_1681446042.png",
                "rarity_score": 65.35507537339777,
                "rarity_level": "COMMON"
            }
        }
    },
    "1008": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1008_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1008",
                "name": "Helpful Badger Cruz #1008",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445918,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1008_1681445925.png",
                "rarity_score": 79.03361886290327,
                "rarity_level": "COMMON"
            }
        }
    },
    "1045": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1045_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1045",
                "name": "Prickly Badger Firenze #1045",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1045_1681445935.png",
                "rarity_score": 167.65865767754565,
                "rarity_level": "RARE"
            }
        }
    },
    "1672": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1672_1681446043.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1672",
                "name": "Unsightly Badger Sirius Black #1672",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446035,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1672_1681446043.png",
                "rarity_score": 90.7211448891085,
                "rarity_level": "RARE"
            }
        }
    },
    "1710": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1710_1681446048.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1710",
                "name": "Fancy Badger Ray #1710",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1710_1681446048.png",
                "rarity_score": 56.963386678854505,
                "rarity_level": "COMMON"
            }
        }
    },
    "997": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/997_1681445924.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "997",
                "name": "Healthy Badger Wilbur #997",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_997_1681445924.png",
                "rarity_score": 99.39917476472395,
                "rarity_level": "RARE"
            }
        }
    },
    "1046": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1046_1681445933.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1046",
                "name": "Dolphin Badger Phineas #1046",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1046_1681445933.png",
                "rarity_score": 147.09478212773374,
                "rarity_level": "RARE"
            }
        }
    },
    "1094": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1094_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1094",
                "name": "Arrogant Badger Christopher #1094",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1094_1681445940.png",
                "rarity_score": 283.7342387524486,
                "rarity_level": "EPIC"
            }
        }
    },
    "1670": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1670_1681446043.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1670",
                "name": "Lazy Badger Nicolas #1670",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446035,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1670_1681446043.png",
                "rarity_score": 86.65251491669243,
                "rarity_level": "COMMON"
            }
        }
    },
    "992": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/992_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "992",
                "name": "Good Badger Prongs #992",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_992_1681445925.png",
                "rarity_score": 120.47258881089984,
                "rarity_level": "RARE"
            }
        }
    },
    "1047": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1047_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1047",
                "name": "Comfy Badger Holly #1047",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1047_1681445931.png",
                "rarity_score": 119.42553383863972,
                "rarity_level": "RARE"
            }
        }
    },
    "1080": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1080_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1080",
                "name": "Silly Badger Werner #1080",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1080_1681445938.png",
                "rarity_score": 62.503640824170354,
                "rarity_level": "COMMON"
            }
        }
    },
    "1664": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1664_1681446043.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1664",
                "name": "Tired Badger Pacquiao #1664",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446034,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1664_1681446043.png",
                "rarity_score": 94.96640503776523,
                "rarity_level": "RARE"
            }
        }
    },
    "1018": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1018_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1018",
                "name": "Anxious Badger Hippogriff #1018",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445921,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1018_1681445925.png",
                "rarity_score": 52.923568277841035,
                "rarity_level": "COMMON"
            }
        }
    },
    "1049": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1049_1681445933.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1049",
                "name": "Muddy Badger Thestral #1049",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1049_1681445933.png",
                "rarity_score": 73.59036356342735,
                "rarity_level": "COMMON"
            }
        }
    },
    "1679": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1679_1681446044.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1679",
                "name": "Dizzy Badger Clay #1679",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446038,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1679_1681446044.png",
                "rarity_score": 66.64099815634141,
                "rarity_level": "COMMON"
            }
        }
    },
    "1715": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1715_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1715",
                "name": "Fine Badger Fireball #1715",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446044,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1715_1681446051.png",
                "rarity_score": 86.13007511028333,
                "rarity_level": "COMMON"
            }
        }
    },
    "995": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/995_1681445925.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "995",
                "name": "Sleepy Badger Brawley #995",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445916,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_995_1681445925.png",
                "rarity_score": 179.42228169630602,
                "rarity_level": "RARE"
            }
        }
    },
    "1050": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1050_1681445936.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1050",
                "name": "Powerful Badger Everett #1050",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1050_1681445936.png",
                "rarity_score": 187.3872465663259,
                "rarity_level": "RARE"
            }
        }
    },
    "1678": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1678_1681446044.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1678",
                "name": "Gentle Badger Bastian #1678",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446038,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1678_1681446044.png",
                "rarity_score": 60.71474636915856,
                "rarity_level": "COMMON"
            }
        }
    },
    "1716": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1716_1681446050.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1716",
                "name": "Pleasant Badger Noah #1716",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446044,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1716_1681446050.png",
                "rarity_score": 104.04350931431858,
                "rarity_level": "RARE"
            }
        }
    },
    "1015": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1015_1681445926.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1015",
                "name": "Poor Badger Landon #1015",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445920,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1015_1681445926.png",
                "rarity_score": 60.08393820047955,
                "rarity_level": "COMMON"
            }
        }
    },
    "1054": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1054_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1054",
                "name": "Broken Badger Cedric #1054",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445927,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1054_1681445935.png",
                "rarity_score": 174.27960700183223,
                "rarity_level": "RARE"
            }
        }
    },
    "1695": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1695_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1695",
                "name": "Annoyed Badger Voldemort #1695",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1695_1681446045.png",
                "rarity_score": 67.50386397507219,
                "rarity_level": "COMMON"
            }
        }
    },
    "1718": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1718_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1718",
                "name": "Sleepy Badger Cayden #1718",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446045,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1718_1681446051.png",
                "rarity_score": 87.78748990204141,
                "rarity_level": "COMMON"
            }
        }
    },
    "1010": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1010_1681445926.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1010",
                "name": "Prickly Badger Lucius #1010",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445919,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1010_1681445926.png",
                "rarity_score": 66.33515589547902,
                "rarity_level": "COMMON"
            }
        }
    },
    "1055": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1055_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1055",
                "name": "Annoying Badger Kingston #1055",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445927,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1055_1681445931.png",
                "rarity_score": 55.84105006320369,
                "rarity_level": "COMMON"
            }
        }
    },
    "1075": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1075_1681445937.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1075",
                "name": "Repulsive Badger Weasley #1075",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445932,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1075_1681445937.png",
                "rarity_score": 77.60629707036634,
                "rarity_level": "COMMON"
            }
        }
    },
    "1683": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1683_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1683",
                "name": "Disgusted Badger Holly #1683",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446038,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1683_1681446045.png",
                "rarity_score": 77.6677165768331,
                "rarity_level": "COMMON"
            }
        }
    },
    "1014": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1014_1681445927.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1014",
                "name": "Lambo Badger Atticus #1014",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445920,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1014_1681445927.png",
                "rarity_score": 115.60240970809846,
                "rarity_level": "RARE"
            }
        }
    },
    "1056": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1056_1681445933.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1056",
                "name": "Filthy Badger Cedric #1056",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445928,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1056_1681445933.png",
                "rarity_score": 107.383122354531,
                "rarity_level": "RARE"
            }
        }
    },
    "1090": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1090_1681445941.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1090",
                "name": "Eager Badger Oliver #1090",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1090_1681445941.png",
                "rarity_score": 107.04741217155772,
                "rarity_level": "RARE"
            }
        }
    },
    "1681": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1681_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1681",
                "name": "Poised Badger Riddick #1681",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446038,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1681_1681446045.png",
                "rarity_score": 130.2226218455044,
                "rarity_level": "RARE"
            }
        }
    },
    "1023": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1023_1681445928.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1023",
                "name": "Average Badger Havelock #1023",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1023_1681445928.png",
                "rarity_score": 73.89574492992224,
                "rarity_level": "COMMON"
            }
        }
    },
    "1059": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1059_1681445934.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1059",
                "name": "Whale Badger Arlo #1059",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445928,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1059_1681445934.png",
                "rarity_score": 96.75706743758286,
                "rarity_level": "RARE"
            }
        }
    },
    "1095": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1095_1681445941.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1095",
                "name": "Cryptic Badger Miguel #1095",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1095_1681445941.png",
                "rarity_score": 159.5205310194253,
                "rarity_level": "RARE"
            }
        }
    },
    "1682": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1682_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1682",
                "name": "Repulsive Badger Jacob #1682",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446038,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1682_1681446045.png",
                "rarity_score": 97.09840555041794,
                "rarity_level": "RARE"
            }
        }
    },
    "1016": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1016_1681445928.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1016",
                "name": "Helpful Badger Antonio #1016",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445921,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1016_1681445928.png",
                "rarity_score": 149.2394872920544,
                "rarity_level": "RARE"
            }
        }
    },
    "1061": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1061_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1061",
                "name": "Repulsive Badger Cash #1061",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445929,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1061_1681445935.png",
                "rarity_score": 58.669063604801394,
                "rarity_level": "COMMON"
            }
        }
    },
    "1098": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1098_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1098",
                "name": "Bright Badger Hagrid #1098",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445935,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1098_1681445940.png",
                "rarity_score": 57.88355292119252,
                "rarity_level": "COMMON"
            }
        }
    },
    "1684": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1684_1681446045.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1684",
                "name": "Arrogant Badger Watson #1684",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1684_1681446045.png",
                "rarity_score": 62.18680533342047,
                "rarity_level": "COMMON"
            }
        }
    },
    "1020": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1020_1681445929.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1020",
                "name": "Cunnilingus Badger Hippogriff #1020",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1020_1681445929.png",
                "rarity_score": 373.86672610232563,
                "rarity_level": "EPIC"
            }
        }
    },
    "1067": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1067_1681445934.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1067",
                "name": "Beautiful Badger Dempsey #1067",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445930,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1067_1681445934.png",
                "rarity_score": 55.90534667509713,
                "rarity_level": "COMMON"
            }
        }
    },
    "1097": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1097_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1097",
                "name": "Asthmatic Badger Ali #1097",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445935,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1097_1681445939.png",
                "rarity_score": 79.19603775212714,
                "rarity_level": "COMMON"
            }
        }
    },
    "1691": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1691_1681446046.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1691",
                "name": "Adventurous Badger Gideon #1691",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1691_1681446046.png",
                "rarity_score": 101.45987470007427,
                "rarity_level": "RARE"
            }
        }
    },
    "1034": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1034_1681445930.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1034",
                "name": "Quaint Badger Basilisk #1034",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445924,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1034_1681445930.png",
                "rarity_score": 78.52151920913333,
                "rarity_level": "COMMON"
            }
        }
    },
    "1068": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1068_1681445937.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1068",
                "name": "Courageous Badger Maxim #1068",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445930,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1068_1681445937.png",
                "rarity_score": 88.8710373015616,
                "rarity_level": "COMMON"
            }
        }
    },
    "1689": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1689_1681446046.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1689",
                "name": "Happy Badger Ryker #1689",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1689_1681446046.png",
                "rarity_score": 90.25708170757073,
                "rarity_level": "RARE"
            }
        }
    },
    "1724": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1724_1681446052.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1724",
                "name": "Confused Badger Scimgeour #1724",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1724_1681446052.png",
                "rarity_score": 85.92734827966143,
                "rarity_level": "COMMON"
            }
        }
    },
    "1025": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1025_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1025",
                "name": "Perfect Badger Logan #1025",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1025_1681445931.png",
                "rarity_score": 123.57006249466436,
                "rarity_level": "RARE"
            }
        }
    },
    "1078": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1078_1681445937.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1078",
                "name": "Enchanting Badger Rowan #1078",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445932,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1078_1681445937.png",
                "rarity_score": 57.142568673690896,
                "rarity_level": "COMMON"
            }
        }
    },
    "1686": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1686_1681446046.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1686",
                "name": "Degen Badger Scimgeour #1686",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1686_1681446046.png",
                "rarity_score": 71.62253711128166,
                "rarity_level": "COMMON"
            }
        }
    },
    "1725": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1725_1681446053.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1725",
                "name": "Enchanting Badger Dudley #1725",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1725_1681446053.png",
                "rarity_score": 90.634922993203,
                "rarity_level": "RARE"
            }
        }
    },
    "1026": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1026_1681445931.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1026",
                "name": "Angry Badger Pacquiao #1026",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445922,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1026_1681445931.png",
                "rarity_score": 85.46270000119657,
                "rarity_level": "COMMON"
            }
        }
    },
    "1081": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1081_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1081",
                "name": "Charming Badger Troy #1081",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1081_1681445938.png",
                "rarity_score": 415.98322678117614,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1688": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1688_1681446046.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1688",
                "name": "Panda Badger Floyd #1688",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1688_1681446046.png",
                "rarity_score": 71.88883812223472,
                "rarity_level": "COMMON"
            }
        }
    },
    "1726": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1726_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1726",
                "name": "Brainy Badger Fitzwilliam #1726",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1726_1681446051.png",
                "rarity_score": 61.746746746746744,
                "rarity_level": "COMMON"
            }
        }
    },
    "1036": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1036_1681445932.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1036",
                "name": "Naughty Badger Dudley #1036",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445924,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1036_1681445932.png",
                "rarity_score": 74.65740176451446,
                "rarity_level": "COMMON"
            }
        }
    },
    "1086": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1086_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1086",
                "name": "FUD Badger Lysander #1086",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1086_1681445938.png",
                "rarity_score": 56.97045482454,
                "rarity_level": "COMMON"
            }
        }
    },
    "1687": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1687_1681446046.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1687",
                "name": "Angry Badger Hippogriff #1687",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1687_1681446046.png",
                "rarity_score": 90.29877055854095,
                "rarity_level": "RARE"
            }
        }
    },
    "1741": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1741_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1741",
                "name": "Clean Badger Jean #1741",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446049,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1741_1681446054.png",
                "rarity_score": 76.46159864080317,
                "rarity_level": "COMMON"
            }
        }
    },
    "1052": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1052_1681445933.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1052",
                "name": "Busy Badger Cruz #1052",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445926,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1052_1681445933.png",
                "rarity_score": 113.66242237720753,
                "rarity_level": "RARE"
            }
        }
    },
    "1092": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1092_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1092",
                "name": "Easy Badger Pacquiao #1092",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1092_1681445940.png",
                "rarity_score": 57.1210564011154,
                "rarity_level": "COMMON"
            }
        }
    },
    "1685": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1685_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1685",
                "name": "Lovely Badger Pacquiao #1685",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1685_1681446047.png",
                "rarity_score": 112.46901633499547,
                "rarity_level": "RARE"
            }
        }
    },
    "1742": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1742_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1742",
                "name": "Tired Badger Fudge #1742",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446049,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1742_1681446054.png",
                "rarity_score": 97.68478344307537,
                "rarity_level": "RARE"
            }
        }
    },
    "1066": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1066_1681445935.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1066",
                "name": "Good Badger Pigwidgeon #1066",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445930,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1066_1681445935.png",
                "rarity_score": 92.45701544054641,
                "rarity_level": "RARE"
            }
        }
    },
    "1700": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1700_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1700",
                "name": "Gentle Badger Theodore #1700",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446041,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1700_1681446047.png",
                "rarity_score": 58.09688047114478,
                "rarity_level": "COMMON"
            }
        }
    },
    "1731": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1731_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1731",
                "name": "Excited Badger Muggle #1731",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1731_1681446054.png",
                "rarity_score": 77.26953034571486,
                "rarity_level": "COMMON"
            }
        }
    },
    "1732": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1732_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1732",
                "name": "Clammy Badger Canelo #1732",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1732_1681446055.png",
                "rarity_score": 107.97140067651847,
                "rarity_level": "RARE"
            }
        }
    },
    "1069": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1069_1681445936.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1069",
                "name": "Charming Badger Vasyl #1069",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445930,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1069_1681445936.png",
                "rarity_score": 294.19825334364737,
                "rarity_level": "EPIC"
            }
        }
    },
    "1699": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1699_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1699",
                "name": "Panda Badger Ryker #1699",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446041,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1699_1681446047.png",
                "rarity_score": 312.94212174320046,
                "rarity_level": "EPIC"
            }
        }
    },
    "1728": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1728_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1728",
                "name": "Aggressive Badger Credence #1728",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1728_1681446055.png",
                "rarity_score": 258.74319406123675,
                "rarity_level": "EPIC"
            }
        }
    },
    "1733": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1733_1681446056.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1733",
                "name": "Terrible Badger Laila #1733",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1733_1681446056.png",
                "rarity_score": 120.77704567364273,
                "rarity_level": "RARE"
            }
        }
    },
    "1083": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1083_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1083",
                "name": "Friendly Badger Maxton #1083",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1083_1681445938.png",
                "rarity_score": 54.935123778457154,
                "rarity_level": "COMMON"
            }
        }
    },
    "1690": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1690_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1690",
                "name": "Tame Badger Bronn #1690",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1690_1681446047.png",
                "rarity_score": 120.20187190500616,
                "rarity_level": "RARE"
            }
        }
    },
    "1734": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1734_1681446053.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1734",
                "name": "Handsome Badger Christer #1734",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1734_1681446053.png",
                "rarity_score": 97.89158185922372,
                "rarity_level": "RARE"
            }
        }
    },
    "1744": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1744_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1744",
                "name": "Bad Badger Dexter #1744",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446049,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1744_1681446055.png",
                "rarity_score": 70.54692596419116,
                "rarity_level": "COMMON"
            }
        }
    },
    "1071": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1071_1681445938.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1071",
                "name": "Grieving Badger Hufflepuff #1071",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445931,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1071_1681445938.png",
                "rarity_score": 220.9768389138306,
                "rarity_level": "RARE"
            }
        }
    },
    "1677": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1677_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1677",
                "name": "Cruel Badger Lumos #1677",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446036,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1677_1681446047.png",
                "rarity_score": 425.4460772119371,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1736": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1736_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1736",
                "name": "Perfect Badger Noah #1736",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1736_1681446054.png",
                "rarity_score": 75.96671897013991,
                "rarity_level": "COMMON"
            }
        }
    },
    "1745": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1745_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1745",
                "name": "Colorful Badger Scimgeour #1745",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446049,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1745_1681446055.png",
                "rarity_score": 66.75718210735101,
                "rarity_level": "COMMON"
            }
        }
    },
    "1089": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1089_1681445939.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1089",
                "name": "Bored Badger Filch #1089",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1089_1681445939.png",
                "rarity_score": 64.50643590699288,
                "rarity_level": "COMMON"
            }
        }
    },
    "1692": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1692_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1692",
                "name": "Unsightly Badger Basilisk #1692",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1692_1681446047.png",
                "rarity_score": 118.4939270553603,
                "rarity_level": "RARE"
            }
        }
    },
    "1749": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1749_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1749",
                "name": "Poor Badger Santiago #1749",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446050,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1749_1681446055.png",
                "rarity_score": 54.295997195204286,
                "rarity_level": "COMMON"
            }
        }
    },
    "1735": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1735_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1735",
                "name": "Proud Badger Jean #1735",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1735_1681446055.png",
                "rarity_score": 74.0337536697478,
                "rarity_level": "COMMON"
            }
        }
    },
    "1088": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1088_1681445940.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1088",
                "name": "Dolphin Badger Fudge #1088",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445933,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1088_1681445940.png",
                "rarity_score": 75.43961246297384,
                "rarity_level": "COMMON"
            }
        }
    },
    "1091": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1091_1681445942.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1091",
                "name": "Charming Badger Weasley #1091",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445934,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1091_1681445942.png",
                "rarity_score": 107.84138059757868,
                "rarity_level": "RARE"
            }
        }
    },
    "1106": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1106_1681445942.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1106",
                "name": "Asthmatic Badger Severus #1106",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445937,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1106_1681445942.png",
                "rarity_score": 97.91542472362318,
                "rarity_level": "RARE"
            }
        }
    },
    "1100": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1100_1681445942.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1100",
                "name": "Angry Badger Lily #1100",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445936,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1100_1681445942.png",
                "rarity_score": 72.77452186839922,
                "rarity_level": "COMMON"
            }
        }
    },
    "1101": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1101_1681445942.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1101",
                "name": "Happy Badger Pigwidgeon #1101",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445936,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1101_1681445942.png",
                "rarity_score": 71.67774532555296,
                "rarity_level": "COMMON"
            }
        }
    },
    "1112": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1112_1681445943.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1112",
                "name": "Perfect Badger Aragog #1112",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445938,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1112_1681445943.png",
                "rarity_score": 61.9521221180414,
                "rarity_level": "COMMON"
            }
        }
    },
    "1109": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1109_1681445943.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1109",
                "name": "Eager Badger Toby #1109",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445937,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1109_1681445943.png",
                "rarity_score": 74.05353546990854,
                "rarity_level": "COMMON"
            }
        }
    },
    "1111": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1111_1681445943.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1111",
                "name": "Bot Badger Slughorn #1111",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445937,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1111_1681445943.png",
                "rarity_score": 77.03212762928698,
                "rarity_level": "COMMON"
            }
        }
    },
    "1118": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1118_1681445943.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1118",
                "name": "Happy Badger Bogrod #1118",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445939,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1118_1681445943.png",
                "rarity_score": 57.24386332905053,
                "rarity_level": "COMMON"
            }
        }
    },
    "1108": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1108_1681445944.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1108",
                "name": "Funny Badger Ronan #1108",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445937,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1108_1681445944.png",
                "rarity_score": 71.91634876222747,
                "rarity_level": "COMMON"
            }
        }
    },
    "1103": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1103_1681445944.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1103",
                "name": "Adventurous Badger Sir Nicholaus #1103",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445936,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1103_1681445944.png",
                "rarity_score": 139.0615730350346,
                "rarity_level": "RARE"
            }
        }
    },
    "1114": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1114_1681445944.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1114",
                "name": "Confused Badger Deontay #1114",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445938,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1114_1681445944.png",
                "rarity_score": 235.93652368081118,
                "rarity_level": "EPIC"
            }
        }
    },
    "1105": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1105_1681445944.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1105",
                "name": "Adventurous Badger Lomachenko #1105",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445936,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1105_1681445944.png",
                "rarity_score": 149.85954756163989,
                "rarity_level": "RARE"
            }
        }
    },
    "1119": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1119_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1119",
                "name": "Evil Badger Pigwidgeon #1119",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445939,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1119_1681445945.png",
                "rarity_score": 63.28101976272371,
                "rarity_level": "COMMON"
            }
        }
    },
    "1126": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1126_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1126",
                "name": "Sleepy Badger Longbottom #1126",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1126_1681445945.png",
                "rarity_score": 226.61820655663297,
                "rarity_level": "EPIC"
            }
        }
    },
    "1115": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1115_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1115",
                "name": "Powerful Badger Leonardo #1115",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445938,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1115_1681445945.png",
                "rarity_score": 105.39912943639048,
                "rarity_level": "RARE"
            }
        }
    },
    "1124": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1124_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1124",
                "name": "Unsightly Badger Newt #1124",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1124_1681445945.png",
                "rarity_score": 102.84403428913812,
                "rarity_level": "RARE"
            }
        }
    },
    "1122": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1122_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1122",
                "name": "Silly Badger Maxton #1122",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1122_1681445945.png",
                "rarity_score": 55.8079405344851,
                "rarity_level": "COMMON"
            }
        }
    },
    "1125": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1125_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1125",
                "name": "Concerned Badger Kostya #1125",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1125_1681445945.png",
                "rarity_score": 63.874011903388684,
                "rarity_level": "COMMON"
            }
        }
    },
    "1110": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1110_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1110",
                "name": "Byzantine Badger Peter #1110",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445937,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1110_1681445945.png",
                "rarity_score": 104.73235433271967,
                "rarity_level": "RARE"
            }
        }
    },
    "1116": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1116_1681445945.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1116",
                "name": "Real Badger Hogwart #1116",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445938,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1116_1681445945.png",
                "rarity_score": 93.07370492590762,
                "rarity_level": "RARE"
            }
        }
    },
    "1128": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1128_1681445946.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1128",
                "name": "Fantastic Badger Graham #1128",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1128_1681445946.png",
                "rarity_score": 112.70951741049541,
                "rarity_level": "RARE"
            }
        }
    },
    "1113": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1113_1681445946.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1113",
                "name": "Filthy Badger Viktor #1113",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445938,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1113_1681445946.png",
                "rarity_score": 115.26022110154096,
                "rarity_level": "RARE"
            }
        }
    },
    "1121": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1121_1681445946.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1121",
                "name": "Cunnilingus Badger Quentin #1121",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445939,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1121_1681445946.png",
                "rarity_score": 108.41902462814495,
                "rarity_level": "RARE"
            }
        }
    },
    "1139": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1139_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1139",
                "name": "Nutty Badger Snape #1139",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445942,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1139_1681445947.png",
                "rarity_score": 59.017141007987696,
                "rarity_level": "COMMON"
            }
        }
    },
    "1123": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1123_1681445946.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1123",
                "name": "Misty Badger Peter #1123",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1123_1681445946.png",
                "rarity_score": 100.76711773862229,
                "rarity_level": "RARE"
            }
        }
    },
    "1132": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1132_1681445946.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1132",
                "name": "Asthmatic Badger Gatsby #1132",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1132_1681445946.png",
                "rarity_score": 83.53907483583092,
                "rarity_level": "COMMON"
            }
        }
    },
    "1165": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1165_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1165",
                "name": "Filthy Badger Khan #1165",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445947,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1165_1681445953.png",
                "rarity_score": 74.84879488770167,
                "rarity_level": "COMMON"
            }
        }
    },
    "1143": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1143_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1143",
                "name": "FUD Badger Alecto #1143",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445943,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1143_1681445947.png",
                "rarity_score": 55.37300430676278,
                "rarity_level": "COMMON"
            }
        }
    },
    "1134": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1134_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1134",
                "name": "Eager Badger Boot #1134",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1134_1681445947.png",
                "rarity_score": 80.10377919034967,
                "rarity_level": "COMMON"
            }
        }
    },
    "1127": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1127_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1127",
                "name": "Bot Badger Atticus #1127",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445940,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1127_1681445947.png",
                "rarity_score": 71.89840719581052,
                "rarity_level": "COMMON"
            }
        }
    },
    "1136": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1136_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1136",
                "name": "Funny Badger Pacquiao #1136",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445942,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1136_1681445947.png",
                "rarity_score": 73.98676535786967,
                "rarity_level": "COMMON"
            }
        }
    },
    "1130": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1130_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1130",
                "name": "Brainy Badger Khan #1130",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1130_1681445947.png",
                "rarity_score": 174.5528404052666,
                "rarity_level": "RARE"
            }
        }
    },
    "1172": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1172_1681445956.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1172",
                "name": "Clean Badger Crookshanks #1172",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1172_1681445956.png",
                "rarity_score": 281.03149905206607,
                "rarity_level": "EPIC"
            }
        }
    },
    "1226": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1226_1681445962.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1226",
                "name": "Dildo Badger Mad-Eye Moody #1226",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1226_1681445962.png",
                "rarity_score": 71.24209215153337,
                "rarity_level": "COMMON"
            }
        }
    },
    "1266": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1266_1681445970.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1266",
                "name": "Scary Badger Tibbles #1266",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1266_1681445970.png",
                "rarity_score": 105.70107913849188,
                "rarity_level": "RARE"
            }
        }
    },
    "1145": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1145_1681445947.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1145",
                "name": "Moon Badger Zane #1145",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445943,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1145_1681445947.png",
                "rarity_score": 52.19662886339203,
                "rarity_level": "COMMON"
            }
        }
    },
    "1173": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1173_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1173",
                "name": "Clever Badger Henry #1173",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1173_1681445955.png",
                "rarity_score": 86.82490120431716,
                "rarity_level": "COMMON"
            }
        }
    },
    "1220": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1220_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1220",
                "name": "Glamorous Badger Creevy #1220",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445956,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1220_1681445961.png",
                "rarity_score": 258.07777629337147,
                "rarity_level": "EPIC"
            }
        }
    },
    "1259": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1259_1681445968.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1259",
                "name": "Energetic Badger Johnson #1259",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1259_1681445968.png",
                "rarity_score": 71.95618966731607,
                "rarity_level": "COMMON"
            }
        }
    },
    "1147": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1147_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1147",
                "name": "Shy Badger Puff #1147",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445943,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1147_1681445948.png",
                "rarity_score": 59.8684217375807,
                "rarity_level": "COMMON"
            }
        }
    },
    "1131": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1131_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1131",
                "name": "Condemned Badger Sir Nicholaus #1131",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1131_1681445948.png",
                "rarity_score": 240.9111747388415,
                "rarity_level": "EPIC"
            }
        }
    },
    "1140": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1140_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1140",
                "name": "Real Badger Lily #1140",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445942,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1140_1681445948.png",
                "rarity_score": 77.69952457028181,
                "rarity_level": "COMMON"
            }
        }
    },
    "1117": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1117_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1117",
                "name": "Contagious Badger Centaur #1117",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445939,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1117_1681445948.png",
                "rarity_score": 88.75059309902022,
                "rarity_level": "COMMON"
            }
        }
    },
    "1135": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1135_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1135",
                "name": "Cute Badger Paws #1135",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1135_1681445948.png",
                "rarity_score": 70.44356775881755,
                "rarity_level": "COMMON"
            }
        }
    },
    "1178": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1178_1681445954.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1178",
                "name": "Muddy Badger Flint #1178",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1178_1681445954.png",
                "rarity_score": 59.911312461296504,
                "rarity_level": "COMMON"
            }
        }
    },
    "1214": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1214_1681445962.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1214",
                "name": "Frail Badger Lucius #1214",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1214_1681445962.png",
                "rarity_score": 73.50917846369836,
                "rarity_level": "COMMON"
            }
        }
    },
    "1265": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1265_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1265",
                "name": "Poised Badger Amos #1265",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1265_1681445972.png",
                "rarity_score": 132.80244260560923,
                "rarity_level": "RARE"
            }
        }
    },
    "1142": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1142_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1142",
                "name": "Good Badger Gauge #1142",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445943,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1142_1681445948.png",
                "rarity_score": 92.44894667621361,
                "rarity_level": "RARE"
            }
        }
    },
    "1120": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1120_1681445948.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1120",
                "name": "Famous Badger Ferdinand #1120",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    }
                ],
                "create-date": 1681445939,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1120_1681445948.png",
                "rarity_score": 371.947143816023,
                "rarity_level": "EPIC"
            }
        }
    },
    "1179": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1179_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1179",
                "name": "Envious Badger Holly #1179",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1179_1681445953.png",
                "rarity_score": 74.94229079609985,
                "rarity_level": "COMMON"
            }
        }
    },
    "1180": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1180_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1180",
                "name": "Talented Badger Wilbur #1180",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1180_1681445955.png",
                "rarity_score": 72.17816670434003,
                "rarity_level": "COMMON"
            }
        }
    },
    "1150": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1150_1681445949.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1150",
                "name": "Enthusiastic Badger Yaxley #1150",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445944,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1150_1681445949.png",
                "rarity_score": 58.92663960031107,
                "rarity_level": "COMMON"
            }
        }
    },
    "1181": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1181_1681445956.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1181",
                "name": "Fragile Badger Potter #1181",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1181_1681445956.png",
                "rarity_score": 132.9455191615541,
                "rarity_level": "RARE"
            }
        }
    },
    "1227": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1227_1681445964.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1227",
                "name": "HODL Badger Ogre #1227",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1227_1681445964.png",
                "rarity_score": 94.18462149084206,
                "rarity_level": "RARE"
            }
        }
    },
    "1272": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1272_1681445971.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1272",
                "name": "Nasty Badger Wilder #1272",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445964,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1272_1681445971.png",
                "rarity_score": 71.32092811252407,
                "rarity_level": "COMMON"
            }
        }
    },
    "1146": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1146_1681445949.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1146",
                "name": "Silly Badger Diggory #1146",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445943,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1146_1681445949.png",
                "rarity_score": 72.41392310626699,
                "rarity_level": "COMMON"
            }
        }
    },
    "1133": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1133_1681445949.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1133",
                "name": "Poised Badger Canelo #1133",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1133_1681445949.png",
                "rarity_score": 126.46104560341436,
                "rarity_level": "RARE"
            }
        }
    },
    "1138": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1138_1681445949.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1138",
                "name": "Clumsy Badger Alvarez #1138",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445942,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1138_1681445949.png",
                "rarity_score": 111.8626798936822,
                "rarity_level": "RARE"
            }
        }
    },
    "1182": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1182_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1182",
                "name": "Clever Badger Toby #1182",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1182_1681445955.png",
                "rarity_score": 59.80572194078771,
                "rarity_level": "COMMON"
            }
        }
    },
    "1152": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1152_1681445949.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1152",
                "name": "Wandering Badger Fawkes #1152",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445945,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1152_1681445949.png",
                "rarity_score": 59.20482081546118,
                "rarity_level": "COMMON"
            }
        }
    },
    "1141": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1141_1681445949.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1141",
                "name": "Beautiful Badger Wilbur #1141",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445942,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1141_1681445949.png",
                "rarity_score": 82.5239531175182,
                "rarity_level": "COMMON"
            }
        }
    },
    "1185": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1185_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1185",
                "name": "Attractive Badger Jasper #1185",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1185_1681445955.png",
                "rarity_score": 78.1336655589331,
                "rarity_level": "COMMON"
            }
        }
    },
    "1186": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1186_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1186",
                "name": "Glorious Badger Dirk #1186",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1186_1681445958.png",
                "rarity_score": 80.3594994479185,
                "rarity_level": "COMMON"
            }
        }
    },
    "1149": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1149_1681445950.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1149",
                "name": "Bewildered Badger Bryce #1149",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445944,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1149_1681445950.png",
                "rarity_score": 61.49902571070455,
                "rarity_level": "COMMON"
            }
        }
    },
    "1187": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1187_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1187",
                "name": "Blushing Badger Calen #1187",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1187_1681445955.png",
                "rarity_score": 55.444746720235266,
                "rarity_level": "COMMON"
            }
        }
    },
    "1217": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1217_1681445960.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1217",
                "name": "Homeless Badger Huck #1217",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1217_1681445960.png",
                "rarity_score": 74.24386883743138,
                "rarity_level": "COMMON"
            }
        }
    },
    "1250": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1250_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1250",
                "name": "Exuberant Badger Greyback #1250",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445961,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1250_1681445966.png",
                "rarity_score": 63.97852172944102,
                "rarity_level": "COMMON"
            }
        }
    },
    "1144": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1144_1681445950.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1144",
                "name": "Dopey Badger Lumos #1144",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445943,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1144_1681445950.png",
                "rarity_score": 115.68694370978989,
                "rarity_level": "RARE"
            }
        }
    },
    "1137": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1137_1681445950.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1137",
                "name": "Rich Badger Ali #1137",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445942,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1137_1681445950.png",
                "rarity_score": 88.00517583130441,
                "rarity_level": "COMMON"
            }
        }
    },
    "1129": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1129_1681445950.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1129",
                "name": "Fierce Badger Holm #1129",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445941,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1129_1681445950.png",
                "rarity_score": 117.73745915806353,
                "rarity_level": "RARE"
            }
        }
    },
    "1188": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1188_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1188",
                "name": "Prickly Badger Sugar #1188",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1188_1681445955.png",
                "rarity_score": 55.979817391537274,
                "rarity_level": "COMMON"
            }
        }
    },
    "1151": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1151_1681445950.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1151",
                "name": "Awful Badger Rubeus #1151",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445945,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1151_1681445950.png",
                "rarity_score": 65.85554104579126,
                "rarity_level": "COMMON"
            }
        }
    },
    "1191": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1191_1681445957.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1191",
                "name": "Friendly Badger Bryce #1191",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445951,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1191_1681445957.png",
                "rarity_score": 98.33143299396451,
                "rarity_level": "RARE"
            }
        }
    },
    "1233": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1233_1681445963.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1233",
                "name": "Attractive Badger Fireball #1233",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445958,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1233_1681445963.png",
                "rarity_score": 58.406093477847634,
                "rarity_level": "COMMON"
            }
        }
    },
    "1268": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1268_1681445970.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1268",
                "name": "Bewildered Badger Richard #1268",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1268_1681445970.png",
                "rarity_score": 81.91110840458786,
                "rarity_level": "COMMON"
            }
        }
    },
    "1153": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1153_1681445950.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1153",
                "name": "Jittery Badger George #1153",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445945,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1153_1681445950.png",
                "rarity_score": 90.4010709678528,
                "rarity_level": "RARE"
            }
        }
    },
    "1192": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1192_1681445957.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1192",
                "name": "Condemned Badger Floyd #1192",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445951,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1192_1681445957.png",
                "rarity_score": 104.17811248127734,
                "rarity_level": "RARE"
            }
        }
    },
    "1229": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1229_1681445963.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1229",
                "name": "Glamorous Badger Hogwart #1229",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1229_1681445963.png",
                "rarity_score": 102.06521373744658,
                "rarity_level": "RARE"
            }
        }
    },
    "1269": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1269_1681445970.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1269",
                "name": "Muddy Badger Tiberius #1269",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1269_1681445970.png",
                "rarity_score": 88.94162741523802,
                "rarity_level": "COMMON"
            }
        }
    },
    "1154": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1154_1681445951.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1154",
                "name": "Itchy Badger Fawkes #1154",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445945,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1154_1681445951.png",
                "rarity_score": 64.45001259674444,
                "rarity_level": "COMMON"
            }
        }
    },
    "1193": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1193_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1193",
                "name": "Defiant Badger Dragon #1193",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445951,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1193_1681445959.png",
                "rarity_score": 118.90919517417024,
                "rarity_level": "RARE"
            }
        }
    },
    "1246": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1246_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1246",
                "name": "Nervous Badger Nicolas #1246",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1246_1681445965.png",
                "rarity_score": 72.64307301791254,
                "rarity_level": "COMMON"
            }
        }
    },
    "1281": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1281_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1281",
                "name": "Asthmatic Badger Toby #1281",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1281_1681445973.png",
                "rarity_score": 112.24481098578475,
                "rarity_level": "RARE"
            }
        }
    },
    "1155": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1155_1681445951.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1155",
                "name": "Encouraging Badger Moby #1155",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445945,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1155_1681445951.png",
                "rarity_score": 65.56984460661924,
                "rarity_level": "COMMON"
            }
        }
    },
    "1194": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1194_1681445957.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1194",
                "name": "Fierce Badger Lily #1194",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445951,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1194_1681445957.png",
                "rarity_score": 87.95021374618567,
                "rarity_level": "COMMON"
            }
        }
    },
    "1231": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1231_1681445962.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1231",
                "name": "Doubtful Badger Viktor #1231",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1231_1681445962.png",
                "rarity_score": 408.97552601109913,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1264": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1264_1681445969.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1264",
                "name": "Grieving Badger Gannon #1264",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1264_1681445969.png",
                "rarity_score": 133.26571408837748,
                "rarity_level": "RARE"
            }
        }
    },
    "1164": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1164_1681445951.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1164",
                "name": "Gorgeous Badger Marciano #1164",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445947,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1164_1681445951.png",
                "rarity_score": 59.76055116677882,
                "rarity_level": "COMMON"
            }
        }
    },
    "1195": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1195_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1195",
                "name": "Foolish Badger Mundungus #1195",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445952,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1195_1681445959.png",
                "rarity_score": 67.86954709501762,
                "rarity_level": "COMMON"
            }
        }
    },
    "1243": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1243_1681445967.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1243",
                "name": "Exuberant Badger Rowan #1243",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1243_1681445967.png",
                "rarity_score": 151.25095110504725,
                "rarity_level": "RARE"
            }
        }
    },
    "1296": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1296_1681445974.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1296",
                "name": "Bored Badger Orlando #1296",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1296_1681445974.png",
                "rarity_score": 59.29747732495223,
                "rarity_level": "COMMON"
            }
        }
    },
    "1148": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1148_1681445951.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1148",
                "name": "Talented Badger Nimbus #1148",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445944,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1148_1681445951.png",
                "rarity_score": 308.1467092987101,
                "rarity_level": "EPIC"
            }
        }
    },
    "1196": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1196_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1196",
                "name": "Impressive Badger Foreman #1196",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445952,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1196_1681445958.png",
                "rarity_score": 70.5820022621139,
                "rarity_level": "COMMON"
            }
        }
    },
    "1235": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1235_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1235",
                "name": "Calm Badger James #1235",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445958,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1235_1681445966.png",
                "rarity_score": 92.88800257930198,
                "rarity_level": "RARE"
            }
        }
    },
    "1291": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1291_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1291",
                "name": "Magnificent Badger Tibbles #1291",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1291_1681445972.png",
                "rarity_score": 54.79103397748138,
                "rarity_level": "COMMON"
            }
        }
    },
    "1156": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1156_1681445951.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1156",
                "name": "Disturbed Badger Khan #1156",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445946,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1156_1681445951.png",
                "rarity_score": 70.28457458171654,
                "rarity_level": "COMMON"
            }
        }
    },
    "1197": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1197_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1197",
                "name": "Powerful Badger Garrick #1197",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445952,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1197_1681445961.png",
                "rarity_score": 121.20507892423929,
                "rarity_level": "RARE"
            }
        }
    },
    "1260": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1260_1681445968.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1260",
                "name": "Disturbed Badger Ranger #1260",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1260_1681445968.png",
                "rarity_score": 77.62542015239983,
                "rarity_level": "COMMON"
            }
        }
    },
    "1299": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1299_1681445974.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1299",
                "name": "Glorious Badger Neville #1299",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1299_1681445974.png",
                "rarity_score": 63.27744924640056,
                "rarity_level": "COMMON"
            }
        }
    },
    "1159": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1159_1681445952.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1159",
                "name": "Eager Badger Tibbles #1159",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445946,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1159_1681445952.png",
                "rarity_score": 62.4379498420983,
                "rarity_level": "COMMON"
            }
        }
    },
    "1198": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1198_1681445957.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1198",
                "name": "Curious Badger Moore #1198",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445952,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1198_1681445957.png",
                "rarity_score": 56.90109715868396,
                "rarity_level": "COMMON"
            }
        }
    },
    "1230": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1230_1681445962.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1230",
                "name": "Famous Badger Brawley #1230",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1230_1681445962.png",
                "rarity_score": 52.520623339807386,
                "rarity_level": "COMMON"
            }
        }
    },
    "1262": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1262_1681445967.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1262",
                "name": "Arrogant Badger Muggle #1262",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1262_1681445967.png",
                "rarity_score": 62.88411178341086,
                "rarity_level": "COMMON"
            }
        }
    },
    "1158": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1158_1681445952.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1158",
                "name": "Whale Badger Leonardo #1158",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445946,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1158_1681445952.png",
                "rarity_score": 63.17899686026003,
                "rarity_level": "COMMON"
            }
        }
    },
    "1199": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1199_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1199",
                "name": "Colorful Badger Alastor #1199",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445952,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1199_1681445958.png",
                "rarity_score": 99.90044038142784,
                "rarity_level": "RARE"
            }
        }
    },
    "1239": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1239_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1239",
                "name": "Lively Badger Arlo #1239",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1239_1681445966.png",
                "rarity_score": 74.53366859904533,
                "rarity_level": "COMMON"
            }
        }
    },
    "1282": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1282_1681445971.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1282",
                "name": "Byzantine Badger Lysander #1282",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1282_1681445971.png",
                "rarity_score": 75.92786437726528,
                "rarity_level": "COMMON"
            }
        }
    },
    "1161": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1161_1681445952.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1161",
                "name": "Wicked Badger Foreman #1161",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445946,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1161_1681445952.png",
                "rarity_score": 92.26195549655785,
                "rarity_level": "RARE"
            }
        }
    },
    "1200": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1200_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1200",
                "name": "Dead Badger Abernathy #1200",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445952,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1200_1681445959.png",
                "rarity_score": 275.19325234778876,
                "rarity_level": "EPIC"
            }
        }
    },
    "1245": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1245_1681445967.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1245",
                "name": "Impressive Badger Gulliver #1245",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1245_1681445967.png",
                "rarity_score": 76.13801101785376,
                "rarity_level": "COMMON"
            }
        }
    },
    "1290": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1290_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1290",
                "name": "Nutty Badger Rhett #1290",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1290_1681445973.png",
                "rarity_score": 212.4801386960607,
                "rarity_level": "RARE"
            }
        }
    },
    "1170": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1170_1681445952.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1170",
                "name": "Shill Badger Alecto #1170",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1170_1681445952.png",
                "rarity_score": 57.594047227829684,
                "rarity_level": "COMMON"
            }
        }
    },
    "1201": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1201_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1201",
                "name": "Homeless Badger Binns #1201",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445953,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1201_1681445958.png",
                "rarity_score": 65.2945536002802,
                "rarity_level": "COMMON"
            }
        }
    },
    "1241": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1241_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1241",
                "name": "Kind Badger Clay #1241",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1241_1681445966.png",
                "rarity_score": 183.36535522408263,
                "rarity_level": "RARE"
            }
        }
    },
    "1284": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1284_1681445971.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1284",
                "name": "Witty Badger Vernon #1284",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445967,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1284_1681445971.png",
                "rarity_score": 57.42424242424242,
                "rarity_level": "COMMON"
            }
        }
    },
    "1157": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1157_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1157",
                "name": "Cute Badger Salazar #1157",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445946,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1157_1681445953.png",
                "rarity_score": 66.74971973444194,
                "rarity_level": "COMMON"
            }
        }
    },
    "1202": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1202_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1202",
                "name": "Dolphin Badger Braham #1202",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445953,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1202_1681445958.png",
                "rarity_score": 294.9591058964587,
                "rarity_level": "EPIC"
            }
        }
    },
    "1237": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1237_1681445964.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1237",
                "name": "Asthmatic Badger Philip #1237",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1237_1681445964.png",
                "rarity_score": 157.3201731251749,
                "rarity_level": "RARE"
            }
        }
    },
    "1273": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1273_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1273",
                "name": "Annoying Badger Kai #1273",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445965,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1273_1681445973.png",
                "rarity_score": 107.3300682414997,
                "rarity_level": "RARE"
            }
        }
    },
    "1160": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1160_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1160",
                "name": "Dolphin Badger Henry #1160",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445946,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1160_1681445953.png",
                "rarity_score": 78.17170491955343,
                "rarity_level": "COMMON"
            }
        }
    },
    "1205": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1205_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1205",
                "name": "Shill Badger Malfoy #1205",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1205_1681445958.png",
                "rarity_score": 58.61199467999072,
                "rarity_level": "COMMON"
            }
        }
    },
    "1236": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1236_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1236",
                "name": "FUD Badger Ferdinand #1236",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1236_1681445965.png",
                "rarity_score": 78.29704500323004,
                "rarity_level": "COMMON"
            }
        }
    },
    "1280": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1280_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1280",
                "name": "Brainy Badger Jacob #1280",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1280_1681445972.png",
                "rarity_score": 101.32428418814527,
                "rarity_level": "RARE"
            }
        }
    },
    "1167": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1167_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1167",
                "name": "Courageous Badger Nimbus #1167",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445947,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1167_1681445953.png",
                "rarity_score": 60.105746901260716,
                "rarity_level": "COMMON"
            }
        }
    },
    "1204": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1204_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1204",
                "name": "Confused Badger Hedwig #1204",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1204_1681445958.png",
                "rarity_score": 55.045852336386474,
                "rarity_level": "COMMON"
            }
        }
    },
    "1240": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1240_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1240",
                "name": "Lively Badger Augustus #1240",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1240_1681445965.png",
                "rarity_score": 68.17343306739959,
                "rarity_level": "COMMON"
            }
        }
    },
    "1275": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1275_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1275",
                "name": "Whale Badger Wentworth #1275",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445965,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1275_1681445973.png",
                "rarity_score": 159.91934918760646,
                "rarity_level": "RARE"
            }
        }
    },
    "1177": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1177_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1177",
                "name": "FUD Badger Roy #1177",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1177_1681445953.png",
                "rarity_score": 59.92244226748282,
                "rarity_level": "COMMON"
            }
        }
    },
    "1206": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1206_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1206",
                "name": "Lavish Badger Rocky #1206",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1206_1681445959.png",
                "rarity_score": 255.8262256233929,
                "rarity_level": "EPIC"
            }
        }
    },
    "1248": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1248_1681445968.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1248",
                "name": "Charming Badger Cornelius #1248",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1248_1681445968.png",
                "rarity_score": 80.33895647970363,
                "rarity_level": "COMMON"
            }
        }
    },
    "1300": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1300_1681445974.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1300",
                "name": "Defiant Badger Leonard #1300",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1300_1681445974.png",
                "rarity_score": 60.548602210166415,
                "rarity_level": "COMMON"
            }
        }
    },
    "1169": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1169_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1169",
                "name": "Funny Badger Pacquiao #1169",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1169_1681445953.png",
                "rarity_score": 164.3944858263112,
                "rarity_level": "RARE"
            }
        }
    },
    "1207": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1207_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1207",
                "name": "Mysterious Badger Malfoy #1207",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1207_1681445959.png",
                "rarity_score": 62.138418491886306,
                "rarity_level": "COMMON"
            }
        }
    },
    "1247": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1247_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1247",
                "name": "Modern Badger Hagrid #1247",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1247_1681445966.png",
                "rarity_score": 77.72497434073227,
                "rarity_level": "COMMON"
            }
        }
    },
    "1283": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1283_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1283",
                "name": "Obnoxious Badger Logan #1283",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1283_1681445973.png",
                "rarity_score": 140.02399537668,
                "rarity_level": "RARE"
            }
        }
    },
    "1162": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1162_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1162",
                "name": "Dizzy Badger Quirrel #1162",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445947,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1162_1681445953.png",
                "rarity_score": 129.87920344934238,
                "rarity_level": "RARE"
            }
        }
    },
    "1208": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1208_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1208",
                "name": "Quaint Badger Dragon #1208",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1208_1681445959.png",
                "rarity_score": 71.39414553700269,
                "rarity_level": "COMMON"
            }
        }
    },
    "1242": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1242_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1242",
                "name": "Carebear Badger Malcolm #1242",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1242_1681445966.png",
                "rarity_score": 81.15359663215335,
                "rarity_level": "COMMON"
            }
        }
    },
    "1285": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1285_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1285",
                "name": "Mushy Badger Wladimir #1285",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445967,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1285_1681445973.png",
                "rarity_score": 60.47539236259361,
                "rarity_level": "COMMON"
            }
        }
    },
    "1163": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1163_1681445953.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1163",
                "name": "Yucky Badger Lomachenko #1163",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445947,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1163_1681445953.png",
                "rarity_score": 77.4139555027172,
                "rarity_level": "COMMON"
            }
        }
    },
    "1209": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1209_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1209",
                "name": "Courageous Badger Wilder #1209",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1209_1681445961.png",
                "rarity_score": 88.0700901335164,
                "rarity_level": "COMMON"
            }
        }
    },
    "1256": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1256_1681445967.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1256",
                "name": "Helpless Badger Arlo #1256",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1256_1681445967.png",
                "rarity_score": 60.200101405842865,
                "rarity_level": "COMMON"
            }
        }
    },
    "1295": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1295_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1295",
                "name": "Bloody Badger Filch #1295",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1295_1681445975.png",
                "rarity_score": 92.77999465634186,
                "rarity_level": "RARE"
            }
        }
    },
    "1174": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1174_1681445954.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1174",
                "name": "Filthy Badger Pettigrew #1174",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1174_1681445954.png",
                "rarity_score": 67.00057090377396,
                "rarity_level": "COMMON"
            }
        }
    },
    "1211": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1211_1681445960.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1211",
                "name": "Good Badger Bludger #1211",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1211_1681445960.png",
                "rarity_score": 219.09151281855338,
                "rarity_level": "RARE"
            }
        }
    },
    "1253": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1253_1681445968.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1253",
                "name": "Nervous Badger Aragog #1253",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445961,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1253_1681445968.png",
                "rarity_score": 73.63508642722466,
                "rarity_level": "COMMON"
            }
        }
    },
    "1297": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1297_1681445976.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1297",
                "name": "Cruel Badger Deontay #1297",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1297_1681445976.png",
                "rarity_score": 77.85717326752824,
                "rarity_level": "COMMON"
            }
        }
    },
    "1166": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1166_1681445954.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1166",
                "name": "Brave Badger Colin #1166",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445947,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1166_1681445954.png",
                "rarity_score": 124.8182680588113,
                "rarity_level": "RARE"
            }
        }
    },
    "1212": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1212_1681445959.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1212",
                "name": "Average Badger Christer #1212",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1212_1681445959.png",
                "rarity_score": 60.74659639877031,
                "rarity_level": "COMMON"
            }
        }
    },
    "1244": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1244_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1244",
                "name": "Lively Badger Caelan #1244",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1244_1681445966.png",
                "rarity_score": 101.28616551133487,
                "rarity_level": "RARE"
            }
        }
    },
    "1289": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1289_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1289",
                "name": "Filthy Badger Heathcliff #1289",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445967,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1289_1681445972.png",
                "rarity_score": 59.42154397254033,
                "rarity_level": "COMMON"
            }
        }
    },
    "1176": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1176_1681445954.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1176",
                "name": "Confused Badger Dumbledore #1176",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1176_1681445954.png",
                "rarity_score": 95.68679699186632,
                "rarity_level": "RARE"
            }
        }
    },
    "1215": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1215_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1215",
                "name": "Modern Badger Fireball #1215",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1215_1681445961.png",
                "rarity_score": 116.75431411958087,
                "rarity_level": "RARE"
            }
        }
    },
    "1254": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1254_1681445969.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1254",
                "name": "Ugly Badger Hedwig #1254",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445961,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1254_1681445969.png",
                "rarity_score": 137.4199635694099,
                "rarity_level": "RARE"
            }
        }
    },
    "1305": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1305_1681445978.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1305",
                "name": "Asthmatic Badger Centaur #1305",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445970,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1305_1681445978.png",
                "rarity_score": 261.1685694729537,
                "rarity_level": "EPIC"
            }
        }
    },
    "1175": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1175_1681445954.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1175",
                "name": "Beautiful Badger Lee #1175",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445949,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1175_1681445954.png",
                "rarity_score": 176.525308157683,
                "rarity_level": "RARE"
            }
        }
    },
    "1213": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1213_1681445962.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1213",
                "name": "Evil Badger Ron #1213",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1213_1681445962.png",
                "rarity_score": 94.67797723683749,
                "rarity_level": "RARE"
            }
        }
    },
    "1263": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1263_1681445967.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1263",
                "name": "Precious Badger Holly #1263",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1263_1681445967.png",
                "rarity_score": 72.12865565711533,
                "rarity_level": "COMMON"
            }
        }
    },
    "1294": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1294_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1294",
                "name": "Average Badger Lewis #1294",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1294_1681445975.png",
                "rarity_score": 73.87398713143372,
                "rarity_level": "COMMON"
            }
        }
    },
    "1168": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1168_1681445954.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1168",
                "name": "Bookworm Badger Jones #1168",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1168_1681445954.png",
                "rarity_score": 125.39281684805542,
                "rarity_level": "RARE"
            }
        }
    },
    "1216": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1216_1681445960.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1216",
                "name": "Precious Badger Gringot #1216",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681445955,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1216_1681445960.png",
                "rarity_score": 72.33465440233765,
                "rarity_level": "COMMON"
            }
        }
    },
    "1249": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1249_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1249",
                "name": "Powerful Badger Cal #1249",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445960,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1249_1681445965.png",
                "rarity_score": 69.61803988361365,
                "rarity_level": "COMMON"
            }
        }
    },
    "1276": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1276_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1276",
                "name": "Awful Badger Kai #1276",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1276_1681445973.png",
                "rarity_score": 85.96306147176145,
                "rarity_level": "COMMON"
            }
        }
    },
    "1184": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1184_1681445955.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1184",
                "name": "Moon Badger Augustus #1184",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1184_1681445955.png",
                "rarity_score": 77.52175589615973,
                "rarity_level": "COMMON"
            }
        }
    },
    "1222": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1222_1681445963.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1222",
                "name": "Unsightly Badger Lennie #1222",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445956,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1222_1681445963.png",
                "rarity_score": 77.53957809122873,
                "rarity_level": "COMMON"
            }
        }
    },
    "1270": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1270_1681445969.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1270",
                "name": "Tough Badger Credence #1270",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445964,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1270_1681445969.png",
                "rarity_score": 80.74790128592137,
                "rarity_level": "COMMON"
            }
        }
    },
    "1304": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1304_1681445976.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1304",
                "name": "Envious Badger Scabbers #1304",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445970,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1304_1681445976.png",
                "rarity_score": 106.28651600630565,
                "rarity_level": "RARE"
            }
        }
    },
    "1189": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1189_1681445956.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1189",
                "name": "Sore Badger Deontay #1189",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1189_1681445956.png",
                "rarity_score": 94.9976846425105,
                "rarity_level": "RARE"
            }
        }
    },
    "1224": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1224_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1224",
                "name": "Pleasant Badger Gatsby #1224",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1224_1681445961.png",
                "rarity_score": 57.257100169132904,
                "rarity_level": "COMMON"
            }
        }
    },
    "1257": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1257_1681445968.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1257",
                "name": "Powerful Badger Cecil #1257",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1257_1681445968.png",
                "rarity_score": 85.87028758792718,
                "rarity_level": "COMMON"
            }
        }
    },
    "1302": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1302_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1302",
                "name": "Misty Badger Cayden #1302",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1302_1681445975.png",
                "rarity_score": 111.45123733359029,
                "rarity_level": "RARE"
            }
        }
    },
    "1190": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1190_1681445956.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1190",
                "name": "Fantastic Badger Mayweather #1190",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445951,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1190_1681445956.png",
                "rarity_score": 64.85909161620876,
                "rarity_level": "COMMON"
            }
        }
    },
    "1225": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1225_1681445962.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1225",
                "name": "Healthy Badger Graham #1225",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1225_1681445962.png",
                "rarity_score": 98.6555006518958,
                "rarity_level": "RARE"
            }
        }
    },
    "1267": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1267_1681445969.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1267",
                "name": "Crazy Badger Sugar Ray #1267",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445963,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1267_1681445969.png",
                "rarity_score": 69.70912259649187,
                "rarity_level": "COMMON"
            }
        }
    },
    "1303": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1303_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1303",
                "name": "Dumb Badger Fitzwilliam #1303",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1303_1681445975.png",
                "rarity_score": 64.12583404901875,
                "rarity_level": "COMMON"
            }
        }
    },
    "1183": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1183_1681445956.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1183",
                "name": "Lavish Badger Cash #1183",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445950,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1183_1681445956.png",
                "rarity_score": 365.0536739217078,
                "rarity_level": "EPIC"
            }
        }
    },
    "1228": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1228_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1228",
                "name": "Delightful Badger Wladimir #1228",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445957,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1228_1681445965.png",
                "rarity_score": 87.638651980702,
                "rarity_level": "COMMON"
            }
        }
    },
    "1277": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1277_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1277",
                "name": "Comfy Badger Holly #1277",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1277_1681445972.png",
                "rarity_score": 101.46248328877209,
                "rarity_level": "RARE"
            }
        }
    },
    "1323": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1323_1681445979.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1323",
                "name": "Cautious Badger Hermione #1323",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445974,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1323_1681445979.png",
                "rarity_score": 64.20967471525881,
                "rarity_level": "COMMON"
            }
        }
    },
    "1171": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1171_1681445957.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1171",
                "name": "Hilarious Badger Quillon #1171",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445948,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1171_1681445957.png",
                "rarity_score": 88.69449867498093,
                "rarity_level": "COMMON"
            }
        }
    },
    "1232": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1232_1681445963.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1232",
                "name": "Real Badger Max #1232",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445958,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1232_1681445963.png",
                "rarity_score": 99.58836090877611,
                "rarity_level": "RARE"
            }
        }
    },
    "1271": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1271_1681445969.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1271",
                "name": "Disgusted Badger Kingston #1271",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681445964,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1271_1681445969.png",
                "rarity_score": 157.3821425938214,
                "rarity_level": "RARE"
            }
        }
    },
    "1306": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1306_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1306",
                "name": "Awful Badger Gideon #1306",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445970,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1306_1681445975.png",
                "rarity_score": 60.39878566048811,
                "rarity_level": "COMMON"
            }
        }
    },
    "1203": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1203_1681445958.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1203",
                "name": "Cunnilingus Badger Voldemort #1203",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445953,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1203_1681445958.png",
                "rarity_score": 62.04445464331622,
                "rarity_level": "COMMON"
            }
        }
    },
    "1238": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1238_1681445964.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1238",
                "name": "Carebear Badger George #1238",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445959,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1238_1681445964.png",
                "rarity_score": 72.04526830106997,
                "rarity_level": "COMMON"
            }
        }
    },
    "1274": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1274_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1274",
                "name": "Poor Badger Ray #1274",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445965,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1274_1681445972.png",
                "rarity_score": 104.29877854361635,
                "rarity_level": "RARE"
            }
        }
    },
    "1316": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1316_1681445978.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1316",
                "name": "Ludicrous Badger Creevy #1316",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445972,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1316_1681445978.png",
                "rarity_score": 65.7251737143631,
                "rarity_level": "COMMON"
            }
        }
    },
    "1210": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1210_1681445960.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1210",
                "name": "Annoyed Badger Zane #1210",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445954,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1210_1681445960.png",
                "rarity_score": 81.40418404633053,
                "rarity_level": "COMMON"
            }
        }
    },
    "1252": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1252_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1252",
                "name": "Faithful Badger Newt #1252",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445961,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1252_1681445966.png",
                "rarity_score": 57.72410313994017,
                "rarity_level": "COMMON"
            }
        }
    },
    "1286": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1286_1681445974.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1286",
                "name": "Silly Badger Fudge #1286",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445967,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1286_1681445974.png",
                "rarity_score": 215.11627784967152,
                "rarity_level": "RARE"
            }
        }
    },
    "1333": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1333_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1333",
                "name": "Filthy Badger Fury #1333",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1333_1681445981.png",
                "rarity_score": 112.76865011157258,
                "rarity_level": "RARE"
            }
        }
    },
    "1218": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1218_1681445960.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1218",
                "name": "Enchanting Badger Peter #1218",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445956,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1218_1681445960.png",
                "rarity_score": 59.34946275801016,
                "rarity_level": "COMMON"
            }
        }
    },
    "1251": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1251_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1251",
                "name": "Glamorous Badger Tszyu #1251",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445961,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1251_1681445965.png",
                "rarity_score": 69.01511202281134,
                "rarity_level": "COMMON"
            }
        }
    },
    "1279": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1279_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1279",
                "name": "Perfect Badger Buckbeak #1279",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1279_1681445972.png",
                "rarity_score": 74.85890649726673,
                "rarity_level": "COMMON"
            }
        }
    },
    "1315": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1315_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1315",
                "name": "Fierce Badger Cayden #1315",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445972,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1315_1681445981.png",
                "rarity_score": 285.42557232174255,
                "rarity_level": "EPIC"
            }
        }
    },
    "1223": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1223_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1223",
                "name": "Grumpy Badger Robinson #1223",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445956,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1223_1681445961.png",
                "rarity_score": 54.625739147444826,
                "rarity_level": "COMMON"
            }
        }
    },
    "1255": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1255_1681445967.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1255",
                "name": "Strange Badger Gauge #1255",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445961,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1255_1681445967.png",
                "rarity_score": 61.633997929512056,
                "rarity_level": "COMMON"
            }
        }
    },
    "1293": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1293_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1293",
                "name": "Gentle Badger Buckbeak #1293",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1293_1681445975.png",
                "rarity_score": 131.84219620184814,
                "rarity_level": "RARE"
            }
        }
    },
    "1341": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1341_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1341",
                "name": "Horrible Badger Bertie #1341",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1341_1681445981.png",
                "rarity_score": 65.06396801782171,
                "rarity_level": "COMMON"
            }
        }
    },
    "1219": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1219_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1219",
                "name": "Delightful Badger Alecto #1219",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445956,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1219_1681445961.png",
                "rarity_score": 83.81262264091133,
                "rarity_level": "COMMON"
            }
        }
    },
    "1258": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1258_1681445966.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1258",
                "name": "Puzzled Badger Hufflepuff #1258",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1258_1681445966.png",
                "rarity_score": 56.97380673813901,
                "rarity_level": "COMMON"
            }
        }
    },
    "1288": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1288_1681445974.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1288",
                "name": "Awful Badger Dallas #1288",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445967,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1288_1681445974.png",
                "rarity_score": 84.33125495963151,
                "rarity_level": "COMMON"
            }
        }
    },
    "1332": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1332_1681445979.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1332",
                "name": "Adventurous Badger Oliver #1332",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1332_1681445979.png",
                "rarity_score": 59.23712992559674,
                "rarity_level": "COMMON"
            }
        }
    },
    "1221": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1221_1681445961.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1221",
                "name": "Kind Badger Galen #1221",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445956,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1221_1681445961.png",
                "rarity_score": 102.68842891328092,
                "rarity_level": "RARE"
            }
        }
    },
    "1261": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1261_1681445968.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1261",
                "name": "Filthy Badger Lennie #1261",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445962,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1261_1681445968.png",
                "rarity_score": 83.50910483038717,
                "rarity_level": "COMMON"
            }
        }
    },
    "1301": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1301_1681445973.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1301",
                "name": "Impressive Badger Huck #1301",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1301_1681445973.png",
                "rarity_score": 57.231724972748516,
                "rarity_level": "COMMON"
            }
        }
    },
    "1331": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1331_1681445980.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1331",
                "name": "Fine Badger Ryker #1331",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1331_1681445980.png",
                "rarity_score": 64.05126126841533,
                "rarity_level": "COMMON"
            }
        }
    },
    "1234": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1234_1681445965.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1234",
                "name": "Envious Badger Langston #1234",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445958,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1234_1681445965.png",
                "rarity_score": 95.00317695689347,
                "rarity_level": "RARE"
            }
        }
    },
    "1278": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1278_1681445970.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1278",
                "name": "Yucky Badger Corin #1278",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445966,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1278_1681445970.png",
                "rarity_score": 86.2961736637759,
                "rarity_level": "COMMON"
            }
        }
    },
    "1311": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1311_1681445976.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1311",
                "name": "Excited Badger Errol #1311",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445971,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1311_1681445976.png",
                "rarity_score": 74.93760052011461,
                "rarity_level": "COMMON"
            }
        }
    },
    "1344": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1344_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1344",
                "name": "Colorful Badger Moby #1344",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1344_1681445983.png",
                "rarity_score": 113.87922386120152,
                "rarity_level": "RARE"
            }
        }
    },
    "1287": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1287_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1287",
                "name": "Scary Badger Robinson #1287",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445967,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1287_1681445972.png",
                "rarity_score": 72.98518784684794,
                "rarity_level": "COMMON"
            }
        }
    },
    "1319": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1319_1681445978.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1319",
                "name": "Cute Badger Charlie #1319",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445973,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1319_1681445978.png",
                "rarity_score": 60.217678336760414,
                "rarity_level": "COMMON"
            }
        }
    },
    "1357": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1357_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1357",
                "name": "Rich Badger Ranger #1357",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445979,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1357_1681445985.png",
                "rarity_score": 69.80146609053526,
                "rarity_level": "COMMON"
            }
        }
    },
    "1393": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1393_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1393",
                "name": "Faithful Badger Landon #1393",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445985,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1393_1681445992.png",
                "rarity_score": 106.89024799185799,
                "rarity_level": "RARE"
            }
        }
    },
    "1292": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1292_1681445972.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1292",
                "name": "Odd Badger Wrackspurt #1292",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445968,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1292_1681445972.png",
                "rarity_score": 61.60585135453193,
                "rarity_level": "COMMON"
            }
        }
    },
    "1321": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1321_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1321",
                "name": "Fine Badger Quentin #1321",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445973,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1321_1681445981.png",
                "rarity_score": 124.6422780793915,
                "rarity_level": "RARE"
            }
        }
    },
    "1370": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1370_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1370",
                "name": "Repulsive Badger Scamander #1370",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445982,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1370_1681445989.png",
                "rarity_score": 78.2958077150097,
                "rarity_level": "COMMON"
            }
        }
    },
    "1417": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1417_1681445995.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1417",
                "name": "Funny Badger Max #1417",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445990,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1417_1681445995.png",
                "rarity_score": 59.089297372341576,
                "rarity_level": "COMMON"
            }
        }
    },
    "1308": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1308_1681445975.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1308",
                "name": "Attractive Badger Bronn #1308",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445970,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1308_1681445975.png",
                "rarity_score": 59.478028850378266,
                "rarity_level": "COMMON"
            }
        }
    },
    "1339": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1339_1681445984.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1339",
                "name": "Lazy Badger Nox #1339",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1339_1681445984.png",
                "rarity_score": 100.38377426837737,
                "rarity_level": "RARE"
            }
        }
    },
    "1389": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1389_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1389",
                "name": "Lively Badger Troy #1389",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1389_1681445989.png",
                "rarity_score": 58.9934057009124,
                "rarity_level": "COMMON"
            }
        }
    },
    "1415": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1415_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1415",
                "name": "Nasty Badger Noah #1415",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445990,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1415_1681445996.png",
                "rarity_score": 66.9492824599767,
                "rarity_level": "COMMON"
            }
        }
    },
    "1309": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1309_1681445976.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1309",
                "name": "Adventurous Badger Bowtruckle #1309",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445971,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1309_1681445976.png",
                "rarity_score": 64.6437541479583,
                "rarity_level": "COMMON"
            }
        }
    },
    "1345": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1345_1681445984.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1345",
                "name": "Dull Badger Killian #1345",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445977,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1345_1681445984.png",
                "rarity_score": 124.5141735550506,
                "rarity_level": "RARE"
            }
        }
    },
    "1392": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1392_1681445990.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1392",
                "name": "Muddy Badger Hedwig #1392",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445985,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1392_1681445990.png",
                "rarity_score": 197.21748888802964,
                "rarity_level": "RARE"
            }
        }
    },
    "1424": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1424_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1424",
                "name": "Clammy Badger Marciano #1424",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1424_1681445998.png",
                "rarity_score": 102.93446823479029,
                "rarity_level": "RARE"
            }
        }
    },
    "1310": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1310_1681445977.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1310",
                "name": "Clumsy Badger Mary #1310",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445971,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1310_1681445977.png",
                "rarity_score": 97.51532587004357,
                "rarity_level": "RARE"
            }
        }
    },
    "1348": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1348_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1348",
                "name": "Average Badger Gatsby #1348",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445977,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1348_1681445983.png",
                "rarity_score": 64.96148800658051,
                "rarity_level": "COMMON"
            }
        }
    },
    "1384": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1384_1681445988.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1384",
                "name": "Nasty Badger Flint #1384",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1384_1681445988.png",
                "rarity_score": 58.85785785785786,
                "rarity_level": "COMMON"
            }
        }
    },
    "1409": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1409_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1409",
                "name": "Muddy Badger Cornelius #1409",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445989,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1409_1681445996.png",
                "rarity_score": 104.22982065179752,
                "rarity_level": "RARE"
            }
        }
    },
    "1307": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1307_1681445977.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1307",
                "name": "Bot Badger Mad-Eye Moody #1307",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445970,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1307_1681445977.png",
                "rarity_score": 121.74629856873634,
                "rarity_level": "RARE"
            }
        }
    },
    "1349": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1349_1681445984.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1349",
                "name": "Glorious Badger Foreman #1349",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445977,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1349_1681445984.png",
                "rarity_score": 106.72954916554392,
                "rarity_level": "RARE"
            }
        }
    },
    "1390": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1390_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1390",
                "name": "Unusual Badger Credence #1390",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445985,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1390_1681445992.png",
                "rarity_score": 82.7681689366854,
                "rarity_level": "COMMON"
            }
        }
    },
    "1437": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1437_1681446000.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1437",
                "name": "Quaint Badger Jett #1437",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1437_1681446000.png",
                "rarity_score": 125.74960056090026,
                "rarity_level": "RARE"
            }
        }
    },
    "1298": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1298_1681445977.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1298",
                "name": "Foolish Badger Mad-Eye Moody #1298",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445969,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1298_1681445977.png",
                "rarity_score": 126.21967197439636,
                "rarity_level": "RARE"
            }
        }
    },
    "1350": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1350_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1350",
                "name": "Friendly Badger Scimgeour #1350",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445978,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1350_1681445983.png",
                "rarity_score": 96.8058105828763,
                "rarity_level": "RARE"
            }
        }
    },
    "1386": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1386_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1386",
                "name": "Funny Badger Archer #1386",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1386_1681445992.png",
                "rarity_score": 78.4221176149481,
                "rarity_level": "COMMON"
            }
        }
    },
    "1440": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1440_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1440",
                "name": "Dumb Badger Jem #1440",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1440_1681445998.png",
                "rarity_score": 73.93683704158934,
                "rarity_level": "COMMON"
            }
        }
    },
    "1318": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1318_1681445977.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1318",
                "name": "Bookworm Badger Cruz #1318",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445973,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1318_1681445977.png",
                "rarity_score": 281.3006082232019,
                "rarity_level": "EPIC"
            }
        }
    },
    "1351": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1351_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1351",
                "name": "Repulsive Badger Miguel #1351",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445978,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1351_1681445983.png",
                "rarity_score": 252.95933884329315,
                "rarity_level": "EPIC"
            }
        }
    },
    "1387": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1387_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1387",
                "name": "Calm Badger Gornuk #1387",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1387_1681445989.png",
                "rarity_score": 61.36700336700336,
                "rarity_level": "COMMON"
            }
        }
    },
    "1412": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1412_1681445995.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1412",
                "name": "Talented Badger Dumbledore #1412",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445989,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1412_1681445995.png",
                "rarity_score": 341.5881044626202,
                "rarity_level": "EPIC"
            }
        }
    },
    "1314": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1314_1681445978.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1314",
                "name": "Brave Badger Minerva #1314",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445972,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1314_1681445978.png",
                "rarity_score": 63.26839096604611,
                "rarity_level": "COMMON"
            }
        }
    },
    "1352": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1352_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1352",
                "name": "Worried Badger Mike #1352",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445978,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1352_1681445985.png",
                "rarity_score": 75.30645875982539,
                "rarity_level": "COMMON"
            }
        }
    },
    "1395": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1395_1681445990.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1395",
                "name": "Foolish Badger Cornelius #1395",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1395_1681445990.png",
                "rarity_score": 236.94441709483993,
                "rarity_level": "EPIC"
            }
        }
    },
    "1427": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1427_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1427",
                "name": "Clean Badger Filius #1427",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1427_1681445996.png",
                "rarity_score": 68.76419094854288,
                "rarity_level": "COMMON"
            }
        }
    },
    "1320": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1320_1681445978.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1320",
                "name": "Energetic Badger Leonard #1320",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445973,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1320_1681445978.png",
                "rarity_score": 67.99230697063516,
                "rarity_level": "COMMON"
            }
        }
    },
    "1354": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1354_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1354",
                "name": "Prickly Badger Percy #1354",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445978,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1354_1681445983.png",
                "rarity_score": 57.10273102878022,
                "rarity_level": "COMMON"
            }
        }
    },
    "1383": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1383_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1383",
                "name": "Whale Badger Riddick #1383",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1383_1681445989.png",
                "rarity_score": 119.52433790306775,
                "rarity_level": "RARE"
            }
        }
    },
    "1419": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1419_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1419",
                "name": "Busy Badger Lee #1419",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445990,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1419_1681445996.png",
                "rarity_score": 203.97097647906233,
                "rarity_level": "RARE"
            }
        }
    },
    "1317": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1317_1681445978.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1317",
                "name": "Magnificent Badger Zane #1317",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445973,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1317_1681445978.png",
                "rarity_score": 60.746365270041466,
                "rarity_level": "COMMON"
            }
        }
    },
    "1356": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1356_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1356",
                "name": "Broken Badger Basilisk #1356",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445979,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1356_1681445985.png",
                "rarity_score": 97.88493352963275,
                "rarity_level": "RARE"
            }
        }
    },
    "1400": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1400_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1400",
                "name": "Envious Badger Ishmael #1400",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1400_1681445991.png",
                "rarity_score": 58.218266045671506,
                "rarity_level": "COMMON"
            }
        }
    },
    "1430": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1430_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1430",
                "name": "Easy Badger Croix #1430",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681445992,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1430_1681445998.png",
                "rarity_score": 106.79926494580862,
                "rarity_level": "RARE"
            }
        }
    },
    "1325": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1325_1681445979.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1325",
                "name": "Foolish Badger Gringot #1325",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445974,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1325_1681445979.png",
                "rarity_score": 57.097976223226254,
                "rarity_level": "COMMON"
            }
        }
    },
    "1358": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1358_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1358",
                "name": "Determined Badger Hogwart #1358",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445979,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1358_1681445985.png",
                "rarity_score": 208.18781217259442,
                "rarity_level": "RARE"
            }
        }
    },
    "1397": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1397_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1397",
                "name": "Ugly Badger Aberforth #1397",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1397_1681445991.png",
                "rarity_score": 193.0540785018397,
                "rarity_level": "RARE"
            }
        }
    },
    "1429": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1429_1681445997.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1429",
                "name": "Dizzy Badger Calen #1429",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1429_1681445997.png",
                "rarity_score": 99.65070599045461,
                "rarity_level": "RARE"
            }
        }
    },
    "1322": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1322_1681445979.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1322",
                "name": "Super Badger Archie #1322",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445973,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1322_1681445979.png",
                "rarity_score": 80.02519666654847,
                "rarity_level": "COMMON"
            }
        }
    },
    "1359": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1359_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1359",
                "name": "Moon Badger George #1359",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445979,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1359_1681445985.png",
                "rarity_score": 63.92563588571015,
                "rarity_level": "COMMON"
            }
        }
    },
    "1394": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1394_1681445990.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1394",
                "name": "Powerful Badger Fred #1394",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1394_1681445990.png",
                "rarity_score": 58.75741425474945,
                "rarity_level": "COMMON"
            }
        }
    },
    "1426": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1426_1681445995.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1426",
                "name": "Shill Badger Dean #1426",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1426_1681445995.png",
                "rarity_score": 71.0323109604362,
                "rarity_level": "COMMON"
            }
        }
    },
    "1329": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1329_1681445979.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1329",
                "name": "Testy Badger Jake #1329",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1329_1681445979.png",
                "rarity_score": 55.768116273700365,
                "rarity_level": "COMMON"
            }
        }
    },
    "1360": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1360_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1360",
                "name": "Enchanting Badger Dobby #1360",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445980,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1360_1681445985.png",
                "rarity_score": 198.6982154906683,
                "rarity_level": "RARE"
            }
        }
    },
    "1399": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1399_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1399",
                "name": "Glamorous Badger Romeo #1399",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1399_1681445992.png",
                "rarity_score": 72.43997318326572,
                "rarity_level": "COMMON"
            }
        }
    },
    "1442": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1442_1681446000.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1442",
                "name": "Jealous Badger Rhett #1442",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1442_1681446000.png",
                "rarity_score": 102.67081856921632,
                "rarity_level": "RARE"
            }
        }
    },
    "1312": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1312_1681445979.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1312",
                "name": "Panda Badger Marcus #1312",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445971,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1312_1681445979.png",
                "rarity_score": 149.75636915607683,
                "rarity_level": "RARE"
            }
        }
    },
    "1363": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1363_1681445986.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1363",
                "name": "Funny Badger Leopold #1363",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445980,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1363_1681445986.png",
                "rarity_score": 70.07398634230748,
                "rarity_level": "COMMON"
            }
        }
    },
    "1403": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1403_1681445994.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1403",
                "name": "Dizzy Badger Firenze #1403",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1403_1681445994.png",
                "rarity_score": 100.32453483458504,
                "rarity_level": "RARE"
            }
        }
    },
    "1447": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1447_1681446001.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1447",
                "name": "Depressed Badger Kom #1447",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445995,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1447_1681446001.png",
                "rarity_score": 95.82913981756386,
                "rarity_level": "RARE"
            }
        }
    },
    "1337": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1337_1681445980.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1337",
                "name": "Delightful Badger Mayweather #1337",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1337_1681445980.png",
                "rarity_score": 61.292638291787725,
                "rarity_level": "COMMON"
            }
        }
    },
    "1364": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1364_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1364",
                "name": "Funny Badger Augustus #1364",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445980,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1364_1681445985.png",
                "rarity_score": 57.24557093482205,
                "rarity_level": "COMMON"
            }
        }
    },
    "1396": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1396_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1396",
                "name": "Dopey Badger Huck #1396",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1396_1681445991.png",
                "rarity_score": 97.96892457384536,
                "rarity_level": "RARE"
            }
        }
    },
    "1439": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1439_1681446000.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1439",
                "name": "Poor Badger Huck #1439",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1439_1681446000.png",
                "rarity_score": 118.28226289328069,
                "rarity_level": "RARE"
            }
        }
    },
    "1324": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1324_1681445980.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1324",
                "name": "Wicked Badger Sebastian #1324",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445974,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1324_1681445980.png",
                "rarity_score": 106.44761112933737,
                "rarity_level": "RARE"
            }
        }
    },
    "1366": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1366_1681445986.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1366",
                "name": "Healthy Badger Hedwig #1366",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681445981,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1366_1681445986.png",
                "rarity_score": 71.74431619012006,
                "rarity_level": "COMMON"
            }
        }
    },
    "1402": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1402_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1402",
                "name": "Foolish Badger Crabbe #1402",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1402_1681445991.png",
                "rarity_score": 56.524940060177045,
                "rarity_level": "COMMON"
            }
        }
    },
    "1433": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1433_1681445997.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1433",
                "name": "HODL Badger Arther #1433",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445992,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1433_1681445997.png",
                "rarity_score": 68.79361045452426,
                "rarity_level": "COMMON"
            }
        }
    },
    "1336": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1336_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1336",
                "name": "Nutty Badger Bellatrix #1336",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1336_1681445981.png",
                "rarity_score": 91.00865712338052,
                "rarity_level": "RARE"
            }
        }
    },
    "1368": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1368_1681445988.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1368",
                "name": "Energetic Badger Pettigrew #1368",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445982,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1368_1681445988.png",
                "rarity_score": 73.2602872443138,
                "rarity_level": "COMMON"
            }
        }
    },
    "1413": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1413_1681445995.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1413",
                "name": "Annoying Badger Nox #1413",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445989,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1413_1681445995.png",
                "rarity_score": 62.74357712077998,
                "rarity_level": "COMMON"
            }
        }
    },
    "1693": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1693_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1693",
                "name": "Comfy Badger Kingsley #1693",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1693_1681446047.png",
                "rarity_score": 143.57462778609408,
                "rarity_level": "RARE"
            }
        }
    },
    "1313": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1313_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1313",
                "name": "Dizzy Badger Dudley #1313",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445972,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1313_1681445981.png",
                "rarity_score": 93.1738195818299,
                "rarity_level": "RARE"
            }
        }
    },
    "1369": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1369_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1369",
                "name": "Lazy Badger Creevy #1369",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445982,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1369_1681445989.png",
                "rarity_score": 75.59232729950787,
                "rarity_level": "COMMON"
            }
        }
    },
    "1414": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1414_1681445994.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1414",
                "name": "Fine Badger Snape #1414",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681445989,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1414_1681445994.png",
                "rarity_score": 65.72330376016731,
                "rarity_level": "COMMON"
            }
        }
    },
    "1702": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1702_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1702",
                "name": "Clammy Badger Everett #1702",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446042,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1702_1681446047.png",
                "rarity_score": 92.44240136955611,
                "rarity_level": "RARE"
            }
        }
    },
    "1327": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1327_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1327",
                "name": "Lambo Badger Severus #1327",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445974,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1327_1681445981.png",
                "rarity_score": 105.37943305464916,
                "rarity_level": "RARE"
            }
        }
    },
    "1371": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1371_1681445987.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1371",
                "name": "Ill Badger Salazar #1371",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445982,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1371_1681445987.png",
                "rarity_score": 52.317441529120366,
                "rarity_level": "COMMON"
            }
        }
    },
    "1406": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1406_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1406",
                "name": "Funny Badger Peeves #1406",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445987,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1406_1681445992.png",
                "rarity_score": 71.05701475818914,
                "rarity_level": "COMMON"
            }
        }
    },
    "1441": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1441_1681445999.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1441",
                "name": "Determined Badger Georgie #1441",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1441_1681445999.png",
                "rarity_score": 61.850186843628514,
                "rarity_level": "COMMON"
            }
        }
    },
    "1326": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1326_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1326",
                "name": "Byzantine Badger Sirius #1326",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445974,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1326_1681445981.png",
                "rarity_score": 102.5266381430873,
                "rarity_level": "RARE"
            }
        }
    },
    "1372": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1372_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1372",
                "name": "Naughty Badger Shep #1372",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445982,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1372_1681445989.png",
                "rarity_score": 93.1194985004325,
                "rarity_level": "RARE"
            }
        }
    },
    "1422": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1422_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1422",
                "name": "Strange Badger Tyrion #1422",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1422_1681445998.png",
                "rarity_score": 70.8570155248943,
                "rarity_level": "COMMON"
            }
        }
    },
    "1680": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1680_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1680",
                "name": "Kind Badger Frederick #1680",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446038,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1680_1681446047.png",
                "rarity_score": 254.69650442674322,
                "rarity_level": "EPIC"
            }
        }
    },
    "1338": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1338_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1338",
                "name": "Degen Badger Jem #1338",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1338_1681445981.png",
                "rarity_score": 60.51181836859156,
                "rarity_level": "COMMON"
            }
        }
    },
    "1375": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1375_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1375",
                "name": "Unusual Badger Fred #1375",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1375_1681445989.png",
                "rarity_score": 83.93356065413873,
                "rarity_level": "COMMON"
            }
        }
    },
    "1423": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1423_1681445995.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1423",
                "name": "Clumsy Badger Walden #1423",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1423_1681445995.png",
                "rarity_score": 216.18128824011177,
                "rarity_level": "RARE"
            }
        }
    },
    "1706": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1706_1681446047.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1706",
                "name": "Angry Badger Jake #1706",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1706_1681446047.png",
                "rarity_score": 54.203850656029644,
                "rarity_level": "COMMON"
            }
        }
    },
    "1340": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1340_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1340",
                "name": "Dildo Badger Galen #1340",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1340_1681445981.png",
                "rarity_score": 64.4513171931008,
                "rarity_level": "COMMON"
            }
        }
    },
    "1376": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1376_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1376",
                "name": "Calm Badger Flitwick #1376",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1376_1681445989.png",
                "rarity_score": 66.37405168025889,
                "rarity_level": "COMMON"
            }
        }
    },
    "1421": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1421_1681445997.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1421",
                "name": "Jealous Badger Hagrid #1421",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1421_1681445997.png",
                "rarity_score": 435.391998452049,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1698": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1698_1681446048.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1698",
                "name": "Nice Badger Dempsey #1698",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446041,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1698_1681446048.png",
                "rarity_score": 370.79994035804265,
                "rarity_level": "EPIC"
            }
        }
    },
    "1342": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1342_1681445982.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1342",
                "name": "Bored Badger Floyd #1342",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1342_1681445982.png",
                "rarity_score": 201.12867497673443,
                "rarity_level": "RARE"
            }
        }
    },
    "1377": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1377_1681445988.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1377",
                "name": "Tough Badger Crabbe #1377",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1377_1681445988.png",
                "rarity_score": 71.13805794586848,
                "rarity_level": "COMMON"
            }
        }
    },
    "1411": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1411_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1411",
                "name": "Poor Badger Mary #1411",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445989,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1411_1681445996.png",
                "rarity_score": 78.45325006896951,
                "rarity_level": "COMMON"
            }
        }
    },
    "1694": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1694_1681446048.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1694",
                "name": "Lucky Badger Beckham #1694",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446040,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1694_1681446048.png",
                "rarity_score": 83.50096846984889,
                "rarity_level": "COMMON"
            }
        }
    },
    "1335": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1335_1681445982.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1335",
                "name": "Ludicrous Badger Beckham #1335",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1335_1681445982.png",
                "rarity_score": 79.77747598977683,
                "rarity_level": "COMMON"
            }
        }
    },
    "1378": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1378_1681445990.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1378",
                "name": "Awful Badger Kelpie #1378",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1378_1681445990.png",
                "rarity_score": 78.2026420291918,
                "rarity_level": "COMMON"
            }
        }
    },
    "1428": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1428_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1428",
                "name": "Rich Badger Snape #1428",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1428_1681445998.png",
                "rarity_score": 133.55523959922104,
                "rarity_level": "RARE"
            }
        }
    },
    "1704": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1704_1681446049.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1704",
                "name": "Upset Badger Fred #1704",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1704_1681446049.png",
                "rarity_score": 59.25675253143176,
                "rarity_level": "COMMON"
            }
        }
    },
    "1330": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1330_1681445981.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1330",
                "name": "Ludicrous Badger Rocky #1330",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1330_1681445981.png",
                "rarity_score": 123.57409808601801,
                "rarity_level": "RARE"
            }
        }
    },
    "1379": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1379_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1379",
                "name": "Silly Badger Filch #1379",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1379_1681445991.png",
                "rarity_score": 201.29563829495825,
                "rarity_level": "RARE"
            }
        }
    },
    "1432": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1432_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1432",
                "name": "Mushy Badger Basilisk #1432",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445992,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1432_1681445998.png",
                "rarity_score": 70.84818207382615,
                "rarity_level": "COMMON"
            }
        }
    },
    "1708": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1708_1681446049.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1708",
                "name": "Degen Badger Mandrake #1708",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1708_1681446049.png",
                "rarity_score": 78.53424148634033,
                "rarity_level": "COMMON"
            }
        }
    },
    "1343": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1343_1681445982.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1343",
                "name": "Degen Badger Carter #1343",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445976,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1343_1681445982.png",
                "rarity_score": 262.3835146672562,
                "rarity_level": "EPIC"
            }
        }
    },
    "1380": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1380_1681445990.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1380",
                "name": "Shy Badger Julio #1380",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1380_1681445990.png",
                "rarity_score": 110.63801049479814,
                "rarity_level": "RARE"
            }
        }
    },
    "1425": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1425_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1425",
                "name": "Bloody Badger Jett #1425",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1425_1681445996.png",
                "rarity_score": 59.427381203023685,
                "rarity_level": "COMMON"
            }
        }
    },
    "1701": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1701_1681446049.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1701",
                "name": "Dangerous Badger Cian #1701",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446041,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1701_1681446049.png",
                "rarity_score": 142.69774071988002,
                "rarity_level": "RARE"
            }
        }
    },
    "1346": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1346_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1346",
                "name": "Confused Badger Aberforth #1346",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681445977,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1346_1681445983.png",
                "rarity_score": 70.27233664623103,
                "rarity_level": "COMMON"
            }
        }
    },
    "1381": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1381_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1381",
                "name": "Carebear Badger Hedwig #1381",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1381_1681445991.png",
                "rarity_score": 117.66117627309679,
                "rarity_level": "RARE"
            }
        }
    },
    "1434": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1434_1681446001.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1434",
                "name": "Gentle Badger Lewis #1434",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445992,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1434_1681446001.png",
                "rarity_score": 139.62717440093272,
                "rarity_level": "RARE"
            }
        }
    },
    "1711": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1711_1681446049.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1711",
                "name": "Elegant Badger Newt #1711",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446044,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1711_1681446049.png",
                "rarity_score": 91.5196208910507,
                "rarity_level": "RARE"
            }
        }
    },
    "1334": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1334_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1334",
                "name": "Dizzy Badger Johnson #1334",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445975,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1334_1681445983.png",
                "rarity_score": 130.67441301561414,
                "rarity_level": "RARE"
            }
        }
    },
    "1385": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1385_1681445988.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1385",
                "name": "Condemned Badger Jay #1385",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1385_1681445988.png",
                "rarity_score": 70.04810846372649,
                "rarity_level": "COMMON"
            }
        }
    },
    "1410": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1410_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1410",
                "name": "Prickly Badger Oliver #1410",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445989,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1410_1681445996.png",
                "rarity_score": 77.36220285172985,
                "rarity_level": "COMMON"
            }
        }
    },
    "1712": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1712_1681446050.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1712",
                "name": "Concerned Badger Graham #1712",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446044,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1712_1681446050.png",
                "rarity_score": 124.47913717118159,
                "rarity_level": "RARE"
            }
        }
    },
    "1328": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1328_1681445983.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1328",
                "name": "Ludicrous Badger Ravenclaw #1328",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445974,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1328_1681445983.png",
                "rarity_score": 95.67557433744109,
                "rarity_level": "RARE"
            }
        }
    },
    "1388": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1388_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1388",
                "name": "Rekt Badger Lily #1388",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445984,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1388_1681445991.png",
                "rarity_score": 105.22211638781627,
                "rarity_level": "RARE"
            }
        }
    },
    "1431": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1431_1681445997.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1431",
                "name": "Sore Badger Johnson #1431",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445992,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1431_1681445997.png",
                "rarity_score": 113.31578500836767,
                "rarity_level": "RARE"
            }
        }
    },
    "1714": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1714_1681446050.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1714",
                "name": "Busy Badger Buckbeak #1714",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446044,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1714_1681446050.png",
                "rarity_score": 91.36494090333855,
                "rarity_level": "RARE"
            }
        }
    },
    "1347": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1347_1681445984.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1347",
                "name": "Talented Badger Dursley #1347",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445977,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1347_1681445984.png",
                "rarity_score": 116.91620692080366,
                "rarity_level": "RARE"
            }
        }
    },
    "1391": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1391_1681445991.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1391",
                "name": "Stupid Badger Connelly #1391",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445985,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1391_1681445991.png",
                "rarity_score": 82.95865864710409,
                "rarity_level": "COMMON"
            }
        }
    },
    "1435": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1435_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1435",
                "name": "Ludicrous Badger Hufflepuff #1435",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681445992,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1435_1681445998.png",
                "rarity_score": 77.65780277951444,
                "rarity_level": "COMMON"
            }
        }
    },
    "1707": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1707_1681446050.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1707",
                "name": "Tame Badger Edward #1707",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446043,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1707_1681446050.png",
                "rarity_score": 108.50950333810925,
                "rarity_level": "RARE"
            }
        }
    },
    "1353": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1353_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1353",
                "name": "Lively Badger Major #1353",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681445978,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1353_1681445985.png",
                "rarity_score": 184.3329706067032,
                "rarity_level": "RARE"
            }
        }
    },
    "1398": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1398_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1398",
                "name": "FUD Badger Archer #1398",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1398_1681445992.png",
                "rarity_score": 66.94846861735354,
                "rarity_level": "COMMON"
            }
        }
    },
    "1436": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1436_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1436",
                "name": "Colorful Badger Jacob #1436",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1436_1681445998.png",
                "rarity_score": 177.08406869538615,
                "rarity_level": "RARE"
            }
        }
    },
    "1721": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1721_1681446050.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1721",
                "name": "Fragile Badger Troy #1721",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446046,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1721_1681446050.png",
                "rarity_score": 68.56487242304576,
                "rarity_level": "COMMON"
            }
        }
    },
    "1355": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1355_1681445985.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1355",
                "name": "Scary Badger Jake #1355",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445978,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1355_1681445985.png",
                "rarity_score": 74.90392006606422,
                "rarity_level": "COMMON"
            }
        }
    },
    "1401": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1401_1681445994.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1401",
                "name": "Jolly Badger Scorpius #1401",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445986,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1401_1681445994.png",
                "rarity_score": 121.16773109327207,
                "rarity_level": "RARE"
            }
        }
    },
    "1720": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1720_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1720",
                "name": "Exuberant Badger Ogre #1720",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446045,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1720_1681446051.png",
                "rarity_score": 75.84095860871182,
                "rarity_level": "COMMON"
            }
        }
    },
    "1739": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1739_1681446055.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1739",
                "name": "Calm Badger Tiberius #1739",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1739_1681446055.png",
                "rarity_score": 95.00823027599398,
                "rarity_level": "RARE"
            }
        }
    },
    "1361": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1361_1681445987.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1361",
                "name": "Friendly Badger Wulfric #1361",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445980,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1361_1681445987.png",
                "rarity_score": 91.754920726251,
                "rarity_level": "RARE"
            }
        }
    },
    "1404": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1404_1681445993.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1404",
                "name": "Glorious Badger Hermes #1404",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445987,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1404_1681445993.png",
                "rarity_score": 104.83335552353579,
                "rarity_level": "RARE"
            }
        }
    },
    "1444": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1444_1681446001.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1444",
                "name": "Carebear Badger Fireball #1444",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1444_1681446001.png",
                "rarity_score": 110.32262608108275,
                "rarity_level": "RARE"
            }
        }
    },
    "1730": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1730_1681446051.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1730",
                "name": "Handsome Badger Huck #1730",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1730_1681446051.png",
                "rarity_score": 59.230700372165565,
                "rarity_level": "COMMON"
            }
        }
    },
    "1362": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1362_1681445987.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1362",
                "name": "Pleasant Badger Lily #1362",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681445980,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1362_1681445987.png",
                "rarity_score": 67.75515935911304,
                "rarity_level": "COMMON"
            }
        }
    },
    "1405": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1405_1681445992.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1405",
                "name": "Glorious Badger Fabian #1405",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445987,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1405_1681445992.png",
                "rarity_score": 56.73865749876227,
                "rarity_level": "COMMON"
            }
        }
    },
    "1438": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1438_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1438",
                "name": "Strange Badger Charlie #1438",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1438_1681445998.png",
                "rarity_score": 67.20522744145762,
                "rarity_level": "COMMON"
            }
        }
    },
    "1722": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1722_1681446052.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1722",
                "name": "Lucky Badger Dash #1722",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446046,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1722_1681446052.png",
                "rarity_score": 81.16923410315214,
                "rarity_level": "COMMON"
            }
        }
    },
    "1374": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1374_1681445987.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1374",
                "name": "Panda Badger Hogwart #1374",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1374_1681445987.png",
                "rarity_score": 77.27187966197118,
                "rarity_level": "COMMON"
            }
        }
    },
    "1407": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1407_1681445994.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1407",
                "name": "Disturbed Badger Laila #1407",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445988,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1407_1681445994.png",
                "rarity_score": 70.60303225302569,
                "rarity_level": "COMMON"
            }
        }
    },
    "1446": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1446_1681446000.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1446",
                "name": "Unsightly Badger Cian #1446",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681445995,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1446_1681446000.png",
                "rarity_score": 55.56995983666859,
                "rarity_level": "COMMON"
            }
        }
    },
    "1723": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1723_1681446052.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1723",
                "name": "Tense Badger Vernon #1723",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1723_1681446052.png",
                "rarity_score": 86.05757137157394,
                "rarity_level": "COMMON"
            }
        }
    },
    "1365": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1365_1681445988.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1365",
                "name": "Enchanting Badger Beckham #1365",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681445981,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1365_1681445988.png",
                "rarity_score": 183.46783126669285,
                "rarity_level": "RARE"
            }
        }
    },
    "1408": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1408_1681445995.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1408",
                "name": "Calm Badger Sirius Black #1408",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681445988,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1408_1681445995.png",
                "rarity_score": 78.5680582307699,
                "rarity_level": "COMMON"
            }
        }
    },
    "1719": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1719_1681446052.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1719",
                "name": "Grieving Badger Grimmauld #1719",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446045,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1719_1681446052.png",
                "rarity_score": 112.90366215666197,
                "rarity_level": "RARE"
            }
        }
    },
    "1750": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1750_1681446056.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1750",
                "name": "Whale Badger Winky #1750",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446050,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1750_1681446056.png",
                "rarity_score": 93.79201046418147,
                "rarity_level": "RARE"
            }
        }
    },
    "1382": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1382_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1382",
                "name": "Encouraging Badger Hagrid #1382",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1382_1681445989.png",
                "rarity_score": 89.59084845494581,
                "rarity_level": "RARE"
            }
        }
    },
    "1416": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1416_1681445998.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1416",
                "name": "Gorgeous Badger James #1416",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681445990,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1416_1681445998.png",
                "rarity_score": 128.46832931813265,
                "rarity_level": "RARE"
            }
        }
    },
    "1729": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1729_1681446053.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1729",
                "name": "Beautiful Badger Evander #1729",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446047,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1729_1681446053.png",
                "rarity_score": 107.71872283203554,
                "rarity_level": "RARE"
            }
        }
    },
    "1751": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1751_1681446056.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1751",
                "name": "Grumpy Badger Wladimir #1751",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446050,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1751_1681446056.png",
                "rarity_score": 99.1329236387079,
                "rarity_level": "RARE"
            }
        }
    },
    "1367": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1367_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1367",
                "name": "Shy Badger Hippogriff #1367",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681445981,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1367_1681445989.png",
                "rarity_score": 102.43182695514213,
                "rarity_level": "RARE"
            }
        }
    },
    "1418": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1418_1681445997.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1418",
                "name": "Elegant Badger Croix #1418",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681445990,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1418_1681445997.png",
                "rarity_score": 114.84003594634194,
                "rarity_level": "RARE"
            }
        }
    },
    "1743": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1743_1681446053.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1743",
                "name": "Shill Badger Flint #1743",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446049,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1743_1681446053.png",
                "rarity_score": 73.06032425142911,
                "rarity_level": "COMMON"
            }
        }
    },
    "1738": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1738_1681446056.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1738",
                "name": "Creepy Badger Voldemort #1738",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1738_1681446056.png",
                "rarity_score": 120.9530866818576,
                "rarity_level": "RARE"
            }
        }
    },
    "1373": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1373_1681445989.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1373",
                "name": "Dangerous Badger Winston #1373",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445983,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1373_1681445989.png",
                "rarity_score": 73.10370905999923,
                "rarity_level": "COMMON"
            }
        }
    },
    "1420": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1420_1681445996.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1420",
                "name": "Bored Badger Langston #1420",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681445991,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1420_1681445996.png",
                "rarity_score": 87.17761011759231,
                "rarity_level": "COMMON"
            }
        }
    },
    "1737": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1737_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1737",
                "name": "Awful Badger Leonardo #1737",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1737_1681446054.png",
                "rarity_score": 88.19029471709979,
                "rarity_level": "COMMON"
            }
        }
    },
    "1753": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1753_1681446056.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1753",
                "name": "Ill Badger Ferdinand #1753",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446051,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1753_1681446056.png",
                "rarity_score": 65.38347296075187,
                "rarity_level": "COMMON"
            }
        }
    },
    "1443": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1443_1681445999.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1443",
                "name": "Nice Badger Connelly #1443",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681445993,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1443_1681445999.png",
                "rarity_score": 64.69129699127855,
                "rarity_level": "COMMON"
            }
        }
    },
    "1740": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1740_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1740",
                "name": "Jittery Badger Lennox #1740",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446048,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1740_1681446054.png",
                "rarity_score": 98.82804913164836,
                "rarity_level": "RARE"
            }
        }
    },
    "1748": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1748_1681446057.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1748",
                "name": "Hungry Badger Lucius #1748",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446050,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1748_1681446057.png",
                "rarity_score": 101.19990083195273,
                "rarity_level": "RARE"
            }
        }
    },
    "1755": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1755_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1755",
                "name": "Dull Badger Bogrod #1755",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1755_1681446058.png",
                "rarity_score": 101.51067325859267,
                "rarity_level": "RARE"
            }
        }
    },
    "1449": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1449_1681446000.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1449",
                "name": "Dumb Badger Prongs #1449",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681445995,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1449_1681446000.png",
                "rarity_score": 58.070959209396996,
                "rarity_level": "COMMON"
            }
        }
    },
    "1746": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1746_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1746",
                "name": "Bot Badger Wilbur #1746",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446049,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1746_1681446054.png",
                "rarity_score": 61.99809621599279,
                "rarity_level": "COMMON"
            }
        }
    },
    "1754": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1754_1681446057.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1754",
                "name": "Broken Badger Filch #1754",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1754_1681446057.png",
                "rarity_score": 75.76562219713892,
                "rarity_level": "COMMON"
            }
        }
    },
    "1758": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1758_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1758",
                "name": "Contagious Badger Muhammad #1758",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1758_1681446058.png",
                "rarity_score": 61.49881414140581,
                "rarity_level": "COMMON"
            }
        }
    },
    "1451": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1451_1681446001.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1451",
                "name": "Wandering Badger Philip #1451",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681445996,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1451_1681446001.png",
                "rarity_score": 97.55545283341057,
                "rarity_level": "RARE"
            }
        }
    },
    "1747": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1747_1681446054.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1747",
                "name": "Dark Badger Sawyer #1747",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446050,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1747_1681446054.png",
                "rarity_score": 56.96577377559938,
                "rarity_level": "COMMON"
            }
        }
    },
    "1752": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1752_1681446057.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1752",
                "name": "Prickly Badger Snape #1752",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446051,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1752_1681446057.png",
                "rarity_score": 69.76486616897053,
                "rarity_level": "COMMON"
            }
        }
    },
    "1756": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1756_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1756",
                "name": "Delightful Badger Cannon #1756",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1756_1681446058.png",
                "rarity_score": 60.77038092641671,
                "rarity_level": "COMMON"
            }
        }
    },
    "1763": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1763_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1763",
                "name": "Shill Badger Mayweather #1763",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1763_1681446058.png",
                "rarity_score": 79.73228663446054,
                "rarity_level": "COMMON"
            }
        }
    },
    "1759": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1759_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1759",
                "name": "Gorgeous Badger Quillon #1759",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1759_1681446058.png",
                "rarity_score": 68.65507011731445,
                "rarity_level": "COMMON"
            }
        }
    },
    "1767": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1767_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1767",
                "name": "Daft Badger Romeo #1767",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1767_1681446058.png",
                "rarity_score": 59.78493705667126,
                "rarity_level": "COMMON"
            }
        }
    },
    "1760": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1760_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1760",
                "name": "Ashamed Badger Wilbur #1760",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1760_1681446058.png",
                "rarity_score": 60.356521624836525,
                "rarity_level": "COMMON"
            }
        }
    },
    "1762": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1762_1681446058.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1762",
                "name": "Grieving Badger Viktor #1762",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Helmet",
                        "identifier": "ecko_helmet",
                        "trait_type": "Headwear",
                        "score": 520.0
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1762_1681446058.png",
                "rarity_score": 574.6093005198859,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1771": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1771_1681446059.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1771",
                "name": "Dildo Badger Ray #1771",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446054,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1771_1681446059.png",
                "rarity_score": 59.17180329243091,
                "rarity_level": "COMMON"
            }
        }
    },
    "1765": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1765_1681446059.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1765",
                "name": "Wen Badger Kai #1765",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1765_1681446059.png",
                "rarity_score": 66.53161134548813,
                "rarity_level": "COMMON"
            }
        }
    },
    "1766": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1766_1681446059.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1766",
                "name": "Strange Badger Sirius Black #1766",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1766_1681446059.png",
                "rarity_score": 93.25563130065834,
                "rarity_level": "RARE"
            }
        }
    },
    "1769": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1769_1681446060.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1769",
                "name": "Embarrassed Badger Mayweather #1769",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446054,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1769_1681446060.png",
                "rarity_score": 61.96333654617889,
                "rarity_level": "COMMON"
            }
        }
    },
    "1776": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1776_1681446060.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1776",
                "name": "Gleaming Badger Vasyl #1776",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1776_1681446060.png",
                "rarity_score": 52.610100603826815,
                "rarity_level": "COMMON"
            }
        }
    },
    "1777": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1777_1681446060.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1777",
                "name": "Excited Badger Fox #1777",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1777_1681446060.png",
                "rarity_score": 57.115424723623164,
                "rarity_level": "COMMON"
            }
        }
    },
    "1764": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1764_1681446060.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1764",
                "name": "Cautious Badger Sirius Black #1764",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1764_1681446060.png",
                "rarity_score": 86.10917345181295,
                "rarity_level": "COMMON"
            }
        }
    },
    "1773": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1773_1681446060.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1773",
                "name": "Strange Badger Riddick #1773",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1773_1681446060.png",
                "rarity_score": 66.12558283078513,
                "rarity_level": "COMMON"
            }
        }
    },
    "1774": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1774_1681446060.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1774",
                "name": "Exuberant Badger Chavez #1774",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1774_1681446060.png",
                "rarity_score": 93.67471966060609,
                "rarity_level": "RARE"
            }
        }
    },
    "1775": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1775_1681446061.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1775",
                "name": "Asthmatic Badger Fudge #1775",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1775_1681446061.png",
                "rarity_score": 108.49661177704957,
                "rarity_level": "RARE"
            }
        }
    },
    "1761": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1761_1681446061.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1761",
                "name": "Charming Badger Malfoy #1761",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446053,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1761_1681446061.png",
                "rarity_score": 150.72425576179847,
                "rarity_level": "RARE"
            }
        }
    },
    "1786": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1786_1681446061.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1786",
                "name": "Doubtful Badger Holden #1786",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1786_1681446061.png",
                "rarity_score": 210.6518200020111,
                "rarity_level": "RARE"
            }
        }
    },
    "1781": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1781_1681446061.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1781",
                "name": "Magnificent Badger Werner #1781",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1781_1681446061.png",
                "rarity_score": 72.38946538355096,
                "rarity_level": "COMMON"
            }
        }
    },
    "1757": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1757_1681446061.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1757",
                "name": "Funny Badger Rudy #1757",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446052,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1757_1681446061.png",
                "rarity_score": 159.04550249740817,
                "rarity_level": "RARE"
            }
        }
    },
    "1785": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1785_1681446061.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1785",
                "name": "Lonely Badger Holden #1785",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1785_1681446061.png",
                "rarity_score": 101.52075281770871,
                "rarity_level": "RARE"
            }
        }
    },
    "1789": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1789_1681446062.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1789",
                "name": "Clumsy Badger Toby #1789",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446057,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1789_1681446062.png",
                "rarity_score": 105.87639537013547,
                "rarity_level": "RARE"
            }
        }
    },
    "1772": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1772_1681446062.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1772",
                "name": "Filthy Badger Grimmauld #1772",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1772_1681446062.png",
                "rarity_score": 96.90910306197833,
                "rarity_level": "RARE"
            }
        }
    },
    "1782": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1782_1681446062.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1782",
                "name": "Faithful Badger Archer #1782",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1782_1681446062.png",
                "rarity_score": 64.46208571347466,
                "rarity_level": "COMMON"
            }
        }
    },
    "1793": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1793_1681446063.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1793",
                "name": "Sore Badger Boggart #1793",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446058,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1793_1681446063.png",
                "rarity_score": 60.23003264477791,
                "rarity_level": "COMMON"
            }
        }
    },
    "1791": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1791_1681446063.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1791",
                "name": "Easy Badger Amir #1791",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446057,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1791_1681446063.png",
                "rarity_score": 261.15880861940667,
                "rarity_level": "EPIC"
            }
        }
    },
    "1778": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1778_1681446063.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1778",
                "name": "Unsightly Badger Morrie #1778",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1778_1681446063.png",
                "rarity_score": 74.20117776490478,
                "rarity_level": "COMMON"
            }
        }
    },
    "1787": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1787_1681446063.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1787",
                "name": "Lucky Badger Hippogriff #1787",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1787_1681446063.png",
                "rarity_score": 94.1293808002817,
                "rarity_level": "RARE"
            }
        }
    },
    "1790": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1790_1681446063.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1790",
                "name": "Expensive Badger Caelan #1790",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446057,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1790_1681446063.png",
                "rarity_score": 96.03151825025742,
                "rarity_level": "RARE"
            }
        }
    },
    "1779": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1779_1681446063.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1779",
                "name": "Wild Badger Cesar #1779",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446055,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1779_1681446063.png",
                "rarity_score": 118.33959361669498,
                "rarity_level": "RARE"
            }
        }
    },
    "1780": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1780_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1780",
                "name": "Jittery Badger Vasyl #1780",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1780_1681446064.png",
                "rarity_score": 115.7157971066498,
                "rarity_level": "RARE"
            }
        }
    },
    "1784": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1784_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1784",
                "name": "Dolphin Badger Winston #1784",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1784_1681446064.png",
                "rarity_score": 77.22635309952783,
                "rarity_level": "COMMON"
            }
        }
    },
    "1794": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1794_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1794",
                "name": "Confused Badger Bryce #1794",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446058,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1794_1681446064.png",
                "rarity_score": 59.89579288472701,
                "rarity_level": "COMMON"
            }
        }
    },
    "1792": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1792_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1792",
                "name": "Dark Badger Dirk #1792",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446057,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1792_1681446064.png",
                "rarity_score": 99.05972134635701,
                "rarity_level": "RARE"
            }
        }
    },
    "1768": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1768_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1768",
                "name": "Clean Badger Boot #1768",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446054,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1768_1681446064.png",
                "rarity_score": 199.25105001177792,
                "rarity_level": "RARE"
            }
        }
    },
    "1788": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1788_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1788",
                "name": "Broken Badger Julio #1788",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446057,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1788_1681446064.png",
                "rarity_score": 122.98215193199452,
                "rarity_level": "RARE"
            }
        }
    },
    "1801": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1801_1681446065.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1801",
                "name": "Healthy Badger Longbottom #1801",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1801_1681446065.png",
                "rarity_score": 97.19634707804244,
                "rarity_level": "RARE"
            }
        }
    },
    "1783": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1783_1681446064.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1783",
                "name": "Odd Badger Jones #1783",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446056,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1783_1681446064.png",
                "rarity_score": 85.43516451008767,
                "rarity_level": "COMMON"
            }
        }
    },
    "1770": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1770_1681446065.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1770",
                "name": "Panda Badger Cash #1770",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446054,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1770_1681446065.png",
                "rarity_score": 137.22050596106735,
                "rarity_level": "RARE"
            }
        }
    },
    "1796": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1796_1681446065.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1796",
                "name": "Sore Badger Amos #1796",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1796_1681446065.png",
                "rarity_score": 84.79314386381544,
                "rarity_level": "COMMON"
            }
        }
    },
    "1797": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1797_1681446065.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1797",
                "name": "Bright Badger Cayman #1797",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1797_1681446065.png",
                "rarity_score": 64.59835903597315,
                "rarity_level": "COMMON"
            }
        }
    },
    "1798": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1798_1681446065.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1798",
                "name": "Tame Badger Muggle #1798",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1798_1681446065.png",
                "rarity_score": 66.06009043703617,
                "rarity_level": "COMMON"
            }
        }
    },
    "1799": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1799_1681446066.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1799",
                "name": "Excited Badger Croix #1799",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1799_1681446066.png",
                "rarity_score": 98.40802765663346,
                "rarity_level": "RARE"
            }
        }
    },
    "1805": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1805_1681446066.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1805",
                "name": "Clean Badger Langston #1805",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1805_1681446066.png",
                "rarity_score": 211.04100474763874,
                "rarity_level": "RARE"
            }
        }
    },
    "1810": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1810_1681446066.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1810",
                "name": "Bashful Badger Dexter #1810",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446061,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1810_1681446066.png",
                "rarity_score": 87.61528114581111,
                "rarity_level": "COMMON"
            }
        }
    },
    "1806": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1806_1681446067.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1806",
                "name": "Fierce Badger Weasley #1806",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446060,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1806_1681446067.png",
                "rarity_score": 63.57091135726679,
                "rarity_level": "COMMON"
            }
        }
    },
    "1812": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1812_1681446067.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1812",
                "name": "Gorgeous Badger Hagrid #1812",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446061,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1812_1681446067.png",
                "rarity_score": 60.706722172138385,
                "rarity_level": "COMMON"
            }
        }
    },
    "1811": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1811_1681446067.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1811",
                "name": "Yucky Badger Colt #1811",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446061,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1811_1681446067.png",
                "rarity_score": 99.70197951662178,
                "rarity_level": "RARE"
            }
        }
    },
    "1800": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1800_1681446067.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1800",
                "name": "Rich Badger Lomachenko #1800",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1800_1681446067.png",
                "rarity_score": 77.3073868282543,
                "rarity_level": "COMMON"
            }
        }
    },
    "1807": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1807_1681446067.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1807",
                "name": "Asthmatic Badger Bingley #1807",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446060,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1807_1681446067.png",
                "rarity_score": 98.67128795715291,
                "rarity_level": "RARE"
            }
        }
    },
    "1804": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1804_1681446067.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1804",
                "name": "Wild Badger Trevor #1804",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1804_1681446067.png",
                "rarity_score": 78.51530572690083,
                "rarity_level": "COMMON"
            }
        }
    },
    "1802": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1802_1681446068.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1802",
                "name": "Talented Badger Nargle #1802",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1802_1681446068.png",
                "rarity_score": 86.39367936672114,
                "rarity_level": "COMMON"
            }
        }
    },
    "1808": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1808_1681446068.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1808",
                "name": "Helpless Badger Duncan #1808",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446060,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1808_1681446068.png",
                "rarity_score": 247.54304709260032,
                "rarity_level": "EPIC"
            }
        }
    },
    "1809": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1809_1681446068.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1809",
                "name": "Bored Badger Cecil #1809",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446060,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1809_1681446068.png",
                "rarity_score": 79.78524513943819,
                "rarity_level": "COMMON"
            }
        }
    },
    "1795": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1795_1681446068.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1795",
                "name": "Confused Badger Marciano #1795",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1795_1681446068.png",
                "rarity_score": 79.9432961159746,
                "rarity_level": "COMMON"
            }
        }
    },
    "1815": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1815_1681446068.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1815",
                "name": "Enthusiastic Badger Grawp #1815",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446062,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1815_1681446068.png",
                "rarity_score": 71.37996088897492,
                "rarity_level": "COMMON"
            }
        }
    },
    "1821": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1821_1681446069.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1821",
                "name": "Poised Badger Nicolas #1821",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446063,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1821_1681446069.png",
                "rarity_score": 69.26081258107548,
                "rarity_level": "COMMON"
            }
        }
    },
    "1853": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1853_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1853",
                "name": "Ill Badger Henry #1853",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446069,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1853_1681446075.png",
                "rarity_score": 59.913081760914686,
                "rarity_level": "COMMON"
            }
        }
    },
    "1885": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1885_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1885",
                "name": "Unusual Badger Kai #1885",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1885_1681446082.png",
                "rarity_score": 256.14680625239305,
                "rarity_level": "EPIC"
            }
        }
    },
    "1934": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1934_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1934",
                "name": "HODL Badger Kelpie #1934",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Helmet",
                        "identifier": "ecko_helmet",
                        "trait_type": "Headwear",
                        "score": 520.0
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1934_1681446090.png",
                "rarity_score": 582.6545495725945,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1822": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1822_1681446069.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1822",
                "name": "Ludicrous Badger Gideon #1822",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446063,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1822_1681446069.png",
                "rarity_score": 62.37718410043384,
                "rarity_level": "COMMON"
            }
        }
    },
    "1803": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1803_1681446069.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1803",
                "name": "Tough Badger Dexter #1803",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446059,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1803_1681446069.png",
                "rarity_score": 210.8549469647271,
                "rarity_level": "RARE"
            }
        }
    },
    "1817": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1817_1681446070.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1817",
                "name": "Cryptic Badger Maxton #1817",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446062,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1817_1681446070.png",
                "rarity_score": 97.93646220532796,
                "rarity_level": "RARE"
            }
        }
    },
    "1816": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1816_1681446070.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1816",
                "name": "Fancy Badger Beckham #1816",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446062,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1816_1681446070.png",
                "rarity_score": 151.70986246292347,
                "rarity_level": "RARE"
            }
        }
    },
    "1813": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1813_1681446070.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1813",
                "name": "Angry Badger Max #1813",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446061,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1813_1681446070.png",
                "rarity_score": 144.93043897227307,
                "rarity_level": "RARE"
            }
        }
    },
    "1858": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1858_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1858",
                "name": "Faithful Badger Aragog #1858",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446071,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1858_1681446076.png",
                "rarity_score": 81.25047100527743,
                "rarity_level": "COMMON"
            }
        }
    },
    "1898": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1898_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1898",
                "name": "Doubtful Badger Muggle #1898",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1898_1681446082.png",
                "rarity_score": 57.2669241305929,
                "rarity_level": "COMMON"
            }
        }
    },
    "1926": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1926_1681446089.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1926",
                "name": "Troubled Badger Hermione #1926",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1926_1681446089.png",
                "rarity_score": 117.20138025943653,
                "rarity_level": "RARE"
            }
        }
    },
    "1818": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1818_1681446070.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1818",
                "name": "Fancy Badger Pacquiao #1818",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446063,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1818_1681446070.png",
                "rarity_score": 82.30315750946639,
                "rarity_level": "COMMON"
            }
        }
    },
    "1859": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1859_1681446077.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1859",
                "name": "Gentle Badger Garrick #1859",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446071,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1859_1681446077.png",
                "rarity_score": 83.33074124704218,
                "rarity_level": "COMMON"
            }
        }
    },
    "1901": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1901_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1901",
                "name": "Perfect Badger Hogwart #1901",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446078,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1901_1681446084.png",
                "rarity_score": 254.4386391570951,
                "rarity_level": "EPIC"
            }
        }
    },
    "1943": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1943_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1943",
                "name": "Broken Badger Archer #1943",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1943_1681446090.png",
                "rarity_score": 76.22039324381363,
                "rarity_level": "COMMON"
            }
        }
    },
    "1819": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1819_1681446070.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1819",
                "name": "Defiant Badger Dean #1819",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446063,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1819_1681446070.png",
                "rarity_score": 101.47600740018065,
                "rarity_level": "RARE"
            }
        }
    },
    "1860": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1860_1681446079.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1860",
                "name": "Hilarious Badger Holyfield #1860",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681446071,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1860_1681446079.png",
                "rarity_score": 202.23594194410734,
                "rarity_level": "RARE"
            }
        }
    },
    "1909": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1909_1681446086.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1909",
                "name": "Fancy Badger Riddle #1909",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446080,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1909_1681446086.png",
                "rarity_score": 116.02342845967942,
                "rarity_level": "RARE"
            }
        }
    },
    "1952": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1952_1681446093.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1952",
                "name": "Troubled Badger Miguel #1952",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446087,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1952_1681446093.png",
                "rarity_score": 81.7205773211343,
                "rarity_level": "COMMON"
            }
        }
    },
    "1832": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1832_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1832",
                "name": "Energetic Badger Ludo #1832",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446065,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1832_1681446071.png",
                "rarity_score": 79.35575447448282,
                "rarity_level": "COMMON"
            }
        }
    },
    "1861": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1861_1681446077.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1861",
                "name": "Dark Badger Greyback #1861",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446071,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1861_1681446077.png",
                "rarity_score": 73.76504932782522,
                "rarity_level": "COMMON"
            }
        }
    },
    "1903": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1903_1681446085.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1903",
                "name": "Arrogant Badger Bogrod #1903",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446078,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1903_1681446085.png",
                "rarity_score": 131.5291828724762,
                "rarity_level": "RARE"
            }
        }
    },
    "1945": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1945_1681446091.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1945",
                "name": "Lively Badger Ogre #1945",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1945_1681446091.png",
                "rarity_score": 67.58108137995532,
                "rarity_level": "COMMON"
            }
        }
    },
    "1820": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1820_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1820",
                "name": "Gentle Badger Watson #1820",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446063,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1820_1681446071.png",
                "rarity_score": 209.034055391291,
                "rarity_level": "RARE"
            }
        }
    },
    "1862": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1862_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1862",
                "name": "Awful Badger Aberforth #1862",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1862_1681446076.png",
                "rarity_score": 56.6971220769487,
                "rarity_level": "COMMON"
            }
        }
    },
    "1894": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1894_1681446083.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1894",
                "name": "Panda Badger Macaulay #1894",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1894_1681446083.png",
                "rarity_score": 97.22108959412358,
                "rarity_level": "RARE"
            }
        }
    },
    "1933": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1933_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1933",
                "name": "Elated Badger Bridger #1933",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1933_1681446090.png",
                "rarity_score": 102.60724781682055,
                "rarity_level": "RARE"
            }
        }
    },
    "1826": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1826_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1826",
                "name": "Helpless Badger Willow #1826",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446064,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1826_1681446071.png",
                "rarity_score": 116.13309825945274,
                "rarity_level": "RARE"
            }
        }
    },
    "1863": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1863_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1863",
                "name": "Asthmatic Badger Deontay #1863",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1863_1681446076.png",
                "rarity_score": 63.69091344040385,
                "rarity_level": "COMMON"
            }
        }
    },
    "1896": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1896_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1896",
                "name": "Grumpy Badger Griphook #1896",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1896_1681446082.png",
                "rarity_score": 79.23605832378752,
                "rarity_level": "COMMON"
            }
        }
    },
    "1935": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1935_1681446089.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1935",
                "name": "Rich Badger Arther #1935",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1935_1681446089.png",
                "rarity_score": 85.4889291785336,
                "rarity_level": "COMMON"
            }
        }
    },
    "1827": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1827_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1827",
                "name": "Repulsive Badger Everett #1827",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446064,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1827_1681446071.png",
                "rarity_score": 60.76958674364181,
                "rarity_level": "COMMON"
            }
        }
    },
    "1864": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1864_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1864",
                "name": "Beautiful Badger Lucius #1864",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1864_1681446076.png",
                "rarity_score": 73.04619690221,
                "rarity_level": "COMMON"
            }
        }
    },
    "1899": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1899_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1899",
                "name": "Bright Badger Scamander #1899",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1899_1681446082.png",
                "rarity_score": 68.86101983288401,
                "rarity_level": "COMMON"
            }
        }
    },
    "1931": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1931_1681446089.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1931",
                "name": "Lovely Badger Moore #1931",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1931_1681446089.png",
                "rarity_score": 87.58600139710208,
                "rarity_level": "COMMON"
            }
        }
    },
    "1834": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1834_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1834",
                "name": "Gorgeous Badger Dumbledore #1834",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446065,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1834_1681446071.png",
                "rarity_score": 63.15573893225841,
                "rarity_level": "COMMON"
            }
        }
    },
    "1865": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1865_1681446080.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1865",
                "name": "Ugly Badger Toby #1865",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1865_1681446080.png",
                "rarity_score": 145.19662199559036,
                "rarity_level": "RARE"
            }
        }
    },
    "1911": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1911_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1911",
                "name": "Comfy Badger Miguel #1911",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1911_1681446087.png",
                "rarity_score": 106.3573389837745,
                "rarity_level": "RARE"
            }
        }
    },
    "1961": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1961_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1961",
                "name": "Wild Badger Brawley #1961",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1961_1681446095.png",
                "rarity_score": 88.56903534326179,
                "rarity_level": "COMMON"
            }
        }
    },
    "1829": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1829_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1829",
                "name": "Annoying Badger Pigwidgeon #1829",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446065,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1829_1681446071.png",
                "rarity_score": 93.53191326803446,
                "rarity_level": "RARE"
            }
        }
    },
    "1866": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1866_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1866",
                "name": "Doubtful Badger Credence #1866",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1866_1681446081.png",
                "rarity_score": 294.10850730475977,
                "rarity_level": "EPIC"
            }
        }
    },
    "1924": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1924_1681446088.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1924",
                "name": "Byzantine Badger Bryce #1924",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1924_1681446088.png",
                "rarity_score": 61.645948160291944,
                "rarity_level": "COMMON"
            }
        }
    },
    "1964": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1964_1681446093.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1964",
                "name": "Fantastic Badger Tiberius #1964",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1964_1681446093.png",
                "rarity_score": 59.70169585765916,
                "rarity_level": "COMMON"
            }
        }
    },
    "1835": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1835_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1835",
                "name": "FUD Badger Greyback #1835",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446066,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1835_1681446071.png",
                "rarity_score": 71.54265295336448,
                "rarity_level": "COMMON"
            }
        }
    },
    "1867": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1867_1681446080.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1867",
                "name": "Glamorous Badger Joe #1867",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1867_1681446080.png",
                "rarity_score": 138.53164511598692,
                "rarity_level": "RARE"
            }
        }
    },
    "1912": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1912_1681446086.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1912",
                "name": "Sore Badger Fred #1912",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1912_1681446086.png",
                "rarity_score": 56.839251737598865,
                "rarity_level": "COMMON"
            }
        }
    },
    "1951": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1951_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1951",
                "name": "Dark Badger Paws #1951",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446087,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1951_1681446095.png",
                "rarity_score": 123.63045262498996,
                "rarity_level": "RARE"
            }
        }
    },
    "1831": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1831_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1831",
                "name": "Moon Badger Mundungus #1831",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446065,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1831_1681446071.png",
                "rarity_score": 83.21715882043588,
                "rarity_level": "COMMON"
            }
        }
    },
    "1868": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1868_1681446078.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1868",
                "name": "Muddy Badger Leonard #1868",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1868_1681446078.png",
                "rarity_score": 101.43951085429993,
                "rarity_level": "RARE"
            }
        }
    },
    "1904": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1904_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1904",
                "name": "Scary Badger Brawley #1904",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446078,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1904_1681446084.png",
                "rarity_score": 64.61525878540408,
                "rarity_level": "COMMON"
            }
        }
    },
    "1938": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1938_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1938",
                "name": "Fancy Badger Dirk #1938",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1938_1681446090.png",
                "rarity_score": 97.89072092587024,
                "rarity_level": "RARE"
            }
        }
    },
    "1841": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1841_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1841",
                "name": "Helpless Badger Vernon #1841",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446067,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1841_1681446071.png",
                "rarity_score": 55.0111190755291,
                "rarity_level": "COMMON"
            }
        }
    },
    "1869": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1869_1681446079.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1869",
                "name": "Confused Badger Slughorn #1869",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1869_1681446079.png",
                "rarity_score": 98.80842250132147,
                "rarity_level": "RARE"
            }
        }
    },
    "1907": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1907_1681446086.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1907",
                "name": "Horrible Badger Nimbus #1907",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446079,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1907_1681446086.png",
                "rarity_score": 100.73924663655991,
                "rarity_level": "RARE"
            }
        }
    },
    "1950": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1950_1681446092.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1950",
                "name": "Fantastic Badger Snape #1950",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446087,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1950_1681446092.png",
                "rarity_score": 59.4122178134303,
                "rarity_level": "COMMON"
            }
        }
    },
    "1824": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1824_1681446071.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1824",
                "name": "FUD Badger Sugar #1824",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446064,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1824_1681446071.png",
                "rarity_score": 99.7929615930174,
                "rarity_level": "RARE"
            }
        }
    },
    "1870": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1870_1681446077.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1870",
                "name": "Daft Badger Fallon #1870",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446072,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1870_1681446077.png",
                "rarity_score": 58.726480499282026,
                "rarity_level": "COMMON"
            }
        }
    },
    "1900": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1900_1681446083.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1900",
                "name": "Worried Badger Marcus #1900",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1900_1681446083.png",
                "rarity_score": 71.99754217551315,
                "rarity_level": "COMMON"
            }
        }
    },
    "1936": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1936_1681446089.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1936",
                "name": "Panda Badger Beckham #1936",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446084,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1936_1681446089.png",
                "rarity_score": 64.382250174703,
                "rarity_level": "COMMON"
            }
        }
    },
    "1833": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1833_1681446072.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1833",
                "name": "Panda Badger Keir #1833",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446065,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1833_1681446072.png",
                "rarity_score": 130.86784198350506,
                "rarity_level": "RARE"
            }
        }
    },
    "1871": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1871_1681446078.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1871",
                "name": "Clammy Badger Rudy #1871",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1871_1681446078.png",
                "rarity_score": 63.94800520984253,
                "rarity_level": "COMMON"
            }
        }
    },
    "1906": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1906_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1906",
                "name": "Scary Badger Mad-Eye Moody #1906",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446079,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1906_1681446084.png",
                "rarity_score": 70.21897054201423,
                "rarity_level": "COMMON"
            }
        }
    },
    "1942": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1942_1681446091.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1942",
                "name": "Jealous Badger Cannon #1942",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1942_1681446091.png",
                "rarity_score": 58.98857307514355,
                "rarity_level": "COMMON"
            }
        }
    },
    "1825": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1825_1681446072.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1825",
                "name": "Anal Badger Roy #1825",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446064,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1825_1681446072.png",
                "rarity_score": 82.36280307793427,
                "rarity_level": "COMMON"
            }
        }
    },
    "1872": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1872_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1872",
                "name": "Ugly Badger Ronan #1872",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1872_1681446081.png",
                "rarity_score": 97.75707654403097,
                "rarity_level": "RARE"
            }
        }
    },
    "1916": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1916_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1916",
                "name": "Bashful Badger Jasper #1916",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1916_1681446087.png",
                "rarity_score": 58.3694223823698,
                "rarity_level": "COMMON"
            }
        }
    },
    "1954": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1954_1681446093.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1954",
                "name": "Creepy Badger Godric #1954",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1954_1681446093.png",
                "rarity_score": 59.78986602812273,
                "rarity_level": "COMMON"
            }
        }
    },
    "1828": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1828_1681446072.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1828",
                "name": "Aggressive Badger Heathcliff #1828",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446064,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1828_1681446072.png",
                "rarity_score": 83.10531386954091,
                "rarity_level": "COMMON"
            }
        }
    },
    "1814": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1814_1681446072.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1814",
                "name": "Fantastic Badger Boot #1814",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446061,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1814_1681446072.png",
                "rarity_score": 86.3460650965441,
                "rarity_level": "COMMON"
            }
        }
    },
    "1874": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1874_1681446080.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1874",
                "name": "Daft Badger Robinson #1874",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1874_1681446080.png",
                "rarity_score": 97.68275006171827,
                "rarity_level": "RARE"
            }
        }
    },
    "1873": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1873_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1873",
                "name": "Whale Badger Jamieson #1873",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1873_1681446081.png",
                "rarity_score": 123.78530411824835,
                "rarity_level": "RARE"
            }
        }
    },
    "1830": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1830_1681446072.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1830",
                "name": "Healthy Badger Salazar #1830",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446065,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1830_1681446072.png",
                "rarity_score": 108.85810798798308,
                "rarity_level": "RARE"
            }
        }
    },
    "1875": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1875_1681446080.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1875",
                "name": "Clean Badger Chavez #1875",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1875_1681446080.png",
                "rarity_score": 331.83958999775683,
                "rarity_level": "EPIC"
            }
        }
    },
    "1915": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1915_1681446088.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1915",
                "name": "Precious Badger Ronan #1915",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1915_1681446088.png",
                "rarity_score": 108.44909295917722,
                "rarity_level": "RARE"
            }
        }
    },
    "1965": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1965_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1965",
                "name": "Cruel Badger Dirk #1965",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1965_1681446096.png",
                "rarity_score": 81.49941447333613,
                "rarity_level": "COMMON"
            }
        }
    },
    "1823": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1823_1681446072.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1823",
                "name": "Gorgeous Badger Bowtruckle #1823",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446063,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1823_1681446072.png",
                "rarity_score": 124.08167664394921,
                "rarity_level": "RARE"
            }
        }
    },
    "1876": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1876_1681446080.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1876",
                "name": "Famous Badger Flint #1876",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1876_1681446080.png",
                "rarity_score": 85.14036423737387,
                "rarity_level": "COMMON"
            }
        }
    },
    "1913": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1913_1681446085.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1913",
                "name": "Dildo Badger Johnson #1913",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1913_1681446085.png",
                "rarity_score": 61.76906477885023,
                "rarity_level": "COMMON"
            }
        }
    },
    "1946": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1946_1681446092.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1946",
                "name": "Lucky Badger Chavez #1946",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446086,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1946_1681446092.png",
                "rarity_score": 160.66462154046854,
                "rarity_level": "RARE"
            }
        }
    },
    "1842": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1842_1681446073.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1842",
                "name": "Tough Badger Draco #1842",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446067,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1842_1681446073.png",
                "rarity_score": 79.6875200217579,
                "rarity_level": "COMMON"
            }
        }
    },
    "1877": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1877_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1877",
                "name": "Super Badger Mike #1877",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446073,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1877_1681446081.png",
                "rarity_score": 245.94245948196146,
                "rarity_level": "EPIC"
            }
        }
    },
    "1928": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1928_1681446091.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1928",
                "name": "Ashamed Badger Diggory #1928",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1928_1681446091.png",
                "rarity_score": 126.39675797210899,
                "rarity_level": "RARE"
            }
        }
    },
    "1982": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1982_1681446097.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1982",
                "name": "Lonely Badger Pacquiao #1982",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446092,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1982_1681446097.png",
                "rarity_score": 89.45684710885949,
                "rarity_level": "RARE"
            }
        }
    },
    "1844": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1844_1681446073.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1844",
                "name": "Brainy Badger Foreman #1844",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446067,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1844_1681446073.png",
                "rarity_score": 95.92839354462647,
                "rarity_level": "RARE"
            }
        }
    },
    "1878": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1878_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1878",
                "name": "Exuberant Badger Johnson #1878",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446074,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1878_1681446084.png",
                "rarity_score": 140.3786557532111,
                "rarity_level": "RARE"
            }
        }
    },
    "1939": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1939_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1939",
                "name": "Degen Badger Crabbe #1939",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1939_1681446090.png",
                "rarity_score": 90.69027998423525,
                "rarity_level": "RARE"
            }
        }
    },
    "1977": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1977_1681446097.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1977",
                "name": "Tame Badger Scorpius #1977",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446091,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1977_1681446097.png",
                "rarity_score": 61.131224647572665,
                "rarity_level": "COMMON"
            }
        }
    },
    "1845": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1845_1681446073.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1845",
                "name": "Dark Badger Jones #1845",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446068,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1845_1681446073.png",
                "rarity_score": 270.9034706443096,
                "rarity_level": "EPIC"
            }
        }
    },
    "1879": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1879_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1879",
                "name": "Shy Badger Jamieson #1879",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446074,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1879_1681446081.png",
                "rarity_score": 103.03300023813314,
                "rarity_level": "RARE"
            }
        }
    },
    "1917": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1917_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1917",
                "name": "Disturbed Badger Newt #1917",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1917_1681446087.png",
                "rarity_score": 70.56042689783813,
                "rarity_level": "COMMON"
            }
        }
    },
    "1956": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1956_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1956",
                "name": "Dull Badger Trevor #1956",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1956_1681446095.png",
                "rarity_score": 76.43568531810212,
                "rarity_level": "COMMON"
            }
        }
    },
    "1838": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1838_1681446074.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1838",
                "name": "Contagious Badger Riddick #1838",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446066,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1838_1681446074.png",
                "rarity_score": 423.7513223023174,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1880": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1880_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1880",
                "name": "Uptight Badger Mayweather #1880",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446074,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1880_1681446081.png",
                "rarity_score": 116.49852801306233,
                "rarity_level": "RARE"
            }
        }
    },
    "1925": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1925_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1925",
                "name": "Disturbed Badger Dexter #1925",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1925_1681446090.png",
                "rarity_score": 144.2053963421149,
                "rarity_level": "RARE"
            }
        }
    },
    "1981": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1981_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1981",
                "name": "Cautious Badger Julio #1981",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446092,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1981_1681446100.png",
                "rarity_score": 138.23467525418684,
                "rarity_level": "RARE"
            }
        }
    },
    "1839": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1839_1681446074.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1839",
                "name": "Curious Badger Gornuk #1839",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446066,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1839_1681446074.png",
                "rarity_score": 142.30810094967623,
                "rarity_level": "RARE"
            }
        }
    },
    "1881": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1881_1681446079.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1881",
                "name": "Lovely Badger Robinson #1881",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446075,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1881_1681446079.png",
                "rarity_score": 57.94674859190989,
                "rarity_level": "COMMON"
            }
        }
    },
    "1908": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1908_1681446085.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1908",
                "name": "Ill Badger Benedick #1908",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446080,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1908_1681446085.png",
                "rarity_score": 63.98303940175861,
                "rarity_level": "COMMON"
            }
        }
    },
    "1948": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1948_1681446091.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1948",
                "name": "Fantastic Badger Morrie #1948",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446086,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1948_1681446091.png",
                "rarity_score": 58.0432368032858,
                "rarity_level": "COMMON"
            }
        }
    },
    "1850": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1850_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1850",
                "name": "Repulsive Badger Colin #1850",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446069,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1850_1681446075.png",
                "rarity_score": 71.78145105385008,
                "rarity_level": "COMMON"
            }
        }
    },
    "1882": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1882_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1882",
                "name": "Eager Badger Carter #1882",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446075,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1882_1681446081.png",
                "rarity_score": 58.57891291733077,
                "rarity_level": "COMMON"
            }
        }
    },
    "1920": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1920_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1920",
                "name": "Arrogant Badger Muggle #1920",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1920_1681446087.png",
                "rarity_score": 71.07997203542409,
                "rarity_level": "COMMON"
            }
        }
    },
    "1959": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1959_1681446093.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1959",
                "name": "Rekt Badger Holly #1959",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1959_1681446093.png",
                "rarity_score": 54.35231109932551,
                "rarity_level": "COMMON"
            }
        }
    },
    "1851": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1851_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1851",
                "name": "Pleasant Badger Ali #1851",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446069,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1851_1681446075.png",
                "rarity_score": 71.86315838977173,
                "rarity_level": "COMMON"
            }
        }
    },
    "1883": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1883_1681446080.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1883",
                "name": "Annoyed Badger Dallas #1883",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446075,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1883_1681446080.png",
                "rarity_score": 54.92387104022158,
                "rarity_level": "COMMON"
            }
        }
    },
    "1910": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1910_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1910",
                "name": "Misty Badger Khan #1910",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446080,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1910_1681446087.png",
                "rarity_score": 105.0277101748083,
                "rarity_level": "RARE"
            }
        }
    },
    "1955": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1955_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1955",
                "name": "Doubtful Badger Yaxley #1955",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1955_1681446095.png",
                "rarity_score": 117.56182214932039,
                "rarity_level": "RARE"
            }
        }
    },
    "1836": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1836_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1836",
                "name": "Jolly Badger Julio #1836",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446066,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1836_1681446075.png",
                "rarity_score": 117.81353301924851,
                "rarity_level": "RARE"
            }
        }
    },
    "1884": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1884_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1884",
                "name": "Degen Badger Holly #1884",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446075,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1884_1681446082.png",
                "rarity_score": 105.56343664369638,
                "rarity_level": "RARE"
            }
        }
    },
    "1932": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1932_1681446089.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1932",
                "name": "Average Badger Bellatrix #1932",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1932_1681446089.png",
                "rarity_score": 74.16934839992663,
                "rarity_level": "COMMON"
            }
        }
    },
    "1967": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1967_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1967",
                "name": "Quaint Badger Scimgeour #1967",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1967_1681446096.png",
                "rarity_score": 246.4377558904364,
                "rarity_level": "EPIC"
            }
        }
    },
    "1837": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1837_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1837",
                "name": "Shill Badger Weasley #1837",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446066,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1837_1681446075.png",
                "rarity_score": 88.33977023773053,
                "rarity_level": "COMMON"
            }
        }
    },
    "1886": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1886_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1886",
                "name": "Good Badger Wilder #1886",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1886_1681446081.png",
                "rarity_score": 174.90787675876385,
                "rarity_level": "RARE"
            }
        }
    },
    "1923": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1923_1681446088.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1923",
                "name": "Annoying Badger Julius #1923",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1923_1681446088.png",
                "rarity_score": 63.95199223172275,
                "rarity_level": "COMMON"
            }
        }
    },
    "1963": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1963_1681446094.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1963",
                "name": "Broken Badger Gideon #1963",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1963_1681446094.png",
                "rarity_score": 73.56139395007719,
                "rarity_level": "COMMON"
            }
        }
    },
    "1848": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1848_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1848",
                "name": "Modern Badger Killian #1848",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446068,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1848_1681446075.png",
                "rarity_score": 64.79640260244376,
                "rarity_level": "COMMON"
            }
        }
    },
    "1887": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1887_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1887",
                "name": "Panda Badger Georgie #1887",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1887_1681446081.png",
                "rarity_score": 60.763893813407144,
                "rarity_level": "COMMON"
            }
        }
    },
    "1927": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1927_1681446088.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1927",
                "name": "Grumpy Badger Mandrake #1927",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1927_1681446088.png",
                "rarity_score": 56.50696897617723,
                "rarity_level": "COMMON"
            }
        }
    },
    "1966": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1966_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1966",
                "name": "Silly Badger Augustus #1966",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1966_1681446095.png",
                "rarity_score": 77.7359555524486,
                "rarity_level": "COMMON"
            }
        }
    },
    "1843": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1843_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1843",
                "name": "Gleaming Badger Grey #1843",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446067,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1843_1681446075.png",
                "rarity_score": 103.84506329189263,
                "rarity_level": "RARE"
            }
        }
    },
    "1888": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1888_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1888",
                "name": "Obnoxious Badger Lomachenko #1888",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1888_1681446082.png",
                "rarity_score": 65.65836429767093,
                "rarity_level": "COMMON"
            }
        }
    },
    "1930": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1930_1681446089.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1930",
                "name": "Uptight Badger Scabbers #1930",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1930_1681446089.png",
                "rarity_score": 89.63582773030897,
                "rarity_level": "RARE"
            }
        }
    },
    "1968": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1968_1681446098.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1968",
                "name": "Embarrassed Badger Quentin #1968",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1968_1681446098.png",
                "rarity_score": 103.40760659499874,
                "rarity_level": "RARE"
            }
        }
    },
    "1849": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1849_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1849",
                "name": "Carebear Badger Marcus #1849",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446069,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1849_1681446075.png",
                "rarity_score": 106.54673022446646,
                "rarity_level": "RARE"
            }
        }
    },
    "1889": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1889_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1889",
                "name": "Filthy Badger Hippogriff #1889",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1889_1681446081.png",
                "rarity_score": 291.97552601109913,
                "rarity_level": "EPIC"
            }
        }
    },
    "1918": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1918_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1918",
                "name": "Eager Badger Philip #1918",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1918_1681446087.png",
                "rarity_score": 119.97998333846456,
                "rarity_level": "RARE"
            }
        }
    },
    "1958": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1958_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1958",
                "name": "Lucky Badger Scabbers #1958",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1958_1681446095.png",
                "rarity_score": 134.82496096823883,
                "rarity_level": "RARE"
            }
        }
    },
    "1846": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1846_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1846",
                "name": "Ugly Badger Firenze #1846",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446068,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1846_1681446075.png",
                "rarity_score": 115.41554217409129,
                "rarity_level": "RARE"
            }
        }
    },
    "1890": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1890_1681446082.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1890",
                "name": "Obnoxious Badger Puff #1890",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1890_1681446082.png",
                "rarity_score": 99.85583561777159,
                "rarity_level": "RARE"
            }
        }
    },
    "1929": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1929_1681446088.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1929",
                "name": "Faithful Badger Ranger #1929",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446083,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1929_1681446088.png",
                "rarity_score": 77.62128004197606,
                "rarity_level": "COMMON"
            }
        }
    },
    "1960": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1960_1681446094.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1960",
                "name": "Clean Badger Larkin #1960",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1960_1681446094.png",
                "rarity_score": 96.18972017598978,
                "rarity_level": "RARE"
            }
        }
    },
    "1855": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1855_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1855",
                "name": "Upset Badger Duncan #1855",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446070,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1855_1681446075.png",
                "rarity_score": 75.37826779358828,
                "rarity_level": "COMMON"
            }
        }
    },
    "1891": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1891_1681446086.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1891",
                "name": "Calm Badger Fang #1891",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446076,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1891_1681446086.png",
                "rarity_score": 152.9389056246194,
                "rarity_level": "RARE"
            }
        }
    },
    "1949": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1949_1681446091.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1949",
                "name": "Bewildered Badger Voldemort #1949",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446086,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1949_1681446091.png",
                "rarity_score": 74.24480486390715,
                "rarity_level": "COMMON"
            }
        }
    },
    "1983": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1983_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1983",
                "name": "Amused Badger Puff #1983",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446092,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1983_1681446096.png",
                "rarity_score": 419.987922705314,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "1857": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1857_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1857",
                "name": "Uptight Badger Leonard #1857",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446070,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1857_1681446076.png",
                "rarity_score": 66.08227991222344,
                "rarity_level": "COMMON"
            }
        }
    },
    "1892": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1892_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1892",
                "name": "Asthmatic Badger Jasper #1892",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1892_1681446081.png",
                "rarity_score": 51.79313349523235,
                "rarity_level": "COMMON"
            }
        }
    },
    "1921": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1921_1681446088.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1921",
                "name": "Rich Badger Edward #1921",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1921_1681446088.png",
                "rarity_score": 115.92607629405444,
                "rarity_level": "RARE"
            }
        }
    },
    "1962": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1962_1681446094.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1962",
                "name": "Disturbed Badger Trevor #1962",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446089,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1962_1681446094.png",
                "rarity_score": 75.36188642213381,
                "rarity_level": "COMMON"
            }
        }
    },
    "1840": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1840_1681446075.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1840",
                "name": "Foolish Badger Moby #1840",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446067,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1840_1681446075.png",
                "rarity_score": 96.57058291516768,
                "rarity_level": "RARE"
            }
        }
    },
    "1893": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1893_1681446081.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1893",
                "name": "Perfect Badger Horace #1893",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1893_1681446081.png",
                "rarity_score": 53.73229783404925,
                "rarity_level": "COMMON"
            }
        }
    },
    "1922": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1922_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1922",
                "name": "Concerned Badger Quentin #1922",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1922_1681446087.png",
                "rarity_score": 58.79269818440062,
                "rarity_level": "COMMON"
            }
        }
    },
    "1953": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1953_1681446093.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1953",
                "name": "Carebear Badger Dallas #1953",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1953_1681446093.png",
                "rarity_score": 75.41576271474285,
                "rarity_level": "COMMON"
            }
        }
    },
    "1852": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1852_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1852",
                "name": "Concerned Badger Salazar #1852",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446069,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1852_1681446076.png",
                "rarity_score": 96.97531491482488,
                "rarity_level": "RARE"
            }
        }
    },
    "1895": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1895_1681446085.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1895",
                "name": "Ludicrous Badger Holyfield #1895",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1895_1681446085.png",
                "rarity_score": 111.54994642458038,
                "rarity_level": "RARE"
            }
        }
    },
    "1944": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1944_1681446092.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1944",
                "name": "Cruel Badger Minerva #1944",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1944_1681446092.png",
                "rarity_score": 106.79776725499386,
                "rarity_level": "RARE"
            }
        }
    },
    "1987": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1987_1681446099.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1987",
                "name": "Ugly Badger Arlo #1987",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446093,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1987_1681446099.png",
                "rarity_score": 84.53389179430158,
                "rarity_level": "COMMON"
            }
        }
    },
    "1854": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1854_1681446076.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1854",
                "name": "Carebear Badger Minerva #1854",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446069,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1854_1681446076.png",
                "rarity_score": 109.67510095024818,
                "rarity_level": "RARE"
            }
        }
    },
    "1897": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1897_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1897",
                "name": "Talented Badger Riddle #1897",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446077,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1897_1681446084.png",
                "rarity_score": 87.44179712857003,
                "rarity_level": "COMMON"
            }
        }
    },
    "1940": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1940_1681446092.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1940",
                "name": "Troubled Badger Evander #1940",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1940_1681446092.png",
                "rarity_score": 202.50030461171337,
                "rarity_level": "RARE"
            }
        }
    },
    "1985": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1985_1681446098.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1985",
                "name": "Contagious Badger Stone #1985",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446092,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1985_1681446098.png",
                "rarity_score": 60.84032117291601,
                "rarity_level": "COMMON"
            }
        }
    },
    "1856": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1856_1681446077.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1856",
                "name": "Cautious Badger Wentworth #1856",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446070,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1856_1681446077.png",
                "rarity_score": 69.76444494913285,
                "rarity_level": "COMMON"
            }
        }
    },
    "1902": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1902_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1902",
                "name": "Silly Badger Crabbe #1902",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446078,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1902_1681446084.png",
                "rarity_score": 100.64913391507125,
                "rarity_level": "RARE"
            }
        }
    },
    "1941": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1941_1681446093.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1941",
                "name": "Moon Badger Buckbeak #1941",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1941_1681446093.png",
                "rarity_score": 110.02934420892785,
                "rarity_level": "RARE"
            }
        }
    },
    "1994": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1994_1681446099.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1994",
                "name": "Obnoxious Badger Basilisk #1994",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446094,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1994_1681446099.png",
                "rarity_score": 59.492300979578665,
                "rarity_level": "COMMON"
            }
        }
    },
    "1847": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1847_1681446078.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1847",
                "name": "Wicked Badger Buckbeak #1847",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446068,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1847_1681446078.png",
                "rarity_score": 118.11358328144213,
                "rarity_level": "RARE"
            }
        }
    },
    "1905": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1905_1681446084.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1905",
                "name": "Homeless Badger Griphook #1905",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446078,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1905_1681446084.png",
                "rarity_score": 63.424032162572956,
                "rarity_level": "COMMON"
            }
        }
    },
    "1937": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1937_1681446090.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1937",
                "name": "Sleepy Badger Filius #1937",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446085,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1937_1681446090.png",
                "rarity_score": 69.31262912779457,
                "rarity_level": "COMMON"
            }
        }
    },
    "1974": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1974_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1974",
                "name": "Clammy Badger Jem #1974",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446091,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1974_1681446096.png",
                "rarity_score": 78.17222231417645,
                "rarity_level": "COMMON"
            }
        }
    },
    "1914": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1914_1681446085.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1914",
                "name": "Grumpy Badger Braham #1914",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446081,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1914_1681446085.png",
                "rarity_score": 61.47984141091329,
                "rarity_level": "COMMON"
            }
        }
    },
    "1947": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1947_1681446094.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1947",
                "name": "HODL Badger Hippogriff #1947",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446086,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1947_1681446094.png",
                "rarity_score": 124.57146663118121,
                "rarity_level": "RARE"
            }
        }
    },
    "1995": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1995_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1995",
                "name": "Healthy Badger Firenze #1995",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1995_1681446100.png",
                "rarity_score": 59.6083253752712,
                "rarity_level": "COMMON"
            }
        }
    },
    "2035": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2035_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2035",
                "name": "Healthy Badger Roy #2035",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446101,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2035_1681446109.png",
                "rarity_score": 81.94491330283047,
                "rarity_level": "COMMON"
            }
        }
    },
    "1919": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1919_1681446087.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1919",
                "name": "Ill Badger Killian #1919",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446082,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1919_1681446087.png",
                "rarity_score": 69.46095053757155,
                "rarity_level": "COMMON"
            }
        }
    },
    "1957": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1957_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1957",
                "name": "Perfect Badger Filch #1957",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446088,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1957_1681446095.png",
                "rarity_score": 241.1294883358305,
                "rarity_level": "EPIC"
            }
        }
    },
    "2004": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2004_1681446102.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2004",
                "name": "Anxious Badger Huck #2004",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2004_1681446102.png",
                "rarity_score": 93.3710136365874,
                "rarity_level": "RARE"
            }
        }
    },
    "2040": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2040_1681446108.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2040",
                "name": "Itchy Badger Maxim #2040",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446102,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2040_1681446108.png",
                "rarity_score": 75.61206015980848,
                "rarity_level": "COMMON"
            }
        }
    },
    "1969": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1969_1681446094.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1969",
                "name": "Quaint Badger Hippogriff #1969",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1969_1681446094.png",
                "rarity_score": 54.950806668154804,
                "rarity_level": "COMMON"
            }
        }
    },
    "1996": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1996_1681446099.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1996",
                "name": "Dark Badger Christer #1996",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1996_1681446099.png",
                "rarity_score": 72.8910407759077,
                "rarity_level": "COMMON"
            }
        }
    },
    "2029": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2029_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2029",
                "name": "Clean Badger Seamus #2029",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446100,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2029_1681446105.png",
                "rarity_score": 74.68602897441447,
                "rarity_level": "COMMON"
            }
        }
    },
    "2065": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2065_1681446113.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2065",
                "name": "Ashamed Badger Clay #2065",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2065_1681446113.png",
                "rarity_score": 99.8599676889782,
                "rarity_level": "RARE"
            }
        }
    },
    "1973": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1973_1681446095.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1973",
                "name": "Arrogant Badger Griphook #1973",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1973_1681446095.png",
                "rarity_score": 369.26741755904493,
                "rarity_level": "EPIC"
            }
        }
    },
    "2003": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2003_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2003",
                "name": "Broken Badger Jett #2003",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2003_1681446100.png",
                "rarity_score": 70.11176703190526,
                "rarity_level": "COMMON"
            }
        }
    },
    "2036": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2036_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2036",
                "name": "Nice Badger Norbert #2036",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446101,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2036_1681446109.png",
                "rarity_score": 457.710773750501,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2082": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2082_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2082",
                "name": "Homeless Badger Gellert #2082",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446110,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2082_1681446117.png",
                "rarity_score": 85.12960211533002,
                "rarity_level": "COMMON"
            }
        }
    },
    "1970": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1970_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1970",
                "name": "Poor Badger Pigwidgeon #1970",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1970_1681446096.png",
                "rarity_score": 348.5330054140175,
                "rarity_level": "EPIC"
            }
        }
    },
    "2007": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2007_1681446102.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2007",
                "name": "Nasty Badger Bellatrix #2007",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2007_1681446102.png",
                "rarity_score": 88.38651466103161,
                "rarity_level": "COMMON"
            }
        }
    },
    "2041": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2041_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2041",
                "name": "Bookworm Badger Sugar #2041",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446103,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2041_1681446109.png",
                "rarity_score": 103.68999758825042,
                "rarity_level": "RARE"
            }
        }
    },
    "2085": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2085_1681446120.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2085",
                "name": "Dildo Badger Amos #2085",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2085_1681446120.png",
                "rarity_score": 90.65137406063785,
                "rarity_level": "RARE"
            }
        }
    },
    "1971": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1971_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1971",
                "name": "Expensive Badger Rufus #1971",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1971_1681446096.png",
                "rarity_score": 81.04577720739968,
                "rarity_level": "COMMON"
            }
        }
    },
    "2008": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2008_1681446102.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2008",
                "name": "Modern Badger Grey #2008",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2008_1681446102.png",
                "rarity_score": 74.49374743480814,
                "rarity_level": "COMMON"
            }
        }
    },
    "2042": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2042_1681446107.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2042",
                "name": "Moon Badger Braham #2042",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446103,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2042_1681446107.png",
                "rarity_score": 63.11289008134982,
                "rarity_level": "COMMON"
            }
        }
    },
    "2072": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2072_1681446118.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2072",
                "name": "Degen Badger Dursley #2072",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446108,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2072_1681446118.png",
                "rarity_score": 131.5670850974252,
                "rarity_level": "RARE"
            }
        }
    },
    "1978": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1978_1681446096.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1978",
                "name": "Calm Badger Gideon #1978",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446091,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1978_1681446096.png",
                "rarity_score": 59.42235461456442,
                "rarity_level": "COMMON"
            }
        }
    },
    "2010": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2010_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2010",
                "name": "Tense Badger Edward #2010",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2010_1681446103.png",
                "rarity_score": 107.45106810152919,
                "rarity_level": "RARE"
            }
        }
    },
    "2053": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2053_1681446110.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2053",
                "name": "Evil Badger Langston #2053",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2053_1681446110.png",
                "rarity_score": 98.59627708711605,
                "rarity_level": "RARE"
            }
        }
    },
    "2091": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2091_1681446119.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2091",
                "name": "Brave Badger Grindylow #2091",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2091_1681446119.png",
                "rarity_score": 96.33611487845721,
                "rarity_level": "RARE"
            }
        }
    },
    "1972": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1972_1681446097.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1972",
                "name": "Rekt Badger Wilbur #1972",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446090,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1972_1681446097.png",
                "rarity_score": 79.40947967489586,
                "rarity_level": "COMMON"
            }
        }
    },
    "2016": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2016_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2016",
                "name": "Quaint Badger Charlie #2016",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446098,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2016_1681446103.png",
                "rarity_score": 76.32946037253504,
                "rarity_level": "COMMON"
            }
        }
    },
    "2051": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2051_1681446111.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2051",
                "name": "Perfect Badger Seamus #2051",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2051_1681446111.png",
                "rarity_score": 111.5870533263113,
                "rarity_level": "RARE"
            }
        }
    },
    "2095": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2095_1681446121.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2095",
                "name": "Annoyed Badger Walden #2095",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2095_1681446121.png",
                "rarity_score": 68.48184365331059,
                "rarity_level": "COMMON"
            }
        }
    },
    "1979": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1979_1681446097.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1979",
                "name": "Tame Badger Basilisk #1979",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446091,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1979_1681446097.png",
                "rarity_score": 79.28208728201841,
                "rarity_level": "COMMON"
            }
        }
    },
    "2017": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2017_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2017",
                "name": "Perfect Badger Rhett #2017",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446098,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2017_1681446105.png",
                "rarity_score": 70.26939562845173,
                "rarity_level": "COMMON"
            }
        }
    },
    "2059": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2059_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2059",
                "name": "Unsightly Badger Winky #2059",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2059_1681446112.png",
                "rarity_score": 108.80195827343105,
                "rarity_level": "RARE"
            }
        }
    },
    "1984": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1984_1681446098.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1984",
                "name": "Bot Badger Wentworth #1984",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446092,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1984_1681446098.png",
                "rarity_score": 98.14719432297738,
                "rarity_level": "RARE"
            }
        }
    },
    "2019": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2019_1681446106.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2019",
                "name": "Fantastic Badger Dursley #2019",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446098,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2019_1681446106.png",
                "rarity_score": 120.6669980827215,
                "rarity_level": "RARE"
            }
        }
    },
    "2068": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2068_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2068",
                "name": "Frail Badger Everett #2068",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446107,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2068_1681446112.png",
                "rarity_score": 87.17345132043855,
                "rarity_level": "COMMON"
            }
        }
    },
    "1975": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1975_1681446098.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1975",
                "name": "Glamorous Badger Phineas #1975",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446091,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1975_1681446098.png",
                "rarity_score": 74.49956540859044,
                "rarity_level": "COMMON"
            }
        }
    },
    "2021": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2021_1681446106.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2021",
                "name": "Wicked Badger Grindylow #2021",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446099,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2021_1681446106.png",
                "rarity_score": 126.23130051048979,
                "rarity_level": "RARE"
            }
        }
    },
    "2070": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2070_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2070",
                "name": "Evil Badger Cannon #2070",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446107,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2070_1681446112.png",
                "rarity_score": 73.64683281448075,
                "rarity_level": "COMMON"
            }
        }
    },
    "2099": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2099_1681446121.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2099",
                "name": "Happy Badger Dash #2099",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2099_1681446121.png",
                "rarity_score": 284.9094124858132,
                "rarity_level": "EPIC"
            }
        }
    },
    "1980": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1980_1681446098.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1980",
                "name": "Precious Badger Phineas #1980",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446092,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1980_1681446098.png",
                "rarity_score": 77.6133906968264,
                "rarity_level": "COMMON"
            }
        }
    },
    "2023": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2023_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2023",
                "name": "Stupid Badger Diggle #2023",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446099,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2023_1681446105.png",
                "rarity_score": 373.64857930980986,
                "rarity_level": "EPIC"
            }
        }
    },
    "2058": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2058_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2058",
                "name": "Witty Badger Fluffy #2058",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446105,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2058_1681446112.png",
                "rarity_score": 116.45098497074949,
                "rarity_level": "RARE"
            }
        }
    },
    "1993": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1993_1681446099.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1993",
                "name": "Real Badger Calen #1993",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446094,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1993_1681446099.png",
                "rarity_score": 67.43106121224118,
                "rarity_level": "COMMON"
            }
        }
    },
    "2024": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2024_1681446106.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2024",
                "name": "Degen Badger Dallas #2024",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446099,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2024_1681446106.png",
                "rarity_score": 70.08467594284276,
                "rarity_level": "COMMON"
            }
        }
    },
    "2067": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2067_1681446116.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2067",
                "name": "Clumsy Badger Kingston #2067",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446107,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2067_1681446116.png",
                "rarity_score": 77.50855391839153,
                "rarity_level": "COMMON"
            }
        }
    },
    "1976": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1976_1681446099.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1976",
                "name": "Scary Badger Thestral #1976",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446091,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1976_1681446099.png",
                "rarity_score": 102.27476683966846,
                "rarity_level": "RARE"
            }
        }
    },
    "2027": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2027_1681446104.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2027",
                "name": "Envious Badger Phineas #2027",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446100,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2027_1681446104.png",
                "rarity_score": 58.14125174005069,
                "rarity_level": "COMMON"
            }
        }
    },
    "2056": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2056_1681446113.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2056",
                "name": "Unusual Badger Aragog #2056",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446105,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2056_1681446113.png",
                "rarity_score": 111.904978328103,
                "rarity_level": "RARE"
            }
        }
    },
    "2107": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2107_1681446121.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2107",
                "name": "Dark Badger Edward #2107",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2107_1681446121.png",
                "rarity_score": 234.83766233766235,
                "rarity_level": "EPIC"
            }
        }
    },
    "1997": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1997_1681446099.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1997",
                "name": "Ugly Badger Tyson #1997",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1997_1681446099.png",
                "rarity_score": 68.19814296754069,
                "rarity_level": "COMMON"
            }
        }
    },
    "2028": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2028_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2028",
                "name": "Powerful Badger Latham #2028",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446100,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2028_1681446105.png",
                "rarity_score": 60.22805486164018,
                "rarity_level": "COMMON"
            }
        }
    },
    "2060": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2060_1681446111.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2060",
                "name": "Helpful Badger Cedric #2060",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2060_1681446111.png",
                "rarity_score": 54.57985433929883,
                "rarity_level": "COMMON"
            }
        }
    },
    "2096": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2096_1681446121.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2096",
                "name": "Excited Badger Maximus #2096",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2096_1681446121.png",
                "rarity_score": 106.69570478004623,
                "rarity_level": "RARE"
            }
        }
    },
    "1986": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1986_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1986",
                "name": "Bloody Badger Alvarez #1986",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446093,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1986_1681446100.png",
                "rarity_score": 82.3601570493254,
                "rarity_level": "COMMON"
            }
        }
    },
    "2030": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2030_1681446107.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2030",
                "name": "Gentle Badger Leopold #2030",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446100,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2030_1681446107.png",
                "rarity_score": 104.89783910398016,
                "rarity_level": "RARE"
            }
        }
    },
    "2071": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2071_1681446113.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2071",
                "name": "Cruel Badger Riddle #2071",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446108,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2071_1681446113.png",
                "rarity_score": 74.92715459015507,
                "rarity_level": "COMMON"
            }
        }
    },
    "1989": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1989_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1989",
                "name": "Unusual Badger Cecil #1989",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446094,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1989_1681446100.png",
                "rarity_score": 70.67194579283867,
                "rarity_level": "COMMON"
            }
        }
    },
    "2031": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2031_1681446108.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2031",
                "name": "Impressive Badger Everett #2031",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446100,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2031_1681446108.png",
                "rarity_score": 120.02501809548731,
                "rarity_level": "RARE"
            }
        }
    },
    "2078": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2078_1681446114.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2078",
                "name": "Famous Badger Jay #2078",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446109,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2078_1681446114.png",
                "rarity_score": 186.3448194673785,
                "rarity_level": "RARE"
            }
        }
    },
    "1992": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1992_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1992",
                "name": "Clumsy Badger Mad-Eye Moody #1992",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446094,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1992_1681446100.png",
                "rarity_score": 108.05225586171642,
                "rarity_level": "RARE"
            }
        }
    },
    "2032": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2032_1681446107.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2032",
                "name": "Anal Badger Killian #2032",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446101,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2032_1681446107.png",
                "rarity_score": 384.35623491962656,
                "rarity_level": "EPIC"
            }
        }
    },
    "2074": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2074_1681446118.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2074",
                "name": "Panda Badger Winston #2074",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446108,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2074_1681446118.png",
                "rarity_score": 110.97391532116532,
                "rarity_level": "RARE"
            }
        }
    },
    "1991": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1991_1681446100.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1991",
                "name": "Scary Badger Kostya #1991",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446094,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1991_1681446100.png",
                "rarity_score": 76.39865157378019,
                "rarity_level": "COMMON"
            }
        }
    },
    "2033": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2033_1681446106.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2033",
                "name": "Clumsy Badger Krum #2033",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446101,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2033_1681446106.png",
                "rarity_score": 124.72166121411504,
                "rarity_level": "RARE"
            }
        }
    },
    "2069": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2069_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2069",
                "name": "Uptight Badger Fabian #2069",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446107,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2069_1681446112.png",
                "rarity_score": 57.29733753890941,
                "rarity_level": "COMMON"
            }
        }
    },
    "2100": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2100_1681446120.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2100",
                "name": "Panda Badger Jacob #2100",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2100_1681446120.png",
                "rarity_score": 70.27736179270504,
                "rarity_level": "COMMON"
            }
        }
    },
    "1990": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1990_1681446101.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1990",
                "name": "Fancy Badger Weston #1990",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446094,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1990_1681446101.png",
                "rarity_score": 208.9749782866616,
                "rarity_level": "RARE"
            }
        }
    },
    "2037": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2037_1681446107.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2037",
                "name": "Bewildered Badger Kingsley #2037",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446101,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2037_1681446107.png",
                "rarity_score": 85.21010108732331,
                "rarity_level": "COMMON"
            }
        }
    },
    "2073": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2073_1681446113.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2073",
                "name": "Byzantine Badger Latham #2073",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446108,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2073_1681446113.png",
                "rarity_score": 67.9322523719572,
                "rarity_level": "COMMON"
            }
        }
    },
    "2000": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2000_1681446101.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2000",
                "name": "Dildo Badger Larkin #2000",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2000_1681446101.png",
                "rarity_score": 96.37223591110106,
                "rarity_level": "RARE"
            }
        }
    },
    "2038": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2038_1681446108.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2038",
                "name": "Naughty Badger Tyrion #2038",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446102,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2038_1681446108.png",
                "rarity_score": 122.39471942843394,
                "rarity_level": "RARE"
            }
        }
    },
    "2076": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2076_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2076",
                "name": "Friendly Badger Everett #2076",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446109,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2076_1681446117.png",
                "rarity_score": 62.31214155713908,
                "rarity_level": "COMMON"
            }
        }
    },
    "1988": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1988_1681446101.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1988",
                "name": "Grieving Badger Galen #1988",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446093,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1988_1681446101.png",
                "rarity_score": 90.86249402616284,
                "rarity_level": "RARE"
            }
        }
    },
    "2039": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2039_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2039",
                "name": "Colorful Badger Weston #2039",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446102,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2039_1681446109.png",
                "rarity_score": 117.21486466064815,
                "rarity_level": "RARE"
            }
        }
    },
    "2086": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2086_1681446119.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2086",
                "name": "Bloody Badger Theodore #2086",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2086_1681446119.png",
                "rarity_score": 60.36693319520725,
                "rarity_level": "COMMON"
            }
        }
    },
    "2001": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2001_1681446102.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2001",
                "name": "Gentle Badger Johnson #2001",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Helmet",
                        "identifier": "ecko_helmet",
                        "trait_type": "Headwear",
                        "score": 520.0
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2001_1681446102.png",
                "rarity_score": 588.6668692164257,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2043": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2043_1681446110.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2043",
                "name": "Precious Badger Boggart #2043",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446103,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2043_1681446110.png",
                "rarity_score": 71.27784147174847,
                "rarity_level": "COMMON"
            }
        }
    },
    "2013": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2013_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2013",
                "name": "Strange Badger Romeo #2013",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2013_1681446103.png",
                "rarity_score": 75.92017870456402,
                "rarity_level": "COMMON"
            }
        }
    },
    "2044": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2044_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2044",
                "name": "Tired Badger Asher #2044",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446103,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2044_1681446109.png",
                "rarity_score": 77.48005006193631,
                "rarity_level": "COMMON"
            }
        }
    },
    "2081": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2081_1681446119.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2081",
                "name": "Excited Badger Keir #2081",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446110,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2081_1681446119.png",
                "rarity_score": 126.09783813695462,
                "rarity_level": "RARE"
            }
        }
    },
    "2014": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2014_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2014",
                "name": "Dumb Badger Lily #2014",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2014_1681446103.png",
                "rarity_score": 59.94743685004236,
                "rarity_level": "COMMON"
            }
        }
    },
    "2045": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2045_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2045",
                "name": "Obnoxious Badger Klitschko #2045",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2045_1681446109.png",
                "rarity_score": 92.72944491963355,
                "rarity_level": "RARE"
            }
        }
    },
    "2083": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2083_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2083",
                "name": "Determined Badger Shields #2083",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446110,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2083_1681446117.png",
                "rarity_score": 57.32452168038284,
                "rarity_level": "COMMON"
            }
        }
    },
    "2005": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2005_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2005",
                "name": "Strange Badger Carter #2005",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2005_1681446103.png",
                "rarity_score": 108.32616371824713,
                "rarity_level": "RARE"
            }
        }
    },
    "2046": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2046_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2046",
                "name": "Dark Badger Zane #2046",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2046_1681446109.png",
                "rarity_score": 59.947339926271866,
                "rarity_level": "COMMON"
            }
        }
    },
    "2089": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2089_1681446118.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2089",
                "name": "Lucky Badger Deontay #2089",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2089_1681446118.png",
                "rarity_score": 264.6424792333363,
                "rarity_level": "EPIC"
            }
        }
    },
    "1998": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1998_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1998",
                "name": "Bookworm Badger Foreman #1998",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1998_1681446103.png",
                "rarity_score": 155.25427739923836,
                "rarity_level": "RARE"
            }
        }
    },
    "2047": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2047_1681446108.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2047",
                "name": "Wen Badger Malcolm #2047",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2047_1681446108.png",
                "rarity_score": 54.58094749967377,
                "rarity_level": "COMMON"
            }
        }
    },
    "2077": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2077_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2077",
                "name": "Hungry Badger Khan #2077",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446109,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2077_1681446117.png",
                "rarity_score": 96.2298057682034,
                "rarity_level": "RARE"
            }
        }
    },
    "2006": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2006_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2006",
                "name": "Easy Badger Macaulay #2006",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2006_1681446103.png",
                "rarity_score": 103.69756252969808,
                "rarity_level": "RARE"
            }
        }
    },
    "2048": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2048_1681446110.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2048",
                "name": "Concerned Badger Ron #2048",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2048_1681446110.png",
                "rarity_score": 98.66909085982695,
                "rarity_level": "RARE"
            }
        }
    },
    "2088": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2088_1681446119.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2088",
                "name": "Rekt Badger Willow #2088",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2088_1681446119.png",
                "rarity_score": 75.00392212667462,
                "rarity_level": "COMMON"
            }
        }
    },
    "1999": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/1999_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "1999",
                "name": "Tired Badger Killian #1999",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446095,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_1999_1681446103.png",
                "rarity_score": 122.18606034127342,
                "rarity_level": "RARE"
            }
        }
    },
    "2049": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2049_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2049",
                "name": "Disturbed Badger David #2049",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2049_1681446109.png",
                "rarity_score": 65.49316458124551,
                "rarity_level": "COMMON"
            }
        }
    },
    "2084": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2084_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2084",
                "name": "Colorful Badger Jones #2084",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2084_1681446117.png",
                "rarity_score": 104.34385251687164,
                "rarity_level": "RARE"
            }
        }
    },
    "2015": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2015_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2015",
                "name": "Naughty Badger Aragog #2015",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Helmet",
                        "identifier": "red_helmet",
                        "trait_type": "Headwear",
                        "score": 2600.0
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2015_1681446103.png",
                "rarity_score": 2668.8232782131468
            }
        }
    },
    "2050": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2050_1681446111.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2050",
                "name": "Wandering Badger Bertie #2050",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2050_1681446111.png",
                "rarity_score": 71.83008082534792,
                "rarity_level": "COMMON"
            }
        }
    },
    "2094": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2094_1681446121.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2094",
                "name": "Uptight Badger Lysander #2094",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2094_1681446121.png",
                "rarity_score": 109.09530419585418,
                "rarity_level": "RARE"
            }
        }
    },
    "2011": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2011_1681446103.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2011",
                "name": "Easy Badger Kingston #2011",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2011_1681446103.png",
                "rarity_score": 108.4946038457833,
                "rarity_level": "RARE"
            }
        }
    },
    "2052": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2052_1681446109.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2052",
                "name": "Ludicrous Badger Peeves #2052",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446104,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2052_1681446109.png",
                "rarity_score": 53.138439667579384,
                "rarity_level": "COMMON"
            }
        }
    },
    "2079": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2079_1681446116.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2079",
                "name": "Anxious Badger Max #2079",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446110,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2079_1681446116.png",
                "rarity_score": 58.40748958126306,
                "rarity_level": "COMMON"
            }
        }
    },
    "2026": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2026_1681446104.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2026",
                "name": "Arrogant Badger Colt #2026",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446100,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2026_1681446104.png",
                "rarity_score": 77.78452571452092,
                "rarity_level": "COMMON"
            }
        }
    },
    "2054": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2054_1681446111.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2054",
                "name": "Elated Badger Walden #2054",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446105,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2054_1681446111.png",
                "rarity_score": 106.74482773425395,
                "rarity_level": "RARE"
            }
        }
    },
    "2022": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2022_1681446104.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2022",
                "name": "Lonely Badger Canelo #2022",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446099,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2022_1681446104.png",
                "rarity_score": 82.22300250280347,
                "rarity_level": "COMMON"
            }
        }
    },
    "2055": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2055_1681446111.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2055",
                "name": "Depressed Badger Weasley #2055",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446105,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2055_1681446111.png",
                "rarity_score": 79.14493423399797,
                "rarity_level": "COMMON"
            }
        }
    },
    "2093": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2093_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2093",
                "name": "Enthusiastic Badger Dursley #2093",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2093_1681446117.png",
                "rarity_score": 72.08211951398181,
                "rarity_level": "COMMON"
            }
        }
    },
    "2009": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2009_1681446104.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2009",
                "name": "Impressive Badger Bastian #2009",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2009_1681446104.png",
                "rarity_score": 90.16973411843776,
                "rarity_level": "RARE"
            }
        }
    },
    "2057": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2057_1681446110.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2057",
                "name": "Delightful Badger Buckbeak #2057",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446105,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2057_1681446110.png",
                "rarity_score": 55.99727433065725,
                "rarity_level": "COMMON"
            }
        }
    },
    "2090": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2090_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2090",
                "name": "Muddy Badger Scorpius #2090",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2090_1681446117.png",
                "rarity_score": 54.09552018574575,
                "rarity_level": "COMMON"
            }
        }
    },
    "2002": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2002_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2002",
                "name": "Panda Badger Fabian #2002",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446096,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2002_1681446105.png",
                "rarity_score": 109.62981655807295,
                "rarity_level": "RARE"
            }
        }
    },
    "2062": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2062_1681446116.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2062",
                "name": "Sore Badger Fawkes #2062",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2062_1681446116.png",
                "rarity_score": 158.44650768307585,
                "rarity_level": "RARE"
            }
        }
    },
    "2025": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2025_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2025",
                "name": "Contagious Badger George #2025",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446099,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2025_1681446105.png",
                "rarity_score": 120.45810739304312,
                "rarity_level": "RARE"
            }
        }
    },
    "2061": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2061_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2061",
                "name": "Fancy Badger Laila #2061",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2061_1681446112.png",
                "rarity_score": 65.89606734747105,
                "rarity_level": "COMMON"
            }
        }
    },
    "2098": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2098_1681446120.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2098",
                "name": "Horrible Badger Credence #2098",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Ecko Helmet",
                        "identifier": "ecko_helmet",
                        "trait_type": "Headwear",
                        "score": 520.0
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2098_1681446120.png",
                "rarity_score": 578.1474940173606,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2020": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2020_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2020",
                "name": "Gleaming Badger Finnigan #2020",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446098,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2020_1681446105.png",
                "rarity_score": 77.66638376137085,
                "rarity_level": "COMMON"
            }
        }
    },
    "2063": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2063_1681446113.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2063",
                "name": "Exuberant Badger Dean #2063",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2063_1681446113.png",
                "rarity_score": 267.4223224906374,
                "rarity_level": "EPIC"
            }
        }
    },
    "2012": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2012_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2012",
                "name": "Energetic Badger Sirius #2012",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446097,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2012_1681446105.png",
                "rarity_score": 112.75568892885747,
                "rarity_level": "RARE"
            }
        }
    },
    "2064": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2064_1681446114.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2064",
                "name": "Dopey Badger Dirk #2064",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2064_1681446114.png",
                "rarity_score": 97.61008228488805,
                "rarity_level": "RARE"
            }
        }
    },
    "2018": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2018_1681446105.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2018",
                "name": "Grieving Badger Benedick #2018",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446098,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2018_1681446105.png",
                "rarity_score": 103.59804945910295,
                "rarity_level": "RARE"
            }
        }
    },
    "2066": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2066_1681446112.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2066",
                "name": "Stupid Badger Bertie #2066",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446106,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2066_1681446112.png",
                "rarity_score": 118.15040679703968,
                "rarity_level": "RARE"
            }
        }
    },
    "2101": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2101_1681446120.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2101",
                "name": "Upset Badger Bowtruckle #2101",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2101_1681446120.png",
                "rarity_score": 68.90269244179444,
                "rarity_level": "COMMON"
            }
        }
    },
    "2034": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2034_1681446110.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2034",
                "name": "Lambo Badger Mary #2034",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446101,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2034_1681446110.png",
                "rarity_score": 125.89264663035145,
                "rarity_level": "RARE"
            }
        }
    },
    "2092": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2092_1681446119.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2092",
                "name": "Precious Badger Orlando #2092",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2092_1681446119.png",
                "rarity_score": 96.2764364161012,
                "rarity_level": "RARE"
            }
        }
    },
    "2075": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2075_1681446117.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2075",
                "name": "Clean Badger Crouch #2075",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446108,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2075_1681446117.png",
                "rarity_score": 103.61350188082032,
                "rarity_level": "RARE"
            }
        }
    },
    "2080": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2080_1681446118.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2080",
                "name": "Tough Badger Gellert #2080",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446110,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2080_1681446118.png",
                "rarity_score": 90.80164819602615,
                "rarity_level": "RARE"
            }
        }
    },
    "2105": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2105_1681446121.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2105",
                "name": "Wicked Badger Hippogriff #2105",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2105_1681446121.png",
                "rarity_score": 88.09645283576911,
                "rarity_level": "COMMON"
            }
        }
    },
    "2109": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2109_1681446122.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2109",
                "name": "Silly Badger Johnson #2109",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2109_1681446122.png",
                "rarity_score": 120.1392538720497,
                "rarity_level": "RARE"
            }
        }
    },
    "2110": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2110_1681446122.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2110",
                "name": "Misty Badger Jake #2110",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2110_1681446122.png",
                "rarity_score": 91.84679621519732,
                "rarity_level": "RARE"
            }
        }
    },
    "2087": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2087_1681446122.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2087",
                "name": "Calm Badger Remus #2087",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446111,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2087_1681446122.png",
                "rarity_score": 77.25351666243384,
                "rarity_level": "COMMON"
            }
        }
    },
    "2108": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2108_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2108",
                "name": "Tense Badger Sugar Ray #2108",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2108_1681446123.png",
                "rarity_score": 116.59759829635661,
                "rarity_level": "RARE"
            }
        }
    },
    "2117": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2117_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2117",
                "name": "Confused Badger Dudley #2117",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446118,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2117_1681446123.png",
                "rarity_score": 59.88498902659855,
                "rarity_level": "COMMON"
            }
        }
    },
    "2102": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2102_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2102",
                "name": "Rich Badger Weasley #2102",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446113,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2102_1681446123.png",
                "rarity_score": 95.38103677455766,
                "rarity_level": "RARE"
            }
        }
    },
    "2111": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2111_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2111",
                "name": "Moon Badger Jake #2111",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2111_1681446123.png",
                "rarity_score": 84.39692530267666,
                "rarity_level": "COMMON"
            }
        }
    },
    "2097": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2097_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2097",
                "name": "Misty Badger Kelpie #2097",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446112,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2097_1681446123.png",
                "rarity_score": 149.8485917943824,
                "rarity_level": "RARE"
            }
        }
    },
    "2116": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2116_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2116",
                "name": "Mysterious Badger Potter #2116",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446118,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2116_1681446123.png",
                "rarity_score": 394.2355889724311,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2115": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2115_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2115",
                "name": "Eager Badger Buckbeak #2115",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446118,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2115_1681446123.png",
                "rarity_score": 59.702598871819816,
                "rarity_level": "COMMON"
            }
        }
    },
    "2114": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2114_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2114",
                "name": "Elegant Badger Richard #2114",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446117,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2114_1681446123.png",
                "rarity_score": 103.62075793960344,
                "rarity_level": "RARE"
            }
        }
    },
    "2113": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2113_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2113",
                "name": "Lucky Badger Hermes #2113",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446117,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2113_1681446123.png",
                "rarity_score": 113.95809002806975,
                "rarity_level": "RARE"
            }
        }
    },
    "2106": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2106_1681446123.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2106",
                "name": "Enchanting Badger Orlando #2106",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446114,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2106_1681446123.png",
                "rarity_score": 220.2607394599929,
                "rarity_level": "RARE"
            }
        }
    },
    "2122": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2122_1681446124.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2122",
                "name": "Grumpy Badger Kenrick #2122",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2122_1681446124.png",
                "rarity_score": 66.41282598943994,
                "rarity_level": "COMMON"
            }
        }
    },
    "2123": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2123_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2123",
                "name": "Degen Badger Krum #2123",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2123_1681446125.png",
                "rarity_score": 174.35342982367823,
                "rarity_level": "RARE"
            }
        }
    },
    "2124": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2124_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2124",
                "name": "Brainy Badger Dobby #2124",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2124_1681446125.png",
                "rarity_score": 90.09079229478125,
                "rarity_level": "RARE"
            }
        }
    },
    "2125": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2125_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2125",
                "name": "Clean Badger Brawley #2125",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2125_1681446125.png",
                "rarity_score": 113.69860020614576,
                "rarity_level": "RARE"
            }
        }
    },
    "2104": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2104_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2104",
                "name": "Whale Badger Creevy #2104",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446113,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2104_1681446125.png",
                "rarity_score": 98.78867703845619,
                "rarity_level": "RARE"
            }
        }
    },
    "2103": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2103_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2103",
                "name": "Aggressive Badger George #2103",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446113,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2103_1681446125.png",
                "rarity_score": 158.03047119725397,
                "rarity_level": "RARE"
            }
        }
    },
    "2134": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2134_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2134",
                "name": "Powerful Badger Creevy #2134",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446121,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2134_1681446125.png",
                "rarity_score": 56.41335890216316,
                "rarity_level": "COMMON"
            }
        }
    },
    "2119": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2119_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2119",
                "name": "Shill Badger Firebolt #2119",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446118,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2119_1681446125.png",
                "rarity_score": 77.47392240644085,
                "rarity_level": "COMMON"
            }
        }
    },
    "2112": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2112_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2112",
                "name": "Depressed Badger Johnson #2112",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446116,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2112_1681446125.png",
                "rarity_score": 117.9654621473504,
                "rarity_level": "RARE"
            }
        }
    },
    "2128": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2128_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2128",
                "name": "Precious Badger Christopher #2128",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446120,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2128_1681446125.png",
                "rarity_score": 61.740533319638175,
                "rarity_level": "COMMON"
            }
        }
    },
    "2121": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2121_1681446125.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2121",
                "name": "Dead Badger Castiel #2121",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2121_1681446125.png",
                "rarity_score": 80.65785311845111,
                "rarity_level": "COMMON"
            }
        }
    },
    "2130": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2130_1681446126.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2130",
                "name": "Poor Badger Christer #2130",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446120,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2130_1681446126.png",
                "rarity_score": 71.54438996076304,
                "rarity_level": "COMMON"
            }
        }
    },
    "2118": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2118_1681446126.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2118",
                "name": "Strange Badger Marciano #2118",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446118,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2118_1681446126.png",
                "rarity_score": 86.66458529007878,
                "rarity_level": "COMMON"
            }
        }
    },
    "2127": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2127_1681446126.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2127",
                "name": "Jealous Badger Langston #2127",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2127_1681446126.png",
                "rarity_score": 85.05638161230443,
                "rarity_level": "COMMON"
            }
        }
    },
    "2139": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2139_1681446126.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2139",
                "name": "Tense Badger Yaxley #2139",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446122,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2139_1681446126.png",
                "rarity_score": 59.506150919539486,
                "rarity_level": "COMMON"
            }
        }
    },
    "2120": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2120_1681446126.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2120",
                "name": "Defiant Badger Leopold #2120",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446118,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2120_1681446126.png",
                "rarity_score": 88.94962394362825,
                "rarity_level": "RARE"
            }
        }
    },
    "2136": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2136_1681446127.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2136",
                "name": "Contagious Badger Scabbers #2136",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446121,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2136_1681446127.png",
                "rarity_score": 241.48480356734402,
                "rarity_level": "EPIC"
            }
        }
    },
    "2126": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2126_1681446127.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2126",
                "name": "Determined Badger Sebastian #2126",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446119,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2126_1681446127.png",
                "rarity_score": 120.16642662485665,
                "rarity_level": "RARE"
            }
        }
    },
    "2133": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2133_1681446127.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2133",
                "name": "Dull Badger Tiberius #2133",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446121,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2133_1681446127.png",
                "rarity_score": 115.99014971662126,
                "rarity_level": "RARE"
            }
        }
    },
    "2132": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2132_1681446127.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2132",
                "name": "Cryptic Badger Moore #2132",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446121,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2132_1681446127.png",
                "rarity_score": 138.71317933694615,
                "rarity_level": "RARE"
            }
        }
    },
    "2135": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2135_1681446128.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2135",
                "name": "Anxious Badger Cal #2135",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446121,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2135_1681446128.png",
                "rarity_score": 105.5151393246594,
                "rarity_level": "RARE"
            }
        }
    },
    "2173": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2173_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2173",
                "name": "Rekt Badger Kenrick #2173",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446128,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2173_1681446132.png",
                "rarity_score": 54.60392979888903,
                "rarity_level": "COMMON"
            }
        }
    },
    "2142": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2142_1681446128.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2142",
                "name": "Testy Badger Theodore #2142",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446123,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2142_1681446128.png",
                "rarity_score": 193.87474266719548,
                "rarity_level": "RARE"
            }
        }
    },
    "2131": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2131_1681446128.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2131",
                "name": "Glorious Badger Finn #2131",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446120,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2131_1681446128.png",
                "rarity_score": 134.52939048445182,
                "rarity_level": "RARE"
            }
        }
    },
    "2129": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2129_1681446128.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2129",
                "name": "Nutty Badger Lumos #2129",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446120,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2129_1681446128.png",
                "rarity_score": 119.54752617743051,
                "rarity_level": "RARE"
            }
        }
    },
    "2138": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2138_1681446129.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2138",
                "name": "Wen Badger Alastor #2138",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446122,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2138_1681446129.png",
                "rarity_score": 113.31682257245997,
                "rarity_level": "RARE"
            }
        }
    },
    "2140": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2140_1681446129.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2140",
                "name": "Clumsy Badger Walden #2140",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Helmet",
                        "identifier": "ecko_helmet",
                        "trait_type": "Headwear",
                        "score": 520.0
                    }
                ],
                "create-date": 1681446122,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2140_1681446129.png",
                "rarity_score": 587.3861245823224,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2141": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2141_1681446129.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2141",
                "name": "Dark Badger Crouch #2141",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446122,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2141_1681446129.png",
                "rarity_score": 167.64219579817114,
                "rarity_level": "RARE"
            }
        }
    },
    "2181": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2181_1681446135.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2181",
                "name": "Bright Badger Dragon #2181",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446130,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2181_1681446135.png",
                "rarity_score": 58.977450357912524,
                "rarity_level": "COMMON"
            }
        }
    },
    "2180": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2180_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2180",
                "name": "Enchanting Badger Pacquiao #2180",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446129,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2180_1681446137.png",
                "rarity_score": 221.81126975894932,
                "rarity_level": "RARE"
            }
        }
    },
    "2144": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2144_1681446129.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2144",
                "name": "Puzzled Badger Sebastian #2144",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446123,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2144_1681446129.png",
                "rarity_score": 64.7217028274234,
                "rarity_level": "COMMON"
            }
        }
    },
    "2137": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2137_1681446129.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2137",
                "name": "Bot Badger Tiberius #2137",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446121,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2137_1681446129.png",
                "rarity_score": 76.95477477428042,
                "rarity_level": "COMMON"
            }
        }
    },
    "2182": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2182_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2182",
                "name": "Shill Badger Rudy #2182",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446130,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2182_1681446136.png",
                "rarity_score": 67.42937382548708,
                "rarity_level": "COMMON"
            }
        }
    },
    "2183": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2183_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2183",
                "name": "Courageous Badger Evander #2183",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446130,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2183_1681446137.png",
                "rarity_score": 80.86179039697898,
                "rarity_level": "COMMON"
            }
        }
    },
    "2143": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2143_1681446129.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2143",
                "name": "Busy Badger Hermes #2143",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446123,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2143_1681446129.png",
                "rarity_score": 284.31131032906706,
                "rarity_level": "EPIC"
            }
        }
    },
    "2184": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2184_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2184",
                "name": "Dildo Badger Fluffy #2184",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446130,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2184_1681446136.png",
                "rarity_score": 78.95449261235808,
                "rarity_level": "COMMON"
            }
        }
    },
    "2216": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2216_1681446145.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2216",
                "name": "Scary Badger Sebastian #2216",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446136,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2216_1681446145.png",
                "rarity_score": 114.68893111105157,
                "rarity_level": "RARE"
            }
        }
    },
    "2274": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2274_1681446156.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2274",
                "name": "Foolish Badger Muhammad #2274",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2274_1681446156.png",
                "rarity_score": 61.49541340249531,
                "rarity_level": "COMMON"
            }
        }
    },
    "2158": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2158_1681446130.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2158",
                "name": "Clean Badger Floyd #2158",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2158_1681446130.png",
                "rarity_score": 55.33702176025225,
                "rarity_level": "COMMON"
            }
        }
    },
    "2185": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2185_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2185",
                "name": "Bewildered Badger Arlo #2185",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446131,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2185_1681446137.png",
                "rarity_score": 122.60848206569906,
                "rarity_level": "RARE"
            }
        }
    },
    "2231": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2231_1681446145.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2231",
                "name": "Ludicrous Badger Floyd #2231",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2231_1681446145.png",
                "rarity_score": 103.0217666595153,
                "rarity_level": "RARE"
            }
        }
    },
    "2270": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2270_1681446153.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2270",
                "name": "Lonely Badger Miguel #2270",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446146,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2270_1681446153.png",
                "rarity_score": 103.52243006265184,
                "rarity_level": "RARE"
            }
        }
    },
    "2146": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2146_1681446130.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2146",
                "name": "Eager Badger Leopold #2146",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2146_1681446130.png",
                "rarity_score": 99.79688699689027,
                "rarity_level": "RARE"
            }
        }
    },
    "2186": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2186_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2186",
                "name": "Repulsive Badger Fireball #2186",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446131,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2186_1681446136.png",
                "rarity_score": 85.61054481223205,
                "rarity_level": "COMMON"
            }
        }
    },
    "2223": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2223_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2223",
                "name": "Energetic Badger Shields #2223",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2223_1681446143.png",
                "rarity_score": 71.46535629114209,
                "rarity_level": "COMMON"
            }
        }
    },
    "2254": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2254_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2254",
                "name": "Fragile Badger Tibbles #2254",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446144,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2254_1681446151.png",
                "rarity_score": 243.6771206920238,
                "rarity_level": "EPIC"
            }
        }
    },
    "2148": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2148_1681446130.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2148",
                "name": "Asthmatic Badger Miguel #2148",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2148_1681446130.png",
                "rarity_score": 107.03323754497444,
                "rarity_level": "RARE"
            }
        }
    },
    "2187": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2187_1681446140.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2187",
                "name": "Bewildered Badger Vernon #2187",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446131,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2187_1681446140.png",
                "rarity_score": 119.12561512058639,
                "rarity_level": "RARE"
            }
        }
    },
    "2246": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2246_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2246",
                "name": "Shy Badger Gilderoy #2246",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2246_1681446150.png",
                "rarity_score": 375.2090361608365,
                "rarity_level": "EPIC"
            }
        }
    },
    "2290": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2290_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2290",
                "name": "Grumpy Badger Tyrion #2290",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446151,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2290_1681446159.png",
                "rarity_score": 465.0200858526398,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2156": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2156_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2156",
                "name": "Lazy Badger Lewis #2156",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446125,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2156_1681446131.png",
                "rarity_score": 67.6984412196258,
                "rarity_level": "COMMON"
            }
        }
    },
    "2188": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2188_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2188",
                "name": "Wandering Badger Lennox #2188",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446131,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2188_1681446136.png",
                "rarity_score": 70.73798807755014,
                "rarity_level": "COMMON"
            }
        }
    },
    "2217": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2217_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2217",
                "name": "Clever Badger Shields #2217",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446137,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2217_1681446143.png",
                "rarity_score": 63.2794661844454,
                "rarity_level": "COMMON"
            }
        }
    },
    "2258": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2258_1681446149.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2258",
                "name": "Unsightly Badger Ethan #2258",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446144,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2258_1681446149.png",
                "rarity_score": 54.93618547632749,
                "rarity_level": "COMMON"
            }
        }
    },
    "2150": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2150_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2150",
                "name": "Bright Badger Huck #2150",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2150_1681446131.png",
                "rarity_score": 122.99145621414578,
                "rarity_level": "RARE"
            }
        }
    },
    "2189": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2189_1681446138.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2189",
                "name": "Shill Badger Manny #2189",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446132,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2189_1681446138.png",
                "rarity_score": 122.30375754725362,
                "rarity_level": "RARE"
            }
        }
    },
    "2236": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2236_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2236",
                "name": "Clumsy Badger Argus #2236",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2236_1681446146.png",
                "rarity_score": 68.6543667618475,
                "rarity_level": "COMMON"
            }
        }
    },
    "2277": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2277_1681446157.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2277",
                "name": "Charming Badger Scorpius #2277",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2277_1681446157.png",
                "rarity_score": 83.97357567914841,
                "rarity_level": "COMMON"
            }
        }
    },
    "2154": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2154_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2154",
                "name": "Misty Badger Jones #2154",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2154_1681446131.png",
                "rarity_score": 109.66811694373204,
                "rarity_level": "RARE"
            }
        }
    },
    "2190": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2190_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2190",
                "name": "FUD Badger Asher #2190",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446132,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2190_1681446136.png",
                "rarity_score": 55.030301928047315,
                "rarity_level": "COMMON"
            }
        }
    },
    "2221": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2221_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2221",
                "name": "Awful Badger Dursley #2221",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446137,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2221_1681446146.png",
                "rarity_score": 119.59841305554431,
                "rarity_level": "RARE"
            }
        }
    },
    "2279": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2279_1681446155.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2279",
                "name": "Rich Badger Crookshanks #2279",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2279_1681446155.png",
                "rarity_score": 103.29276232746153,
                "rarity_level": "RARE"
            }
        }
    },
    "2167": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2167_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2167",
                "name": "Scary Badger Greyback #2167",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446127,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2167_1681446131.png",
                "rarity_score": 56.14065792957674,
                "rarity_level": "COMMON"
            }
        }
    },
    "2191": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2191_1681446140.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2191",
                "name": "Aggressive Badger Griphook #2191",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446132,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2191_1681446140.png",
                "rarity_score": 85.22800744961894,
                "rarity_level": "COMMON"
            }
        }
    },
    "2242": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2242_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2242",
                "name": "Filthy Badger Buckbeak #2242",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2242_1681446146.png",
                "rarity_score": 57.610343553636106,
                "rarity_level": "COMMON"
            }
        }
    },
    "2272": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2272_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2272",
                "name": "Depressed Badger Finn #2272",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446146,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2272_1681446151.png",
                "rarity_score": 74.2521687462864,
                "rarity_level": "COMMON"
            }
        }
    },
    "2153": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2153_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2153",
                "name": "Dull Badger Abernathy #2153",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2153_1681446131.png",
                "rarity_score": 114.23493097109305,
                "rarity_level": "RARE"
            }
        }
    },
    "2192": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2192_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2192",
                "name": "Bored Badger Holm #2192",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446132,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2192_1681446137.png",
                "rarity_score": 71.29445651001956,
                "rarity_level": "COMMON"
            }
        }
    },
    "2228": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2228_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2228",
                "name": "Horrible Badger Grimmauld #2228",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2228_1681446144.png",
                "rarity_score": 66.1113283399021,
                "rarity_level": "COMMON"
            }
        }
    },
    "2261": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2261_1681446152.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2261",
                "name": "Dead Badger Santiago #2261",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2261_1681446152.png",
                "rarity_score": 62.941569640008666,
                "rarity_level": "COMMON"
            }
        }
    },
    "2145": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2145_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2145",
                "name": "Crazy Badger Diggle #2145",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446123,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2145_1681446131.png",
                "rarity_score": 87.06272145150669,
                "rarity_level": "COMMON"
            }
        }
    },
    "2193": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2193_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2193",
                "name": "Stupid Badger Quentin #2193",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446132,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2193_1681446137.png",
                "rarity_score": 62.010074270366694,
                "rarity_level": "COMMON"
            }
        }
    },
    "2225": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2225_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2225",
                "name": "Misty Badger Leopold #2225",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2225_1681446146.png",
                "rarity_score": 356.0466370439547,
                "rarity_level": "EPIC"
            }
        }
    },
    "2273": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2273_1681446152.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2273",
                "name": "Enchanting Badger Yaxley #2273",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446146,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2273_1681446152.png",
                "rarity_score": 68.74904209025094,
                "rarity_level": "COMMON"
            }
        }
    },
    "2169": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2169_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2169",
                "name": "Condemned Badger Larkin #2169",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446127,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2169_1681446131.png",
                "rarity_score": 56.544522600611984,
                "rarity_level": "COMMON"
            }
        }
    },
    "2194": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2194_1681446139.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2194",
                "name": "Poised Badger Peter #2194",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2194_1681446139.png",
                "rarity_score": 84.07468706566716,
                "rarity_level": "COMMON"
            }
        }
    },
    "2240": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2240_1681446147.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2240",
                "name": "Jittery Badger Trevor #2240",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446140,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2240_1681446147.png",
                "rarity_score": 156.10239154008326,
                "rarity_level": "RARE"
            }
        }
    },
    "2283": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2283_1681446157.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2283",
                "name": "Glorious Badger Greyback #2283",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2283_1681446157.png",
                "rarity_score": 76.84868741461769,
                "rarity_level": "COMMON"
            }
        }
    },
    "2161": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2161_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2161",
                "name": "Clever Badger Fudge #2161",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2161_1681446131.png",
                "rarity_score": 64.59611743146634,
                "rarity_level": "COMMON"
            }
        }
    },
    "2195": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2195_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2195",
                "name": "Panda Badger Havelock #2195",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2195_1681446137.png",
                "rarity_score": 54.265473711277586,
                "rarity_level": "COMMON"
            }
        }
    },
    "2227": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2227_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2227",
                "name": "Dolphin Badger Dursley #2227",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2227_1681446146.png",
                "rarity_score": 67.2806624182884,
                "rarity_level": "COMMON"
            }
        }
    },
    "2276": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2276_1681446157.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2276",
                "name": "Shill Badger Peter #2276",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2276_1681446157.png",
                "rarity_score": 88.9687822332231,
                "rarity_level": "RARE"
            }
        }
    },
    "2160": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2160_1681446131.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2160",
                "name": "Fancy Badger Yaxley #2160",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2160_1681446131.png",
                "rarity_score": 104.6854590043045,
                "rarity_level": "RARE"
            }
        }
    },
    "2196": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2196_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2196",
                "name": "Lambo Badger Sirius Black #2196",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2196_1681446137.png",
                "rarity_score": 72.681312273842,
                "rarity_level": "COMMON"
            }
        }
    },
    "2232": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2232_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2232",
                "name": "Excited Badger James #2232",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2232_1681446144.png",
                "rarity_score": 63.129983435467516,
                "rarity_level": "COMMON"
            }
        }
    },
    "2263": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2263_1681446157.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2263",
                "name": "Good Badger Quentin #2263",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Rabbit Ears",
                        "identifier": "rabbit_ears",
                        "trait_type": "Headwear",
                        "score": 288.8888888888889
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2263_1681446157.png",
                "rarity_score": 424.76672393258,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2162": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2162_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2162",
                "name": "Beautiful Badger Kenrick #2162",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2162_1681446132.png",
                "rarity_score": 73.86550048543035,
                "rarity_level": "COMMON"
            }
        }
    },
    "2197": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2197_1681446140.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2197",
                "name": "Busy Badger Scabbers #2197",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2197_1681446140.png",
                "rarity_score": 68.55726546727678,
                "rarity_level": "COMMON"
            }
        }
    },
    "2241": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2241_1681446145.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2241",
                "name": "Dull Badger Clay #2241",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446140,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2241_1681446145.png",
                "rarity_score": 61.25945757761545,
                "rarity_level": "COMMON"
            }
        }
    },
    "2267": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2267_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2267",
                "name": "Broken Badger Flitwick #2267",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2267_1681446151.png",
                "rarity_score": 97.93762539435573,
                "rarity_level": "RARE"
            }
        }
    },
    "2164": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2164_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2164",
                "name": "Tired Badger Riddle #2164",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2164_1681446132.png",
                "rarity_score": 97.84083054606486,
                "rarity_level": "RARE"
            }
        }
    },
    "2198": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2198_1681446140.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2198",
                "name": "Lovely Badger Julio #2198",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2198_1681446140.png",
                "rarity_score": 93.60601406147806,
                "rarity_level": "RARE"
            }
        }
    },
    "2244": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2244_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2244",
                "name": "Witty Badger Atticus #2244",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2244_1681446150.png",
                "rarity_score": 83.09944470642968,
                "rarity_level": "COMMON"
            }
        }
    },
    "2291": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2291_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2291",
                "name": "Healthy Badger Jones #2291",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446151,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2291_1681446159.png",
                "rarity_score": 323.65153839989034,
                "rarity_level": "EPIC"
            }
        }
    },
    "2151": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2151_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2151",
                "name": "Good Badger Scorpius #2151",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2151_1681446132.png",
                "rarity_score": 115.40614439665802,
                "rarity_level": "RARE"
            }
        }
    },
    "2199": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2199_1681446139.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2199",
                "name": "Hilarious Badger Finnigan #2199",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2199_1681446139.png",
                "rarity_score": 58.09234451154648,
                "rarity_level": "COMMON"
            }
        }
    },
    "2238": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2238_1681446145.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2238",
                "name": "Excited Badger Elfie #2238",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2238_1681446145.png",
                "rarity_score": 73.23493914880129,
                "rarity_level": "COMMON"
            }
        }
    },
    "2271": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2271_1681446156.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2271",
                "name": "Disturbed Badger Kenrick #2271",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446146,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2271_1681446156.png",
                "rarity_score": 297.9570233693497,
                "rarity_level": "EPIC"
            }
        }
    },
    "2157": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2157_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2157",
                "name": "Average Badger Werner #2157",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446125,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2157_1681446132.png",
                "rarity_score": 152.65768490797996,
                "rarity_level": "RARE"
            }
        }
    },
    "2200": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2200_1681446139.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2200",
                "name": "Modern Badger Roy #2200",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2200_1681446139.png",
                "rarity_score": 65.35957941788575,
                "rarity_level": "COMMON"
            }
        }
    },
    "2237": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2237_1681446145.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2237",
                "name": "Perfect Badger Diggle #2237",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2237_1681446145.png",
                "rarity_score": 411.43096420635857,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2268": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2268_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2268",
                "name": "Clumsy Badger Sugar #2268",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2268_1681446151.png",
                "rarity_score": 308.3408125055722,
                "rarity_level": "EPIC"
            }
        }
    },
    "2149": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2149_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2149",
                "name": "Prickly Badger Fang #2149",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2149_1681446132.png",
                "rarity_score": 125.8236252917246,
                "rarity_level": "RARE"
            }
        }
    },
    "2201": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2201_1681446138.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2201",
                "name": "Embarrassed Badger Cullen #2201",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2201_1681446138.png",
                "rarity_score": 61.03347332910143,
                "rarity_level": "COMMON"
            }
        }
    },
    "2230": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2230_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2230",
                "name": "Cruel Badger Cian #2230",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2230_1681446144.png",
                "rarity_score": 94.65586659848,
                "rarity_level": "RARE"
            }
        }
    },
    "2262": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2262_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2262",
                "name": "Ludicrous Badger Vasyl #2262",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2262_1681446150.png",
                "rarity_score": 73.34121961694619,
                "rarity_level": "COMMON"
            }
        }
    },
    "2152": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2152_1681446132.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2152",
                "name": "Precious Badger Leonardo #2152",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2152_1681446132.png",
                "rarity_score": 185.33226630860378,
                "rarity_level": "RARE"
            }
        }
    },
    "2203": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2203_1681446138.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2203",
                "name": "Precious Badger Corin #2203",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2203_1681446138.png",
                "rarity_score": 51.18734610533715,
                "rarity_level": "COMMON"
            }
        }
    },
    "2234": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2234_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2234",
                "name": "Aggressive Badger Hippogriff #2234",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2234_1681446144.png",
                "rarity_score": 64.53006912933098,
                "rarity_level": "COMMON"
            }
        }
    },
    "2265": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2265_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2265",
                "name": "Amused Badger Sphynx #2265",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2265_1681446151.png",
                "rarity_score": 224.2628561854498,
                "rarity_level": "EPIC"
            }
        }
    },
    "2155": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2155_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2155",
                "name": "Creepy Badger Killian #2155",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446125,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2155_1681446133.png",
                "rarity_score": 87.2568312333437,
                "rarity_level": "COMMON"
            }
        }
    },
    "2205": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2205_1681446141.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2205",
                "name": "Creepy Badger Percival #2205",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2205_1681446141.png",
                "rarity_score": 146.10995849651377,
                "rarity_level": "RARE"
            }
        }
    },
    "2248": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2248_1681446148.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2248",
                "name": "Happy Badger Tibbles #2248",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2248_1681446148.png",
                "rarity_score": 62.53126373924352,
                "rarity_level": "COMMON"
            }
        }
    },
    "2285": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2285_1681446155.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2285",
                "name": "Easy Badger Edward #2285",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446148,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2285_1681446155.png",
                "rarity_score": 95.90724194306317,
                "rarity_level": "RARE"
            }
        }
    },
    "2170": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2170_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2170",
                "name": "Broken Badger Darcy #2170",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446127,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2170_1681446133.png",
                "rarity_score": 71.64523572080289,
                "rarity_level": "COMMON"
            }
        }
    },
    "2204": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2204_1681446141.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2204",
                "name": "Daft Badger Logan #2204",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2204_1681446141.png",
                "rarity_score": 150.38072368659698,
                "rarity_level": "RARE"
            }
        }
    },
    "2247": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2247_1681446149.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2247",
                "name": "Ashamed Badger Alvarez #2247",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2247_1681446149.png",
                "rarity_score": 75.19654378742753,
                "rarity_level": "COMMON"
            }
        }
    },
    "2287": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2287_1681446157.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2287",
                "name": "Cautious Badger Firenze #2287",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446149,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2287_1681446157.png",
                "rarity_score": 71.43301694635191,
                "rarity_level": "COMMON"
            }
        }
    },
    "2171": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2171_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2171",
                "name": "Lively Badger Logan #2171",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446127,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2171_1681446133.png",
                "rarity_score": 71.82769192248875,
                "rarity_level": "COMMON"
            }
        }
    },
    "2206": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2206_1681446139.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2206",
                "name": "Misty Badger Layne #2206",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2206_1681446139.png",
                "rarity_score": 59.78082428199867,
                "rarity_level": "COMMON"
            }
        }
    },
    "2235": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2235_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2235",
                "name": "Clammy Badger Percival #2235",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2235_1681446146.png",
                "rarity_score": 151.29668070996934,
                "rarity_level": "RARE"
            }
        }
    },
    "2275": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2275_1681446152.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2275",
                "name": "Nice Badger Newt #2275",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2275_1681446152.png",
                "rarity_score": 95.57933342175896,
                "rarity_level": "RARE"
            }
        }
    },
    "2147": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2147_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2147",
                "name": "Poor Badger Troy #2147",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446124,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2147_1681446133.png",
                "rarity_score": 179.60442449993585,
                "rarity_level": "RARE"
            }
        }
    },
    "2209": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2209_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2209",
                "name": "Muddy Badger Pigwidgeon #2209",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2209_1681446144.png",
                "rarity_score": 132.8031791677143,
                "rarity_level": "RARE"
            }
        }
    },
    "2264": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2264_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2264",
                "name": "Elegant Badger Sirius Black #2264",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2264_1681446151.png",
                "rarity_score": 58.296558590676234,
                "rarity_level": "COMMON"
            }
        }
    },
    "2298": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2298_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2298",
                "name": "Scary Badger Ron #2298",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2298_1681446160.png",
                "rarity_score": 215.12807920631099,
                "rarity_level": "RARE"
            }
        }
    },
    "2176": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2176_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2176",
                "name": "Clever Badger Pacquiao #2176",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446129,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2176_1681446133.png",
                "rarity_score": 59.95272636611541,
                "rarity_level": "COMMON"
            }
        }
    },
    "2207": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2207_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2207",
                "name": "Nasty Badger Malfoy #2207",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2207_1681446143.png",
                "rarity_score": 159.66799287142567,
                "rarity_level": "RARE"
            }
        }
    },
    "2257": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2257_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2257",
                "name": "Disgusted Badger Fireball #2257",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446144,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2257_1681446150.png",
                "rarity_score": 60.305322221669954,
                "rarity_level": "COMMON"
            }
        }
    },
    "2288": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2288_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2288",
                "name": "Shill Badger Puff #2288",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446150,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2288_1681446159.png",
                "rarity_score": 118.67715704376762,
                "rarity_level": "RARE"
            }
        }
    },
    "2165": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2165_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2165",
                "name": "Tired Badger Blaise #2165",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2165_1681446133.png",
                "rarity_score": 88.60197013483179,
                "rarity_level": "COMMON"
            }
        }
    },
    "2208": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2208_1681446140.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2208",
                "name": "Gifted Badger Langston #2208",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2208_1681446140.png",
                "rarity_score": 269.34957628053274,
                "rarity_level": "EPIC"
            }
        }
    },
    "2243": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2243_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2243",
                "name": "Helpful Badger Holden #2243",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2243_1681446146.png",
                "rarity_score": 74.00352927433194,
                "rarity_level": "COMMON"
            }
        }
    },
    "2280": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2280_1681446156.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2280",
                "name": "Worried Badger Sir Nicholaus #2280",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2280_1681446156.png",
                "rarity_score": 130.77747443745423,
                "rarity_level": "RARE"
            }
        }
    },
    "2172": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2172_1681446133.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2172",
                "name": "Talented Badger Draco #2172",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446128,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2172_1681446133.png",
                "rarity_score": 66.65524411866107,
                "rarity_level": "COMMON"
            }
        }
    },
    "2210": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2210_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2210",
                "name": "Modern Badger Brawley #2210",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446134,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2210_1681446143.png",
                "rarity_score": 142.8831574182696,
                "rarity_level": "RARE"
            }
        }
    },
    "2255": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2255_1681446153.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2255",
                "name": "Determined Badger Larkin #2255",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Kadena",
                        "identifier": "mean_face_kadena",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446144,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2255_1681446153.png",
                "rarity_score": 430.9373167998694,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2315": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2315_1681446163.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2315",
                "name": "Impressive Badger Khan #2315",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446154,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2315_1681446163.png",
                "rarity_score": 109.99799010408731,
                "rarity_level": "RARE"
            }
        }
    },
    "2163": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2163_1681446134.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2163",
                "name": "Clammy Badger Scabbers #2163",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2163_1681446134.png",
                "rarity_score": 121.61736666435084,
                "rarity_level": "RARE"
            }
        }
    },
    "2211": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2211_1681446139.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2211",
                "name": "Filthy Badger Newt #2211",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446135,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2211_1681446139.png",
                "rarity_score": 54.85919442330616,
                "rarity_level": "COMMON"
            }
        }
    },
    "2239": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2239_1681446147.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2239",
                "name": "Lovely Badger Logan #2239",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446140,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2239_1681446147.png",
                "rarity_score": 260.1579660716266,
                "rarity_level": "EPIC"
            }
        }
    },
    "2281": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2281_1681446152.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2281",
                "name": "Kind Badger Riddick #2281",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2281_1681446152.png",
                "rarity_score": 53.87846540760513,
                "rarity_level": "COMMON"
            }
        }
    },
    "2159": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2159_1681446135.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2159",
                "name": "Anal Badger Stone #2159",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446126,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2159_1681446135.png",
                "rarity_score": 95.21909372420181,
                "rarity_level": "RARE"
            }
        }
    },
    "2213": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2213_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2213",
                "name": "Unusual Badger Wladimir #2213",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446136,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2213_1681446143.png",
                "rarity_score": 118.83282839209257,
                "rarity_level": "RARE"
            }
        }
    },
    "2256": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2256_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2256",
                "name": "Dark Badger Christopher #2256",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446144,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2256_1681446150.png",
                "rarity_score": 97.90498546960737,
                "rarity_level": "RARE"
            }
        }
    },
    "2292": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2292_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2292",
                "name": "Shy Badger Sphynx #2292",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446151,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2292_1681446160.png",
                "rarity_score": 70.53866296233973,
                "rarity_level": "COMMON"
            }
        }
    },
    "2174": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2174_1681446135.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2174",
                "name": "Ugly Badger Creevy #2174",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446128,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2174_1681446135.png",
                "rarity_score": 76.60084046762448,
                "rarity_level": "COMMON"
            }
        }
    },
    "2212": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2212_1681446141.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2212",
                "name": "Broken Badger Severus #2212",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446136,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2212_1681446141.png",
                "rarity_score": 76.03428535094109,
                "rarity_level": "COMMON"
            }
        }
    },
    "2249": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2249_1681446147.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2249",
                "name": "Tense Badger Buckbeak #2249",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446142,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2249_1681446147.png",
                "rarity_score": 109.48972369115057,
                "rarity_level": "RARE"
            }
        }
    },
    "2284": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2284_1681446152.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2284",
                "name": "Grumpy Badger Sugar Ray #2284",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446148,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2284_1681446152.png",
                "rarity_score": 55.268149871810294,
                "rarity_level": "COMMON"
            }
        }
    },
    "2166": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2166_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2166",
                "name": "Envious Badger Lily #2166",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446127,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2166_1681446136.png",
                "rarity_score": 163.7795941122314,
                "rarity_level": "RARE"
            }
        }
    },
    "2215": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2215_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2215",
                "name": "Wandering Badger Troy #2215",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446136,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2215_1681446144.png",
                "rarity_score": 87.0833223970189,
                "rarity_level": "COMMON"
            }
        }
    },
    "2266": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2266_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2266",
                "name": "Muddy Badger Nox #2266",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2266_1681446151.png",
                "rarity_score": 58.09082818153651,
                "rarity_level": "COMMON"
            }
        }
    },
    "2297": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2297_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2297",
                "name": "Lucky Badger Laila #2297",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2297_1681446159.png",
                "rarity_score": 61.71099182261564,
                "rarity_level": "COMMON"
            }
        }
    },
    "2175": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2175_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2175",
                "name": "Prickly Badger Centaur #2175",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446128,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2175_1681446136.png",
                "rarity_score": 241.3287102226682,
                "rarity_level": "EPIC"
            }
        }
    },
    "2218": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2218_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2218",
                "name": "Elated Badger Quentin #2218",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446137,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2218_1681446143.png",
                "rarity_score": 59.56434914101466,
                "rarity_level": "COMMON"
            }
        }
    },
    "2252": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2252_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2252",
                "name": "Fierce Badger Richard #2252",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446143,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2252_1681446150.png",
                "rarity_score": 62.11434723852748,
                "rarity_level": "COMMON"
            }
        }
    },
    "2293": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2293_1681446158.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2293",
                "name": "HODL Badger Riddick #2293",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446151,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2293_1681446158.png",
                "rarity_score": 59.18130627254896,
                "rarity_level": "COMMON"
            }
        }
    },
    "2177": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2177_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2177",
                "name": "Curious Badger Lawrence #2177",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446129,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2177_1681446136.png",
                "rarity_score": 108.90639148308668,
                "rarity_level": "RARE"
            }
        }
    },
    "2219": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2219_1681446145.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2219",
                "name": "Happy Badger Tyrion #2219",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446137,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2219_1681446145.png",
                "rarity_score": 240.25502999407598,
                "rarity_level": "EPIC"
            }
        }
    },
    "2269": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2269_1681446153.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2269",
                "name": "Annoying Badger George #2269",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446146,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2269_1681446153.png",
                "rarity_score": 134.03069138692027,
                "rarity_level": "RARE"
            }
        }
    },
    "2312": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2312_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2312",
                "name": "Depressed Badger Darcy #2312",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446154,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2312_1681446161.png",
                "rarity_score": 246.86546358550126,
                "rarity_level": "EPIC"
            }
        }
    },
    "2178": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2178_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2178",
                "name": "Determined Badger Lachlan #2178",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446129,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2178_1681446136.png",
                "rarity_score": 100.99491845288588,
                "rarity_level": "RARE"
            }
        }
    },
    "2220": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2220_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2220",
                "name": "Creepy Badger Paws #2220",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446137,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2220_1681446143.png",
                "rarity_score": 68.45811325292244,
                "rarity_level": "COMMON"
            }
        }
    },
    "2253": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2253_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2253",
                "name": "Funny Badger Georgie #2253",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446143,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2253_1681446151.png",
                "rarity_score": 67.4977858773484,
                "rarity_level": "COMMON"
            }
        }
    },
    "2305": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2305_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2305",
                "name": "Byzantine Badger Heathcliff #2305",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2305_1681446160.png",
                "rarity_score": 77.11991980517804,
                "rarity_level": "COMMON"
            }
        }
    },
    "2168": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2168_1681446136.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2168",
                "name": "Glorious Badger Fabian #2168",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446127,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2168_1681446136.png",
                "rarity_score": 130.48065773024166,
                "rarity_level": "RARE"
            }
        }
    },
    "2222": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2222_1681446142.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2222",
                "name": "Disgusted Badger Cornelius #2222",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446137,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2222_1681446142.png",
                "rarity_score": 75.56745132631117,
                "rarity_level": "COMMON"
            }
        }
    },
    "2251": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2251_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2251",
                "name": "Sleepy Badger Klitschko #2251",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446142,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2251_1681446150.png",
                "rarity_score": 138.54308370348585,
                "rarity_level": "RARE"
            }
        }
    },
    "2296": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2296_1681446158.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2296",
                "name": "Elegant Badger Jasper #2296",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2296_1681446158.png",
                "rarity_score": 56.700539588545325,
                "rarity_level": "COMMON"
            }
        }
    },
    "2179": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2179_1681446137.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2179",
                "name": "Quaint Badger Beckham #2179",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446129,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2179_1681446137.png",
                "rarity_score": 112.66432843805154,
                "rarity_level": "RARE"
            }
        }
    },
    "2229": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2229_1681446147.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2229",
                "name": "Lavish Badger Dursley #2229",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2229_1681446147.png",
                "rarity_score": 83.24342090923678,
                "rarity_level": "COMMON"
            }
        }
    },
    "2282": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2282_1681446153.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2282",
                "name": "Testy Badger Tszyu #2282",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2282_1681446153.png",
                "rarity_score": 117.33438291521719,
                "rarity_level": "RARE"
            }
        }
    },
    "2313": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2313_1681446164.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2313",
                "name": "Daft Badger Lumos #2313",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446154,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2313_1681446164.png",
                "rarity_score": 150.1105131458827,
                "rarity_level": "RARE"
            }
        }
    },
    "2202": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2202_1681446140.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2202",
                "name": "Faithful Badger Pacquiao #2202",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446133,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2202_1681446140.png",
                "rarity_score": 112.80107640555728,
                "rarity_level": "RARE"
            }
        }
    },
    "2245": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2245_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2245",
                "name": "Comfy Badger Jarvis #2245",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446141,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2245_1681446150.png",
                "rarity_score": 108.6259027623035,
                "rarity_level": "RARE"
            }
        }
    },
    "2289": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2289_1681446158.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2289",
                "name": "Anal Badger Havelock #2289",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446151,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2289_1681446158.png",
                "rarity_score": 112.62419891482894,
                "rarity_level": "RARE"
            }
        }
    },
    "2330": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2330_1681446165.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2330",
                "name": "Fancy Badger Clay #2330",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446159,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2330_1681446165.png",
                "rarity_score": 73.4767652654346,
                "rarity_level": "COMMON"
            }
        }
    },
    "2214": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2214_1681446141.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2214",
                "name": "Creepy Badger David #2214",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446136,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2214_1681446141.png",
                "rarity_score": 66.57406361754188,
                "rarity_level": "COMMON"
            }
        }
    },
    "2250": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2250_1681446151.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2250",
                "name": "Unsightly Badger Marciano #2250",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446142,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2250_1681446151.png",
                "rarity_score": 230.25161647639493,
                "rarity_level": "EPIC"
            }
        }
    },
    "2306": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2306_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2306",
                "name": "Bright Badger Antonio #2306",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2306_1681446161.png",
                "rarity_score": 67.90142609443589,
                "rarity_level": "COMMON"
            }
        }
    },
    "2353": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2353_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2353",
                "name": "Silly Badger Fitzwilliam #2353",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2353_1681446169.png",
                "rarity_score": 98.75741425474945,
                "rarity_level": "RARE"
            }
        }
    },
    "2224": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2224_1681446143.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2224",
                "name": "Rekt Badger Sirius Black #2224",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2224_1681446143.png",
                "rarity_score": 84.5308090854104,
                "rarity_level": "COMMON"
            }
        }
    },
    "2259": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2259_1681446153.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2259",
                "name": "Glamorous Badger Jarvis #2259",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446144,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2259_1681446153.png",
                "rarity_score": 89.57024580465189,
                "rarity_level": "RARE"
            }
        }
    },
    "2316": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2316_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2316",
                "name": "Obnoxious Badger Lily #2316",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446154,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2316_1681446160.png",
                "rarity_score": 111.5093072180135,
                "rarity_level": "RARE"
            }
        }
    },
    "2344": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2344_1681446167.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2344",
                "name": "Proud Badger Diggle #2344",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446162,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2344_1681446167.png",
                "rarity_score": 75.33117806649534,
                "rarity_level": "COMMON"
            }
        }
    },
    "2226": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2226_1681446144.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2226",
                "name": "Comfy Badger Noah #2226",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446138,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2226_1681446144.png",
                "rarity_score": 111.45958666578609,
                "rarity_level": "RARE"
            }
        }
    },
    "2260": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2260_1681446150.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2260",
                "name": "Brainy Badger Bingley #2260",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446145,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2260_1681446150.png",
                "rarity_score": 72.13318766967276,
                "rarity_level": "COMMON"
            }
        }
    },
    "2294": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2294_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2294",
                "name": "Dull Badger Gulliver #2294",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446151,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2294_1681446161.png",
                "rarity_score": 139.8563034698912,
                "rarity_level": "RARE"
            }
        }
    },
    "2348": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2348_1681446170.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2348",
                "name": "Funny Badger Orlando #2348",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446162,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2348_1681446170.png",
                "rarity_score": 230.33758437801754,
                "rarity_level": "EPIC"
            }
        }
    },
    "2233": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2233_1681446146.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2233",
                "name": "Proud Badger Mandrake #2233",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446139,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2233_1681446146.png",
                "rarity_score": 73.26217094968426,
                "rarity_level": "COMMON"
            }
        }
    },
    "2278": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2278_1681446152.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2278",
                "name": "Real Badger Shields #2278",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446147,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2278_1681446152.png",
                "rarity_score": 54.418289727643256,
                "rarity_level": "COMMON"
            }
        }
    },
    "2303": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2303_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2303",
                "name": "Pleasant Badger Klitschko #2303",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2303_1681446160.png",
                "rarity_score": 80.94034879407683,
                "rarity_level": "COMMON"
            }
        }
    },
    "2339": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2339_1681446167.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2339",
                "name": "FUD Badger Antonio #2339",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446161,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2339_1681446167.png",
                "rarity_score": 242.48088327613,
                "rarity_level": "EPIC"
            }
        }
    },
    "2286": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2286_1681446158.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2286",
                "name": "Energetic Badger Dragon #2286",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446149,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2286_1681446158.png",
                "rarity_score": 76.14625048032717,
                "rarity_level": "COMMON"
            }
        }
    },
    "2327": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2327_1681446164.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2327",
                "name": "Nice Badger Lomachenko #2327",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446158,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2327_1681446164.png",
                "rarity_score": 73.81674288505779,
                "rarity_level": "COMMON"
            }
        }
    },
    "2366": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2366_1681446172.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2366",
                "name": "Attractive Badger Cecil #2366",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2366_1681446172.png",
                "rarity_score": 126.70541657336173,
                "rarity_level": "RARE"
            }
        }
    },
    "2409": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2409_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2409",
                "name": "Handsome Badger Lucius #2409",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446173,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2409_1681446180.png",
                "rarity_score": 67.31502370992813,
                "rarity_level": "COMMON"
            }
        }
    },
    "2307": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2307_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2307",
                "name": "Expensive Badger Bronn #2307",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2307_1681446159.png",
                "rarity_score": 70.64832392568721,
                "rarity_level": "COMMON"
            }
        }
    },
    "2331": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2331_1681446165.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2331",
                "name": "Annoyed Badger Croix #2331",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446159,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2331_1681446165.png",
                "rarity_score": 64.12269676563108,
                "rarity_level": "COMMON"
            }
        }
    },
    "2372": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2372_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2372",
                "name": "Wild Badger Macaulay #2372",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446166,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2372_1681446174.png",
                "rarity_score": 86.19740849365516,
                "rarity_level": "COMMON"
            }
        }
    },
    "2420": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2420_1681446182.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2420",
                "name": "Healthy Badger Maximus #2420",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2420_1681446182.png",
                "rarity_score": 108.02586726737604,
                "rarity_level": "RARE"
            }
        }
    },
    "2301": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2301_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2301",
                "name": "Scary Badger Kom #2301",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2301_1681446159.png",
                "rarity_score": 56.82855901185514,
                "rarity_level": "COMMON"
            }
        }
    },
    "2332": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2332_1681446165.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2332",
                "name": "Tame Badger Scimgeour #2332",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446160,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2332_1681446165.png",
                "rarity_score": 73.81045091820096,
                "rarity_level": "COMMON"
            }
        }
    },
    "2373": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2373_1681446176.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2373",
                "name": "Busy Badger Ollivander #2373",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446166,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2373_1681446176.png",
                "rarity_score": 318.61233677303113,
                "rarity_level": "EPIC"
            }
        }
    },
    "2299": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2299_1681446159.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2299",
                "name": "Dark Badger Quillon #2299",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2299_1681446159.png",
                "rarity_score": 62.26355446939635,
                "rarity_level": "COMMON"
            }
        }
    },
    "2335": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2335_1681446165.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2335",
                "name": "Puzzled Badger Sir Nicholaus #2335",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446160,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2335_1681446165.png",
                "rarity_score": 247.6314733459636,
                "rarity_level": "EPIC"
            }
        }
    },
    "2370": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2370_1681446171.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2370",
                "name": "Repulsive Badger Mad-Eye Moody #2370",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2370_1681446171.png",
                "rarity_score": 63.53895657502929,
                "rarity_level": "COMMON"
            }
        }
    },
    "2405": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2405_1681446177.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2405",
                "name": "Calm Badger Jasper #2405",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446172,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2405_1681446177.png",
                "rarity_score": 58.7536258535575,
                "rarity_level": "COMMON"
            }
        }
    },
    "2314": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2314_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2314",
                "name": "Nice Badger Basilisk #2314",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446154,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2314_1681446160.png",
                "rarity_score": 73.99737654697373,
                "rarity_level": "COMMON"
            }
        }
    },
    "2338": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2338_1681446168.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2338",
                "name": "Helpless Badger Niffler #2338",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446161,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2338_1681446168.png",
                "rarity_score": 104.02351378195219,
                "rarity_level": "RARE"
            }
        }
    },
    "2383": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2383_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2383",
                "name": "Kind Badger Holly #2383",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446168,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2383_1681446174.png",
                "rarity_score": 73.49971850126188,
                "rarity_level": "COMMON"
            }
        }
    },
    "2415": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2415_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2415",
                "name": "Amused Badger Seamus #2415",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2415_1681446180.png",
                "rarity_score": 99.20945041126333,
                "rarity_level": "RARE"
            }
        }
    },
    "2309": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2309_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2309",
                "name": "Happy Badger Castiel #2309",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2309_1681446160.png",
                "rarity_score": 75.44049038649167,
                "rarity_level": "COMMON"
            }
        }
    },
    "2341": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2341_1681446168.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2341",
                "name": "Dull Badger Bronn #2341",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446161,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2341_1681446168.png",
                "rarity_score": 116.66241596246967,
                "rarity_level": "RARE"
            }
        }
    },
    "2385": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2385_1681446175.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2385",
                "name": "Comfy Badger Colt #2385",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446169,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2385_1681446175.png",
                "rarity_score": 89.55544514117378,
                "rarity_level": "RARE"
            }
        }
    },
    "2421": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2421_1681446181.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2421",
                "name": "Shill Badger Edmund #2421",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2421_1681446181.png",
                "rarity_score": 60.74807342164441,
                "rarity_level": "COMMON"
            }
        }
    },
    "2295": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2295_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2295",
                "name": "Poor Badger Dorian #2295",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2295_1681446160.png",
                "rarity_score": 99.58891291214778,
                "rarity_level": "RARE"
            }
        }
    },
    "2343": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2343_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2343",
                "name": "Talented Badger Nicolas #2343",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446162,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2343_1681446169.png",
                "rarity_score": 81.49743091138902,
                "rarity_level": "COMMON"
            }
        }
    },
    "2391": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2391_1681446177.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2391",
                "name": "Dolphin Badger Beckham #2391",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2391_1681446177.png",
                "rarity_score": 105.72884497410523,
                "rarity_level": "RARE"
            }
        }
    },
    "2308": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2308_1681446160.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2308",
                "name": "Comfy Badger Vasyl #2308",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2308_1681446160.png",
                "rarity_score": 197.9472692493448,
                "rarity_level": "RARE"
            }
        }
    },
    "2345": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2345_1681446166.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2345",
                "name": "Condemned Badger Ray #2345",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446162,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2345_1681446166.png",
                "rarity_score": 52.559923776582885,
                "rarity_level": "COMMON"
            }
        }
    },
    "2375": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2375_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2375",
                "name": "Encouraging Badger Quirrel #2375",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446167,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2375_1681446174.png",
                "rarity_score": 72.42681239784649,
                "rarity_level": "COMMON"
            }
        }
    },
    "2419": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2419_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2419",
                "name": "Scary Badger Charlie #2419",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2419_1681446180.png",
                "rarity_score": 73.62773165307267,
                "rarity_level": "COMMON"
            }
        }
    },
    "2302": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2302_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2302",
                "name": "Excited Badger Bones #2302",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2302_1681446161.png",
                "rarity_score": 82.98635894373756,
                "rarity_level": "COMMON"
            }
        }
    },
    "2347": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2347_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2347",
                "name": "Delightful Badger Theodore #2347",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446162,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2347_1681446169.png",
                "rarity_score": 73.7918747286698,
                "rarity_level": "COMMON"
            }
        }
    },
    "2396": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2396_1681446179.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2396",
                "name": "Energetic Badger Antonio #2396",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2396_1681446179.png",
                "rarity_score": 111.67648659727942,
                "rarity_level": "RARE"
            }
        }
    },
    "2311": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2311_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2311",
                "name": "Nice Badger Cecil #2311",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446154,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2311_1681446161.png",
                "rarity_score": 132.35490579418473,
                "rarity_level": "RARE"
            }
        }
    },
    "2349": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2349_1681446168.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2349",
                "name": "Scary Badger Sawyer #2349",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2349_1681446168.png",
                "rarity_score": 71.32004884622062,
                "rarity_level": "COMMON"
            }
        }
    },
    "2388": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2388_1681446175.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2388",
                "name": "Whale Badger Grey #2388",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446169,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2388_1681446175.png",
                "rarity_score": 56.658480027198635,
                "rarity_level": "COMMON"
            }
        }
    },
    "2422": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2422_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2422",
                "name": "Envious Badger Lachlan #2422",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446176,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2422_1681446180.png",
                "rarity_score": 60.38616685943168,
                "rarity_level": "COMMON"
            }
        }
    },
    "2304": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2304_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2304",
                "name": "Defiant Badger Bastian #2304",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2304_1681446161.png",
                "rarity_score": 72.12703324272096,
                "rarity_level": "COMMON"
            }
        }
    },
    "2351": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2351_1681446171.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2351",
                "name": "Annoying Badger Cesar #2351",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2351_1681446171.png",
                "rarity_score": 83.06890200653176,
                "rarity_level": "COMMON"
            }
        }
    },
    "2402": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2402_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2402",
                "name": "Disgusted Badger Gatsby #2402",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446171,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2402_1681446178.png",
                "rarity_score": 71.79368461906854,
                "rarity_level": "COMMON"
            }
        }
    },
    "2300": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2300_1681446161.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2300",
                "name": "Brainy Badger Sphynx #2300",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446152,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2300_1681446161.png",
                "rarity_score": 94.48693892261488,
                "rarity_level": "RARE"
            }
        }
    },
    "2352": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2352_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2352",
                "name": "Brave Badger Dudley #2352",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2352_1681446169.png",
                "rarity_score": 160.97194300776425,
                "rarity_level": "RARE"
            }
        }
    },
    "2390": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2390_1681446177.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2390",
                "name": "Dildo Badger Hermione #2390",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2390_1681446177.png",
                "rarity_score": 65.75767972141217,
                "rarity_level": "COMMON"
            }
        }
    },
    "2318": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2318_1681446162.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2318",
                "name": "Poised Badger Tyson #2318",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446156,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2318_1681446162.png",
                "rarity_score": 60.2922351644102,
                "rarity_level": "COMMON"
            }
        }
    },
    "2354": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2354_1681446170.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2354",
                "name": "Fierce Badger Hendrix #2354",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2354_1681446170.png",
                "rarity_score": 79.47238902135958,
                "rarity_level": "COMMON"
            }
        }
    },
    "2399": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2399_1681446179.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2399",
                "name": "Gleaming Badger Malfoy #2399",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446171,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2399_1681446179.png",
                "rarity_score": 109.95943705437897,
                "rarity_level": "RARE"
            }
        }
    },
    "2321": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2321_1681446162.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2321",
                "name": "Testy Badger Peter #2321",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446156,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2321_1681446162.png",
                "rarity_score": 70.93438015431842,
                "rarity_level": "COMMON"
            }
        }
    },
    "2355": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2355_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2355",
                "name": "Obnoxious Badger Newt #2355",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2355_1681446169.png",
                "rarity_score": 93.229552164488,
                "rarity_level": "RARE"
            }
        }
    },
    "2392": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2392_1681446176.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2392",
                "name": "Defiant Badger Tiberius #2392",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2392_1681446176.png",
                "rarity_score": 68.88536966545445,
                "rarity_level": "COMMON"
            }
        }
    },
    "2324": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2324_1681446162.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2324",
                "name": "Elegant Badger Santiago #2324",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446158,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2324_1681446162.png",
                "rarity_score": 55.61183643941427,
                "rarity_level": "COMMON"
            }
        }
    },
    "2356": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2356_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2356",
                "name": "Elegant Badger Malcolm #2356",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2356_1681446169.png",
                "rarity_score": 68.24936808873099,
                "rarity_level": "COMMON"
            }
        }
    },
    "2394": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2394_1681446175.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2394",
                "name": "Clammy Badger Severus #2394",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2394_1681446175.png",
                "rarity_score": 53.12279390231193,
                "rarity_level": "COMMON"
            }
        }
    },
    "2320": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2320_1681446162.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2320",
                "name": "Bookworm Badger Georgie #2320",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Kadena",
                        "identifier": "regular_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Kadena",
                        "identifier": "smiley_face_kadena",
                        "trait_type": "Face",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446156,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2320_1681446162.png",
                "rarity_score": 258.4967689315515,
                "rarity_level": "EPIC"
            }
        }
    },
    "2357": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2357_1681446170.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2357",
                "name": "Shill Badger George #2357",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2357_1681446170.png",
                "rarity_score": 77.52766100504833,
                "rarity_level": "COMMON"
            }
        }
    },
    "2398": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2398_1681446179.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2398",
                "name": "Helpless Badger Peeves #2398",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446171,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2398_1681446179.png",
                "rarity_score": 140.78377410818283,
                "rarity_level": "RARE"
            }
        }
    },
    "2310": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2310_1681446162.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2310",
                "name": "Powerful Badger Ely #2310",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446153,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2310_1681446162.png",
                "rarity_score": 135.70773090970454,
                "rarity_level": "RARE"
            }
        }
    },
    "2358": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2358_1681446168.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2358",
                "name": "Courageous Badger Orlando #2358",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2358_1681446168.png",
                "rarity_score": 57.874446647643914,
                "rarity_level": "COMMON"
            }
        }
    },
    "2382": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2382_1681446176.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2382",
                "name": "Glorious Badger Klitschko #2382",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446168,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2382_1681446176.png",
                "rarity_score": 102.21422038837046,
                "rarity_level": "RARE"
            }
        }
    },
    "2317": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2317_1681446163.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2317",
                "name": "Magnificent Badger Peter #2317",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446156,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2317_1681446163.png",
                "rarity_score": 77.00614886361183,
                "rarity_level": "COMMON"
            }
        }
    },
    "2360": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2360_1681446168.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2360",
                "name": "Kind Badger Binns #2360",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446164,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2360_1681446168.png",
                "rarity_score": 54.05079398457831,
                "rarity_level": "COMMON"
            }
        }
    },
    "2386": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2386_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2386",
                "name": "Tired Badger Firebolt #2386",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446169,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2386_1681446174.png",
                "rarity_score": 56.33532103253329,
                "rarity_level": "COMMON"
            }
        }
    },
    "2418": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2418_1681446181.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2418",
                "name": "Fine Badger Fluffy #2418",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2418_1681446181.png",
                "rarity_score": 85.18724120249112,
                "rarity_level": "COMMON"
            }
        }
    },
    "2319": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2319_1681446163.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2319",
                "name": "Tense Badger Mandrake #2319",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446156,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2319_1681446163.png",
                "rarity_score": 103.98813914812328,
                "rarity_level": "RARE"
            }
        }
    },
    "2361": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2361_1681446172.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2361",
                "name": "Cryptic Badger Moby #2361",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446164,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2361_1681446172.png",
                "rarity_score": 111.88412744311975,
                "rarity_level": "RARE"
            }
        }
    },
    "2407": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2407_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2407",
                "name": "Wicked Badger Maxim #2407",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446173,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2407_1681446178.png",
                "rarity_score": 55.593582887700535,
                "rarity_level": "COMMON"
            }
        }
    },
    "2325": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2325_1681446163.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2325",
                "name": "Lambo Badger Bryce #2325",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446158,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2325_1681446163.png",
                "rarity_score": 77.67300120753661,
                "rarity_level": "COMMON"
            }
        }
    },
    "2362": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2362_1681446171.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2362",
                "name": "Perfect Badger Lysander #2362",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446164,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2362_1681446171.png",
                "rarity_score": 93.45128912683853,
                "rarity_level": "RARE"
            }
        }
    },
    "2406": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2406_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2406",
                "name": "Happy Badger Jake #2406",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446172,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2406_1681446180.png",
                "rarity_score": 115.56379803018977,
                "rarity_level": "RARE"
            }
        }
    },
    "2326": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2326_1681446163.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2326",
                "name": "Puzzled Badger Robinson #2326",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446158,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2326_1681446163.png",
                "rarity_score": 64.50823965208737,
                "rarity_level": "COMMON"
            }
        }
    },
    "2364": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2364_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2364",
                "name": "Uptight Badger Moore #2364",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446164,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2364_1681446169.png",
                "rarity_score": 52.65259207131128,
                "rarity_level": "COMMON"
            }
        }
    },
    "2387": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2387_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2387",
                "name": "Hungry Badger Jacob #2387",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446169,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2387_1681446178.png",
                "rarity_score": 95.66458504063306,
                "rarity_level": "RARE"
            }
        }
    },
    "2322": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2322_1681446163.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2322",
                "name": "Pleasant Badger Dumbledore #2322",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446157,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2322_1681446163.png",
                "rarity_score": 73.98116203453736,
                "rarity_level": "COMMON"
            }
        }
    },
    "2363": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2363_1681446172.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2363",
                "name": "Busy Badger Bellatrix #2363",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446164,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2363_1681446172.png",
                "rarity_score": 73.96463797379262,
                "rarity_level": "COMMON"
            }
        }
    },
    "2408": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2408_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2408",
                "name": "Courageous Badger Lennie #2408",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446173,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2408_1681446178.png",
                "rarity_score": 52.4783734651862,
                "rarity_level": "COMMON"
            }
        }
    },
    "2328": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2328_1681446164.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2328",
                "name": "Brainy Badger Romeo #2328",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446159,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2328_1681446164.png",
                "rarity_score": 71.47182705140781,
                "rarity_level": "COMMON"
            }
        }
    },
    "2367": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2367_1681446173.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2367",
                "name": "Misty Badger Minerva #2367",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2367_1681446173.png",
                "rarity_score": 108.88902285527838,
                "rarity_level": "RARE"
            }
        }
    },
    "2410": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2410_1681446179.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2410",
                "name": "Tame Badger Nimbus #2410",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446173,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2410_1681446179.png",
                "rarity_score": 96.70455708152701,
                "rarity_level": "RARE"
            }
        }
    },
    "2323": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2323_1681446164.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2323",
                "name": "Fine Badger Manny #2323",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446158,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2323_1681446164.png",
                "rarity_score": 96.69857741514642,
                "rarity_level": "RARE"
            }
        }
    },
    "2368": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2368_1681446171.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2368",
                "name": "Fragile Badger Ely #2368",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2368_1681446171.png",
                "rarity_score": 100.50344685877958,
                "rarity_level": "RARE"
            }
        }
    },
    "2404": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2404_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2404",
                "name": "FUD Badger Fury #2404",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446172,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2404_1681446178.png",
                "rarity_score": 224.6121226268285,
                "rarity_level": "EPIC"
            }
        }
    },
    "2334": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2334_1681446165.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2334",
                "name": "Silly Badger Philip #2334",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446160,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2334_1681446165.png",
                "rarity_score": 58.06677367741404,
                "rarity_level": "COMMON"
            }
        }
    },
    "2369": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2369_1681446173.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2369",
                "name": "Lambo Badger Wilbur #2369",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2369_1681446173.png",
                "rarity_score": 152.11435996489104,
                "rarity_level": "RARE"
            }
        }
    },
    "2412": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2412_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2412",
                "name": "Terrible Badger Weasley #2412",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446174,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2412_1681446180.png",
                "rarity_score": 76.73892226158121,
                "rarity_level": "COMMON"
            }
        }
    },
    "2337": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2337_1681446166.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2337",
                "name": "Angry Badger Cian #2337",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446160,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2337_1681446166.png",
                "rarity_score": 73.4708872208008,
                "rarity_level": "COMMON"
            }
        }
    },
    "2374": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2374_1681446173.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2374",
                "name": "Dildo Badger Dallas #2374",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446167,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2374_1681446173.png",
                "rarity_score": 73.0067359471029,
                "rarity_level": "COMMON"
            }
        }
    },
    "2411": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2411_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2411",
                "name": "Fantastic Badger Gulliver #2411",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Tie Dye",
                        "identifier": "skinny_tie_dye",
                        "trait_type": "Body",
                        "score": 76.47058823529412
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446174,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2411_1681446180.png",
                "rarity_score": 343.698840911308,
                "rarity_level": "EPIC"
            }
        }
    },
    "2346": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2346_1681446166.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2346",
                "name": "Filthy Badger Tyson #2346",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446162,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2346_1681446166.png",
                "rarity_score": 72.74304768492652,
                "rarity_level": "COMMON"
            }
        }
    },
    "2376": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2376_1681446175.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2376",
                "name": "Funny Badger Rubeus #2376",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446167,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2376_1681446175.png",
                "rarity_score": 82.29543957858435,
                "rarity_level": "COMMON"
            }
        }
    },
    "2329": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2329_1681446167.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2329",
                "name": "Ashamed Badger Shep #2329",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446159,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2329_1681446167.png",
                "rarity_score": 83.28621482130916,
                "rarity_level": "COMMON"
            }
        }
    },
    "2377": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2377_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2377",
                "name": "Modern Badger Cash #2377",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446167,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2377_1681446174.png",
                "rarity_score": 67.29862613001849,
                "rarity_level": "COMMON"
            }
        }
    },
    "2414": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2414_1681446181.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2414",
                "name": "Dangerous Badger Braham #2414",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446174,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2414_1681446181.png",
                "rarity_score": 113.15543354041608,
                "rarity_level": "RARE"
            }
        }
    },
    "2336": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2336_1681446167.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2336",
                "name": "Brainy Badger Gulliver #2336",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446160,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2336_1681446167.png",
                "rarity_score": 78.55896920101225,
                "rarity_level": "COMMON"
            }
        }
    },
    "2379": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2379_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2379",
                "name": "Precious Badger Grawp #2379",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446168,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2379_1681446174.png",
                "rarity_score": 105.08715414723113,
                "rarity_level": "RARE"
            }
        }
    },
    "2417": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2417_1681446181.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2417",
                "name": "Mushy Badger Pacquiao #2417",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Tired Face Kadena",
                        "identifier": "tired_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2417_1681446181.png",
                "rarity_score": 427.7369017917043,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2342": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2342_1681446167.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2342",
                "name": "Odd Badger Dudley #2342",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446161,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2342_1681446167.png",
                "rarity_score": 64.58858910141501,
                "rarity_level": "COMMON"
            }
        }
    },
    "2380": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2380_1681446175.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2380",
                "name": "Rich Badger Scimgeour #2380",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446168,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2380_1681446175.png",
                "rarity_score": 67.88168166313092,
                "rarity_level": "COMMON"
            }
        }
    },
    "2425": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2425_1681446182.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2425",
                "name": "Witty Badger Griphook #2425",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Tired Face Tie Dye",
                        "identifier": "tired_face_tie_dye",
                        "trait_type": "Face",
                        "score": 100.0
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446176,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2425_1681446182.png",
                "rarity_score": 191.8310143174027,
                "rarity_level": "RARE"
            }
        }
    },
    "2359": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2359_1681446168.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2359",
                "name": "Helpful Badger Boot #2359",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446164,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2359_1681446168.png",
                "rarity_score": 52.38276816228619,
                "rarity_level": "COMMON"
            }
        }
    },
    "2384": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2384_1681446176.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2384",
                "name": "Dead Badger Aragog #2384",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446169,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2384_1681446176.png",
                "rarity_score": 65.16168385754449,
                "rarity_level": "COMMON"
            }
        }
    },
    "2365": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2365_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2365",
                "name": "Bright Badger Johnson #2365",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2365_1681446169.png",
                "rarity_score": 76.18446555363653,
                "rarity_level": "COMMON"
            }
        }
    },
    "2393": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2393_1681446176.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2393",
                "name": "Brave Badger Cannon #2393",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2393_1681446176.png",
                "rarity_score": 55.58511808114699,
                "rarity_level": "COMMON"
            }
        }
    },
    "2340": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2340_1681446169.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2340",
                "name": "Talented Badger Errol #2340",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446161,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2340_1681446169.png",
                "rarity_score": 79.63668056618185,
                "rarity_level": "COMMON"
            }
        }
    },
    "2395": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2395_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2395",
                "name": "Famous Badger Draco #2395",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2395_1681446178.png",
                "rarity_score": 76.57978483264745,
                "rarity_level": "COMMON"
            }
        }
    },
    "2350": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2350_1681446170.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2350",
                "name": "Strange Badger Heathcliff #2350",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446163,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2350_1681446170.png",
                "rarity_score": 141.75841244594662,
                "rarity_level": "RARE"
            }
        }
    },
    "2397": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2397_1681446180.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2397",
                "name": "Bookworm Badger Scimgeour #2397",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2397_1681446180.png",
                "rarity_score": 137.1320773066799,
                "rarity_level": "RARE"
            }
        }
    },
    "2333": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2333_1681446170.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2333",
                "name": "Exuberant Badger Maxim #2333",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446160,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2333_1681446170.png",
                "rarity_score": 101.04920341318767,
                "rarity_level": "RARE"
            }
        }
    },
    "2400": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2400_1681446178.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2400",
                "name": "Perfect Badger Fabian #2400",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446171,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2400_1681446178.png",
                "rarity_score": 114.91636734486356,
                "rarity_level": "RARE"
            }
        }
    },
    "2371": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2371_1681446171.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2371",
                "name": "Fragile Badger Watson #2371",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446165,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2371_1681446171.png",
                "rarity_score": 72.09911871219374,
                "rarity_level": "COMMON"
            }
        }
    },
    "2403": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2403_1681446179.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2403",
                "name": "Enthusiastic Badger Marciano #2403",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446172,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2403_1681446179.png",
                "rarity_score": 88.38848891392105,
                "rarity_level": "COMMON"
            }
        }
    },
    "2381": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2381_1681446173.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2381",
                "name": "Glorious Badger Mike #2381",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446168,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2381_1681446173.png",
                "rarity_score": 68.86523091769337,
                "rarity_level": "COMMON"
            }
        }
    },
    "2413": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2413_1681446182.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2413",
                "name": "Helpful Badger Grawp #2413",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Kadena",
                        "identifier": "muscular_kadena",
                        "trait_type": "Body",
                        "score": 86.66666666666667
                    },
                    {
                        "value": "Silly Face Kadena",
                        "identifier": "silly_face_kadena",
                        "trait_type": "Face",
                        "score": 144.44444444444446
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446174,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2413_1681446182.png",
                "rarity_score": 311.0240783598847,
                "rarity_level": "EPIC"
            }
        }
    },
    "2378": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2378_1681446174.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2378",
                "name": "Modern Badger Harry Potter #2378",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446167,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2378_1681446174.png",
                "rarity_score": 102.62051454065477,
                "rarity_level": "RARE"
            }
        }
    },
    "2401": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2401_1681446177.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2401",
                "name": "Testy Badger Wilbur #2401",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446171,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2401_1681446177.png",
                "rarity_score": 61.14171939132702,
                "rarity_level": "COMMON"
            }
        }
    },
    "2389": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2389_1681446177.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2389",
                "name": "FUD Badger Bertie #2389",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446170,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2389_1681446177.png",
                "rarity_score": 66.91451293193674,
                "rarity_level": "COMMON"
            }
        }
    },
    "2427": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2427_1681446182.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2427",
                "name": "Fancy Badger Rubin #2427",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446177,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2427_1681446182.png",
                "rarity_score": 69.17727090836334,
                "rarity_level": "COMMON"
            }
        }
    },
    "2424": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2424_1681446183.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2424",
                "name": "Determined Badger Carter #2424",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446176,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2424_1681446183.png",
                "rarity_score": 137.27299564670133,
                "rarity_level": "RARE"
            }
        }
    },
    "2416": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2416_1681446183.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2416",
                "name": "Enchanting Badger Grawp #2416",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446175,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2416_1681446183.png",
                "rarity_score": 114.97482783154712,
                "rarity_level": "RARE"
            }
        }
    },
    "2423": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2423_1681446183.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2423",
                "name": "Byzantine Badger Moore #2423",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446176,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2423_1681446183.png",
                "rarity_score": 102.37135486070608,
                "rarity_level": "RARE"
            }
        }
    },
    "2430": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2430_1681446184.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2430",
                "name": "Kind Badger Duncan #2430",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446177,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2430_1681446184.png",
                "rarity_score": 67.20868736197474,
                "rarity_level": "COMMON"
            }
        }
    },
    "2431": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2431_1681446184.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2431",
                "name": "Disgusted Badger Goyle #2431",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446177,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2431_1681446184.png",
                "rarity_score": 64.76420283178994,
                "rarity_level": "COMMON"
            }
        }
    },
    "2432": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2432_1681446184.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2432",
                "name": "Hilarious Badger Cedric #2432",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446178,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2432_1681446184.png",
                "rarity_score": 59.67342454067589,
                "rarity_level": "COMMON"
            }
        }
    },
    "2433": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2433_1681446184.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2433",
                "name": "Fragile Badger Graham #2433",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446178,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2433_1681446184.png",
                "rarity_score": 117.58044924383321,
                "rarity_level": "RARE"
            }
        }
    },
    "2443": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2443_1681446185.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2443",
                "name": "FUD Badger Langston #2443",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2443_1681446185.png",
                "rarity_score": 70.58090087544028,
                "rarity_level": "COMMON"
            }
        }
    },
    "2437": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2437_1681446185.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2437",
                "name": "Cautious Badger Gringot #2437",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446178,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2437_1681446185.png",
                "rarity_score": 85.02652667819169,
                "rarity_level": "COMMON"
            }
        }
    },
    "2439": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2439_1681446185.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2439",
                "name": "Average Badger Miguel #2439",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2439_1681446185.png",
                "rarity_score": 57.452481904374764,
                "rarity_level": "COMMON"
            }
        }
    },
    "2426": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2426_1681446185.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2426",
                "name": "HODL Badger Pettigrew #2426",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446177,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2426_1681446185.png",
                "rarity_score": 68.15670710057202,
                "rarity_level": "COMMON"
            }
        }
    },
    "2445": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2445_1681446185.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2445",
                "name": "Broken Badger Minerva #2445",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446180,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2445_1681446185.png",
                "rarity_score": 59.66179611331862,
                "rarity_level": "COMMON"
            }
        }
    },
    "2441": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2441_1681446186.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2441",
                "name": "Magnificent Badger Hendrix #2441",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2441_1681446186.png",
                "rarity_score": 198.45249517995794,
                "rarity_level": "RARE"
            }
        }
    },
    "2436": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2436_1681446186.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2436",
                "name": "Helpless Badger Sawyer #2436",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446178,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2436_1681446186.png",
                "rarity_score": 107.74254102691607,
                "rarity_level": "RARE"
            }
        }
    },
    "2440": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2440_1681446186.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2440",
                "name": "Rekt Badger Asher #2440",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2440_1681446186.png",
                "rarity_score": 119.43989871143216,
                "rarity_level": "RARE"
            }
        }
    },
    "2435": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2435_1681446186.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2435",
                "name": "Creepy Badger Kingston #2435",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446178,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2435_1681446186.png",
                "rarity_score": 78.0895456096771,
                "rarity_level": "COMMON"
            }
        }
    },
    "2442": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2442_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2442",
                "name": "Creepy Badger Jacob #2442",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2442_1681446187.png",
                "rarity_score": 61.42698556082281,
                "rarity_level": "COMMON"
            }
        }
    },
    "2434": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2434_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2434",
                "name": "Uptight Badger Layne #2434",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446178,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2434_1681446187.png",
                "rarity_score": 114.87201506817044,
                "rarity_level": "RARE"
            }
        }
    },
    "2428": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2428_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2428",
                "name": "Fantastic Badger Gulliver #2428",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446177,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2428_1681446187.png",
                "rarity_score": 76.18022619461084,
                "rarity_level": "COMMON"
            }
        }
    },
    "2429": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2429_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2429",
                "name": "Funny Badger Ludo #2429",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446177,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2429_1681446187.png",
                "rarity_score": 77.22884701147284,
                "rarity_level": "COMMON"
            }
        }
    },
    "2452": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2452_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2452",
                "name": "Troubled Badger Tibbles #2452",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Normal Face Tie Dye",
                        "identifier": "normal_face_tie_dye",
                        "trait_type": "Face",
                        "score": 96.29629629629629
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446181,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2452_1681446187.png",
                "rarity_score": 204.33797978891533,
                "rarity_level": "RARE"
            }
        }
    },
    "2459": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2459_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2459",
                "name": "Helpless Badger Marcus #2459",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446182,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2459_1681446187.png",
                "rarity_score": 56.75929358147619,
                "rarity_level": "COMMON"
            }
        }
    },
    "2438": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2438_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2438",
                "name": "Wild Badger Johnson #2438",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2438_1681446187.png",
                "rarity_score": 127.50387077528573,
                "rarity_level": "RARE"
            }
        }
    },
    "2451": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2451_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2451",
                "name": "Clever Badger Hippogriff #2451",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446181,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2451_1681446188.png",
                "rarity_score": 53.81401123106051,
                "rarity_level": "COMMON"
            }
        }
    },
    "2444": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2444_1681446187.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2444",
                "name": "Powerful Badger Jones #2444",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Kadena",
                        "identifier": "skinny_kadena",
                        "trait_type": "Body",
                        "score": 113.04347826086956
                    },
                    {
                        "value": "Normal Face Kadena",
                        "identifier": "normal_face_kadena",
                        "trait_type": "Face",
                        "score": 260.0
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446179,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2444_1681446187.png",
                "rarity_score": 450.9136380119612,
                "rarity_level": "LEGENDARY"
            }
        }
    },
    "2447": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2447_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2447",
                "name": "Jittery Badger Cian #2447",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446180,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2447_1681446188.png",
                "rarity_score": 63.42523162019085,
                "rarity_level": "COMMON"
            }
        }
    },
    "2454": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2454_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2454",
                "name": "Panda Badger Tszyu #2454",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446181,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2454_1681446188.png",
                "rarity_score": 61.032073269695346,
                "rarity_level": "COMMON"
            }
        }
    },
    "2449": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2449_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2449",
                "name": "Modern Badger Crookshanks #2449",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kaptain Kadena Hat",
                        "identifier": "kaptain_kadena_hat",
                        "trait_type": "Headwear",
                        "score": 144.44444444444446
                    }
                ],
                "create-date": 1681446180,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2449_1681446188.png",
                "rarity_score": 213.70604752354933,
                "rarity_level": "RARE"
            }
        }
    },
    "2456": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2456_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2456",
                "name": "Easy Badger Dragon #2456",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446182,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2456_1681446188.png",
                "rarity_score": 68.40751322139,
                "rarity_level": "COMMON"
            }
        }
    },
    "2462": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2462_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2462",
                "name": "Clumsy Badger Dursley #2462",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446183,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2462_1681446188.png",
                "rarity_score": 122.67794855614468,
                "rarity_level": "RARE"
            }
        }
    },
    "2448": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2448_1681446188.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2448",
                "name": "Cautious Badger Grimmauld #2448",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Silly Face Tie Dye",
                        "identifier": "silly_face_tie_dye",
                        "trait_type": "Face",
                        "score": 136.8421052631579
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446180,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2448_1681446188.png",
                "rarity_score": 287.21444523332764,
                "rarity_level": "EPIC"
            }
        }
    },
    "2453": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2453_1681446189.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2453",
                "name": "Pleasant Badger Hogwart #2453",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446181,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2453_1681446189.png",
                "rarity_score": 103.36661701725959,
                "rarity_level": "RARE"
            }
        }
    },
    "2458": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2458_1681446189.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2458",
                "name": "Helpless Badger Maximus #2458",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446182,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2458_1681446189.png",
                "rarity_score": 139.25443615455106,
                "rarity_level": "RARE"
            }
        }
    },
    "2495": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2495_1681446195.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2495",
                "name": "Grumpy Badger Salazar #2495",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446190,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2495_1681446195.png",
                "rarity_score": 60.28771252309918,
                "rarity_level": "COMMON"
            }
        }
    },
    "2463": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2463_1681446189.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2463",
                "name": "FUD Badger Bartemius #2463",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446183,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2463_1681446189.png",
                "rarity_score": 60.16632313553656,
                "rarity_level": "COMMON"
            }
        }
    },
    "2497": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2497_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2497",
                "name": "Brainy Badger Ryker #2497",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446190,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2497_1681446198.png",
                "rarity_score": 120.12192779268707,
                "rarity_level": "RARE"
            }
        }
    },
    "2548": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2548_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2548",
                "name": "Magnificent Badger Godric #2548",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2548_1681446205.png",
                "rarity_score": 54.08418530286703,
                "rarity_level": "COMMON"
            }
        }
    },
    "2574": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2574_1681446212.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2574",
                "name": "Cryptic Badger Calvin #2574",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2574_1681446212.png",
                "rarity_score": 110.51297274720692,
                "rarity_level": "RARE"
            }
        }
    },
    "2465": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2465_1681446189.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2465",
                "name": "Aggressive Badger Jacob #2465",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446183,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2465_1681446189.png",
                "rarity_score": 67.55908066852061,
                "rarity_level": "COMMON"
            }
        }
    },
    "2455": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2455_1681446189.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2455",
                "name": "Disgusted Badger Abernathy #2455",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446181,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2455_1681446189.png",
                "rarity_score": 130.07998246303333,
                "rarity_level": "RARE"
            }
        }
    },
    "2446": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2446_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2446",
                "name": "Crazy Badger Griphook #2446",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446180,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2446_1681446190.png",
                "rarity_score": 66.17061036211612,
                "rarity_level": "COMMON"
            }
        }
    },
    "2499": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2499_1681446196.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2499",
                "name": "Testy Badger Kelpie #2499",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446190,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2499_1681446196.png",
                "rarity_score": 171.94458264234385,
                "rarity_level": "RARE"
            }
        }
    },
    "2464": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2464_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2464",
                "name": "Modern Badger Bastian #2464",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446183,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2464_1681446190.png",
                "rarity_score": 90.09375424604522,
                "rarity_level": "RARE"
            }
        }
    },
    "2460": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2460_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2460",
                "name": "Arrogant Badger Grindewald #2460",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446182,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2460_1681446190.png",
                "rarity_score": 80.95506481090239,
                "rarity_level": "COMMON"
            }
        }
    },
    "2457": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2457_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2457",
                "name": "Itchy Badger Ronald #2457",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446182,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2457_1681446190.png",
                "rarity_score": 90.49057111196802,
                "rarity_level": "RARE"
            }
        }
    },
    "2503": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2503_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2503",
                "name": "Gentle Badger Lewis #2503",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446191,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2503_1681446197.png",
                "rarity_score": 61.12169718397761,
                "rarity_level": "COMMON"
            }
        }
    },
    "2469": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2469_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2469",
                "name": "Aggressive Badger Filch #2469",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446185,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2469_1681446190.png",
                "rarity_score": 64.68972389692199,
                "rarity_level": "COMMON"
            }
        }
    },
    "2505": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2505_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2505",
                "name": "Annoying Badger Flitwick #2505",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446191,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2505_1681446198.png",
                "rarity_score": 80.0178720473656,
                "rarity_level": "COMMON"
            }
        }
    },
    "2546": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2546_1681446208.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2546",
                "name": "Determined Badger Muggle #2546",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2546_1681446208.png",
                "rarity_score": 119.13073546812093,
                "rarity_level": "RARE"
            }
        }
    },
    "2594": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2594_1681446215.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2594",
                "name": "Anal Badger Louis #2594",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446209,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2594_1681446215.png",
                "rarity_score": 70.52928557865447,
                "rarity_level": "COMMON"
            }
        }
    },
    "2470": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2470_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2470",
                "name": "Delightful Badger Quentin #2470",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446185,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2470_1681446190.png",
                "rarity_score": 70.05313816756794,
                "rarity_level": "COMMON"
            }
        }
    },
    "2506": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2506_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2506",
                "name": "Disgusted Badger Tyrion #2506",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446192,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2506_1681446198.png",
                "rarity_score": 123.41015685407102,
                "rarity_level": "RARE"
            }
        }
    },
    "2549": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2549_1681446208.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2549",
                "name": "Poor Badger Fabian #2549",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2549_1681446208.png",
                "rarity_score": 121.41469877038638,
                "rarity_level": "RARE"
            }
        }
    },
    "2597": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2597_1681446215.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2597",
                "name": "Depressed Badger Robinson #2597",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446209,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2597_1681446215.png",
                "rarity_score": 67.31725362661362,
                "rarity_level": "COMMON"
            }
        }
    },
    "2467": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2467_1681446191.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2467",
                "name": "Dumb Badger Sirius Black #2467",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446184,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2467_1681446191.png",
                "rarity_score": 93.98604996863443,
                "rarity_level": "RARE"
            }
        }
    },
    "2507": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2507_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2507",
                "name": "Cute Badger Mandrake #2507",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446192,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2507_1681446198.png",
                "rarity_score": 82.88293210383226,
                "rarity_level": "COMMON"
            }
        }
    },
    "2547": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2547_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2547",
                "name": "Enchanting Badger Louis #2547",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2547_1681446207.png",
                "rarity_score": 64.39916645364741,
                "rarity_level": "COMMON"
            }
        }
    },
    "2590": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2590_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2590",
                "name": "Clever Badger Bridger #2590",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Shades",
                        "identifier": "kadena_shades",
                        "trait_type": "Glasses",
                        "score": 35.61643835616438
                    }
                ],
                "create-date": 1681446208,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2590_1681446213.png",
                "rarity_score": 105.11949365215118,
                "rarity_level": "RARE"
            }
        }
    },
    "2466": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2466_1681446190.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2466",
                "name": "Annoyed Badger Elfie #2466",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446184,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2466_1681446190.png",
                "rarity_score": 76.59570462668596,
                "rarity_level": "COMMON"
            }
        }
    },
    "2461": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2461_1681446191.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2461",
                "name": "Envious Badger Greyback #2461",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446182,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2461_1681446191.png",
                "rarity_score": 110.38029132249736,
                "rarity_level": "RARE"
            }
        }
    },
    "2509": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2509_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2509",
                "name": "Stupid Badger Frederick #2509",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446192,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2509_1681446197.png",
                "rarity_score": 73.8781794877126,
                "rarity_level": "COMMON"
            }
        }
    },
    "2508": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2508_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2508",
                "name": "Proud Badger Cash #2508",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446192,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2508_1681446197.png",
                "rarity_score": 100.77607845344116,
                "rarity_level": "RARE"
            }
        }
    },
    "2476": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2476_1681446192.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2476",
                "name": "Dark Badger Louis #2476",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446186,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2476_1681446192.png",
                "rarity_score": 60.154511561220296,
                "rarity_level": "COMMON"
            }
        }
    },
    "2510": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2510_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2510",
                "name": "Asthmatic Badger Neville #2510",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446192,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2510_1681446198.png",
                "rarity_score": 92.46299498863074,
                "rarity_level": "RARE"
            }
        }
    },
    "2543": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2543_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2543",
                "name": "Clever Badger Macaulay #2543",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2543_1681446205.png",
                "rarity_score": 68.89738531309291,
                "rarity_level": "COMMON"
            }
        }
    },
    "2578": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2578_1681446211.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2578",
                "name": "Easy Badger Laila #2578",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446206,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2578_1681446211.png",
                "rarity_score": 62.869909472187906,
                "rarity_level": "COMMON"
            }
        }
    },
    "2479": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2479_1681446192.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2479",
                "name": "Friendly Badger Augustus #2479",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446187,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2479_1681446192.png",
                "rarity_score": 57.94567316156536,
                "rarity_level": "COMMON"
            }
        }
    },
    "2450": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2450_1681446192.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2450",
                "name": "Disgusted Badger Colt #2450",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681446181,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2450_1681446192.png",
                "rarity_score": 190.14618567614312,
                "rarity_level": "RARE"
            }
        }
    },
    "2511": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2511_1681446199.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2511",
                "name": "Homeless Badger Amos #2511",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446192,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2511_1681446199.png",
                "rarity_score": 95.50777929287689,
                "rarity_level": "RARE"
            }
        }
    },
    "2512": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2512_1681446200.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2512",
                "name": "Shy Badger Zane #2512",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Smiley Face Black",
                        "identifier": "smiley_face_black",
                        "trait_type": "Face",
                        "score": 20.967741935483872
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446193,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2512_1681446200.png",
                "rarity_score": 84.6195441273297,
                "rarity_level": "COMMON"
            }
        }
    },
    "2472": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2472_1681446192.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2472",
                "name": "Scary Badger Colt #2472",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446185,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2472_1681446192.png",
                "rarity_score": 66.2882967922612,
                "rarity_level": "COMMON"
            }
        }
    },
    "2513": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2513_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2513",
                "name": "Hilarious Badger Bones #2513",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446193,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2513_1681446197.png",
                "rarity_score": 54.46279749861872,
                "rarity_level": "COMMON"
            }
        }
    },
    "2537": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2537_1681446203.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2537",
                "name": "Lazy Badger Quillon #2537",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2537_1681446203.png",
                "rarity_score": 75.60980221919581,
                "rarity_level": "COMMON"
            }
        }
    },
    "2570": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2570_1681446210.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2570",
                "name": "Brainy Badger Lawrence #2570",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446204,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2570_1681446210.png",
                "rarity_score": 68.9914563613155,
                "rarity_level": "COMMON"
            }
        }
    },
    "2477": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2477_1681446193.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2477",
                "name": "Sore Badger Paws #2477",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446186,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2477_1681446193.png",
                "rarity_score": 74.35606112265909,
                "rarity_level": "COMMON"
            }
        }
    },
    "2514": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2514_1681446200.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2514",
                "name": "Bad Badger Flitwick #2514",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446193,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2514_1681446200.png",
                "rarity_score": 124.11773179068827,
                "rarity_level": "RARE"
            }
        }
    },
    "2556": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2556_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2556",
                "name": "Puzzled Badger Landon #2556",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2556_1681446209.png",
                "rarity_score": 86.80341869566567,
                "rarity_level": "COMMON"
            }
        }
    },
    "567": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/567_1681446215.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "567",
                "name": "Attractive Badger Grimmauld #567",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446210,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_567_1681446215.png",
                "rarity_score": 63.10238236045707,
                "rarity_level": "COMMON"
            }
        }
    },
    "2471": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2471_1681446193.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2471",
                "name": "Attractive Badger Troy #2471",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446185,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2471_1681446193.png",
                "rarity_score": 74.58467862450674,
                "rarity_level": "COMMON"
            }
        }
    },
    "2515": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2515_1681446201.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2515",
                "name": "Worried Badger Arlo #2515",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446194,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2515_1681446201.png",
                "rarity_score": 74.20464601324248,
                "rarity_level": "COMMON"
            }
        }
    },
    "2560": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2560_1681446208.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2560",
                "name": "Itchy Badger George #2560",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446202,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2560_1681446208.png",
                "rarity_score": 66.61062023931251,
                "rarity_level": "COMMON"
            }
        }
    },
    "2598": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2598_1681446217.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2598",
                "name": "Prickly Badger Alvarez #2598",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446209,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2598_1681446217.png",
                "rarity_score": 122.75984284354993,
                "rarity_level": "RARE"
            }
        }
    },
    "2468": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2468_1681446193.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2468",
                "name": "Annoying Badger Nicolas #2468",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446184,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2468_1681446193.png",
                "rarity_score": 87.14444172740637,
                "rarity_level": "COMMON"
            }
        }
    },
    "2516": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2516_1681446201.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2516",
                "name": "Troubled Badger Fang #2516",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446194,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2516_1681446201.png",
                "rarity_score": 89.28504222785118,
                "rarity_level": "RARE"
            }
        }
    },
    "2557": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2557_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2557",
                "name": "Carebear Badger Diggle #2557",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2557_1681446207.png",
                "rarity_score": 80.64764261064076,
                "rarity_level": "COMMON"
            }
        }
    },
    "2588": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2588_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2588",
                "name": "Dangerous Badger Jake #2588",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446208,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2588_1681446213.png",
                "rarity_score": 59.954169010901786,
                "rarity_level": "COMMON"
            }
        }
    },
    "2481": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2481_1681446193.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2481",
                "name": "Creepy Badger Robinson #2481",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2481_1681446193.png",
                "rarity_score": 118.37239869509196,
                "rarity_level": "RARE"
            }
        }
    },
    "2517": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2517_1681446202.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2517",
                "name": "Prickly Badger Quirrel #2517",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446194,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2517_1681446202.png",
                "rarity_score": 319.86764260471625,
                "rarity_level": "EPIC"
            }
        }
    },
    "2563": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2563_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2563",
                "name": "Uptight Badger Phineas #2563",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446202,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2563_1681446209.png",
                "rarity_score": 79.25649692128687,
                "rarity_level": "COMMON"
            }
        }
    },
    "2484": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2484_1681446193.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2484",
                "name": "Pleasant Badger Newt #2484",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2484_1681446193.png",
                "rarity_score": 126.29189340200375,
                "rarity_level": "RARE"
            }
        }
    },
    "2518": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2518_1681446203.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2518",
                "name": "Energetic Badger Percival #2518",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446194,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2518_1681446203.png",
                "rarity_score": 134.4273679823761,
                "rarity_level": "RARE"
            }
        }
    },
    "2569": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2569_1681446210.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2569",
                "name": "Impressive Badger Alecto #2569",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446204,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2569_1681446210.png",
                "rarity_score": 63.271249094640815,
                "rarity_level": "COMMON"
            }
        }
    },
    "2473": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2473_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2473",
                "name": "Famous Badger Bryce #2473",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446186,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2473_1681446194.png",
                "rarity_score": 117.50520016078205,
                "rarity_level": "RARE"
            }
        }
    },
    "2519": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2519_1681446200.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2519",
                "name": "Aggressive Badger Ethan #2519",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446194,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2519_1681446200.png",
                "rarity_score": 102.85978383425818,
                "rarity_level": "RARE"
            }
        }
    },
    "2552": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2552_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2552",
                "name": "Sore Badger Potter #2552",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446200,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2552_1681446209.png",
                "rarity_score": 131.563843420549,
                "rarity_level": "RARE"
            }
        }
    },
    "2483": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2483_1681446193.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2483",
                "name": "Testy Badger Finn #2483",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Tie Dye",
                        "identifier": "muscular_tie_dye",
                        "trait_type": "Body",
                        "score": 68.42105263157895
                    },
                    {
                        "value": "Smiley Face Tie Dye",
                        "identifier": "smiley_face_tie_dye",
                        "trait_type": "Face",
                        "score": 173.33333333333334
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2483_1681446193.png",
                "rarity_score": 304.1915300126967,
                "rarity_level": "EPIC"
            }
        }
    },
    "2520": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2520_1681446202.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2520",
                "name": "Average Badger Amos #2520",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2520_1681446202.png",
                "rarity_score": 71.34824400589447,
                "rarity_level": "COMMON"
            }
        }
    },
    "2564": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2564_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2564",
                "name": "Pleasant Badger Frederick #2564",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446203,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2564_1681446209.png",
                "rarity_score": 107.98689942904338,
                "rarity_level": "RARE"
            }
        }
    },
    "2486": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2486_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2486",
                "name": "Famous Badger Floyd #2486",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2486_1681446194.png",
                "rarity_score": 71.38124154940235,
                "rarity_level": "COMMON"
            }
        }
    },
    "2521": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2521_1681446200.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2521",
                "name": "Courageous Badger Lumos #2521",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2521_1681446200.png",
                "rarity_score": 64.784257023515,
                "rarity_level": "COMMON"
            }
        }
    },
    "2555": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2555_1681446208.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2555",
                "name": "Concerned Badger Cayman #2555",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2555_1681446208.png",
                "rarity_score": 116.82352575594304,
                "rarity_level": "RARE"
            }
        }
    },
    "2593": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2593_1681446215.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2593",
                "name": "Creepy Badger Henry #2593",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Kadena Kandy Cap",
                        "identifier": "kadena_kandy_cap",
                        "trait_type": "Headwear",
                        "score": 96.29629629629629
                    }
                ],
                "create-date": 1681446208,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2593_1681446215.png",
                "rarity_score": 182.21750247362735,
                "rarity_level": "RARE"
            }
        }
    },
    "2487": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2487_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2487",
                "name": "Upset Badger Major #2487",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2487_1681446194.png",
                "rarity_score": 82.30201174088495,
                "rarity_level": "COMMON"
            }
        }
    },
    "2522": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2522_1681446201.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2522",
                "name": "Excited Badger Deontay #2522",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2522_1681446201.png",
                "rarity_score": 61.84737783080643,
                "rarity_level": "COMMON"
            }
        }
    },
    "2561": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2561_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2561",
                "name": "Cautious Badger Cal #2561",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446202,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2561_1681446209.png",
                "rarity_score": 115.48034825600595,
                "rarity_level": "RARE"
            }
        }
    },
    "2599": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2599_1681446214.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2599",
                "name": "Nutty Badger Greyback #2599",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446209,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2599_1681446214.png",
                "rarity_score": 59.04024341819636,
                "rarity_level": "COMMON"
            }
        }
    },
    "2474": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2474_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2474",
                "name": "Nasty Badger Hagrid #2474",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446186,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2474_1681446194.png",
                "rarity_score": 103.5677895046568,
                "rarity_level": "RARE"
            }
        }
    },
    "2523": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2523_1681446201.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2523",
                "name": "Clever Badger Ryker #2523",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2523_1681446201.png",
                "rarity_score": 63.61463236106892,
                "rarity_level": "COMMON"
            }
        }
    },
    "2559": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2559_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2559",
                "name": "Strange Badger Latham #2559",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2559_1681446207.png",
                "rarity_score": 80.33968498788607,
                "rarity_level": "COMMON"
            }
        }
    },
    "2591": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2591_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2591",
                "name": "Encouraging Badger Bones #2591",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446208,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2591_1681446213.png",
                "rarity_score": 66.80663767370186,
                "rarity_level": "COMMON"
            }
        }
    },
    "2475": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2475_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2475",
                "name": "Repulsive Badger Blaise #2475",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Halo",
                        "identifier": "halo",
                        "trait_type": "Headwear",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446186,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2475_1681446194.png",
                "rarity_score": 121.87817788956995,
                "rarity_level": "RARE"
            }
        }
    },
    "2525": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2525_1681446203.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2525",
                "name": "Dead Badger Peverell #2525",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2525_1681446203.png",
                "rarity_score": 75.27695837368717,
                "rarity_level": "COMMON"
            }
        }
    },
    "2565": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2565_1681446211.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2565",
                "name": "Annoying Badger James #2565",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446203,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2565_1681446211.png",
                "rarity_score": 114.37966799476362,
                "rarity_level": "RARE"
            }
        }
    },
    "2488": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2488_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2488",
                "name": "Horrible Badger Lucius #2488",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2488_1681446194.png",
                "rarity_score": 61.57296042222161,
                "rarity_level": "COMMON"
            }
        }
    },
    "2524": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2524_1681446206.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2524",
                "name": "Perfect Badger Sugar Ray #2524",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2524_1681446206.png",
                "rarity_score": 126.11577995059409,
                "rarity_level": "RARE"
            }
        }
    },
    "2583": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2583_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2583",
                "name": "Lonely Badger Croix #2583",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Cool Bro Shades",
                        "identifier": "cool_bro_shades",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446207,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2583_1681446213.png",
                "rarity_score": 149.61915622757252,
                "rarity_level": "RARE"
            }
        }
    },
    "2490": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2490_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2490",
                "name": "Filthy Badger Grindewald #2490",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446189,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2490_1681446194.png",
                "rarity_score": 73.98676535786967,
                "rarity_level": "COMMON"
            }
        }
    },
    "2526": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2526_1681446203.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2526",
                "name": "Unsightly Badger Dempsey #2526",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2526_1681446203.png",
                "rarity_score": 98.00965994447475,
                "rarity_level": "RARE"
            }
        }
    },
    "2567": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2567_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2567",
                "name": "Upset Badger Finn #2567",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Indigo",
                        "identifier": "indigo",
                        "trait_type": "Background",
                        "score": 13.06532663316583
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446204,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2567_1681446209.png",
                "rarity_score": 66.71395089441236,
                "rarity_level": "COMMON"
            }
        }
    },
    "2480": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2480_1681446194.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2480",
                "name": "Attractive Badger Rhett #2480",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446187,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2480_1681446194.png",
                "rarity_score": 107.16113766011918,
                "rarity_level": "RARE"
            }
        }
    },
    "2527": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2527_1681446203.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2527",
                "name": "Delightful Badger Gatsby #2527",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2527_1681446203.png",
                "rarity_score": 81.13303046574232,
                "rarity_level": "COMMON"
            }
        }
    },
    "2566": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2566_1681446210.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2566",
                "name": "Filthy Badger Sugar #2566",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    }
                ],
                "create-date": 1681446203,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2566_1681446210.png",
                "rarity_score": 89.31551810247252,
                "rarity_level": "RARE"
            }
        }
    },
    "2493": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2493_1681446195.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2493",
                "name": "Calm Badger Mundungus #2493",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446189,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2493_1681446195.png",
                "rarity_score": 78.41140121445726,
                "rarity_level": "COMMON"
            }
        }
    },
    "2528": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2528_1681446203.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2528",
                "name": "Tough Badger Weasley #2528",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446195,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2528_1681446203.png",
                "rarity_score": 85.734181122625,
                "rarity_level": "COMMON"
            }
        }
    },
    "2568": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2568_1681446208.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2568",
                "name": "Lambo Badger Crouch #2568",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Mean Face Black",
                        "identifier": "mean_face_black",
                        "trait_type": "Face",
                        "score": 25.742574257425744
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446204,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2568_1681446208.png",
                "rarity_score": 60.472956551188325,
                "rarity_level": "COMMON"
            }
        }
    },
    "2596": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2596_1681446214.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2596",
                "name": "Charming Badger Pigwidgeon #2596",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446209,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2596_1681446214.png",
                "rarity_score": 59.168453334700445,
                "rarity_level": "COMMON"
            }
        }
    },
    "2478": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2478_1681446195.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2478",
                "name": "Bewildered Badger Gannon #2478",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446187,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2478_1681446195.png",
                "rarity_score": 76.67807985807875,
                "rarity_level": "COMMON"
            }
        }
    },
    "2529": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2529_1681446204.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2529",
                "name": "Dull Badger Landon #2529",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "White Sweater",
                        "identifier": "white_sweater",
                        "trait_type": "Sweater",
                        "score": 21.13821138211382
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446196,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2529_1681446204.png",
                "rarity_score": 166.1398544599963,
                "rarity_level": "RARE"
            }
        }
    },
    "2576": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2576_1681446212.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2576",
                "name": "FUD Badger Sawyer #2576",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Shock Therapy Gloves",
                        "identifier": "shock_therapy_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 26.262626262626263
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2576_1681446212.png",
                "rarity_score": 97.18452992501716,
                "rarity_level": "RARE"
            }
        }
    },
    "2489": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2489_1681446195.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2489",
                "name": "FUD Badger Longbottom #2489",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Punishing Poison Gloves",
                        "identifier": "punishing_poison_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 27.083333333333332
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    }
                ],
                "create-date": 1681446189,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2489_1681446195.png",
                "rarity_score": 201.15696280178764,
                "rarity_level": "RARE"
            }
        }
    },
    "2531": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2531_1681446202.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2531",
                "name": "Bad Badger Paws #2531",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446196,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2531_1681446202.png",
                "rarity_score": 99.7331835820788,
                "rarity_level": "RARE"
            }
        }
    },
    "2562": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2562_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2562",
                "name": "Fine Badger Firebolt #2562",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    }
                ],
                "create-date": 1681446202,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2562_1681446207.png",
                "rarity_score": 80.27681665275566,
                "rarity_level": "COMMON"
            }
        }
    },
    "2586": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2586_1681446215.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2586",
                "name": "Dull Badger Klitschko #2586",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446207,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2586_1681446215.png",
                "rarity_score": 117.97015721550649,
                "rarity_level": "RARE"
            }
        }
    },
    "2492": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2492_1681446196.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2492",
                "name": "Brainy Badger Cian #2492",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Badger Blue",
                        "identifier": "badger_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Emerald Crown",
                        "identifier": "emerald_crown",
                        "trait_type": "Headwear",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446189,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2492_1681446196.png",
                "rarity_score": 104.56452840624048,
                "rarity_level": "RARE"
            }
        }
    },
    "2532": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2532_1681446201.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2532",
                "name": "Crazy Badger Firenze #2532",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446196,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2532_1681446201.png",
                "rarity_score": 59.117522226962166,
                "rarity_level": "COMMON"
            }
        }
    },
    "2558": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2558_1681446208.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2558",
                "name": "Cruel Badger Prongs #2558",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Kadena Helmet",
                        "identifier": "kadena_helmet",
                        "trait_type": "Headwear",
                        "score": 104.0
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2558_1681446208.png",
                "rarity_score": 165.83057978435548,
                "rarity_level": "RARE"
            }
        }
    },
    "2595": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2595_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2595",
                "name": "Proud Badger Tibbles #2595",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    }
                ],
                "create-date": 1681446209,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2595_1681446213.png",
                "rarity_score": 57.14659622256338,
                "rarity_level": "COMMON"
            }
        }
    },
    "2485": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2485_1681446196.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2485",
                "name": "Powerful Badger Latham #2485",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446188,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2485_1681446196.png",
                "rarity_score": 148.7118027282268,
                "rarity_level": "RARE"
            }
        }
    },
    "2533": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2533_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2533",
                "name": "Embarrassed Badger Asher #2533",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Regular Tie Dye",
                        "identifier": "regular_tie_dye",
                        "trait_type": "Body",
                        "score": 70.27027027027027
                    },
                    {
                        "value": "Mean Face Tie Dye",
                        "identifier": "mean_face_tie_dye",
                        "trait_type": "Face",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446196,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2533_1681446205.png",
                "rarity_score": 264.57709198341587,
                "rarity_level": "EPIC"
            }
        }
    },
    "2579": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2579_1681446212.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2579",
                "name": "Super Badger Dobby #2579",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Silly Face Brown",
                        "identifier": "silly_face_brown",
                        "trait_type": "Face",
                        "score": 24.074074074074073
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446206,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2579_1681446212.png",
                "rarity_score": 97.15545259704724,
                "rarity_level": "RARE"
            }
        }
    },
    "2504": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2504_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2504",
                "name": "Modern Badger Landon #2504",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Smurfin",
                        "identifier": "smurfin",
                        "trait_type": "Background",
                        "score": 15.028901734104046
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446191,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2504_1681446197.png",
                "rarity_score": 66.50757777102181,
                "rarity_level": "COMMON"
            }
        }
    },
    "2536": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2536_1681446204.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2536",
                "name": "Panda Badger Werner #2536",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Kadena White Cap",
                        "identifier": "kadena_white_cap",
                        "trait_type": "Headwear",
                        "score": 35.13513513513514
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2536_1681446204.png",
                "rarity_score": 88.92487368818627,
                "rarity_level": "COMMON"
            }
        }
    },
    "2572": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2572_1681446212.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2572",
                "name": "Frail Badger Cayden #2572",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2572_1681446212.png",
                "rarity_score": 78.70095394541916,
                "rarity_level": "COMMON"
            }
        }
    },
    "2494": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2494_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2494",
                "name": "FUD Badger Jack #2494",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Muscular Black",
                        "identifier": "muscular_black",
                        "trait_type": "Body",
                        "score": 12.206572769953052
                    },
                    {
                        "value": "Normal Face Black",
                        "identifier": "normal_face_black",
                        "trait_type": "Face",
                        "score": 19.402985074626866
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446189,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2494_1681446197.png",
                "rarity_score": 81.93295560695628,
                "rarity_level": "COMMON"
            }
        }
    },
    "2539": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2539_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2539",
                "name": "Fair Badger Langston #2539",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2539_1681446207.png",
                "rarity_score": 71.6168039688604,
                "rarity_level": "COMMON"
            }
        }
    },
    "2589": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2589_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2589",
                "name": "Dull Badger Quentin #2589",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446208,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2589_1681446213.png",
                "rarity_score": 59.056522607560865,
                "rarity_level": "COMMON"
            }
        }
    },
    "2501": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2501_1681446197.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2501",
                "name": "Gentle Badger Slughorn #2501",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    }
                ],
                "create-date": 1681446191,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2501_1681446197.png",
                "rarity_score": 73.82868956891134,
                "rarity_level": "COMMON"
            }
        }
    },
    "2541": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2541_1681446206.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2541",
                "name": "Ashamed Badger Creevy #2541",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Hip Pink Shades",
                        "identifier": "hip_pink_shades",
                        "trait_type": "Glasses",
                        "score": 44.827586206896555
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2541_1681446206.png",
                "rarity_score": 117.23593603018381,
                "rarity_level": "RARE"
            }
        }
    },
    "2582": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2582_1681446211.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2582",
                "name": "Strange Badger Peeves #2582",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Boxing Ring",
                        "identifier": "boxing_ring",
                        "trait_type": "Background",
                        "score": 24.761904761904763
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Smiley Face Beige",
                        "identifier": "smiley_face_beige",
                        "trait_type": "Face",
                        "score": 21.311475409836067
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446206,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2582_1681446211.png",
                "rarity_score": 69.80483996877439,
                "rarity_level": "COMMON"
            }
        }
    },
    "2491": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2491_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2491",
                "name": "Embarrassed Badger Jem #2491",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446189,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2491_1681446198.png",
                "rarity_score": 109.27975505640163,
                "rarity_level": "RARE"
            }
        }
    },
    "2542": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2542_1681446206.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2542",
                "name": "Worried Badger Fred #2542",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Gray",
                        "identifier": "normal_face_gray",
                        "trait_type": "Face",
                        "score": 20.0
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2542_1681446206.png",
                "rarity_score": 77.96339692968907,
                "rarity_level": "COMMON"
            }
        }
    },
    "2584": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2584_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2584",
                "name": "Jolly Badger Phineas #2584",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Regular Beige",
                        "identifier": "regular_beige",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446207,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2584_1681446213.png",
                "rarity_score": 76.46587003265503,
                "rarity_level": "COMMON"
            }
        }
    },
    "2496": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2496_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2496",
                "name": "Wild Badger Dragon #2496",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Max Pain Gloves",
                        "identifier": "max_pain_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 32.5
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446190,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2496_1681446198.png",
                "rarity_score": 110.93033219881332,
                "rarity_level": "RARE"
            }
        }
    },
    "2544": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2544_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2544",
                "name": "Hilarious Badger Diggle #2544",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2544_1681446205.png",
                "rarity_score": 78.16759597511238,
                "rarity_level": "COMMON"
            }
        }
    },
    "2581": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2581_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2581",
                "name": "Poised Badger Gellert #2581",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Bitcoin Bling",
                        "identifier": "bitcoin_bling",
                        "trait_type": "Necklace",
                        "score": 15.2046783625731
                    },
                    {
                        "value": "Honey Gloves",
                        "identifier": "honey_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 21.487603305785125
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446206,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2581_1681446213.png",
                "rarity_score": 92.92044606912997,
                "rarity_level": "RARE"
            }
        }
    },
    "2498": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2498_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2498",
                "name": "Cryptic Badger Alvarez #2498",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Brown",
                        "identifier": "mean_face_brown",
                        "trait_type": "Face",
                        "score": 22.608695652173914
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Aviators",
                        "identifier": "aviators",
                        "trait_type": "Glasses",
                        "score": 38.23529411764706
                    }
                ],
                "create-date": 1681446190,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2498_1681446198.png",
                "rarity_score": 110.67646569510381,
                "rarity_level": "RARE"
            }
        }
    },
    "2545": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2545_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2545",
                "name": "Busy Badger David #2545",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Black",
                        "identifier": "skinny_black",
                        "trait_type": "Body",
                        "score": 12.264150943396226
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Red Bandana",
                        "identifier": "red_bandana",
                        "trait_type": "Headwear",
                        "score": 118.18181818181819
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2545_1681446209.png",
                "rarity_score": 230.9915146665412,
                "rarity_level": "EPIC"
            }
        }
    },
    "2600": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2600_1681446217.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2600",
                "name": "Powerful Badger Hippogriff #2600",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446210,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2600_1681446217.png",
                "rarity_score": 132.88195787704245,
                "rarity_level": "RARE"
            }
        }
    },
    "2502": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2502_1681446198.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2502",
                "name": "Filthy Badger Bowtruckle #2502",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Ecko Bling",
                        "identifier": "ecko_bling",
                        "trait_type": "Necklace",
                        "score": 14.606741573033707
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446191,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2502_1681446198.png",
                "rarity_score": 82.8147968230551,
                "rarity_level": "COMMON"
            }
        }
    },
    "2500": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2500_1681446199.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2500",
                "name": "Amused Badger Blaise #2500",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kandy Red",
                        "identifier": "kandy_red",
                        "trait_type": "Background",
                        "score": 12.440191387559809
                    },
                    {
                        "value": "Skinny Brown",
                        "identifier": "skinny_brown",
                        "trait_type": "Body",
                        "score": 11.555555555555555
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Magma Gloves",
                        "identifier": "magma_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446191,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2500_1681446199.png",
                "rarity_score": 168.9647019793038,
                "rarity_level": "RARE"
            }
        }
    },
    "2550": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2550_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2550",
                "name": "Muddy Badger Heathcliff #2550",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Tired Face Brown",
                        "identifier": "tired_face_brown",
                        "trait_type": "Face",
                        "score": 17.687074829931973
                    },
                    {
                        "value": "Purple Gloves",
                        "identifier": "purple_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.187279151943462
                    },
                    {
                        "value": "Ecko Dex Cap",
                        "identifier": "ecko_dex_cap",
                        "trait_type": "Headwear",
                        "score": 31.325301204819276
                    }
                ],
                "create-date": 1681446199,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2550_1681446207.png",
                "rarity_score": 100.75302903740376,
                "rarity_level": "RARE"
            }
        }
    },
    "2553": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2553_1681446211.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2553",
                "name": "Nasty Badger Snape #2553",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2553_1681446211.png",
                "rarity_score": 133.5720652168208,
                "rarity_level": "RARE"
            }
        }
    },
    "2534": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2534_1681446204.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2534",
                "name": "Embarrassed Badger Edmund #2534",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446197,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2534_1681446204.png",
                "rarity_score": 63.889849230514265,
                "rarity_level": "COMMON"
            }
        }
    },
    "2571": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2571_1681446210.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2571",
                "name": "Panda Badger Darcy #2571",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Purple Nurple",
                        "identifier": "purple_nurple",
                        "trait_type": "Background",
                        "score": 13.0
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Blue Gloves",
                        "identifier": "blue_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.783783783783784
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2571_1681446210.png",
                "rarity_score": 59.93656616612472,
                "rarity_level": "COMMON"
            }
        }
    },
    "2535": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2535_1681446204.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2535",
                "name": "Enchanting Badger Quentin #2535",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Blurple",
                        "identifier": "blurple",
                        "trait_type": "Background",
                        "score": 13.612565445026178
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Silly Face Gray",
                        "identifier": "silly_face_gray",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2535_1681446204.png",
                "rarity_score": 60.58287197776526,
                "rarity_level": "COMMON"
            }
        }
    },
    "2573": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2573_1681446209.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2573",
                "name": "Fierce Badger Wladimir #2573",
                "skills": [
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Mint Madness",
                        "identifier": "mint_madness",
                        "trait_type": "Background",
                        "score": 12.871287128712872
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Silly Face Beige",
                        "identifier": "silly_face_beige",
                        "trait_type": "Face",
                        "score": 22.033898305084747
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2573_1681446209.png",
                "rarity_score": 56.229805768203406,
                "rarity_level": "COMMON"
            }
        }
    },
    "2540": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2540_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2540",
                "name": "Dopey Badger Grawp #2540",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Red Envy",
                        "identifier": "red_envy",
                        "trait_type": "Background",
                        "score": 13.471502590673575
                    },
                    {
                        "value": "Muscular Brown",
                        "identifier": "muscular_brown",
                        "trait_type": "Body",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Normal Face Brown",
                        "identifier": "normal_face_brown",
                        "trait_type": "Face",
                        "score": 18.978102189781023
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2540_1681446205.png",
                "rarity_score": 62.652870739116246,
                "rarity_level": "COMMON"
            }
        }
    },
    "2575": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2575_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2575",
                "name": "Delightful Badger Santiago #2575",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Silly Face Black",
                        "identifier": "silly_face_black",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2575_1681446213.png",
                "rarity_score": 75.93387236048176,
                "rarity_level": "COMMON"
            }
        }
    },
    "2530": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2530_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2530",
                "name": "Courageous Badger Wilbur #2530",
                "skills": [
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    },
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Muscular Gray",
                        "identifier": "muscular_gray",
                        "trait_type": "Body",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Mean Face Gray",
                        "identifier": "mean_face_gray",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Red Gloves",
                        "identifier": "red_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.66542750929368
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Technicolor Shades",
                        "identifier": "technicolor_shades",
                        "trait_type": "Glasses",
                        "score": 42.622950819672134
                    }
                ],
                "create-date": 1681446196,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2530_1681446205.png",
                "rarity_score": 131.55534586098784,
                "rarity_level": "RARE"
            }
        }
    },
    "2577": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2577_1681446214.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2577",
                "name": "Misty Badger Cannon #2577",
                "skills": [
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    },
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Regular Brown",
                        "identifier": "regular_brown",
                        "trait_type": "Body",
                        "score": 12.322274881516588
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Smiley Face Brown",
                        "identifier": "smiley_face_brown",
                        "trait_type": "Face",
                        "score": 23.853211009174313
                    },
                    {
                        "value": "Kadena Bling",
                        "identifier": "kadena_bling",
                        "trait_type": "Necklace",
                        "score": 14.689265536723164
                    },
                    {
                        "value": "Orange Gloves",
                        "identifier": "orange_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.523809523809524
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    },
                    {
                        "value": "Hip White Shades",
                        "identifier": "hip_white_shades",
                        "trait_type": "Glasses",
                        "score": 40.0
                    }
                ],
                "create-date": 1681446205,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2577_1681446214.png",
                "rarity_score": 123.82836223299103,
                "rarity_level": "RARE"
            }
        }
    },
    "2551": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2551_1681446205.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2551",
                "name": "Bookworm Badger Percy #2551",
                "skills": [
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Frozen Heart Gloves",
                        "identifier": "frozen_heart_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 25.49019607843137
                    }
                ],
                "create-date": 1681446200,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2551_1681446205.png",
                "rarity_score": 75.02298601645988,
                "rarity_level": "COMMON"
            }
        }
    },
    "2580": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2580_1681446212.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2580",
                "name": "Bashful Badger Asher #2580",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Honey Badger Yellow",
                        "identifier": "honey_badger_yellow",
                        "trait_type": "Background",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Tired Face Beige",
                        "identifier": "tired_face_beige",
                        "trait_type": "Face",
                        "score": 26.0
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Nose Ring",
                        "identifier": "nose_ring",
                        "trait_type": "Nose Ring",
                        "score": 8.58085808580858
                    }
                ],
                "create-date": 1681446206,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2580_1681446212.png",
                "rarity_score": 67.29185821531209,
                "rarity_level": "COMMON"
            }
        }
    },
    "2554": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2554_1681446206.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2554",
                "name": "Yucky Badger Holm #2554",
                "skills": [
                    {
                        "id": "stamina_regeneration",
                        "name": "Stamina Regeneration"
                    },
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    }
                ],
                "create-date": 1681446201,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2554_1681446206.png",
                "rarity_score": 67.64637925701962,
                "rarity_level": "COMMON"
            }
        }
    },
    "2585": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2585_1681446214.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2585",
                "name": "Whale Badger Werner #2585",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "physical_damage",
                        "name": "Physical Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Kadena Pink",
                        "identifier": "kadena_pink",
                        "trait_type": "Background",
                        "score": 12.935323383084578
                    },
                    {
                        "value": "Skinny Beige",
                        "identifier": "skinny_beige",
                        "trait_type": "Body",
                        "score": 11.659192825112108
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Normal Face Beige",
                        "identifier": "normal_face_beige",
                        "trait_type": "Face",
                        "score": 20.47244094488189
                    },
                    {
                        "value": "Yellow Gloves",
                        "identifier": "yellow_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.30670926517572
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    }
                ],
                "create-date": 1681446207,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2585_1681446214.png",
                "rarity_score": 70.3678564387601,
                "rarity_level": "COMMON"
            }
        }
    },
    "2538": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2538_1681446207.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2538",
                "name": "Uptight Badger Georgie #2538",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "dodge_enhancement",
                        "name": "Dodge Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Sky Blue",
                        "identifier": "sky_blue",
                        "trait_type": "Background",
                        "score": 14.444444444444445
                    },
                    {
                        "value": "Regular Black",
                        "identifier": "regular_black",
                        "trait_type": "Body",
                        "score": 13.402061855670103
                    },
                    {
                        "value": "Dark Tattoos",
                        "identifier": "dark_tattoos",
                        "trait_type": "Tattoos",
                        "score": 8.441558441558442
                    },
                    {
                        "value": "Tired Face Black",
                        "identifier": "tired_face_black",
                        "trait_type": "Face",
                        "score": 19.548872180451127
                    },
                    {
                        "value": "Cigarette",
                        "identifier": "cigarette",
                        "trait_type": "Cigarette",
                        "score": 46.42857142857143
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Beanie",
                        "identifier": "beanie",
                        "trait_type": "Headwear",
                        "score": 4.459691252144082
                    }
                ],
                "create-date": 1681446198,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2538_1681446207.png",
                "rarity_score": 116.57368445132447,
                "rarity_level": "RARE"
            }
        }
    },
    "2587": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2587_1681446215.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2587",
                "name": "Anal Badger Greyback #2587",
                "skills": [
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    },
                    {
                        "id": "speed_enhancement",
                        "name": "Speed Enhancement"
                    }
                ],
                "attributes": [
                    {
                        "value": "Life is Peachy",
                        "identifier": "life_is_peachy",
                        "trait_type": "Background",
                        "score": 14.525139664804469
                    },
                    {
                        "value": "Regular Gray",
                        "identifier": "regular_gray",
                        "trait_type": "Body",
                        "score": 13.131313131313131
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Smiley Face Gray",
                        "identifier": "smiley_face_gray",
                        "trait_type": "Face",
                        "score": 23.636363636363637
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Hip Yellow Shades",
                        "identifier": "hip_yellow_shades",
                        "trait_type": "Glasses",
                        "score": 41.935483870967744
                    }
                ],
                "create-date": 1681446207,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2587_1681446215.png",
                "rarity_score": 125.60791135453711,
                "rarity_level": "RARE"
            }
        }
    },
    "2592": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2592_1681446213.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2592",
                "name": "Lazy Badger Prongs #2592",
                "skills": [
                    {
                        "id": "hp_regeneration",
                        "name": "HP Regeneration"
                    },
                    {
                        "id": "physical_resistance",
                        "name": "Physical Resistance"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Skinny Gray",
                        "identifier": "skinny_gray",
                        "trait_type": "Body",
                        "score": 12.745098039215685
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Tired Face Gray",
                        "identifier": "tired_face_gray",
                        "trait_type": "Face",
                        "score": 22.80701754385965
                    },
                    {
                        "value": "Teal Gloves",
                        "identifier": "teal_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 8.96551724137931
                    }
                ],
                "create-date": 1681446208,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2592_1681446213.png",
                "rarity_score": 64.88307959747101,
                "rarity_level": "COMMON"
            }
        }
    },
    "2482": {
        "uri": {
            "data": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/2482_1681446694.png",
            "scheme": "https"
        },
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "id": "2482",
                "name": "Defiant Badger Morrie #2482",
                "skills": [
                    {
                        "id": "elemental_resistance",
                        "name": "Elemental Resistance"
                    },
                    {
                        "id": "elemental_damage",
                        "name": "Elemental Damage"
                    }
                ],
                "attributes": [
                    {
                        "value": "Greenscreen",
                        "identifier": "greenscreen",
                        "trait_type": "Background",
                        "score": 14.130434782608695
                    },
                    {
                        "value": "Muscular Beige",
                        "identifier": "muscular_beige",
                        "trait_type": "Body",
                        "score": 14.207650273224044
                    },
                    {
                        "value": "Light Tattoos",
                        "identifier": "light_tattoos",
                        "trait_type": "Tattoos",
                        "score": 13.978494623655914
                    },
                    {
                        "value": "Black Sweater",
                        "identifier": "black_sweater",
                        "trait_type": "Sweater",
                        "score": 6.235011990407674
                    },
                    {
                        "value": "Mean Face Beige",
                        "identifier": "mean_face_beige",
                        "trait_type": "Face",
                        "score": 20.8
                    },
                    {
                        "value": "Pink Gloves",
                        "identifier": "pink_gloves",
                        "trait_type": "Boxing Gloves",
                        "score": 9.848484848484848
                    },
                    {
                        "value": "Earring",
                        "identifier": "earring",
                        "trait_type": "Earring",
                        "score": 8.552631578947368
                    },
                    {
                        "value": "Tophat",
                        "identifier": "tophat",
                        "trait_type": "Headwear",
                        "score": 45.6140350877193
                    },
                    {
                        "value": "3D Glasses",
                        "identifier": "3d_glasses",
                        "trait_type": "Glasses",
                        "score": 44.067796610169495
                    }
                ],
                "create-date": 1681446683,
                "description": "This Boxing Badger is ready to compete and become the new king of the ring!",
                "thumbnailUrl": "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/thumbnails/thumb_2482_1681446694.png",
                "rarity_score": 177.43453979521732,
                "rarity_level": "RARE"
            }
        }
    }
}

export {
  boxingBadgerMetadata
}
import { Flex, Text, Switch } from "@chakra-ui/react";
import React, { memo } from "react";

const FilterToggle = memo(
    ({ label, toggleCallback, toggleState, ...props }) => {
        return (
            <Flex
                align="center"
                justifyContent={"space-between"}
                py="8px"
                {...props}
            >
                <Text fontSize="16px" fontWeight="700">
                    {label}
                </Text>
                <Switch
                    // defaultChecked={isChecked}
                    checked={toggleState}
                    onChange={(e) => toggleCallback(e.target.checked)}
                />
            </Flex>
        );
    }
);

export default FilterToggle;

import { toast } from "react-toastify";
import {
    NETWORK_ID,
    KDA_CHECK_STATUS,
    KDA_CONNECT,
    KDA_DISCONNECT,
    KDA_GET_SELECTED_ACCOUNT,
    KDA_REQUEST_ACCOUNT,
    KDA_REQUEST_SIGN,
    KDA_GET_NETWORK,
    KDA_GET_CHAIN,
    KDA_QUICKSIGN,
    ECKO_WALLET_NAME
} from "../utils/Constants";

//Attempt to connect application to Kadena eckoWALLET extension
async function connectKadena(netId) {

    //Initiate KDA connect
    const response = await window.kadena.request({
        method: KDA_CONNECT,
        networkId: NETWORK_ID
    }).catch((e) => {
        console.error(e.message)
        return;
    });

    return response;
}

async function connectKoala(netId) {

    //Initiate KDA connect
    const response = await window.koala.request({
        method: KDA_CONNECT,
        networkId: NETWORK_ID
    }).catch((e) => {
        console.error(e.message)
        return;
    });

    return response;
}

//Check the user's Kadena extenstion connection status
async function getKadenaConnectStatus(netId) {
    const response = await window.kadena.request({
        method: KDA_CHECK_STATUS,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });
    return response;
}

async function requestKadenaAccount(netId) {
    const response = await window.kadena.request({
        method: KDA_REQUEST_ACCOUNT,
        networkId: NETWORK_ID,
    })
        .catch((e) => {
            console.error(e.message);
            toast.error("Trouble Connecting to your EckoWallet. Please update it, disconnect, reconnect then try again")
            return;
        });
    return response;
}

async function requestSign(netId, dataToSign) {
    const response = await window.kadena.request({
        method: KDA_REQUEST_SIGN,
        networkId: netId,
        data: dataToSign
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

async function getSelectedAccount(netId) {
    const response = await window.kadena.request({
        method: KDA_GET_SELECTED_ACCOUNT,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

//Disconnect the user's eckoWALLET account from this application
async function disconnectKadena(netId) {
    const response = await window.kadena.request({
        method: KDA_DISCONNECT,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });
    return response;
}

async function getNetwork() {
    const response = await window.kadena.request({
        method: KDA_GET_NETWORK
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

async function getChain() {
    const response = await window.kadena.request({
        method: KDA_GET_CHAIN
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

async function quickSignEcko(cmds) {
    console.log(cmds)
    let signedCmds = [];
    let sigDatas = cmds.map((cmd) => {
        let obj = {
            sigs: [
                {
                    pubKey: cmd["pubKey"]
                }
            ],
            cmd: JSON.stringify(cmd["cmd"])
        }
        return obj;
    });

    let cmdData = {
        networkId: NETWORK_ID,
        commandSigDatas: sigDatas
    }
    console.log(cmdData)
    const response = await window.kadena.request({
        method: KDA_QUICKSIGN,
        data: cmdData
    })
        .catch((e) => {
            console.error(e.message);
            toast.error("Ecko Wallet Failed to QuickSign")
            return { status: "failure", data: "Exception: " + e.message, wallet: ECKO_WALLET_NAME };
        });
    console.log(response)

    if (response.status === "success") {
        let quickSignDataWithHash = response.quickSignData.map((qsData) => {
            qsData["commandSigData"]["hash"] = qsData["outcome"]["hash"];
            return qsData;
        });
        signedCmds = quickSignDataWithHash;
    } else {
        return { status: "failure", data: "eckoWallet Failure" }
    }

    console.log("NEW QUICKSIGN COMMANDS")
    console.log(signedCmds)
    return { status: "success", data: signedCmds, wallet: ECKO_WALLET_NAME };
}

export {
    getChain,
    getNetwork,
    requestSign,
    connectKoala,
    quickSignEcko,
    connectKadena,
    disconnectKadena,
    getSelectedAccount,
    requestKadenaAccount,
    getKadenaConnectStatus,
}
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Checkbox,
    Flex,
    Icon,
    Image,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    Text,
} from "@chakra-ui/react";
import {
    BLOCKS_IN_A_DAY,
    BOXING_BADGER_PROJECT_NAME_KEY,
    CONNECTED_WALLET_TYPE,
    FEE_PERCENTAGE,
    ISOKO,
    KADCARS_PROJECT_NAME_KEY,
    MARMALADE_TYPE,
    PLATFORM,
    ROYALTY_INFO_KEY,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
    STACKED_TYPE,
    ZELCORE_WALLET_NAME,
} from "../utils/Constants";
import { BsInfoCircleFill, BsShareFill } from "react-icons/bs";
import React, { useContext, useEffect, useState } from "react";
import {
    checkIfNullOrUndefined,
    tryLoadLocal,
    useGetNftDetails,
    useGetNftExpired,
} from "../utils/utils";
import {
    getBlockHeightForChain,
    useGetBlockHeightForChain,
} from "../pact/PactUtils";

import CustomCalendar from "./CustomCalendar";
import { FiChevronLeft } from "react-icons/fi";
import { PactContext } from "../pact/PactContextProvider";
import { toast } from "react-toastify";

let darkMode = true;
const dark = "#29242B";
const light = "rgba(189, 61, 240, 0.3)";

const NftListPageHero = ({
    nftData,
    collectionData,
    backButtonCallback,
    actionButtonCallback,
}) => {
    const { account } = useContext(PactContext);
    const nftExpired = useGetNftExpired(nftData);
    const [listPrice, setListPrice] = useState(null);
    const [expireDays, setExpireDays] = useState("");
    const [withdrawable, setWithdrawable] = useState(false);
    const [currentHeight, setCurrentHeight] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [receiverWalletAddress, setReceiverWalletAddress] = useState(null);

    useEffect(() => {
        isWithdrawDisabled();
    }, [nftExpired]);

    useEffect(() => {
        async function updateExpiration() {
            let currHeight = await getBlockHeightForChain(nftData["chain-id"]);
            let date = getExpirationDate(
                nftData["listing-details"]["timeout"],
                currHeight
            );
            setCurrentHeight(currHeight)
            setExpirationDate(date);
        }
        if (
            !checkIfNullOrUndefined(nftData) &&
            !checkIfNullOrUndefined(nftData["listing-details"]) &&
            (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE)
        ) {
            updateExpiration();
        }
    }, [nftData, setExpirationDate, setCurrentHeight]);

    async function handleActionButtonClick(action) {
        let newNftData = null;
        if (
            nftData["policy-info"]["collection-name"] === SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY
        ) {
            if (action !== "transfer") {
                toast.error("This NFT cannot be listed at this time");
                return;
            }
        }
        if (
            action === "list" &&
            (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE) &&
            expireDays === ""
        ) {
            toast.error("Please specify the number of days");
            return;
        }
        let timeData = await getBlockHeightForChain(nftData["chain-id"]);
        // timeData = timeData + parseInt(expireDays) * BLOCKS_IN_A_DAY;
        timeData = timeData + expireDays * 120;
        // timeData = timeData + 10; //TODO: REMOVE
        console.log(timeData);
        console.log(expireDays);

        if (listPrice || receiverWalletAddress) {
            newNftData = {
                price: parseFloat(listPrice),
                nftId: nftData["nftId"],
                receiver: receiverWalletAddress,
                timeout: timeData,
            };
        }
        actionButtonCallback(action, newNftData);
    }

    function calculateExpireDays(calendarValue) {
        let value = new Date(calendarValue) - Date.now();
        let convertedValue = Math.ceil(parseFloat(value) / 3600000.0);

        console.log(convertedValue);
        setExpireDays(convertedValue);
    }

    function getExpirationDate(blockTimeout, currentHeight) {
        let diff = blockTimeout - currentHeight;
        let minLeft = diff / 2;
        let today = Date.now();
        let newDate = new Date(today);
        newDate.setMinutes(newDate.getMinutes() + minLeft);
        let dateString = newDate.toDateString();

        return dateString;
    }

    function getWithdrawButtonText() {
        let text = "Cancel Listing";

        if (!checkIfNullOrUndefined(nftData)) {
            if (
                nftData["policy-info"]["collection-name"] === SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY ||
                nftData["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY ||
                tryLoadLocal(CONNECTED_WALLET_TYPE) === ZELCORE_WALLET_NAME
            ) {
                return "This NFT cannot be listed at this time";
            }
            if (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE) {
                if (nftExpired) {
                    text = "Cancel Listing";
                } else {
                    text = `You can withdraw this NFT after ${expirationDate}`;
                }
            }
        }

        return text;
    }

    function isWithdrawDisabled() {
        if (!checkIfNullOrUndefined(nftData)) {
            if (
                nftData["policy-info"]["collection-name"] === SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY ||
                nftData["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY
            ) {
                setWithdrawable(false);
            }
            if (nftData["standard"] === MARMALADE_TYPE || nftData["standard"] === STACKED_TYPE) {
                if (nftExpired) {
                    setWithdrawable(true);
                } else {
                    setWithdrawable(false);
                }
            } else {
                setWithdrawable(true);
            }
        }
    }

    return (
        <Box py="60px">
            <SimpleGrid columns={[1, 1, 2]} spacing="50px">
                <Box maxW="600px">
                    {/* <Flex align="center" justify="space-between" gap="10px">
                        <Flex
                            gap="10px"
                            justify="flex-start"
                            align={"center"}
                            onClick={backButtonCallback}
                            style={{ cursor: "pointer", width: "fit-content" }}
                            pb={["2.3rem"]}
                        >
                            <Icon as={FiChevronLeft} boxSize={"1.25rem"} display={"inline-block"} />
                            <Text>
                                Back to Collection
                            </Text>
                        </Flex>
                    </Flex> */}
                    <Box
                        // mt="37px"
                        // bgColor="#160D1A"
                        p="20px"
                        rounded="8px"
                        // style={{ border: "1px solid #5E1E78" }}
                        bgColor={darkMode ? "#160D1A" : light}
                        style={{
                            border: `0.5px solid ${
                                darkMode ? "#5E1E78" : "#5E1E78"
                            }`,
                        }}
                    >
                        <Flex align="center" justify="space-between" gap="10px">
                            <Text fontSize="28px" fontWeight="900">
                                {nftData && nftData["name"]}
                            </Text>

                            {/* <Icon
                                as={BsShareFill}
                                color="#fff"
                                cursor="pointer"
                            /> */}
                        </Flex>

                        <Flex
                            justify={"space-between"}
                            gap="1rem"
                            align={"center"}
                        >
                            <Text mt="23px">
                                Collection:{" "}
                                <Text as="strong" color="#BC3CF0">
                                    {nftData && nftData["collection-name"]}
                                </Text>{" "}
                            </Text>

                            <Text fontWeight="700" mt="23px">
                                Status:{" "}
                                {nftData &&
                                (!nftData["price"] ||
                                    nftData["price"] === -1 ||
                                    nftData["price"] === undefined)
                                    ? "NFT not listed"
                                    : nftExpired
                                    ? "Listing expired"
                                    : "This NFT is already listed"}
                            </Text>
                        </Flex>

                        <Box w="100%">
                            <Flex gap="30px" mt="23px" w="100%">
                                {nftData &&
                                    (nftData["listing-details"] &&
                                    nftData["listing-details"]["price"] &&
                                    nftData["listing-details"]["price"] !==
                                        -1 ? (
                                        <Box
                                            borderRadius="8px"
                                            py="22px"
                                            maxW="717px"
                                            w="100%"
                                        >
                                            <Button
                                                outline={"none"}
                                                border="2px"
                                                borderColor="#BD3DF0"
                                                w="100%"
                                                py="20px"
                                                bgColor="#BC3CF0"
                                                _hover={{
                                                    bgColor:
                                                        "rgba(189, 61, 240, 0.33)",
                                                }}
                                                mb="28px"
                                                onClick={() =>
                                                    handleActionButtonClick(
                                                        "view"
                                                    )
                                                }
                                                rounded={"full"}
                                            >
                                                <span
                                                    style={{ color: "white" }}
                                                >
                                                    View in Marketplace
                                                </span>
                                            </Button>
                                            {nftData["current-owner"] ===
                                                account?.account && (
                                                <Button
                                                    outline={"none"}
                                                    border="2px"
                                                    borderColor="#BD3DF0"
                                                    w="100%"
                                                    py="20px"
                                                    bgColor="transparent"
                                                    _hover={{
                                                        bgColor:
                                                            "rgba(189, 61, 240, 0.33)",
                                                    }}
                                                    mb="28px"
                                                    onClick={() => {
                                                        handleActionButtonClick(
                                                            "withdraw"
                                                        );
                                                    }}
                                                    rounded={"full"}
                                                    isDisabled={!withdrawable}
                                                >
                                                    <span
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        {getWithdrawButtonText()}
                                                    </span>
                                                </Button>
                                            )}
                                        </Box>
                                    ) : (
                                        <>
                                            {nftData["current-owner"] && (
                                                <>
                                                    {nftData[
                                                        "current-owner"
                                                    ] === account?.account ? (
                                                        <ListNftField
                                                            nftData={nftData}
                                                            standard={
                                                                nftData[
                                                                    "standard"
                                                                ]
                                                            }
                                                            calculateExpireDays={
                                                                calculateExpireDays
                                                            }
                                                            setListPrice={
                                                                setListPrice
                                                            }
                                                            handleActionButtonClick={
                                                                handleActionButtonClick
                                                            }
                                                        />
                                                    ) : account ? (
                                                        <Text>
                                                            You don't own this
                                                            NFT
                                                        </Text>
                                                    ) : (
                                                        <Text>
                                                            Connect Your Wallet
                                                        </Text>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ))}
                            </Flex>
                        </Box>
                    </Box>
                    <Box>
                        {nftData &&
                            nftData["current-owner"] === account?.account && (
                                <TransferCard
                                    nftData={nftData}
                                    nftExpired={nftExpired}
                                    setReceiverWalletAddress={
                                        setReceiverWalletAddress
                                    }
                                    handleActionButtonClick={
                                        handleActionButtonClick
                                    }
                                />
                            )}
                    </Box>
                    <Box>
                        <AboutCard nftData={nftData} account={account} />
                    </Box>
                </Box>
                <Box>
                    <Image
                        src={nftData && nftData["nft-uri"]}
                        maxH="707px"
                        h="707px"
                        w="100%"
                        objectFit="cover"
                        alt="Hero Image"
                    />
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default NftListPageHero;

const ListNftField = ({
    nftData,
    standard,
    setListPrice,
    calculateExpireDays,
    handleActionButtonClick,
}) => {
    const [listable, setListable] = useState(false);

    useEffect(() => {
        console.log(nftData)
        if (nftData && !nftData["has_marketplace"] || tryLoadLocal(CONNECTED_WALLET_TYPE) === ZELCORE_WALLET_NAME) {
            setListable(false);
        } else {
            setListable(true);
        }
    }, [nftData])

    function getText() {
        if (nftData && !nftData["has_marketplace"]) {
            return "You can't list this NFT at this time"
        } else {
            return "List Now"
        }
    }

    function checkListable() {
        if (nftData && !nftData["has_marketplace"]) {
            console.log(nftData)
            return false;
        } else {
            return true;
        }
    }

    return (
        <Box borderRadius="8px" py="22px" maxW="717px" w="100%">
            <Flex marginTop={"0px"} gap="20px">
                <Input
                    outline={"none"}
                    border="1px"
                    borderColor="#5E1E78"
                    w="100%"
                    maxW={"292px"}
                    // py="20px"
                    h="2.5rem"
                    bgColor="transparent"
                    color="#fff"
                    _hover={{ outline: "none" }}
                    _focus={{}}
                    _focusVisible={{}}
                    // mb="28px"
                    isDisabled={!listable}
                    placeholder={listable ? "Enter listing price (KAD)" : "Not Listable"}
                    onChange={(e) => setListPrice(e.target.value)}
                />
            </Flex>
            {(standard === MARMALADE_TYPE || standard === STACKED_TYPE) && listable && (
                <Box pos={"relative"}>
                    <CustomCalendar callback={(e) => calculateExpireDays(e)} />
                </Box>
            )}
            {!true ? (
                <Box mt="0.75rem">
                    <Checkbox colorScheme="" defaultChecked fontSize="1rem">
                        Auto Withdrawal
                    </Checkbox>
                </Box>
            ) : (
                ""
            )}
            <Button
                mt="1.5rem"
                border="2px"
                borderColor="#BD3DF0"
                w="100%"
                py="20px"
                bgColor="#BC3CF0"
                _hover={{
                    bgColor: "rgba(189, 61, 240, 0.33)",
                }}
                onClick={() => handleActionButtonClick("list")}
                rounded={"full"}
                isDisabled={!listable}
            >
                <span style={{ "color": "white" }}>
                    {nftData && getText()}
                </span>
            </Button>
        </Box>
    );
};

const TransferCard = ({
    nftData,
    setReceiverWalletAddress,
    handleActionButtonClick,
}) => {
    const [nftTransferrable, setNftTransferrable] = useState(true);

    useEffect(() => {
        if (!checkIfNullOrUndefined(nftData)) {
            let temp = getNftTransferrable();
            setNftTransferrable(temp);
        }
    }, [nftData]);

    function getNftTransferrable() {
        let transferrable = false;

        // if (!checkIfNullOrUndefined(nftData["listing-details"]) || nftData["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY) {
        if (!checkIfNullOrUndefined(nftData["listing-details"])) {
            transferrable = false;
        } else {
            transferrable = true;
        }

        return transferrable;
    }

    return (
        <Box
            mt="30px"
            // bgColor="#160D1A"
            // border="1px"
            // borderColor="#5E1E78"
            bgColor={darkMode ? "#160D1A" : light}
            style={{
                border: `0.5px solid ${darkMode ? "#5E1E78" : "#5E1E78"}`,
            }}
            rounded="8px"
            overflow={"hidden"}
            px="20px"
        >
            <h2 m="0">
                <Flex align="center" justify="space-between" gap="10px">
                    <Text
                        as="span"
                        fontSize="20px"
                        fontWeight="700"
                        flex="1"
                        textAlign="left"
                    >
                        Transfer to Wallet Address
                    </Text>
                </Flex>
            </h2>
            <Input
                outline={"none"}
                border="2px"
                borderColor="#5E1E78"
                w="100%"
                py="20px"
                bgColor="transparent"
                color="#fff"
                _focus={{}}
                _active={{}}
                _hover={{}}
                _focusVisible={{}}
                mb="28px"
                placeholder={nftTransferrable ? "Enter destination address" : "Not transferrable"}
                isDisabled={!nftTransferrable}
                onChange={(e) => setReceiverWalletAddress(e.target.value)}
            />
            <Button
                outline={"none"}
                border="2px"
                borderColor="#BD3DF0"
                w="100%"
                py="20px"
                bgColor="#BC3CF0"
                _hover={{
                    bgColor: "rgba(189, 61, 240, 0.33)",
                }}
                mb="28px"
                onClick={() => handleActionButtonClick("transfer")}
                rounded={"full"}
                isDisabled={!nftTransferrable}
            >
                <span style={{ "color": "white" }}>
                    {nftTransferrable
                        ? "Transfer NFT"
                        : "This NFT cannot be transferred at this time"}
                </span>
            </Button>
        </Box>
    );
};

const AboutCard = ({ nftData }) => {
    const details = useGetNftDetails(nftData);
    const [traits, setTraits] = useState(null);

    useEffect(() => {
        !checkIfNullOrUndefined(nftData) && getNftTraits();
    }, [nftData]);

    function getNftTraits() {
        let traits = [];
        if (!nftData) {
            return traits;
        }

        nftData["collection_traits"].forEach((traitName) => {
            if (!checkIfNullOrUndefined(nftData["formatted_attributes"][traitName])) {
                traits.push({
                    name: nftData["formatted_attributes"][traitName]["name"],
                    frequency: nftData["formatted_attributes"][traitName]["frequency"]
                })
            }
        });

        setTraits(traits);
    }

    return (
        <Box
            mt="30px"
            bgColor="#160D1A"
            border="1px"
            borderColor="#5E1E78"
            borderRadius="8px"
            overflow={"hidden"}
        >
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem borderTop="0px">
                    <h2>
                        <AccordionButton
                            p="10px"
                            px="16px"
                            _hover={{ bgColor: "button" }}
                            className="button"
                        >
                            <Text
                                as="span"
                                fontSize="20px"
                                fontWeight="700"
                                flex="1"
                                textAlign="left"
                            >
                                About {nftData && nftData["collection_name"]}
                            </Text>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize="14px" pt="0" mt="0">
                        {nftData && nftData["project_description_summary"]}
                    </AccordionPanel>
                </AccordionItem>

                {/* trwo  */}
                <AccordionItem borderBottom={"0px"} borderTopColor={"#5E1E78"}>
                    <h2>
                        <AccordionButton p="10px" px="16px" className="button">
                            <Text
                                as="span"
                                fontSize="20px"
                                fontWeight="700"
                                flex="1"
                                textAlign="left"
                            >
                                Attributes
                            </Text>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize="14px" pt="0" mt="0">
                        {/* I have make and coment out the old version of this component At the end of this code   */}
                        <SimpleGrid columns={[2, 3]} spacing="20px" mt="20px">
                            {traits?.map((traitObj, index) => {
                                return (
                                    <Box
                                        key={index}
                                        rounded="8px"
                                        borderColor="#5E1E78"
                                        bgColor={darkMode ? dark : light}
                                        style={{
                                            border: `0.5px solid ${
                                                darkMode ? "#757575" : "#5E1E78"
                                            }`,
                                        }}
                                        p="8px"
                                    >
                                        <Text fontWeight="900">
                                            {traitObj["name"]}
                                        </Text>
                                        <Text fontSize="14px" fontWeight="400">
                                            {traitObj["frequency"]}% have this
                                            trait
                                        </Text>
                                    </Box>
                                );
                            })}
                        </SimpleGrid>
                    </AccordionPanel>
                </AccordionItem>

                {/* // three */}
                <AccordionItem borderBottom={"0px"} borderTopColor={"#5E1E78"}>
                    <h2>
                        <AccordionButton p="10px" px="16px" className="button">
                            <Text
                                as="span"
                                fontSize="20px"
                                fontWeight="700"
                                flex="1"
                                textAlign="left"
                            >
                                Details
                            </Text>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize="14px" pt="0" mt="0">
                        <Box>
                            {details.map((item, i) => {
                                return (
                                    <Flex
                                        key={i}
                                        align="center"
                                        justify="space-between"
                                        mb="8px"
                                        gap="20px"
                                    >
                                        <Text fontSize="12px">
                                            {item.itemName}
                                        </Text>
                                        <Text fontSize="16px" fontWeight="700">
                                            {item.value}
                                        </Text>
                                    </Flex>
                                );
                            })}
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    );
};

import { Box, Flex, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import ScreenSize from "../layouts/ScreenSize";

const EZ_BUY_DONE_STEP = "done";
const EZ_BUY_FAIL_STEP = "fail";
const EZ_BUY_CURRENT_STEP = "current";
const EZ_BUY_NEUTRAL_STEP = "neutral";

const EZBuyProgressBar = ({ data, status, currentStep, transactionStepData }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const paymentData = [
        { label: "Transactions submitted", value: "1", status: "done" },
        { label: "Signing transactions", value: "2", status: "fail" },
        { label: "Transferring funds", value: "3", status: "current" },
        { label: "Finalising transfer", value: "4", status: "" },
        { label: "Executing Business Transaction", value: "5", status: "" },
    ];

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    // const getStatusColor = (status) => {
    //     switch (status) {
    //         case "done":
    //             return "green.500";
    //         case "fail":
    //             return "red.500";
    //         case "current":
    //             return "purple.500";
    //         default:
    //             return "gray.200";
    //     }
    // };

    return (
        <ScreenSize w="100%" pos="relative">
            <Box pos="relative">
                <Flex
                    pos="fixed"
                    w="100%"
                    maxW={isCollapsed ? "110px" : "500px"}
                    bgColor="#160D1A"
                    borderLeft="4px"
                    borderColor="#BC3CF0"
                    pt="30px"
                    bottom="64px"
                    right="64px"
                    borderRadius="8px"
                    pb="20px"
                    justify={"center"}
                    align={"center"}
                    transition="all 0.3s ease-in-out"
                    overflowX={"hidden"}
                >
                    <Icon
                        as={isCollapsed ? BiChevronsLeft : BiChevronsRight}
                        position={"absolute"}
                        top={"12px"}
                        left={"10px"}
                        boxSize={"20px"}
                        cursor={"pointer"}
                        onClick={handleCollapse}
                    />
                    {/* <Box
                display="flex"
                alignItems="center"
                w="100%"
                justifyContent={"center"}
            >
                {transactionStepData.map((stage) => {
                    return (
                        <Flex
                            key={stage && stage.value}
                            flexDirection="column"
                            align="center"
                            position="relative"
                            maxWidth="82px"
                            width="100%"
                        >
                            <Box position="relative">
                                <Box
                                    width="30.18px"
                                    height="30.18px"
                                    borderRadius="full"
                                    bg={
                                        stage.status === "done"
                                            ? "#00994F"
                                            : stage.status === "current"
                                                ? "#BC3CF0"
                                                : stage.status === "fail"
                                                    ? "red.500"
                                                    : "#9E9E9E"
                                    }
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    zIndex={10}
                                    pos={"relative"}
                                    fontSize="12px"
                                >
                                    {stage && stage.status === "fail"
                                        ? "x"
                                        : stage.value}
                                </Box>
                                {paymentData.length != stage.value && (
                                    <Box
                                        width="74px"
                                        h="4px"
                                        position="absolute"
                                        right="-57px"
                                        top="50%"
                                        transform="translateY(-50%)"
                                        zIndex={1}
                                        bgColor={
                                            stage.status === "done"
                                                ? "#00994F"
                                                : stage.status === "current"
                                                    ? "#BC3CF0"
                                                    : stage.status === "fail"
                                                        ? "red.500"
                                                        : "#9E9E9E"
                                        }
                                    />
                                )}
                            </Box>
                            <Box
                                textAlign="center"
                                marginTop="1"
                                fontSize={"12px"}
                                h="38px"
                            >
                                {stage && stage.label}
                            </Box>
                        </Flex>
                    );
                })}
            </Box> */}
                    {
                        !isCollapsed && <PaymentProgress transactionStepData={transactionStepData} paymentData={paymentData} />
                    }

                    {isCollapsed && <SingleCurrentProgress />}
                </Flex>
            </Box>

        </ScreenSize>
    );
};

const PaymentProgress = ({ transactionStepData, paymentData }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            w="100%"
            justifyContent={"center"}
        >
            {transactionStepData.map((stage) => {
                return (
                    <Flex
                        key={stage && stage.value}
                        flexDirection="column"
                        align="center"
                        position="relative"
                        maxWidth="82px"
                        width="100%"
                    >
                        <Box position="relative">
                            <Box
                                width="30.18px"
                                height="30.18px"
                                borderRadius="full"
                                bg={
                                    stage.status === "done"
                                        ? "#00994F"
                                        : stage.status === "current"
                                            ? "#BC3CF0"
                                            : stage.status === "fail"
                                                ? "red.500"
                                                : "#9E9E9E"
                                }
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                zIndex={10}
                                pos={"relative"}
                                fontSize="12px"
                            >
                                {stage && stage.status === "fail"
                                    ? "x"
                                    : stage.value}
                            </Box>
                            {paymentData.length != stage.value && (
                                <Box
                                    width="74px"
                                    h="4px"
                                    position="absolute"
                                    right="-57px"
                                    top="50%"
                                    transform="translateY(-50%)"
                                    zIndex={1}
                                    bgColor={
                                        stage.status === "done"
                                            ? "#00994F"
                                            : stage.status === "current"
                                                ? "#BC3CF0"
                                                : stage.status === "fail"
                                                    ? "red.500"
                                                    : "#9E9E9E"
                                    }
                                />
                            )}
                        </Box>
                        <Box
                            textAlign="center"
                            marginTop="1"
                            fontSize={"12px"}
                            h="38px"
                        >
                            {stage && stage.label}
                        </Box>
                    </Flex>
                );
            })}
        </Box>
    )
}

const SingleCurrentProgress = () => {
    return (
        <Flex flexDir={"column"} justify={"center"} align={"center"}>
            <Box
                width="30.18px"
                height="30.18px"
                borderRadius="full"
                bg={"#BC3CF0"}
                display="flex"
                justifyContent="center"
                fontWeight={900}
                alignItems="center"
                zIndex={10}
                pos={"relative"}
                fontSize="12px"
            >
                1
            </Box>
            <Box
                textAlign="center"
                marginTop="10px"
                fontSize={"12px"}
                h="38px"
                color={"#BC3CF0"}
                fontWeight={700}
            >
                EZ-Buy in Progress
            </Box>
        </Flex>
    );
};

export {
    EZBuyProgressBar,
    EZ_BUY_DONE_STEP,
    EZ_BUY_FAIL_STEP,
    EZ_BUY_CURRENT_STEP,
    EZ_BUY_NEUTRAL_STEP
}
import {
    Box,
    SimpleGrid,
    Image,
    Text,
    Flex,
    Link,
    Divider,
    Spinner
} from "@chakra-ui/react";
import React from "react";
import { BsBoxArrowDown } from "react-icons/bs";
import {
    FaDiscord,
    FaInfo,
    FaInfoCircle,
    FaLink,
    FaTwitter,
} from "react-icons/fa";
import { SocialLinkGroup } from "../CommonComponents/SocialLinkGroup";
import { checkIfNullOrUndefined } from "../utils/utils";
import { STACKED_TYPE } from "../utils/Constants";

const MarketplaceCollectionHero = ({ collectionMetadata }) => {
    return (
        <Box py={["25px", null, "20px"]}>
            <Flex gap="37px">
                <Box minW={["100%", null, "374px"]} maxW="400px">
                    <Image
                        src={
                            collectionMetadata &&
                            (
                                collectionMetadata["standard"] === STACKED_TYPE ?
                                    collectionMetadata["collection_image"] :
                                    collectionMetadata["collection_thumbnail_image"]
                            )
                        }
                        alt={"name of card Mint"}
                        borderRadius="8px"
                        w="100%"
                    />
                </Box>
                <Box minW={["100%", null, "368px"]}>
                    <Box>
                        <Text
                            fontSize={["26px", null, "36px"]}
                            fontWeight="900"
                        >
                            {collectionMetadata &&
                                !checkIfNullOrUndefined(collectionMetadata["collection-name"]) &&
                                collectionMetadata["collection-name"].toUpperCase()}
                        </Text>
                    </Box>
                    <Box my="24px">
                        <Text fontSize={["18px", null, "20px"]}>
                            By:{" "}
                            {collectionMetadata &&
                                collectionMetadata["project-name"]}
                        </Text>
                    </Box>
                    <Box mt="0px">
                        <Box
                            borderRadius="8px"
                            border="1px"
                            borderColor="#5E1E78"
                            px="8px"
                            py="20px"
                            bgColor="#160D1A"
                        >
                            <Flex gap={["20px", null, null, "40px"]}>
                                <Box w="100%">
                                    <SubInfo
                                        name="FLOOR:"
                                        value={
                                            collectionMetadata &&
                                            collectionMetadata["floor"]
                                        }
                                        unit="KDA"
                                    />
                                    {/* <SubInfo
                                        name="TOTAL VOL:"
                                        value={
                                            collectionMetadata &&
                                            collectionMetadata["total_volume"]
                                        }
                                        unit="KDA"
                                    /> */}
                                    <SubInfo
                                        name="OWNERS:"
                                        value={
                                            collectionMetadata &&
                                            collectionMetadata["owners"] &&
                                            collectionMetadata["owners"].length
                                        }
                                        unit={""}
                                    />
                                </Box>
                                <Box w="100%">
                                    <SubInfo
                                        name="LISTED:"
                                        value={
                                            collectionMetadata &&
                                            collectionMetadata["listed"]
                                        }
                                        unit={""}
                                    />
                                    <SubInfo
                                        name="SUPPLY:"
                                        value={
                                            collectionMetadata &&
                                            collectionMetadata["total_supply"]
                                        }
                                        unit={""}
                                    />
                                    <SubInfo
                                        name="BEST OFFER:"
                                        value={
                                            collectionMetadata &&
                                            collectionMetadata["floor"]
                                        }
                                        unit={"KDA"}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </Box>

                <Box
                    border="1px"
                    borderColor="#5E1E78"
                    min="100%"
                    maxW="1px"
                ></Box>

                <Box flex="1" minW={["100%", null, "350px"]}>
                    <Box>
                        <Text
                            fontSize={["26px", null, "36px"]}
                            fontWeight="900"
                            mb="11px"
                        >
                            About Collection
                        </Text>

                        <SocialLinkGroup projectConfig={collectionMetadata} />

                        <Flex
                            align="center"
                            color="#BC3CF0"
                            gap="9px"
                            className="colored"
                        >
                            <Text py="8px" fontWeight="700">
                                Chain{" "}
                                {collectionMetadata &&
                                    collectionMetadata["chain_id"]}
                            </Text>
                            <FaInfoCircle color="#BC3CF0" />
                        </Flex>

                        <Box>
                            <Box>
                                <Text>
                                    {collectionMetadata &&
                                        collectionMetadata[
                                        "project_description_summary"
                                        ]}
                                </Text>
                                <Text mt="10px">
                                    {collectionMetadata &&
                                        collectionMetadata["project_description"]}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default MarketplaceCollectionHero;

const SubInfo = ({ name, value, unit }) => {
    return (
        <Flex align="center" w="100%" mb="8px" gap="4px">
            <Text fontSize="12px">{name}</Text>
            {!checkIfNullOrUndefined(value) ? <Text
                fontWeight={700}
                fontSize={["14px", null, null, "16px"]}
                textAlign="right"
                flex="1"
            >
                {value}{" "}{unit}
            </Text> : <Spinner size={"sm"} />}
        </Flex>
    );
};

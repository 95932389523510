import { Box, Flex, Text, Image, useDisclosure } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { CartModeContext } from "../Context/CartContext";

import CircleBox from "../assets/images/circle.svg";
import { useEffect } from "react";
import { memo } from "react";

const ListedNFTCard = memo(
    ({
        collectionName,
        nftData,
        cardCallback,
        currentHeight,
        moveUp = false,
    }) => {
        const { isCartActive, showFilter } = useContext(CartModeContext);
        const [hovered, setHovered] = useState(false);
        const [selectedAtc, setSelectedAtc] = useState(false);
        const { isOpen, onOpen, onClose } = useDisclosure();

        // useEffect(() => {
        //     setSelectedAtc(false);
        // }, [isCartActive]);

        //TODO: use utility
        function getCardDisplayState() {
            let displayText = "Not Listed";

            if (nftData["listing-details"]) {
                if (nftData["listing-details"]["timeout"] <= currentHeight) {
                    displayText = `${nftData["listing-details"]["price"]} KDA  (Expired)`;
                    return displayText;
                } else {
                    return `${nftData["listing-details"]["price"]} KDA`;
                }
            }

            return displayText;
        }

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };

        return (
            <Box
                bg={"transparent"}
                onMouseOver={() => {
                    if (selectedAtc && selectedAtc) {
                        setHovered(true);
                    } else {
                        setHovered(false);
                    }
                }}
                onMouseOut={() => setHovered(false)}
                style={{ cursor: "pointer" }}
                className={`kadCard latestDesign cardHover ${isCartActive && hovered ? "activeCard" : ""
                    } `}
                onClick={() => {
                    cardCallback && cardCallback(nftData && nftData["nft-id"]);
                    moveUp && scrollToTop();
                }}
            >
                <Box pos="relative" px="0.25rem" pt="0.25rem">
                    <Image
                        src={nftData && nftData["nft-uri"]}
                        alt="nftCard"
                        width={"100%"}
                        height={[true ? "18.5rem" : "328px"]}
                        objectFit="cover"
                        className="cardImage"
                        borderTopRightRadius={"0.75rem"}
                        borderTopLeftRadius={"0.75rem"}
                    />
                    <Image
                        loading="lazy"
                        src={CircleBox}
                        alt="Icon"
                        pos="absolute"
                        top="12px"
                        right="12px"
                        fontSize="32px"
                        className="pink"
                        display={selectedAtc && selectedAtc ? "block" : "none"}
                        borderTopRightRadius={"0.75rem"}
                        borderTopLeftRadius={"0.75rem"}
                    />
                </Box>
                <Box
                    style={{ padding: "16px 16px 16px 21px" }}
                // bgColor={selectedAtc && selectedAtc ? "#bc3cf0" : "#160d19"}
                >
                    {hovered ? (
                        <Flex h="100px" align="center" justify={"center"}>
                            <Text
                                as="button"
                                fontSize="20px"
                                textDecor={"underline"}
                                fontWeight={900}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onOpen();
                                }}
                            >
                                View details
                            </Text>
                        </Flex>
                    ) : (
                        <Box minH="">
                            <Flex
                                align=""
                                justify="space-between"
                                mb="10px"
                                gap="20px"
                                fontSize={[showFilter ? "1rem" : "20px"]}
                            >
                                {" "}
                                <Text
                                    fontWeight="700"
                                    fontSize={[showFilter ? "1rem" : "20px"]}
                                    w="60%"
                                >
                                    {collectionName && collectionName}
                                </Text>
                                <Text
                                    fontWeight="700"
                                    fontSize={[showFilter ? "1rem" : "20px"]}
                                >
                                    #{nftData && nftData["nft-id"]}
                                </Text>
                            </Flex>
                            <Box>
                                <Text fontWeight="900" fontSize="1.25rem">
                                    {nftData && getCardDisplayState()}
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }
);

export default ListedNFTCard;

const kadena_skellies_metadata = {
    "370": {
        "name": "Kadena Skellies #370",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/370.png",
        "dna": "1b94b95046205a24e1fea6d5abcf7336eb6dd169",
        "edition": 370,
        "date": 1666812975011,
        "attributes": [
            { "trait_type": "Special", "value": "T850", "score": 1250.0 }
        ],
        "id": "370",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "871": {
        "name": "Kadena Skellies #871",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/871.png",
        "dna": "dc1dfd2bb6161e0fdb7822be8e778925398b5a3c",
        "edition": 871,
        "date": 1666812975066,
        "attributes": [
            { "trait_type": "Special", "value": "Marv", "score": 1250.0 }
        ],
        "id": "871",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "41": {
        "name": "Kadena Skellies #41",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/41.png",
        "dna": "e7ce560bf3a14ae46cb2ddb36841c8789efa8519",
        "edition": 41,
        "date": 1666812975279,
        "attributes": [
            { "trait_type": "Special", "value": "Red Skull", "score": 1250.0 }
        ],
        "id": "41",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "346": {
        "name": "Kadena Skellies #346",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/346.png",
        "dna": "9e9d65c1a04566d4c3550c629af9917397d773ff",
        "edition": 346,
        "date": 1666812975333,
        "attributes": [
            { "trait_type": "Special", "value": "Ghost Rider", "score": 1250.0 }
        ],
        "id": "346",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "427": {
        "name": "Kadena Skellies #427",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/427.png",
        "dna": "a5ac0a4412488a527705396efec8268fe6b69d75",
        "edition": 427,
        "date": 1666812975385,
        "attributes": [
            {
                "trait_type": "Special",
                "value": "Jack Sparrow",
                "score": 1250.0
            }
        ],
        "id": "427",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "203": {
        "name": "Kadena Skellies #203",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/203.png",
        "dna": "a084d1b24bb6e34bd1b75ca1196c4fc393aede8c",
        "edition": 203,
        "date": 1666812975438,
        "attributes": [
            { "trait_type": "Special", "value": "Skeletor", "score": 1250.0 }
        ],
        "id": "203",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "627": {
        "name": "Kadena Skellies #627",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/627.png",
        "dna": "cadf0825816ce91d7b161f4f0afdf7fe3e251f10",
        "edition": 627,
        "date": 1666812975542,
        "attributes": [
            {
                "trait_type": "Special",
                "value": "Tales From The Crypt",
                "score": 1250.0
            }
        ],
        "id": "627",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "452": {
        "name": "Kadena Skellies #452",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/452.png",
        "dna": "6a5ad4de1303f938438c4dacd443b21395d4e082",
        "edition": 452,
        "date": 1666812975595,
        "attributes": [
            { "trait_type": "Special", "value": "Grim Reaper", "score": 1250.0 }
        ],
        "id": "452",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "681": {
        "name": "Kadena Skellies #681",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/681.png",
        "dna": "04b3fa3252cb1923d16986691d7543977af9e40e",
        "edition": 681,
        "date": 1666812975646,
        "attributes": [
            { "trait_type": "Special", "value": "Skellington", "score": 1250.0 }
        ],
        "id": "681",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "830": {
        "name": "Kadena Skellies #830",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/830.png",
        "dna": "b079c59bec3fa8fabcac20107b8dd5cf11782c83",
        "edition": 830,
        "date": 1666812975699,
        "attributes": [
            { "trait_type": "Special", "value": "Achmed", "score": 1250.0 }
        ],
        "id": "830",
        "rarity_score": 1250.0,
        "rarity_level": "ONE OF ONE"
    },
    "869": {
        "name": "Kadena Skellies #869",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/869.png",
        "dna": "4084f1c4ead3b26797a8c13181f7e93868fcf3f7",
        "edition": 869,
        "date": 1666812975769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "869",
        "rarity_score": 129.54173088407313,
        "rarity_level": "COMMON"
    },
    "1250": {
        "name": "Kadena Skellies #1250",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1250.png",
        "dna": "9495b79b4fad105872554bab7c3b547b9fc2990c",
        "edition": 1250,
        "date": 1666812975845,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1250",
        "rarity_score": 142.37187445140546,
        "rarity_level": "COMMON"
    },
    "1147": {
        "name": "Kadena Skellies #1147",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1147.png",
        "dna": "8d99f84e37f304ac0edaf4e2e03f8a557528582c",
        "edition": 1147,
        "date": 1666812975924,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1147",
        "rarity_score": 135.96846210413008,
        "rarity_level": "COMMON"
    },
    "1161": {
        "name": "Kadena Skellies #1161",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1161.png",
        "dna": "cbb73ed24f9c3d9f27d6803a9da7aa8c126495e6",
        "edition": 1161,
        "date": 1666812975998,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1161",
        "rarity_score": 105.71425078447749,
        "rarity_level": "COMMON"
    },
    "302": {
        "name": "Kadena Skellies #302",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/302.png",
        "dna": "b600858e2145fea7bfb1290f1ab17d829a0f62ae",
        "edition": 302,
        "date": 1666812976077,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "302",
        "rarity_score": 159.33224712265283,
        "rarity_level": "COMMON"
    },
    "1072": {
        "name": "Kadena Skellies #1072",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1072.png",
        "dna": "0eae50dbdde5d682575c80742f5a2ebd5cf62153",
        "edition": 1072,
        "date": 1666812976156,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1072",
        "rarity_score": 134.5888845687395,
        "rarity_level": "COMMON"
    },
    "1234": {
        "name": "Kadena Skellies #1234",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1234.png",
        "dna": "6cd6244168c437f2478d875cf83f78a32fd1d617",
        "edition": 1234,
        "date": 1666812976225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1234",
        "rarity_score": 198.89872946120303,
        "rarity_level": "RARE"
    },
    "904": {
        "name": "Kadena Skellies #904",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/904.png",
        "dna": "21e8706c980c988a3b651f564c0d09e42d28a262",
        "edition": 904,
        "date": 1666812976300,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "904",
        "rarity_score": 124.03070750156228,
        "rarity_level": "COMMON"
    },
    "1176": {
        "name": "Kadena Skellies #1176",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1176.png",
        "dna": "6d192174e9776035304d6e24ac86ab9e601dc370",
        "edition": 1176,
        "date": 1666812976372,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1176",
        "rarity_score": 134.4987524084163,
        "rarity_level": "COMMON"
    },
    "288": {
        "name": "Kadena Skellies #288",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/288.png",
        "dna": "dcdb1ef73a1ac4589b21f35907350bedffb9f188",
        "edition": 288,
        "date": 1666812976449,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "288",
        "rarity_score": 213.3050121242971,
        "rarity_level": "RARE"
    },
    "365": {
        "name": "Kadena Skellies #365",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/365.png",
        "dna": "8a64f5221c7de8f1fa00323ae550a293b34acf13",
        "edition": 365,
        "date": 1666812976524,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "365",
        "rarity_score": 127.57641566341,
        "rarity_level": "COMMON"
    },
    "74": {
        "name": "Kadena Skellies #74",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/74.png",
        "dna": "9a7ce5a3580e2e0b3912227b4737b8653ea03d7f",
        "edition": 74,
        "date": 1666812976596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "74",
        "rarity_score": 191.62375630524664,
        "rarity_level": "RARE"
    },
    "387": {
        "name": "Kadena Skellies #387",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/387.png",
        "dna": "910ffba5ea90774c7e527fbca58e8f25dd23c6c3",
        "edition": 387,
        "date": 1666812976671,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "387",
        "rarity_score": 170.2900107438647,
        "rarity_level": "RARE"
    },
    "1235": {
        "name": "Kadena Skellies #1235",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1235.png",
        "dna": "49c027937b884fa2010a334b9051e139a367a391",
        "edition": 1235,
        "date": 1666812976744,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1235",
        "rarity_score": 94.08803365674034,
        "rarity_level": "COMMON"
    },
    "543": {
        "name": "Kadena Skellies #543",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/543.png",
        "dna": "6ca6bdce6bfa573cf4459991937948f0aa8d2a4e",
        "edition": 543,
        "date": 1666812976816,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "543",
        "rarity_score": 171.89133426405064,
        "rarity_level": "RARE"
    },
    "696": {
        "name": "Kadena Skellies #696",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/696.png",
        "dna": "43ab15ce531267790faa511d180b7b7beef81deb",
        "edition": 696,
        "date": 1666812976896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "696",
        "rarity_score": 156.97480587397263,
        "rarity_level": "COMMON"
    },
    "416": {
        "name": "Kadena Skellies #416",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/416.png",
        "dna": "1e6ea71710cfc92a4edcd2b2d163e5f6f2e45a9f",
        "edition": 416,
        "date": 1666812976967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "416",
        "rarity_score": 157.01952928663857,
        "rarity_level": "COMMON"
    },
    "268": {
        "name": "Kadena Skellies #268",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/268.png",
        "dna": "6f25efb6e69e5c886df5c41a5406456b0ee14e52",
        "edition": 268,
        "date": 1666812977042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "268",
        "rarity_score": 161.38742253348815,
        "rarity_level": "COMMON"
    },
    "1103": {
        "name": "Kadena Skellies #1103",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1103.png",
        "dna": "337909029a2eb0aec2af89c08a7bd8895dfaf033",
        "edition": 1103,
        "date": 1666812977121,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1103",
        "rarity_score": 133.34832587914985,
        "rarity_level": "COMMON"
    },
    "1039": {
        "name": "Kadena Skellies #1039",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1039.png",
        "dna": "9ac744b490f81e2ac7f1d1630c9bddb0a268f640",
        "edition": 1039,
        "date": 1666812977195,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1039",
        "rarity_score": 174.3989608059211,
        "rarity_level": "RARE"
    },
    "521": {
        "name": "Kadena Skellies #521",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/521.png",
        "dna": "fd1e6c6972c9a2a63d13f3b2addbb5f64490fb6c",
        "edition": 521,
        "date": 1666812977267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "521",
        "rarity_score": 156.64190259778496,
        "rarity_level": "COMMON"
    },
    "556": {
        "name": "Kadena Skellies #556",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/556.png",
        "dna": "c59a602567173b905ed2060461dc06812839c4b8",
        "edition": 556,
        "date": 1666812977341,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "556",
        "rarity_score": 197.61348761923378,
        "rarity_level": "RARE"
    },
    "396": {
        "name": "Kadena Skellies #396",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/396.png",
        "dna": "16b20e07f7704e5fe15b4db4c4dd5f3651588a53",
        "edition": 396,
        "date": 1666812977413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "396",
        "rarity_score": 122.46576531290137,
        "rarity_level": "COMMON"
    },
    "492": {
        "name": "Kadena Skellies #492",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/492.png",
        "dna": "1e40885f02a60970d4ef7f06252f5a46f2b6c6cb",
        "edition": 492,
        "date": 1666812977483,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "492",
        "rarity_score": 102.35405287317553,
        "rarity_level": "COMMON"
    },
    "243": {
        "name": "Kadena Skellies #243",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/243.png",
        "dna": "0db688eefde2aef503d1e0beced195413a0915ac",
        "edition": 243,
        "date": 1666812977556,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "243",
        "rarity_score": 176.30638724388726,
        "rarity_level": "RARE"
    },
    "1031": {
        "name": "Kadena Skellies #1031",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1031.png",
        "dna": "4c8f34aae58ad0f3ccc3ea36a0deef727566ec78",
        "edition": 1031,
        "date": 1666812977629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1031",
        "rarity_score": 118.73797946598428,
        "rarity_level": "COMMON"
    },
    "782": {
        "name": "Kadena Skellies #782",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/782.png",
        "dna": "5945b7938f791ba95e272694e3c8c496ef41f4d1",
        "edition": 782,
        "date": 1666812977708,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "782",
        "rarity_score": 168.2743315255715,
        "rarity_level": "COMMON"
    },
    "123": {
        "name": "Kadena Skellies #123",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/123.png",
        "dna": "47eed3382acd4b0086540ae2fdcf58a43f520f23",
        "edition": 123,
        "date": 1666812977780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "123",
        "rarity_score": 121.59170410860835,
        "rarity_level": "COMMON"
    },
    "977": {
        "name": "Kadena Skellies #977",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/977.png",
        "dna": "ec250bb92a814bf833f48c6b31e3a0c6a720b741",
        "edition": 977,
        "date": 1666812977852,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "977",
        "rarity_score": 128.17663418951562,
        "rarity_level": "COMMON"
    },
    "231": {
        "name": "Kadena Skellies #231",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/231.png",
        "dna": "5acb28d5f9c7cd59b27c1c812bd7a05ab0b6f02c",
        "edition": 231,
        "date": 1666812977929,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "231",
        "rarity_score": 99.39700403136533,
        "rarity_level": "COMMON"
    },
    "388": {
        "name": "Kadena Skellies #388",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/388.png",
        "dna": "5c0d8c5e00d88d1cc11b991b4d70706d89d76087",
        "edition": 388,
        "date": 1666812978001,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "388",
        "rarity_score": 120.12572853441114,
        "rarity_level": "COMMON"
    },
    "79": {
        "name": "Kadena Skellies #79",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/79.png",
        "dna": "82f18eee18827edc92d385dcf194b5eee79c5bb6",
        "edition": 79,
        "date": 1666812978082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "79",
        "rarity_score": 233.22170328881083,
        "rarity_level": "RARE"
    },
    "60": {
        "name": "Kadena Skellies #60",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/60.png",
        "dna": "87c3aca2c72320f00b500b2d1790808fcf2e5c1a",
        "edition": 60,
        "date": 1666812978153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "60",
        "rarity_score": 134.90932760061884,
        "rarity_level": "COMMON"
    },
    "468": {
        "name": "Kadena Skellies #468",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/468.png",
        "dna": "03bf1ffc4984d6eede6866f7618704ceceb284b5",
        "edition": 468,
        "date": 1666812978227,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "468",
        "rarity_score": 143.38438382940402,
        "rarity_level": "COMMON"
    },
    "1188": {
        "name": "Kadena Skellies #1188",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1188.png",
        "dna": "953d3e1b322ffd35dfad45956792fddc9cb5e7fd",
        "edition": 1188,
        "date": 1666812978298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1188",
        "rarity_score": 137.84188653396046,
        "rarity_level": "COMMON"
    },
    "751": {
        "name": "Kadena Skellies #751",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/751.png",
        "dna": "a8d84c90bab8ec32884b3e420e3bcb20e6486914",
        "edition": 751,
        "date": 1666812978367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "751",
        "rarity_score": 117.6666156885036,
        "rarity_level": "COMMON"
    },
    "740": {
        "name": "Kadena Skellies #740",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/740.png",
        "dna": "8e6e02eb31188f488a2af4406d996b0a62516a7c",
        "edition": 740,
        "date": 1666812978437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "740",
        "rarity_score": 180.716087425535,
        "rarity_level": "RARE"
    },
    "536": {
        "name": "Kadena Skellies #536",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/536.png",
        "dna": "fe82bc3a2fbb1e91424f0748958b9ded97d413ab",
        "edition": 536,
        "date": 1666812978511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "536",
        "rarity_score": 143.89693627042,
        "rarity_level": "COMMON"
    },
    "362": {
        "name": "Kadena Skellies #362",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/362.png",
        "dna": "ab40153e6f02fdcd2a8c32281d6aecf6a06a6c14",
        "edition": 362,
        "date": 1666812978584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "362",
        "rarity_score": 184.88267645192698,
        "rarity_level": "RARE"
    },
    "554": {
        "name": "Kadena Skellies #554",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/554.png",
        "dna": "c39c11004bcbb130fbf9c0ca128ef65688979aed",
        "edition": 554,
        "date": 1666812978655,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "554",
        "rarity_score": 161.21342471428875,
        "rarity_level": "COMMON"
    },
    "811": {
        "name": "Kadena Skellies #811",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/811.png",
        "dna": "f9241d708b2f5cd9c9878518951143fbea6be0a8",
        "edition": 811,
        "date": 1666812978727,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "811",
        "rarity_score": 137.14126333093364,
        "rarity_level": "COMMON"
    },
    "717": {
        "name": "Kadena Skellies #717",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/717.png",
        "dna": "5ac2cc54f2d9f34aefaa07e6700a6e45c06ffbbe",
        "edition": 717,
        "date": 1666812978797,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "717",
        "rarity_score": 92.59210202418218,
        "rarity_level": "COMMON"
    },
    "1073": {
        "name": "Kadena Skellies #1073",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1073.png",
        "dna": "ea61f450852de7b379d850b14a9b1e515045e832",
        "edition": 1073,
        "date": 1666812978868,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1073",
        "rarity_score": 120.71654093126423,
        "rarity_level": "COMMON"
    },
    "1200": {
        "name": "Kadena Skellies #1200",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1200.png",
        "dna": "d428d3bb26115dbc45a05257f1bf5bc3b309baaf",
        "edition": 1200,
        "date": 1666812978937,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1200",
        "rarity_score": 132.12150066263106,
        "rarity_level": "COMMON"
    },
    "309": {
        "name": "Kadena Skellies #309",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/309.png",
        "dna": "b0e9b824dda184ba4a29bc753259eeb3f088f010",
        "edition": 309,
        "date": 1666812979008,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "309",
        "rarity_score": 132.35008310455615,
        "rarity_level": "COMMON"
    },
    "982": {
        "name": "Kadena Skellies #982",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/982.png",
        "dna": "04d38c5d674855b79164f74c42f2f3fdcbd225f3",
        "edition": 982,
        "date": 1666812979087,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "982",
        "rarity_score": 131.68665711880004,
        "rarity_level": "COMMON"
    },
    "594": {
        "name": "Kadena Skellies #594",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/594.png",
        "dna": "bcbca783ead53e6cda57ddd1ebc98c4273cf56cf",
        "edition": 594,
        "date": 1666812979161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "594",
        "rarity_score": 147.08145755283516,
        "rarity_level": "COMMON"
    },
    "223": {
        "name": "Kadena Skellies #223",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/223.png",
        "dna": "23cfaacad9c72c06517159648a9951dbdcee40f0",
        "edition": 223,
        "date": 1666812979236,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "223",
        "rarity_score": 138.42397399629266,
        "rarity_level": "COMMON"
    },
    "407": {
        "name": "Kadena Skellies #407",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/407.png",
        "dna": "1378b6d9b11a28999d051423b0a2923fdb0863eb",
        "edition": 407,
        "date": 1666812979309,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "407",
        "rarity_score": 142.98974318168467,
        "rarity_level": "COMMON"
    },
    "850": {
        "name": "Kadena Skellies #850",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/850.png",
        "dna": "25b610db9b4b7622d45cabdd7d0b8e1e220666ab",
        "edition": 850,
        "date": 1666812979380,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "850",
        "rarity_score": 135.53577059490922,
        "rarity_level": "COMMON"
    },
    "922": {
        "name": "Kadena Skellies #922",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/922.png",
        "dna": "6a2d6923d5ca38ab04abe1b6fdb34007204aaddd",
        "edition": 922,
        "date": 1666812979452,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "922",
        "rarity_score": 235.80094364706994,
        "rarity_level": "RARE"
    },
    "1041": {
        "name": "Kadena Skellies #1041",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1041.png",
        "dna": "bb1e466b1d9cad72f331a8e9ab5dc0c96e25bf81",
        "edition": 1041,
        "date": 1666812979521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1041",
        "rarity_score": 116.97488086964435,
        "rarity_level": "COMMON"
    },
    "508": {
        "name": "Kadena Skellies #508",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/508.png",
        "dna": "c0e5f42c08a84a349738bab495f2f6efc2d90712",
        "edition": 508,
        "date": 1666812979591,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "508",
        "rarity_score": 129.14245577137376,
        "rarity_level": "COMMON"
    },
    "1224": {
        "name": "Kadena Skellies #1224",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1224.png",
        "dna": "c628cb17988893a79fdfee7e8f266c2c1971209b",
        "edition": 1224,
        "date": 1666812979670,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1224",
        "rarity_score": 128.93324514865512,
        "rarity_level": "COMMON"
    },
    "464": {
        "name": "Kadena Skellies #464",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/464.png",
        "dna": "890a3e330c3c963c0fc82ce2f8db2e2078171261",
        "edition": 464,
        "date": 1666812979745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "464",
        "rarity_score": 133.98132994756855,
        "rarity_level": "COMMON"
    },
    "853": {
        "name": "Kadena Skellies #853",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/853.png",
        "dna": "eb4b851e0df8e1c39ab0b8fd4838fae0c7c9d59a",
        "edition": 853,
        "date": 1666812979818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "853",
        "rarity_score": 133.85682157673708,
        "rarity_level": "COMMON"
    },
    "15": {
        "name": "Kadena Skellies #15",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/15.png",
        "dna": "400f7f23616db390928186e7df8c47df7526d047",
        "edition": 15,
        "date": 1666812979890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "15",
        "rarity_score": 98.82336647592469,
        "rarity_level": "COMMON"
    },
    "421": {
        "name": "Kadena Skellies #421",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/421.png",
        "dna": "0b4aae2d6e3475959f004d65cded0e0b95ada9c6",
        "edition": 421,
        "date": 1666812979962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Round Kadena Glasses",
                "score": 250.0
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "421",
        "rarity_score": 345.64411134492576,
        "rarity_level": "EPIC"
    },
    "640": {
        "name": "Kadena Skellies #640",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/640.png",
        "dna": "cc28903a7ce57a8d03ddc5abeb02cb93b3e86337",
        "edition": 640,
        "date": 1666812980034,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "640",
        "rarity_score": 117.25250052639149,
        "rarity_level": "COMMON"
    },
    "1101": {
        "name": "Kadena Skellies #1101",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1101.png",
        "dna": "4fb7beffcfa4e1f32ee63f6eab94671e85b475e4",
        "edition": 1101,
        "date": 1666812980109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1101",
        "rarity_score": 134.83103874091398,
        "rarity_level": "COMMON"
    },
    "221": {
        "name": "Kadena Skellies #221",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/221.png",
        "dna": "8b0fd15559701a678f05db9fa3678cdbbe751f27",
        "edition": 221,
        "date": 1666812980183,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "221",
        "rarity_score": 249.43700784893238,
        "rarity_level": "RARE"
    },
    "609": {
        "name": "Kadena Skellies #609",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/609.png",
        "dna": "57f4806bb3be58010b5c060cd8be9801f02ceff3",
        "edition": 609,
        "date": 1666812980261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "609",
        "rarity_score": 167.85162591095698,
        "rarity_level": "COMMON"
    },
    "514": {
        "name": "Kadena Skellies #514",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/514.png",
        "dna": "71c42a6807640e7e37a0dade12dc329675478341",
        "edition": 514,
        "date": 1666812980331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "514",
        "rarity_score": 103.31775069895737,
        "rarity_level": "COMMON"
    },
    "44": {
        "name": "Kadena Skellies #44",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/44.png",
        "dna": "2c3d3e11fcd426ccc3b64093e2fc6d6e03b3ec6a",
        "edition": 44,
        "date": 1666812980403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "44",
        "rarity_score": 142.62187291083146,
        "rarity_level": "COMMON"
    },
    "629": {
        "name": "Kadena Skellies #629",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/629.png",
        "dna": "32b8070910c7aab17b2978dcc07a1bc58d0388b0",
        "edition": 629,
        "date": 1666812980475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "629",
        "rarity_score": 148.52866088052266,
        "rarity_level": "COMMON"
    },
    "25": {
        "name": "Kadena Skellies #25",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/25.png",
        "dna": "76909a554b7bf8ade7a6f923a65cdef4539d2058",
        "edition": 25,
        "date": 1666812980550,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "25",
        "rarity_score": 202.80150844175236,
        "rarity_level": "RARE"
    },
    "963": {
        "name": "Kadena Skellies #963",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/963.png",
        "dna": "eaa348dd38d7c4439abca4ad75b2019f811e547c",
        "edition": 963,
        "date": 1666812980621,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "963",
        "rarity_score": 99.09942145426923,
        "rarity_level": "COMMON"
    },
    "512": {
        "name": "Kadena Skellies #512",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/512.png",
        "dna": "5c0f367a0a4c2387fe49b4324a232d7bd4d67ee1",
        "edition": 512,
        "date": 1666812980699,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "512",
        "rarity_score": 141.00147225147225,
        "rarity_level": "COMMON"
    },
    "495": {
        "name": "Kadena Skellies #495",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/495.png",
        "dna": "d3f19e368b416580a7f87984df924b9fccb30f78",
        "edition": 495,
        "date": 1666812980771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "495",
        "rarity_score": 167.87643346578523,
        "rarity_level": "COMMON"
    },
    "1105": {
        "name": "Kadena Skellies #1105",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1105.png",
        "dna": "ddf7f1babdce7feddd9591134648af4c2c0f6d79",
        "edition": 1105,
        "date": 1666812980844,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1105",
        "rarity_score": 127.13701080265751,
        "rarity_level": "COMMON"
    },
    "355": {
        "name": "Kadena Skellies #355",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/355.png",
        "dna": "7f385089982b41d757abdd4b6122cd8a084c7ad3",
        "edition": 355,
        "date": 1666812980914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "355",
        "rarity_score": 131.48165847406835,
        "rarity_level": "COMMON"
    },
    "1055": {
        "name": "Kadena Skellies #1055",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1055.png",
        "dna": "8247f121cb08fcc6792b4665b418f6bd69c813be",
        "edition": 1055,
        "date": 1666812980985,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1055",
        "rarity_score": 112.5213867691916,
        "rarity_level": "COMMON"
    },
    "822": {
        "name": "Kadena Skellies #822",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/822.png",
        "dna": "9f3f154957cf725a14457717cfa8706f5b91709f",
        "edition": 822,
        "date": 1666812981061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "822",
        "rarity_score": 145.5442810947879,
        "rarity_level": "COMMON"
    },
    "150": {
        "name": "Kadena Skellies #150",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/150.png",
        "dna": "a6278361939c13ef007e63928cce4227de0c27f6",
        "edition": 150,
        "date": 1666812981136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "150",
        "rarity_score": 111.34459170208525,
        "rarity_level": "COMMON"
    },
    "1247": {
        "name": "Kadena Skellies #1247",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1247.png",
        "dna": "995d772975869d0f308213036aa7adcb3553e3d1",
        "edition": 1247,
        "date": 1666812981216,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1247",
        "rarity_score": 146.42754978957115,
        "rarity_level": "COMMON"
    },
    "1056": {
        "name": "Kadena Skellies #1056",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1056.png",
        "dna": "9a31f0667570d0518470fba1ac6830fa64fdc1f6",
        "edition": 1056,
        "date": 1666812981289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1056",
        "rarity_score": 129.28933771044933,
        "rarity_level": "COMMON"
    },
    "980": {
        "name": "Kadena Skellies #980",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/980.png",
        "dna": "e06152a546fb42a5c43d0b26588b76626b784465",
        "edition": 980,
        "date": 1666812981360,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "980",
        "rarity_score": 137.2675287773379,
        "rarity_level": "COMMON"
    },
    "647": {
        "name": "Kadena Skellies #647",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/647.png",
        "dna": "30467276d5834b919a8b5de70ba0b74f1de7f57a",
        "edition": 647,
        "date": 1666812981432,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "647",
        "rarity_score": 124.3450833764133,
        "rarity_level": "COMMON"
    },
    "722": {
        "name": "Kadena Skellies #722",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/722.png",
        "dna": "bb68f0463fb9943ea40ec13565a73d9535df40b5",
        "edition": 722,
        "date": 1666812981502,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "722",
        "rarity_score": 94.93738597701588,
        "rarity_level": "COMMON"
    },
    "984": {
        "name": "Kadena Skellies #984",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/984.png",
        "dna": "d16c725c221c5bb2e73343f0609452f43435124f",
        "edition": 984,
        "date": 1666812981575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "984",
        "rarity_score": 121.28432465439603,
        "rarity_level": "COMMON"
    },
    "993": {
        "name": "Kadena Skellies #993",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/993.png",
        "dna": "79a9ec6c1dabb0ccae33926f1435f0192ca78356",
        "edition": 993,
        "date": 1666812981646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "993",
        "rarity_score": 121.31671046672164,
        "rarity_level": "COMMON"
    },
    "689": {
        "name": "Kadena Skellies #689",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/689.png",
        "dna": "124407fd035fb8da67ddb54f69d110bbf5ea5cea",
        "edition": 689,
        "date": 1666812981717,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "689",
        "rarity_score": 106.5287236718887,
        "rarity_level": "COMMON"
    },
    "1086": {
        "name": "Kadena Skellies #1086",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1086.png",
        "dna": "c933ef2895132e1851c9da8dd2e27309b2677ef7",
        "edition": 1086,
        "date": 1666812981793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1086",
        "rarity_score": 176.30318112660365,
        "rarity_level": "RARE"
    },
    "821": {
        "name": "Kadena Skellies #821",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/821.png",
        "dna": "848f98bdef9fe58f567aa91c29c373e4251a360d",
        "edition": 821,
        "date": 1666812981863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "821",
        "rarity_score": 143.96467882459262,
        "rarity_level": "COMMON"
    },
    "955": {
        "name": "Kadena Skellies #955",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/955.png",
        "dna": "0f2df2ade1de7e14ffb135ae851eec3e514bad03",
        "edition": 955,
        "date": 1666812981939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "955",
        "rarity_score": 135.63956052546317,
        "rarity_level": "COMMON"
    },
    "1071": {
        "name": "Kadena Skellies #1071",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1071.png",
        "dna": "3029d655e32fa9e16ec66ecd47ca569879379433",
        "edition": 1071,
        "date": 1666812982011,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1071",
        "rarity_score": 98.53000596322126,
        "rarity_level": "COMMON"
    },
    "71": {
        "name": "Kadena Skellies #71",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/71.png",
        "dna": "3c3c3a08327372c25fc3993a82bcc5d5448795d7",
        "edition": 71,
        "date": 1666812982082,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "71",
        "rarity_score": 130.7647129204322,
        "rarity_level": "COMMON"
    },
    "233": {
        "name": "Kadena Skellies #233",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/233.png",
        "dna": "8e2121531ae8b8dc5b7ad4e1c430dd73b4a842f8",
        "edition": 233,
        "date": 1666812982157,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "233",
        "rarity_score": 176.46865296262018,
        "rarity_level": "RARE"
    },
    "911": {
        "name": "Kadena Skellies #911",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/911.png",
        "dna": "6275578a57537d3ed238a722fc6ded4a9e5163ed",
        "edition": 911,
        "date": 1666812982228,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "911",
        "rarity_score": 148.89314793210647,
        "rarity_level": "COMMON"
    },
    "928": {
        "name": "Kadena Skellies #928",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/928.png",
        "dna": "3a374b0fe27e6593cc5c93a7d5c9871b624a525f",
        "edition": 928,
        "date": 1666812982299,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "928",
        "rarity_score": 119.14665613516819,
        "rarity_level": "COMMON"
    },
    "584": {
        "name": "Kadena Skellies #584",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/584.png",
        "dna": "ce547f598b97b21c4d40e7ea57ee1cdcf4d9f9e4",
        "edition": 584,
        "date": 1666812982373,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "584",
        "rarity_score": 129.37285973395038,
        "rarity_level": "COMMON"
    },
    "88": {
        "name": "Kadena Skellies #88",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/88.png",
        "dna": "d2e639b3e4b7512b48f9db24d2e7f93c4983f7d0",
        "edition": 88,
        "date": 1666812982445,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "88",
        "rarity_score": 152.5374388425859,
        "rarity_level": "COMMON"
    },
    "927": {
        "name": "Kadena Skellies #927",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/927.png",
        "dna": "8523378245774f92c2621fb48f048290728be847",
        "edition": 927,
        "date": 1666812982518,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "927",
        "rarity_score": 354.2637331937535,
        "rarity_level": "EPIC"
    },
    "868": {
        "name": "Kadena Skellies #868",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/868.png",
        "dna": "b72abde61d8538075f4d1cb262f42ecbe3605e70",
        "edition": 868,
        "date": 1666812982589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "868",
        "rarity_score": 130.935833063762,
        "rarity_level": "COMMON"
    },
    "224": {
        "name": "Kadena Skellies #224",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/224.png",
        "dna": "3ab11f5adb3f525baea6cc85bb657af5e9ecdde1",
        "edition": 224,
        "date": 1666812982659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "224",
        "rarity_score": 125.39722669441966,
        "rarity_level": "COMMON"
    },
    "682": {
        "name": "Kadena Skellies #682",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/682.png",
        "dna": "d105f0dae01848c73a802d49bf2acc2f51c2c1b2",
        "edition": 682,
        "date": 1666812982732,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "682",
        "rarity_score": 166.49071244659478,
        "rarity_level": "COMMON"
    },
    "1173": {
        "name": "Kadena Skellies #1173",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1173.png",
        "dna": "ca5b6790ab19d785d1276a01cafb6452c022bcf9",
        "edition": 1173,
        "date": 1666812982803,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1173",
        "rarity_score": 117.28706933310515,
        "rarity_level": "COMMON"
    },
    "303": {
        "name": "Kadena Skellies #303",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/303.png",
        "dna": "5f5f9bab483afa3589b70c9f0028aff357b1fc4c",
        "edition": 303,
        "date": 1666812982874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "303",
        "rarity_score": 288.7213183512623,
        "rarity_level": "RARE"
    },
    "962": {
        "name": "Kadena Skellies #962",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/962.png",
        "dna": "75415ea39474de6db9eba93de806ece488f13fd8",
        "edition": 962,
        "date": 1666812982959,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Space Helmet",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "962",
        "rarity_score": 311.038636038636,
        "rarity_level": "RARE"
    },
    "610": {
        "name": "Kadena Skellies #610",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/610.png",
        "dna": "eea5e05c2cab03d0b5432a84971f40022205ad19",
        "edition": 610,
        "date": 1666812983028,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "610",
        "rarity_score": 154.5882690979054,
        "rarity_level": "COMMON"
    },
    "823": {
        "name": "Kadena Skellies #823",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/823.png",
        "dna": "c7dbe05e453356da5371a1b5c04905f106b3c3d5",
        "edition": 823,
        "date": 1666812983099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "823",
        "rarity_score": 149.64147609801122,
        "rarity_level": "COMMON"
    },
    "1025": {
        "name": "Kadena Skellies #1025",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1025.png",
        "dna": "c16adcde47edeac16416720c4af936efdc9e65d1",
        "edition": 1025,
        "date": 1666812983173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1025",
        "rarity_score": 136.94225011479875,
        "rarity_level": "COMMON"
    },
    "990": {
        "name": "Kadena Skellies #990",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/990.png",
        "dna": "b13829965a9201a669ad73e6479524cee57903e1",
        "edition": 990,
        "date": 1666812983244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "990",
        "rarity_score": 278.04482177849394,
        "rarity_level": "RARE"
    },
    "837": {
        "name": "Kadena Skellies #837",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/837.png",
        "dna": "c7045fbb863bedc352c34535868657eb54bc4406",
        "edition": 837,
        "date": 1666812983315,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "837",
        "rarity_score": 166.15743286370437,
        "rarity_level": "COMMON"
    },
    "359": {
        "name": "Kadena Skellies #359",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/359.png",
        "dna": "55dfea5eee7dbedfe82edad49e5638d82f148f86",
        "edition": 359,
        "date": 1666812983387,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "359",
        "rarity_score": 137.1901212423809,
        "rarity_level": "COMMON"
    },
    "727": {
        "name": "Kadena Skellies #727",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/727.png",
        "dna": "484ea0bbe7c214c74f78b907f572af5b8aa0f50b",
        "edition": 727,
        "date": 1666812983458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "727",
        "rarity_score": 149.9055050784304,
        "rarity_level": "COMMON"
    },
    "100": {
        "name": "Kadena Skellies #100",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/100.png",
        "dna": "d13a8309b462903278cb26540acfc8f2466aaf19",
        "edition": 100,
        "date": 1666812983530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "100",
        "rarity_score": 264.9287018617281,
        "rarity_level": "RARE"
    },
    "216": {
        "name": "Kadena Skellies #216",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/216.png",
        "dna": "1ee76604e20a5fd3ab4ceb047028e765ee4d092c",
        "edition": 216,
        "date": 1666812983602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "216",
        "rarity_score": 116.89257722852558,
        "rarity_level": "COMMON"
    },
    "375": {
        "name": "Kadena Skellies #375",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/375.png",
        "dna": "c2dc92077303b59fb795440fbba8d6ea7ceb524d",
        "edition": 375,
        "date": 1666812983672,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "375",
        "rarity_score": 109.81918874364007,
        "rarity_level": "COMMON"
    },
    "551": {
        "name": "Kadena Skellies #551",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/551.png",
        "dna": "497864cdb7d8c8f56cefa1455016493dcea0cb82",
        "edition": 551,
        "date": 1666812983747,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "551",
        "rarity_score": 98.86663624406057,
        "rarity_level": "COMMON"
    },
    "232": {
        "name": "Kadena Skellies #232",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/232.png",
        "dna": "d587f11a58270ab0f77d79966010a269271f8f21",
        "edition": 232,
        "date": 1666812983825,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Space Helmet",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "232",
        "rarity_score": 301.89768865755707,
        "rarity_level": "RARE"
    },
    "913": {
        "name": "Kadena Skellies #913",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/913.png",
        "dna": "da39c995991ae82894ba8566b4b59e7b196e572b",
        "edition": 913,
        "date": 1666812983897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "913",
        "rarity_score": 129.15300034861116,
        "rarity_level": "COMMON"
    },
    "274": {
        "name": "Kadena Skellies #274",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/274.png",
        "dna": "93f1243fba66614da9744bbcb03d77baf76c2e4d",
        "edition": 274,
        "date": 1666812983967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "274",
        "rarity_score": 124.55451485677452,
        "rarity_level": "COMMON"
    },
    "194": {
        "name": "Kadena Skellies #194",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/194.png",
        "dna": "60f65b3db720d86038628e2ce4d2ad05ca8679ce",
        "edition": 194,
        "date": 1666812984040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "194",
        "rarity_score": 142.92298463435696,
        "rarity_level": "COMMON"
    },
    "893": {
        "name": "Kadena Skellies #893",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/893.png",
        "dna": "efe6579de3be9ca4467bd3f511076d7be466287e",
        "edition": 893,
        "date": 1666812984117,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "893",
        "rarity_score": 128.1436036902368,
        "rarity_level": "COMMON"
    },
    "333": {
        "name": "Kadena Skellies #333",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/333.png",
        "dna": "991a46efecb136aae54d3f2e023aa2ec530dddf3",
        "edition": 333,
        "date": 1666812984191,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "333",
        "rarity_score": 112.96175762115848,
        "rarity_level": "COMMON"
    },
    "1206": {
        "name": "Kadena Skellies #1206",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1206.png",
        "dna": "b9ccf59968a2530bee7fbe2ea3865c13a9da27b8",
        "edition": 1206,
        "date": 1666812984262,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1206",
        "rarity_score": 149.65042592791283,
        "rarity_level": "COMMON"
    },
    "275": {
        "name": "Kadena Skellies #275",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/275.png",
        "dna": "63bffbd01cf123a7b8ce2b23e0f680aca95e2779",
        "edition": 275,
        "date": 1666812984335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "275",
        "rarity_score": 135.50278867166085,
        "rarity_level": "COMMON"
    },
    "736": {
        "name": "Kadena Skellies #736",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/736.png",
        "dna": "8124a6989cae0bcc629001fbab4f20f805c7082c",
        "edition": 736,
        "date": 1666812984408,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "736",
        "rarity_score": 131.78479753270244,
        "rarity_level": "COMMON"
    },
    "478": {
        "name": "Kadena Skellies #478",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/478.png",
        "dna": "62f31ba07208ecfb668270717f07a77a8dcc76ce",
        "edition": 478,
        "date": 1666812984479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "478",
        "rarity_score": 104.25006662372297,
        "rarity_level": "COMMON"
    },
    "748": {
        "name": "Kadena Skellies #748",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/748.png",
        "dna": "e6ac12bffad923dd08b6acf27a696e1af74e0cca",
        "edition": 748,
        "date": 1666812984550,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "748",
        "rarity_score": 133.83704330439775,
        "rarity_level": "COMMON"
    },
    "947": {
        "name": "Kadena Skellies #947",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/947.png",
        "dna": "73f1d096295c7937800175f8e0988bb66c20db2e",
        "edition": 947,
        "date": 1666812984621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "947",
        "rarity_score": 124.65941825283878,
        "rarity_level": "COMMON"
    },
    "945": {
        "name": "Kadena Skellies #945",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/945.png",
        "dna": "fd4e4d17a7ea632563bc520bd2eac73e55da859e",
        "edition": 945,
        "date": 1666812984694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "945",
        "rarity_score": 114.70028179910086,
        "rarity_level": "COMMON"
    },
    "526": {
        "name": "Kadena Skellies #526",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/526.png",
        "dna": "bf6e3ee713b0b6354fb0dd30c2123d0db9ea05e5",
        "edition": 526,
        "date": 1666812984764,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "526",
        "rarity_score": 226.52811792189385,
        "rarity_level": "RARE"
    },
    "1062": {
        "name": "Kadena Skellies #1062",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1062.png",
        "dna": "bf53f5908efe7044d3d4bf3ae935f0d5d2deaa57",
        "edition": 1062,
        "date": 1666812984835,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1062",
        "rarity_score": 115.51843004598825,
        "rarity_level": "COMMON"
    },
    "327": {
        "name": "Kadena Skellies #327",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/327.png",
        "dna": "b32c2f155440484d43e2529433105a90afa27f57",
        "edition": 327,
        "date": 1666812984908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "327",
        "rarity_score": 287.1630864379778,
        "rarity_level": "RARE"
    },
    "469": {
        "name": "Kadena Skellies #469",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/469.png",
        "dna": "fcaa3aa34e9a1a770859847e92a78819e6597080",
        "edition": 469,
        "date": 1666812984978,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "469",
        "rarity_score": 120.42855842561144,
        "rarity_level": "COMMON"
    },
    "762": {
        "name": "Kadena Skellies #762",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/762.png",
        "dna": "fa253a2698179faa7a26802c733f4965816159a3",
        "edition": 762,
        "date": 1666812985048,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "762",
        "rarity_score": 118.2388196171193,
        "rarity_level": "COMMON"
    },
    "642": {
        "name": "Kadena Skellies #642",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/642.png",
        "dna": "8cc3230d6ce3faeaaf710e14c21d7c56d1804be5",
        "edition": 642,
        "date": 1666812985127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "642",
        "rarity_score": 159.89969063204882,
        "rarity_level": "COMMON"
    },
    "293": {
        "name": "Kadena Skellies #293",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/293.png",
        "dna": "27c02f50586bb4f9316d98c1bce97bc162b34dca",
        "edition": 293,
        "date": 1666812985199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "293",
        "rarity_score": 110.38719882443522,
        "rarity_level": "COMMON"
    },
    "351": {
        "name": "Kadena Skellies #351",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/351.png",
        "dna": "6811b42f178fa197f84c008097efe1e2dd076890",
        "edition": 351,
        "date": 1666812985272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "351",
        "rarity_score": 135.72834404384824,
        "rarity_level": "COMMON"
    },
    "805": {
        "name": "Kadena Skellies #805",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/805.png",
        "dna": "4bf74af8c344fb2c476bffe29c1d2491ec984b09",
        "edition": 805,
        "date": 1666812985342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "805",
        "rarity_score": 119.4747470813343,
        "rarity_level": "COMMON"
    },
    "385": {
        "name": "Kadena Skellies #385",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/385.png",
        "dna": "7babea4711f06c03ef0b1fc4f22f871509dadab8",
        "edition": 385,
        "date": 1666812985413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "385",
        "rarity_score": 114.50950707688665,
        "rarity_level": "COMMON"
    },
    "971": {
        "name": "Kadena Skellies #971",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/971.png",
        "dna": "bf2726e8d356bfdd2217db8cb55a4b7e42904c61",
        "edition": 971,
        "date": 1666812985487,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "971",
        "rarity_score": 136.53830880214363,
        "rarity_level": "COMMON"
    },
    "918": {
        "name": "Kadena Skellies #918",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/918.png",
        "dna": "6007b35e8db1255e73959cc375ad5167aababf46",
        "edition": 918,
        "date": 1666812985557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "918",
        "rarity_score": 210.57741589279507,
        "rarity_level": "RARE"
    },
    "428": {
        "name": "Kadena Skellies #428",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/428.png",
        "dna": "9ffc09dccde03590da52759aecd84bd292958787",
        "edition": 428,
        "date": 1666812985631,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "428",
        "rarity_score": 205.04560707459714,
        "rarity_level": "RARE"
    },
    "1184": {
        "name": "Kadena Skellies #1184",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1184.png",
        "dna": "8215ac715b85a47bd461e681e68a6024d7cc0305",
        "edition": 1184,
        "date": 1666812985703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1184",
        "rarity_score": 134.63406828050057,
        "rarity_level": "COMMON"
    },
    "292": {
        "name": "Kadena Skellies #292",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/292.png",
        "dna": "b0e13af243189681c72692d5fa25c475f897c453",
        "edition": 292,
        "date": 1666812985774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "292",
        "rarity_score": 133.18149313921188,
        "rarity_level": "COMMON"
    },
    "104": {
        "name": "Kadena Skellies #104",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/104.png",
        "dna": "544c5e4f64e631b68c883c3ee586654ffb19e372",
        "edition": 104,
        "date": 1666812985851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "104",
        "rarity_score": 136.80304787609293,
        "rarity_level": "COMMON"
    },
    "1145": {
        "name": "Kadena Skellies #1145",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1145.png",
        "dna": "28627ca66dbc877dc554fb603c4ca77594ff9228",
        "edition": 1145,
        "date": 1666812985921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1145",
        "rarity_score": 278.19239753415224,
        "rarity_level": "RARE"
    },
    "471": {
        "name": "Kadena Skellies #471",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/471.png",
        "dna": "884ea3f8733ef91b6217827adf4cc404464e05fe",
        "edition": 471,
        "date": 1666812985991,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "471",
        "rarity_score": 162.6121251888675,
        "rarity_level": "COMMON"
    },
    "718": {
        "name": "Kadena Skellies #718",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/718.png",
        "dna": "56fed0fc2ce903f32c2a73f215f7c6b2a00656b0",
        "edition": 718,
        "date": 1666812986062,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "718",
        "rarity_score": 123.58614277418512,
        "rarity_level": "COMMON"
    },
    "1196": {
        "name": "Kadena Skellies #1196",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1196.png",
        "dna": "8386e75810655e3c7c4d9bbbdd7fea7a7e58d1c3",
        "edition": 1196,
        "date": 1666812986132,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1196",
        "rarity_score": 139.109099682122,
        "rarity_level": "COMMON"
    },
    "784": {
        "name": "Kadena Skellies #784",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/784.png",
        "dna": "6adfe3ecbdffbd84cc2bf8c1f41404fb4d175fac",
        "edition": 784,
        "date": 1666812986207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "784",
        "rarity_score": 136.8030750661781,
        "rarity_level": "COMMON"
    },
    "513": {
        "name": "Kadena Skellies #513",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/513.png",
        "dna": "7a22c26136c1b5128ab4444c424644d8cd33143d",
        "edition": 513,
        "date": 1666812986280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "513",
        "rarity_score": 123.8849469784773,
        "rarity_level": "COMMON"
    },
    "182": {
        "name": "Kadena Skellies #182",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/182.png",
        "dna": "32f0a0f6d50718f22e22ab505fcb25fabb37afa7",
        "edition": 182,
        "date": 1666812986352,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "182",
        "rarity_score": 146.4957089681269,
        "rarity_level": "COMMON"
    },
    "537": {
        "name": "Kadena Skellies #537",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/537.png",
        "dna": "7afc3e6c7e93f9b2af841ab931466e470e864e34",
        "edition": 537,
        "date": 1666812986422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "537",
        "rarity_score": 186.25655023415462,
        "rarity_level": "RARE"
    },
    "1005": {
        "name": "Kadena Skellies #1005",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1005.png",
        "dna": "469613177f89ed3bcbd65171206997c1ab3e0650",
        "edition": 1005,
        "date": 1666812986496,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1005",
        "rarity_score": 133.70892988826745,
        "rarity_level": "COMMON"
    },
    "467": {
        "name": "Kadena Skellies #467",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/467.png",
        "dna": "a1cf1aa36f5d29adc8892045cd28ff01c5160155",
        "edition": 467,
        "date": 1666812986566,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "467",
        "rarity_score": 99.93946426549344,
        "rarity_level": "COMMON"
    },
    "626": {
        "name": "Kadena Skellies #626",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/626.png",
        "dna": "e24404538567ca1890f08b414bc988a77ea6173e",
        "edition": 626,
        "date": 1666812986637,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "626",
        "rarity_score": 134.29435393862718,
        "rarity_level": "COMMON"
    },
    "50": {
        "name": "Kadena Skellies #50",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/50.png",
        "dna": "79ca332d1eb4aff2683ed32d818469aa0f46f910",
        "edition": 50,
        "date": 1666812986708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "50",
        "rarity_score": 273.40914713247435,
        "rarity_level": "RARE"
    },
    "549": {
        "name": "Kadena Skellies #549",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/549.png",
        "dna": "f4c29a141f0bf80dbfbb1bc61efcee64d472e94f",
        "edition": 549,
        "date": 1666812986778,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "549",
        "rarity_score": 179.68037178297618,
        "rarity_level": "RARE"
    },
    "357": {
        "name": "Kadena Skellies #357",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/357.png",
        "dna": "66365f54fbae4f4edddeec050d4fb728045b6fa4",
        "edition": 357,
        "date": 1666812986850,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "357",
        "rarity_score": 160.78046842826012,
        "rarity_level": "COMMON"
    },
    "660": {
        "name": "Kadena Skellies #660",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/660.png",
        "dna": "f67e1bc7049506955c36d85db537fcba2c89b4eb",
        "edition": 660,
        "date": 1666812986920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Fiery Eyes",
                "score": 416.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "660",
        "rarity_score": 563.04315943624,
        "rarity_level": "LEGENDARY"
    },
    "723": {
        "name": "Kadena Skellies #723",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/723.png",
        "dna": "9d7ad3e5911d3007413ddbb18038c11111fbcc73",
        "edition": 723,
        "date": 1666812986990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "723",
        "rarity_score": 158.4652071835228,
        "rarity_level": "COMMON"
    },
    "816": {
        "name": "Kadena Skellies #816",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/816.png",
        "dna": "0ae9564ab0d560bd784be166f34b7fe38c1d4a43",
        "edition": 816,
        "date": 1666812987063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "816",
        "rarity_score": 119.26458330557205,
        "rarity_level": "COMMON"
    },
    "394": {
        "name": "Kadena Skellies #394",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/394.png",
        "dna": "2b8ddafa34ed90889c5513ae8d21e3becd27e038",
        "edition": 394,
        "date": 1666812987134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "394",
        "rarity_score": 165.79722777097808,
        "rarity_level": "COMMON"
    },
    "218": {
        "name": "Kadena Skellies #218",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/218.png",
        "dna": "3d909a1fe7e90befea163d9bedda274b6344fb2f",
        "edition": 218,
        "date": 1666812987207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "218",
        "rarity_score": 168.12208068528986,
        "rarity_level": "COMMON"
    },
    "23": {
        "name": "Kadena Skellies #23",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/23.png",
        "dna": "2bb3ab37e70f9085a218d195f16b60d50e2a1ee3",
        "edition": 23,
        "date": 1666812987280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "23",
        "rarity_score": 179.6816417126671,
        "rarity_level": "RARE"
    },
    "520": {
        "name": "Kadena Skellies #520",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/520.png",
        "dna": "06e13bee6ae5b0fc8d8b8abb37ae1ee20a0ed46b",
        "edition": 520,
        "date": 1666812987350,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "520",
        "rarity_score": 170.55990404941267,
        "rarity_level": "RARE"
    },
    "646": {
        "name": "Kadena Skellies #646",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/646.png",
        "dna": "8970b99cd634ace4c756386d6899ce6073de5f20",
        "edition": 646,
        "date": 1666812987423,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "646",
        "rarity_score": 196.72738279457914,
        "rarity_level": "RARE"
    },
    "507": {
        "name": "Kadena Skellies #507",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/507.png",
        "dna": "3b1e16643108433b707663f2b0ee949f796d59d7",
        "edition": 507,
        "date": 1666812987495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "507",
        "rarity_score": 153.84158001472696,
        "rarity_level": "COMMON"
    },
    "1160": {
        "name": "Kadena Skellies #1160",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1160.png",
        "dna": "5a503e17345dc512ce6453e90eedd91d5de567c9",
        "edition": 1160,
        "date": 1666812987571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1160",
        "rarity_score": 134.34026291169147,
        "rarity_level": "COMMON"
    },
    "842": {
        "name": "Kadena Skellies #842",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/842.png",
        "dna": "38ff21b30c65bbaf2e3809df842f2b26e3015f6d",
        "edition": 842,
        "date": 1666812987642,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "842",
        "rarity_score": 117.23459753016404,
        "rarity_level": "COMMON"
    },
    "1191": {
        "name": "Kadena Skellies #1191",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1191.png",
        "dna": "26cfb6b55cda3a63d22abf20c6f3611ac744ae7e",
        "edition": 1191,
        "date": 1666812987712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1191",
        "rarity_score": 190.64208814993913,
        "rarity_level": "RARE"
    },
    "778": {
        "name": "Kadena Skellies #778",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/778.png",
        "dna": "c9cfd5f94fb6fe7ff4f68c6d2b4439713df1b0fb",
        "edition": 778,
        "date": 1666812987787,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "778",
        "rarity_score": 101.90835545836724,
        "rarity_level": "COMMON"
    },
    "1211": {
        "name": "Kadena Skellies #1211",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1211.png",
        "dna": "2a3b2f59c6f0787201b582698599b23d4208968b",
        "edition": 1211,
        "date": 1666812987858,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1211",
        "rarity_score": 211.78284405655097,
        "rarity_level": "RARE"
    },
    "829": {
        "name": "Kadena Skellies #829",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/829.png",
        "dna": "9a17aeb34b6d46a003e2c12ec0d3ade8ba2c1882",
        "edition": 829,
        "date": 1666812987929,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "829",
        "rarity_score": 167.5383842761584,
        "rarity_level": "COMMON"
    },
    "466": {
        "name": "Kadena Skellies #466",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/466.png",
        "dna": "01cce5e98830adb424bb2e5c9f2b0baa09238cd9",
        "edition": 466,
        "date": 1666812988002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "466",
        "rarity_score": 121.00934149253479,
        "rarity_level": "COMMON"
    },
    "82": {
        "name": "Kadena Skellies #82",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/82.png",
        "dna": "7c2ae6e0105da9df1a553eed6d8ce8ec4478c208",
        "edition": 82,
        "date": 1666812988072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "82",
        "rarity_score": 129.08397234113824,
        "rarity_level": "COMMON"
    },
    "767": {
        "name": "Kadena Skellies #767",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/767.png",
        "dna": "7b0a37d68770a71443784b4b2682726a9d808f62",
        "edition": 767,
        "date": 1666812988144,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "767",
        "rarity_score": 136.97430024645038,
        "rarity_level": "COMMON"
    },
    "835": {
        "name": "Kadena Skellies #835",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/835.png",
        "dna": "763d7e629d3bdc6d787cbae4d3f5db49b4947a35",
        "edition": 835,
        "date": 1666812988222,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "835",
        "rarity_score": 169.33177304437098,
        "rarity_level": "RARE"
    },
    "964": {
        "name": "Kadena Skellies #964",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/964.png",
        "dna": "041dce353e946f92a10ed3878eeb54105f7c893b",
        "edition": 964,
        "date": 1666812988292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "964",
        "rarity_score": 125.01373169935515,
        "rarity_level": "COMMON"
    },
    "944": {
        "name": "Kadena Skellies #944",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/944.png",
        "dna": "e63dd45ee0d3197f9bc6bf534c9a32e1433a04c6",
        "edition": 944,
        "date": 1666812988363,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "944",
        "rarity_score": 123.36016998301221,
        "rarity_level": "COMMON"
    },
    "1089": {
        "name": "Kadena Skellies #1089",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1089.png",
        "dna": "1d4e9f12a22f74a58ec18139612bd94137f0c02d",
        "edition": 1089,
        "date": 1666812988432,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1089",
        "rarity_score": 140.45229694802873,
        "rarity_level": "COMMON"
    },
    "1098": {
        "name": "Kadena Skellies #1098",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1098.png",
        "dna": "74a1e983553f3bc487ce5270c511af3b5d00d39f",
        "edition": 1098,
        "date": 1666812988502,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1098",
        "rarity_score": 168.3567864448013,
        "rarity_level": "COMMON"
    },
    "59": {
        "name": "Kadena Skellies #59",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/59.png",
        "dna": "c1384a4cc5ddeadf4c19d5b11d2bcece6224eaeb",
        "edition": 59,
        "date": 1666812988575,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "59",
        "rarity_score": 118.21729599862593,
        "rarity_level": "COMMON"
    },
    "284": {
        "name": "Kadena Skellies #284",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/284.png",
        "dna": "a4999dbed9f0563139891b30bb61c9e8332bb9c3",
        "edition": 284,
        "date": 1666812988644,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "284",
        "rarity_score": 126.26540613576968,
        "rarity_level": "COMMON"
    },
    "599": {
        "name": "Kadena Skellies #599",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/599.png",
        "dna": "47a70cffb20d6d5250c0d12eee1b2e76e30215f2",
        "edition": 599,
        "date": 1666812988714,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "599",
        "rarity_score": 143.94230841206962,
        "rarity_level": "COMMON"
    },
    "772": {
        "name": "Kadena Skellies #772",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/772.png",
        "dna": "e679daa032d1760649992fe512934107b4ca2f48",
        "edition": 772,
        "date": 1666812988785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "772",
        "rarity_score": 170.21251601237716,
        "rarity_level": "RARE"
    },
    "528": {
        "name": "Kadena Skellies #528",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/528.png",
        "dna": "30763b23b5c9e465eb23a2d5cfe04fcbee818b6c",
        "edition": 528,
        "date": 1666812988854,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "528",
        "rarity_score": 168.541960717652,
        "rarity_level": "COMMON"
    },
    "1208": {
        "name": "Kadena Skellies #1208",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1208.png",
        "dna": "a5e61b19aaf3e000197768f88da91da44891e0ec",
        "edition": 1208,
        "date": 1666812988925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1208",
        "rarity_score": 118.25873146181135,
        "rarity_level": "COMMON"
    },
    "753": {
        "name": "Kadena Skellies #753",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/753.png",
        "dna": "ee25f24fb61b9b31046421cc4ba8ee17a70167f2",
        "edition": 753,
        "date": 1666812988995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "753",
        "rarity_score": 115.61903113493922,
        "rarity_level": "COMMON"
    },
    "679": {
        "name": "Kadena Skellies #679",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/679.png",
        "dna": "070f2b1191090f94ee94dcd8c9259810918dbe51",
        "edition": 679,
        "date": 1666812989079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "679",
        "rarity_score": 165.44344155425543,
        "rarity_level": "COMMON"
    },
    "1013": {
        "name": "Kadena Skellies #1013",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1013.png",
        "dna": "0b97251c1a28227cde857fb81207bef520098d9c",
        "edition": 1013,
        "date": 1666812989157,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1013",
        "rarity_score": 106.98186946151738,
        "rarity_level": "COMMON"
    },
    "272": {
        "name": "Kadena Skellies #272",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/272.png",
        "dna": "949a90ec821c3372c86bf5a05ef40912f13b6790",
        "edition": 272,
        "date": 1666812989226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "272",
        "rarity_score": 96.98543503824983,
        "rarity_level": "COMMON"
    },
    "1035": {
        "name": "Kadena Skellies #1035",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1035.png",
        "dna": "fd7d5346febb1fd4a49da78fd993f021acda3d67",
        "edition": 1035,
        "date": 1666812989304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1035",
        "rarity_score": 245.18747187873967,
        "rarity_level": "RARE"
    },
    "1222": {
        "name": "Kadena Skellies #1222",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1222.png",
        "dna": "2b15354697b0edc8d6eb54e4c99d8f577d0960a4",
        "edition": 1222,
        "date": 1666812989375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1222",
        "rarity_score": 137.8286247572937,
        "rarity_level": "COMMON"
    },
    "339": {
        "name": "Kadena Skellies #339",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/339.png",
        "dna": "348c333c60f51f7834b6592bbb9b76e14bf85478",
        "edition": 339,
        "date": 1666812989445,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "339",
        "rarity_score": 115.92057367389464,
        "rarity_level": "COMMON"
    },
    "1117": {
        "name": "Kadena Skellies #1117",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1117.png",
        "dna": "e0ee6fb1bd70058daea833597379cd10799851ea",
        "edition": 1117,
        "date": 1666812989519,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1117",
        "rarity_score": 108.41320009509249,
        "rarity_level": "COMMON"
    },
    "882": {
        "name": "Kadena Skellies #882",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/882.png",
        "dna": "99e60054e75d13029db86b46348cc3a30fa27a57",
        "edition": 882,
        "date": 1666812989590,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "882",
        "rarity_score": 176.8826770643631,
        "rarity_level": "RARE"
    },
    "17": {
        "name": "Kadena Skellies #17",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/17.png",
        "dna": "d9aa2de6e5e82b444f15edd949e85da875ecb657",
        "edition": 17,
        "date": 1666812989662,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "17",
        "rarity_score": 122.44720995756622,
        "rarity_level": "COMMON"
    },
    "769": {
        "name": "Kadena Skellies #769",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/769.png",
        "dna": "cc5c9cb16c79d4a85831dae01b986e5c412d5cc2",
        "edition": 769,
        "date": 1666812989735,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "769",
        "rarity_score": 129.0067330797514,
        "rarity_level": "COMMON"
    },
    "1140": {
        "name": "Kadena Skellies #1140",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1140.png",
        "dna": "79e7ad0b8e82cb0fc1ec9d0ec25576005d37e80d",
        "edition": 1140,
        "date": 1666812989808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1140",
        "rarity_score": 108.35722521725837,
        "rarity_level": "COMMON"
    },
    "553": {
        "name": "Kadena Skellies #553",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/553.png",
        "dna": "d26f6c11d333cc99975305162de56da43f7e3074",
        "edition": 553,
        "date": 1666812989881,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "553",
        "rarity_score": 127.11172474591102,
        "rarity_level": "COMMON"
    },
    "509": {
        "name": "Kadena Skellies #509",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/509.png",
        "dna": "ed7c7d10fe817367274316b90543ed2f96a1ee3d",
        "edition": 509,
        "date": 1666812989954,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "509",
        "rarity_score": 132.08597179147478,
        "rarity_level": "COMMON"
    },
    "313": {
        "name": "Kadena Skellies #313",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/313.png",
        "dna": "19fdc7ee22c2cf514098c0cc501c2b22ed9e8ab4",
        "edition": 313,
        "date": 1666812990025,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "313",
        "rarity_score": 114.59483628331378,
        "rarity_level": "COMMON"
    },
    "867": {
        "name": "Kadena Skellies #867",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/867.png",
        "dna": "4e14210c86ff25fbb9060cff0a67cd1ec460707e",
        "edition": 867,
        "date": 1666812990108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Space Helmet",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "867",
        "rarity_score": 405.24103945181093,
        "rarity_level": "EPIC"
    },
    "1": {
        "name": "Kadena Skellies #1",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1.png",
        "dna": "90eeb3e907ca17f9b3d326e252b6445f3586e5f4",
        "edition": 1,
        "date": 1666812990187,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1",
        "rarity_score": 132.58300521600088,
        "rarity_level": "COMMON"
    },
    "1065": {
        "name": "Kadena Skellies #1065",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1065.png",
        "dna": "93b9c26b1f9484d85dd3e5f96c60cc760a506563",
        "edition": 1065,
        "date": 1666812990259,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1065",
        "rarity_score": 134.25423153221783,
        "rarity_level": "COMMON"
    },
    "929": {
        "name": "Kadena Skellies #929",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/929.png",
        "dna": "d5d9574a38cd76bb0fc522f1e5b36ca905188b24",
        "edition": 929,
        "date": 1666812990331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "929",
        "rarity_score": 122.16292189834729,
        "rarity_level": "COMMON"
    },
    "1108": {
        "name": "Kadena Skellies #1108",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1108.png",
        "dna": "85bd10e955284c8059baf6aafac9f56dd75121b9",
        "edition": 1108,
        "date": 1666812990401,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1108",
        "rarity_score": 135.5198540518882,
        "rarity_level": "COMMON"
    },
    "905": {
        "name": "Kadena Skellies #905",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/905.png",
        "dna": "c3c200adacc3468a2c6b060e44ae2b8acb2017c3",
        "edition": 905,
        "date": 1666812990477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "905",
        "rarity_score": 209.5146521545197,
        "rarity_level": "RARE"
    },
    "1116": {
        "name": "Kadena Skellies #1116",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1116.png",
        "dna": "431706adc0df123f6f8f91202c4ef67f49e32163",
        "edition": 1116,
        "date": 1666812990550,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1116",
        "rarity_score": 248.92298594616602,
        "rarity_level": "RARE"
    },
    "1248": {
        "name": "Kadena Skellies #1248",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1248.png",
        "dna": "8bbe1d3c78e59cf37c9788e37abc9b8d50150d00",
        "edition": 1248,
        "date": 1666812990621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1248",
        "rarity_score": 138.11807679141847,
        "rarity_level": "COMMON"
    },
    "225": {
        "name": "Kadena Skellies #225",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/225.png",
        "dna": "0323eb6c58fa0cc67f69a03b5efe7e1950feb2ce",
        "edition": 225,
        "date": 1666812990698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "225",
        "rarity_score": 126.71384940292504,
        "rarity_level": "COMMON"
    },
    "744": {
        "name": "Kadena Skellies #744",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/744.png",
        "dna": "fde21ea04d9bbc52df0f677c5404d617f0c38d46",
        "edition": 744,
        "date": 1666812990769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            { "trait_type": "Eyes", "value": "Red Cross Eyes", "score": 250.0 },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "744",
        "rarity_score": 385.89690942219033,
        "rarity_level": "EPIC"
    },
    "546": {
        "name": "Kadena Skellies #546",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/546.png",
        "dna": "655de68bb605786d25d7c2c39e186521475614af",
        "edition": 546,
        "date": 1666812990840,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "546",
        "rarity_score": 115.36306893579841,
        "rarity_level": "COMMON"
    },
    "815": {
        "name": "Kadena Skellies #815",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/815.png",
        "dna": "447c351b450a6dfa03a56f611aecff9d411b73d3",
        "edition": 815,
        "date": 1666812990914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "815",
        "rarity_score": 125.16281363085201,
        "rarity_level": "COMMON"
    },
    "155": {
        "name": "Kadena Skellies #155",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/155.png",
        "dna": "97c318a63095be115bf1b474177e8093c0134359",
        "edition": 155,
        "date": 1666812990985,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "155",
        "rarity_score": 128.38525682997462,
        "rarity_level": "COMMON"
    },
    "624": {
        "name": "Kadena Skellies #624",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/624.png",
        "dna": "d897a9f439ffc5e2a4ec4febc5d9b4195293416f",
        "edition": 624,
        "date": 1666812991055,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Round Kadena Glasses",
                "score": 250.0
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "624",
        "rarity_score": 323.62563133458184,
        "rarity_level": "RARE"
    },
    "1192": {
        "name": "Kadena Skellies #1192",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1192.png",
        "dna": "8dd16efd78a7de74d4672ba6d6315db10ad86c49",
        "edition": 1192,
        "date": 1666812991127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1192",
        "rarity_score": 113.22115620519816,
        "rarity_level": "COMMON"
    },
    "134": {
        "name": "Kadena Skellies #134",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/134.png",
        "dna": "ab0e828667efa8a2e5453574cf99fda41e782490",
        "edition": 134,
        "date": 1666812991202,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "134",
        "rarity_score": 126.4379758511894,
        "rarity_level": "COMMON"
    },
    "298": {
        "name": "Kadena Skellies #298",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/298.png",
        "dna": "f3d2fbf16a82c8ccbb9440000bfa7d0524c96ef3",
        "edition": 298,
        "date": 1666812991274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "298",
        "rarity_score": 132.0584275265749,
        "rarity_level": "COMMON"
    },
    "489": {
        "name": "Kadena Skellies #489",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/489.png",
        "dna": "042d3df28d22a9c63d0970cbc369e97264b7cf9e",
        "edition": 489,
        "date": 1666812991346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "489",
        "rarity_score": 145.16387238011097,
        "rarity_level": "COMMON"
    },
    "796": {
        "name": "Kadena Skellies #796",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/796.png",
        "dna": "43c4baf6ebed8eaa7632238fd83aca4e0165e007",
        "edition": 796,
        "date": 1666812991417,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "796",
        "rarity_score": 131.53383198982982,
        "rarity_level": "COMMON"
    },
    "374": {
        "name": "Kadena Skellies #374",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/374.png",
        "dna": "bd2087c85de0f44902a534aba22c9a2094c13438",
        "edition": 374,
        "date": 1666812991492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "374",
        "rarity_score": 135.3034446784447,
        "rarity_level": "COMMON"
    },
    "1131": {
        "name": "Kadena Skellies #1131",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1131.png",
        "dna": "83508981fab5707a03b3a2f81e6730c77c9536db",
        "edition": 1131,
        "date": 1666812991564,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1131",
        "rarity_score": 131.6619593839965,
        "rarity_level": "COMMON"
    },
    "140": {
        "name": "Kadena Skellies #140",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/140.png",
        "dna": "36bfdd80cc67f0867f4e1f734dda74f681ffc3a9",
        "edition": 140,
        "date": 1666812991637,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "140",
        "rarity_score": 175.61414354940646,
        "rarity_level": "RARE"
    },
    "522": {
        "name": "Kadena Skellies #522",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/522.png",
        "dna": "af6fb102e47a87fb04ac7d05dccbabbf2c4dae26",
        "edition": 522,
        "date": 1666812991711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "522",
        "rarity_score": 151.9082555394622,
        "rarity_level": "COMMON"
    },
    "1032": {
        "name": "Kadena Skellies #1032",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1032.png",
        "dna": "4395a49b7f0d534aadf5263b390969e8127c3980",
        "edition": 1032,
        "date": 1666812991784,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1032",
        "rarity_score": 138.985997731141,
        "rarity_level": "COMMON"
    },
    "11": {
        "name": "Kadena Skellies #11",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/11.png",
        "dna": "7f0f3f188f45193cd9780ae94f227b0c85a00e3d",
        "edition": 11,
        "date": 1666812991856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "11",
        "rarity_score": 173.27793979721304,
        "rarity_level": "RARE"
    },
    "57": {
        "name": "Kadena Skellies #57",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/57.png",
        "dna": "b4ff061305e98cabafff6c35bb17d8c7ea27bc15",
        "edition": 57,
        "date": 1666812991927,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "57",
        "rarity_score": 220.99426504122442,
        "rarity_level": "RARE"
    },
    "622": {
        "name": "Kadena Skellies #622",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/622.png",
        "dna": "aa6f7a40237c44727f5f6188cac155f7b6bd1415",
        "edition": 622,
        "date": 1666812991999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "622",
        "rarity_score": 115.13487630549547,
        "rarity_level": "COMMON"
    },
    "308": {
        "name": "Kadena Skellies #308",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/308.png",
        "dna": "4e4c00fe6811eb2af6c90d0ddc19dd71a22544e6",
        "edition": 308,
        "date": 1666812992074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "308",
        "rarity_score": 138.8189966931043,
        "rarity_level": "COMMON"
    },
    "849": {
        "name": "Kadena Skellies #849",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/849.png",
        "dna": "d43b88d31631c4993c3c0afbb29943872fa446ab",
        "edition": 849,
        "date": 1666812992147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "849",
        "rarity_score": 138.93794074140527,
        "rarity_level": "COMMON"
    },
    "1183": {
        "name": "Kadena Skellies #1183",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1183.png",
        "dna": "edb75be415070bf3f2dc54185f79dac8d5306cae",
        "edition": 1183,
        "date": 1666812992220,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1183",
        "rarity_score": 144.70791814541818,
        "rarity_level": "COMMON"
    },
    "889": {
        "name": "Kadena Skellies #889",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/889.png",
        "dna": "13896310abe9ebbbc25c61dcf7553c3aac98bc16",
        "edition": 889,
        "date": 1666812992293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "889",
        "rarity_score": 151.37043483899467,
        "rarity_level": "COMMON"
    },
    "5": {
        "name": "Kadena Skellies #5",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/5.png",
        "dna": "e90c32477d1582ea9138d14099e8e648882a4679",
        "edition": 5,
        "date": 1666812992364,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "5",
        "rarity_score": 134.62302094031563,
        "rarity_level": "COMMON"
    },
    "147": {
        "name": "Kadena Skellies #147",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/147.png",
        "dna": "02244cf1a2eff31d747055a01dfdb70618da76f5",
        "edition": 147,
        "date": 1666812992436,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "147",
        "rarity_score": 122.48519132153768,
        "rarity_level": "COMMON"
    },
    "663": {
        "name": "Kadena Skellies #663",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/663.png",
        "dna": "495da496ebcd16d630869938b66f8bbb8d8ea144",
        "edition": 663,
        "date": 1666812992508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "663",
        "rarity_score": 124.8050896755331,
        "rarity_level": "COMMON"
    },
    "559": {
        "name": "Kadena Skellies #559",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/559.png",
        "dna": "e298ed116d8d5dc64de7191cba8e2924a9c09d9b",
        "edition": 559,
        "date": 1666812992579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "559",
        "rarity_score": 131.36346355655817,
        "rarity_level": "COMMON"
    },
    "139": {
        "name": "Kadena Skellies #139",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/139.png",
        "dna": "ebcc5d17bfab7dfa1a5f7d85783d4f19aa463535",
        "edition": 139,
        "date": 1666812992655,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "139",
        "rarity_score": 138.35720866970868,
        "rarity_level": "COMMON"
    },
    "1230": {
        "name": "Kadena Skellies #1230",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1230.png",
        "dna": "96cc01cc8867a0888b3e72d8ace8c8da91fe964c",
        "edition": 1230,
        "date": 1666812992728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1230",
        "rarity_score": 283.87386723237785,
        "rarity_level": "RARE"
    },
    "332": {
        "name": "Kadena Skellies #332",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/332.png",
        "dna": "545b915bc88a25648c5f60e8fe245a939bea8821",
        "edition": 332,
        "date": 1666812992800,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "332",
        "rarity_score": 153.9751539243397,
        "rarity_level": "COMMON"
    },
    "923": {
        "name": "Kadena Skellies #923",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/923.png",
        "dna": "5b48673d1d824db46c2e767c6d7b1ea55febe121",
        "edition": 923,
        "date": 1666812992873,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "923",
        "rarity_score": 174.313365614912,
        "rarity_level": "RARE"
    },
    "729": {
        "name": "Kadena Skellies #729",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/729.png",
        "dna": "fbb28ee0361a8ac1eff409462afa07148a4f4686",
        "edition": 729,
        "date": 1666812992943,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "729",
        "rarity_score": 119.02424337680719,
        "rarity_level": "COMMON"
    },
    "1219": {
        "name": "Kadena Skellies #1219",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1219.png",
        "dna": "4e8ac8f8237e698fedbc1dec7ccf5f3465e40842",
        "edition": 1219,
        "date": 1666812993022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1219",
        "rarity_score": 313.04570100169883,
        "rarity_level": "RARE"
    },
    "1197": {
        "name": "Kadena Skellies #1197",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1197.png",
        "dna": "913693e130282692cbe237fdea2b3b2a02839b2d",
        "edition": 1197,
        "date": 1666812993097,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1197",
        "rarity_score": 168.42562457062837,
        "rarity_level": "COMMON"
    },
    "171": {
        "name": "Kadena Skellies #171",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/171.png",
        "dna": "6339d34af0eda3dbd30c91615398a446fb528eab",
        "edition": 171,
        "date": 1666812993169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "171",
        "rarity_score": 125.90678942548911,
        "rarity_level": "COMMON"
    },
    "937": {
        "name": "Kadena Skellies #937",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/937.png",
        "dna": "97d6fd08b6c3ebd7e76b9895ceb24b49342d5725",
        "edition": 937,
        "date": 1666812993244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "937",
        "rarity_score": 121.0645239783322,
        "rarity_level": "COMMON"
    },
    "475": {
        "name": "Kadena Skellies #475",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/475.png",
        "dna": "aaeed7394a450e67f1cfd709c3a9c48dc5094bda",
        "edition": 475,
        "date": 1666812993317,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "475",
        "rarity_score": 219.5050659815418,
        "rarity_level": "RARE"
    },
    "965": {
        "name": "Kadena Skellies #965",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/965.png",
        "dna": "152192962e30c62fda95aa249c6758d71bed5850",
        "edition": 965,
        "date": 1666812993390,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "965",
        "rarity_score": 155.74415768515146,
        "rarity_level": "COMMON"
    },
    "596": {
        "name": "Kadena Skellies #596",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/596.png",
        "dna": "81a6aa40eea746271ce977707938074afb3d5544",
        "edition": 596,
        "date": 1666812993470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "596",
        "rarity_score": 146.4785745071551,
        "rarity_level": "COMMON"
    },
    "208": {
        "name": "Kadena Skellies #208",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/208.png",
        "dna": "81e98a7582b1ae48c30a66757f5fef5bef2461ab",
        "edition": 208,
        "date": 1666812993541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "208",
        "rarity_score": 122.82166529525685,
        "rarity_level": "COMMON"
    },
    "398": {
        "name": "Kadena Skellies #398",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/398.png",
        "dna": "9eda9361aae8d58976dcc70300fb6925284470ec",
        "edition": 398,
        "date": 1666812993613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "398",
        "rarity_score": 109.58985008294945,
        "rarity_level": "COMMON"
    },
    "391": {
        "name": "Kadena Skellies #391",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/391.png",
        "dna": "42eb6625e8ce719e6b4b0be45053056f5a936dc9",
        "edition": 391,
        "date": 1666812993683,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "391",
        "rarity_score": 116.53130905027822,
        "rarity_level": "COMMON"
    },
    "710": {
        "name": "Kadena Skellies #710",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/710.png",
        "dna": "9d315ec4eecc7563444f5d2221fa0ffa5cb0b8a2",
        "edition": 710,
        "date": 1666812993752,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "710",
        "rarity_score": 136.4596550129629,
        "rarity_level": "COMMON"
    },
    "125": {
        "name": "Kadena Skellies #125",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/125.png",
        "dna": "1394089b800c1e42f64208df1371d2c6eb571a7b",
        "edition": 125,
        "date": 1666812993829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "125",
        "rarity_score": 128.73793487021786,
        "rarity_level": "COMMON"
    },
    "1111": {
        "name": "Kadena Skellies #1111",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1111.png",
        "dna": "261fce090e810dbabb59fc546bb4efae37422491",
        "edition": 1111,
        "date": 1666812993900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1111",
        "rarity_score": 100.74413619242841,
        "rarity_level": "COMMON"
    },
    "625": {
        "name": "Kadena Skellies #625",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/625.png",
        "dna": "a256c7fb940905876213b9d936d9d1872cc01000",
        "edition": 625,
        "date": 1666812993972,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "625",
        "rarity_score": 127.21951606010269,
        "rarity_level": "COMMON"
    },
    "263": {
        "name": "Kadena Skellies #263",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/263.png",
        "dna": "aa99c162ed101d29a17d1838407ff541bb5861c1",
        "edition": 263,
        "date": 1666812994043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "263",
        "rarity_score": 126.37787628681266,
        "rarity_level": "COMMON"
    },
    "855": {
        "name": "Kadena Skellies #855",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/855.png",
        "dna": "1f23efaf1ff44018f3e18beab1e01fa0a5468438",
        "edition": 855,
        "date": 1666812994121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "855",
        "rarity_score": 220.15988021789425,
        "rarity_level": "RARE"
    },
    "70": {
        "name": "Kadena Skellies #70",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/70.png",
        "dna": "bbb38362b5054b4313bd84513479f9e6b1681e2c",
        "edition": 70,
        "date": 1666812994198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "70",
        "rarity_score": 133.6768417839054,
        "rarity_level": "COMMON"
    },
    "222": {
        "name": "Kadena Skellies #222",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/222.png",
        "dna": "08bd670c5706a5dc2402712dee71ba66cfdcc0fd",
        "edition": 222,
        "date": 1666812994269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "222",
        "rarity_score": 125.61544772452672,
        "rarity_level": "COMMON"
    },
    "330": {
        "name": "Kadena Skellies #330",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/330.png",
        "dna": "51003349846f10437d58d3664aea7e5f1f1b0e47",
        "edition": 330,
        "date": 1666812994341,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "330",
        "rarity_score": 132.5638092002295,
        "rarity_level": "COMMON"
    },
    "35": {
        "name": "Kadena Skellies #35",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/35.png",
        "dna": "0cb56351e3c3f192b5b863baea24c49623e26964",
        "edition": 35,
        "date": 1666812994413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "35",
        "rarity_score": 166.32960141440176,
        "rarity_level": "COMMON"
    },
    "1091": {
        "name": "Kadena Skellies #1091",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1091.png",
        "dna": "6bd52f2e8307f24e2f93d53e3d2133ddbea953a7",
        "edition": 1091,
        "date": 1666812994484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1091",
        "rarity_score": 105.21850606686603,
        "rarity_level": "COMMON"
    },
    "967": {
        "name": "Kadena Skellies #967",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/967.png",
        "dna": "f0b367927bc2daa1c1a3c31a2e62808831c008f8",
        "edition": 967,
        "date": 1666812994554,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "967",
        "rarity_score": 139.89910907901896,
        "rarity_level": "COMMON"
    },
    "89": {
        "name": "Kadena Skellies #89",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/89.png",
        "dna": "defd7b586498db53f57b09fbdd45e14afa50bd8b",
        "edition": 89,
        "date": 1666812994626,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "89",
        "rarity_score": 124.71527968553116,
        "rarity_level": "COMMON"
    },
    "143": {
        "name": "Kadena Skellies #143",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/143.png",
        "dna": "280d00dc781b794246fd1c618c06d9991853500c",
        "edition": 143,
        "date": 1666812994696,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "143",
        "rarity_score": 142.99954302030068,
        "rarity_level": "COMMON"
    },
    "589": {
        "name": "Kadena Skellies #589",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/589.png",
        "dna": "c97517f709f5da3069fb40ca95b3a33f694f5b1e",
        "edition": 589,
        "date": 1666812994771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "589",
        "rarity_score": 184.29236272761455,
        "rarity_level": "RARE"
    },
    "1181": {
        "name": "Kadena Skellies #1181",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1181.png",
        "dna": "7f0c328385c7513445f2fe87c6ed7421e7428524",
        "edition": 1181,
        "date": 1666812994841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1181",
        "rarity_score": 175.76449194082372,
        "rarity_level": "RARE"
    },
    "789": {
        "name": "Kadena Skellies #789",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/789.png",
        "dna": "1a74ac9ecb93eebb5bada18c5a45f79b3a916fec",
        "edition": 789,
        "date": 1666812994912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "789",
        "rarity_score": 256.67231810037003,
        "rarity_level": "RARE"
    },
    "743": {
        "name": "Kadena Skellies #743",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/743.png",
        "dna": "7dc0f7ffb2ec567002ebd589bf6661e057767784",
        "edition": 743,
        "date": 1666812994988,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "743",
        "rarity_score": 127.87097064302668,
        "rarity_level": "COMMON"
    },
    "230": {
        "name": "Kadena Skellies #230",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/230.png",
        "dna": "e288a1fb30b45ce37057c7765b8fece2e69ff988",
        "edition": 230,
        "date": 1666812995061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "230",
        "rarity_score": 161.60878736079246,
        "rarity_level": "COMMON"
    },
    "493": {
        "name": "Kadena Skellies #493",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/493.png",
        "dna": "d781518c67380d664214af41e47d8f72adc60db6",
        "edition": 493,
        "date": 1666812995137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "493",
        "rarity_score": 121.38269549674189,
        "rarity_level": "COMMON"
    },
    "692": {
        "name": "Kadena Skellies #692",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/692.png",
        "dna": "c4811f0eaeab6c578bcdccd576ae575d1d5baf82",
        "edition": 692,
        "date": 1666812995208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "692",
        "rarity_score": 154.13488922616958,
        "rarity_level": "COMMON"
    },
    "948": {
        "name": "Kadena Skellies #948",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/948.png",
        "dna": "884ec9cb3c06b3bb7cfb31fa640c50a3b66901af",
        "edition": 948,
        "date": 1666812995279,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "948",
        "rarity_score": 231.62205151563512,
        "rarity_level": "RARE"
    },
    "301": {
        "name": "Kadena Skellies #301",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/301.png",
        "dna": "5bb230cb79308893cc165cad1d988152ac7ca958",
        "edition": 301,
        "date": 1666812995352,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "301",
        "rarity_score": 121.78887260415601,
        "rarity_level": "COMMON"
    },
    "107": {
        "name": "Kadena Skellies #107",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/107.png",
        "dna": "e7583157c9840afc7cb8b299a87f00ac463ee894",
        "edition": 107,
        "date": 1666812995422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "107",
        "rarity_score": 123.1796574989892,
        "rarity_level": "COMMON"
    },
    "160": {
        "name": "Kadena Skellies #160",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/160.png",
        "dna": "360cdbda5f0bee0261db3d1d774cbe38d7d9a564",
        "edition": 160,
        "date": 1666812995493,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "160",
        "rarity_score": 142.58315922098004,
        "rarity_level": "COMMON"
    },
    "773": {
        "name": "Kadena Skellies #773",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/773.png",
        "dna": "f89097bc8c7851226eaafa4c0fb47f158e5b8fe0",
        "edition": 773,
        "date": 1666812995567,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "773",
        "rarity_score": 127.56744557079737,
        "rarity_level": "COMMON"
    },
    "587": {
        "name": "Kadena Skellies #587",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/587.png",
        "dna": "62ff71d3dac90dd52c4eecffdbe14dd05f4e7e6c",
        "edition": 587,
        "date": 1666812995641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "587",
        "rarity_score": 238.80813203371136,
        "rarity_level": "RARE"
    },
    "531": {
        "name": "Kadena Skellies #531",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/531.png",
        "dna": "281f55d458a3f064791dca0ae706551af0e658e3",
        "edition": 531,
        "date": 1666812995713,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "531",
        "rarity_score": 140.79099674659093,
        "rarity_level": "COMMON"
    },
    "69": {
        "name": "Kadena Skellies #69",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/69.png",
        "dna": "9cdc3ee729985b6cae039de4d4aebc2bf05c4e7d",
        "edition": 69,
        "date": 1666812995783,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "69",
        "rarity_score": 103.89765069588759,
        "rarity_level": "COMMON"
    },
    "1168": {
        "name": "Kadena Skellies #1168",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1168.png",
        "dna": "d2bb5a8447cbf2597b2bd6a29cf24370b3269b09",
        "edition": 1168,
        "date": 1666812995861,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1168",
        "rarity_score": 156.6106107164234,
        "rarity_level": "COMMON"
    },
    "562": {
        "name": "Kadena Skellies #562",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/562.png",
        "dna": "a733948f3ed5b6390cea660717afa003f57631c9",
        "edition": 562,
        "date": 1666812995936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "562",
        "rarity_score": 139.94379131443665,
        "rarity_level": "COMMON"
    },
    "344": {
        "name": "Kadena Skellies #344",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/344.png",
        "dna": "d40c8520917614fdf0e3eef916eed9f998be5e4d",
        "edition": 344,
        "date": 1666812996007,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "344",
        "rarity_score": 138.49277013797968,
        "rarity_level": "COMMON"
    },
    "39": {
        "name": "Kadena Skellies #39",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/39.png",
        "dna": "433b95548cd4b67fb67a8f9ff121b4ef4b04c32a",
        "edition": 39,
        "date": 1666812996078,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "39",
        "rarity_score": 147.42445903885897,
        "rarity_level": "COMMON"
    },
    "258": {
        "name": "Kadena Skellies #258",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/258.png",
        "dna": "36753fc9e86fe1f609007aea257b94dee7f51970",
        "edition": 258,
        "date": 1666812996154,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "258",
        "rarity_score": 232.6173476470917,
        "rarity_level": "RARE"
    },
    "1229": {
        "name": "Kadena Skellies #1229",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1229.png",
        "dna": "da347442d413e6df43efe72fdc5e52b01e95b010",
        "edition": 1229,
        "date": 1666812996227,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1229",
        "rarity_score": 134.34749105915782,
        "rarity_level": "COMMON"
    },
    "324": {
        "name": "Kadena Skellies #324",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/324.png",
        "dna": "c263ab80c1ce8be44fe8be0140010f2dbfb8fe25",
        "edition": 324,
        "date": 1666812996298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "324",
        "rarity_score": 106.06725273338313,
        "rarity_level": "COMMON"
    },
    "250": {
        "name": "Kadena Skellies #250",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/250.png",
        "dna": "f6326c794314ec4ae2a1e4568004dbf05b6b4c10",
        "edition": 250,
        "date": 1666812996371,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "250",
        "rarity_score": 132.87694024582729,
        "rarity_level": "COMMON"
    },
    "499": {
        "name": "Kadena Skellies #499",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/499.png",
        "dna": "97a41ff112f20f334dfd222eab123f7128d94fc8",
        "edition": 499,
        "date": 1666812996444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "499",
        "rarity_score": 128.17081776823497,
        "rarity_level": "COMMON"
    },
    "886": {
        "name": "Kadena Skellies #886",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/886.png",
        "dna": "5e94b123f7ed0fa3a45ec023330e293e5600178c",
        "edition": 886,
        "date": 1666812996519,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "886",
        "rarity_score": 166.86875674529776,
        "rarity_level": "COMMON"
    },
    "981": {
        "name": "Kadena Skellies #981",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/981.png",
        "dna": "77e46aca254e64f83fc89eb29a41186f7d42fec4",
        "edition": 981,
        "date": 1666812996590,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "981",
        "rarity_score": 124.41407863122606,
        "rarity_level": "COMMON"
    },
    "777": {
        "name": "Kadena Skellies #777",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/777.png",
        "dna": "749cb2f9f32587663013daf08b8b5d3120c07242",
        "edition": 777,
        "date": 1666812996662,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "777",
        "rarity_score": 149.69309507634546,
        "rarity_level": "COMMON"
    },
    "389": {
        "name": "Kadena Skellies #389",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/389.png",
        "dna": "17eecbb14e96aa1c2406eb5b79a08d684efb7cc0",
        "edition": 389,
        "date": 1666812996745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Skull",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "389",
        "rarity_score": 431.3074035951394,
        "rarity_level": "EPIC"
    },
    "678": {
        "name": "Kadena Skellies #678",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/678.png",
        "dna": "fe5333cba79015e5441887749db21860fb827e82",
        "edition": 678,
        "date": 1666812996819,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Fiery Eyes",
                "score": 416.6666666666667
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "678",
        "rarity_score": 675.4344254344254,
        "rarity_level": "LEGENDARY"
    },
    "638": {
        "name": "Kadena Skellies #638",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/638.png",
        "dna": "6a93537b1f66a878979f9ff2f90bbee33bd6b32e",
        "edition": 638,
        "date": 1666812996891,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "638",
        "rarity_score": 126.41050787866465,
        "rarity_level": "COMMON"
    },
    "1059": {
        "name": "Kadena Skellies #1059",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1059.png",
        "dna": "fdf3fb74c8ed41f784fcc0908510cb9bbe7791e4",
        "edition": 1059,
        "date": 1666812996967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1059",
        "rarity_score": 217.84503137102317,
        "rarity_level": "RARE"
    },
    "834": {
        "name": "Kadena Skellies #834",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/834.png",
        "dna": "35f462637116cee72a227cc63487d81059bc5ce5",
        "edition": 834,
        "date": 1666812997039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "834",
        "rarity_score": 202.07575001408858,
        "rarity_level": "RARE"
    },
    "1021": {
        "name": "Kadena Skellies #1021",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1021.png",
        "dna": "9cf5b440edd365bed0ad171eeb407103fc82b216",
        "edition": 1021,
        "date": 1666812997112,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1021",
        "rarity_score": 115.65341031765995,
        "rarity_level": "COMMON"
    },
    "921": {
        "name": "Kadena Skellies #921",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/921.png",
        "dna": "ad08e9788539abe87eb33a72b0f823698b8e4678",
        "edition": 921,
        "date": 1666812997187,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "921",
        "rarity_score": 134.09996208197285,
        "rarity_level": "COMMON"
    },
    "854": {
        "name": "Kadena Skellies #854",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/854.png",
        "dna": "d66881750374247686127e2c70395101d062f953",
        "edition": 854,
        "date": 1666812997261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "854",
        "rarity_score": 153.95262415221907,
        "rarity_level": "COMMON"
    },
    "286": {
        "name": "Kadena Skellies #286",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/286.png",
        "dna": "d25ac10dc1e4a811a7978d714d0aa4647e601be9",
        "edition": 286,
        "date": 1666812997337,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "286",
        "rarity_score": 140.27951198336595,
        "rarity_level": "COMMON"
    },
    "326": {
        "name": "Kadena Skellies #326",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/326.png",
        "dna": "3b36480a2306e1d016d814e9066f29ebcbb460fb",
        "edition": 326,
        "date": 1666812997410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "326",
        "rarity_score": 149.21104052097286,
        "rarity_level": "COMMON"
    },
    "1092": {
        "name": "Kadena Skellies #1092",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1092.png",
        "dna": "9c1bea5d4d9d5ae67077fb4bc07de86834f76d30",
        "edition": 1092,
        "date": 1666812997483,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1092",
        "rarity_score": 180.05531748459939,
        "rarity_level": "RARE"
    },
    "337": {
        "name": "Kadena Skellies #337",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/337.png",
        "dna": "364aae3c9379855b9818af8b825486b1aef5067b",
        "edition": 337,
        "date": 1666812997555,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "337",
        "rarity_score": 156.60042099352614,
        "rarity_level": "COMMON"
    },
    "803": {
        "name": "Kadena Skellies #803",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/803.png",
        "dna": "1d945a936351e1289259d09728e10afb03b73a32",
        "edition": 803,
        "date": 1666812997626,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "803",
        "rarity_score": 149.88153438105743,
        "rarity_level": "COMMON"
    },
    "690": {
        "name": "Kadena Skellies #690",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/690.png",
        "dna": "362785c5b57d02b1aa3237afe9a123125986a460",
        "edition": 690,
        "date": 1666812997698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "690",
        "rarity_score": 143.3317774404571,
        "rarity_level": "COMMON"
    },
    "1242": {
        "name": "Kadena Skellies #1242",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1242.png",
        "dna": "3449b71e90a62601d80b397afa2f711a0a5d46bd",
        "edition": 1242,
        "date": 1666812997770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1242",
        "rarity_score": 123.05684769201625,
        "rarity_level": "COMMON"
    },
    "1057": {
        "name": "Kadena Skellies #1057",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1057.png",
        "dna": "a3e8598c35451d2e0a1a2cb9d8b20c449408c3ac",
        "edition": 1057,
        "date": 1666812997841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1057",
        "rarity_score": 117.12043467909683,
        "rarity_level": "COMMON"
    },
    "966": {
        "name": "Kadena Skellies #966",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/966.png",
        "dna": "1407043f4a7cdd678c559d73b241518b5e68ee9c",
        "edition": 966,
        "date": 1666812997919,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "966",
        "rarity_score": 123.11409002585474,
        "rarity_level": "COMMON"
    },
    "650": {
        "name": "Kadena Skellies #650",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/650.png",
        "dna": "cf3bd1069fef6131f7876b5133a60cbdd260ee8a",
        "edition": 650,
        "date": 1666812997993,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "650",
        "rarity_score": 198.12521856968002,
        "rarity_level": "RARE"
    },
    "570": {
        "name": "Kadena Skellies #570",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/570.png",
        "dna": "811efe7c25c0b05f62fb7bce6ac4e6c4ad4eeffb",
        "edition": 570,
        "date": 1666812998065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "570",
        "rarity_score": 157.99539707285945,
        "rarity_level": "COMMON"
    },
    "498": {
        "name": "Kadena Skellies #498",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/498.png",
        "dna": "b08360120631b3565d25194aa7d895706d532db5",
        "edition": 498,
        "date": 1666812998136,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "498",
        "rarity_score": 121.22122456898748,
        "rarity_level": "COMMON"
    },
    "141": {
        "name": "Kadena Skellies #141",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/141.png",
        "dna": "53259d96cb41ed2e0ecb2a914402470b9bbd6c6c",
        "edition": 141,
        "date": 1666812998207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "141",
        "rarity_score": 189.046840954142,
        "rarity_level": "RARE"
    },
    "1129": {
        "name": "Kadena Skellies #1129",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1129.png",
        "dna": "766739afd3bfb4c1e29b11231fc4b433c50bdcc9",
        "edition": 1129,
        "date": 1666812998279,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1129",
        "rarity_score": 173.97272931354257,
        "rarity_level": "RARE"
    },
    "220": {
        "name": "Kadena Skellies #220",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/220.png",
        "dna": "cac5954e7193bf95c109f07925f2f964ee25c049",
        "edition": 220,
        "date": 1666812998358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "220",
        "rarity_score": 132.73744918162396,
        "rarity_level": "COMMON"
    },
    "393": {
        "name": "Kadena Skellies #393",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/393.png",
        "dna": "2104914579f4230fb597ee198651b56b46df7592",
        "edition": 393,
        "date": 1666812998430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "393",
        "rarity_score": 146.11304491637435,
        "rarity_level": "COMMON"
    },
    "1014": {
        "name": "Kadena Skellies #1014",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1014.png",
        "dna": "b3223c3e02c1d343d4406892c176d45151711065",
        "edition": 1014,
        "date": 1666812998502,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1014",
        "rarity_score": 152.86378427295372,
        "rarity_level": "COMMON"
    },
    "119": {
        "name": "Kadena Skellies #119",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/119.png",
        "dna": "13e0149d27c0dd02b69354d5fff84acbf45661f4",
        "edition": 119,
        "date": 1666812998574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "119",
        "rarity_score": 184.29250025271332,
        "rarity_level": "RARE"
    },
    "239": {
        "name": "Kadena Skellies #239",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/239.png",
        "dna": "13479968478a825b89eab7ab6f60f869b2d14dca",
        "edition": 239,
        "date": 1666812998647,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "239",
        "rarity_score": 220.40679776957856,
        "rarity_level": "RARE"
    },
    "969": {
        "name": "Kadena Skellies #969",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/969.png",
        "dna": "0b643df317e8314a034d3d779419168dcbc539fc",
        "edition": 969,
        "date": 1666812998720,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "969",
        "rarity_score": 185.04265991240973,
        "rarity_level": "RARE"
    },
    "1058": {
        "name": "Kadena Skellies #1058",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1058.png",
        "dna": "1750e5d63d5514a0870dcd8590258143259a7697",
        "edition": 1058,
        "date": 1666812998792,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1058",
        "rarity_score": 187.60169258462153,
        "rarity_level": "RARE"
    },
    "555": {
        "name": "Kadena Skellies #555",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/555.png",
        "dna": "b2a6c5c7365bbfe83636093e85f86dd0d753aefa",
        "edition": 555,
        "date": 1666812998863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "555",
        "rarity_score": 247.43446572723204,
        "rarity_level": "RARE"
    },
    "884": {
        "name": "Kadena Skellies #884",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/884.png",
        "dna": "ecbcf0aca4a0e20c59aaf88b9091b0e4975c2bd3",
        "edition": 884,
        "date": 1666812998935,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "884",
        "rarity_score": 117.61983188005698,
        "rarity_level": "COMMON"
    },
    "124": {
        "name": "Kadena Skellies #124",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/124.png",
        "dna": "61312fd3e882cab579888e9026b7775a6438e8ff",
        "edition": 124,
        "date": 1666812999006,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "124",
        "rarity_score": 103.25804471610246,
        "rarity_level": "COMMON"
    },
    "161": {
        "name": "Kadena Skellies #161",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/161.png",
        "dna": "e37e237b7f794e3eac5550d5268008dd9f4349c9",
        "edition": 161,
        "date": 1666812999079,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "161",
        "rarity_score": 178.1771732493556,
        "rarity_level": "RARE"
    },
    "341": {
        "name": "Kadena Skellies #341",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/341.png",
        "dna": "22189234578742329c63a7405a6f04e4cce85c48",
        "edition": 341,
        "date": 1666812999150,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Skull",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "341",
        "rarity_score": 316.1497201193721,
        "rarity_level": "RARE"
    },
    "163": {
        "name": "Kadena Skellies #163",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/163.png",
        "dna": "d881f9f44bfb9dc2cbb5c8e9009a657c89e9dffc",
        "edition": 163,
        "date": 1666812999230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "163",
        "rarity_score": 125.86013049499043,
        "rarity_level": "COMMON"
    },
    "300": {
        "name": "Kadena Skellies #300",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/300.png",
        "dna": "6281de4e21c644b8b950ac3c961335c1da6b50cc",
        "edition": 300,
        "date": 1666812999304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "300",
        "rarity_score": 81.47141326837149,
        "rarity_level": "COMMON"
    },
    "995": {
        "name": "Kadena Skellies #995",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/995.png",
        "dna": "906e0cf5cf557f3071cea29f6055a68df43f8923",
        "edition": 995,
        "date": 1666812999375,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "995",
        "rarity_score": 163.00809384907149,
        "rarity_level": "COMMON"
    },
    "1118": {
        "name": "Kadena Skellies #1118",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1118.png",
        "dna": "0835b7f0699ab3c1e7f4966a773fcfda95cfa4d6",
        "edition": 1118,
        "date": 1666812999449,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1118",
        "rarity_score": 124.53017921767923,
        "rarity_level": "COMMON"
    },
    "439": {
        "name": "Kadena Skellies #439",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/439.png",
        "dna": "e09fc201c3534a27d38f08df82ab516ed15fbff5",
        "edition": 439,
        "date": 1666812999521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "439",
        "rarity_score": 104.70894970925546,
        "rarity_level": "COMMON"
    },
    "244": {
        "name": "Kadena Skellies #244",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/244.png",
        "dna": "a79ef8d0d665a55fa8e70d78c43a3918810396e7",
        "edition": 244,
        "date": 1666812999593,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "244",
        "rarity_score": 124.80966250957306,
        "rarity_level": "COMMON"
    },
    "1240": {
        "name": "Kadena Skellies #1240",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1240.png",
        "dna": "4d86db4bf35b971afb7e1a4ead7604248c66a544",
        "edition": 1240,
        "date": 1666812999665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1240",
        "rarity_score": 140.3640726770054,
        "rarity_level": "COMMON"
    },
    "720": {
        "name": "Kadena Skellies #720",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/720.png",
        "dna": "c20fa7c0a56dfc77acd8df05fcf5ff9b923f2aa6",
        "edition": 720,
        "date": 1666812999736,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "720",
        "rarity_score": 119.6450758812153,
        "rarity_level": "COMMON"
    },
    "503": {
        "name": "Kadena Skellies #503",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/503.png",
        "dna": "dbb66a1b9a7968d78a4a0b650e9bd8416fec9b31",
        "edition": 503,
        "date": 1666812999808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "503",
        "rarity_score": 129.32250663955105,
        "rarity_level": "COMMON"
    },
    "592": {
        "name": "Kadena Skellies #592",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/592.png",
        "dna": "a5d11ad8c8cb4c66b93b43bdbe9afd4e256033f6",
        "edition": 592,
        "date": 1666812999883,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "592",
        "rarity_score": 183.28210471476785,
        "rarity_level": "RARE"
    },
    "1169": {
        "name": "Kadena Skellies #1169",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1169.png",
        "dna": "a773d8223ae0aac578464dfff9a6a050d6031e4f",
        "edition": 1169,
        "date": 1666812999955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1169",
        "rarity_score": 134.4633833857972,
        "rarity_level": "COMMON"
    },
    "705": {
        "name": "Kadena Skellies #705",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/705.png",
        "dna": "ed32778dfd728118b58590a8222b72075357af1e",
        "edition": 705,
        "date": 1666813000026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "705",
        "rarity_score": 151.3591714830105,
        "rarity_level": "COMMON"
    },
    "486": {
        "name": "Kadena Skellies #486",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/486.png",
        "dna": "2861e62c9529efffe543a09ec24b3aa879072b0d",
        "edition": 486,
        "date": 1666813000098,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "486",
        "rarity_score": 143.58996911942583,
        "rarity_level": "COMMON"
    },
    "968": {
        "name": "Kadena Skellies #968",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/968.png",
        "dna": "789ce6a27dfe4cda08b1acc32e033fa1adcf0809",
        "edition": 968,
        "date": 1666813000170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "968",
        "rarity_score": 269.13942472453107,
        "rarity_level": "RARE"
    },
    "1143": {
        "name": "Kadena Skellies #1143",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1143.png",
        "dna": "6546dc8477a9e9d412796efb4d19562e1ce584c7",
        "edition": 1143,
        "date": 1666813000242,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1143",
        "rarity_score": 125.99662530621833,
        "rarity_level": "COMMON"
    },
    "461": {
        "name": "Kadena Skellies #461",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/461.png",
        "dna": "dae176cb5cbf4f1795df1c516fe62dc353ee6e2b",
        "edition": 461,
        "date": 1666813000314,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "461",
        "rarity_score": 188.1864767861153,
        "rarity_level": "RARE"
    },
    "620": {
        "name": "Kadena Skellies #620",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/620.png",
        "dna": "6e8870bb2ec5c412eaaa0b1152f22dbd4c6031d1",
        "edition": 620,
        "date": 1666813000385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "620",
        "rarity_score": 117.98662455199153,
        "rarity_level": "COMMON"
    },
    "145": {
        "name": "Kadena Skellies #145",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/145.png",
        "dna": "3410e4ebd037ac0ed73898c14515bfb90d883bad",
        "edition": 145,
        "date": 1666813000462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "145",
        "rarity_score": 121.20655510055022,
        "rarity_level": "COMMON"
    },
    "641": {
        "name": "Kadena Skellies #641",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/641.png",
        "dna": "c3b8e3b416129211da02623f5cbd93a93a822c22",
        "edition": 641,
        "date": 1666813000533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "641",
        "rarity_score": 109.26737094783535,
        "rarity_level": "COMMON"
    },
    "412": {
        "name": "Kadena Skellies #412",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/412.png",
        "dna": "abb143e7fedcd253beea225b075db2195bffcd11",
        "edition": 412,
        "date": 1666813000609,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "412",
        "rarity_score": 155.5563276822649,
        "rarity_level": "COMMON"
    },
    "277": {
        "name": "Kadena Skellies #277",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/277.png",
        "dna": "657be4ba7e1c9e1fb003e7b187ed5a1f5a6f4bef",
        "edition": 277,
        "date": 1666813000681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "277",
        "rarity_score": 160.53122467897094,
        "rarity_level": "COMMON"
    },
    "10": {
        "name": "Kadena Skellies #10",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/10.png",
        "dna": "218bc80d78a43007bab38e7dfcc3aa2c046d98be",
        "edition": 10,
        "date": 1666813000754,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "10",
        "rarity_score": 124.78234641644517,
        "rarity_level": "COMMON"
    },
    "1214": {
        "name": "Kadena Skellies #1214",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1214.png",
        "dna": "a9110060c015a6c16cde41d833670ef39f6eb75e",
        "edition": 1214,
        "date": 1666813000832,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1214",
        "rarity_score": 163.0306079485358,
        "rarity_level": "COMMON"
    },
    "83": {
        "name": "Kadena Skellies #83",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/83.png",
        "dna": "33cd8dbcf131806560db57ff8db5381cf8f77a67",
        "edition": 83,
        "date": 1666813000903,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "83",
        "rarity_score": 140.94548380532072,
        "rarity_level": "COMMON"
    },
    "42": {
        "name": "Kadena Skellies #42",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/42.png",
        "dna": "7f2efaceec06241b04491240442d893330878345",
        "edition": 42,
        "date": 1666813000974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "42",
        "rarity_score": 199.67787733817266,
        "rarity_level": "RARE"
    },
    "908": {
        "name": "Kadena Skellies #908",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/908.png",
        "dna": "395f433d222616f3cf0576af5a21544e1599a61c",
        "edition": 908,
        "date": 1666813001050,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "908",
        "rarity_score": 140.9966081104724,
        "rarity_level": "COMMON"
    },
    "694": {
        "name": "Kadena Skellies #694",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/694.png",
        "dna": "5a7ffb79129afb6a2e038589242328094267172b",
        "edition": 694,
        "date": 1666813001124,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "694",
        "rarity_score": 181.23676628831265,
        "rarity_level": "RARE"
    },
    "654": {
        "name": "Kadena Skellies #654",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/654.png",
        "dna": "20f895888dfad676639030e22e8e6de26516297b",
        "edition": 654,
        "date": 1666813001196,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "654",
        "rarity_score": 103.31793086181132,
        "rarity_level": "COMMON"
    },
    "1226": {
        "name": "Kadena Skellies #1226",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1226.png",
        "dna": "1bd68ca426b3d86a998f68304248fa1337003fce",
        "edition": 1226,
        "date": 1666813001268,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1226",
        "rarity_score": 119.96530522587948,
        "rarity_level": "COMMON"
    },
    "102": {
        "name": "Kadena Skellies #102",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/102.png",
        "dna": "22f317993030ea9025d40cc2df564bf2c526e7d2",
        "edition": 102,
        "date": 1666813001340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "102",
        "rarity_score": 192.05942987671517,
        "rarity_level": "RARE"
    },
    "770": {
        "name": "Kadena Skellies #770",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/770.png",
        "dna": "783cf18f9a4cec2acde172e41d7e9f8d361e84d6",
        "edition": 770,
        "date": 1666813001419,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "770",
        "rarity_score": 157.27158105206885,
        "rarity_level": "COMMON"
    },
    "851": {
        "name": "Kadena Skellies #851",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/851.png",
        "dna": "ae8b86211ef1a830d3eaa62fd5cc1fbde3f1d053",
        "edition": 851,
        "date": 1666813001490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "851",
        "rarity_score": 135.40597301003243,
        "rarity_level": "COMMON"
    },
    "517": {
        "name": "Kadena Skellies #517",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/517.png",
        "dna": "c057a3dd74a94832f6db1d60bc6e78e2af05fe2e",
        "edition": 517,
        "date": 1666813001562,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "517",
        "rarity_score": 105.94168868328263,
        "rarity_level": "COMMON"
    },
    "294": {
        "name": "Kadena Skellies #294",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/294.png",
        "dna": "1ef63492daa631c308f4b8c8d53ef507325e7708",
        "edition": 294,
        "date": 1666813001636,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "294",
        "rarity_score": 136.69923851337197,
        "rarity_level": "COMMON"
    },
    "728": {
        "name": "Kadena Skellies #728",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/728.png",
        "dna": "375c27d4af32704edfc87a7d88c4eab42310dadc",
        "edition": 728,
        "date": 1666813001708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "728",
        "rarity_score": 280.00944063622757,
        "rarity_level": "RARE"
    },
    "173": {
        "name": "Kadena Skellies #173",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/173.png",
        "dna": "3d55160ec0c5723dffe2df000d16e3f33a2529a3",
        "edition": 173,
        "date": 1666813001779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "173",
        "rarity_score": 273.87353223446064,
        "rarity_level": "RARE"
    },
    "334": {
        "name": "Kadena Skellies #334",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/334.png",
        "dna": "30daf4ee1ae6c37a23775338ae49ebe815706e37",
        "edition": 334,
        "date": 1666813001851,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "334",
        "rarity_score": 210.99648014962804,
        "rarity_level": "RARE"
    },
    "1110": {
        "name": "Kadena Skellies #1110",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1110.png",
        "dna": "3ab156de124d3d1632d071e809db154ce9433cd0",
        "edition": 1110,
        "date": 1666813001922,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1110",
        "rarity_score": 120.22234646777704,
        "rarity_level": "COMMON"
    },
    "713": {
        "name": "Kadena Skellies #713",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/713.png",
        "dna": "2a7c496566dd38efb60063f9366c543486db7818",
        "edition": 713,
        "date": 1666813001993,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "713",
        "rarity_score": 115.82742752359752,
        "rarity_level": "COMMON"
    },
    "951": {
        "name": "Kadena Skellies #951",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/951.png",
        "dna": "544b53e748513d36c407799e7d03c8c845dbb138",
        "edition": 951,
        "date": 1666813002065,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "951",
        "rarity_score": 107.30233555024047,
        "rarity_level": "COMMON"
    },
    "998": {
        "name": "Kadena Skellies #998",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/998.png",
        "dna": "586237aac205b143105d1cc3911a195001787743",
        "edition": 998,
        "date": 1666813002138,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "998",
        "rarity_score": 159.09010902979782,
        "rarity_level": "COMMON"
    },
    "737": {
        "name": "Kadena Skellies #737",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/737.png",
        "dna": "26cdd721bce6475b3a64071ff6ccb4458015d0af",
        "edition": 737,
        "date": 1666813002211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "737",
        "rarity_score": 131.20947360553922,
        "rarity_level": "COMMON"
    },
    "382": {
        "name": "Kadena Skellies #382",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/382.png",
        "dna": "53dd154eaf66846aebda992d437abbed730a6973",
        "edition": 382,
        "date": 1666813002282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "382",
        "rarity_score": 143.03120445361824,
        "rarity_level": "COMMON"
    },
    "724": {
        "name": "Kadena Skellies #724",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/724.png",
        "dna": "0b4481181feea86c5d4292935f65067c5a02d98c",
        "edition": 724,
        "date": 1666813002355,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Round Kadena Glasses",
                "score": 250.0
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "724",
        "rarity_score": 372.9647729751292,
        "rarity_level": "EPIC"
    },
    "628": {
        "name": "Kadena Skellies #628",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/628.png",
        "dna": "11d22f2e4fdda2de5e7e410879fe8d6983ec599b",
        "edition": 628,
        "date": 1666813002425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "628",
        "rarity_score": 259.83862827992607,
        "rarity_level": "RARE"
    },
    "329": {
        "name": "Kadena Skellies #329",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/329.png",
        "dna": "d3b25293581f500dcf35e25e33b18826862c6e1d",
        "edition": 329,
        "date": 1666813002495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "329",
        "rarity_score": 150.27057080468597,
        "rarity_level": "COMMON"
    },
    "759": {
        "name": "Kadena Skellies #759",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/759.png",
        "dna": "e064692b3d5cd0b5b83ccdb57a0ccc17d531beb0",
        "edition": 759,
        "date": 1666813002571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "759",
        "rarity_score": 169.17746876882373,
        "rarity_level": "RARE"
    },
    "384": {
        "name": "Kadena Skellies #384",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/384.png",
        "dna": "71f00396980f75cbbd602e6fcb5ee570ea641050",
        "edition": 384,
        "date": 1666813002641,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "384",
        "rarity_score": 131.45724602302306,
        "rarity_level": "COMMON"
    },
    "204": {
        "name": "Kadena Skellies #204",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/204.png",
        "dna": "508156b4106ee1fd1d504f539096676b7d2eef77",
        "edition": 204,
        "date": 1666813002712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "204",
        "rarity_score": 116.07393736638969,
        "rarity_level": "COMMON"
    },
    "986": {
        "name": "Kadena Skellies #986",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/986.png",
        "dna": "8d8a1ab4d5987310f16515b20f9ee8a62a778420",
        "edition": 986,
        "date": 1666813002785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "986",
        "rarity_score": 213.28590779652308,
        "rarity_level": "RARE"
    },
    "632": {
        "name": "Kadena Skellies #632",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/632.png",
        "dna": "e9c64652b17242487bfa9b280367a47f7fcf0965",
        "edition": 632,
        "date": 1666813002855,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "632",
        "rarity_score": 92.96573315634532,
        "rarity_level": "COMMON"
    },
    "167": {
        "name": "Kadena Skellies #167",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/167.png",
        "dna": "d18c4097c3cdf98df73344822965ab1a9670c0ad",
        "edition": 167,
        "date": 1666813002926,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "167",
        "rarity_score": 183.78965723536166,
        "rarity_level": "RARE"
    },
    "787": {
        "name": "Kadena Skellies #787",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/787.png",
        "dna": "3d06cec562c3dd703f8cb955ca702d06d5a813e5",
        "edition": 787,
        "date": 1666813002998,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "787",
        "rarity_score": 129.48641397689352,
        "rarity_level": "COMMON"
    },
    "271": {
        "name": "Kadena Skellies #271",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/271.png",
        "dna": "4e49834bc4078a832212f34e4e49d876f9dc5dfe",
        "edition": 271,
        "date": 1666813003072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "271",
        "rarity_score": 115.88697032176877,
        "rarity_level": "COMMON"
    },
    "209": {
        "name": "Kadena Skellies #209",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/209.png",
        "dna": "687045c8af7aca32b7f375896cd51d93d29761b9",
        "edition": 209,
        "date": 1666813003146,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "209",
        "rarity_score": 157.13334404885984,
        "rarity_level": "COMMON"
    },
    "1187": {
        "name": "Kadena Skellies #1187",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1187.png",
        "dna": "6b3f9666a4850da2d55a4490b08f4bd9284a391a",
        "edition": 1187,
        "date": 1666813003217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1187",
        "rarity_score": 115.77600692942988,
        "rarity_level": "COMMON"
    },
    "236": {
        "name": "Kadena Skellies #236",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/236.png",
        "dna": "dd347cb14a5050fd88803b8d20b42f0bb108ceb6",
        "edition": 236,
        "date": 1666813003288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "236",
        "rarity_score": 152.5831855049222,
        "rarity_level": "COMMON"
    },
    "595": {
        "name": "Kadena Skellies #595",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/595.png",
        "dna": "f5257092838d4dfb1fc0cb898d13aafa84fefecf",
        "edition": 595,
        "date": 1666813003362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "595",
        "rarity_score": 127.73119845764924,
        "rarity_level": "COMMON"
    },
    "1095": {
        "name": "Kadena Skellies #1095",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1095.png",
        "dna": "888c84eaed4291900f45b8e482b9249b5f4626bf",
        "edition": 1095,
        "date": 1666813003433,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1095",
        "rarity_score": 130.55438621088118,
        "rarity_level": "COMMON"
    },
    "768": {
        "name": "Kadena Skellies #768",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/768.png",
        "dna": "72e3d5e1ca1e9e2597e59a7d715baceeefd71844",
        "edition": 768,
        "date": 1666813003511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "768",
        "rarity_score": 166.53504775480883,
        "rarity_level": "COMMON"
    },
    "264": {
        "name": "Kadena Skellies #264",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/264.png",
        "dna": "4467062ace60d4aba9d2fa310ef195ec3f7da7c9",
        "edition": 264,
        "date": 1666813003582,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Skull",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "264",
        "rarity_score": 303.7186250633482,
        "rarity_level": "RARE"
    },
    "586": {
        "name": "Kadena Skellies #586",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/586.png",
        "dna": "bd3f930d94462d5de0109dbd7671cb4efaf66834",
        "edition": 586,
        "date": 1666813003652,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "586",
        "rarity_score": 92.24719165379558,
        "rarity_level": "COMMON"
    },
    "733": {
        "name": "Kadena Skellies #733",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/733.png",
        "dna": "78b80b176687278f4eab36f301c8b07a9c5efe31",
        "edition": 733,
        "date": 1666813003734,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "733",
        "rarity_score": 218.33846701679275,
        "rarity_level": "RARE"
    },
    "1054": {
        "name": "Kadena Skellies #1054",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1054.png",
        "dna": "1cfcbe96f41ee7a25a6ef732a1d82d3590b035d8",
        "edition": 1054,
        "date": 1666813003805,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1054",
        "rarity_score": 153.7743918963062,
        "rarity_level": "COMMON"
    },
    "405": {
        "name": "Kadena Skellies #405",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/405.png",
        "dna": "3ad258a1170fbf35aba029ed053a2a30b710c062",
        "edition": 405,
        "date": 1666813003878,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "405",
        "rarity_score": 276.71973129120784,
        "rarity_level": "RARE"
    },
    "241": {
        "name": "Kadena Skellies #241",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/241.png",
        "dna": "dcb62ca4fdc7a0b06c86bcd70bf1635ff9e2af9f",
        "edition": 241,
        "date": 1666813003949,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "241",
        "rarity_score": 145.8301689402021,
        "rarity_level": "COMMON"
    },
    "1109": {
        "name": "Kadena Skellies #1109",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1109.png",
        "dna": "bc79fa572254c59a9a4c5d9bccf5e4d3a86364dd",
        "edition": 1109,
        "date": 1666813004018,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1109",
        "rarity_score": 127.03770200748528,
        "rarity_level": "COMMON"
    },
    "470": {
        "name": "Kadena Skellies #470",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/470.png",
        "dna": "a8edb0694f1669c06dbb556bebe45f182487a292",
        "edition": 470,
        "date": 1666813004088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "470",
        "rarity_score": 108.06932378997854,
        "rarity_level": "COMMON"
    },
    "113": {
        "name": "Kadena Skellies #113",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/113.png",
        "dna": "dac37ce8f7fae9a2c860885a7362645f7d5b2fdd",
        "edition": 113,
        "date": 1666813004159,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "113",
        "rarity_score": 118.52954749506438,
        "rarity_level": "COMMON"
    },
    "227": {
        "name": "Kadena Skellies #227",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/227.png",
        "dna": "9c23739bdac48955396b79a49c20b652f6d22522",
        "edition": 227,
        "date": 1666813004230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "227",
        "rarity_score": 113.93334618101849,
        "rarity_level": "COMMON"
    },
    "653": {
        "name": "Kadena Skellies #653",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/653.png",
        "dna": "619094fc31564814375af3746a58081d99c667e2",
        "edition": 653,
        "date": 1666813004302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "653",
        "rarity_score": 178.60941040054942,
        "rarity_level": "RARE"
    },
    "972": {
        "name": "Kadena Skellies #972",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/972.png",
        "dna": "c54fbaf257a039e4290567c43b55f346a15e85ce",
        "edition": 972,
        "date": 1666813004372,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "972",
        "rarity_score": 138.29840273342845,
        "rarity_level": "COMMON"
    },
    "502": {
        "name": "Kadena Skellies #502",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/502.png",
        "dna": "34aabb2d289f916bf63ef1f69c4e2df8d00b4a60",
        "edition": 502,
        "date": 1666813004441,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "502",
        "rarity_score": 104.87353565084796,
        "rarity_level": "COMMON"
    },
    "129": {
        "name": "Kadena Skellies #129",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/129.png",
        "dna": "4b11a90150db9c7e396cdcff2106f662c263433d",
        "edition": 129,
        "date": 1666813004514,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "129",
        "rarity_score": 120.27308759235692,
        "rarity_level": "COMMON"
    },
    "890": {
        "name": "Kadena Skellies #890",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/890.png",
        "dna": "eb66d34080dc333e76f29b19cab32de7d1dbc98c",
        "edition": 890,
        "date": 1666813004584,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "890",
        "rarity_score": 146.18237453013742,
        "rarity_level": "COMMON"
    },
    "463": {
        "name": "Kadena Skellies #463",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/463.png",
        "dna": "346d0d05353ebe4632b703aec575cf3a9329d455",
        "edition": 463,
        "date": 1666813004654,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "463",
        "rarity_score": 214.86472014857787,
        "rarity_level": "RARE"
    },
    "564": {
        "name": "Kadena Skellies #564",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/564.png",
        "dna": "cbe9b52f66c312e3f0aa988d33b4bf98cc234551",
        "edition": 564,
        "date": 1666813004726,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "564",
        "rarity_score": 117.51388104374162,
        "rarity_level": "COMMON"
    },
    "127": {
        "name": "Kadena Skellies #127",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/127.png",
        "dna": "0c1a1745f0e6341b6bf1fab9447f019f5acd0b2e",
        "edition": 127,
        "date": 1666813004796,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "127",
        "rarity_score": 151.13108967891804,
        "rarity_level": "COMMON"
    },
    "950": {
        "name": "Kadena Skellies #950",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/950.png",
        "dna": "9a6a0b5a722b79f90bdf5a95960128decd84411e",
        "edition": 950,
        "date": 1666813004867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "950",
        "rarity_score": 189.8595352949402,
        "rarity_level": "RARE"
    },
    "496": {
        "name": "Kadena Skellies #496",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/496.png",
        "dna": "2e2244603182194a350ba453f9432a5c3e349540",
        "edition": 496,
        "date": 1666813004938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "496",
        "rarity_score": 119.19123106132992,
        "rarity_level": "COMMON"
    },
    "930": {
        "name": "Kadena Skellies #930",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/930.png",
        "dna": "8febe61e3dc94adf58521586979c0d6b09f6f50d",
        "edition": 930,
        "date": 1666813005008,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "930",
        "rarity_score": 118.40056868451043,
        "rarity_level": "COMMON"
    },
    "1142": {
        "name": "Kadena Skellies #1142",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1142.png",
        "dna": "e282c735d43ab63976778f337a6d9448f0870f28",
        "edition": 1142,
        "date": 1666813005085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1142",
        "rarity_score": 88.65428352515198,
        "rarity_level": "COMMON"
    },
    "656": {
        "name": "Kadena Skellies #656",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/656.png",
        "dna": "103ceb176dfc1494f9ae2b7247ee1ebc07b53ef7",
        "edition": 656,
        "date": 1666813005162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "656",
        "rarity_score": 255.2626519816374,
        "rarity_level": "RARE"
    },
    "290": {
        "name": "Kadena Skellies #290",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/290.png",
        "dna": "46984862981eafcc4bdcc92e9ee66c2e38014d4e",
        "edition": 290,
        "date": 1666813005236,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "290",
        "rarity_score": 141.22086732901843,
        "rarity_level": "COMMON"
    },
    "788": {
        "name": "Kadena Skellies #788",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/788.png",
        "dna": "12b8c7da516aa30d5c10d673efd5de5abf80774f",
        "edition": 788,
        "date": 1666813005312,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "788",
        "rarity_score": 152.3847643056763,
        "rarity_level": "COMMON"
    },
    "174": {
        "name": "Kadena Skellies #174",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/174.png",
        "dna": "738b3e34ca1b8c7953cc4a36ed0d8c1ac9a0307f",
        "edition": 174,
        "date": 1666813005386,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "174",
        "rarity_score": 124.39275894435926,
        "rarity_level": "COMMON"
    },
    "185": {
        "name": "Kadena Skellies #185",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/185.png",
        "dna": "3970c9c9307335eb575a4e9b97064503cfc689f8",
        "edition": 185,
        "date": 1666813005458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "185",
        "rarity_score": 109.04247885541159,
        "rarity_level": "COMMON"
    },
    "451": {
        "name": "Kadena Skellies #451",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/451.png",
        "dna": "29bfb1ed3b2771cc32e8c11541236d9d54f0ce6e",
        "edition": 451,
        "date": 1666813005529,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "451",
        "rarity_score": 114.7059052629705,
        "rarity_level": "COMMON"
    },
    "1177": {
        "name": "Kadena Skellies #1177",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1177.png",
        "dna": "dc709f8b495edf282f2c10aaa88f64eaf46dad0c",
        "edition": 1177,
        "date": 1666813005599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1177",
        "rarity_score": 207.449689648525,
        "rarity_level": "RARE"
    },
    "798": {
        "name": "Kadena Skellies #798",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/798.png",
        "dna": "43aac2468de12f7149b6003c518bc66eed4f3ec0",
        "edition": 798,
        "date": 1666813005670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "798",
        "rarity_score": 119.27530815749972,
        "rarity_level": "COMMON"
    },
    "338": {
        "name": "Kadena Skellies #338",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/338.png",
        "dna": "8b8cf84ce92ab923ef01cb2585e263f0d61e0566",
        "edition": 338,
        "date": 1666813005741,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "338",
        "rarity_score": 113.93123781516638,
        "rarity_level": "COMMON"
    },
    "56": {
        "name": "Kadena Skellies #56",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/56.png",
        "dna": "91dc5b290436072ebd7ed3a4c92d9ab4e0a05100",
        "edition": 56,
        "date": 1666813005811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "56",
        "rarity_score": 117.49202054985089,
        "rarity_level": "COMMON"
    },
    "983": {
        "name": "Kadena Skellies #983",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/983.png",
        "dna": "06f51e1a2700244702f0f1afad2ee877d9562a7a",
        "edition": 983,
        "date": 1666813005888,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "983",
        "rarity_score": 289.1997719247031,
        "rarity_level": "RARE"
    },
    "26": {
        "name": "Kadena Skellies #26",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/26.png",
        "dna": "7ec4328202b6af8b22013d8c0bca5eae9512d798",
        "edition": 26,
        "date": 1666813005958,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "26",
        "rarity_score": 126.50805844478162,
        "rarity_level": "COMMON"
    },
    "441": {
        "name": "Kadena Skellies #441",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/441.png",
        "dna": "569f439641f59ada0e66e44c299d07e4aae0d233",
        "edition": 441,
        "date": 1666813006029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "441",
        "rarity_score": 128.51080215852872,
        "rarity_level": "COMMON"
    },
    "31": {
        "name": "Kadena Skellies #31",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/31.png",
        "dna": "7893cfe3773a9a415c2da27c54dda5a8a11a5729",
        "edition": 31,
        "date": 1666813006103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "31",
        "rarity_score": 135.10980523045058,
        "rarity_level": "COMMON"
    },
    "116": {
        "name": "Kadena Skellies #116",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/116.png",
        "dna": "59b2e9143dac9977314d23f34114bfa9bb2b1967",
        "edition": 116,
        "date": 1666813006175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "116",
        "rarity_score": 229.3717125142499,
        "rarity_level": "RARE"
    },
    "1153": {
        "name": "Kadena Skellies #1153",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1153.png",
        "dna": "63dc0467887498504809d98cbe404048cbf665cd",
        "edition": 1153,
        "date": 1666813006247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1153",
        "rarity_score": 111.52202964813394,
        "rarity_level": "COMMON"
    },
    "311": {
        "name": "Kadena Skellies #311",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/311.png",
        "dna": "f5f70bef0a8e8930ab0d165515adebfcf7b87b60",
        "edition": 311,
        "date": 1666813006319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "311",
        "rarity_score": 132.5805429259477,
        "rarity_level": "COMMON"
    },
    "1126": {
        "name": "Kadena Skellies #1126",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1126.png",
        "dna": "f89c4fa0ce550c9273b6beefc9e292f9b9424b58",
        "edition": 1126,
        "date": 1666813006391,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1126",
        "rarity_score": 315.4862139237139,
        "rarity_level": "RARE"
    },
    "959": {
        "name": "Kadena Skellies #959",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/959.png",
        "dna": "334a3f8446cae6a2419e6448534c2c8a22f024aa",
        "edition": 959,
        "date": 1666813006465,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "959",
        "rarity_score": 116.73576988730338,
        "rarity_level": "COMMON"
    },
    "896": {
        "name": "Kadena Skellies #896",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/896.png",
        "dna": "9505e6ea31bbb85fc9e36c876ccde9df0152af81",
        "edition": 896,
        "date": 1666813006536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "896",
        "rarity_score": 145.57340148408102,
        "rarity_level": "COMMON"
    },
    "72": {
        "name": "Kadena Skellies #72",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/72.png",
        "dna": "05cdf20b04bd76f5991010dea092ac7cb2c75cbb",
        "edition": 72,
        "date": 1666813006608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "72",
        "rarity_score": 139.36874166552082,
        "rarity_level": "COMMON"
    },
    "314": {
        "name": "Kadena Skellies #314",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/314.png",
        "dna": "60e0251a284c369c14c1cb9b2ceaa7ed066869d6",
        "edition": 314,
        "date": 1666813006680,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "314",
        "rarity_score": 168.68486514391532,
        "rarity_level": "COMMON"
    },
    "189": {
        "name": "Kadena Skellies #189",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/189.png",
        "dna": "7c154a9ad8a8bda7b5487834658e0cceea5f7999",
        "edition": 189,
        "date": 1666813006751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "189",
        "rarity_score": 113.26473546469332,
        "rarity_level": "COMMON"
    },
    "1241": {
        "name": "Kadena Skellies #1241",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1241.png",
        "dna": "52c0d1c75f0168bb4fec13c0117194517461c6e0",
        "edition": 1241,
        "date": 1666813006821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1241",
        "rarity_score": 77.35164715377938,
        "rarity_level": "COMMON"
    },
    "670": {
        "name": "Kadena Skellies #670",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/670.png",
        "dna": "0744a68b4f0b5f1e923f8b5087dafc826c5ac147",
        "edition": 670,
        "date": 1666813006892,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "670",
        "rarity_score": 184.2106034268467,
        "rarity_level": "RARE"
    },
    "1044": {
        "name": "Kadena Skellies #1044",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1044.png",
        "dna": "f1d9732b15a37cdd420bd26791a8fb897f5ee0e9",
        "edition": 1044,
        "date": 1666813006963,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1044",
        "rarity_score": 109.05585284548454,
        "rarity_level": "COMMON"
    },
    "761": {
        "name": "Kadena Skellies #761",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/761.png",
        "dna": "fb91583d5b939fda00f74903e3e243ae8221e5d6",
        "edition": 761,
        "date": 1666813007037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "761",
        "rarity_score": 166.90805292355097,
        "rarity_level": "COMMON"
    },
    "12": {
        "name": "Kadena Skellies #12",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/12.png",
        "dna": "a1430f7406e95d94f7f1c1ce38c0ef9a85a1446a",
        "edition": 12,
        "date": 1666813007110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "12",
        "rarity_score": 161.68902583809393,
        "rarity_level": "COMMON"
    },
    "420": {
        "name": "Kadena Skellies #420",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/420.png",
        "dna": "3c2f94899d777cf6cb066ef347e072d580014a58",
        "edition": 420,
        "date": 1666813007183,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "420",
        "rarity_score": 140.4349442208823,
        "rarity_level": "COMMON"
    },
    "793": {
        "name": "Kadena Skellies #793",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/793.png",
        "dna": "e47ca440f22c1701a63c9f0688cf0349fa1a501c",
        "edition": 793,
        "date": 1666813007259,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "793",
        "rarity_score": 135.77209106820268,
        "rarity_level": "COMMON"
    },
    "739": {
        "name": "Kadena Skellies #739",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/739.png",
        "dna": "9dd35b6acca24a097557e2c74ebbec0bd65f4853",
        "edition": 739,
        "date": 1666813007332,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "739",
        "rarity_score": 194.32238123130082,
        "rarity_level": "RARE"
    },
    "940": {
        "name": "Kadena Skellies #940",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/940.png",
        "dna": "fa5ea1b6b0ed4665b88c7115429cd5eba377d96d",
        "edition": 940,
        "date": 1666813007405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "940",
        "rarity_score": 312.6538191989479,
        "rarity_level": "RARE"
    },
    "800": {
        "name": "Kadena Skellies #800",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/800.png",
        "dna": "876844306d4b5bc1fb5358bf0819478b4ab047dc",
        "edition": 800,
        "date": 1666813007477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "800",
        "rarity_score": 282.2023945025087,
        "rarity_level": "RARE"
    },
    "540": {
        "name": "Kadena Skellies #540",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/540.png",
        "dna": "11412d5e2ff88322d10077a431be9953cdbe6a32",
        "edition": 540,
        "date": 1666813007550,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "540",
        "rarity_score": 110.67256984656224,
        "rarity_level": "COMMON"
    },
    "1130": {
        "name": "Kadena Skellies #1130",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1130.png",
        "dna": "61198f1cafe9e4d9cae6f11abd398cce8bb24e12",
        "edition": 1130,
        "date": 1666813007624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1130",
        "rarity_score": 124.91767695716388,
        "rarity_level": "COMMON"
    },
    "899": {
        "name": "Kadena Skellies #899",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/899.png",
        "dna": "5c4ca183bae86d584f031612add9832f76332f37",
        "edition": 899,
        "date": 1666813007696,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "899",
        "rarity_score": 151.81532414171303,
        "rarity_level": "COMMON"
    },
    "1225": {
        "name": "Kadena Skellies #1225",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1225.png",
        "dna": "63a4b59c861708971583d5875a3a4c63d64d3746",
        "edition": 1225,
        "date": 1666813007768,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1225",
        "rarity_score": 157.18491275448852,
        "rarity_level": "COMMON"
    },
    "1171": {
        "name": "Kadena Skellies #1171",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1171.png",
        "dna": "0bfdf533a44c43e9f4cc9685bbcb0a2d2aea4391",
        "edition": 1171,
        "date": 1666813007840,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1171",
        "rarity_score": 120.34404563917431,
        "rarity_level": "COMMON"
    },
    "715": {
        "name": "Kadena Skellies #715",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/715.png",
        "dna": "62d41d78929e181954a0b84035a0b7c4136147ce",
        "edition": 715,
        "date": 1666813007910,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "715",
        "rarity_score": 88.88599855762136,
        "rarity_level": "COMMON"
    },
    "714": {
        "name": "Kadena Skellies #714",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/714.png",
        "dna": "9306e8f1b7f474c435546675a1755299143eb457",
        "edition": 714,
        "date": 1666813007986,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "714",
        "rarity_score": 129.1754295456331,
        "rarity_level": "COMMON"
    },
    "874": {
        "name": "Kadena Skellies #874",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/874.png",
        "dna": "b50f72222997748c27ff67378d64f4cb3e8e3925",
        "edition": 874,
        "date": 1666813008058,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "874",
        "rarity_score": 197.26984695078855,
        "rarity_level": "RARE"
    },
    "481": {
        "name": "Kadena Skellies #481",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/481.png",
        "dna": "7dd44ec5057230ce10a9ea73d1ece439de3e3d08",
        "edition": 481,
        "date": 1666813008133,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "481",
        "rarity_score": 138.21308799068498,
        "rarity_level": "COMMON"
    },
    "515": {
        "name": "Kadena Skellies #515",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/515.png",
        "dna": "9067d21759966ae23f2ba5720b4e61c02adec4f9",
        "edition": 515,
        "date": 1666813008203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "515",
        "rarity_score": 136.74843423125296,
        "rarity_level": "COMMON"
    },
    "891": {
        "name": "Kadena Skellies #891",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/891.png",
        "dna": "2500e0b08aebe2407a91d093aa1780f4316a0c79",
        "edition": 891,
        "date": 1666813008275,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "891",
        "rarity_score": 121.87977578965103,
        "rarity_level": "COMMON"
    },
    "860": {
        "name": "Kadena Skellies #860",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/860.png",
        "dna": "920ad80d27e643f502efeb0b3c2d2bebaec3118b",
        "edition": 860,
        "date": 1666813008351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "860",
        "rarity_score": 146.97325290983753,
        "rarity_level": "COMMON"
    },
    "931": {
        "name": "Kadena Skellies #931",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/931.png",
        "dna": "407b22f9a6f20a7ea549fa57ec88f3483d816c4e",
        "edition": 931,
        "date": 1666813008422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "931",
        "rarity_score": 105.42009815643083,
        "rarity_level": "COMMON"
    },
    "1138": {
        "name": "Kadena Skellies #1138",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1138.png",
        "dna": "3cbaf0769f33d028c701b0152354e288aaade626",
        "edition": 1138,
        "date": 1666813008493,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1138",
        "rarity_score": 136.70342928063707,
        "rarity_level": "COMMON"
    },
    "775": {
        "name": "Kadena Skellies #775",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/775.png",
        "dna": "537714add9fd69dd91d870d78441bcc6cad65e0b",
        "edition": 775,
        "date": 1666813008566,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "775",
        "rarity_score": 111.70927880387514,
        "rarity_level": "COMMON"
    },
    "750": {
        "name": "Kadena Skellies #750",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/750.png",
        "dna": "af49ba5df126b7642bbf4492d04e4fda2a5402a0",
        "edition": 750,
        "date": 1666813008638,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "750",
        "rarity_score": 135.54028642587917,
        "rarity_level": "COMMON"
    },
    "246": {
        "name": "Kadena Skellies #246",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/246.png",
        "dna": "87588223eea62eae81933644657e49caa9b5169a",
        "edition": 246,
        "date": 1666813008707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "246",
        "rarity_score": 111.01995144826465,
        "rarity_level": "COMMON"
    },
    "122": {
        "name": "Kadena Skellies #122",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/122.png",
        "dna": "bd01770cbd092d2c43ec6ac961d245f31083a74e",
        "edition": 122,
        "date": 1666813008780,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "122",
        "rarity_score": 161.81034603413636,
        "rarity_level": "COMMON"
    },
    "1144": {
        "name": "Kadena Skellies #1144",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1144.png",
        "dna": "1b67a0321804777a2a2a5b66be103521a161cd64",
        "edition": 1144,
        "date": 1666813008850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1144",
        "rarity_score": 112.17012536038145,
        "rarity_level": "COMMON"
    },
    "325": {
        "name": "Kadena Skellies #325",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/325.png",
        "dna": "7fb5c65d5592dd122867a8e08a506acb4f7b97c7",
        "edition": 325,
        "date": 1666813008922,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "325",
        "rarity_score": 123.4914374102941,
        "rarity_level": "COMMON"
    },
    "1236": {
        "name": "Kadena Skellies #1236",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1236.png",
        "dna": "bf3bc0da2297968e5a29a0b6285b4d4756711294",
        "edition": 1236,
        "date": 1666813008994,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1236",
        "rarity_score": 125.57537130060715,
        "rarity_level": "COMMON"
    },
    "320": {
        "name": "Kadena Skellies #320",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/320.png",
        "dna": "0f9a73cad6865fe8fcf80a277a302fdfd45499e3",
        "edition": 320,
        "date": 1666813009066,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "320",
        "rarity_score": 127.7548298235365,
        "rarity_level": "COMMON"
    },
    "378": {
        "name": "Kadena Skellies #378",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/378.png",
        "dna": "aa805a6d0d977c3d3c49eb1e2f6fc821764d5e5c",
        "edition": 378,
        "date": 1666813009142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            { "trait_type": "Head", "value": "Crown", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "378",
        "rarity_score": 522.5547315286917,
        "rarity_level": "LEGENDARY"
    },
    "936": {
        "name": "Kadena Skellies #936",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/936.png",
        "dna": "fecd201ea95773113085e7f45cd3b3c37a014eac",
        "edition": 936,
        "date": 1666813009216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "936",
        "rarity_score": 132.39917111692722,
        "rarity_level": "COMMON"
    },
    "878": {
        "name": "Kadena Skellies #878",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/878.png",
        "dna": "72edc13a7082222b06d439592115d516d3e37427",
        "edition": 878,
        "date": 1666813009290,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "878",
        "rarity_score": 138.28970600918103,
        "rarity_level": "COMMON"
    },
    "755": {
        "name": "Kadena Skellies #755",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/755.png",
        "dna": "25c832be4507bebcdb1fd09cafe70a2674091a68",
        "edition": 755,
        "date": 1666813009364,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "755",
        "rarity_score": 137.3163676119341,
        "rarity_level": "COMMON"
    },
    "183": {
        "name": "Kadena Skellies #183",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/183.png",
        "dna": "b6d56d8158e5d4daf51d3bdacb4523f23d86a4a8",
        "edition": 183,
        "date": 1666813009435,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "183",
        "rarity_score": 155.57233641191013,
        "rarity_level": "COMMON"
    },
    "1201": {
        "name": "Kadena Skellies #1201",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1201.png",
        "dna": "c827d1e811dd24883cd4a10db0227cbc4374920c",
        "edition": 1201,
        "date": 1666813009507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1201",
        "rarity_score": 142.79161092987462,
        "rarity_level": "COMMON"
    },
    "1000": {
        "name": "Kadena Skellies #1000",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1000.png",
        "dna": "38ec65374475cde9c1e8d1dc990c134f31441e7f",
        "edition": 1000,
        "date": 1666813009579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1000",
        "rarity_score": 124.60349684135159,
        "rarity_level": "COMMON"
    },
    "52": {
        "name": "Kadena Skellies #52",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/52.png",
        "dna": "e5ec91be70e336a26cf389953e6ea70ed75155fa",
        "edition": 52,
        "date": 1666813009650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "52",
        "rarity_score": 163.34033259964303,
        "rarity_level": "COMMON"
    },
    "336": {
        "name": "Kadena Skellies #336",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/336.png",
        "dna": "839d557f9df71e4391f501995e415e1f78b0e60f",
        "edition": 336,
        "date": 1666813009728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "336",
        "rarity_score": 142.90871363090736,
        "rarity_level": "COMMON"
    },
    "691": {
        "name": "Kadena Skellies #691",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/691.png",
        "dna": "ae836646ad9be1ecdaa78129bb819bca91e088b0",
        "edition": 691,
        "date": 1666813009800,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "691",
        "rarity_score": 301.81439248326467,
        "rarity_level": "RARE"
    },
    "618": {
        "name": "Kadena Skellies #618",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/618.png",
        "dna": "dfa59b52d8c85f20cd944d32c094f3677330beed",
        "edition": 618,
        "date": 1666813009873,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "618",
        "rarity_score": 98.47742864953749,
        "rarity_level": "COMMON"
    },
    "749": {
        "name": "Kadena Skellies #749",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/749.png",
        "dna": "4aafc96b6e75b1b78d5906fad90ac39b699d1f06",
        "edition": 749,
        "date": 1666813009948,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "749",
        "rarity_score": 149.64755075672323,
        "rarity_level": "COMMON"
    },
    "808": {
        "name": "Kadena Skellies #808",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/808.png",
        "dna": "6cd263de903b3c5ecda34fdb39a482b23d1aa4cd",
        "edition": 808,
        "date": 1666813010020,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "808",
        "rarity_score": 138.30178170158072,
        "rarity_level": "COMMON"
    },
    "847": {
        "name": "Kadena Skellies #847",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/847.png",
        "dna": "217b1012ad85622b94eacd1519ba3218390b5a33",
        "edition": 847,
        "date": 1666813010094,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "847",
        "rarity_score": 118.73765393182869,
        "rarity_level": "COMMON"
    },
    "1212": {
        "name": "Kadena Skellies #1212",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1212.png",
        "dna": "384b6fc43ad445c301506441ef10cf6344931d3c",
        "edition": 1212,
        "date": 1666813010173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1212",
        "rarity_score": 153.73402183582635,
        "rarity_level": "COMMON"
    },
    "417": {
        "name": "Kadena Skellies #417",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/417.png",
        "dna": "4d6083bc9bbb26c331d92a48a20cc10c19ec9404",
        "edition": 417,
        "date": 1666813010247,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "417",
        "rarity_score": 209.00924713876887,
        "rarity_level": "RARE"
    },
    "106": {
        "name": "Kadena Skellies #106",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/106.png",
        "dna": "4ea09ab058a316366cdccf3bdb56d40cf5ac6328",
        "edition": 106,
        "date": 1666813010319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "106",
        "rarity_score": 182.61215837267176,
        "rarity_level": "RARE"
    },
    "704": {
        "name": "Kadena Skellies #704",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/704.png",
        "dna": "9ad63d3b2ec4619c0afb05b22717eea67b6996ec",
        "edition": 704,
        "date": 1666813010393,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "704",
        "rarity_score": 82.14110607538163,
        "rarity_level": "COMMON"
    },
    "687": {
        "name": "Kadena Skellies #687",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/687.png",
        "dna": "bd55d9411c23257e64d0a3737b12d17720aac181",
        "edition": 687,
        "date": 1666813010462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "687",
        "rarity_score": 123.04294394131976,
        "rarity_level": "COMMON"
    },
    "215": {
        "name": "Kadena Skellies #215",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/215.png",
        "dna": "781357c2716dfb93364ff1ab907e28d2a5ab1ca7",
        "edition": 215,
        "date": 1666813010537,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "215",
        "rarity_score": 161.6716337170103,
        "rarity_level": "COMMON"
    },
    "558": {
        "name": "Kadena Skellies #558",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/558.png",
        "dna": "2b58a3cf91a416ed67cbac0f799c68d257588e10",
        "edition": 558,
        "date": 1666813010608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "558",
        "rarity_score": 114.23888777929778,
        "rarity_level": "COMMON"
    },
    "880": {
        "name": "Kadena Skellies #880",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/880.png",
        "dna": "6dbbf3ec9a62b9721da2e58d759374dc3a371bba",
        "edition": 880,
        "date": 1666813010678,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "880",
        "rarity_score": 86.39522354457539,
        "rarity_level": "COMMON"
    },
    "1046": {
        "name": "Kadena Skellies #1046",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1046.png",
        "dna": "bd9c34adcb1d7524cb7be873762aee3df133347a",
        "edition": 1046,
        "date": 1666813010756,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1046",
        "rarity_score": 129.98776585733108,
        "rarity_level": "COMMON"
    },
    "791": {
        "name": "Kadena Skellies #791",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/791.png",
        "dna": "d91e9fb98b02186ffe495708fbcce2bc08fa3ece",
        "edition": 791,
        "date": 1666813010827,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "791",
        "rarity_score": 128.84670399448135,
        "rarity_level": "COMMON"
    },
    "1093": {
        "name": "Kadena Skellies #1093",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1093.png",
        "dna": "a17b1eca6873f42c2a712aed3ad1775af9d8ae51",
        "edition": 1093,
        "date": 1666813010904,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1093",
        "rarity_score": 107.54359716607438,
        "rarity_level": "COMMON"
    },
    "523": {
        "name": "Kadena Skellies #523",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/523.png",
        "dna": "a8f108d0ba0535e6afae4406fbf69f09096446c9",
        "edition": 523,
        "date": 1666813010977,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "523",
        "rarity_score": 118.00289973927396,
        "rarity_level": "COMMON"
    },
    "322": {
        "name": "Kadena Skellies #322",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/322.png",
        "dna": "5d3f852624e131d82677787258f98ddbcc619822",
        "edition": 322,
        "date": 1666813011052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "322",
        "rarity_score": 167.67432306065047,
        "rarity_level": "COMMON"
    },
    "21": {
        "name": "Kadena Skellies #21",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/21.png",
        "dna": "587676b3dc2d8e593ad49b09c7c77b9175844416",
        "edition": 21,
        "date": 1666813011126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "21",
        "rarity_score": 178.833827333415,
        "rarity_level": "RARE"
    },
    "84": {
        "name": "Kadena Skellies #84",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/84.png",
        "dna": "b57a9ef486850f601de63fff50008fdeb1a03265",
        "edition": 84,
        "date": 1666813011198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "84",
        "rarity_score": 121.72172371333315,
        "rarity_level": "COMMON"
    },
    "450": {
        "name": "Kadena Skellies #450",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/450.png",
        "dna": "a2ef0f6723cdb02c123827979eb3e713378d7c76",
        "edition": 450,
        "date": 1666813011269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "450",
        "rarity_score": 135.9116369648907,
        "rarity_level": "COMMON"
    },
    "1051": {
        "name": "Kadena Skellies #1051",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1051.png",
        "dna": "fe7a61e957887106967ffb0889d1ea521a1cc287",
        "edition": 1051,
        "date": 1666813011343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1051",
        "rarity_score": 122.21709197596958,
        "rarity_level": "COMMON"
    },
    "380": {
        "name": "Kadena Skellies #380",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/380.png",
        "dna": "2adb6579011356e950425823a98321eebce1ef93",
        "edition": 380,
        "date": 1666813011414,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "380",
        "rarity_score": 273.9240594533207,
        "rarity_level": "RARE"
    },
    "571": {
        "name": "Kadena Skellies #571",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/571.png",
        "dna": "dce20a9abf27f199624faf00adb0c3693115dc43",
        "edition": 571,
        "date": 1666813011485,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "571",
        "rarity_score": 132.32931641736147,
        "rarity_level": "COMMON"
    },
    "403": {
        "name": "Kadena Skellies #403",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/403.png",
        "dna": "38921a65fdaa1613ae972b77c6de9362edda2cf7",
        "edition": 403,
        "date": 1666813011557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "403",
        "rarity_score": 120.71245766731974,
        "rarity_level": "COMMON"
    },
    "649": {
        "name": "Kadena Skellies #649",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/649.png",
        "dna": "11fbf712228216ad23cd4d2f90d94f1b304342cc",
        "edition": 649,
        "date": 1666813011628,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "649",
        "rarity_score": 223.57198413068207,
        "rarity_level": "RARE"
    },
    "1097": {
        "name": "Kadena Skellies #1097",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1097.png",
        "dna": "fb0eb7128a865815185d3f5c66953f405b0fcb57",
        "edition": 1097,
        "date": 1666813011700,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1097",
        "rarity_score": 174.58183845248382,
        "rarity_level": "RARE"
    },
    "245": {
        "name": "Kadena Skellies #245",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/245.png",
        "dna": "851c9ff4cd34a01f5eb285f5d648c2d8ac97c6ef",
        "edition": 245,
        "date": 1666813011777,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "245",
        "rarity_score": 144.33995470313812,
        "rarity_level": "COMMON"
    },
    "1042": {
        "name": "Kadena Skellies #1042",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1042.png",
        "dna": "0d37961a0480ec7986db14c08132341d451e8106",
        "edition": 1042,
        "date": 1666813011848,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1042",
        "rarity_score": 110.5261303859126,
        "rarity_level": "COMMON"
    },
    "529": {
        "name": "Kadena Skellies #529",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/529.png",
        "dna": "205b5c728562cbb3a8b7663f7589a11360f7c460",
        "edition": 529,
        "date": 1666813011922,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "529",
        "rarity_score": 125.97045723313471,
        "rarity_level": "COMMON"
    },
    "698": {
        "name": "Kadena Skellies #698",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/698.png",
        "dna": "30a7c988b25a96e3f1aa270f72485a459785b280",
        "edition": 698,
        "date": 1666813011992,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "698",
        "rarity_score": 143.09973902085065,
        "rarity_level": "COMMON"
    },
    "1040": {
        "name": "Kadena Skellies #1040",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1040.png",
        "dna": "2d31c3cd76ef8104949f51516e11d683447899b2",
        "edition": 1040,
        "date": 1666813012063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1040",
        "rarity_score": 133.5491312395078,
        "rarity_level": "COMMON"
    },
    "1165": {
        "name": "Kadena Skellies #1165",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1165.png",
        "dna": "a380bceccb56922316e3fb0ab3acbd84d41aa4d5",
        "edition": 1165,
        "date": 1666813012136,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1165",
        "rarity_score": 120.11140580022047,
        "rarity_level": "COMMON"
    },
    "440": {
        "name": "Kadena Skellies #440",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/440.png",
        "dna": "8ba684540702833217f04446f65708043aaca076",
        "edition": 440,
        "date": 1666813012207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "440",
        "rarity_score": 112.29971410523243,
        "rarity_level": "COMMON"
    },
    "453": {
        "name": "Kadena Skellies #453",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/453.png",
        "dna": "f8d991428ca5f7dbe0c9a0d6cfebbe490dcb2aba",
        "edition": 453,
        "date": 1666813012279,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "453",
        "rarity_score": 180.84501828873627,
        "rarity_level": "RARE"
    },
    "97": {
        "name": "Kadena Skellies #97",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/97.png",
        "dna": "6e7ac365b36b1116a8a69610c353fc5309628cb7",
        "edition": 97,
        "date": 1666813012349,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "97",
        "rarity_score": 112.73649146955798,
        "rarity_level": "COMMON"
    },
    "166": {
        "name": "Kadena Skellies #166",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/166.png",
        "dna": "508ae0578fbffba3364b0f1f12182f8a742f5a02",
        "edition": 166,
        "date": 1666813012420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "166",
        "rarity_score": 153.7166205742973,
        "rarity_level": "COMMON"
    },
    "685": {
        "name": "Kadena Skellies #685",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/685.png",
        "dna": "915539ce6eb1448fd739a7970d898c339af4036f",
        "edition": 685,
        "date": 1666813012494,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "685",
        "rarity_score": 271.8303852024782,
        "rarity_level": "RARE"
    },
    "645": {
        "name": "Kadena Skellies #645",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/645.png",
        "dna": "cbe93cac05585dd86d567042020779b6763486f3",
        "edition": 645,
        "date": 1666813012565,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "645",
        "rarity_score": 130.60806968861598,
        "rarity_level": "COMMON"
    },
    "978": {
        "name": "Kadena Skellies #978",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/978.png",
        "dna": "d6cd4398f6da480eb0f36772a5a379453d19fb42",
        "edition": 978,
        "date": 1666813012641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "978",
        "rarity_score": 124.23407408803513,
        "rarity_level": "COMMON"
    },
    "1136": {
        "name": "Kadena Skellies #1136",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1136.png",
        "dna": "053cf0d825a738119f19a3e06682dcc2611cfaa3",
        "edition": 1136,
        "date": 1666813012712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1136",
        "rarity_score": 126.38338897709214,
        "rarity_level": "COMMON"
    },
    "1239": {
        "name": "Kadena Skellies #1239",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1239.png",
        "dna": "e166b2491650f1ad05a7cf80357cd3e20930dda1",
        "edition": 1239,
        "date": 1666813012783,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1239",
        "rarity_score": 143.40135084290483,
        "rarity_level": "COMMON"
    },
    "399": {
        "name": "Kadena Skellies #399",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/399.png",
        "dna": "3f9b0461cdc4354ca65750c2b47813a5fc048cb6",
        "edition": 399,
        "date": 1666813012856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "399",
        "rarity_score": 154.74054015873372,
        "rarity_level": "COMMON"
    },
    "1141": {
        "name": "Kadena Skellies #1141",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1141.png",
        "dna": "9a91f29c5307f95ec3b130c824f4550db4fce494",
        "edition": 1141,
        "date": 1666813012932,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1141",
        "rarity_score": 143.29276152670857,
        "rarity_level": "COMMON"
    },
    "877": {
        "name": "Kadena Skellies #877",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/877.png",
        "dna": "9d045be60b92597f9302fd197e13446a56c01bf1",
        "edition": 877,
        "date": 1666813013002,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "877",
        "rarity_score": 145.97650446555087,
        "rarity_level": "COMMON"
    },
    "1064": {
        "name": "Kadena Skellies #1064",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1064.png",
        "dna": "b6cd336824f2674840d52893efcea2f7c961d76b",
        "edition": 1064,
        "date": 1666813013075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Skull",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1064",
        "rarity_score": 279.7992588311719,
        "rarity_level": "RARE"
    },
    "697": {
        "name": "Kadena Skellies #697",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/697.png",
        "dna": "ecb34881d7874b806a11b3d35af7245ca3253d73",
        "edition": 697,
        "date": 1666813013147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "697",
        "rarity_score": 138.3587250834225,
        "rarity_level": "COMMON"
    },
    "888": {
        "name": "Kadena Skellies #888",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/888.png",
        "dna": "3d7fef10531d5732a61dd5575a2898142fddf4ce",
        "edition": 888,
        "date": 1666813013218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "888",
        "rarity_score": 202.84476149658238,
        "rarity_level": "RARE"
    },
    "192": {
        "name": "Kadena Skellies #192",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/192.png",
        "dna": "9ea234c60ae4bdb8400fac4b45dc001bf8fc0e00",
        "edition": 192,
        "date": 1666813013291,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "192",
        "rarity_score": 138.8126278175493,
        "rarity_level": "COMMON"
    },
    "630": {
        "name": "Kadena Skellies #630",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/630.png",
        "dna": "8d30124c8f25a2714cc762ca3e79b4a83262403a",
        "edition": 630,
        "date": 1666813013362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "630",
        "rarity_score": 241.6797967083584,
        "rarity_level": "RARE"
    },
    "455": {
        "name": "Kadena Skellies #455",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/455.png",
        "dna": "da690eacc33b7eb0e9c7a2e78ea8f9daefa767c3",
        "edition": 455,
        "date": 1666813013436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "455",
        "rarity_score": 135.5297398214166,
        "rarity_level": "COMMON"
    },
    "196": {
        "name": "Kadena Skellies #196",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/196.png",
        "dna": "9201e7fce5a48b1d1f3e4813d58cb9c132a9d196",
        "edition": 196,
        "date": 1666813013515,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "196",
        "rarity_score": 275.97054135747,
        "rarity_level": "RARE"
    },
    "894": {
        "name": "Kadena Skellies #894",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/894.png",
        "dna": "70f6f0d9e68dc60e56b51f3e541dde980662ccf6",
        "edition": 894,
        "date": 1666813013591,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            { "trait_type": "Eyes", "value": "Red Cross Eyes", "score": 250.0 },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "894",
        "rarity_score": 352.9845344253741,
        "rarity_level": "EPIC"
    },
    "1033": {
        "name": "Kadena Skellies #1033",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1033.png",
        "dna": "1cb0c1d7728192ea99170150233e022d94a9fa4f",
        "edition": 1033,
        "date": 1666813013670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1033",
        "rarity_score": 121.47630642901032,
        "rarity_level": "COMMON"
    },
    "422": {
        "name": "Kadena Skellies #422",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/422.png",
        "dna": "b04a4f926f5c216873bbaa406f1ab03143d5b018",
        "edition": 422,
        "date": 1666813013741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "422",
        "rarity_score": 113.36250835979547,
        "rarity_level": "COMMON"
    },
    "1194": {
        "name": "Kadena Skellies #1194",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1194.png",
        "dna": "3870f12b97eef4e442b956c0564eefc1dd308598",
        "edition": 1194,
        "date": 1666813013812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1194",
        "rarity_score": 181.4883212833414,
        "rarity_level": "RARE"
    },
    "1170": {
        "name": "Kadena Skellies #1170",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1170.png",
        "dna": "84088dd541b0c324c910e1d31b1bed30f405391c",
        "edition": 1170,
        "date": 1666813013884,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1170",
        "rarity_score": 125.44946744511279,
        "rarity_level": "COMMON"
    },
    "411": {
        "name": "Kadena Skellies #411",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/411.png",
        "dna": "a586e0c8d7273d3011545ad72b6f2f0f5b1fc162",
        "edition": 411,
        "date": 1666813013962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "411",
        "rarity_score": 143.33595417121086,
        "rarity_level": "COMMON"
    },
    "1008": {
        "name": "Kadena Skellies #1008",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1008.png",
        "dna": "1d10966536972ae7ef838083a53d9b262f36ef75",
        "edition": 1008,
        "date": 1666813014037,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1008",
        "rarity_score": 116.1358448572026,
        "rarity_level": "COMMON"
    },
    "18": {
        "name": "Kadena Skellies #18",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/18.png",
        "dna": "d62bc7c4a378fdbb69b9b51a35733c446a9eccfb",
        "edition": 18,
        "date": 1666813014109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "18",
        "rarity_score": 154.03649755407503,
        "rarity_level": "COMMON"
    },
    "1099": {
        "name": "Kadena Skellies #1099",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1099.png",
        "dna": "adb221d1210c3ffd0eaa61287177f732fd5af009",
        "edition": 1099,
        "date": 1666813014181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1099",
        "rarity_score": 115.74645611067656,
        "rarity_level": "COMMON"
    },
    "1083": {
        "name": "Kadena Skellies #1083",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1083.png",
        "dna": "acb6108e828a39165b3b6459bb99d5f295e7c234",
        "edition": 1083,
        "date": 1666813014254,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1083",
        "rarity_score": 134.99577403456718,
        "rarity_level": "COMMON"
    },
    "1137": {
        "name": "Kadena Skellies #1137",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1137.png",
        "dna": "baac444317d636febae393741bef96a744df545c",
        "edition": 1137,
        "date": 1666813014326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            { "trait_type": "Head", "value": "Brown Ponytail", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1137",
        "rarity_score": 415.34610910965023,
        "rarity_level": "EPIC"
    },
    "794": {
        "name": "Kadena Skellies #794",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/794.png",
        "dna": "48f2b461e7a6199d474ad8cf24e88736bad63608",
        "edition": 794,
        "date": 1666813014403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "794",
        "rarity_score": 145.90434902934902,
        "rarity_level": "COMMON"
    },
    "1010": {
        "name": "Kadena Skellies #1010",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1010.png",
        "dna": "1f6b59201bcbbbb80e25e2fc280ba51bb70d54b3",
        "edition": 1010,
        "date": 1666813014476,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1010",
        "rarity_score": 129.0152700017365,
        "rarity_level": "COMMON"
    },
    "711": {
        "name": "Kadena Skellies #711",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/711.png",
        "dna": "c77c266c84e79681a069925823d0ef57560c70a5",
        "edition": 711,
        "date": 1666813014551,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "711",
        "rarity_score": 147.7606117940301,
        "rarity_level": "COMMON"
    },
    "938": {
        "name": "Kadena Skellies #938",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/938.png",
        "dna": "cfce1c19c064da8a8da6f3fe57189538f53849ba",
        "edition": 938,
        "date": 1666813014626,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "938",
        "rarity_score": 175.99693830484546,
        "rarity_level": "RARE"
    },
    "156": {
        "name": "Kadena Skellies #156",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/156.png",
        "dna": "b3131e87ca065e37fb8919aa83946dd96fd8e76b",
        "edition": 156,
        "date": 1666813014698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "156",
        "rarity_score": 129.38523242525298,
        "rarity_level": "COMMON"
    },
    "103": {
        "name": "Kadena Skellies #103",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/103.png",
        "dna": "63c7190866abc6c59fabd88c01830ae837438ac0",
        "edition": 103,
        "date": 1666813014771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "103",
        "rarity_score": 215.52836491860884,
        "rarity_level": "RARE"
    },
    "533": {
        "name": "Kadena Skellies #533",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/533.png",
        "dna": "23df8cc1a690f768bda785e42d1b076257f9c97c",
        "edition": 533,
        "date": 1666813014846,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "533",
        "rarity_score": 122.0725707225176,
        "rarity_level": "COMMON"
    },
    "1249": {
        "name": "Kadena Skellies #1249",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1249.png",
        "dna": "fbd827cec5c9ada4a34fc53cecfca675dd5aceca",
        "edition": 1249,
        "date": 1666813014919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1249",
        "rarity_score": 112.68130810419733,
        "rarity_level": "COMMON"
    },
    "465": {
        "name": "Kadena Skellies #465",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/465.png",
        "dna": "7df8242c01b4ef84bfe62d7d31516a5d5b8290fd",
        "edition": 465,
        "date": 1666813014992,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "465",
        "rarity_score": 123.41748379344259,
        "rarity_level": "COMMON"
    },
    "597": {
        "name": "Kadena Skellies #597",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/597.png",
        "dna": "d1359f8ff3415f7e68b0066911e86c9c1591a386",
        "edition": 597,
        "date": 1666813015065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "597",
        "rarity_score": 145.02517703828008,
        "rarity_level": "COMMON"
    },
    "745": {
        "name": "Kadena Skellies #745",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/745.png",
        "dna": "0c9afe9881c3167765244fc919ab4af37bc46847",
        "edition": 745,
        "date": 1666813015136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "745",
        "rarity_score": 155.32274973109446,
        "rarity_level": "COMMON"
    },
    "101": {
        "name": "Kadena Skellies #101",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/101.png",
        "dna": "a47c7979cdfd23b890eb2a776f24a61f7b6620b4",
        "edition": 101,
        "date": 1666813015213,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "101",
        "rarity_score": 145.6557641652592,
        "rarity_level": "COMMON"
    },
    "673": {
        "name": "Kadena Skellies #673",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/673.png",
        "dna": "6e1e2b620c31085ac4eee8b9b49bb59494a8c5fa",
        "edition": 673,
        "date": 1666813015286,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "673",
        "rarity_score": 132.70790100601246,
        "rarity_level": "COMMON"
    },
    "305": {
        "name": "Kadena Skellies #305",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/305.png",
        "dna": "bba85add29bc07127abb872d68fc9eab82e26d0b",
        "edition": 305,
        "date": 1666813015357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "305",
        "rarity_score": 123.89700324896566,
        "rarity_level": "COMMON"
    },
    "883": {
        "name": "Kadena Skellies #883",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/883.png",
        "dna": "cf7028eda607ac595b68e650f8f8ebd7c2fab84c",
        "edition": 883,
        "date": 1666813015434,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "883",
        "rarity_score": 207.2296034948474,
        "rarity_level": "RARE"
    },
    "1149": {
        "name": "Kadena Skellies #1149",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1149.png",
        "dna": "6ff31ce26e2babe54f51c74856f2736dbf4bfce9",
        "edition": 1149,
        "date": 1666813015506,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1149",
        "rarity_score": 120.03926503270661,
        "rarity_level": "COMMON"
    },
    "45": {
        "name": "Kadena Skellies #45",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/45.png",
        "dna": "6bedc870e1f4c6e0eced6b5e2ed700fd58d2a5e3",
        "edition": 45,
        "date": 1666813015578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "45",
        "rarity_score": 129.1069283485866,
        "rarity_level": "COMMON"
    },
    "1139": {
        "name": "Kadena Skellies #1139",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1139.png",
        "dna": "02bf7b00bf778d73a098288f43ee9154a54b5ba3",
        "edition": 1139,
        "date": 1666813015649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1139",
        "rarity_score": 147.97748943063652,
        "rarity_level": "COMMON"
    },
    "709": {
        "name": "Kadena Skellies #709",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/709.png",
        "dna": "add05957cb068501773dc6642e75871b8d6acc68",
        "edition": 709,
        "date": 1666813015721,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "709",
        "rarity_score": 99.56920476387936,
        "rarity_level": "COMMON"
    },
    "566": {
        "name": "Kadena Skellies #566",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/566.png",
        "dna": "3de3acd8c764792d3fb3fcacef9ead9c8325d54c",
        "edition": 566,
        "date": 1666813015801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "566",
        "rarity_score": 203.7562128304515,
        "rarity_level": "RARE"
    },
    "824": {
        "name": "Kadena Skellies #824",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/824.png",
        "dna": "e1f4601b94e14fbbf89e3176c1d6eb70ba2280ac",
        "edition": 824,
        "date": 1666813015873,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "824",
        "rarity_score": 171.81459894841566,
        "rarity_level": "RARE"
    },
    "255": {
        "name": "Kadena Skellies #255",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/255.png",
        "dna": "dfe52ac01e8db8507e23cea662768bb02e4e9f00",
        "edition": 255,
        "date": 1666813015945,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "255",
        "rarity_score": 139.62087511738696,
        "rarity_level": "COMMON"
    },
    "1172": {
        "name": "Kadena Skellies #1172",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1172.png",
        "dna": "3d29c2925ca4071c128375180d84283f15c3c43f",
        "edition": 1172,
        "date": 1666813016023,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1172",
        "rarity_score": 130.80785643143042,
        "rarity_level": "COMMON"
    },
    "1081": {
        "name": "Kadena Skellies #1081",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1081.png",
        "dna": "f46534173b859fd89bf2804261f6afd9fe7f7d58",
        "edition": 1081,
        "date": 1666813016094,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1081",
        "rarity_score": 165.9693704397716,
        "rarity_level": "COMMON"
    },
    "519": {
        "name": "Kadena Skellies #519",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/519.png",
        "dna": "4087ea1ddf86fbc7c86a305bdc437a29dcc1bf89",
        "edition": 519,
        "date": 1666813016170,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "519",
        "rarity_score": 200.23621594899686,
        "rarity_level": "RARE"
    },
    "1038": {
        "name": "Kadena Skellies #1038",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1038.png",
        "dna": "0e446acf71e76643e99c3cef107bf114f3edb083",
        "edition": 1038,
        "date": 1666813016249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1038",
        "rarity_score": 135.03112324240973,
        "rarity_level": "COMMON"
    },
    "1015": {
        "name": "Kadena Skellies #1015",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1015.png",
        "dna": "75fc1286f5387a2f57dff28386f84f9c8c699744",
        "edition": 1015,
        "date": 1666813016318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1015",
        "rarity_score": 111.96066936404401,
        "rarity_level": "COMMON"
    },
    "783": {
        "name": "Kadena Skellies #783",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/783.png",
        "dna": "aa64cdceb0d70a8e8783fdd0fa4cf253619a2027",
        "edition": 783,
        "date": 1666813016392,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "783",
        "rarity_score": 136.79303659601635,
        "rarity_level": "COMMON"
    },
    "432": {
        "name": "Kadena Skellies #432",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/432.png",
        "dna": "c6ce287fc661811605a66ca3b57a9a3c71f3d49a",
        "edition": 432,
        "date": 1666813016464,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "432",
        "rarity_score": 139.90537773867766,
        "rarity_level": "COMMON"
    },
    "1189": {
        "name": "Kadena Skellies #1189",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1189.png",
        "dna": "95ee5a0daf69b86f167a12da02c93530f9bd8b54",
        "edition": 1189,
        "date": 1666813016535,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1189",
        "rarity_score": 102.62614317505223,
        "rarity_level": "COMMON"
    },
    "1119": {
        "name": "Kadena Skellies #1119",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1119.png",
        "dna": "bcffeb3f40fe79806989df55069b19727c3c41b6",
        "edition": 1119,
        "date": 1666813016610,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1119",
        "rarity_score": 130.26208002252747,
        "rarity_level": "COMMON"
    },
    "419": {
        "name": "Kadena Skellies #419",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/419.png",
        "dna": "6ca272c5d53831854a2e13725e7073000fe14e3b",
        "edition": 419,
        "date": 1666813016681,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "419",
        "rarity_score": 124.57655708483793,
        "rarity_level": "COMMON"
    },
    "658": {
        "name": "Kadena Skellies #658",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/658.png",
        "dna": "88fec674ca6195a98a6f334f7f2de0b8fcb1ce62",
        "edition": 658,
        "date": 1666813016755,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "658",
        "rarity_score": 260.04465112920997,
        "rarity_level": "RARE"
    },
    "879": {
        "name": "Kadena Skellies #879",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/879.png",
        "dna": "39b4bc78dd55c04f8ab208a1ed1366f22d340767",
        "edition": 879,
        "date": 1666813016830,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            { "trait_type": "Eyes", "value": "Red Cross Eyes", "score": 250.0 },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "879",
        "rarity_score": 360.61131912189774,
        "rarity_level": "EPIC"
    },
    "328": {
        "name": "Kadena Skellies #328",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/328.png",
        "dna": "ccf513710163c79a73c2280557e922c3b0e04b65",
        "edition": 328,
        "date": 1666813016901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "328",
        "rarity_score": 132.32222586970428,
        "rarity_level": "COMMON"
    },
    "1185": {
        "name": "Kadena Skellies #1185",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1185.png",
        "dna": "533f71821d853f87b64e25906680500c64f42818",
        "edition": 1185,
        "date": 1666813016975,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1185",
        "rarity_score": 138.97499251272836,
        "rarity_level": "COMMON"
    },
    "820": {
        "name": "Kadena Skellies #820",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/820.png",
        "dna": "de967a2901dd190d7f193814cdec013b4bdd62de",
        "edition": 820,
        "date": 1666813017047,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "820",
        "rarity_score": 183.43357187330074,
        "rarity_level": "RARE"
    },
    "91": {
        "name": "Kadena Skellies #91",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/91.png",
        "dna": "965fe294a388d4c09e9922fdd3bb8708f74016d1",
        "edition": 91,
        "date": 1666813017120,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "91",
        "rarity_score": 98.14712273669647,
        "rarity_level": "COMMON"
    },
    "1027": {
        "name": "Kadena Skellies #1027",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1027.png",
        "dna": "250404190ef1dbaf89f223409778e3807459c0ee",
        "edition": 1027,
        "date": 1666813017195,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1027",
        "rarity_score": 141.62273403148015,
        "rarity_level": "COMMON"
    },
    "741": {
        "name": "Kadena Skellies #741",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/741.png",
        "dna": "c968c132d9dd733ae94598e2dadd3ab7afe0176c",
        "edition": 741,
        "date": 1666813017274,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "741",
        "rarity_score": 119.8375315845968,
        "rarity_level": "COMMON"
    },
    "516": {
        "name": "Kadena Skellies #516",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/516.png",
        "dna": "b09d2d4b7e3bd07b614db42e84a4d913a23ebcbd",
        "edition": 516,
        "date": 1666813017348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "516",
        "rarity_score": 165.88918741466077,
        "rarity_level": "COMMON"
    },
    "591": {
        "name": "Kadena Skellies #591",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/591.png",
        "dna": "c2a232027b2dcf9c113276d4813351c6fdfc5673",
        "edition": 591,
        "date": 1666813017424,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "591",
        "rarity_score": 119.16169730870635,
        "rarity_level": "COMMON"
    },
    "706": {
        "name": "Kadena Skellies #706",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/706.png",
        "dna": "818a850910f8ccdef69b2ce040746097d733ae7b",
        "edition": 706,
        "date": 1666813017497,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "706",
        "rarity_score": 233.47658608909023,
        "rarity_level": "RARE"
    },
    "1202": {
        "name": "Kadena Skellies #1202",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1202.png",
        "dna": "936b349767071e317eaa0916be0b7baeb11f61fa",
        "edition": 1202,
        "date": 1666813017574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1202",
        "rarity_score": 182.01557382229652,
        "rarity_level": "RARE"
    },
    "648": {
        "name": "Kadena Skellies #648",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/648.png",
        "dna": "acea527f64bc0ef021c33c2f83bb76e1738dd702",
        "edition": 648,
        "date": 1666813017648,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "648",
        "rarity_score": 130.3226833922225,
        "rarity_level": "COMMON"
    },
    "1244": {
        "name": "Kadena Skellies #1244",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1244.png",
        "dna": "9f214c268d491437a53eca206a10d98ce2a7f416",
        "edition": 1244,
        "date": 1666813017721,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1244",
        "rarity_score": 94.08289395467523,
        "rarity_level": "COMMON"
    },
    "81": {
        "name": "Kadena Skellies #81",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/81.png",
        "dna": "27dfb2d93dec29fae327bdcc83e69c276e061b15",
        "edition": 81,
        "date": 1666813017798,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "81",
        "rarity_score": 171.66395742169934,
        "rarity_level": "RARE"
    },
    "975": {
        "name": "Kadena Skellies #975",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/975.png",
        "dna": "355aca82645bfb33ca1a70af8b301327d7091ea9",
        "edition": 975,
        "date": 1666813017872,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "975",
        "rarity_score": 124.93098982452764,
        "rarity_level": "COMMON"
    },
    "942": {
        "name": "Kadena Skellies #942",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/942.png",
        "dna": "ef47e21abf695be4ed19cb3fdf129968f00c8274",
        "edition": 942,
        "date": 1666813017944,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "942",
        "rarity_score": 103.22166005133155,
        "rarity_level": "COMMON"
    },
    "582": {
        "name": "Kadena Skellies #582",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/582.png",
        "dna": "65a0c0a83c3eba0a07f50d8f9cb352e3f7484a17",
        "edition": 582,
        "date": 1666813018017,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "582",
        "rarity_score": 152.00967557594333,
        "rarity_level": "COMMON"
    },
    "202": {
        "name": "Kadena Skellies #202",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/202.png",
        "dna": "9de1f68e7513b3bfdb980eb8bf1053dff939cc5d",
        "edition": 202,
        "date": 1666813018094,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "202",
        "rarity_score": 124.41670240636132,
        "rarity_level": "COMMON"
    },
    "1077": {
        "name": "Kadena Skellies #1077",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1077.png",
        "dna": "10a71fe678764a36974cb602bbbca19f04321d28",
        "edition": 1077,
        "date": 1666813018170,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1077",
        "rarity_score": 141.34635713299505,
        "rarity_level": "COMMON"
    },
    "434": {
        "name": "Kadena Skellies #434",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/434.png",
        "dna": "fde44f8db914fe67910a6f43563cd0f0223d3796",
        "edition": 434,
        "date": 1666813018253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "434",
        "rarity_score": 186.90581252611346,
        "rarity_level": "RARE"
    },
    "1154": {
        "name": "Kadena Skellies #1154",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1154.png",
        "dna": "bcfb56b884df8201e96dbfb05544f38abbacc37e",
        "edition": 1154,
        "date": 1666813018326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1154",
        "rarity_score": 118.07798134091736,
        "rarity_level": "COMMON"
    },
    "1246": {
        "name": "Kadena Skellies #1246",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1246.png",
        "dna": "f5add5157c2750487751de22428414db561328c1",
        "edition": 1246,
        "date": 1666813018406,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1246",
        "rarity_score": 187.13649311075784,
        "rarity_level": "RARE"
    },
    "510": {
        "name": "Kadena Skellies #510",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/510.png",
        "dna": "b863e889d65ecedea0c748dc73d881191d877031",
        "edition": 510,
        "date": 1666813018479,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "510",
        "rarity_score": 145.088642407139,
        "rarity_level": "COMMON"
    },
    "366": {
        "name": "Kadena Skellies #366",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/366.png",
        "dna": "33be2f685afdee739c128d8aca7180e536afe8da",
        "edition": 366,
        "date": 1666813018553,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "366",
        "rarity_score": 177.6413046898633,
        "rarity_level": "RARE"
    },
    "668": {
        "name": "Kadena Skellies #668",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/668.png",
        "dna": "f01d54de6cada2fd8bce5e8216b53ed7015b34c9",
        "edition": 668,
        "date": 1666813018628,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "668",
        "rarity_score": 142.15426606276554,
        "rarity_level": "COMMON"
    },
    "672": {
        "name": "Kadena Skellies #672",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/672.png",
        "dna": "d5f6a62a9321ab4f9ba476756814e60eaabd31b3",
        "edition": 672,
        "date": 1666813018700,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "672",
        "rarity_score": 156.87641608610954,
        "rarity_level": "COMMON"
    },
    "312": {
        "name": "Kadena Skellies #312",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/312.png",
        "dna": "b3337f54afa592d9a345781583709160e8ea4e69",
        "edition": 312,
        "date": 1666813018773,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "312",
        "rarity_score": 121.90380717472428,
        "rarity_level": "COMMON"
    },
    "839": {
        "name": "Kadena Skellies #839",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/839.png",
        "dna": "d4f2e71ca9d254ea5a744a501f6381446f5a81f0",
        "edition": 839,
        "date": 1666813018844,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "839",
        "rarity_score": 140.919074046941,
        "rarity_level": "COMMON"
    },
    "846": {
        "name": "Kadena Skellies #846",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/846.png",
        "dna": "dc52a500873cfc2cbd9bc3111a031fdd3d946bf0",
        "edition": 846,
        "date": 1666813018917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "846",
        "rarity_score": 122.6111755750536,
        "rarity_level": "COMMON"
    },
    "67": {
        "name": "Kadena Skellies #67",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/67.png",
        "dna": "0d5d675e61a3437d8413de2f5d9ac4ad69207f00",
        "edition": 67,
        "date": 1666813018991,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "67",
        "rarity_score": 143.60065118093618,
        "rarity_level": "COMMON"
    },
    "637": {
        "name": "Kadena Skellies #637",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/637.png",
        "dna": "759a975871785c079647a85d438a1f3edcb509fb",
        "edition": 637,
        "date": 1666813019064,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "637",
        "rarity_score": 113.30011426529644,
        "rarity_level": "COMMON"
    },
    "164": {
        "name": "Kadena Skellies #164",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/164.png",
        "dna": "de56f210fccf5612dbd3ef19f1721a8c5c21bfc9",
        "edition": 164,
        "date": 1666813019136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "164",
        "rarity_score": 115.86500178864121,
        "rarity_level": "COMMON"
    },
    "530": {
        "name": "Kadena Skellies #530",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/530.png",
        "dna": "b383cc79eb2742965fb067594d765ef27d70f354",
        "edition": 530,
        "date": 1666813019210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "530",
        "rarity_score": 145.95641785255435,
        "rarity_level": "COMMON"
    },
    "576": {
        "name": "Kadena Skellies #576",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/576.png",
        "dna": "035bae2e1ed0bef63bf3beeaadb1359b091b0a7d",
        "edition": 576,
        "date": 1666813019280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "576",
        "rarity_score": 128.64179455131168,
        "rarity_level": "COMMON"
    },
    "688": {
        "name": "Kadena Skellies #688",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/688.png",
        "dna": "10eae880353c2fb1f85d8d71518335afb3f2aea2",
        "edition": 688,
        "date": 1666813019353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "688",
        "rarity_score": 209.08716149690113,
        "rarity_level": "RARE"
    },
    "1233": {
        "name": "Kadena Skellies #1233",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1233.png",
        "dna": "d5ec509d63a6ed3c01876250fa329991f8c41494",
        "edition": 1233,
        "date": 1666813019425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1233",
        "rarity_score": 119.66770438003778,
        "rarity_level": "COMMON"
    },
    "511": {
        "name": "Kadena Skellies #511",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/511.png",
        "dna": "563396111cf43bf82031b7657f04fb4c55508f7c",
        "edition": 511,
        "date": 1666813019495,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "511",
        "rarity_score": 179.29239146963155,
        "rarity_level": "RARE"
    },
    "700": {
        "name": "Kadena Skellies #700",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/700.png",
        "dna": "cadd86cd2cd257a65df012f3418c82f57bc08296",
        "edition": 700,
        "date": 1666813019570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "700",
        "rarity_score": 121.16979513143475,
        "rarity_level": "COMMON"
    },
    "299": {
        "name": "Kadena Skellies #299",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/299.png",
        "dna": "ad488de54c340af4072cc9e218e25cb9975138d6",
        "edition": 299,
        "date": 1666813019640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "299",
        "rarity_score": 162.96113539911994,
        "rarity_level": "COMMON"
    },
    "267": {
        "name": "Kadena Skellies #267",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/267.png",
        "dna": "acec3be58d3d14bc215deb453ead71e1679923c9",
        "edition": 267,
        "date": 1666813019711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "267",
        "rarity_score": 130.5284559315355,
        "rarity_level": "COMMON"
    },
    "345": {
        "name": "Kadena Skellies #345",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/345.png",
        "dna": "18695f33cc305522c33e9fb183879ba73b4db939",
        "edition": 345,
        "date": 1666813019786,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "345",
        "rarity_score": 143.5653973759679,
        "rarity_level": "COMMON"
    },
    "289": {
        "name": "Kadena Skellies #289",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/289.png",
        "dna": "c041edda9431c0626c762893e66e2ab25852aeeb",
        "edition": 289,
        "date": 1666813019859,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "289",
        "rarity_score": 162.85025674426595,
        "rarity_level": "COMMON"
    },
    "539": {
        "name": "Kadena Skellies #539",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/539.png",
        "dna": "f6ae81ddbd3742ae266836975e06cb1bc842bf7e",
        "edition": 539,
        "date": 1666813019938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "539",
        "rarity_score": 138.3281644637195,
        "rarity_level": "COMMON"
    },
    "702": {
        "name": "Kadena Skellies #702",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/702.png",
        "dna": "53131bcc50e72a35a8317ce407f86aa398d137b7",
        "edition": 702,
        "date": 1666813020012,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "702",
        "rarity_score": 113.72363650225097,
        "rarity_level": "COMMON"
    },
    "1152": {
        "name": "Kadena Skellies #1152",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1152.png",
        "dna": "93f059ab79a2704f1acb88881623063e32cfa029",
        "edition": 1152,
        "date": 1666813020089,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1152",
        "rarity_score": 119.66744765966855,
        "rarity_level": "COMMON"
    },
    "433": {
        "name": "Kadena Skellies #433",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/433.png",
        "dna": "0d4f1cb3f93da089c71d060581a1e7734ad7bad3",
        "edition": 433,
        "date": 1666813020171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "433",
        "rarity_score": 333.1495143369865,
        "rarity_level": "RARE"
    },
    "985": {
        "name": "Kadena Skellies #985",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/985.png",
        "dna": "ea4db49946fc37eda456921985789e3441fce7c3",
        "edition": 985,
        "date": 1666813020243,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "985",
        "rarity_score": 156.19622363547103,
        "rarity_level": "COMMON"
    },
    "198": {
        "name": "Kadena Skellies #198",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/198.png",
        "dna": "42ada40e414590d8ffb3675527d2c03bbd0a8500",
        "edition": 198,
        "date": 1666813020314,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "198",
        "rarity_score": 106.31350891760836,
        "rarity_level": "COMMON"
    },
    "340": {
        "name": "Kadena Skellies #340",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/340.png",
        "dna": "de96b7c90c6aa9003669aefb48b70f6d8eb11c5d",
        "edition": 340,
        "date": 1666813020390,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "340",
        "rarity_score": 141.57672179275318,
        "rarity_level": "COMMON"
    },
    "110": {
        "name": "Kadena Skellies #110",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/110.png",
        "dna": "5ba4b70103571e1d2572931ac1c17a6294680c92",
        "edition": 110,
        "date": 1666813020468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "110",
        "rarity_score": 163.70879610969124,
        "rarity_level": "COMMON"
    },
    "633": {
        "name": "Kadena Skellies #633",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/633.png",
        "dna": "93fd31e8fc21f0717375a863f59eb70d8b9eaef1",
        "edition": 633,
        "date": 1666813020541,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "633",
        "rarity_score": 103.73175539014446,
        "rarity_level": "COMMON"
    },
    "1080": {
        "name": "Kadena Skellies #1080",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1080.png",
        "dna": "168497d46eee3efb7c130c0362d3904a66b87261",
        "edition": 1080,
        "date": 1666813020614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1080",
        "rarity_score": 124.22800095213887,
        "rarity_level": "COMMON"
    },
    "87": {
        "name": "Kadena Skellies #87",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/87.png",
        "dna": "8f2b9ce704b546ea2b3db4bf1e16b1e8cf16efea",
        "edition": 87,
        "date": 1666813020684,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            { "trait_type": "Head", "value": "Crown", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "87",
        "rarity_score": 398.96664668381493,
        "rarity_level": "EPIC"
    },
    "804": {
        "name": "Kadena Skellies #804",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/804.png",
        "dna": "7a560dd72f70f5a4525f64cde421c8bad6084613",
        "edition": 804,
        "date": 1666813020757,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "804",
        "rarity_score": 178.47041208707034,
        "rarity_level": "RARE"
    },
    "445": {
        "name": "Kadena Skellies #445",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/445.png",
        "dna": "bb3204831a5ca53021daa7a208aa06d57e6d9d1e",
        "edition": 445,
        "date": 1666813020829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "445",
        "rarity_score": 134.0555655713339,
        "rarity_level": "COMMON"
    },
    "376": {
        "name": "Kadena Skellies #376",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/376.png",
        "dna": "03f8fa7b3981e4d71939845e57c692e04260c653",
        "edition": 376,
        "date": 1666813020901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "376",
        "rarity_score": 113.24140860389655,
        "rarity_level": "COMMON"
    },
    "1094": {
        "name": "Kadena Skellies #1094",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1094.png",
        "dna": "4a7156f7b5fd7ad64dba62afe13a03a9bdcd9bf0",
        "edition": 1094,
        "date": 1666813020976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1094",
        "rarity_score": 155.72433697401348,
        "rarity_level": "COMMON"
    },
    "1204": {
        "name": "Kadena Skellies #1204",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1204.png",
        "dna": "3f344ca3da1a804469d7d46c3a704306664eb9f6",
        "edition": 1204,
        "date": 1666813021054,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1204",
        "rarity_score": 158.56514606770233,
        "rarity_level": "COMMON"
    },
    "335": {
        "name": "Kadena Skellies #335",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/335.png",
        "dna": "5b75e46ae20a391d4db35254d66af8cb6c600942",
        "edition": 335,
        "date": 1666813021127,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "335",
        "rarity_score": 137.81205362818693,
        "rarity_level": "COMMON"
    },
    "210": {
        "name": "Kadena Skellies #210",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/210.png",
        "dna": "cd597a8f264915f9d63713ce5ba64428f72aa8e3",
        "edition": 210,
        "date": 1666813021200,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "210",
        "rarity_score": 103.51661099118165,
        "rarity_level": "COMMON"
    },
    "1022": {
        "name": "Kadena Skellies #1022",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1022.png",
        "dna": "3b54a8d7edcb519844f41623f21984ec125b6b09",
        "edition": 1022,
        "date": 1666813021273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1022",
        "rarity_score": 143.4789397890518,
        "rarity_level": "COMMON"
    },
    "910": {
        "name": "Kadena Skellies #910",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/910.png",
        "dna": "bd87eb3b9f96f489d909adfd40af9d9ed7b621c8",
        "edition": 910,
        "date": 1666813021347,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "910",
        "rarity_score": 92.375296564755,
        "rarity_level": "COMMON"
    },
    "395": {
        "name": "Kadena Skellies #395",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/395.png",
        "dna": "ce8d4b9f11caf17929193a7b1de8713ec62744ff",
        "edition": 395,
        "date": 1666813021418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "395",
        "rarity_score": 163.00389583163403,
        "rarity_level": "COMMON"
    },
    "557": {
        "name": "Kadena Skellies #557",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/557.png",
        "dna": "227b7cab13a2dbf71b8908a56df0e859c66680e3",
        "edition": 557,
        "date": 1666813021491,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "557",
        "rarity_score": 185.04658824250643,
        "rarity_level": "RARE"
    },
    "1012": {
        "name": "Kadena Skellies #1012",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1012.png",
        "dna": "1493f4ef07ac80af0652225911c2462fb4e8b9e8",
        "edition": 1012,
        "date": 1666813021568,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1012",
        "rarity_score": 107.65341896005647,
        "rarity_level": "COMMON"
    },
    "866": {
        "name": "Kadena Skellies #866",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/866.png",
        "dna": "d41875cdc1f2b70f233145936ed16e3ff6b625e8",
        "edition": 866,
        "date": 1666813021640,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "866",
        "rarity_score": 152.44375633984242,
        "rarity_level": "COMMON"
    },
    "148": {
        "name": "Kadena Skellies #148",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/148.png",
        "dna": "993b1f5bdc24cd048cab3aa4d2d047105172083a",
        "edition": 148,
        "date": 1666813021717,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "148",
        "rarity_score": 261.62941403009836,
        "rarity_level": "RARE"
    },
    "379": {
        "name": "Kadena Skellies #379",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/379.png",
        "dna": "f8eb6b7afae1032c865bc06cf80cdd35adac4adc",
        "edition": 379,
        "date": 1666813021791,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "379",
        "rarity_score": 150.8072027705139,
        "rarity_level": "COMMON"
    },
    "1198": {
        "name": "Kadena Skellies #1198",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1198.png",
        "dna": "0c73b106b4f08085e74a5698b91ea2cb6cded42f",
        "edition": 1198,
        "date": 1666813021863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1198",
        "rarity_score": 155.68117446984465,
        "rarity_level": "COMMON"
    },
    "721": {
        "name": "Kadena Skellies #721",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/721.png",
        "dna": "573d9f5f0006cb32aea46d8e10d99ae0583f17d7",
        "edition": 721,
        "date": 1666813021936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "721",
        "rarity_score": 107.78091661529413,
        "rarity_level": "COMMON"
    },
    "547": {
        "name": "Kadena Skellies #547",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/547.png",
        "dna": "e8a3b92e5e83ba50d73eedd2078c9891edf85cb1",
        "edition": 547,
        "date": 1666813022008,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "547",
        "rarity_score": 152.83375311572732,
        "rarity_level": "COMMON"
    },
    "1087": {
        "name": "Kadena Skellies #1087",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1087.png",
        "dna": "c8323dbbd3f89098726c138e034a741b99a0a480",
        "edition": 1087,
        "date": 1666813022080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1087",
        "rarity_score": 206.2578784750318,
        "rarity_level": "RARE"
    },
    "136": {
        "name": "Kadena Skellies #136",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/136.png",
        "dna": "b8a7b272e538b7b960aa8ceb527300b9d12524e5",
        "edition": 136,
        "date": 1666813022156,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "136",
        "rarity_score": 152.42370318990555,
        "rarity_level": "COMMON"
    },
    "473": {
        "name": "Kadena Skellies #473",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/473.png",
        "dna": "440c8a66a584ca0a6ecbeeb28b78e9262f0c3514",
        "edition": 473,
        "date": 1666813022228,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Arkade Sweatshirt",
                "score": 416.6666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "473",
        "rarity_score": 508.0930995029748,
        "rarity_level": "LEGENDARY"
    },
    "932": {
        "name": "Kadena Skellies #932",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/932.png",
        "dna": "e75c6b429cd4217881c652df1f6d5baac6144e6a",
        "edition": 932,
        "date": 1666813022299,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "932",
        "rarity_score": 156.8751730586598,
        "rarity_level": "COMMON"
    },
    "876": {
        "name": "Kadena Skellies #876",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/876.png",
        "dna": "7eb392bb02d4c1f83f100acf138c1dfbecf8f11e",
        "edition": 876,
        "date": 1666813022378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "876",
        "rarity_score": 121.77975733026413,
        "rarity_level": "COMMON"
    },
    "657": {
        "name": "Kadena Skellies #657",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/657.png",
        "dna": "a08242992d313628c5c0c1d4e065159b25639e23",
        "edition": 657,
        "date": 1666813022456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "657",
        "rarity_score": 236.54626007154096,
        "rarity_level": "RARE"
    },
    "20": {
        "name": "Kadena Skellies #20",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/20.png",
        "dna": "de66e91d015993fc89bb2093a3299d31c1d80358",
        "edition": 20,
        "date": 1666813022526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "20",
        "rarity_score": 119.03774643446849,
        "rarity_level": "COMMON"
    },
    "372": {
        "name": "Kadena Skellies #372",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/372.png",
        "dna": "703660494a9cf0d447ba8d505558614e2099b874",
        "edition": 372,
        "date": 1666813022599,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Arkade Sweatshirt",
                "score": 416.6666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "372",
        "rarity_score": 510.1295920933819,
        "rarity_level": "LEGENDARY"
    },
    "801": {
        "name": "Kadena Skellies #801",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/801.png",
        "dna": "451931383518a1f146e247f87fbe4dd62da82cee",
        "edition": 801,
        "date": 1666813022676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "801",
        "rarity_score": 221.8301030212165,
        "rarity_level": "RARE"
    },
    "973": {
        "name": "Kadena Skellies #973",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/973.png",
        "dna": "b274446011c51ec76d320377d7faaeefaff9fdb5",
        "edition": 973,
        "date": 1666813022748,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "973",
        "rarity_score": 152.8132555945855,
        "rarity_level": "COMMON"
    },
    "1186": {
        "name": "Kadena Skellies #1186",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1186.png",
        "dna": "a667c9a0098cd85853729b7d7a6f38f0f39ccbec",
        "edition": 1186,
        "date": 1666813022818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1186",
        "rarity_score": 118.39377582912067,
        "rarity_level": "COMMON"
    },
    "214": {
        "name": "Kadena Skellies #214",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/214.png",
        "dna": "ee9417652a4f5f2446f9fc809596de6930a06413",
        "edition": 214,
        "date": 1666813022890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "214",
        "rarity_score": 167.7546832068381,
        "rarity_level": "COMMON"
    },
    "457": {
        "name": "Kadena Skellies #457",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/457.png",
        "dna": "3750e2def2845f751aca852013f71b2e00470362",
        "edition": 457,
        "date": 1666813022962,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "457",
        "rarity_score": 109.23073583286752,
        "rarity_level": "COMMON"
    },
    "438": {
        "name": "Kadena Skellies #438",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/438.png",
        "dna": "55f51e168d0eca9593371fba08b257214f40165f",
        "edition": 438,
        "date": 1666813023033,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "438",
        "rarity_score": 160.28815388314274,
        "rarity_level": "COMMON"
    },
    "726": {
        "name": "Kadena Skellies #726",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/726.png",
        "dna": "05ff4a815bbe381b970942a458283ffd72be6de8",
        "edition": 726,
        "date": 1666813023110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "726",
        "rarity_score": 131.15561996472536,
        "rarity_level": "COMMON"
    },
    "459": {
        "name": "Kadena Skellies #459",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/459.png",
        "dna": "7d6a717d6b4844ed77aea936b13d499c270a7cd2",
        "edition": 459,
        "date": 1666813023179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "459",
        "rarity_score": 128.44842095563874,
        "rarity_level": "COMMON"
    },
    "895": {
        "name": "Kadena Skellies #895",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/895.png",
        "dna": "90f8b69abda5bca7e5196d61d3c5e7c450b1ed35",
        "edition": 895,
        "date": 1666813023253,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "895",
        "rarity_score": 258.3356834959727,
        "rarity_level": "RARE"
    },
    "970": {
        "name": "Kadena Skellies #970",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/970.png",
        "dna": "146f98a3a8193e2e0325d0ee8cc18d76758dd9d7",
        "edition": 970,
        "date": 1666813023327,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "970",
        "rarity_score": 116.3462969865409,
        "rarity_level": "COMMON"
    },
    "676": {
        "name": "Kadena Skellies #676",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/676.png",
        "dna": "5c753311bcade29575af8acf52132437b0436bca",
        "edition": 676,
        "date": 1666813023397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "676",
        "rarity_score": 151.55809859123218,
        "rarity_level": "COMMON"
    },
    "776": {
        "name": "Kadena Skellies #776",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/776.png",
        "dna": "9279fb45074b3d05ac052337c8fbc85e9d1eaaab",
        "edition": 776,
        "date": 1666813023468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "776",
        "rarity_score": 152.2545278978071,
        "rarity_level": "COMMON"
    },
    "615": {
        "name": "Kadena Skellies #615",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/615.png",
        "dna": "9029d911fa1f512c09496a4bca956989df3d1c8f",
        "edition": 615,
        "date": 1666813023541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "615",
        "rarity_score": 168.24783299376503,
        "rarity_level": "COMMON"
    },
    "259": {
        "name": "Kadena Skellies #259",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/259.png",
        "dna": "10a3682307165a2658b2d48819887ee6244c65c9",
        "edition": 259,
        "date": 1666813023610,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "259",
        "rarity_score": 108.21644329708846,
        "rarity_level": "COMMON"
    },
    "600": {
        "name": "Kadena Skellies #600",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/600.png",
        "dna": "bbb313b9c73c1263121a86b8715a9d67f165ae45",
        "edition": 600,
        "date": 1666813023680,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "600",
        "rarity_score": 119.7957859543097,
        "rarity_level": "COMMON"
    },
    "675": {
        "name": "Kadena Skellies #675",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/675.png",
        "dna": "b265f739c69e8a8728ec716a47b9754918dfa297",
        "edition": 675,
        "date": 1666813023751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "675",
        "rarity_score": 117.13561495708015,
        "rarity_level": "COMMON"
    },
    "429": {
        "name": "Kadena Skellies #429",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/429.png",
        "dna": "629f3f43bea3744903f2046fd8b9eaa02d26b5cf",
        "edition": 429,
        "date": 1666813023821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "429",
        "rarity_score": 162.4287202817695,
        "rarity_level": "COMMON"
    },
    "37": {
        "name": "Kadena Skellies #37",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/37.png",
        "dna": "c054105d8deafe964dc111fbe462e7a57d6992e3",
        "edition": 37,
        "date": 1666813023898,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "37",
        "rarity_score": 130.76410732660733,
        "rarity_level": "COMMON"
    },
    "78": {
        "name": "Kadena Skellies #78",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/78.png",
        "dna": "0669e4e600024a5f3fe3bde1df2996c3f6389533",
        "edition": 78,
        "date": 1666813023968,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "78",
        "rarity_score": 132.68428429684414,
        "rarity_level": "COMMON"
    },
    "1076": {
        "name": "Kadena Skellies #1076",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1076.png",
        "dna": "c5d160bec55c94abf40570738c5fc46c26296890",
        "edition": 1076,
        "date": 1666813024044,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1076",
        "rarity_score": 127.13890847980528,
        "rarity_level": "COMMON"
    },
    "719": {
        "name": "Kadena Skellies #719",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/719.png",
        "dna": "483204e64e0f2e1342b9de879de69c5acd0a53e5",
        "edition": 719,
        "date": 1666813024120,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "719",
        "rarity_score": 179.59005816148672,
        "rarity_level": "RARE"
    },
    "383": {
        "name": "Kadena Skellies #383",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/383.png",
        "dna": "1d496be1eefe5421da724f784838af62a4949b98",
        "edition": 383,
        "date": 1666813024190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "383",
        "rarity_score": 132.13182413512382,
        "rarity_level": "COMMON"
    },
    "1157": {
        "name": "Kadena Skellies #1157",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1157.png",
        "dna": "92a5c038a1c7b1834e85095afdb28ffbd75eceb2",
        "edition": 1157,
        "date": 1666813024262,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1157",
        "rarity_score": 116.74213862925365,
        "rarity_level": "COMMON"
    },
    "392": {
        "name": "Kadena Skellies #392",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/392.png",
        "dna": "0ca774bc787e039859c0d454a2656704530b044f",
        "edition": 392,
        "date": 1666813024335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "392",
        "rarity_score": 177.1752300576588,
        "rarity_level": "RARE"
    },
    "73": {
        "name": "Kadena Skellies #73",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/73.png",
        "dna": "1080749bccb586660e7293aa74559f36dcae6af8",
        "edition": 73,
        "date": 1666813024404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "73",
        "rarity_score": 97.27229681625309,
        "rarity_level": "COMMON"
    },
    "572": {
        "name": "Kadena Skellies #572",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/572.png",
        "dna": "13883a1a1cae0d17ec855631aafeab5769a745f9",
        "edition": 572,
        "date": 1666813024476,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "572",
        "rarity_score": 144.08166059146973,
        "rarity_level": "COMMON"
    },
    "112": {
        "name": "Kadena Skellies #112",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/112.png",
        "dna": "f48dfb27e2427ebaf9e56c170ed32f214d7b863a",
        "edition": 112,
        "date": 1666813024547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "112",
        "rarity_score": 191.28589742431777,
        "rarity_level": "RARE"
    },
    "487": {
        "name": "Kadena Skellies #487",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/487.png",
        "dna": "ab73dd9270dd1593f1b83ec913a92f5d44e0b404",
        "edition": 487,
        "date": 1666813024619,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "487",
        "rarity_score": 127.51016637602656,
        "rarity_level": "COMMON"
    },
    "838": {
        "name": "Kadena Skellies #838",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/838.png",
        "dna": "db14d8cccd3d6da8f859fad692c282d812df8354",
        "edition": 838,
        "date": 1666813024696,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "838",
        "rarity_score": 293.26925524048613,
        "rarity_level": "RARE"
    },
    "49": {
        "name": "Kadena Skellies #49",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/49.png",
        "dna": "7f18386ea1dad03fc2e40bce9cbfb6d95e0989a4",
        "edition": 49,
        "date": 1666813024767,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "49",
        "rarity_score": 161.67347233523705,
        "rarity_level": "COMMON"
    },
    "708": {
        "name": "Kadena Skellies #708",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/708.png",
        "dna": "3538baceaa6730efba91d71a6122d37977759290",
        "edition": 708,
        "date": 1666813024840,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            { "trait_type": "Head", "value": "Brown Ponytail", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "708",
        "rarity_score": 435.6473827132224,
        "rarity_level": "EPIC"
    },
    "34": {
        "name": "Kadena Skellies #34",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/34.png",
        "dna": "9b4f14583ee28e20ff49ef569d7728dea9ff86a6",
        "edition": 34,
        "date": 1666813024911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "34",
        "rarity_score": 114.39726105878533,
        "rarity_level": "COMMON"
    },
    "291": {
        "name": "Kadena Skellies #291",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/291.png",
        "dna": "e862ba7b6169a63efbb13269eeceab1c6e543780",
        "edition": 291,
        "date": 1666813024982,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "291",
        "rarity_score": 139.22864631603775,
        "rarity_level": "COMMON"
    },
    "157": {
        "name": "Kadena Skellies #157",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/157.png",
        "dna": "d9fb76ee6b9722ae4c663fc085a069156d6cc48c",
        "edition": 157,
        "date": 1666813025060,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "157",
        "rarity_score": 174.82390447209877,
        "rarity_level": "RARE"
    },
    "135": {
        "name": "Kadena Skellies #135",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/135.png",
        "dna": "43a83391a93f3aebdeaa36542acd28f5d7431fa7",
        "edition": 135,
        "date": 1666813025133,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "135",
        "rarity_score": 141.50035944988238,
        "rarity_level": "COMMON"
    },
    "448": {
        "name": "Kadena Skellies #448",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/448.png",
        "dna": "e7c2685cb2e12faa72f663a0ac4f7b07c19703de",
        "edition": 448,
        "date": 1666813025203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "448",
        "rarity_score": 132.26471243136626,
        "rarity_level": "COMMON"
    },
    "54": {
        "name": "Kadena Skellies #54",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/54.png",
        "dna": "42d678c705e0e16d4d89d0af61285a3b207199ae",
        "edition": 54,
        "date": 1666813025276,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "54",
        "rarity_score": 117.38814657455602,
        "rarity_level": "COMMON"
    },
    "449": {
        "name": "Kadena Skellies #449",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/449.png",
        "dna": "fef4cd4dd59eb32191bc4153c8f820ee40f37797",
        "edition": 449,
        "date": 1666813025345,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "449",
        "rarity_score": 217.7044385231452,
        "rarity_level": "RARE"
    },
    "667": {
        "name": "Kadena Skellies #667",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/667.png",
        "dna": "7ad7dc8d25915255f7d163afa43878b96a2274de",
        "edition": 667,
        "date": 1666813025422,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "667",
        "rarity_score": 125.2170773759351,
        "rarity_level": "COMMON"
    },
    "603": {
        "name": "Kadena Skellies #603",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/603.png",
        "dna": "742c63526d62a887d6854a3de607ad5d04bcc908",
        "edition": 603,
        "date": 1666813025492,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "603",
        "rarity_score": 117.19710957854952,
        "rarity_level": "COMMON"
    },
    "831": {
        "name": "Kadena Skellies #831",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/831.png",
        "dna": "3a75d8fdd8aa74db70ca413865d5eefa300222b1",
        "edition": 831,
        "date": 1666813025563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "831",
        "rarity_score": 121.08035664502692,
        "rarity_level": "COMMON"
    },
    "228": {
        "name": "Kadena Skellies #228",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/228.png",
        "dna": "0bb454954da3f1c6e7e29948d86b5038f6cd1121",
        "edition": 228,
        "date": 1666813025639,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "228",
        "rarity_score": 117.76694385538994,
        "rarity_level": "COMMON"
    },
    "437": {
        "name": "Kadena Skellies #437",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/437.png",
        "dna": "9050a7c3722312702e9bdbf251554d802c2ee202",
        "edition": 437,
        "date": 1666813025709,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "437",
        "rarity_score": 151.232317460893,
        "rarity_level": "COMMON"
    },
    "671": {
        "name": "Kadena Skellies #671",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/671.png",
        "dna": "84a6a91d407d495db4fa3fad6f923bdc37025966",
        "edition": 671,
        "date": 1666813025780,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "671",
        "rarity_score": 125.18115856956264,
        "rarity_level": "COMMON"
    },
    "1148": {
        "name": "Kadena Skellies #1148",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1148.png",
        "dna": "abedf7d9aecd3951e23cfd6cc36f57e3b718ed5d",
        "edition": 1148,
        "date": 1666813025854,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1148",
        "rarity_score": 138.95537331651272,
        "rarity_level": "COMMON"
    },
    "400": {
        "name": "Kadena Skellies #400",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/400.png",
        "dna": "f6018acaefc47fca4dbc592f5ee47a827ecd180e",
        "edition": 400,
        "date": 1666813025925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "400",
        "rarity_score": 132.3684837243755,
        "rarity_level": "COMMON"
    },
    "754": {
        "name": "Kadena Skellies #754",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/754.png",
        "dna": "93d6b1609be80f3c5eeb8c11fc3e8f0afb4d8b93",
        "edition": 754,
        "date": 1666813025996,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "754",
        "rarity_score": 97.94448486336532,
        "rarity_level": "COMMON"
    },
    "105": {
        "name": "Kadena Skellies #105",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/105.png",
        "dna": "e42a0308bff03ef30c58c3f8b9e6b959c1e4581c",
        "edition": 105,
        "date": 1666813026068,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "105",
        "rarity_score": 128.45134858249077,
        "rarity_level": "COMMON"
    },
    "254": {
        "name": "Kadena Skellies #254",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/254.png",
        "dna": "fb9a38b53630e60bf20630b00e18875c3a95700f",
        "edition": 254,
        "date": 1666813026139,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "254",
        "rarity_score": 105.29144028356352,
        "rarity_level": "COMMON"
    },
    "1125": {
        "name": "Kadena Skellies #1125",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1125.png",
        "dna": "f359d3733e3d36bf510f1776171e1fe53aca0fea",
        "edition": 1125,
        "date": 1666813026212,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1125",
        "rarity_score": 116.06047626964755,
        "rarity_level": "COMMON"
    },
    "786": {
        "name": "Kadena Skellies #786",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/786.png",
        "dna": "32ef4486abab600eb15b65db84282c3343a97966",
        "edition": 786,
        "date": 1666813026283,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "786",
        "rarity_score": 117.45840866859908,
        "rarity_level": "COMMON"
    },
    "914": {
        "name": "Kadena Skellies #914",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/914.png",
        "dna": "af263d86a2d9c2d59bd4dc3b6a2201143511fa74",
        "edition": 914,
        "date": 1666813026360,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            { "trait_type": "Head", "value": "Brown Ponytail", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "914",
        "rarity_score": 412.3973214495811,
        "rarity_level": "EPIC"
    },
    "583": {
        "name": "Kadena Skellies #583",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/583.png",
        "dna": "763fae801674b803fcce3c8bfc13e7ed6d7e7ce1",
        "edition": 583,
        "date": 1666813026435,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "583",
        "rarity_score": 202.5330614413113,
        "rarity_level": "RARE"
    },
    "958": {
        "name": "Kadena Skellies #958",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/958.png",
        "dna": "870a30638a356b9d041cecb78f24e7f599782440",
        "edition": 958,
        "date": 1666813026506,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "958",
        "rarity_score": 181.2018894913944,
        "rarity_level": "RARE"
    },
    "1167": {
        "name": "Kadena Skellies #1167",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1167.png",
        "dna": "9e65318574dbd77ab92fedf9628a92619c75e473",
        "edition": 1167,
        "date": 1666813026577,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1167",
        "rarity_score": 96.9048885232753,
        "rarity_level": "COMMON"
    },
    "746": {
        "name": "Kadena Skellies #746",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/746.png",
        "dna": "4db6aba266d49b25067ac084c42973864bee90b6",
        "edition": 746,
        "date": 1666813026653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "746",
        "rarity_score": 104.83342087627005,
        "rarity_level": "COMMON"
    },
    "130": {
        "name": "Kadena Skellies #130",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/130.png",
        "dna": "c3658a711be9a263b006c902d8693d6ae0e21d48",
        "edition": 130,
        "date": 1666813026724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "130",
        "rarity_score": 113.58801696585613,
        "rarity_level": "COMMON"
    },
    "1166": {
        "name": "Kadena Skellies #1166",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1166.png",
        "dna": "6e8bb1b0c04bc228f1b105e1a3bbd787d1fb2591",
        "edition": 1166,
        "date": 1666813026797,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1166",
        "rarity_score": 158.8011048083497,
        "rarity_level": "COMMON"
    },
    "538": {
        "name": "Kadena Skellies #538",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/538.png",
        "dna": "aa95ccd1330ac690441370fd8f8dc95aa666bf1e",
        "edition": 538,
        "date": 1666813026882,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Space Helmet",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "538",
        "rarity_score": 319.1843124055433,
        "rarity_level": "RARE"
    },
    "763": {
        "name": "Kadena Skellies #763",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/763.png",
        "dna": "7acc0394f5522369960b6e85988641aa189beddc",
        "edition": 763,
        "date": 1666813026958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "763",
        "rarity_score": 161.06414285292587,
        "rarity_level": "COMMON"
    },
    "248": {
        "name": "Kadena Skellies #248",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/248.png",
        "dna": "df1043385733967ad2e087b5095f0a4f4b5feedc",
        "edition": 248,
        "date": 1666813027033,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "248",
        "rarity_score": 116.79652138997216,
        "rarity_level": "COMMON"
    },
    "1135": {
        "name": "Kadena Skellies #1135",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1135.png",
        "dna": "433808bff2f903a4c09a323cb74583cb621ab0fc",
        "edition": 1135,
        "date": 1666813027106,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1135",
        "rarity_score": 99.4792451382322,
        "rarity_level": "COMMON"
    },
    "494": {
        "name": "Kadena Skellies #494",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/494.png",
        "dna": "54b6c079efb42d587c7765cf3a960f55ab1d0b65",
        "edition": 494,
        "date": 1666813027178,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "494",
        "rarity_score": 126.802688637795,
        "rarity_level": "COMMON"
    },
    "872": {
        "name": "Kadena Skellies #872",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/872.png",
        "dna": "feb53f426fc39a37d01095d2543f563c6f80e28b",
        "edition": 872,
        "date": 1666813027250,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "872",
        "rarity_score": 163.24908680949173,
        "rarity_level": "COMMON"
    },
    "1067": {
        "name": "Kadena Skellies #1067",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1067.png",
        "dna": "2a23f61ec49d4dfe027bf9c9ff645a05e29f6359",
        "edition": 1067,
        "date": 1666813027322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1067",
        "rarity_score": 131.2989192929424,
        "rarity_level": "COMMON"
    },
    "693": {
        "name": "Kadena Skellies #693",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/693.png",
        "dna": "8ff177f59a60845b093aa1650291a391261c2f54",
        "edition": 693,
        "date": 1666813027393,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "693",
        "rarity_score": 127.3129988254321,
        "rarity_level": "COMMON"
    },
    "257": {
        "name": "Kadena Skellies #257",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/257.png",
        "dna": "7e1c0a30aa5c92882cc566109ef7e0ca81fde48f",
        "edition": 257,
        "date": 1666813027463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "257",
        "rarity_score": 103.88464691870706,
        "rarity_level": "COMMON"
    },
    "490": {
        "name": "Kadena Skellies #490",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/490.png",
        "dna": "350fec975f1be5d66f0e933941c028dd0d66aa78",
        "edition": 490,
        "date": 1666813027533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "490",
        "rarity_score": 151.43957918936562,
        "rarity_level": "COMMON"
    },
    "425": {
        "name": "Kadena Skellies #425",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/425.png",
        "dna": "8508dbfa9b2f27f964108f8b51f0e5be3107eba6",
        "edition": 425,
        "date": 1666813027606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "425",
        "rarity_score": 153.75687962371717,
        "rarity_level": "COMMON"
    },
    "247": {
        "name": "Kadena Skellies #247",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/247.png",
        "dna": "a846a74c7eb36618825e4cbc2cfd0baecf37276d",
        "edition": 247,
        "date": 1666813027676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "247",
        "rarity_score": 147.6357213756237,
        "rarity_level": "COMMON"
    },
    "994": {
        "name": "Kadena Skellies #994",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/994.png",
        "dna": "804b12ca0c263b4de06f1a10735825fa0f921130",
        "edition": 994,
        "date": 1666813027747,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "994",
        "rarity_score": 160.65499602267178,
        "rarity_level": "COMMON"
    },
    "799": {
        "name": "Kadena Skellies #799",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/799.png",
        "dna": "a3dd36961dc3c75653bb8d971cff297c7fef3ec7",
        "edition": 799,
        "date": 1666813027820,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "799",
        "rarity_score": 204.81925025912042,
        "rarity_level": "RARE"
    },
    "577": {
        "name": "Kadena Skellies #577",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/577.png",
        "dna": "6c76802ebc5976901d54c118191a56c78316b6bc",
        "edition": 577,
        "date": 1666813027897,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "577",
        "rarity_score": 118.86311021466433,
        "rarity_level": "COMMON"
    },
    "19": {
        "name": "Kadena Skellies #19",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/19.png",
        "dna": "f4f4700d11af8d1d341f47c2fb496b88003e2f70",
        "edition": 19,
        "date": 1666813027970,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "19",
        "rarity_score": 185.65395654324576,
        "rarity_level": "RARE"
    },
    "456": {
        "name": "Kadena Skellies #456",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/456.png",
        "dna": "1b1490f3af58406154913723d9c29fbb79a31ed5",
        "edition": 456,
        "date": 1666813028040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "456",
        "rarity_score": 169.48452049453496,
        "rarity_level": "RARE"
    },
    "826": {
        "name": "Kadena Skellies #826",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/826.png",
        "dna": "14aed2becaaca5a477d6f9e70911c4c44a28a2f1",
        "edition": 826,
        "date": 1666813028116,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Crown", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "826",
        "rarity_score": 418.7218988096572,
        "rarity_level": "EPIC"
    },
    "358": {
        "name": "Kadena Skellies #358",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/358.png",
        "dna": "239577b49b0f6a860b9fcfbb354a85885eb2a316",
        "edition": 358,
        "date": 1666813028190,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "358",
        "rarity_score": 111.50298938401097,
        "rarity_level": "COMMON"
    },
    "482": {
        "name": "Kadena Skellies #482",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/482.png",
        "dna": "a825272c78614e2191228f4d8cc4fdba01304923",
        "edition": 482,
        "date": 1666813028265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "482",
        "rarity_score": 158.49535155023764,
        "rarity_level": "COMMON"
    },
    "282": {
        "name": "Kadena Skellies #282",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/282.png",
        "dna": "ecf1c1cc1194b9c0f6ce02cabe6e254dd99d13a6",
        "edition": 282,
        "date": 1666813028341,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "282",
        "rarity_score": 199.19972241023538,
        "rarity_level": "RARE"
    },
    "953": {
        "name": "Kadena Skellies #953",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/953.png",
        "dna": "ea85aae315fcc68f93210bdbcadff533cf8717e8",
        "edition": 953,
        "date": 1666813028417,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "953",
        "rarity_score": 157.12182949218027,
        "rarity_level": "COMMON"
    },
    "518": {
        "name": "Kadena Skellies #518",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/518.png",
        "dna": "f11c536a419e86d0890a153c13dc409b4da3f147",
        "edition": 518,
        "date": 1666813028493,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "518",
        "rarity_score": 143.59229932164675,
        "rarity_level": "COMMON"
    },
    "1162": {
        "name": "Kadena Skellies #1162",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1162.png",
        "dna": "7b7d739b9c73de56cc64793bcb1af8bbd80b8099",
        "edition": 1162,
        "date": 1666813028573,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1162",
        "rarity_score": 208.16037751935215,
        "rarity_level": "RARE"
    },
    "266": {
        "name": "Kadena Skellies #266",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/266.png",
        "dna": "0fc7486dcec4e691294c67219ea674a7ad7df5a3",
        "edition": 266,
        "date": 1666813028649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "266",
        "rarity_score": 131.23221549359775,
        "rarity_level": "COMMON"
    },
    "666": {
        "name": "Kadena Skellies #666",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/666.png",
        "dna": "72344dd98163502e8e6e6e3ddf6e99e8cfe81249",
        "edition": 666,
        "date": 1666813028726,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "666",
        "rarity_score": 123.90630385086864,
        "rarity_level": "COMMON"
    },
    "1019": {
        "name": "Kadena Skellies #1019",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1019.png",
        "dna": "8e22566f8d49173322ce3979592fb2e9ca2f06e2",
        "edition": 1019,
        "date": 1666813028807,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1019",
        "rarity_score": 119.09195680137546,
        "rarity_level": "COMMON"
    },
    "662": {
        "name": "Kadena Skellies #662",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/662.png",
        "dna": "aebcd48e7688a3cf49dca4d7afb725948bbc3068",
        "edition": 662,
        "date": 1666813028884,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "662",
        "rarity_score": 118.05654988199746,
        "rarity_level": "COMMON"
    },
    "542": {
        "name": "Kadena Skellies #542",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/542.png",
        "dna": "f446cad7020aa79d2bc7e9b7579ab9f66f2f678d",
        "edition": 542,
        "date": 1666813028957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "542",
        "rarity_score": 119.62339355640377,
        "rarity_level": "COMMON"
    },
    "149": {
        "name": "Kadena Skellies #149",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/149.png",
        "dna": "339f53bc841690380dd0bdbf6a5f7a3164944ff5",
        "edition": 149,
        "date": 1666813029033,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "149",
        "rarity_score": 125.21863774884014,
        "rarity_level": "COMMON"
    },
    "757": {
        "name": "Kadena Skellies #757",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/757.png",
        "dna": "7b0c5e11f6c214763737728132e6e28a100428af",
        "edition": 757,
        "date": 1666813029111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "757",
        "rarity_score": 245.24983724853743,
        "rarity_level": "RARE"
    },
    "6": {
        "name": "Kadena Skellies #6",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/6.png",
        "dna": "fa7db785ef7a7e641f6710902bfd850b4c482b6d",
        "edition": 6,
        "date": 1666813029189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            { "trait_type": "Head", "value": "Crown", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "6",
        "rarity_score": 421.8219745590059,
        "rarity_level": "EPIC"
    },
    "1029": {
        "name": "Kadena Skellies #1029",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1029.png",
        "dna": "415e6882eeb2ce8853e5c0e5554defc750307d9c",
        "edition": 1029,
        "date": 1666813029268,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1029",
        "rarity_score": 113.89268859091874,
        "rarity_level": "COMMON"
    },
    "792": {
        "name": "Kadena Skellies #792",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/792.png",
        "dna": "d663a0dc4d4d8b92aca1af23aa6a3cd34939dfa1",
        "edition": 792,
        "date": 1666813029339,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "792",
        "rarity_score": 129.88733584264577,
        "rarity_level": "COMMON"
    },
    "1209": {
        "name": "Kadena Skellies #1209",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1209.png",
        "dna": "9971abf858f42ef559a96dcf636e757afdd10d12",
        "edition": 1209,
        "date": 1666813029418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1209",
        "rarity_score": 93.70072313346402,
        "rarity_level": "COMMON"
    },
    "24": {
        "name": "Kadena Skellies #24",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/24.png",
        "dna": "bfab3e521171eafc19a23e39032ba23532474b79",
        "edition": 24,
        "date": 1666813029490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "24",
        "rarity_score": 285.5291529810342,
        "rarity_level": "RARE"
    },
    "1220": {
        "name": "Kadena Skellies #1220",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1220.png",
        "dna": "c90f7669388c948fde5497989e766475e0e0868b",
        "edition": 1220,
        "date": 1666813029564,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1220",
        "rarity_score": 128.64475678945044,
        "rarity_level": "COMMON"
    },
    "115": {
        "name": "Kadena Skellies #115",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/115.png",
        "dna": "f2f6c60479607b8df9fff167ee0a8d74d367518c",
        "edition": 115,
        "date": 1666813029635,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "115",
        "rarity_score": 142.43352110999172,
        "rarity_level": "COMMON"
    },
    "949": {
        "name": "Kadena Skellies #949",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/949.png",
        "dna": "70ce562329d1a1c5367de54c1c3fe1c1621f8f47",
        "edition": 949,
        "date": 1666813029707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "949",
        "rarity_score": 116.60223480107956,
        "rarity_level": "COMMON"
    },
    "1133": {
        "name": "Kadena Skellies #1133",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1133.png",
        "dna": "5989a5546c9154ed11bf067d8804b3a8ddb99729",
        "edition": 1133,
        "date": 1666813029788,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1133",
        "rarity_score": 189.16793809035187,
        "rarity_level": "RARE"
    },
    "65": {
        "name": "Kadena Skellies #65",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/65.png",
        "dna": "bd01bef33b68d8b591600af7e8d849ce7422898d",
        "edition": 65,
        "date": 1666813029858,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Arkade Sweatshirt",
                "score": 416.6666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "65",
        "rarity_score": 525.5538592851781,
        "rarity_level": "LEGENDARY"
    },
    "760": {
        "name": "Kadena Skellies #760",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/760.png",
        "dna": "983639fa85c7612be33ba7e8362e0c047bcd676d",
        "edition": 760,
        "date": 1666813029928,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "760",
        "rarity_score": 101.48266395684693,
        "rarity_level": "COMMON"
    },
    "925": {
        "name": "Kadena Skellies #925",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/925.png",
        "dna": "68055375407038c36caecc9e2b400a6933c3866e",
        "edition": 925,
        "date": 1666813030001,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "925",
        "rarity_score": 144.09804243930455,
        "rarity_level": "COMMON"
    },
    "1007": {
        "name": "Kadena Skellies #1007",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1007.png",
        "dna": "2f57f8861aaacf8430a723911a57aa5f66e6f38e",
        "edition": 1007,
        "date": 1666813030071,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1007",
        "rarity_score": 124.86825423216169,
        "rarity_level": "COMMON"
    },
    "415": {
        "name": "Kadena Skellies #415",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/415.png",
        "dna": "0aad5cce5f52d6255f870e5bd316302060fb7420",
        "edition": 415,
        "date": 1666813030145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "415",
        "rarity_score": 207.1004236078729,
        "rarity_level": "RARE"
    },
    "848": {
        "name": "Kadena Skellies #848",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/848.png",
        "dna": "f5bbe4cd1a14868da3303298dce5b114133c2145",
        "edition": 848,
        "date": 1666813030216,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "848",
        "rarity_score": 109.57124300698032,
        "rarity_level": "COMMON"
    },
    "390": {
        "name": "Kadena Skellies #390",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/390.png",
        "dna": "b05cf569496bd52da97bb9574345900feba24aa2",
        "edition": 390,
        "date": 1666813030285,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "390",
        "rarity_score": 209.78155898147554,
        "rarity_level": "RARE"
    },
    "1178": {
        "name": "Kadena Skellies #1178",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1178.png",
        "dna": "33542b9a214be0f5c37df3235b84e2db4c004402",
        "edition": 1178,
        "date": 1666813030366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1178",
        "rarity_score": 128.08291146032764,
        "rarity_level": "COMMON"
    },
    "734": {
        "name": "Kadena Skellies #734",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/734.png",
        "dna": "0775be308b7d49f4ed7c565fcdd177575c03956a",
        "edition": 734,
        "date": 1666813030436,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "734",
        "rarity_score": 131.22173203860638,
        "rarity_level": "COMMON"
    },
    "605": {
        "name": "Kadena Skellies #605",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/605.png",
        "dna": "002d7f91095a67186dbf171b372d9cf4822b66c5",
        "edition": 605,
        "date": 1666813030512,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "605",
        "rarity_score": 143.128936449297,
        "rarity_level": "COMMON"
    },
    "935": {
        "name": "Kadena Skellies #935",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/935.png",
        "dna": "23a33bce38af323a960c064b382bba0b74483fcc",
        "edition": 935,
        "date": 1666813030589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "935",
        "rarity_score": 135.05225200556671,
        "rarity_level": "COMMON"
    },
    "436": {
        "name": "Kadena Skellies #436",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/436.png",
        "dna": "cd4f4b68784327453ff9ea488d4ae175f682ffaf",
        "edition": 436,
        "date": 1666813030660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "436",
        "rarity_score": 213.91244187541528,
        "rarity_level": "RARE"
    },
    "567": {
        "name": "Kadena Skellies #567",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/567.png",
        "dna": "251eba00ebcf0ebaca027ea5565eb087d3fd8ed0",
        "edition": 567,
        "date": 1666813030731,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "567",
        "rarity_score": 95.59602091183343,
        "rarity_level": "COMMON"
    },
    "735": {
        "name": "Kadena Skellies #735",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/735.png",
        "dna": "fae60797c8a8daf338f9fe90d5f062a5e8fd7e36",
        "edition": 735,
        "date": 1666813030808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "735",
        "rarity_score": 121.27304119926818,
        "rarity_level": "COMMON"
    },
    "33": {
        "name": "Kadena Skellies #33",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/33.png",
        "dna": "1d04c4ac3b65541d7a7c183b82cc1f3434724013",
        "edition": 33,
        "date": 1666813030878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "33",
        "rarity_score": 187.48573467610169,
        "rarity_level": "RARE"
    },
    "93": {
        "name": "Kadena Skellies #93",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/93.png",
        "dna": "031851b5b6c8cb503015872af5cfe41d5a23868e",
        "edition": 93,
        "date": 1666813030957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "93",
        "rarity_score": 109.25650751201749,
        "rarity_level": "COMMON"
    },
    "712": {
        "name": "Kadena Skellies #712",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/712.png",
        "dna": "0f3a0a0d2a7dbfa0f6e36587f6cc3f5fe7ef3623",
        "edition": 712,
        "date": 1666813031034,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "712",
        "rarity_score": 135.84067820929465,
        "rarity_level": "COMMON"
    },
    "447": {
        "name": "Kadena Skellies #447",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/447.png",
        "dna": "e6ed3828b919b17b9cd39a259951ec54f5ddcab0",
        "edition": 447,
        "date": 1666813031109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "447",
        "rarity_score": 183.56250594371264,
        "rarity_level": "RARE"
    },
    "77": {
        "name": "Kadena Skellies #77",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/77.png",
        "dna": "e946735d219e44356932d239916c9e8f7f28a8c1",
        "edition": 77,
        "date": 1666813031182,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "77",
        "rarity_score": 136.6654363897011,
        "rarity_level": "COMMON"
    },
    "479": {
        "name": "Kadena Skellies #479",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/479.png",
        "dna": "d3c1493c5bf3955a59ab2904202e51fa6e160b70",
        "edition": 479,
        "date": 1666813031254,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "479",
        "rarity_score": 211.80832356185795,
        "rarity_level": "RARE"
    },
    "606": {
        "name": "Kadena Skellies #606",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/606.png",
        "dna": "ed4b55c5b949eccdd42fe518206f1527fcd3e0b5",
        "edition": 606,
        "date": 1666813031326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "606",
        "rarity_score": 125.04156942422169,
        "rarity_level": "COMMON"
    },
    "1026": {
        "name": "Kadena Skellies #1026",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1026.png",
        "dna": "dceda5545e371bc403d86e1414d23a2f59bc39f0",
        "edition": 1026,
        "date": 1666813031396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1026",
        "rarity_score": 115.53416635334112,
        "rarity_level": "COMMON"
    },
    "686": {
        "name": "Kadena Skellies #686",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/686.png",
        "dna": "8005e9472fefc2e93bf8b802701c16bff0df723c",
        "edition": 686,
        "date": 1666813031467,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "686",
        "rarity_score": 152.52526047900417,
        "rarity_level": "COMMON"
    },
    "814": {
        "name": "Kadena Skellies #814",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/814.png",
        "dna": "48d22ed9636e01642962738cab2f7180cae111d0",
        "edition": 814,
        "date": 1666813031543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "814",
        "rarity_score": 120.07278385774876,
        "rarity_level": "COMMON"
    },
    "408": {
        "name": "Kadena Skellies #408",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/408.png",
        "dna": "f474c885a402fd86703f82fd79dbea52d487fba8",
        "edition": 408,
        "date": 1666813031613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "408",
        "rarity_score": 121.59252224022043,
        "rarity_level": "COMMON"
    },
    "943": {
        "name": "Kadena Skellies #943",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/943.png",
        "dna": "eb46462dd4f9080a2d2e638533f76ad0ead0c94b",
        "edition": 943,
        "date": 1666813031683,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "943",
        "rarity_score": 172.10833982117654,
        "rarity_level": "RARE"
    },
    "613": {
        "name": "Kadena Skellies #613",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/613.png",
        "dna": "6b300d8d8b5a3818b58c19366a13349bdd9db806",
        "edition": 613,
        "date": 1666813031756,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "613",
        "rarity_score": 137.1372939457807,
        "rarity_level": "COMMON"
    },
    "414": {
        "name": "Kadena Skellies #414",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/414.png",
        "dna": "66317d2eb71afe5a6803df5bd971fd7013a9ab2d",
        "edition": 414,
        "date": 1666813031832,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "414",
        "rarity_score": 167.16357635617803,
        "rarity_level": "COMMON"
    },
    "172": {
        "name": "Kadena Skellies #172",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/172.png",
        "dna": "b4c012596781e2834281edd534c308dda466c821",
        "edition": 172,
        "date": 1666813031904,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "172",
        "rarity_score": 122.90374192947723,
        "rarity_level": "COMMON"
    },
    "402": {
        "name": "Kadena Skellies #402",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/402.png",
        "dna": "a1bb77413be93226556fe19ecebdeb38ca30e01c",
        "edition": 402,
        "date": 1666813031975,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "402",
        "rarity_score": 144.71133009634454,
        "rarity_level": "COMMON"
    },
    "611": {
        "name": "Kadena Skellies #611",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/611.png",
        "dna": "6e573bbc4cb7d8c0681adb22c084eed78e980488",
        "edition": 611,
        "date": 1666813032046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "611",
        "rarity_score": 128.23643473603616,
        "rarity_level": "COMMON"
    },
    "9": {
        "name": "Kadena Skellies #9",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/9.png",
        "dna": "a342e26c4eddf7bb06a589169f1508af926d7c79",
        "edition": 9,
        "date": 1666813032122,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "9",
        "rarity_score": 145.03899151671982,
        "rarity_level": "COMMON"
    },
    "527": {
        "name": "Kadena Skellies #527",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/527.png",
        "dna": "162696a3e2644c05e92b236251f30c5af063879a",
        "edition": 527,
        "date": 1666813032193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "527",
        "rarity_score": 93.84458057135348,
        "rarity_level": "COMMON"
    },
    "472": {
        "name": "Kadena Skellies #472",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/472.png",
        "dna": "da47a2a966dec5516df7b9fb477f87b590383936",
        "edition": 472,
        "date": 1666813032264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "472",
        "rarity_score": 138.71281483845758,
        "rarity_level": "COMMON"
    },
    "377": {
        "name": "Kadena Skellies #377",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/377.png",
        "dna": "a5f79214fe671b827a642fa617fde7596148af30",
        "edition": 377,
        "date": 1666813032340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "377",
        "rarity_score": 178.96579206780268,
        "rarity_level": "RARE"
    },
    "151": {
        "name": "Kadena Skellies #151",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/151.png",
        "dna": "45d57a03661193461b7b57a16ddb464d3db86dc4",
        "edition": 151,
        "date": 1666813032412,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "151",
        "rarity_score": 174.4127280226418,
        "rarity_level": "RARE"
    },
    "1203": {
        "name": "Kadena Skellies #1203",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1203.png",
        "dna": "247966830103f9fc545d687f61bae2b5a382cba5",
        "edition": 1203,
        "date": 1666813032485,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1203",
        "rarity_score": 141.5176374979821,
        "rarity_level": "COMMON"
    },
    "1107": {
        "name": "Kadena Skellies #1107",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1107.png",
        "dna": "96f9000eb91abf9462a3485ff466b04f33d1e972",
        "edition": 1107,
        "date": 1666813032560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1107",
        "rarity_score": 152.49471209593858,
        "rarity_level": "COMMON"
    },
    "634": {
        "name": "Kadena Skellies #634",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/634.png",
        "dna": "711c277c1da683ad23bd3f78d4214713b9d8c912",
        "edition": 634,
        "date": 1666813032632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "634",
        "rarity_score": 154.01695868428806,
        "rarity_level": "COMMON"
    },
    "188": {
        "name": "Kadena Skellies #188",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/188.png",
        "dna": "23087d9f629bed764cd63f1e7d4e1db16161a56c",
        "edition": 188,
        "date": 1666813032703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "188",
        "rarity_score": 118.89746473389734,
        "rarity_level": "COMMON"
    },
    "568": {
        "name": "Kadena Skellies #568",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/568.png",
        "dna": "c1fbcc998364be1dcae8927905659db379f2b506",
        "edition": 568,
        "date": 1666813032774,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "568",
        "rarity_score": 146.23757950736794,
        "rarity_level": "COMMON"
    },
    "561": {
        "name": "Kadena Skellies #561",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/561.png",
        "dna": "5309f47592ea645d7370e2248945af4ef8ecd246",
        "edition": 561,
        "date": 1666813032845,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "561",
        "rarity_score": 133.70245167037683,
        "rarity_level": "COMMON"
    },
    "53": {
        "name": "Kadena Skellies #53",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/53.png",
        "dna": "acbfc2ad46929ec0db2c1ec91e2be5197589e3d8",
        "edition": 53,
        "date": 1666813032920,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "53",
        "rarity_score": 143.66832933009405,
        "rarity_level": "COMMON"
    },
    "177": {
        "name": "Kadena Skellies #177",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/177.png",
        "dna": "aaa6b321f222f8658c7126cf52daa0737bffbbaf",
        "edition": 177,
        "date": 1666813032991,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "177",
        "rarity_score": 113.94339781422751,
        "rarity_level": "COMMON"
    },
    "1024": {
        "name": "Kadena Skellies #1024",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1024.png",
        "dna": "87c37303a062fb2ca16f4c559f0778e2d8b548e9",
        "edition": 1024,
        "date": 1666813033066,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1024",
        "rarity_score": 129.10763446466416,
        "rarity_level": "COMMON"
    },
    "659": {
        "name": "Kadena Skellies #659",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/659.png",
        "dna": "d8e7c49d76a985d6f0a5e27a625b6a19d0b2a674",
        "edition": 659,
        "date": 1666813033137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "659",
        "rarity_score": 135.72483280952287,
        "rarity_level": "COMMON"
    },
    "269": {
        "name": "Kadena Skellies #269",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/269.png",
        "dna": "9c5b3c8dd0a21bac37aa9bf2a80c6d115f8bd737",
        "edition": 269,
        "date": 1666813033208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "269",
        "rarity_score": 116.89748957599308,
        "rarity_level": "COMMON"
    },
    "62": {
        "name": "Kadena Skellies #62",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/62.png",
        "dna": "367d090ebc689fb468e9cbecad4244738f2236da",
        "edition": 62,
        "date": 1666813033291,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "62",
        "rarity_score": 128.03602678944586,
        "rarity_level": "COMMON"
    },
    "593": {
        "name": "Kadena Skellies #593",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/593.png",
        "dna": "7fcb2ff1233240ed80ddc3de4d1e44773fc40626",
        "edition": 593,
        "date": 1666813033362,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "593",
        "rarity_score": 109.22721087487687,
        "rarity_level": "COMMON"
    },
    "747": {
        "name": "Kadena Skellies #747",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/747.png",
        "dna": "4c95f7bafe379a66d96f7c64240ba5a5dd7374ac",
        "edition": 747,
        "date": 1666813033434,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "747",
        "rarity_score": 133.73939342014032,
        "rarity_level": "COMMON"
    },
    "80": {
        "name": "Kadena Skellies #80",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/80.png",
        "dna": "17271fee44c9b753e1ba6541bbd1087a087d9ec5",
        "edition": 80,
        "date": 1666813033507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            { "trait_type": "Eyes", "value": "Red Cross Eyes", "score": 250.0 },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "80",
        "rarity_score": 366.35278045491214,
        "rarity_level": "EPIC"
    },
    "912": {
        "name": "Kadena Skellies #912",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/912.png",
        "dna": "94d7909d638469aa093650267a8a648b4ea224eb",
        "edition": 912,
        "date": 1666813033579,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "912",
        "rarity_score": 151.02603957271816,
        "rarity_level": "COMMON"
    },
    "484": {
        "name": "Kadena Skellies #484",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/484.png",
        "dna": "05c19f61d24e296fb6d495738cdc8f7928b24297",
        "edition": 484,
        "date": 1666813033651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "484",
        "rarity_score": 133.95873678934768,
        "rarity_level": "COMMON"
    },
    "430": {
        "name": "Kadena Skellies #430",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/430.png",
        "dna": "5f9b564c75b60bb7fc6bf7d7161d0606ddf904a9",
        "edition": 430,
        "date": 1666813033727,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "430",
        "rarity_score": 168.27757577956015,
        "rarity_level": "COMMON"
    },
    "1090": {
        "name": "Kadena Skellies #1090",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1090.png",
        "dna": "4ff6987932f026d94f1dc3a231227a5a3e531f5c",
        "edition": 1090,
        "date": 1666813033800,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1090",
        "rarity_score": 271.9722606335887,
        "rarity_level": "RARE"
    },
    "285": {
        "name": "Kadena Skellies #285",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/285.png",
        "dna": "61c5b0daad181269fd02271ec52dab5e2d1cb1c7",
        "edition": 285,
        "date": 1666813033880,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "285",
        "rarity_score": 197.7523868912955,
        "rarity_level": "RARE"
    },
    "146": {
        "name": "Kadena Skellies #146",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/146.png",
        "dna": "661cb70afe44a3e5f923dd6d35e80f024db266ec",
        "edition": 146,
        "date": 1666813033952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "146",
        "rarity_score": 123.07371514664763,
        "rarity_level": "COMMON"
    },
    "1023": {
        "name": "Kadena Skellies #1023",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1023.png",
        "dna": "68cfe469b8613b1b75c2a966586ab5efe75e8727",
        "edition": 1023,
        "date": 1666813034023,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1023",
        "rarity_score": 144.59350254300313,
        "rarity_level": "COMMON"
    },
    "193": {
        "name": "Kadena Skellies #193",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/193.png",
        "dna": "6a004f4f66c832bd3430390aff6975c864307e93",
        "edition": 193,
        "date": 1666813034099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "193",
        "rarity_score": 216.43410402780717,
        "rarity_level": "RARE"
    },
    "262": {
        "name": "Kadena Skellies #262",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/262.png",
        "dna": "c655c7a74b3bd20bfde1c228666543ca54e398e7",
        "edition": 262,
        "date": 1666813034171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "262",
        "rarity_score": 131.6929948884747,
        "rarity_level": "COMMON"
    },
    "863": {
        "name": "Kadena Skellies #863",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/863.png",
        "dna": "e92fff58f55b2382e566488c9e4191b3053897d9",
        "edition": 863,
        "date": 1666813034243,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "863",
        "rarity_score": 126.55322923461627,
        "rarity_level": "COMMON"
    },
    "296": {
        "name": "Kadena Skellies #296",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/296.png",
        "dna": "ed2a2d45303354c486801ee500113cb9b16f33ce",
        "edition": 296,
        "date": 1666813034318,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "296",
        "rarity_score": 116.16108134961596,
        "rarity_level": "COMMON"
    },
    "961": {
        "name": "Kadena Skellies #961",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/961.png",
        "dna": "0c0be95f63aaf377d93e610cadfdc7b6d4ea647a",
        "edition": 961,
        "date": 1666813034390,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "961",
        "rarity_score": 118.03806257664444,
        "rarity_level": "COMMON"
    },
    "95": {
        "name": "Kadena Skellies #95",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/95.png",
        "dna": "c06eea1e2a92f1f9f4777a9dedce23d19a433772",
        "edition": 95,
        "date": 1666813034464,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "95",
        "rarity_score": 135.96581428165396,
        "rarity_level": "COMMON"
    },
    "213": {
        "name": "Kadena Skellies #213",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/213.png",
        "dna": "3b12e98fe31cf76d761bd143e0de9c1075567c85",
        "edition": 213,
        "date": 1666813034538,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "213",
        "rarity_score": 114.106697955212,
        "rarity_level": "COMMON"
    },
    "485": {
        "name": "Kadena Skellies #485",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/485.png",
        "dna": "430957cfb992f0e6dbde2d908f0da5f62c18cc78",
        "edition": 485,
        "date": 1666813034611,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "485",
        "rarity_score": 312.15985375209783,
        "rarity_level": "RARE"
    },
    "939": {
        "name": "Kadena Skellies #939",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/939.png",
        "dna": "7107c71345591d586575584a0f00f3f502307d70",
        "edition": 939,
        "date": 1666813034687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "939",
        "rarity_score": 124.90099990099989,
        "rarity_level": "COMMON"
    },
    "1060": {
        "name": "Kadena Skellies #1060",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1060.png",
        "dna": "49ea9ead36fad68329bb10fc6fd24d05b1a81244",
        "edition": 1060,
        "date": 1666813034757,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1060",
        "rarity_score": 136.0466550855475,
        "rarity_level": "COMMON"
    },
    "128": {
        "name": "Kadena Skellies #128",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/128.png",
        "dna": "0dd6105a5f99b25c8d2dfa6cd0b432198734b14e",
        "edition": 128,
        "date": 1666813034830,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "128",
        "rarity_score": 117.70250037974164,
        "rarity_level": "COMMON"
    },
    "13": {
        "name": "Kadena Skellies #13",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/13.png",
        "dna": "40790b60808b90bbad58d3a403ad871d630e7127",
        "edition": 13,
        "date": 1666813034903,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "13",
        "rarity_score": 171.94068912638932,
        "rarity_level": "RARE"
    },
    "28": {
        "name": "Kadena Skellies #28",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/28.png",
        "dna": "cdda4ca793200e148b09e0d3344a00db033ac085",
        "edition": 28,
        "date": 1666813034975,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "28",
        "rarity_score": 167.73894944601633,
        "rarity_level": "COMMON"
    },
    "99": {
        "name": "Kadena Skellies #99",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/99.png",
        "dna": "912eb0deac2d934217697e0035c4946ae10169a9",
        "edition": 99,
        "date": 1666813035054,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "99",
        "rarity_score": 134.85172344100567,
        "rarity_level": "COMMON"
    },
    "342": {
        "name": "Kadena Skellies #342",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/342.png",
        "dna": "c61a672323eba8ad1edf38b5c38c54202cb3d984",
        "edition": 342,
        "date": 1666813035126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "342",
        "rarity_score": 241.6580307536948,
        "rarity_level": "RARE"
    },
    "534": {
        "name": "Kadena Skellies #534",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/534.png",
        "dna": "3c3e01376d7a2259ddc4d09f6960e166f398b9a8",
        "edition": 534,
        "date": 1666813035197,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "534",
        "rarity_score": 210.944793821776,
        "rarity_level": "RARE"
    },
    "1082": {
        "name": "Kadena Skellies #1082",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1082.png",
        "dna": "0bd7c56acedd9b5c8b8c7b9c9dbd3f9f1480e864",
        "edition": 1082,
        "date": 1666813035272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1082",
        "rarity_score": 124.80722043554675,
        "rarity_level": "COMMON"
    },
    "677": {
        "name": "Kadena Skellies #677",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/677.png",
        "dna": "b8978ca53b7e75cfc6bd44690cd73a2542e74e1a",
        "edition": 677,
        "date": 1666813035347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "677",
        "rarity_score": 154.56762294692115,
        "rarity_level": "COMMON"
    },
    "488": {
        "name": "Kadena Skellies #488",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/488.png",
        "dna": "69aa005c45e9020d89be299903aae39b6b736243",
        "edition": 488,
        "date": 1666813035424,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "488",
        "rarity_score": 171.2356334128735,
        "rarity_level": "RARE"
    },
    "1195": {
        "name": "Kadena Skellies #1195",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1195.png",
        "dna": "b53d4abd2bbb0f0be9feaa79bd921bd08479e63a",
        "edition": 1195,
        "date": 1666813035500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1195",
        "rarity_score": 152.91231491346366,
        "rarity_level": "COMMON"
    },
    "870": {
        "name": "Kadena Skellies #870",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/870.png",
        "dna": "719b49e29df57033c461b6d51c725edf5034512d",
        "edition": 870,
        "date": 1666813035572,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "870",
        "rarity_score": 197.46271906198376,
        "rarity_level": "RARE"
    },
    "833": {
        "name": "Kadena Skellies #833",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/833.png",
        "dna": "cc793a61278374144edaf9ccb06e860fada8b7ab",
        "edition": 833,
        "date": 1666813035660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "833",
        "rarity_score": 154.05132350267465,
        "rarity_level": "COMMON"
    },
    "865": {
        "name": "Kadena Skellies #865",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/865.png",
        "dna": "66a322fb94f61cbdda6e506658bc74a969a062d7",
        "edition": 865,
        "date": 1666813035730,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "865",
        "rarity_score": 109.66064297277902,
        "rarity_level": "COMMON"
    },
    "1037": {
        "name": "Kadena Skellies #1037",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1037.png",
        "dna": "06ca059b88a8161459889caa628681f5ccfde084",
        "edition": 1037,
        "date": 1666813035806,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1037",
        "rarity_score": 142.98223910537612,
        "rarity_level": "COMMON"
    },
    "352": {
        "name": "Kadena Skellies #352",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/352.png",
        "dna": "91fea884186947ad25b6f767a730dc762a472797",
        "edition": 352,
        "date": 1666813035879,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "352",
        "rarity_score": 208.18841076411888,
        "rarity_level": "RARE"
    },
    "920": {
        "name": "Kadena Skellies #920",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/920.png",
        "dna": "560cd089aa302532b20c300fdf1de8ad57078a70",
        "edition": 920,
        "date": 1666813035950,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "920",
        "rarity_score": 132.35524204560903,
        "rarity_level": "COMMON"
    },
    "813": {
        "name": "Kadena Skellies #813",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/813.png",
        "dna": "d26f9f455bc84040ca61c0b1f380d447d4aa9e7f",
        "edition": 813,
        "date": 1666813036022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "813",
        "rarity_score": 182.556450735482,
        "rarity_level": "RARE"
    },
    "1068": {
        "name": "Kadena Skellies #1068",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1068.png",
        "dna": "63b0e4e77899c6b0344a6a7a067ace05a67f062b",
        "edition": 1068,
        "date": 1666813036093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1068",
        "rarity_score": 123.7834310890142,
        "rarity_level": "COMMON"
    },
    "367": {
        "name": "Kadena Skellies #367",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/367.png",
        "dna": "ee7a3a86d2e1d28af77e68ba012f8cb59ba37eaf",
        "edition": 367,
        "date": 1666813036164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "367",
        "rarity_score": 231.4409210282334,
        "rarity_level": "RARE"
    },
    "273": {
        "name": "Kadena Skellies #273",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/273.png",
        "dna": "9aedc776eabf3b29e658ad26cfb827c438eff182",
        "edition": 273,
        "date": 1666813036242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "273",
        "rarity_score": 205.5028096707915,
        "rarity_level": "RARE"
    },
    "159": {
        "name": "Kadena Skellies #159",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/159.png",
        "dna": "b39e576fa274d74bab6d7983467c0da13fc52da4",
        "edition": 159,
        "date": 1666813036315,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "159",
        "rarity_score": 134.81668603444217,
        "rarity_level": "COMMON"
    },
    "1215": {
        "name": "Kadena Skellies #1215",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1215.png",
        "dna": "59ff18380bb51a51289d8dae8b15e2469391bed3",
        "edition": 1215,
        "date": 1666813036387,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1215",
        "rarity_score": 203.64886298909224,
        "rarity_level": "RARE"
    },
    "602": {
        "name": "Kadena Skellies #602",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/602.png",
        "dna": "70a9de8515d822e423da6f827064244c71bb08d6",
        "edition": 602,
        "date": 1666813036460,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "602",
        "rarity_score": 235.20633012085182,
        "rarity_level": "RARE"
    },
    "1066": {
        "name": "Kadena Skellies #1066",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1066.png",
        "dna": "b55fa567f44e807ca60b7e49aa55342055b48fb6",
        "edition": 1066,
        "date": 1666813036531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1066",
        "rarity_score": 136.24539818169717,
        "rarity_level": "COMMON"
    },
    "934": {
        "name": "Kadena Skellies #934",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/934.png",
        "dna": "efb3cc93e9340a9779a2b178ee5b03cb6a896db8",
        "edition": 934,
        "date": 1666813036601,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "934",
        "rarity_score": 128.32036219347273,
        "rarity_level": "COMMON"
    },
    "643": {
        "name": "Kadena Skellies #643",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/643.png",
        "dna": "005c6f372ac6e5e6bec369cdfef96ed8c8df9bf3",
        "edition": 643,
        "date": 1666813036673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "643",
        "rarity_score": 127.76544421551262,
        "rarity_level": "COMMON"
    },
    "27": {
        "name": "Kadena Skellies #27",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/27.png",
        "dna": "26bd7edc96acc1375d94371986024409337e6d09",
        "edition": 27,
        "date": 1666813036745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "27",
        "rarity_score": 222.33665212077838,
        "rarity_level": "RARE"
    },
    "373": {
        "name": "Kadena Skellies #373",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/373.png",
        "dna": "af54ebe760459abd8610c13538452a4ace2b538c",
        "edition": 373,
        "date": 1666813036817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "373",
        "rarity_score": 140.83768156205653,
        "rarity_level": "COMMON"
    },
    "1134": {
        "name": "Kadena Skellies #1134",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1134.png",
        "dna": "358f543d7b8ae2148ed9b33bc6c7252625104477",
        "edition": 1134,
        "date": 1666813036886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1134",
        "rarity_score": 108.9449842094585,
        "rarity_level": "COMMON"
    },
    "992": {
        "name": "Kadena Skellies #992",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/992.png",
        "dna": "7613bc27e3eddcd7748c089289b57ebf1153e799",
        "edition": 992,
        "date": 1666813036955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "992",
        "rarity_score": 147.53129480631299,
        "rarity_level": "COMMON"
    },
    "674": {
        "name": "Kadena Skellies #674",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/674.png",
        "dna": "69c39da19947f1e1cc26d4964d3a61d78c5550ef",
        "edition": 674,
        "date": 1666813037028,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "674",
        "rarity_score": 135.60082340187734,
        "rarity_level": "COMMON"
    },
    "211": {
        "name": "Kadena Skellies #211",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/211.png",
        "dna": "9bb13053d11996068eefe49b58f6bf273df7ca48",
        "edition": 211,
        "date": 1666813037099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "211",
        "rarity_score": 127.68746445804202,
        "rarity_level": "COMMON"
    },
    "197": {
        "name": "Kadena Skellies #197",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/197.png",
        "dna": "1e2c6c5be14853280522fd9a4498a24247672143",
        "edition": 197,
        "date": 1666813037176,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "197",
        "rarity_score": 132.9452861004885,
        "rarity_level": "COMMON"
    },
    "680": {
        "name": "Kadena Skellies #680",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/680.png",
        "dna": "c2ae244dae4b8d7cc194b39ef12b9d502c9ab326",
        "edition": 680,
        "date": 1666813037257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "680",
        "rarity_score": 144.96332962390883,
        "rarity_level": "COMMON"
    },
    "1050": {
        "name": "Kadena Skellies #1050",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1050.png",
        "dna": "019443347949355eb8b531913e44bb0f025c06e4",
        "edition": 1050,
        "date": 1666813037328,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1050",
        "rarity_score": 136.5165875698413,
        "rarity_level": "COMMON"
    },
    "474": {
        "name": "Kadena Skellies #474",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/474.png",
        "dna": "ceab124051313e274323194f3a826fc2ea7bd787",
        "edition": 474,
        "date": 1666813037406,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "474",
        "rarity_score": 124.52140697635116,
        "rarity_level": "COMMON"
    },
    "707": {
        "name": "Kadena Skellies #707",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/707.png",
        "dna": "f49b9c2e929e512fbd0fc4cce367dccd1ec79f9f",
        "edition": 707,
        "date": 1666813037477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "707",
        "rarity_score": 140.7985110300777,
        "rarity_level": "COMMON"
    },
    "260": {
        "name": "Kadena Skellies #260",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/260.png",
        "dna": "7215cb958e051a01b61bca45a03c2e4871314679",
        "edition": 260,
        "date": 1666813037547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "260",
        "rarity_score": 106.25831057001025,
        "rarity_level": "COMMON"
    },
    "86": {
        "name": "Kadena Skellies #86",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/86.png",
        "dna": "fdbbb4b7c997e7dfb3e058269139f3c1a8bfc03b",
        "edition": 86,
        "date": 1666813037621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "86",
        "rarity_score": 113.16289838533032,
        "rarity_level": "COMMON"
    },
    "580": {
        "name": "Kadena Skellies #580",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/580.png",
        "dna": "9e541043cd4d4dfdb5092894aa998c7ed830a098",
        "edition": 580,
        "date": 1666813037697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "580",
        "rarity_score": 220.32972412168397,
        "rarity_level": "RARE"
    },
    "1079": {
        "name": "Kadena Skellies #1079",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1079.png",
        "dna": "77f682703b19e2392f1cd10448a8ca622c62875f",
        "edition": 1079,
        "date": 1666813037768,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1079",
        "rarity_score": 143.4093280981783,
        "rarity_level": "COMMON"
    },
    "165": {
        "name": "Kadena Skellies #165",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/165.png",
        "dna": "14748495812bbae4575e33a7ccef36116697273d",
        "edition": 165,
        "date": 1666813037845,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "165",
        "rarity_score": 124.19703971598275,
        "rarity_level": "COMMON"
    },
    "695": {
        "name": "Kadena Skellies #695",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/695.png",
        "dna": "32f95cf68ef44b090b15fea4008b3989a6c4f769",
        "edition": 695,
        "date": 1666813037917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "695",
        "rarity_score": 140.0043085874806,
        "rarity_level": "COMMON"
    },
    "361": {
        "name": "Kadena Skellies #361",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/361.png",
        "dna": "98d2130f73e8716933442b467dc62f41803818b1",
        "edition": 361,
        "date": 1666813037995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "361",
        "rarity_score": 109.20871721205691,
        "rarity_level": "COMMON"
    },
    "1151": {
        "name": "Kadena Skellies #1151",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1151.png",
        "dna": "b33d958c9f1f84d9834a54b7137500c795b2aef3",
        "edition": 1151,
        "date": 1666813038067,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1151",
        "rarity_score": 100.85786771103893,
        "rarity_level": "COMMON"
    },
    "61": {
        "name": "Kadena Skellies #61",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/61.png",
        "dna": "b8aad4021f07a9d7176eaf4e8f7c94ae3fe7af15",
        "edition": 61,
        "date": 1666813038140,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "61",
        "rarity_score": 126.44167419394,
        "rarity_level": "COMMON"
    },
    "175": {
        "name": "Kadena Skellies #175",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/175.png",
        "dna": "a00d9d20dee1349b41e1cc09f7d7cb5ea64d6815",
        "edition": 175,
        "date": 1666813038214,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "175",
        "rarity_score": 124.97049413609517,
        "rarity_level": "COMMON"
    },
    "217": {
        "name": "Kadena Skellies #217",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/217.png",
        "dna": "5152b5e2abe811e37210db2fdde263d45427e68e",
        "edition": 217,
        "date": 1666813038285,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "217",
        "rarity_score": 117.64898843387215,
        "rarity_level": "COMMON"
    },
    "989": {
        "name": "Kadena Skellies #989",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/989.png",
        "dna": "11112c44e9fef23f7ad4dff153233bcbf5ce0311",
        "edition": 989,
        "date": 1666813038356,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "989",
        "rarity_score": 141.9347128704502,
        "rarity_level": "COMMON"
    },
    "1155": {
        "name": "Kadena Skellies #1155",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1155.png",
        "dna": "9d3413908e36f48721f3f089063949fe9204e42b",
        "edition": 1155,
        "date": 1666813038428,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1155",
        "rarity_score": 154.7172247181449,
        "rarity_level": "COMMON"
    },
    "608": {
        "name": "Kadena Skellies #608",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/608.png",
        "dna": "60218c8e9471baade3f244dde5891b189c227e7a",
        "edition": 608,
        "date": 1666813038506,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "608",
        "rarity_score": 115.03035849076262,
        "rarity_level": "COMMON"
    },
    "186": {
        "name": "Kadena Skellies #186",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/186.png",
        "dna": "146baba167f4c93c3f24d951996d33a0decc88ab",
        "edition": 186,
        "date": 1666813038584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "186",
        "rarity_score": 141.90258254026003,
        "rarity_level": "COMMON"
    },
    "55": {
        "name": "Kadena Skellies #55",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/55.png",
        "dna": "37db520f5ccbdfe28cbd35b452d8e5020003db82",
        "edition": 55,
        "date": 1666813038655,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "55",
        "rarity_score": 134.41716581330508,
        "rarity_level": "COMMON"
    },
    "1237": {
        "name": "Kadena Skellies #1237",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1237.png",
        "dna": "44065ac84dffec5fc8826caeac28ec7668aa0178",
        "edition": 1237,
        "date": 1666813038726,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1237",
        "rarity_score": 130.16646695497468,
        "rarity_level": "COMMON"
    },
    "265": {
        "name": "Kadena Skellies #265",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/265.png",
        "dna": "3cb314b95540df2e4a5f6dffde54b359bcb84407",
        "edition": 265,
        "date": 1666813038799,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "265",
        "rarity_score": 151.7980400690454,
        "rarity_level": "COMMON"
    },
    "350": {
        "name": "Kadena Skellies #350",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/350.png",
        "dna": "1ddc71a28ee648b9b467217f6b6c193a2246ce71",
        "edition": 350,
        "date": 1666813038879,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "350",
        "rarity_score": 149.43528575345937,
        "rarity_level": "COMMON"
    },
    "191": {
        "name": "Kadena Skellies #191",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/191.png",
        "dna": "db65ed4f64c1c896da4b11b2efa9934852d76221",
        "edition": 191,
        "date": 1666813038953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "191",
        "rarity_score": 236.84479184702423,
        "rarity_level": "RARE"
    },
    "1006": {
        "name": "Kadena Skellies #1006",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1006.png",
        "dna": "a93ba041a84f583bc3477f9de50f5818598f504a",
        "edition": 1006,
        "date": 1666813039026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1006",
        "rarity_score": 149.3664783336466,
        "rarity_level": "COMMON"
    },
    "4": {
        "name": "Kadena Skellies #4",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/4.png",
        "dna": "f96c099b6b8e2c7bf3cea519719b7e3dc494722c",
        "edition": 4,
        "date": 1666813039106,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "4",
        "rarity_score": 157.8440178809108,
        "rarity_level": "COMMON"
    },
    "1078": {
        "name": "Kadena Skellies #1078",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1078.png",
        "dna": "4f19550651df08d6e22ab4836573e46ec98cc1a8",
        "edition": 1078,
        "date": 1666813039181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Brown Ponytail", "score": 312.5 },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1078",
        "rarity_score": 395.9893261316797,
        "rarity_level": "EPIC"
    },
    "604": {
        "name": "Kadena Skellies #604",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/604.png",
        "dna": "d8283250418d2b75b55247848b33c2487ac74477",
        "edition": 604,
        "date": 1666813039253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "604",
        "rarity_score": 99.75391513012679,
        "rarity_level": "COMMON"
    },
    "36": {
        "name": "Kadena Skellies #36",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/36.png",
        "dna": "5c57920236adce078360b314119814b6a76b1816",
        "edition": 36,
        "date": 1666813039328,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "36",
        "rarity_score": 203.69992388056647,
        "rarity_level": "RARE"
    },
    "371": {
        "name": "Kadena Skellies #371",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/371.png",
        "dna": "0b5a8b21d1c91b061d20bb99dcc2f28730c4548a",
        "edition": 371,
        "date": 1666813039406,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "371",
        "rarity_score": 121.92455988134472,
        "rarity_level": "COMMON"
    },
    "506": {
        "name": "Kadena Skellies #506",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/506.png",
        "dna": "6045a815de31dd66265bd1ab3091b5501d388dc1",
        "edition": 506,
        "date": 1666813039484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "506",
        "rarity_score": 149.25127358293162,
        "rarity_level": "COMMON"
    },
    "1011": {
        "name": "Kadena Skellies #1011",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1011.png",
        "dna": "bb47c07df141c4a4756bde3f901ecb647b18830f",
        "edition": 1011,
        "date": 1666813039563,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1011",
        "rarity_score": 145.21744402259108,
        "rarity_level": "COMMON"
    },
    "1049": {
        "name": "Kadena Skellies #1049",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1049.png",
        "dna": "3abb8ecc62fb313853ac98d0f8c0fd6d31e0cd81",
        "edition": 1049,
        "date": 1666813039634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1049",
        "rarity_score": 178.35886863434197,
        "rarity_level": "RARE"
    },
    "941": {
        "name": "Kadena Skellies #941",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/941.png",
        "dna": "b7fa3f238a3df9e34156d823ffea21d77b91d8f8",
        "edition": 941,
        "date": 1666813039708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "941",
        "rarity_score": 213.38192704380063,
        "rarity_level": "RARE"
    },
    "802": {
        "name": "Kadena Skellies #802",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/802.png",
        "dna": "96eb9ae24c9a9d6fb78f0b60fc42c4bf55fe23c0",
        "edition": 802,
        "date": 1666813039786,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "802",
        "rarity_score": 93.42617231417341,
        "rarity_level": "COMMON"
    },
    "843": {
        "name": "Kadena Skellies #843",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/843.png",
        "dna": "8fda7387d64fb9b10a3c939c552b779ff8906469",
        "edition": 843,
        "date": 1666813039859,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "843",
        "rarity_score": 103.72370044930656,
        "rarity_level": "COMMON"
    },
    "612": {
        "name": "Kadena Skellies #612",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/612.png",
        "dna": "847941bbac743ad791d9ed3676e13ddbdeb9fcf3",
        "edition": 612,
        "date": 1666813039931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "612",
        "rarity_score": 140.63100959916636,
        "rarity_level": "COMMON"
    },
    "532": {
        "name": "Kadena Skellies #532",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/532.png",
        "dna": "5ac6785480b68178600aa6635a4464a7da4f8da8",
        "edition": 532,
        "date": 1666813040005,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "532",
        "rarity_score": 169.1312136827479,
        "rarity_level": "RARE"
    },
    "16": {
        "name": "Kadena Skellies #16",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/16.png",
        "dna": "52e8f7cf62e4130430c31a8dfa76bb84549b3e5f",
        "edition": 16,
        "date": 1666813040082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "16",
        "rarity_score": 186.6065517342249,
        "rarity_level": "RARE"
    },
    "997": {
        "name": "Kadena Skellies #997",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/997.png",
        "dna": "9ff324b576cc46507b7d302c44c9480e6afdf555",
        "edition": 997,
        "date": 1666813040153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "997",
        "rarity_score": 117.95468010126211,
        "rarity_level": "COMMON"
    },
    "331": {
        "name": "Kadena Skellies #331",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/331.png",
        "dna": "b9fab01cee0579c134da5cc5437016c6489f57a4",
        "edition": 331,
        "date": 1666813040225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "331",
        "rarity_score": 158.57992407385962,
        "rarity_level": "COMMON"
    },
    "318": {
        "name": "Kadena Skellies #318",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/318.png",
        "dna": "283de7543480f0f17f6a618d488eed5831d698c1",
        "edition": 318,
        "date": 1666813040298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "318",
        "rarity_score": 159.92302534801743,
        "rarity_level": "COMMON"
    },
    "617": {
        "name": "Kadena Skellies #617",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/617.png",
        "dna": "c497b57d2bfd6c8b9d81161e6ed141d32a6af94f",
        "edition": 617,
        "date": 1666813040381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "617",
        "rarity_score": 112.54558658518232,
        "rarity_level": "COMMON"
    },
    "766": {
        "name": "Kadena Skellies #766",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/766.png",
        "dna": "c43e095d87bfabc33f880292c42e564cd892e41e",
        "edition": 766,
        "date": 1666813040453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "766",
        "rarity_score": 147.10354969313633,
        "rarity_level": "COMMON"
    },
    "907": {
        "name": "Kadena Skellies #907",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/907.png",
        "dna": "58c9bc5be5ed9508775ef8b49358dbf29172a125",
        "edition": 907,
        "date": 1666813040530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "907",
        "rarity_score": 126.88344548457738,
        "rarity_level": "COMMON"
    },
    "279": {
        "name": "Kadena Skellies #279",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/279.png",
        "dna": "3e13d495e875e773e471a1963dcd87912edfdc8d",
        "edition": 279,
        "date": 1666813040602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "279",
        "rarity_score": 124.43135656380585,
        "rarity_level": "COMMON"
    },
    "541": {
        "name": "Kadena Skellies #541",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/541.png",
        "dna": "db3153cbc36c06525b8c1392b74e543fce5be8a0",
        "edition": 541,
        "date": 1666813040673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "541",
        "rarity_score": 124.49394998056232,
        "rarity_level": "COMMON"
    },
    "716": {
        "name": "Kadena Skellies #716",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/716.png",
        "dna": "d9f9a7906920e746b8178a6ca0b756e825b770e2",
        "edition": 716,
        "date": 1666813040745,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "716",
        "rarity_score": 169.6060219908904,
        "rarity_level": "RARE"
    },
    "424": {
        "name": "Kadena Skellies #424",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/424.png",
        "dna": "c965af90e7626e377174d1af9a05812e1102a5ff",
        "edition": 424,
        "date": 1666813040818,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "424",
        "rarity_score": 113.20416393506218,
        "rarity_level": "COMMON"
    },
    "933": {
        "name": "Kadena Skellies #933",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/933.png",
        "dna": "d371d6917093cb618b6d4ba3213f34b8d8bea774",
        "edition": 933,
        "date": 1666813040890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "933",
        "rarity_score": 172.1824766950413,
        "rarity_level": "RARE"
    },
    "200": {
        "name": "Kadena Skellies #200",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/200.png",
        "dna": "c1a8757a6d7defcbe5b3bb20ee4c8fcfe9c95165",
        "edition": 200,
        "date": 1666813040967,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "200",
        "rarity_score": 122.65353465981084,
        "rarity_level": "COMMON"
    },
    "137": {
        "name": "Kadena Skellies #137",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/137.png",
        "dna": "efbe6dc6f6ebd9e4824f980c0a225f9e3d722e5b",
        "edition": 137,
        "date": 1666813041041,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "137",
        "rarity_score": 141.65918641269676,
        "rarity_level": "COMMON"
    },
    "348": {
        "name": "Kadena Skellies #348",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/348.png",
        "dna": "6efb8b343614ae3df6041742144ad9af3054cab7",
        "edition": 348,
        "date": 1666813041115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "348",
        "rarity_score": 135.6446944682239,
        "rarity_level": "COMMON"
    },
    "491": {
        "name": "Kadena Skellies #491",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/491.png",
        "dna": "f54131467cdf734a170a82d854622f102c98547d",
        "edition": 491,
        "date": 1666813041192,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "491",
        "rarity_score": 131.5393078368528,
        "rarity_level": "COMMON"
    },
    "443": {
        "name": "Kadena Skellies #443",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/443.png",
        "dna": "9cecccfe5ede0cdbbe6dfe89bbccf6f2753af30c",
        "edition": 443,
        "date": 1666813041265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "443",
        "rarity_score": 111.71530534421663,
        "rarity_level": "COMMON"
    },
    "1102": {
        "name": "Kadena Skellies #1102",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1102.png",
        "dna": "4a88b0d71836ae3d5b2ba12befd675afe514fd95",
        "edition": 1102,
        "date": 1666813041341,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1102",
        "rarity_score": 163.1553500535177,
        "rarity_level": "COMMON"
    },
    "1205": {
        "name": "Kadena Skellies #1205",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1205.png",
        "dna": "63021ea799b0bb13e0ccdf8a6b108bb4622583fc",
        "edition": 1205,
        "date": 1666813041413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1205",
        "rarity_score": 129.03868013831863,
        "rarity_level": "COMMON"
    },
    "126": {
        "name": "Kadena Skellies #126",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/126.png",
        "dna": "1a301cc7efd14f7cabf61e4a30aa036af5437a45",
        "edition": 126,
        "date": 1666813041484,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "126",
        "rarity_score": 139.69249152445846,
        "rarity_level": "COMMON"
    },
    "898": {
        "name": "Kadena Skellies #898",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/898.png",
        "dna": "d96bc1bfd60ecbabc71f4a46e1dc932ce77524e1",
        "edition": 898,
        "date": 1666813041561,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "898",
        "rarity_score": 213.6171174186638,
        "rarity_level": "RARE"
    },
    "1175": {
        "name": "Kadena Skellies #1175",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1175.png",
        "dna": "0dfbb587dcbe88b7e1acbfe74977081b47171f0b",
        "edition": 1175,
        "date": 1666813041632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1175",
        "rarity_score": 129.96304663846547,
        "rarity_level": "COMMON"
    },
    "544": {
        "name": "Kadena Skellies #544",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/544.png",
        "dna": "a91e5af45f2f9eeb5b68e4639445bc9ff527e43c",
        "edition": 544,
        "date": 1666813041703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "544",
        "rarity_score": 125.09717102939031,
        "rarity_level": "COMMON"
    },
    "1002": {
        "name": "Kadena Skellies #1002",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1002.png",
        "dna": "85450456ce3dbf148a858838fbe62230b18be078",
        "edition": 1002,
        "date": 1666813041776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1002",
        "rarity_score": 193.4764370430808,
        "rarity_level": "RARE"
    },
    "253": {
        "name": "Kadena Skellies #253",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/253.png",
        "dna": "875342cb53bbc172687ff4e35e818725c457e10f",
        "edition": 253,
        "date": 1666813041847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "253",
        "rarity_score": 117.07495560041409,
        "rarity_level": "COMMON"
    },
    "364": {
        "name": "Kadena Skellies #364",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/364.png",
        "dna": "a5d5d515213e7145f091ce88c14c44a506230020",
        "edition": 364,
        "date": 1666813041919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "364",
        "rarity_score": 124.79207150504489,
        "rarity_level": "COMMON"
    },
    "442": {
        "name": "Kadena Skellies #442",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/442.png",
        "dna": "f96315f04d5e22024ee0672eccf84b40b9fe2ef6",
        "edition": 442,
        "date": 1666813041990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "442",
        "rarity_score": 116.56668620145672,
        "rarity_level": "COMMON"
    },
    "1146": {
        "name": "Kadena Skellies #1146",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1146.png",
        "dna": "229b72220dc0f11240ded6d0b70db1b13b3c1c0e",
        "edition": 1146,
        "date": 1666813042069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1146",
        "rarity_score": 121.6745890706547,
        "rarity_level": "COMMON"
    },
    "1245": {
        "name": "Kadena Skellies #1245",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1245.png",
        "dna": "11e3d3f7c80b247099feff7a423b3fe7aa4aa4bf",
        "edition": 1245,
        "date": 1666813042145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1245",
        "rarity_score": 134.13743653415858,
        "rarity_level": "COMMON"
    },
    "916": {
        "name": "Kadena Skellies #916",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/916.png",
        "dna": "a71d34384e89537cc337b7c7366e1b4d9d36fd8a",
        "edition": 916,
        "date": 1666813042218,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "916",
        "rarity_score": 195.8142529126129,
        "rarity_level": "RARE"
    },
    "881": {
        "name": "Kadena Skellies #881",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/881.png",
        "dna": "79fa546193e2b2920ab62308a03357e8776049c6",
        "edition": 881,
        "date": 1666813042298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "881",
        "rarity_score": 163.69232008471974,
        "rarity_level": "COMMON"
    },
    "574": {
        "name": "Kadena Skellies #574",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/574.png",
        "dna": "75cecfab4066de17f966531cb99532bdb12c29e0",
        "edition": 574,
        "date": 1666813042378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "574",
        "rarity_score": 102.80225430735078,
        "rarity_level": "COMMON"
    },
    "1052": {
        "name": "Kadena Skellies #1052",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1052.png",
        "dna": "f2d0c18c9ef62394f37fe6507e3ff1e833f34ba2",
        "edition": 1052,
        "date": 1666813042452,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1052",
        "rarity_score": 166.17731412560636,
        "rarity_level": "COMMON"
    },
    "1158": {
        "name": "Kadena Skellies #1158",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1158.png",
        "dna": "f76a5e6d177503c44e996a725692f25200e70db8",
        "edition": 1158,
        "date": 1666813042524,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1158",
        "rarity_score": 144.9812131441586,
        "rarity_level": "COMMON"
    },
    "906": {
        "name": "Kadena Skellies #906",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/906.png",
        "dna": "f5acd5c35362f1376c079e11bd47111dc0f73b8a",
        "edition": 906,
        "date": 1666813042597,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "906",
        "rarity_score": 150.305684981003,
        "rarity_level": "COMMON"
    },
    "845": {
        "name": "Kadena Skellies #845",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/845.png",
        "dna": "44d07290e67b0eb3a15eb5ea5799d7468e2e85fc",
        "edition": 845,
        "date": 1666813042670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "845",
        "rarity_score": 269.8020321651178,
        "rarity_level": "RARE"
    },
    "1121": {
        "name": "Kadena Skellies #1121",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1121.png",
        "dna": "0db6bb5bbffc050850873cd4c9195920599a35ab",
        "edition": 1121,
        "date": 1666813042743,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1121",
        "rarity_score": 129.58644085391364,
        "rarity_level": "COMMON"
    },
    "323": {
        "name": "Kadena Skellies #323",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/323.png",
        "dna": "af4e2c1948bd3d7f2d3290918d266fd3d6629532",
        "edition": 323,
        "date": 1666813042814,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "323",
        "rarity_score": 98.94503106483353,
        "rarity_level": "COMMON"
    },
    "683": {
        "name": "Kadena Skellies #683",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/683.png",
        "dna": "11fedad5b3f7278920c169decb118dc76bf05960",
        "edition": 683,
        "date": 1666813042886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "683",
        "rarity_score": 139.0967644121314,
        "rarity_level": "COMMON"
    },
    "765": {
        "name": "Kadena Skellies #765",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/765.png",
        "dna": "02e4b2e42de3227d8a90fe9ec9897542d1db482a",
        "edition": 765,
        "date": 1666813042961,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "765",
        "rarity_score": 125.28682657359128,
        "rarity_level": "COMMON"
    },
    "631": {
        "name": "Kadena Skellies #631",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/631.png",
        "dna": "f2b7597296c73debd50f35f9a44f1ac8e66fdff6",
        "edition": 631,
        "date": 1666813043039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "631",
        "rarity_score": 124.06210612042183,
        "rarity_level": "COMMON"
    },
    "138": {
        "name": "Kadena Skellies #138",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/138.png",
        "dna": "b91433976ce560628ace67003ea71fdd55e91c39",
        "edition": 138,
        "date": 1666813043111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "138",
        "rarity_score": 153.36189297984703,
        "rarity_level": "COMMON"
    },
    "480": {
        "name": "Kadena Skellies #480",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/480.png",
        "dna": "c900a256694c5570d71c8351f32588fbc2d01a8d",
        "edition": 480,
        "date": 1666813043184,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "480",
        "rarity_score": 200.03925495722632,
        "rarity_level": "RARE"
    },
    "699": {
        "name": "Kadena Skellies #699",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/699.png",
        "dna": "1f9590f1d78abd5caa9641fd09c49c61e9730bb6",
        "edition": 699,
        "date": 1666813043256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "699",
        "rarity_score": 167.20965362630744,
        "rarity_level": "COMMON"
    },
    "1120": {
        "name": "Kadena Skellies #1120",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1120.png",
        "dna": "a71d197f4ee1fd611f53c20da224a1ad095f7a77",
        "edition": 1120,
        "date": 1666813043331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1120",
        "rarity_score": 156.94585382085384,
        "rarity_level": "COMMON"
    },
    "635": {
        "name": "Kadena Skellies #635",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/635.png",
        "dna": "815b07f31132d12d17a5a78084b4991128b4a9d0",
        "edition": 635,
        "date": 1666813043408,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "635",
        "rarity_score": 156.0832555634754,
        "rarity_level": "COMMON"
    },
    "1028": {
        "name": "Kadena Skellies #1028",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1028.png",
        "dna": "15a396d72c8081b6fee9f7c31473ab37524ebc6a",
        "edition": 1028,
        "date": 1666813043482,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1028",
        "rarity_score": 83.86342285981753,
        "rarity_level": "COMMON"
    },
    "64": {
        "name": "Kadena Skellies #64",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/64.png",
        "dna": "9cb1f682add06ecbd8b3cc774d5c15cae1d1e9f9",
        "edition": 64,
        "date": 1666813043560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "64",
        "rarity_score": 215.87256347828378,
        "rarity_level": "RARE"
    },
    "75": {
        "name": "Kadena Skellies #75",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/75.png",
        "dna": "5ae86d18393642065dc953edb36ea8c78c707950",
        "edition": 75,
        "date": 1666813043634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "75",
        "rarity_score": 201.48335750250934,
        "rarity_level": "RARE"
    },
    "343": {
        "name": "Kadena Skellies #343",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/343.png",
        "dna": "62e44848d47427eca8fb7fe677aeffcd3d6135d4",
        "edition": 343,
        "date": 1666813043707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "343",
        "rarity_score": 172.06519011598795,
        "rarity_level": "RARE"
    },
    "875": {
        "name": "Kadena Skellies #875",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/875.png",
        "dna": "369f907a2e85070b3cb6eadd9237c76df163cd4c",
        "edition": 875,
        "date": 1666813043780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "875",
        "rarity_score": 136.75591048839078,
        "rarity_level": "COMMON"
    },
    "731": {
        "name": "Kadena Skellies #731",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/731.png",
        "dna": "a07137670725777f80c55544be0c515d25af70a4",
        "edition": 731,
        "date": 1666813043850,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "731",
        "rarity_score": 114.03129969306441,
        "rarity_level": "COMMON"
    },
    "306": {
        "name": "Kadena Skellies #306",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/306.png",
        "dna": "3942dd515f987382de91e6e538a222f13662fa2a",
        "edition": 306,
        "date": 1666813043925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "306",
        "rarity_score": 116.45697297939167,
        "rarity_level": "COMMON"
    },
    "1174": {
        "name": "Kadena Skellies #1174",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1174.png",
        "dna": "9cdabceac98b74ff87f776bd1b3164d979a093ad",
        "edition": 1174,
        "date": 1666813043998,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1174",
        "rarity_score": 184.24365978790715,
        "rarity_level": "RARE"
    },
    "249": {
        "name": "Kadena Skellies #249",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/249.png",
        "dna": "743f2bd5f96c54a928d95fb1ccf8f57b4dd1f27c",
        "edition": 249,
        "date": 1666813044069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "249",
        "rarity_score": 201.041358281798,
        "rarity_level": "RARE"
    },
    "315": {
        "name": "Kadena Skellies #315",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/315.png",
        "dna": "5238de09538b3f7ab849b873570b9023e99260b4",
        "edition": 315,
        "date": 1666813044144,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "315",
        "rarity_score": 205.18057769284596,
        "rarity_level": "RARE"
    },
    "7": {
        "name": "Kadena Skellies #7",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/7.png",
        "dna": "885363bb265dfe2e21c7777738a930590eb6bf5b",
        "edition": 7,
        "date": 1666813044214,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "7",
        "rarity_score": 99.20676162079694,
        "rarity_level": "COMMON"
    },
    "991": {
        "name": "Kadena Skellies #991",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/991.png",
        "dna": "c42747922a5f17f6c2a5fd42a4a4e540586613ed",
        "edition": 991,
        "date": 1666813044285,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "991",
        "rarity_score": 131.29126739659284,
        "rarity_level": "COMMON"
    },
    "1043": {
        "name": "Kadena Skellies #1043",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1043.png",
        "dna": "f78818ac992138dfb1f8688afb726b2f97caee1d",
        "edition": 1043,
        "date": 1666813044361,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1043",
        "rarity_score": 128.00337441198272,
        "rarity_level": "COMMON"
    },
    "226": {
        "name": "Kadena Skellies #226",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/226.png",
        "dna": "2d4d3be69afeb77cf0be2d5f91637a5dcbfe8846",
        "edition": 226,
        "date": 1666813044438,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "226",
        "rarity_score": 131.7214671960236,
        "rarity_level": "COMMON"
    },
    "738": {
        "name": "Kadena Skellies #738",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/738.png",
        "dna": "5e47b23685cfe3860105d1b57e6c96170208cb25",
        "edition": 738,
        "date": 1666813044520,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "738",
        "rarity_score": 174.89036245715275,
        "rarity_level": "RARE"
    },
    "278": {
        "name": "Kadena Skellies #278",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/278.png",
        "dna": "649417eb7c7f1aa3325764a9066ca3b1368fc7a0",
        "edition": 278,
        "date": 1666813044592,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "278",
        "rarity_score": 122.86152139151433,
        "rarity_level": "COMMON"
    },
    "409": {
        "name": "Kadena Skellies #409",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/409.png",
        "dna": "cb6922b5343c385d7c6ff2732166459894ff857e",
        "edition": 409,
        "date": 1666813044661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "409",
        "rarity_score": 139.98458856353383,
        "rarity_level": "COMMON"
    },
    "825": {
        "name": "Kadena Skellies #825",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/825.png",
        "dna": "fa846779eef2c933438f348fdc3323ca45753985",
        "edition": 825,
        "date": 1666813044735,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "825",
        "rarity_score": 234.22649359969378,
        "rarity_level": "RARE"
    },
    "237": {
        "name": "Kadena Skellies #237",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/237.png",
        "dna": "e241ebce756d57682c41898eeba14c9242e311ba",
        "edition": 237,
        "date": 1666813044807,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "237",
        "rarity_score": 222.47909192594665,
        "rarity_level": "RARE"
    },
    "242": {
        "name": "Kadena Skellies #242",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/242.png",
        "dna": "0cad125cf8f5b7ec720298b204353100872f0b2d",
        "edition": 242,
        "date": 1666813044880,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "242",
        "rarity_score": 170.62434175601652,
        "rarity_level": "RARE"
    },
    "619": {
        "name": "Kadena Skellies #619",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/619.png",
        "dna": "091ed3b8abaf534a9257a55fb892fc4a5e023da4",
        "edition": 619,
        "date": 1666813044960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "619",
        "rarity_score": 114.00423647078428,
        "rarity_level": "COMMON"
    },
    "51": {
        "name": "Kadena Skellies #51",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/51.png",
        "dna": "29772ea031afdbd8ec9de905d336598a4647978c",
        "edition": 51,
        "date": 1666813045032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "51",
        "rarity_score": 228.59161682691095,
        "rarity_level": "RARE"
    },
    "1128": {
        "name": "Kadena Skellies #1128",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1128.png",
        "dna": "20c90893a44da65187064d6ccd04acb302388a23",
        "edition": 1128,
        "date": 1666813045110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1128",
        "rarity_score": 107.52966791468238,
        "rarity_level": "COMMON"
    },
    "807": {
        "name": "Kadena Skellies #807",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/807.png",
        "dna": "19b2b870a8da4e36d5522eefed01fe876a1f9d12",
        "edition": 807,
        "date": 1666813045184,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "807",
        "rarity_score": 168.52838657469135,
        "rarity_level": "COMMON"
    },
    "756": {
        "name": "Kadena Skellies #756",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/756.png",
        "dna": "52900cb9f561cc9170662f6383084a54c9702eec",
        "edition": 756,
        "date": 1666813045256,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "756",
        "rarity_score": 115.31017542230167,
        "rarity_level": "COMMON"
    },
    "607": {
        "name": "Kadena Skellies #607",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/607.png",
        "dna": "b8f5f35c77eddf7c651a4c8362a50a821b06fa50",
        "edition": 607,
        "date": 1666813045331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "607",
        "rarity_score": 128.61459806133414,
        "rarity_level": "COMMON"
    },
    "701": {
        "name": "Kadena Skellies #701",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/701.png",
        "dna": "616d0c2c4c518a5b9286d73bab770b4b69d9d6b7",
        "edition": 701,
        "date": 1666813045402,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "701",
        "rarity_score": 174.15468876811855,
        "rarity_level": "RARE"
    },
    "852": {
        "name": "Kadena Skellies #852",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/852.png",
        "dna": "b85869346621c3158913f7638b1d931df4f82503",
        "edition": 852,
        "date": 1666813045480,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "852",
        "rarity_score": 113.41197438747051,
        "rarity_level": "COMMON"
    },
    "1221": {
        "name": "Kadena Skellies #1221",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1221.png",
        "dna": "170a9f0ae8852bbdb2c093788ea392d348ac49c3",
        "edition": 1221,
        "date": 1666813045552,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1221",
        "rarity_score": 108.06827470328916,
        "rarity_level": "COMMON"
    },
    "144": {
        "name": "Kadena Skellies #144",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/144.png",
        "dna": "0cb47e65387e6ade1550da284cb394402f606b8d",
        "edition": 144,
        "date": 1666813045631,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "144",
        "rarity_score": 126.10492869955601,
        "rarity_level": "COMMON"
    },
    "63": {
        "name": "Kadena Skellies #63",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/63.png",
        "dna": "1cae1fba185640b77b5f3076aadfaed3c7dfa603",
        "edition": 63,
        "date": 1666813045710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "63",
        "rarity_score": 131.93303010522308,
        "rarity_level": "COMMON"
    },
    "381": {
        "name": "Kadena Skellies #381",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/381.png",
        "dna": "8e3b560e6d021da648e38089672d6b8575202c3a",
        "edition": 381,
        "date": 1666813045784,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "381",
        "rarity_score": 104.3555885779097,
        "rarity_level": "COMMON"
    },
    "152": {
        "name": "Kadena Skellies #152",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/152.png",
        "dna": "0d68ae1dfe2b8b8c883245f4ad7b991467e537ea",
        "edition": 152,
        "date": 1666813045856,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "152",
        "rarity_score": 131.82920172759316,
        "rarity_level": "COMMON"
    },
    "363": {
        "name": "Kadena Skellies #363",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/363.png",
        "dna": "0d55c4f6a6fa9d3692bc89a390b9e60e8060509c",
        "edition": 363,
        "date": 1666813045938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "363",
        "rarity_score": 131.86673437248052,
        "rarity_level": "COMMON"
    },
    "809": {
        "name": "Kadena Skellies #809",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/809.png",
        "dna": "2e57916afe6ed35def56c1fadb90d3915d49c6b7",
        "edition": 809,
        "date": 1666813046010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "809",
        "rarity_score": 153.02900149062998,
        "rarity_level": "COMMON"
    },
    "1179": {
        "name": "Kadena Skellies #1179",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1179.png",
        "dna": "da289312df56a36468a63f7363cf7a0e2d870d0e",
        "edition": 1179,
        "date": 1666813046085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1179",
        "rarity_score": 160.04302384824538,
        "rarity_level": "COMMON"
    },
    "251": {
        "name": "Kadena Skellies #251",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/251.png",
        "dna": "031eb08d20b0e33c3a1ac4d0516672145ae4acff",
        "edition": 251,
        "date": 1666813046157,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "251",
        "rarity_score": 113.29974544735182,
        "rarity_level": "COMMON"
    },
    "132": {
        "name": "Kadena Skellies #132",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/132.png",
        "dna": "802976cb3e2934f022a873e855c72f0bb9bb29bd",
        "edition": 132,
        "date": 1666813046233,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "132",
        "rarity_score": 123.09416241445086,
        "rarity_level": "COMMON"
    },
    "168": {
        "name": "Kadena Skellies #168",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/168.png",
        "dna": "2dd2e641a221bd42eff42f9c0e60704d4c5341ab",
        "edition": 168,
        "date": 1666813046307,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "168",
        "rarity_score": 152.51757522995305,
        "rarity_level": "COMMON"
    },
    "585": {
        "name": "Kadena Skellies #585",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/585.png",
        "dna": "5683af9fb44513eb4da6c7812958608b9459f46c",
        "edition": 585,
        "date": 1666813046381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "585",
        "rarity_score": 156.82530601900186,
        "rarity_level": "COMMON"
    },
    "1106": {
        "name": "Kadena Skellies #1106",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1106.png",
        "dna": "38ba1fdfaa806710df4ed13a2414b1c0b52b839d",
        "edition": 1106,
        "date": 1666813046457,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1106",
        "rarity_score": 129.6705844382817,
        "rarity_level": "COMMON"
    },
    "32": {
        "name": "Kadena Skellies #32",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/32.png",
        "dna": "0953d91afdb0c29c6b6afee96146fda5e383012d",
        "edition": 32,
        "date": 1666813046531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "32",
        "rarity_score": 96.95518561761561,
        "rarity_level": "COMMON"
    },
    "900": {
        "name": "Kadena Skellies #900",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/900.png",
        "dna": "d55889933634516100c40bbae3c3ab0c09aadc7e",
        "edition": 900,
        "date": 1666813046602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "900",
        "rarity_score": 277.0882168945835,
        "rarity_level": "RARE"
    },
    "1238": {
        "name": "Kadena Skellies #1238",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1238.png",
        "dna": "14b69a3414c3e86962352c2c6a27eb33724a7910",
        "edition": 1238,
        "date": 1666813046676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1238",
        "rarity_score": 124.81381075562659,
        "rarity_level": "COMMON"
    },
    "781": {
        "name": "Kadena Skellies #781",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/781.png",
        "dna": "33a2687850c936a09f47b5c867c63d74a66f99ce",
        "edition": 781,
        "date": 1666813046749,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "781",
        "rarity_score": 147.42027029211107,
        "rarity_level": "COMMON"
    },
    "238": {
        "name": "Kadena Skellies #238",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/238.png",
        "dna": "79a763cfff20447956c8501199d64c89b8228192",
        "edition": 238,
        "date": 1666813046822,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "238",
        "rarity_score": 131.4055810352269,
        "rarity_level": "COMMON"
    },
    "956": {
        "name": "Kadena Skellies #956",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/956.png",
        "dna": "8513ef61298857482bf53d571dffc5dd22d2d188",
        "edition": 956,
        "date": 1666813046900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "956",
        "rarity_score": 189.2641796383881,
        "rarity_level": "RARE"
    },
    "623": {
        "name": "Kadena Skellies #623",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/623.png",
        "dna": "802207bef09b9de31f3e008476e916cf5873b515",
        "edition": 623,
        "date": 1666813046972,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "623",
        "rarity_score": 118.24879190905482,
        "rarity_level": "COMMON"
    },
    "43": {
        "name": "Kadena Skellies #43",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/43.png",
        "dna": "81ca59f810ac2dc53fa037cd75aa0311a4ab29ed",
        "edition": 43,
        "date": 1666813047046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "43",
        "rarity_score": 134.93019656285824,
        "rarity_level": "COMMON"
    },
    "111": {
        "name": "Kadena Skellies #111",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/111.png",
        "dna": "1f6a5bffcf2894980a902e323ae899f6abf6b877",
        "edition": 111,
        "date": 1666813047118,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "111",
        "rarity_score": 118.3032994970175,
        "rarity_level": "COMMON"
    },
    "219": {
        "name": "Kadena Skellies #219",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/219.png",
        "dna": "de29e985b72b3482a3bb5d6dffbba6f663717fbb",
        "edition": 219,
        "date": 1666813047195,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Space Helmet",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "219",
        "rarity_score": 284.5112387882647,
        "rarity_level": "RARE"
    },
    "29": {
        "name": "Kadena Skellies #29",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/29.png",
        "dna": "eff4c67f43e60e1c954f21367387277cf1706128",
        "edition": 29,
        "date": 1666813047282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "29",
        "rarity_score": 115.50507031547524,
        "rarity_level": "COMMON"
    },
    "295": {
        "name": "Kadena Skellies #295",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/295.png",
        "dna": "52f754f6055b53e6b3b53c7b48a9e77db81584c7",
        "edition": 295,
        "date": 1666813047360,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "295",
        "rarity_score": 112.10782440488317,
        "rarity_level": "COMMON"
    },
    "885": {
        "name": "Kadena Skellies #885",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/885.png",
        "dna": "d46b6a0812bdecb10d19eaa246c61abe23e8effb",
        "edition": 885,
        "date": 1666813047439,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "885",
        "rarity_score": 231.5387922005569,
        "rarity_level": "RARE"
    },
    "588": {
        "name": "Kadena Skellies #588",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/588.png",
        "dna": "8142d772ea579ef75846875445680658a5dc244b",
        "edition": 588,
        "date": 1666813047531,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "588",
        "rarity_score": 161.8775899495894,
        "rarity_level": "COMMON"
    },
    "38": {
        "name": "Kadena Skellies #38",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/38.png",
        "dna": "6a66926d89a86f27e6bf820c44a50d1cd762f5f0",
        "edition": 38,
        "date": 1666813047608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "38",
        "rarity_score": 132.59390176011362,
        "rarity_level": "COMMON"
    },
    "919": {
        "name": "Kadena Skellies #919",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/919.png",
        "dna": "82bca610777a1a611bdf8dd711f45978480a6835",
        "edition": 919,
        "date": 1666813047679,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "919",
        "rarity_score": 118.37435922655311,
        "rarity_level": "COMMON"
    },
    "207": {
        "name": "Kadena Skellies #207",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/207.png",
        "dna": "a53ec3e7dd4690c1b50f679c4c692a0001e1fe9d",
        "edition": 207,
        "date": 1666813047753,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "207",
        "rarity_score": 150.81431201414895,
        "rarity_level": "COMMON"
    },
    "1124": {
        "name": "Kadena Skellies #1124",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1124.png",
        "dna": "5960d36de87033e0cd01b5c354f950373347bc4e",
        "edition": 1124,
        "date": 1666813047824,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1124",
        "rarity_score": 124.88944103700742,
        "rarity_level": "COMMON"
    },
    "360": {
        "name": "Kadena Skellies #360",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/360.png",
        "dna": "3e0b147aee32681f3a490d32222c43b57c7e6da3",
        "edition": 360,
        "date": 1666813047895,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "360",
        "rarity_score": 133.33218155338326,
        "rarity_level": "COMMON"
    },
    "960": {
        "name": "Kadena Skellies #960",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/960.png",
        "dna": "61b28c082a32fd1292d807f2199a7b5cae4fe6e2",
        "edition": 960,
        "date": 1666813047974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "960",
        "rarity_score": 147.0579478720235,
        "rarity_level": "COMMON"
    },
    "1231": {
        "name": "Kadena Skellies #1231",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1231.png",
        "dna": "65c97c4dff272c16e75b48bbeaba64df6e9c29f7",
        "edition": 1231,
        "date": 1666813048046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1231",
        "rarity_score": 196.12535682017935,
        "rarity_level": "RARE"
    },
    "976": {
        "name": "Kadena Skellies #976",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/976.png",
        "dna": "c6d783fda2aa289204612262b070888e2017c4fd",
        "edition": 976,
        "date": 1666813048124,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "976",
        "rarity_score": 143.0748124863179,
        "rarity_level": "COMMON"
    },
    "579": {
        "name": "Kadena Skellies #579",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/579.png",
        "dna": "535065283c28966468e29e805e74a46ceee67cdd",
        "edition": 579,
        "date": 1666813048196,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "579",
        "rarity_score": 133.66453925080344,
        "rarity_level": "COMMON"
    },
    "180": {
        "name": "Kadena Skellies #180",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/180.png",
        "dna": "0cf910959e9b70a47873d6b1a74ca881d9541836",
        "edition": 180,
        "date": 1666813048269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "180",
        "rarity_score": 155.97962570214742,
        "rarity_level": "COMMON"
    },
    "504": {
        "name": "Kadena Skellies #504",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/504.png",
        "dna": "eecef910aa177b28d6d29fa049beca7d74627546",
        "edition": 504,
        "date": 1666813048344,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "504",
        "rarity_score": 132.21411984514407,
        "rarity_level": "COMMON"
    },
    "864": {
        "name": "Kadena Skellies #864",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/864.png",
        "dna": "74506c735235bf2d72ee531cbf14b215a71bcc77",
        "edition": 864,
        "date": 1666813048417,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "864",
        "rarity_score": 133.9599363273985,
        "rarity_level": "COMMON"
    },
    "1228": {
        "name": "Kadena Skellies #1228",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1228.png",
        "dna": "fcc5adf1091031e1014646d3f3cf360b6ad2a6a1",
        "edition": 1228,
        "date": 1666813048489,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1228",
        "rarity_score": 193.68109903644364,
        "rarity_level": "RARE"
    },
    "1190": {
        "name": "Kadena Skellies #1190",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1190.png",
        "dna": "b955f04eab4297c645acacb3f88efa271b23914e",
        "edition": 1190,
        "date": 1666813048564,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1190",
        "rarity_score": 138.86853740430556,
        "rarity_level": "COMMON"
    },
    "661": {
        "name": "Kadena Skellies #661",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/661.png",
        "dna": "ee9ae83254b936e91348e37fc5127344227e97a1",
        "edition": 661,
        "date": 1666813048644,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "661",
        "rarity_score": 137.514642660224,
        "rarity_level": "COMMON"
    },
    "909": {
        "name": "Kadena Skellies #909",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/909.png",
        "dna": "98cdeaba4956f5cf46c8c4c517fccc0430a67d6e",
        "edition": 909,
        "date": 1666813048716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "909",
        "rarity_score": 118.31056040756324,
        "rarity_level": "COMMON"
    },
    "356": {
        "name": "Kadena Skellies #356",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/356.png",
        "dna": "efc8e645f4b3e8707a3e4fcf9a71b4dbd1e74e2f",
        "edition": 356,
        "date": 1666813048796,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "356",
        "rarity_score": 187.01832597992436,
        "rarity_level": "RARE"
    },
    "665": {
        "name": "Kadena Skellies #665",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/665.png",
        "dna": "0952f1cba8f8b9502b3513d9bf74f7fb304c9aff",
        "edition": 665,
        "date": 1666813048871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Kadena Hat",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "665",
        "rarity_score": 187.62120497034292,
        "rarity_level": "RARE"
    },
    "1016": {
        "name": "Kadena Skellies #1016",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1016.png",
        "dna": "11d3a721e79d249182781c2b47f24c443532654e",
        "edition": 1016,
        "date": 1666813048950,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1016",
        "rarity_score": 192.14202292437884,
        "rarity_level": "RARE"
    },
    "1104": {
        "name": "Kadena Skellies #1104",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1104.png",
        "dna": "5a557955deb25ae46e8f155fa7e6fae5dd72a45c",
        "edition": 1104,
        "date": 1666813049024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1104",
        "rarity_score": 158.88815338046368,
        "rarity_level": "COMMON"
    },
    "979": {
        "name": "Kadena Skellies #979",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/979.png",
        "dna": "f93d238ff4ccbf3491821bcb16eb59864152eaf9",
        "edition": 979,
        "date": 1666813049098,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "979",
        "rarity_score": 113.6494652008102,
        "rarity_level": "COMMON"
    },
    "774": {
        "name": "Kadena Skellies #774",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/774.png",
        "dna": "803ae35cc45ebaf9760efca7e9a672ed536175f9",
        "edition": 774,
        "date": 1666813049172,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Round Kadena Glasses",
                "score": 250.0
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "774",
        "rarity_score": 365.1878885574538,
        "rarity_level": "EPIC"
    },
    "349": {
        "name": "Kadena Skellies #349",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/349.png",
        "dna": "2119628d938b6023c4bc6db03afca4b4eb340f59",
        "edition": 349,
        "date": 1666813049244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "349",
        "rarity_score": 308.1959358388925,
        "rarity_level": "RARE"
    },
    "68": {
        "name": "Kadena Skellies #68",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/68.png",
        "dna": "d0ae8abcf4b4bbb39fe82e5f28ca955d459ae2c1",
        "edition": 68,
        "date": 1666813049315,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "68",
        "rarity_score": 128.12361840494833,
        "rarity_level": "COMMON"
    },
    "1207": {
        "name": "Kadena Skellies #1207",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1207.png",
        "dna": "cd8a0667f4f3ff0171f987d0736f926fe0fe9483",
        "edition": 1207,
        "date": 1666813049396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1207",
        "rarity_score": 151.60436568727667,
        "rarity_level": "COMMON"
    },
    "40": {
        "name": "Kadena Skellies #40",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/40.png",
        "dna": "8e5121d60c7c051694f40e62b19f1909e7e94f7e",
        "edition": 40,
        "date": 1666813049470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "40",
        "rarity_score": 181.10436309667338,
        "rarity_level": "RARE"
    },
    "153": {
        "name": "Kadena Skellies #153",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/153.png",
        "dna": "19112a0e326251681fb38108699f825ac406a4c2",
        "edition": 153,
        "date": 1666813049554,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "153",
        "rarity_score": 125.28277012343895,
        "rarity_level": "COMMON"
    },
    "460": {
        "name": "Kadena Skellies #460",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/460.png",
        "dna": "a5af73178c70cfa7afe0f5b65238b3a944b8710a",
        "edition": 460,
        "date": 1666813049624,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "460",
        "rarity_score": 119.98365459168109,
        "rarity_level": "COMMON"
    },
    "1004": {
        "name": "Kadena Skellies #1004",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1004.png",
        "dna": "4323628f8927d4793b2e779c5832ce115abb99b8",
        "edition": 1004,
        "date": 1666813049698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1004",
        "rarity_score": 156.15281586683713,
        "rarity_level": "COMMON"
    },
    "281": {
        "name": "Kadena Skellies #281",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/281.png",
        "dna": "474ab4495f0e05cce7aa572fa88bf78e58f2810d",
        "edition": 281,
        "date": 1666813049779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "281",
        "rarity_score": 156.71905892081043,
        "rarity_level": "COMMON"
    },
    "58": {
        "name": "Kadena Skellies #58",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/58.png",
        "dna": "f86f135c6c9a808a6e7bc3debf42f7209c8f030c",
        "edition": 58,
        "date": 1666813049852,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "58",
        "rarity_score": 113.90159040726328,
        "rarity_level": "COMMON"
    },
    "206": {
        "name": "Kadena Skellies #206",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/206.png",
        "dna": "07d1088db2cd8ca4ee7b5a50b228ab3fadb10091",
        "edition": 206,
        "date": 1666813049925,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "206",
        "rarity_score": 129.26723255387049,
        "rarity_level": "COMMON"
    },
    "974": {
        "name": "Kadena Skellies #974",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/974.png",
        "dna": "c76d47931a1656c2447c28b847c890d9162af136",
        "edition": 974,
        "date": 1666813049997,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "974",
        "rarity_score": 111.69673494621225,
        "rarity_level": "COMMON"
    },
    "771": {
        "name": "Kadena Skellies #771",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/771.png",
        "dna": "5da456d2d442d7376f540fd93317f0edde63ec64",
        "edition": 771,
        "date": 1666813050074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "771",
        "rarity_score": 128.80029180491752,
        "rarity_level": "COMMON"
    },
    "563": {
        "name": "Kadena Skellies #563",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/563.png",
        "dna": "921a0fe75a6ff2d8e1ea055f27eda4f3add78a50",
        "edition": 563,
        "date": 1666813050153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "563",
        "rarity_score": 193.29603310977308,
        "rarity_level": "RARE"
    },
    "957": {
        "name": "Kadena Skellies #957",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/957.png",
        "dna": "391cabb775428e510505fc2cb19877026b996fe2",
        "edition": 957,
        "date": 1666813050223,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "957",
        "rarity_score": 128.38103130071346,
        "rarity_level": "COMMON"
    },
    "560": {
        "name": "Kadena Skellies #560",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/560.png",
        "dna": "6c45086ff39cb20cbca5cbae3635c8cb52ae347a",
        "edition": 560,
        "date": 1666813050299,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "560",
        "rarity_score": 135.72624234237577,
        "rarity_level": "COMMON"
    },
    "178": {
        "name": "Kadena Skellies #178",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/178.png",
        "dna": "84538a0a578095ffdbb037a61e034d83df6afe3d",
        "edition": 178,
        "date": 1666813050381,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "178",
        "rarity_score": 224.72875212153525,
        "rarity_level": "RARE"
    },
    "730": {
        "name": "Kadena Skellies #730",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/730.png",
        "dna": "7e0e9250f4b3c0538657957fb992e95d098fd59e",
        "edition": 730,
        "date": 1666813050461,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "730",
        "rarity_score": 137.85478755374442,
        "rarity_level": "COMMON"
    },
    "477": {
        "name": "Kadena Skellies #477",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/477.png",
        "dna": "3a8f35413a301c437ce6eb2cd29a1a1ddd92624b",
        "edition": 477,
        "date": 1666813050542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "477",
        "rarity_score": 186.89734372504765,
        "rarity_level": "RARE"
    },
    "1232": {
        "name": "Kadena Skellies #1232",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1232.png",
        "dna": "43b58788cb8d235827b89d061176969b6797db06",
        "edition": 1232,
        "date": 1666813050621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1232",
        "rarity_score": 136.76954999447202,
        "rarity_level": "COMMON"
    },
    "386": {
        "name": "Kadena Skellies #386",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/386.png",
        "dna": "66f03d289bb116ba0514020b5e6abb96dec800d8",
        "edition": 386,
        "date": 1666813050697,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "386",
        "rarity_score": 141.23193369408006,
        "rarity_level": "COMMON"
    },
    "590": {
        "name": "Kadena Skellies #590",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/590.png",
        "dna": "4c3dce36e46595061ed9dbb2b7bb86d516431400",
        "edition": 590,
        "date": 1666813050775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "590",
        "rarity_score": 151.92321414716218,
        "rarity_level": "COMMON"
    },
    "435": {
        "name": "Kadena Skellies #435",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/435.png",
        "dna": "3a9a21761765d88b00dc463c0cbb31ba89c37cf0",
        "edition": 435,
        "date": 1666813050845,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "435",
        "rarity_score": 114.5918437268582,
        "rarity_level": "COMMON"
    },
    "85": {
        "name": "Kadena Skellies #85",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/85.png",
        "dna": "4666bf1ab73cb5f6d644b1e8fb318cd2d306aa2e",
        "edition": 85,
        "date": 1666813050932,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Space Helmet",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "85",
        "rarity_score": 390.50623268570155,
        "rarity_level": "EPIC"
    },
    "1199": {
        "name": "Kadena Skellies #1199",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1199.png",
        "dna": "d6bbbc28c81f729fc51db586d4b6951e063af09a",
        "edition": 1199,
        "date": 1666813051013,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1199",
        "rarity_score": 100.43918042163736,
        "rarity_level": "COMMON"
    },
    "319": {
        "name": "Kadena Skellies #319",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/319.png",
        "dna": "f58b3fa3f98e61201195991f568cf016fb3f61ce",
        "edition": 319,
        "date": 1666813051124,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "319",
        "rarity_score": 140.77849574021425,
        "rarity_level": "COMMON"
    },
    "616": {
        "name": "Kadena Skellies #616",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/616.png",
        "dna": "4f691e2064622b5d9081a77e62da00cfefe85434",
        "edition": 616,
        "date": 1666813051215,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "616",
        "rarity_score": 130.94037203469676,
        "rarity_level": "COMMON"
    },
    "857": {
        "name": "Kadena Skellies #857",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/857.png",
        "dna": "e61911a71ea5f658b56b308ccf4a246dcfeeaed8",
        "edition": 857,
        "date": 1666813051293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "857",
        "rarity_score": 145.55009936200202,
        "rarity_level": "COMMON"
    },
    "812": {
        "name": "Kadena Skellies #812",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/812.png",
        "dna": "25051dcfc0e4a23ab2d74ffce4602518452ece65",
        "edition": 812,
        "date": 1666813051378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "812",
        "rarity_score": 130.80865185763625,
        "rarity_level": "COMMON"
    },
    "578": {
        "name": "Kadena Skellies #578",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/578.png",
        "dna": "8d44635b43d1e20a848b3ac5b781268380adc5fd",
        "edition": 578,
        "date": 1666813051450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "578",
        "rarity_score": 102.67218535464073,
        "rarity_level": "COMMON"
    },
    "1045": {
        "name": "Kadena Skellies #1045",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1045.png",
        "dna": "0a57bd2f625d25e97e807e1f9cb5d0036357fdd0",
        "edition": 1045,
        "date": 1666813051530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1045",
        "rarity_score": 159.33611938766578,
        "rarity_level": "COMMON"
    },
    "98": {
        "name": "Kadena Skellies #98",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/98.png",
        "dna": "c85c5466d9df61e8486fae35f2c4d9108cc6b9d1",
        "edition": 98,
        "date": 1666813051607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "98",
        "rarity_score": 129.14712690457617,
        "rarity_level": "COMMON"
    },
    "270": {
        "name": "Kadena Skellies #270",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/270.png",
        "dna": "fba10b330959fb1e7c552068a6304f3eda6a2efb",
        "edition": 270,
        "date": 1666813051683,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "270",
        "rarity_score": 142.4392992270106,
        "rarity_level": "COMMON"
    },
    "500": {
        "name": "Kadena Skellies #500",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/500.png",
        "dna": "05a783a9aa8fabbf6d6618973e15a5a4f4953ffb",
        "edition": 500,
        "date": 1666813051753,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "500",
        "rarity_score": 129.62241890738983,
        "rarity_level": "COMMON"
    },
    "316": {
        "name": "Kadena Skellies #316",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/316.png",
        "dna": "cbb9cc6526020d35a3d704bbbc7f8e070ccfad16",
        "edition": 316,
        "date": 1666813051825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "316",
        "rarity_score": 112.7076082943268,
        "rarity_level": "COMMON"
    },
    "817": {
        "name": "Kadena Skellies #817",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/817.png",
        "dna": "d53540bc5bfec14b7f1e1db941b80ec420c7f0cc",
        "edition": 817,
        "date": 1666813051896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "817",
        "rarity_score": 132.3158945032215,
        "rarity_level": "COMMON"
    },
    "505": {
        "name": "Kadena Skellies #505",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/505.png",
        "dna": "4eacae4dfc3b3ba4f27923e6d255453c46c6f281",
        "edition": 505,
        "date": 1666813051973,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "505",
        "rarity_score": 99.50624741989813,
        "rarity_level": "COMMON"
    },
    "614": {
        "name": "Kadena Skellies #614",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/614.png",
        "dna": "de8e270c461bf3043dba96eb1cdc141a1c08e6d6",
        "edition": 614,
        "date": 1666813052055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "614",
        "rarity_score": 104.55409567133876,
        "rarity_level": "COMMON"
    },
    "1009": {
        "name": "Kadena Skellies #1009",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1009.png",
        "dna": "d02b3e73ebf144ee6af5377ecc5300299786e2ca",
        "edition": 1009,
        "date": 1666813052137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1009",
        "rarity_score": 138.54123426063788,
        "rarity_level": "COMMON"
    },
    "162": {
        "name": "Kadena Skellies #162",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/162.png",
        "dna": "bf16109f35b2b3b0a22e41aa894b95415c2a3d1d",
        "edition": 162,
        "date": 1666813052214,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "162",
        "rarity_score": 109.56511998672815,
        "rarity_level": "COMMON"
    },
    "476": {
        "name": "Kadena Skellies #476",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/476.png",
        "dna": "3150c18ff7d26e9aa1a9174daa3b0611c2d6eabe",
        "edition": 476,
        "date": 1666813052294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "476",
        "rarity_score": 149.5418915904552,
        "rarity_level": "COMMON"
    },
    "1047": {
        "name": "Kadena Skellies #1047",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1047.png",
        "dna": "2be52a3b233b32c3b671244e08c336f9aedee977",
        "edition": 1047,
        "date": 1666813052372,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1047",
        "rarity_score": 126.20188712552654,
        "rarity_level": "COMMON"
    },
    "862": {
        "name": "Kadena Skellies #862",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/862.png",
        "dna": "b366560f40e4dac1de33a8acb93a17482ce7b04c",
        "edition": 862,
        "date": 1666813052450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "862",
        "rarity_score": 124.23830357627692,
        "rarity_level": "COMMON"
    },
    "1193": {
        "name": "Kadena Skellies #1193",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1193.png",
        "dna": "1798bb0480ed89f61aef2ae2869f5145d2f26f1d",
        "edition": 1193,
        "date": 1666813052525,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1193",
        "rarity_score": 139.57545779969124,
        "rarity_level": "COMMON"
    },
    "785": {
        "name": "Kadena Skellies #785",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/785.png",
        "dna": "78dc4729f43555da18981f48a71aae4d03540fc1",
        "edition": 785,
        "date": 1666813052597,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "785",
        "rarity_score": 105.05438805636247,
        "rarity_level": "COMMON"
    },
    "66": {
        "name": "Kadena Skellies #66",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/66.png",
        "dna": "07190b44106f9785ec1c2f94809144fc4fd689c4",
        "edition": 66,
        "date": 1666813052675,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Laser Eyes",
                "score": 125.0
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "66",
        "rarity_score": 217.40413212037072,
        "rarity_level": "RARE"
    },
    "758": {
        "name": "Kadena Skellies #758",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/758.png",
        "dna": "82f6371128bf231831c21ab2896803343ecb1e60",
        "edition": 758,
        "date": 1666813052757,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "758",
        "rarity_score": 298.28923471164853,
        "rarity_level": "RARE"
    },
    "1070": {
        "name": "Kadena Skellies #1070",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1070.png",
        "dna": "ede86ef429637f094483f02150bd1333efc31c21",
        "edition": 1070,
        "date": 1666813052834,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1070",
        "rarity_score": 121.96477008321166,
        "rarity_level": "COMMON"
    },
    "261": {
        "name": "Kadena Skellies #261",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/261.png",
        "dna": "33870e3f0ef205045fdb564040070e537792c805",
        "edition": 261,
        "date": 1666813052914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "261",
        "rarity_score": 142.89254847765486,
        "rarity_level": "COMMON"
    },
    "109": {
        "name": "Kadena Skellies #109",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/109.png",
        "dna": "ecd21053772f733989be5e9f3dfbe79632b7b9a1",
        "edition": 109,
        "date": 1666813052985,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "109",
        "rarity_score": 149.4196649767302,
        "rarity_level": "COMMON"
    },
    "1243": {
        "name": "Kadena Skellies #1243",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1243.png",
        "dna": "4e7e9cd97ee345497c5f79353337c92366df5d08",
        "edition": 1243,
        "date": 1666813053057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1243",
        "rarity_score": 198.78817983166186,
        "rarity_level": "RARE"
    },
    "764": {
        "name": "Kadena Skellies #764",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/764.png",
        "dna": "6180ef266cc7e47e12a8f9548314560bbe4a1b49",
        "edition": 764,
        "date": 1666813053134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "764",
        "rarity_score": 112.52019366060259,
        "rarity_level": "COMMON"
    },
    "1100": {
        "name": "Kadena Skellies #1100",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1100.png",
        "dna": "b6df10cca8862e04d57b39e854ac55ebef22f0c2",
        "edition": 1100,
        "date": 1666813053207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1100",
        "rarity_score": 194.63408043183074,
        "rarity_level": "RARE"
    },
    "401": {
        "name": "Kadena Skellies #401",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/401.png",
        "dna": "30d97f24fdf126d32ff4ca61cd08f3c6351562b6",
        "edition": 401,
        "date": 1666813053282,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Red Hollow Eyes",
                "score": 48.07692307692308
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "401",
        "rarity_score": 183.3767737073846,
        "rarity_level": "RARE"
    },
    "212": {
        "name": "Kadena Skellies #212",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/212.png",
        "dna": "d173d72b10fbc2cda22c3673419e77ab430a4cdc",
        "edition": 212,
        "date": 1666813053363,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "212",
        "rarity_score": 235.20456090141562,
        "rarity_level": "RARE"
    },
    "924": {
        "name": "Kadena Skellies #924",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/924.png",
        "dna": "f7d18e5f7457f82aa1770eddf7707b89d97d768c",
        "edition": 924,
        "date": 1666813053435,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "924",
        "rarity_score": 133.558497374449,
        "rarity_level": "COMMON"
    },
    "917": {
        "name": "Kadena Skellies #917",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/917.png",
        "dna": "01210ab533bbcf653f300f3529b4bc5d877efafc",
        "edition": 917,
        "date": 1666813053511,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "917",
        "rarity_score": 132.16807370416024,
        "rarity_level": "COMMON"
    },
    "1114": {
        "name": "Kadena Skellies #1114",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1114.png",
        "dna": "3784f463d24c4b6d336c37aa52c3912723d1cb08",
        "edition": 1114,
        "date": 1666813053589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Fiery Eyes",
                "score": 416.6666666666667
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1114",
        "rarity_score": 535.7965655504836,
        "rarity_level": "LEGENDARY"
    },
    "410": {
        "name": "Kadena Skellies #410",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/410.png",
        "dna": "91179b23437e0a6f5f9bcfc1b6dad525148dd476",
        "edition": 410,
        "date": 1666813053664,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "410",
        "rarity_score": 120.527420061507,
        "rarity_level": "COMMON"
    },
    "780": {
        "name": "Kadena Skellies #780",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/780.png",
        "dna": "3ba201a9d42983447b7130711b011c55877b2564",
        "edition": 780,
        "date": 1666813053741,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "780",
        "rarity_score": 195.37960797051423,
        "rarity_level": "RARE"
    },
    "368": {
        "name": "Kadena Skellies #368",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/368.png",
        "dna": "9f23262ff04bfbf79849440b8d6d58bf8c757712",
        "edition": 368,
        "date": 1666813053818,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "368",
        "rarity_score": 123.15882188553839,
        "rarity_level": "COMMON"
    },
    "810": {
        "name": "Kadena Skellies #810",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/810.png",
        "dna": "ba561e3f93e5494a28eac8741928eb642a6f0e2d",
        "edition": 810,
        "date": 1666813053896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "810",
        "rarity_score": 140.29522477026902,
        "rarity_level": "COMMON"
    },
    "1048": {
        "name": "Kadena Skellies #1048",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1048.png",
        "dna": "efdec80da16230a4614c5745f826fd7a13dae159",
        "edition": 1048,
        "date": 1666813053974,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1048",
        "rarity_score": 155.58328503164626,
        "rarity_level": "COMMON"
    },
    "1096": {
        "name": "Kadena Skellies #1096",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1096.png",
        "dna": "29c7a0395952e8dfc0c3ce61049d492e7f3f505a",
        "edition": 1096,
        "date": 1666813054046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1096",
        "rarity_score": 270.07665891724554,
        "rarity_level": "RARE"
    },
    "999": {
        "name": "Kadena Skellies #999",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/999.png",
        "dna": "8a21431f9699a526155726c8c931beb4c4804534",
        "edition": 999,
        "date": 1666813054123,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "999",
        "rarity_score": 107.84447831429311,
        "rarity_level": "COMMON"
    },
    "988": {
        "name": "Kadena Skellies #988",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/988.png",
        "dna": "d220c1302c9bce9b38d6d8efe9db4a07ad4a2d97",
        "edition": 988,
        "date": 1666813054197,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "988",
        "rarity_score": 116.85562529136261,
        "rarity_level": "COMMON"
    },
    "1018": {
        "name": "Kadena Skellies #1018",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1018.png",
        "dna": "659e1f3856a612e2ae6c0362abaf9bb5c9470591",
        "edition": 1018,
        "date": 1666813054270,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1018",
        "rarity_score": 214.1813721833466,
        "rarity_level": "RARE"
    },
    "1182": {
        "name": "Kadena Skellies #1182",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1182.png",
        "dna": "874dd755dc5120871bba7ced0320a0843fe26abf",
        "edition": 1182,
        "date": 1666813054347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1182",
        "rarity_score": 136.284292187375,
        "rarity_level": "COMMON"
    },
    "131": {
        "name": "Kadena Skellies #131",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/131.png",
        "dna": "35a591c39ac586842de1d13b60febd0a0df3d239",
        "edition": 131,
        "date": 1666813054421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Flux Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "131",
        "rarity_score": 267.44646405386123,
        "rarity_level": "RARE"
    },
    "30": {
        "name": "Kadena Skellies #30",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/30.png",
        "dna": "4bbd22da3e34f688eaedc475b82a95365ead7e33",
        "edition": 30,
        "date": 1666813054499,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "30",
        "rarity_score": 97.19998847320662,
        "rarity_level": "COMMON"
    },
    "569": {
        "name": "Kadena Skellies #569",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/569.png",
        "dna": "6d6619e17484f6395f8f302cb8a69cf9de5b621a",
        "edition": 569,
        "date": 1666813054570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "569",
        "rarity_score": 105.9441275748681,
        "rarity_level": "COMMON"
    },
    "190": {
        "name": "Kadena Skellies #190",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/190.png",
        "dna": "6644e12819a0fd66162470e4e9b803cba1ff13fd",
        "edition": 190,
        "date": 1666813054642,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "190",
        "rarity_score": 137.76604477669386,
        "rarity_level": "COMMON"
    },
    "996": {
        "name": "Kadena Skellies #996",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/996.png",
        "dna": "cb11d6293ca826885bb1dad350e7183a99368a14",
        "edition": 996,
        "date": 1666813054716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            { "trait_type": "Eyes", "value": "Red Cross Eyes", "score": 250.0 },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "996",
        "rarity_score": 384.25798448419783,
        "rarity_level": "EPIC"
    },
    "926": {
        "name": "Kadena Skellies #926",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/926.png",
        "dna": "3ef862adf44c49192be196b9c27c6fec11855699",
        "edition": 926,
        "date": 1666813054789,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "926",
        "rarity_score": 130.55073761724867,
        "rarity_level": "COMMON"
    },
    "859": {
        "name": "Kadena Skellies #859",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/859.png",
        "dna": "cb8d5228f56a9baa741af6a8ef3a92341f32406f",
        "edition": 859,
        "date": 1666813054863,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Skull",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "859",
        "rarity_score": 305.13743516539887,
        "rarity_level": "RARE"
    },
    "636": {
        "name": "Kadena Skellies #636",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/636.png",
        "dna": "4c9e0f356baafd598ef50c3fc32a812cab95e1d1",
        "edition": 636,
        "date": 1666813054939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "636",
        "rarity_score": 140.31796887882533,
        "rarity_level": "COMMON"
    },
    "795": {
        "name": "Kadena Skellies #795",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/795.png",
        "dna": "eca7c57a2c3acf4d1e4fef64136da3de210763d0",
        "edition": 795,
        "date": 1666813055015,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "795",
        "rarity_score": 132.00377639330833,
        "rarity_level": "COMMON"
    },
    "1036": {
        "name": "Kadena Skellies #1036",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1036.png",
        "dna": "e207ee0816d63994e57cdd47462f5aef430d2dac",
        "edition": 1036,
        "date": 1666813055096,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1036",
        "rarity_score": 138.30260834120517,
        "rarity_level": "COMMON"
    },
    "283": {
        "name": "Kadena Skellies #283",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/283.png",
        "dna": "90ca11939543fe68742b00ffb8f4d6a6e23774a3",
        "edition": 283,
        "date": 1666813055169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "283",
        "rarity_score": 112.58738814843142,
        "rarity_level": "COMMON"
    },
    "297": {
        "name": "Kadena Skellies #297",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/297.png",
        "dna": "2a4e762ef9878cce40d098db04a989f0f0201aa3",
        "edition": 297,
        "date": 1666813055241,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "297",
        "rarity_score": 119.97414111767895,
        "rarity_level": "COMMON"
    },
    "552": {
        "name": "Kadena Skellies #552",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/552.png",
        "dna": "91e9c81211e7f4e18f657f3b965e8bf7088e1f92",
        "edition": 552,
        "date": 1666813055320,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "552",
        "rarity_score": 131.08778079627697,
        "rarity_level": "COMMON"
    },
    "317": {
        "name": "Kadena Skellies #317",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/317.png",
        "dna": "47b1dccd77d28b9da43b9c85c409beb511b03f9a",
        "edition": 317,
        "date": 1666813055397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "317",
        "rarity_score": 112.34215351189422,
        "rarity_level": "COMMON"
    },
    "892": {
        "name": "Kadena Skellies #892",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/892.png",
        "dna": "772191f15ba8bff62bfb5a4a492506e7c157697c",
        "edition": 892,
        "date": 1666813055472,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "KMC Tshirt",
                "score": 138.88888888888889
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "892",
        "rarity_score": 226.43030941350267,
        "rarity_level": "RARE"
    },
    "252": {
        "name": "Kadena Skellies #252",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/252.png",
        "dna": "abc568952358f7a62e8b18a52e75f7a6b717f80b",
        "edition": 252,
        "date": 1666813055547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "252",
        "rarity_score": 169.48553838888532,
        "rarity_level": "RARE"
    },
    "307": {
        "name": "Kadena Skellies #307",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/307.png",
        "dna": "8ece8845048fb61676ac30938c2cfa80e33be20b",
        "edition": 307,
        "date": 1666813055630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "307",
        "rarity_score": 177.4108056266918,
        "rarity_level": "RARE"
    },
    "581": {
        "name": "Kadena Skellies #581",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/581.png",
        "dna": "1aa19c2108c7640c8692bd8922d508a2f853ca36",
        "edition": 581,
        "date": 1666813055712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "581",
        "rarity_score": 175.06930512445217,
        "rarity_level": "RARE"
    },
    "639": {
        "name": "Kadena Skellies #639",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/639.png",
        "dna": "ed142cec7a369419bd149e256df0be47741633e3",
        "edition": 639,
        "date": 1666813055792,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "639",
        "rarity_score": 139.74804914116956,
        "rarity_level": "COMMON"
    },
    "176": {
        "name": "Kadena Skellies #176",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/176.png",
        "dna": "70599080c9688137a7807819b104a4e61eeed1cb",
        "edition": 176,
        "date": 1666813055864,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "176",
        "rarity_score": 125.88028116923971,
        "rarity_level": "COMMON"
    },
    "1127": {
        "name": "Kadena Skellies #1127",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1127.png",
        "dna": "49bc9380d39cb6889e85aa300c31932a6eee26d3",
        "edition": 1127,
        "date": 1666813055939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1127",
        "rarity_score": 116.17536391821223,
        "rarity_level": "COMMON"
    },
    "195": {
        "name": "Kadena Skellies #195",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/195.png",
        "dna": "9d6cd3754567f3020e48cce110400e2ec4ea3b85",
        "edition": 195,
        "date": 1666813056013,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "195",
        "rarity_score": 130.1283786726625,
        "rarity_level": "COMMON"
    },
    "1075": {
        "name": "Kadena Skellies #1075",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1075.png",
        "dna": "c3494cc570d1830e8bd903b419cca3b7200d2f98",
        "edition": 1075,
        "date": 1666813056088,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1075",
        "rarity_score": 125.31617060715936,
        "rarity_level": "COMMON"
    },
    "828": {
        "name": "Kadena Skellies #828",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/828.png",
        "dna": "14035e1cb967496f00eb05e476e8f9bf791a64e2",
        "edition": 828,
        "date": 1666813056164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "828",
        "rarity_score": 95.88366245694606,
        "rarity_level": "COMMON"
    },
    "598": {
        "name": "Kadena Skellies #598",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/598.png",
        "dna": "f6542b1e530e9d149639be208875716c3c770d9f",
        "edition": 598,
        "date": 1666813056244,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "598",
        "rarity_score": 119.80193578574905,
        "rarity_level": "COMMON"
    },
    "954": {
        "name": "Kadena Skellies #954",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/954.png",
        "dna": "a94a1b876aaf458a96f545ee151282f22d21d52d",
        "edition": 954,
        "date": 1666813056333,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Babena Tshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "954",
        "rarity_score": 209.75254198264435,
        "rarity_level": "RARE"
    },
    "431": {
        "name": "Kadena Skellies #431",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/431.png",
        "dna": "134c908f21a6d4480727d5bf4bf70efe8f3de4bb",
        "edition": 431,
        "date": 1666813056411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "431",
        "rarity_score": 142.27535089751754,
        "rarity_level": "COMMON"
    },
    "1122": {
        "name": "Kadena Skellies #1122",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1122.png",
        "dna": "9d785e2b8c5a2fc0e546df1a94c8a54f619cc2f3",
        "edition": 1122,
        "date": 1666813056483,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1122",
        "rarity_score": 121.25266431225734,
        "rarity_level": "COMMON"
    },
    "22": {
        "name": "Kadena Skellies #22",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/22.png",
        "dna": "a93312c4ced309ead04ac82620f34761b23c3260",
        "edition": 22,
        "date": 1666813056558,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Tie Dye Shirt",
                "score": 52.083333333333336
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "22",
        "rarity_score": 152.76557592734065,
        "rarity_level": "COMMON"
    },
    "120": {
        "name": "Kadena Skellies #120",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/120.png",
        "dna": "b69e4439670d6ce55a114f25512b209da1f89d2e",
        "edition": 120,
        "date": 1666813056630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "120",
        "rarity_score": 203.1085526409627,
        "rarity_level": "RARE"
    },
    "524": {
        "name": "Kadena Skellies #524",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/524.png",
        "dna": "6d43433f1944f065382f1edf60575f0359923ecf",
        "edition": 524,
        "date": 1666813056705,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "524",
        "rarity_score": 129.04972936417994,
        "rarity_level": "COMMON"
    },
    "669": {
        "name": "Kadena Skellies #669",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/669.png",
        "dna": "7169d4f235ece9eb99c4a3dfd9885f495ccba3e5",
        "edition": 669,
        "date": 1666813056776,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "669",
        "rarity_score": 162.16514115752724,
        "rarity_level": "COMMON"
    },
    "497": {
        "name": "Kadena Skellies #497",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/497.png",
        "dna": "adfd828ad549c6cb0e7df69fda68ab67ed8a89aa",
        "edition": 497,
        "date": 1666813056848,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "497",
        "rarity_score": 115.29280927338456,
        "rarity_level": "COMMON"
    },
    "92": {
        "name": "Kadena Skellies #92",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/92.png",
        "dna": "96c02e3e7c680440ddeb49e37bcf1438e4e97276",
        "edition": 92,
        "date": 1666813056927,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "92",
        "rarity_score": 163.9672619470453,
        "rarity_level": "COMMON"
    },
    "1218": {
        "name": "Kadena Skellies #1218",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1218.png",
        "dna": "b1c00fbbeedfa0f5765a98c9630c02610d5a150b",
        "edition": 1218,
        "date": 1666813056999,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1218",
        "rarity_score": 192.13562136532818,
        "rarity_level": "RARE"
    },
    "1001": {
        "name": "Kadena Skellies #1001",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1001.png",
        "dna": "269bd33c8e65537ddbad4b41bb5a476a9b339bad",
        "edition": 1001,
        "date": 1666813057072,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1001",
        "rarity_score": 94.34485067036096,
        "rarity_level": "COMMON"
    },
    "1213": {
        "name": "Kadena Skellies #1213",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1213.png",
        "dna": "2f4d3d7eae35fca3efb1f0555548acf2012dffce",
        "edition": 1213,
        "date": 1666813057153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Kittykad Tshirt",
                "score": 178.57142857142858
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1213",
        "rarity_score": 320.9480731477157,
        "rarity_level": "RARE"
    },
    "858": {
        "name": "Kadena Skellies #858",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/858.png",
        "dna": "eb67a081be946a3f9620bbf35e6bcf473d287621",
        "edition": 858,
        "date": 1666813057229,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "858",
        "rarity_score": 124.58370959727878,
        "rarity_level": "COMMON"
    },
    "1132": {
        "name": "Kadena Skellies #1132",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1132.png",
        "dna": "4c12be2515f73614e949ea6ec1b6f9bcde49bfda",
        "edition": 1132,
        "date": 1666813057307,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1132",
        "rarity_score": 130.408709284276,
        "rarity_level": "COMMON"
    },
    "170": {
        "name": "Kadena Skellies #170",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/170.png",
        "dna": "9819184e33ace03bcb1d9b71ec2bf4f2e9d56c33",
        "edition": 170,
        "date": 1666813057379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "170",
        "rarity_score": 140.23989346941386,
        "rarity_level": "COMMON"
    },
    "732": {
        "name": "Kadena Skellies #732",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/732.png",
        "dna": "9a43eae29ac571cae72145600e2fd6c908048b52",
        "edition": 732,
        "date": 1666813057451,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "732",
        "rarity_score": 150.65862078452327,
        "rarity_level": "COMMON"
    },
    "184": {
        "name": "Kadena Skellies #184",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/184.png",
        "dna": "25cf7188c9a74ec3de5a803ad68b37c11c68c02b",
        "edition": 184,
        "date": 1666813057526,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "184",
        "rarity_score": 180.84600984405725,
        "rarity_level": "RARE"
    },
    "483": {
        "name": "Kadena Skellies #483",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/483.png",
        "dna": "4ff7e74a63a0889775eca134bed7b779e0042495",
        "edition": 483,
        "date": 1666813057600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "483",
        "rarity_score": 154.99057324262776,
        "rarity_level": "COMMON"
    },
    "861": {
        "name": "Kadena Skellies #861",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/861.png",
        "dna": "3c8fa99a6683ec15869aa61b06a57fae83d10633",
        "edition": 861,
        "date": 1666813057674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "861",
        "rarity_score": 128.1214952951347,
        "rarity_level": "COMMON"
    },
    "644": {
        "name": "Kadena Skellies #644",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/644.png",
        "dna": "efb4c1de90ca39b20e52be134951962b3a070e2a",
        "edition": 644,
        "date": 1666813057750,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "644",
        "rarity_score": 150.0201268520938,
        "rarity_level": "COMMON"
    },
    "952": {
        "name": "Kadena Skellies #952",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/952.png",
        "dna": "e99a8760851655f7bdb7f6038e1b0a017a3bdafe",
        "edition": 952,
        "date": 1666813057821,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "952",
        "rarity_score": 110.35125187273673,
        "rarity_level": "COMMON"
    },
    "347": {
        "name": "Kadena Skellies #347",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/347.png",
        "dna": "deb27e1c305fb26cb75d6647b9bbbfd517960e27",
        "edition": 347,
        "date": 1666813057900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "347",
        "rarity_score": 127.68763897155819,
        "rarity_level": "COMMON"
    },
    "48": {
        "name": "Kadena Skellies #48",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/48.png",
        "dna": "a3abb61942277762db412c710e148dd07651c1a7",
        "edition": 48,
        "date": 1666813057977,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Skeleton Tshirt",
                "score": 56.81818181818182
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "48",
        "rarity_score": 155.57877643091769,
        "rarity_level": "COMMON"
    },
    "915": {
        "name": "Kadena Skellies #915",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/915.png",
        "dna": "2a1d0fc8a62f80f678db810f0518f99b808e70a1",
        "edition": 915,
        "date": 1666813058050,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "915",
        "rarity_score": 102.41183196336047,
        "rarity_level": "COMMON"
    },
    "1034": {
        "name": "Kadena Skellies #1034",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1034.png",
        "dna": "4e427aca8d5fb972654de5fbfa2f684df3db7c3c",
        "edition": 1034,
        "date": 1666813058123,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1034",
        "rarity_score": 123.82087852664952,
        "rarity_level": "COMMON"
    },
    "133": {
        "name": "Kadena Skellies #133",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/133.png",
        "dna": "c083857fddcb65dbfa7e8d065515e47a414ac168",
        "edition": 133,
        "date": 1666813058200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "133",
        "rarity_score": 118.98380498747147,
        "rarity_level": "COMMON"
    },
    "14": {
        "name": "Kadena Skellies #14",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/14.png",
        "dna": "e0964aca6575787678754002b3e9a1b98858c2f2",
        "edition": 14,
        "date": 1666813058273,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "14",
        "rarity_score": 123.14036452336019,
        "rarity_level": "COMMON"
    },
    "1156": {
        "name": "Kadena Skellies #1156",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1156.png",
        "dna": "fc873cc655a949c69b7bfe99e43913baf9da3f37",
        "edition": 1156,
        "date": 1666813058353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1156",
        "rarity_score": 154.37894676632695,
        "rarity_level": "COMMON"
    },
    "841": {
        "name": "Kadena Skellies #841",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/841.png",
        "dna": "ead5326cc1833b4ef9b0c19335be7049d46c66ce",
        "edition": 841,
        "date": 1666813058427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "841",
        "rarity_score": 194.3063038302177,
        "rarity_level": "RARE"
    },
    "426": {
        "name": "Kadena Skellies #426",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/426.png",
        "dna": "d72a80867ee8cd1f50b3e8b29ae1385b32ee53e5",
        "edition": 426,
        "date": 1666813058500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "426",
        "rarity_score": 210.8177478696297,
        "rarity_level": "RARE"
    },
    "1069": {
        "name": "Kadena Skellies #1069",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1069.png",
        "dna": "9ceb7c7bb288f42ba392172417d4faf5d4a6f334",
        "edition": 1069,
        "date": 1666813058574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1069",
        "rarity_score": 142.25714246100114,
        "rarity_level": "COMMON"
    },
    "1163": {
        "name": "Kadena Skellies #1163",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1163.png",
        "dna": "429a72e6636d24d9ee66a279c9d715eaf04f4ae6",
        "edition": 1163,
        "date": 1666813058649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1163",
        "rarity_score": 114.84264919792932,
        "rarity_level": "COMMON"
    },
    "1003": {
        "name": "Kadena Skellies #1003",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1003.png",
        "dna": "2041bc32b8243eabc872eddec6d1ec4b44aa90a3",
        "edition": 1003,
        "date": 1666813058729,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1003",
        "rarity_score": 177.15405934278576,
        "rarity_level": "RARE"
    },
    "703": {
        "name": "Kadena Skellies #703",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/703.png",
        "dna": "32a7e22280e8abf110c826a755b09e5fbc625e84",
        "edition": 703,
        "date": 1666813058803,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "703",
        "rarity_score": 134.5221470480204,
        "rarity_level": "COMMON"
    },
    "797": {
        "name": "Kadena Skellies #797",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/797.png",
        "dna": "e0530a86fde58bedc3c2e0e3825085a588f18d10",
        "edition": 797,
        "date": 1666813058877,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "797",
        "rarity_score": 120.34250768300043,
        "rarity_level": "COMMON"
    },
    "902": {
        "name": "Kadena Skellies #902",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/902.png",
        "dna": "bbb8c03f86c45332578ba9952dc6867028f93c0b",
        "edition": 902,
        "date": 1666813058958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "902",
        "rarity_score": 128.1074645126413,
        "rarity_level": "COMMON"
    },
    "987": {
        "name": "Kadena Skellies #987",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/987.png",
        "dna": "e9a76b8996d14519d78d8aaaee1a30979e0fb8f7",
        "edition": 987,
        "date": 1666813059034,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "987",
        "rarity_score": 121.65060222515763,
        "rarity_level": "COMMON"
    },
    "96": {
        "name": "Kadena Skellies #96",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/96.png",
        "dna": "6a39fcbd996fa278136653e285a2671699fec35c",
        "edition": 96,
        "date": 1666813059113,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Brown Cowboy Hat",
                "score": 21.551724137931036
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "96",
        "rarity_score": 130.05398525747273,
        "rarity_level": "COMMON"
    },
    "1115": {
        "name": "Kadena Skellies #1115",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1115.png",
        "dna": "a2438f1bf59b18897d79f94ed34d0335fc64597a",
        "edition": 1115,
        "date": 1666813059188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1115",
        "rarity_score": 138.9833210948602,
        "rarity_level": "COMMON"
    },
    "321": {
        "name": "Kadena Skellies #321",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/321.png",
        "dna": "faabe242bd5edf9043a37474633c2c35b8d4a65a",
        "edition": 321,
        "date": 1666813059265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Round Kadena Glasses",
                "score": 250.0
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "321",
        "rarity_score": 345.4662591709276,
        "rarity_level": "EPIC"
    },
    "8": {
        "name": "Kadena Skellies #8",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/8.png",
        "dna": "b902b88aa261ed1284f3703da6f9dd1f3fc54022",
        "edition": 8,
        "date": 1666813059343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "8",
        "rarity_score": 134.9392666912581,
        "rarity_level": "COMMON"
    },
    "1030": {
        "name": "Kadena Skellies #1030",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1030.png",
        "dna": "f550c5dcbf68406aa1067403ce38e9bd09c4a720",
        "edition": 1030,
        "date": 1666813059425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1030",
        "rarity_score": 122.0494715147119,
        "rarity_level": "COMMON"
    },
    "621": {
        "name": "Kadena Skellies #621",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/621.png",
        "dna": "2078054dfea9cefef14213b18a46d6b44cd57762",
        "edition": 621,
        "date": 1666813059508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "621",
        "rarity_score": 112.87049154696213,
        "rarity_level": "COMMON"
    },
    "575": {
        "name": "Kadena Skellies #575",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/575.png",
        "dna": "5570ac14267a1a73ac28fdecaa64fc06c8571b47",
        "edition": 575,
        "date": 1666813059585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "575",
        "rarity_score": 90.7576190930129,
        "rarity_level": "COMMON"
    },
    "114": {
        "name": "Kadena Skellies #114",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/114.png",
        "dna": "f4c2d6a0aa16d83b9b09062b3c2a8abf9f65369b",
        "edition": 114,
        "date": 1666813059663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "114",
        "rarity_score": 113.49884840677105,
        "rarity_level": "COMMON"
    },
    "1223": {
        "name": "Kadena Skellies #1223",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1223.png",
        "dna": "7cf7825eac69a10c483d03d3e65fdbc527c53605",
        "edition": 1223,
        "date": 1666813059739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1223",
        "rarity_score": 243.61419632300786,
        "rarity_level": "RARE"
    },
    "3": {
        "name": "Kadena Skellies #3",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/3.png",
        "dna": "8e88517fcecf095361db5e825ee20121e2cda89b",
        "edition": 3,
        "date": 1666813059814,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "3",
        "rarity_score": 105.55596397376641,
        "rarity_level": "COMMON"
    },
    "205": {
        "name": "Kadena Skellies #205",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/205.png",
        "dna": "6443338ed1a14401abe1dfe8dd43f1ef1fee7e37",
        "edition": 205,
        "date": 1666813059899,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "205",
        "rarity_score": 115.76130333579087,
        "rarity_level": "COMMON"
    },
    "573": {
        "name": "Kadena Skellies #573",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/573.png",
        "dna": "da8474959c2509068927f64598f83267d8879504",
        "edition": 573,
        "date": 1666813059981,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            { "trait_type": "Outfit", "value": "Biker Jacket", "score": 31.25 },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "573",
        "rarity_score": 159.34185988529936,
        "rarity_level": "COMMON"
    },
    "1113": {
        "name": "Kadena Skellies #1113",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1113.png",
        "dna": "abb6a6753b6268e750292b83d40d0aaa39edada0",
        "edition": 1113,
        "date": 1666813060063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1113",
        "rarity_score": 153.4142957121643,
        "rarity_level": "COMMON"
    },
    "229": {
        "name": "Kadena Skellies #229",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/229.png",
        "dna": "c4015adc00337edec9feac90651d9ac654fda629",
        "edition": 229,
        "date": 1666813060136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "229",
        "rarity_score": 134.63226700654286,
        "rarity_level": "COMMON"
    },
    "46": {
        "name": "Kadena Skellies #46",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/46.png",
        "dna": "9a70697deaf03b3c35e8cc0e807f8a73b732bf4d",
        "edition": 46,
        "date": 1666813060210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "46",
        "rarity_score": 115.41522924380433,
        "rarity_level": "COMMON"
    },
    "413": {
        "name": "Kadena Skellies #413",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/413.png",
        "dna": "d844da47bd65e09a6cdaded43d64286bf2430e74",
        "edition": 413,
        "date": 1666813060280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Wen Hat",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "413",
        "rarity_score": 137.86730374071522,
        "rarity_level": "COMMON"
    },
    "256": {
        "name": "Kadena Skellies #256",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/256.png",
        "dna": "1e65971b07ac8b8b76468ed576da5efa315e8f21",
        "edition": 256,
        "date": 1666813060360,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "256",
        "rarity_score": 171.82669254000362,
        "rarity_level": "RARE"
    },
    "790": {
        "name": "Kadena Skellies #790",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/790.png",
        "dna": "bffc962234d00d14b839df6e2ebd6ce91547ebd1",
        "edition": 790,
        "date": 1666813060438,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Galaxy",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "790",
        "rarity_score": 126.35147852757311,
        "rarity_level": "COMMON"
    },
    "76": {
        "name": "Kadena Skellies #76",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/76.png",
        "dna": "67c694b7b3aee20e7287fc0b0bfefb3dc05d0896",
        "edition": 76,
        "date": 1666813060514,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "76",
        "rarity_score": 234.0447274960961,
        "rarity_level": "RARE"
    },
    "181": {
        "name": "Kadena Skellies #181",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/181.png",
        "dna": "675406a886da12a305b304c8f0d853ca1e9cc4d2",
        "edition": 181,
        "date": 1666813060589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Pirate Hat",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "181",
        "rarity_score": 164.43970566627706,
        "rarity_level": "COMMON"
    },
    "873": {
        "name": "Kadena Skellies #873",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/873.png",
        "dna": "d22a4aec2c7b8334863882d7c4f2725ef316e0ee",
        "edition": 873,
        "date": 1666813060663,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "873",
        "rarity_score": 168.28096348842286,
        "rarity_level": "COMMON"
    },
    "1085": {
        "name": "Kadena Skellies #1085",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1085.png",
        "dna": "4cacc2f32f0399918fed99a30eaa0630ca609148",
        "edition": 1085,
        "date": 1666813060736,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1085",
        "rarity_score": 125.65132111159846,
        "rarity_level": "COMMON"
    },
    "901": {
        "name": "Kadena Skellies #901",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/901.png",
        "dna": "ff05e949cbb73ece24efb4c76cdfa699479cca92",
        "edition": 901,
        "date": 1666813060814,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "901",
        "rarity_score": 138.97458460245156,
        "rarity_level": "COMMON"
    },
    "806": {
        "name": "Kadena Skellies #806",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/806.png",
        "dna": "1d87e15792ddca5f995633055971416eb290ea1c",
        "edition": 806,
        "date": 1666813060886,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "806",
        "rarity_score": 141.20738492802113,
        "rarity_level": "COMMON"
    },
    "1164": {
        "name": "Kadena Skellies #1164",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1164.png",
        "dna": "4ad25cc9fafe35bfcec6d0f789d27291e07fca9a",
        "edition": 1164,
        "date": 1666813060959,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1164",
        "rarity_score": 135.63760583720077,
        "rarity_level": "COMMON"
    },
    "454": {
        "name": "Kadena Skellies #454",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/454.png",
        "dna": "3e8d6d7f644920f390f4308a4a3345bf431cfc9a",
        "edition": 454,
        "date": 1666813061036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "KDLaunch Sweatshirt",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "454",
        "rarity_score": 191.98948568190713,
        "rarity_level": "RARE"
    },
    "887": {
        "name": "Kadena Skellies #887",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/887.png",
        "dna": "48886fc031e62ec2710d8ff40c6df9dbae4ad70f",
        "edition": 887,
        "date": 1666813061114,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "887",
        "rarity_score": 127.8400426940921,
        "rarity_level": "COMMON"
    },
    "836": {
        "name": "Kadena Skellies #836",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/836.png",
        "dna": "b2c2346a21c7f87b15bb52a6d48d3a2175fd01ea",
        "edition": 836,
        "date": 1666813061189,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "836",
        "rarity_score": 160.4681101708636,
        "rarity_level": "COMMON"
    },
    "2": {
        "name": "Kadena Skellies #2",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/2.png",
        "dna": "0bff0d273fb68363bf2239ed24264278369d5b50",
        "edition": 2,
        "date": 1666813061261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "2",
        "rarity_score": 118.69658437009682,
        "rarity_level": "COMMON"
    },
    "458": {
        "name": "Kadena Skellies #458",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/458.png",
        "dna": "19055690d9f5c0647a2afeb2a76c2432599ce219",
        "edition": 458,
        "date": 1666813061336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "458",
        "rarity_score": 191.87115929772622,
        "rarity_level": "RARE"
    },
    "1216": {
        "name": "Kadena Skellies #1216",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1216.png",
        "dna": "67a686a913e9a91da606e6502c00ecba407d829a",
        "edition": 1216,
        "date": 1666813061417,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Blue Beanie",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1216",
        "rarity_score": 176.3248837220573,
        "rarity_level": "RARE"
    },
    "548": {
        "name": "Kadena Skellies #548",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/548.png",
        "dna": "92ea11e30efa519fd72897ba2e545c38d179e55e",
        "edition": 548,
        "date": 1666813061492,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "548",
        "rarity_score": 173.0666017258112,
        "rarity_level": "RARE"
    },
    "169": {
        "name": "Kadena Skellies #169",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/169.png",
        "dna": "7556752c3920994eab8a12f947b32dde7b98fa79",
        "edition": 169,
        "date": 1666813061565,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "169",
        "rarity_score": 162.49887555296337,
        "rarity_level": "COMMON"
    },
    "535": {
        "name": "Kadena Skellies #535",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/535.png",
        "dna": "7609882165db72e2bbd4b76dd5689224061b8201",
        "edition": 535,
        "date": 1666813061640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "535",
        "rarity_score": 96.55799396598697,
        "rarity_level": "COMMON"
    },
    "369": {
        "name": "Kadena Skellies #369",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/369.png",
        "dna": "416b55c3919ec38fb973c0868c447159d13442d9",
        "edition": 369,
        "date": 1666813061713,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "369",
        "rarity_score": 137.6753478389297,
        "rarity_level": "COMMON"
    },
    "1217": {
        "name": "Kadena Skellies #1217",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1217.png",
        "dna": "e382c1959b9db37c7e416c21533531813348446d",
        "edition": 1217,
        "date": 1666813061785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1217",
        "rarity_score": 108.84294692182475,
        "rarity_level": "COMMON"
    },
    "276": {
        "name": "Kadena Skellies #276",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/276.png",
        "dna": "b789bdce134e6b796df801519536dd165fa7600a",
        "edition": 276,
        "date": 1666813061860,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "276",
        "rarity_score": 132.8850442995492,
        "rarity_level": "COMMON"
    },
    "142": {
        "name": "Kadena Skellies #142",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/142.png",
        "dna": "9277686fa3cc612f3da1c4fb3d613fd930f09a63",
        "edition": 142,
        "date": 1666813061934,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "142",
        "rarity_score": 112.03138513686785,
        "rarity_level": "COMMON"
    },
    "280": {
        "name": "Kadena Skellies #280",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/280.png",
        "dna": "11e22ec2280fba7206e1d6d86a4cee56fc03143e",
        "edition": 280,
        "date": 1666813062008,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Skull",
                "score": 208.33333333333334
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "280",
        "rarity_score": 301.16835585585585,
        "rarity_level": "RARE"
    },
    "121": {
        "name": "Kadena Skellies #121",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/121.png",
        "dna": "5eac52d5b6df5c2ed52d7070b5f5c2bcb24aa12f",
        "edition": 121,
        "date": 1666813062081,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "121",
        "rarity_score": 175.12740041194328,
        "rarity_level": "RARE"
    },
    "1061": {
        "name": "Kadena Skellies #1061",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1061.png",
        "dna": "b5c8f9fabd7fc55997d24e3fddb18eb7c851a3f4",
        "edition": 1061,
        "date": 1666813062156,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1061",
        "rarity_score": 124.39573173417782,
        "rarity_level": "COMMON"
    },
    "655": {
        "name": "Kadena Skellies #655",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/655.png",
        "dna": "b2566c328e0c993cd550d2060714b583524550aa",
        "edition": 655,
        "date": 1666813062239,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Blue",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "655",
        "rarity_score": 158.59857613895798,
        "rarity_level": "COMMON"
    },
    "725": {
        "name": "Kadena Skellies #725",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/725.png",
        "dna": "a242a202b8b30cb54eb94547d14690f185f8f569",
        "edition": 725,
        "date": 1666813062343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "725",
        "rarity_score": 126.04659368645956,
        "rarity_level": "COMMON"
    },
    "1017": {
        "name": "Kadena Skellies #1017",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1017.png",
        "dna": "5f055ba1a31267b7f8766d0a4e7e64e261e7a002",
        "edition": 1017,
        "date": 1666813062437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1017",
        "rarity_score": 115.8300354766269,
        "rarity_level": "COMMON"
    },
    "550": {
        "name": "Kadena Skellies #550",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/550.png",
        "dna": "7f6acf9730b9deaf17b59a6eabd1eb6c3b9ab8a4",
        "edition": 550,
        "date": 1666813062510,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Diamond",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "550",
        "rarity_score": 193.21921100306872,
        "rarity_level": "RARE"
    },
    "1088": {
        "name": "Kadena Skellies #1088",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1088.png",
        "dna": "c5bff9556dc508bf534b2095e0be1ab13ed02b97",
        "edition": 1088,
        "date": 1666813062584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1088",
        "rarity_score": 175.45061295061296,
        "rarity_level": "RARE"
    },
    "1074": {
        "name": "Kadena Skellies #1074",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1074.png",
        "dna": "a39da54e8a737f55aa2a6352c96114b7457701d2",
        "edition": 1074,
        "date": 1666813062658,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Kaddex Sweatshirt",
                "score": 78.125
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "1074",
        "rarity_score": 184.83615543140687,
        "rarity_level": "RARE"
    },
    "840": {
        "name": "Kadena Skellies #840",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/840.png",
        "dna": "571f52db3cb8c28658f7878c9786274ee1ba3d62",
        "edition": 840,
        "date": 1666813062730,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "840",
        "rarity_score": 99.69184097867802,
        "rarity_level": "COMMON"
    },
    "1053": {
        "name": "Kadena Skellies #1053",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1053.png",
        "dna": "e75decfbb15919c40b8463f1ff8dc56471878840",
        "edition": 1053,
        "date": 1666813062803,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1053",
        "rarity_score": 123.39150353349454,
        "rarity_level": "COMMON"
    },
    "397": {
        "name": "Kadena Skellies #397",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/397.png",
        "dna": "114c27edd12871c06d7136ebe3428df44f15b564",
        "edition": 397,
        "date": 1666813062879,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "397",
        "rarity_score": 117.21353412529885,
        "rarity_level": "COMMON"
    },
    "353": {
        "name": "Kadena Skellies #353",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/353.png",
        "dna": "7dbb9a7bd9bbc5cb7492eba36f0b0326c8fcecc2",
        "edition": 353,
        "date": 1666813062952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "353",
        "rarity_score": 124.37226806826018,
        "rarity_level": "COMMON"
    },
    "1180": {
        "name": "Kadena Skellies #1180",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1180.png",
        "dna": "5633cdb80b43a51dd3fcc50efd7f8fc8e2c59425",
        "edition": 1180,
        "date": 1666813063026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1180",
        "rarity_score": 129.95646391679225,
        "rarity_level": "COMMON"
    },
    "154": {
        "name": "Kadena Skellies #154",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/154.png",
        "dna": "6d2cec3d4276e73b17b13f5cf9ae14fe07ce6b7a",
        "edition": 154,
        "date": 1666813063100,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "154",
        "rarity_score": 147.6543375783767,
        "rarity_level": "COMMON"
    },
    "179": {
        "name": "Kadena Skellies #179",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/179.png",
        "dna": "821c9c74f70db034711d7fbdaa02f4bb882d65d8",
        "edition": 179,
        "date": 1666813063175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "179",
        "rarity_score": 97.16685535751095,
        "rarity_level": "COMMON"
    },
    "158": {
        "name": "Kadena Skellies #158",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/158.png",
        "dna": "a8e13a132bea4d05713681548480210070d6ad84",
        "edition": 158,
        "date": 1666813063249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "158",
        "rarity_score": 134.98466015535342,
        "rarity_level": "COMMON"
    },
    "462": {
        "name": "Kadena Skellies #462",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/462.png",
        "dna": "9110adf96fc8dec61bde95f9e05d8f550228de03",
        "edition": 462,
        "date": 1666813063321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "462",
        "rarity_score": 133.7454047619633,
        "rarity_level": "COMMON"
    },
    "565": {
        "name": "Kadena Skellies #565",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/565.png",
        "dna": "b304656d00d06ba03ca3f25122614a024f8abf3c",
        "edition": 565,
        "date": 1666813063394,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "565",
        "rarity_score": 161.57054114467977,
        "rarity_level": "COMMON"
    },
    "444": {
        "name": "Kadena Skellies #444",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/444.png",
        "dna": "dd685295df3960bd72f88bc00ddcca56999087f4",
        "edition": 444,
        "date": 1666813063471,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Red Varsity Jacket",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Gold Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "444",
        "rarity_score": 142.9635922731853,
        "rarity_level": "COMMON"
    },
    "94": {
        "name": "Kadena Skellies #94",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/94.png",
        "dna": "46bc26ec130027d909c2efa842b090bf6b2c8ca0",
        "edition": 94,
        "date": 1666813063542,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Gold", "score": 125.0 },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Red Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "94",
        "rarity_score": 215.54676663502127,
        "rarity_level": "RARE"
    },
    "304": {
        "name": "Kadena Skellies #304",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/304.png",
        "dna": "9f7992d4072ce0258a7529d671cd288d7acc38c4",
        "edition": 304,
        "date": 1666813063615,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "304",
        "rarity_score": 107.54711708252296,
        "rarity_level": "COMMON"
    },
    "752": {
        "name": "Kadena Skellies #752",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/752.png",
        "dna": "5f6e10c2b0ab4bd52869af2260086d5bff38fed1",
        "edition": 752,
        "date": 1666813063689,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Sweatshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Purple Wizard Hat",
                "score": 50.0
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "752",
        "rarity_score": 154.573472474081,
        "rarity_level": "COMMON"
    },
    "651": {
        "name": "Kadena Skellies #651",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/651.png",
        "dna": "6972a3cec29b3451e5169deebe8d62ad9ef140f6",
        "edition": 651,
        "date": 1666813063761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Green Bandana",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "651",
        "rarity_score": 133.01503653807563,
        "rarity_level": "COMMON"
    },
    "1063": {
        "name": "Kadena Skellies #1063",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1063.png",
        "dna": "cad65781be4b5dc5dc11d93e53e8685eac7cacc9",
        "edition": 1063,
        "date": 1666813063837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            { "trait_type": "Mouth", "value": "Drooling", "score": 15.625 },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "1063",
        "rarity_score": 134.6733467823064,
        "rarity_level": "COMMON"
    },
    "827": {
        "name": "Kadena Skellies #827",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/827.png",
        "dna": "8264c1125e71f8f1691d3864eaf1716b7219c968",
        "edition": 827,
        "date": 1666813063909,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "827",
        "rarity_score": 146.2192349693313,
        "rarity_level": "COMMON"
    },
    "1210": {
        "name": "Kadena Skellies #1210",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1210.png",
        "dna": "6467204bc0fb3a6e008304b90b3fa536b63b1ff1",
        "edition": 1210,
        "date": 1666813063980,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Holographic Glasses",
                "score": 21.1864406779661
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Black Hat",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "1210",
        "rarity_score": 158.7286789221567,
        "rarity_level": "COMMON"
    },
    "844": {
        "name": "Kadena Skellies #844",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/844.png",
        "dna": "d4ed6fb2516a4b71275f3dafbf0bd5e7f6fc233c",
        "edition": 844,
        "date": 1666813064055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Jumper",
                "score": 34.72222222222222
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "844",
        "rarity_score": 117.71051660746419,
        "rarity_level": "COMMON"
    },
    "1112": {
        "name": "Kadena Skellies #1112",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1112.png",
        "dna": "4161b253b49a5367a82acaa52a354ab4534cdd5b",
        "edition": 1112,
        "date": 1666813064137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Pink Jacket With Vest",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1112",
        "rarity_score": 176.80952509581542,
        "rarity_level": "RARE"
    },
    "240": {
        "name": "Kadena Skellies #240",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/240.png",
        "dna": "fc674537858309731c0b3803d2d8a7d5caeeb11a",
        "edition": 240,
        "date": 1666813064211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "240",
        "rarity_score": 131.79197128853636,
        "rarity_level": "COMMON"
    },
    "118": {
        "name": "Kadena Skellies #118",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/118.png",
        "dna": "3f5d1630271a86c858e6eafe3221e60008c32e48",
        "edition": 118,
        "date": 1666813064288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Punk Jacket",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "118",
        "rarity_score": 145.0172625044748,
        "rarity_level": "COMMON"
    },
    "90": {
        "name": "Kadena Skellies #90",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/90.png",
        "dna": "f90eb05506697fe883a16e9f868f36a82e208a5f",
        "edition": 90,
        "date": 1666813064365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "90",
        "rarity_score": 124.73073316295243,
        "rarity_level": "COMMON"
    },
    "234": {
        "name": "Kadena Skellies #234",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/234.png",
        "dna": "6d04cd0d16b54bd67bbd362c52c4d27b8841f546",
        "edition": 234,
        "date": 1666813064442,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Black",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "234",
        "rarity_score": 141.1437077819159,
        "rarity_level": "COMMON"
    },
    "310": {
        "name": "Kadena Skellies #310",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/310.png",
        "dna": "6f0170d72a1d5f41791e407fd999972099b04442",
        "edition": 310,
        "date": 1666813064514,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "310",
        "rarity_score": 118.05541595092726,
        "rarity_level": "COMMON"
    },
    "187": {
        "name": "Kadena Skellies #187",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/187.png",
        "dna": "7ec68992f1926abc54496eccd33cbe3cc78a68af",
        "edition": 187,
        "date": 1666813064586,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            { "trait_type": "Eyes", "value": "Cross Eyes", "score": 156.25 },
            {
                "trait_type": "Mouth",
                "value": "Teethy Laugh",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "187",
        "rarity_score": 242.1005058576216,
        "rarity_level": "RARE"
    },
    "199": {
        "name": "Kadena Skellies #199",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/199.png",
        "dna": "0916fc9bc965c39d285bbd37a7172592a8ca9412",
        "edition": 199,
        "date": 1666813064661,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Kadena",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "199",
        "rarity_score": 159.52699887559044,
        "rarity_level": "COMMON"
    },
    "501": {
        "name": "Kadena Skellies #501",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/501.png",
        "dna": "94aaa1647c1e43ce1486ac86b67982372d1d976a",
        "edition": 501,
        "date": 1666813064732,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Red Jersey",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "501",
        "rarity_score": 122.10275964239133,
        "rarity_level": "COMMON"
    },
    "287": {
        "name": "Kadena Skellies #287",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/287.png",
        "dna": "1c4a61e2e9818ffe749ead7a68a39c461105a943",
        "edition": 287,
        "date": 1666813064805,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "VR Glasses",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "287",
        "rarity_score": 126.96431492153427,
        "rarity_level": "COMMON"
    },
    "1020": {
        "name": "Kadena Skellies #1020",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1020.png",
        "dna": "b8b674552137a36ee87731a0bd22db88735b0579",
        "edition": 1020,
        "date": 1666813064876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1020",
        "rarity_score": 97.10658072299404,
        "rarity_level": "COMMON"
    },
    "779": {
        "name": "Kadena Skellies #779",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/779.png",
        "dna": "ba6c6715479fbce8ab83000d84b5723d3b2ebbd0",
        "edition": 779,
        "date": 1666813064948,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Green Pink Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "779",
        "rarity_score": 116.89678053011176,
        "rarity_level": "COMMON"
    },
    "525": {
        "name": "Kadena Skellies #525",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/525.png",
        "dna": "a2232fd48d0f635e8cb620196c92f24479958465",
        "edition": 525,
        "date": 1666813065021,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Tshirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Teeth",
                "score": 104.16666666666667
            },
            {
                "trait_type": "Head",
                "value": "Fire",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "525",
        "rarity_score": 223.46900821795265,
        "rarity_level": "RARE"
    },
    "652": {
        "name": "Kadena Skellies #652",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/652.png",
        "dna": "c9f6f1f01fd12930743ebcd4e7046524539c7b8d",
        "edition": 652,
        "date": 1666813065092,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "Brown Suit",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Shiny Glasses",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Brown Hat",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "652",
        "rarity_score": 115.20982265999876,
        "rarity_level": "COMMON"
    },
    "406": {
        "name": "Kadena Skellies #406",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/406.png",
        "dna": "057b4f630b9752b3c6354c4cfbcbfc9416967666",
        "edition": 406,
        "date": 1666813065164,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Orange Turtleneck",
                "score": 25.0
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "406",
        "rarity_score": 101.39335638630939,
        "rarity_level": "COMMON"
    },
    "47": {
        "name": "Kadena Skellies #47",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/47.png",
        "dna": "6330a5fca1cb2ccaa4c6de22389e3c7433b524f6",
        "edition": 47,
        "date": 1666813065242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Red Hawaiian Shirt",
                "score": 35.714285714285715
            },
            {
                "trait_type": "Eyes",
                "value": "Red Round Glasses",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "47",
        "rarity_score": 126.74171175528093,
        "rarity_level": "COMMON"
    },
    "742": {
        "name": "Kadena Skellies #742",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/742.png",
        "dna": "40c98bb4c83e682243cd777cdd847ffb6a9f100f",
        "edition": 742,
        "date": 1666813065315,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Graveyard",
                "score": 73.52941176470588
            },
            {
                "trait_type": "Base",
                "value": "Pink",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Coat",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "742",
        "rarity_score": 199.9620671897474,
        "rarity_level": "RARE"
    },
    "117": {
        "name": "Kadena Skellies #117",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/117.png",
        "dna": "a69ebd315d0d18f53ef3fb768f47af0ba055e879",
        "edition": 117,
        "date": 1666813065388,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "117",
        "rarity_score": 161.91293522758465,
        "rarity_level": "COMMON"
    },
    "423": {
        "name": "Kadena Skellies #423",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/423.png",
        "dna": "6efd7974865e6a350d85a097e548509cb277d9aa",
        "edition": 423,
        "date": 1666813065461,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "423",
        "rarity_score": 123.9496740427912,
        "rarity_level": "COMMON"
    },
    "1159": {
        "name": "Kadena Skellies #1159",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1159.png",
        "dna": "01b751f8d29aeb67c61f852ddb18508f5a58850f",
        "edition": 1159,
        "date": 1666813065533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "Beige",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Overalls",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Round Glasses",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1159",
        "rarity_score": 119.48879691103612,
        "rarity_level": "COMMON"
    },
    "404": {
        "name": "Kadena Skellies #404",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/404.png",
        "dna": "85b70ad8d68cb689eb53c331499e9e13f0a0807b",
        "edition": 404,
        "date": 1666813065607,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "KDSwap Sweatshirt",
                "score": 89.28571428571429
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Monocle",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Bubblegum",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "404",
        "rarity_score": 221.9861596686541,
        "rarity_level": "RARE"
    },
    "108": {
        "name": "Kadena Skellies #108",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/108.png",
        "dna": "1e828fc62ac5b3e2a0647a848e29d4090a78af56",
        "edition": 108,
        "date": 1666813065681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Pink Graveyard",
                "score": 62.5
            },
            {
                "trait_type": "Base",
                "value": "Green Pink",
                "score": 13.736263736263735
            },
            {
                "trait_type": "Outfit",
                "value": "No Clothes",
                "score": 10.080645161290322
            },
            {
                "trait_type": "Eyes",
                "value": "Heart Glasses",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "108",
        "rarity_score": 236.5630427615021,
        "rarity_level": "RARE"
    },
    "418": {
        "name": "Kadena Skellies #418",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/418.png",
        "dna": "29273ca7632dc071bebc9f67f99ba0e94fd89447",
        "edition": 418,
        "date": 1666813065753,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Eyeballs",
                "score": 54.34782608695652
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "418",
        "rarity_score": 155.38775198677445,
        "rarity_level": "COMMON"
    },
    "201": {
        "name": "Kadena Skellies #201",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/201.png",
        "dna": "d783c689de4fdd9ec01884a0aa4dfecac483f8ad",
        "edition": 201,
        "date": 1666813065827,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Shaded Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Open Mouth",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Head",
                "value": "Rainy Cloud",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "201",
        "rarity_score": 123.51672495300467,
        "rarity_level": "COMMON"
    },
    "1123": {
        "name": "Kadena Skellies #1123",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1123.png",
        "dna": "6fd43ea8e5e3e64784c47ba6a07b187ca5e947f9",
        "edition": 1123,
        "date": 1666813065899,
        "attributes": [
            { "trait_type": "Background", "value": "Teal", "score": 7.8125 },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Black Vest",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Eyes",
                "value": "Icy Blue Eyes",
                "score": 83.33333333333333
            },
            {
                "trait_type": "Mouth",
                "value": "Green Tongue",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Head",
                "value": "Olive Beanie",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1123",
        "rarity_score": 199.4455999890394,
        "rarity_level": "RARE"
    },
    "946": {
        "name": "Kadena Skellies #946",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/946.png",
        "dna": "b7a73a9e9368ab64ceec536f6e3b4d4789e30474",
        "edition": 946,
        "date": 1666813065973,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Head",
                "value": "Sky Blue Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "Red Light Saber",
                "score": 14.044943820224718
            }
        ],
        "id": "946",
        "rarity_score": 243.72223140192403,
        "rarity_level": "RARE"
    },
    "897": {
        "name": "Kadena Skellies #897",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/897.png",
        "dna": "01eeb04fd7b117a2250be90b82219fb0c5733a01",
        "edition": 897,
        "date": 1666813066045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Peach",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Overalls",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Tongue Out",
                "score": 12.626262626262626
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "897",
        "rarity_score": 117.06529297469383,
        "rarity_level": "COMMON"
    },
    "446": {
        "name": "Kadena Skellies #446",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/446.png",
        "dna": "36ed1bca34cc687cacb89811659650f7988ebfe0",
        "edition": 446,
        "date": 1666813066121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brown",
                "score": 8.80281690140845
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Teal Jumper",
                "score": 33.78378378378378
            },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Headphones",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "446",
        "rarity_score": 132.07438261955912,
        "rarity_level": "COMMON"
    },
    "856": {
        "name": "Kadena Skellies #856",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/856.png",
        "dna": "f01e7de351ff1b126bc2cc8f625eb126c63d69a4",
        "edition": 856,
        "date": 1666813066207,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Purple",
                "score": 10.504201680672269
            },
            { "trait_type": "Base", "value": "Purple", "score": 12.5 },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Brown Rim Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Straight Teeth",
                "score": 12.135922330097088
            },
            {
                "trait_type": "Head",
                "value": "Purple Beanie",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "856",
        "rarity_score": 118.0476375585268,
        "rarity_level": "COMMON"
    },
    "684": {
        "name": "Kadena Skellies #684",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/684.png",
        "dna": "f6c3233d6ad2b1f0a6c29a9ad5e98d2925eb2d69",
        "edition": 684,
        "date": 1666813066284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 8.561643835616438
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Yellow Hollow Eyes",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Headband",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "684",
        "rarity_score": 135.66481788058078,
        "rarity_level": "COMMON"
    },
    "903": {
        "name": "Kadena Skellies #903",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/903.png",
        "dna": "482e778c16e377d82bfd807da706d54ad58904c4",
        "edition": 903,
        "date": 1666813066354,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Red Jacket With Tshirt",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Sports Glasses",
                "score": 20.161290322580644
            },
            {
                "trait_type": "Mouth",
                "value": "Skeleton Mouth",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "No Hat",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "903",
        "rarity_score": 94.42065189774192,
        "rarity_level": "COMMON"
    },
    "664": {
        "name": "Kadena Skellies #664",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/664.png",
        "dna": "677f1d802fa38a64139784783c7a883798dfe106",
        "edition": 664,
        "date": 1666813066427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            { "trait_type": "Base", "value": "Ghost", "score": 31.25 },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Purple Shaded Glasses",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Red Headband",
                "score": 40.32258064516129
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "664",
        "rarity_score": 159.75334784853487,
        "rarity_level": "COMMON"
    },
    "601": {
        "name": "Kadena Skellies #601",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/601.png",
        "dna": "87b7a17d9eb391f22f7c58e596d91db93e305fc3",
        "edition": 601,
        "date": 1666813066504,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Minecraft",
                "score": 22.321428571428573
            },
            {
                "trait_type": "Base",
                "value": "Neon",
                "score": 12.626262626262626
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Triangle Party Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Teethy Smile",
                "score": 69.44444444444444
            },
            { "trait_type": "Head", "value": "Red Mohawk", "score": 31.25 },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "601",
        "rarity_score": 194.14950664950666,
        "rarity_level": "RARE"
    },
    "235": {
        "name": "Kadena Skellies #235",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/235.png",
        "dna": "901b191094237dc53f9d3205cb25eb8dcd8fd284",
        "edition": 235,
        "date": 1666813066577,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket With Vest",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 46.2962962962963
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Joker Hat",
                "score": 27.77777777777778
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "235",
        "rarity_score": 173.19347010209634,
        "rarity_level": "RARE"
    },
    "819": {
        "name": "Kadena Skellies #819",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/819.png",
        "dna": "4604e5fc8215dd146e6b8c9a0e53282e0554b8cf",
        "edition": 819,
        "date": 1666813066649,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 11.363636363636363
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Green Orange Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Smile",
                "score": 11.363636363636363
            },
            {
                "trait_type": "Head",
                "value": "Blue Kadena Hat",
                "score": 69.44444444444444
            },
            {
                "trait_type": "Accessories",
                "value": "Spear",
                "score": 11.79245283018868
            }
        ],
        "id": "819",
        "rarity_score": 179.324670636625,
        "rarity_level": "RARE"
    },
    "1150": {
        "name": "Kadena Skellies #1150",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1150.png",
        "dna": "b3f44aba6e4d79177a08b5a711b43a0e3052268d",
        "edition": 1150,
        "date": 1666813066720,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sea Green",
                "score": 9.124087591240876
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Purple Varsity Jacket",
                "score": 26.595744680851062
            },
            {
                "trait_type": "Eyes",
                "value": "Gold Blue Glasses",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Blue Spikey Mohawk",
                "score": 27.17391304347826
            },
            {
                "trait_type": "Accessories",
                "value": "Blue Light Saber",
                "score": 12.135922330097088
            }
        ],
        "id": "1150",
        "rarity_score": 128.73171156125784,
        "rarity_level": "COMMON"
    },
    "545": {
        "name": "Kadena Skellies #545",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/545.png",
        "dna": "a409e8e62a764ad032c5242c9abb999a63885234",
        "edition": 545,
        "date": 1666813066795,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Spooky Trees",
                "score": 41.666666666666664
            },
            {
                "trait_type": "Base",
                "value": "Bluish Green",
                "score": 13.020833333333334
            },
            { "trait_type": "Outfit", "value": "Brown Coat", "score": 39.0625 },
            {
                "trait_type": "Eyes",
                "value": "Green Round Glasses",
                "score": 32.89473684210526
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "545",
        "rarity_score": 165.09863191364738,
        "rarity_level": "COMMON"
    },
    "1227": {
        "name": "Kadena Skellies #1227",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1227.png",
        "dna": "1f606b4c6c042347691455bd6a936ef6beed16f2",
        "edition": 1227,
        "date": 1666813066867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Olive Sweatshirt",
                "score": 32.05128205128205
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Translucent Glasses",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Waldo",
                "score": 96.15384615384616
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "1227",
        "rarity_score": 189.55454628608365,
        "rarity_level": "RARE"
    },
    "818": {
        "name": "Kadena Skellies #818",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/818.png",
        "dna": "c7338f68bfcec6955f95e364393d2fe35d5c1fcf",
        "edition": 818,
        "date": 1666813066937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Indigo",
                "score": 9.541984732824428
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.01923076923077
            },
            {
                "trait_type": "Outfit",
                "value": "Dark Blue Suit",
                "score": 29.761904761904763
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Metal Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Baby Mouth",
                "score": 12.254901960784315
            },
            {
                "trait_type": "Head",
                "value": "Red Spikey Mohawk",
                "score": 24.50980392156863
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "818",
        "rarity_score": 126.54172121785504,
        "rarity_level": "COMMON"
    },
    "832": {
        "name": "Kadena Skellies #832",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/832.png",
        "dna": "1811ca41ef0c30b883079cf9cf18322551b9ef18",
        "edition": 832,
        "date": 1666813067013,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Grayish Blue",
                "score": 9.469696969696969
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jacket",
                "score": 30.48780487804878
            },
            {
                "trait_type": "Eyes",
                "value": "Black Glasses",
                "score": 23.14814814814815
            },
            {
                "trait_type": "Mouth",
                "value": "Cute Laugh",
                "score": 9.689922480620154
            },
            {
                "trait_type": "Head",
                "value": "Blue Mohawk",
                "score": 29.069767441860463
            },
            {
                "trait_type": "Accessories",
                "value": "Claymore Sword",
                "score": 11.904761904761905
            }
        ],
        "id": "832",
        "rarity_score": 128.13791791509044,
        "rarity_level": "COMMON"
    },
    "1084": {
        "name": "Kadena Skellies #1084",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/1084.png",
        "dna": "f90743c13597a8a509db725c7f54d788e83b3406",
        "edition": 1084,
        "date": 1666813067087,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Olive",
                "score": 15.060240963855422
            },
            {
                "trait_type": "Outfit",
                "value": "Yellow Blue Turtleneck",
                "score": 28.40909090909091
            },
            {
                "trait_type": "Eyes",
                "value": "Purple Translucent Glasses",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Mouth",
                "value": "Sad Skeleton Mouth",
                "score": 12.88659793814433
            },
            {
                "trait_type": "Head",
                "value": "Blue Wizard Hat",
                "score": 43.10344827586207
            },
            {
                "trait_type": "Accessories",
                "value": "Green Light Saber",
                "score": 12.88659793814433
            }
        ],
        "id": "1084",
        "rarity_score": 157.44401524078333,
        "rarity_level": "COMMON"
    },
    "354": {
        "name": "Kadena Skellies #354",
        "description": "Kadena Skellies",
        "image": "https://arkade-prod.s3.amazonaws.com/kadena-skellies/354.png",
        "dna": "cd69a3e0439e2cee8c440e3bd705664f952b07c8",
        "edition": 354,
        "date": 1666813067159,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 8.333333333333334
            },
            {
                "trait_type": "Base",
                "value": "Soft",
                "score": 14.367816091954023
            },
            {
                "trait_type": "Outfit",
                "value": "Blue Jersey",
                "score": 36.76470588235294
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Green Glasses",
                "score": 26.041666666666668
            },
            {
                "trait_type": "Mouth",
                "value": "Evil Laugh",
                "score": 11.46788990825688
            },
            {
                "trait_type": "Head",
                "value": "Green Bucket Hat",
                "score": 25.510204081632654
            },
            {
                "trait_type": "Accessories",
                "value": "No Accessories",
                "score": 1.6891891891891893
            }
        ],
        "id": "354",
        "rarity_score": 124.17480515338569,
        "rarity_level": "COMMON"
    }
}

export default kadena_skellies_metadata
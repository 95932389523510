import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { PactContext } from "../pact/PactContextProvider";
import { ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY, BUTTER_TYPE, ISOKO_COLLECTION_POLICY, KADCARS_PROJECT_NAME_KEY, KAWAII_K9S_PROJECT_NAME_KEY, MARMALADE_TYPE, NETWORK_ID, PACT_RATS_PROJECT_NAME_KEY, SGK_WEAPONS_NAME_KEY, STACKED_TYPE } from "../utils/Constants";
import { doc, query } from "@firebase/firestore";
import { buildDocumentQuery, firestoreGetDocument } from "../Firestore/FirestoreService";
import { checkIfNullOrUndefined } from "../utils/utils";
import { getAllOnSale, getIdsOwnedBuy, getUserNftsInStandardFormat } from "../CollectionProviders/ButterCollectionProvider";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import useFirestore from "../Firestore/useFirestore";
import { useGetUserNfts } from "./useGetUserNfts";
import { fetchPactLocal, getNetworkUrlWithChainId } from "../pact/PactUtils";
import uris from "../project_config_files/kadcars_uris";
import { getContractMeta } from "../utils/ContractMeta";
import { getNftData, getNftImage } from "../utils/weaponsUtils";
import { kawaii_k9s_metadata } from "../project_config_files/kawaii_k9s";
import { KADCARS_COLLECTION_HASH } from "../CollectionProviders/KadcarsProvider";
import { useQueryBlockchainForData } from "../pact/ContractHooks";

const UserContext = createContext();

function UserContextProvider({ children }) {
    const isokoCollectionsContext = useContext(IsokoCollectionsContext);
    const pactContext = useContext(PactContext);
    const [userCollections, setUserCollections] = useState(null);
    const [userCollectionsNonMarm, setUserCollectionsNonMarm] = useState(null);
    const [userNftData, setUserNftData] = useState({
        "total": 0,
        "listed": 0,
        "unlisted": 0,
    });
    const [userNftDataNonMarm, setUserNftDataNonMarm] = useState({
        "total": 0,
        "listed": 0,
        "unlisted": 0,
    });
    const [userNftMap, setUserNftMap] = useState(null);
    const [userItems, setUserItems] = useState(null);
    const [userNftMapNonMarm, setUserNftMapNonMarm] = useState(null);
    const [userItemsNonMarm, setUserItemsNonMarm] = useState(null);
    const [nonMarmIdsOwnedByUser, setNonMarmIdsOwnedByUser] = useState([]);
    const userItemsQuery = useGetUserNfts(
        pactContext.account?.account,
        isokoCollectionsContext?.collections,
        async (data) => {
            setFetching(true);
            setFetchedMarm(false);
            let isUndefined = checkIfNullOrUndefined(data);
            let userNfts = {};
            let userCollections = {
                "all": {},
                "listed": {}
            };
            if (!isUndefined) {
                for (const [projName, project] of Object.entries(data)) {
                    userNfts = { ...userNfts, ...project };
                    for (const [collName, collection] of Object.entries(project)) {
                        // if (collName === SGK_WEAPONS_NAME_KEY) continue
                        let config = isokoCollectionsContext.getCollectionConfig(projName, collName);
                        userCollections["all"][collName] = config
                    }
                }
                setUserCollections(userCollections);
                setUserItems(userNfts);

                return userNfts;
            } else {
                setUserCollections(userCollections);
                setUserItems(userNfts);
                return userNfts;
            }
        }
    )
    // const userItemsQuery = useFirestore(
    //     buildDocumentQuery(`/users/${pactContext.account?.account}`),
    //     async (data) => {
    //         setFetching(true);
    //         setFetchedMarm(false);
    //         let isUndefined = checkIfNullOrUndefined(data);
    //         let userNfts = {};
    //         let userCollections = {
    //             "all": {},
    //             "listed": {}
    //         };
    //         if (!isUndefined) {
    //             for (const [projName, project] of Object.entries(data)) {
    //                 userNfts = { ...userNfts, ...project };
    //                 for (const [collName, collection] of Object.entries(project)) {
    //                     // if (collName === SGK_WEAPONS_NAME_KEY) continue
    //                     let config = isokoCollectionsContext.getCollectionConfig(projName, collName);
    //                     userCollections["all"][collName] = config
    //                 }
    //             }
    //             setUserCollections(userCollections);
    //             setUserItems(userNfts);

    //             return userNfts;
    //         } else {
    //             setUserCollections(userCollections);
    //             setUserItems(userNfts);
    //             return userNfts;
    //         }
    //     },
    //     "doc"
    // )
    const [fetching, setFetching] = useState(false);
    const [fetchedMarm, setFetchedMarm] = useState(false);
    const [fetchedNonMarm, setFetchedNonMarm] = useState(false);
    const [fetchingProgress, setFetchingProgress] = useState(0);
    const [progress, setProgress] = useState(0);
    const queryBlockChainForData = useQueryBlockchainForData();

    let updateUserNfts = useCallback(async () => {
        let nftCount = 0;
        let totalUnlisted = 0;
        let totalListed = 0;
        let collConfig = {
            "all": {},
            "listed": {}
        };
        let listedCollections = {};
        let localUserItems = { ...userItems };
        let localUserNftMap = {};
        let localUserNftData = {
            "total": 0,
            "listed": 0,
            "unlisted": 0,
        };
        let progressLocal = progress;
        let collectionsAmt = 0;
        Object.entries(isokoCollectionsContext.collections).forEach(([collName, collectionConfigs]) => {
            for (const collectionConfig of collectionConfigs) {
                if (collectionConfig["has_marketplace"] || collectionConfig["has_launchpad"]) {
                    collectionsAmt++;
                }
            }
        });

        if (!checkIfNullOrUndefined(userCollections) && fetching && fetchedNonMarm && !fetchedMarm) {
            for (const [collName, collectionConfig] of Object.entries(userCollections["all"])) {
                progressLocal += 1;
                setProgress(progressLocal)
                // setFetchingProgress((progressLocal / Object.entries(userCollections).length) * 100);
                setFetchingProgress((progressLocal / collectionsAmt) * 100);

                if (checkIfNullOrUndefined(pactContext.account)) {
                    return;
                }
                if (checkIfNullOrUndefined(localUserNftMap[collName])) {
                    localUserNftMap[collName] = {};
                }

                if (collectionConfig["standard"] === MARMALADE_TYPE || collectionConfig["standard"] === STACKED_TYPE) {
                    let batches = {};

                    for (const nft of userItems[collectionConfig["policy-info"]["collection-name"]]) {
                        nftCount++;
                        let contractMeta = getContractMeta(collectionConfig["policy-info"]["collection-name"]);
                        let nftPrefix = nft.split(collectionConfig["nft-id-separator-token"])[0];
                        let nftId = nft.split(collectionConfig["nft-id-separator-token"])[1];
                        let batch = Math.floor(parseInt(nftId) / contractMeta["batch-size"]);

                        if (checkIfNullOrUndefined(batches[batch]) && collectionConfig["standard"] === MARMALADE_TYPE) {
                            let nftMapPath = `projects/${collectionConfig["company_name"]}/nft-collections/${collectionConfig["path"]}/nft-map/${batch}`
                            let batchSnapshot = await firestoreGetDocument(nftMapPath);
                            batches[batch] = {
                                ...batches[batch],
                                ...batchSnapshot
                            }
                        }

                        if (checkIfNullOrUndefined(collConfig["all"][collName])) {
                            collConfig["all"][collName] = collectionConfig;
                        }

                        if (collectionConfig["standard"] === STACKED_TYPE) {
                            localUserNftMap[collName][nft] = {};
                            localUserNftMap[collName][nft]["nft-id"] = nft;
                            localUserNftMap[collName][nft]["nft-uri"] = getNftImage(nft);
                            localUserNftMap[collName][nft]["standard"] = STACKED_TYPE;
                            totalUnlisted++;
                        } else {
                            localUserNftMap[collName][nft] = batches[batch][pactContext.account.account][nft];
                            localUserNftMap[collName][nft]["standard"] = MARMALADE_TYPE;
                            if (checkIfNullOrUndefined(batches[batch][pactContext.account.account]) ||
                                checkIfNullOrUndefined(batches[batch][pactContext.account.account][nft])) {
                                continue;
                            }

                            if (batches[batch][pactContext.account.account][nft]["status"] === "listed") {
                                if (checkIfNullOrUndefined(collConfig["listed"][collName])) {
                                    collConfig["listed"][collName] = collectionConfig;
                                }
                                totalListed++;
                            } else {
                                totalUnlisted++;
                            }
                        }
                        // userNftMap = await firestoreGetDocument(`projects/${collectionConfig["company_name"]}/nft-collections/${collectionConfig["path"]}/nft-map/nft-map-doc`);
                        // userNftMap = userNftMap[account.account]
                    }
                }
            }

            localUserNftData["total"] += nftCount + userNftDataNonMarm["total"];
            localUserNftData["listed"] += totalListed + userNftDataNonMarm["listed"];
            localUserNftData["unlisted"] += totalUnlisted + userNftDataNonMarm["unlisted"];

            setFetchingProgress(0);
            setFetching(false);
            setFetchedMarm(true);
        }

        collConfig["all"] = { ...collConfig["all"], ...userCollectionsNonMarm["all"] };
        collConfig["listed"] = { ...collConfig["listed"], ...userCollectionsNonMarm["listed"] };
        setUserCollections(collConfig);
        setUserItems({ ...userItemsNonMarm, ...localUserItems });
        setUserNftMap({ ...userNftMapNonMarm, ...localUserNftMap });
        setUserNftData(localUserNftData);
    }, [userItemsQuery, fetchedNonMarm]);

    useEffect(() => {
        !checkIfNullOrUndefined(userItems) &&
            !checkIfNullOrUndefined(userCollections) &&
            fetching && fetchedNonMarm && !fetchedMarm &&
            updateUserNfts();
    }, [updateUserNfts]);

    useEffect(() => {
        if (checkIfNullOrUndefined(pactContext.account)) {
            clearUserContext();
        } else if (!checkIfNullOrUndefined(pactContext.account) && !fetching && !fetchedMarm && !fetchedNonMarm) {
            setFetching(true)
        }
    }, [pactContext.account]);

    useEffect(() => {
        if (
            fetching &&
            fetchingProgress === 0 &&
            !fetchedNonMarm &&
            !checkIfNullOrUndefined(isokoCollectionsContext.collections)
        ) {
            getNonMarmNfts();
        }
    }, [fetching, isokoCollectionsContext.collections]);

    async function getNonMarmNfts() {
        let nftCount = 0;
        let totalUnlisted = 0;
        let totalListed = 0;
        let collConfig = {
            "all": {},
            "listed": {}
        };
        let localUserItems = {};
        let localUserNftMap = {};
        let localUserNftData = {
            "total": 0,
            "listed": 0,
            "unlisted": 0,
        };
        let progressLocal = fetchingProgress;
        let collectionsAmt = 0;
        Object.entries(isokoCollectionsContext.collections).forEach(([collName, collectionConfigs]) => {
            for (const collectionConfig of collectionConfigs) {
                if (collectionConfig["has_marketplace"] || collectionConfig["has_launchpad"]) {
                    collectionsAmt++;
                }
            }
        });

        for (const [collName, collectionConfigs] of Object.entries(isokoCollectionsContext.collections)) {
            for (const collectionConfig of collectionConfigs) {
                let collectionName = collectionConfig["policy-info"]["collection-name"];

                if (collectionName === KADCARS_PROJECT_NAME_KEY) {
                    let networkUrl = getNetworkUrlWithChainId(NETWORK_ID, "8")
                    // let kadcars = await fetchPactLocal(`(free.kadcars-nft-policy.get-cars-in-collection-by-owner "k:2" "${pactContext.account?.account}")`, "13", networkUrl, pactContext.account?.account);
                    // let kadcars = await fetchPactLocal(`(${ISOKO_COLLECTION_POLICY}.get-nfts-by-owner "${KADCARS_COLLECTION_HASH}" "${pactContext.account?.account}")`, "8", networkUrl, pactContext.account?.account);
                    let pactCode = `(${ISOKO_COLLECTION_POLICY}.get-nfts-by-owner "${KADCARS_COLLECTION_HASH}" "${pactContext.account?.account}")`
                    let kadcars = await queryBlockChainForData(pactCode, "8");
                    console.log(kadcars)

                    if (kadcars?.length > 0) {
                        totalUnlisted += kadcars.length;
                        nftCount += kadcars.length;
                        localUserItems[collectionName] = kadcars.map((kc) => kc["token-id"]);
                        localUserNftMap[collectionName] = {};

                        for (const kc of kadcars) {
                            // let nftId = kc["token-id"].split(":")[2];
                            // if (checkIfNullOrUndefined(uris[nftId])) {
                            //     continue;
                            // }
                            // localUserNftMap[collectionName][kc["token-id"]] = {
                            //     "nft-id": nftId,
                            //     "nft-uri": `https://${uris[nftId].split("//")[1]}.ipfs.nftstorage.link`
                            // }
                            let manifest = await fetchPactLocal(`(marmalade-v2.ledger.get-token-info "${kc["token-id"]}")`, "8", networkUrl, pactContext.account?.account);

                            await fetch(manifest["uri"]).then((response) => response.json()).then((res) => {
                                localUserNftMap[collectionName][kc["token-id"]] = {
                                    ...res["data"][3],
                                    "token-id": manifest["id"],
                                    "nft-id": res["data"][1]["datum"]["vehicle-information"]["vin"],
                                    "nft-uri": `https://${res["uri"]["data"].split("//")[1]}.ipfs.nftstorage.link`
                                }
                            });
                        }
                        collConfig["all"][collectionName] = collectionConfig;
                    }

                    continue;
                } else if (collectionName === KAWAII_K9S_PROJECT_NAME_KEY) {
                    let networkUrl = getNetworkUrlWithChainId(NETWORK_ID, "8")
                    let nfts = await fetchPactLocal(`(free.kawaii-k9s.get-nfts-by-owner "${pactContext.account?.account}")`, "8", networkUrl, pactContext.account?.account);

                    if (nfts?.length > 0) {
                        totalUnlisted += nfts.length;
                        nftCount += nfts.length;
                        localUserItems[collectionName] = nfts.map((nft) => nft["token-id"]);

                        localUserNftMap[collectionName] = {};
                        for (const nft of nfts) {
                            let nftId = nft["token-id"].split(":")[1];
                            if (checkIfNullOrUndefined(kawaii_k9s_metadata[nftId])) {
                                continue;
                            }
                            localUserNftMap[collectionName][nft["token-id"]] = {
                                "nft-id": nftId,
                                "nft-uri": kawaii_k9s_metadata[nftId]["uri"]["data"]
                            }
                        }
                        collConfig["all"][collectionName] = collectionConfig;
                    }

                    continue;
                }

                if (collectionConfig["standard"] !== BUTTER_TYPE || !collectionConfig["has_user_profile"]) {
                    continue;
                } else {
                    progressLocal += 1;
                    setProgress(progressLocal);
                    setFetchingProgress((progressLocal / collectionsAmt) * 100);
                }

                if (checkIfNullOrUndefined(pactContext.account)) {
                    return;
                }

                let userNfts = [];
                if (collectionConfig["standard"] === STACKED_TYPE) {
                    // let userNftsLocal = await queryBlockChainForData(
                    //     `(free.collection-data-utility.get-nfts-by-owner-prod "${pactContext.account.account}")`,
                    //     "8",
                    //     null,
                    //     1000000000
                    // );
                    // userNftsLocal.forEach((nftGroup) => {
                    //     userNfts = userNfts.concat(nftGroup);
                    // });
                    // userNfts = userNfts.map((nft) => {
                    //     let nftId = nft["token-id"];
                    //     let nftPrefix = nft["token-id"].split(":")[0];
                    //     let updated = {};
                    //     updated["nft-id"] = nftId;
                    //     updated["nft-uri"] = `${collectionConfig["policy-info"]["base_image_url"]
                    //         }${collectionConfig["child-collection-map"][nftPrefix.split("-")[2]]["type"]
                    //         }/${collectionConfig["child-collection-map"][nftPrefix.split("-")[2]]["type"]
                    //         }-${collectionConfig["collection_item_rarities"][nftPrefix.split("-")[3]]
                    //         }.png`;
                    //     return updated;
                    // });

                } else if (collectionConfig["standard"] === BUTTER_TYPE) {
                    userNfts = await getIdsOwnedBuy(pactContext, collectionConfig["policy-info"]);
                    let allOnSale = await getAllOnSale(collectionConfig["policy-info"], pactContext);
                    let userNftIds = userNfts.map((nft) => nft["id"]);
                    let allNftIds = allOnSale.map((nft) => nft["id"]);
                    let listedUserNfts = allNftIds.filter((nft) => userNftIds.includes(nft["id"]));

                    totalListed += listedUserNfts.length;
                    totalUnlisted += userNftIds.length - listedUserNfts.length;

                    userNfts = userNfts.map((nft) => {
                        let updated = {};
                        updated["nft-id"] = nft["id"];
                        updated["nft-uri"] = `${collectionConfig["policy-info"]["base_image_url"]}${nft["id"]}.png`;
                        return updated;
                    });
                }

                if (checkIfNullOrUndefined(userNfts) || userNfts.length === 0) {
                    continue;
                } else {
                    collConfig["all"][collectionName] = collectionConfig;
                    if (checkIfNullOrUndefined(localUserNftMap[collectionName])) {
                        localUserItems[collectionName] = {};
                        localUserNftMap[collectionName] = {};
                    }
                }

                for (const nft of userNfts) {
                    nftCount++;
                    localUserNftMap[collectionName][nft["nft-id"]] = nft;
                }
                localUserItems[collectionName] = userNfts;

                let transformedList = await getUserNftsInStandardFormat(collectionConfig["policy-info"], pactContext);
                for (const nft of transformedList) {
                    if (nft["status"] === "listed") {
                        if (checkIfNullOrUndefined(collConfig["listed"][collectionConfig["policy-info"]["collection-name"]])) {
                            collConfig["listed"][collectionConfig["policy-info"]["collection-name"]] = collectionConfig;
                        }
                        totalListed++;
                    } else {
                        totalUnlisted++;
                    }
                    localUserNftMap[collectionConfig["policy-info"]["collection-name"]][nft["nft-id"]] = nft;
                }
            }
        }

        localUserNftData["total"] += nftCount;
        localUserNftData["listed"] += totalListed;
        localUserNftData["unlisted"] += totalUnlisted;

        setUserCollectionsNonMarm({ ...userCollectionsNonMarm, ...collConfig });
        setUserItemsNonMarm({ ...userItemsNonMarm, ...localUserItems });
        setUserNftMapNonMarm({ ...userNftMapNonMarm, ...localUserNftMap });
        setUserNftDataNonMarm({ ...userNftDataNonMarm, ...localUserNftData });
        setFetchedNonMarm(true);
        // setFetchingProgress(0);
    }

    function triggerRefetch() {
        setFetchedMarm(false);
        setFetchedNonMarm(false);
        setFetching(true);
        setFetchingProgress(0);
        setProgress(0);
    }

    function addNft(nftData, collectionConfig) {
        let collConfig = { ...userCollections };
        let localUserItems = { ...userItems };
        let localUserNftMap = { ...userNftMap };
        let localUserNftData = { ...userNftData };
        let collectionName = collectionConfig["policy-info"]["collection-name"];

        //Update userCollections state
        if (checkIfNullOrUndefined(collConfig["all"])) {
            collConfig["all"] = {};
        }
        collConfig["all"][collectionName] = collectionConfig;

        //Update userItems state
        if (checkIfNullOrUndefined(localUserItems[collectionName])) {
            localUserItems[collectionName] = [];
        }
        localUserItems[collectionName].push(nftData["nft-id"]);

        //Update userNftMap state
        if (checkIfNullOrUndefined(localUserNftMap[collectionName])) {
            localUserNftMap[collectionName] = {};
        }
        localUserNftMap[collectionName][nftData["nft-id"]] = nftData;

        //Update userNftData state
        localUserNftData["total"] += 1;
        localUserNftData["unlisted"] += 1;

        updateUserNftStates(collConfig, localUserItems, localUserNftMap, localUserNftData);
    }

    function removeNft(nftData, collectionConfig) {
        let collConfig = { ...userCollections };
        let localUserItems = { ...userItems };
        let localUserNftMap = { ...userNftMap };
        let localUserNftData = { ...userNftData };
        let collectionName = collectionConfig["policy-info"]["collection-name"];

        //Update userCollections state
        delete collConfig[collectionName];

        //Update userItems state
        let index = localUserItems[collectionName].indexOf(nftData["nft-id"]);
        localUserItems[collectionName].splice(index, 1);
        if (localUserItems[collectionName].length === 0) {
            delete localUserItems[collectionName];
        }

        //Update userNftMap state
        delete localUserNftMap[collectionName][nftData["nft-id"]];
        if (Object.entries(localUserNftMap[collectionName]).length === 0) {
            delete localUserNftMap[collectionName];
        }

        //Update userNftData state
        localUserNftData["total"] -= 1;
        localUserNftData["unlisted"] -= 1;

        updateUserNftStates(collConfig, localUserItems, localUserNftMap, localUserNftData);
    }

    function updateUserNftStates(collConfig, localUserItems, localUserNftMap, localUserNftData) {
        setUserCollections(collConfig);
        setUserItems(localUserItems);
        setUserNftMap(localUserNftMap);
        setUserNftData(localUserNftData);
    }

    function clearUserContext() {
        setFetching(false);
        setFetchedNonMarm(false);
        setFetchedMarm(false);
        setUserCollectionsNonMarm(null);
        setUserNftDataNonMarm(null);
        setUserNftMapNonMarm(null);
        setUserItemsNonMarm(null);
        setUserCollections(null);
        setUserNftData(null);
        setUserNftMap(null);
        setUserItems(null);
    }

    return (
        <UserContext.Provider
            value={{
                fetching,
                setFetching,
                setFetchedMarm,
                setFetchedNonMarm,
                setFetchingProgress,
                fetchingProgress,
                clearUserContext,
                userCollections,
                userNftData,
                userNftMap,
                userItems,
                triggerRefetch,
                addNft,
                removeNft
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export {
    UserContext,
    UserContextProvider
}
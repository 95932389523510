import {
    Box,
    Button,
    CloseButton,
    Flex,
    FormLabel,
    Icon,
    Input,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Textarea,
    FormControl,
    FormErrorMessage,
    FormHelperText,
} from "@chakra-ui/react";
import emailjs from '@emailjs/browser'
import React, { useContext, useState, useRef } from "react";
import { IsokoDialogContext} from "../IsokoContextProvider/IsokoDialogContextProvider";
import { FaTimes } from "react-icons/fa";

const BugReportModal = () => {
    const { isOpenFeedbackModal, onOpenFeedbackModal, onCloseFeedbackModal } = useContext(IsokoDialogContext);
    const initialData = { title: "", message: "" }
    const [Feedback, setFeedback] = useState(initialData);
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFeedback({ ...Feedback, [name]: value })
    }
    const { title, message } = ''

    const [emailText, setEmailText] = useState('');
    const isError = message === ''
    const form = useRef();
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        if (!emailText.trim()) {
            // Email text is empty or contains only spaces
            setIsFocused(true); // Set isFocused to true to make the box red
            return;
        }
        emailjs.sendForm('service_pt9rehb', 'template_45lcgqo', form.current, '8n8Q22d7PD_jtzzXL').then((response) => {
            console.log('Email sent successfully:', response);
        })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    }


    return (
        <>
            <Modal
                blockScrollOnMount={false}
                isOpen={isOpenFeedbackModal}
                trapFocus={false}
                size="sm"
                onClose={onCloseFeedbackModal}
                isCentered
            >
                <CloseButton onClick={onCloseFeedbackModal} />
                <ModalOverlay />
                <ModalContent
                    bgColor="transparent"
                    className="latestDesign"
                    w="520px"
                >
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="1.75rem"
                        px="20px"
                    >
                        {" "}
                        <Flex justify={"space-between"}>

                            <Text fontSize={["20px"]} fontWeight={["700"]}>
                                Report a Bug
                            </Text>

                            <Icon
                                as={FaTimes}
                                onClick={onCloseFeedbackModal}
                                color={"white"}
                                w="1.25rem"
                                h="1.25rem"
                                cursor={"pointer"}
                            />
                        </Flex>
                        <Box mt="1.38rem">
                            <Box>
                                <FormLabel fontSize={"1rem"} fontWeight={"900"}>
                                    Title
                                </FormLabel>
                                <Input name='title' placeholder="Example" borderRadius="0.25rem" border={"2px"} boxShadow={"rgba(0, 0, 0, 0.12)"} borderColor={"#bc3cf0"} _hover={{}} className="inputBox" borderStyle={"inset"} _focusVisible={{}} onChange={handleInputChange} value={title} />
                            </Box>
                        </Box>

                        <form ref={form} onSubmit={sendEmail} >
                            <Box mt="1.38rem">
                                <Box>

                                    <FormControl isInvalid={isError || (isFocused && !emailText.trim())}>
                                        <FormLabel fontSize={"1rem"} fontWeight={"900"}>
                                            Desciption
                                        </FormLabel>
                                        <Textarea
                                            name='message'
                                            placeholder="Please describe the issue in detail"
                                            borderRadius="0.25rem"
                                            border={"2px"}
                                            boxShadow={"rgba(0, 0, 0, 0.12)"}
                                            borderColor={"#bc3cf0"} _hover={{}}
                                            className="inputBox"
                                            borderStyle={"inset"}
                                            _focusVisible={{}}
                                            height="8.375rem"
                                            onChange={(e) => setEmailText(e.target.value)}
                                            type="text"
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            value={emailText}
                                        />
                                        {!isError ? (
                                            <FormHelperText>
                                                Enter your issue
                                            </FormHelperText>
                                        ) : (
                                            <FormErrorMessage>Enter Something.</FormErrorMessage>

                                        )}
                                    </FormControl>
                                </Box>
                            </Box>

                            <Flex marginTop={"1.38rem"} justify={"flex-end"} gap="20px">


                                <Button
                                    outline={"none"}
                                    border="1px"
                                    borderColor="#BD3DF0"
                                    w="fit-content"
                                    px="1rem"
                                    py="0.375rem"
                                    fontSize={"0.875rem"}
                                    fontWeight={"700"}
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    rounded={"16px"}
                                    type="submit"

                                >
                                    Submit
                                </Button>
                            </Flex>
                        </form>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};

export default BugReportModal;

import { useCallback, useState, useEffect } from "react";
import { db, useQuery } from "../Firestore/FirestoreService";
import { doc, onSnapshot, query } from "firebase/firestore";
import { PactContext } from "../pact/PactContextProvider";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { checkIfNullOrUndefined } from "../utils/utils";

function useGetUserNfts(account, isokoCollections, postProcessFn = null, defaultValue = null) {
    // const queryResult = useQuery(query(doc(db, `/users/${account}`)));
    const [queryResult, setQueryResult] = useState(null);
    const [data, setData] = useLocalStorage("user_collections", {});

    const getUserNfts = useCallback(async () => {
        if (
            !checkIfNullOrUndefined(queryResult) &&
            !checkIfNullOrUndefined(isokoCollections) &&
            Object.entries(isokoCollections).length > 0
        ) {
            let queryData = null;
            queryData = await queryResult.data();

            if (postProcessFn) {
                postProcessFn(queryData).then((data) => {
                    setData(data);
                });
            } else {
                setData(queryData);
            }
        }
    }, [queryResult, isokoCollections]);

    useEffect(() => {
        getUserNfts()
    }, [getUserNfts]);

    useEffect(() => {
        async function runUserQuery() {
            const userQuery = query(doc(db, `/users/${account}`));
            const unsubscribe = onSnapshot(userQuery, (snapshot) => {
                setQueryResult(snapshot);
            });
            return () => unsubscribe();
        }

        if (!checkIfNullOrUndefined(account)) {
            runUserQuery();
        }
    }, [account]);

    if (defaultValue) {
        return defaultValue;
    }
    return data;
}

export {
    useGetUserNfts
}
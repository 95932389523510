import React, { useContext, useEffect, useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import ScreenSize from "../layouts/ScreenSize";
import MintProgressBar from "./MintProgressBar";
import { Box, Button, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { PactContext } from "../pact/PactContextProvider";
import { KADCARS_PROJECT_NAME_KEY, LONG_POLLING_TIMER, PHASE_END_TIME, PHASE_START_TIME } from "../utils/Constants";
import { useInterval } from "../utils/utils";
import { toast } from "react-toastify";
import { MintWindow } from "./MintWindow";
import { SocialLinkGroup } from "../CommonComponents/SocialLinkGroup";
import { FiChevronLeft } from "react-icons/fi";
import { getTotalPriceForAmount } from "./Mint";

//TODO move all functions up to Mint.js level
const MintHero = ({
    initiateMint,
    account,
    userNftList,
    accountWhitelistInfo,
    mintProvider,
    collectionProvider,
    projectConfig,
    onMintCompleteCallback,
    handleBackClick,
    handleConnectClick
}) => {
    const [nftAmount, setNftAmount] = useState(0);
    const [amountMinted, setAmountMinted] = useState(0.0);
    const [totalNftSupply, setTotalSupply] = useState(0.0);
    const [mintedAmountPercentage, setMintedAmountPercentage] = useState(0.0);
    const [currentMintPrice, setCurrentMintPrice] = useState(0.0);
    const [pricePerToken, setPricePerToken] = useState(null);

    useEffect(() => {
        async function refreshDisplayedAmount() {
            await refreshNftSupply();
            let price = await mintProvider?.getNextPrice(nftAmount);
            setPricePerToken(price);
        }
        refreshDisplayedAmount();
    }, [amountMinted, mintProvider]);

    useInterval(
        async () => {
            await refreshNftSupply();
        },
        35000,
        false
    );

    //TODO: handle checking if supply ran out
    async function updateNftAmount(isAdding) {
        let projectNftAmountForConfig = collectionProvider.getNftAmountLimit();
        let currentAmount = 0;

        if (isAdding) {
            if (projectConfig["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY) {
                if (nftAmount < 1) {
                    currentAmount = nftAmount + 1;
                } else {
                    currentAmount = 1;
                }
            } else {
                if (nftAmount < 10) {
                    currentAmount = nftAmount + 1;
                } else {
                    currentAmount = 10;
                }
            }
            setNftAmount(currentAmount);

            let totalPrice = await getTotalPriceForAmount(mintProvider, accountWhitelistInfo, currentAmount);
            if (!totalPrice) {
                return;
            }

            setCurrentMintPrice(parseFloat((totalPrice).toFixed(4)));
            return;
        }

        if (nftAmount > 0) {
            currentAmount = nftAmount - 1;
            // let nextPrice = await mintProvider.getNextPrice(currentAmount);//getnextprice - on amount change
            let totalPrice = await getTotalPriceForAmount(mintProvider, accountWhitelistInfo, currentAmount);//getnextprice - on amount change

            setCurrentMintPrice(parseFloat((totalPrice).toFixed(4)));
        }
        setNftAmount(currentAmount);
    }

    async function mintCompleteCallback(data) {
        onMintCompleteCallback(data);
        await refreshNftSupply();
    }

    async function refreshNftSupply() {
        let nextPrice = await mintProvider?.getNextPrice(1000, accountWhitelistInfo); //getnextprice - on refresh poll
        // let nextPrice = await getTotalPriceForAmount(); //getnextprice - on refresh poll
        let collectionSupply = await collectionProvider.getTotalSupply();
        let mintedAmount = await mintProvider?.getMintedTotal();

        setPricePerToken(nextPrice);
        setTotalSupply(collectionSupply);

        if (mintedAmount) {
            let numMinted = mintedAmount.length
                ? mintedAmount.length
                : mintedAmount;
            setAmountMinted(numMinted);
            setMintedAmountPercentage(
                parseFloat(((numMinted / collectionSupply) * 100).toFixed(2))
            );
        }
    }

    return (
        <ScreenSize>
            {/* <Link to="/launchPad" className="backBtn"> */}


            {/* </Link> */}
            <div
                className="hero-section2"
                style={{ justifyContent: "space-between" }}
            ></div>

            <Flex
                className="latestDesign backBtn"
                onClick={handleBackClick}
                style={{ cursor: "pointer", width: "fit-content" }}
                mb="20px"
                gap="10px"
                align={"center"}

            >
                <Icon as={FiChevronLeft} boxSize={"1.25rem"} display={"inline-block"} /> Back to Launchpad
            </Flex>

            <Flex
                gap="30px"
                align="flex-start"
                flexDir={["column", "row"]}
                justify="space-between"
            >
                <Flex
                    w="100%"
                    className="hero-content"
                    style={{
                        maxWidth: "500px",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <Text fontSize="36px" fontWeight="900">
                            {projectConfig && projectConfig["project-name"]}
                        </Text>

                        <SocialLinkGroup projectConfig={projectConfig} />

                        <Box className="MintHeroText" mt="30px">
                            <Text mb="16px">
                                {/* Introducing a revolutionary new racing game that
                                utilizes blockchain technology to create a truly
                                immersive and unique gaming experience. In this
                                game, players will compete in high-speed races
                                on futuristic tracks, but with the added twist
                                of blockchain technology. */}
                                {projectConfig &&
                                    projectConfig["project_description"]}
                            </Text>
                            <Text>
                                {projectConfig &&
                                    projectConfig[
                                    "project_description_summary"
                                    ]}
                            </Text>
                        </Box>

                        <Box className="mintTotal flex warp" mt="30px">
                            <p>
                                <b>Total items: {totalNftSupply}</b>
                            </p>
                            <p>
                                <b>
                                    Price{" "}
                                    <span className="mintToken">per token</span>
                                    : {pricePerToken ? pricePerToken : "N/A"} KDA
                                </b>
                            </p>
                            <p>
                                <b>Total owned: {userNftList?.length}</b>
                            </p>
                        </Box>

                        {Object.entries(collectionProvider.getMintPhases())
                            .sort(([phase, phaseData], [phaseB, phaseDataB]) => {
                                let first = Date.parse(phaseData[PHASE_START_TIME])
                                let second = Date.parse(phaseDataB[PHASE_START_TIME])

                                return first - second
                            }).map(
                                ([phase, phaseData], i) => {
                                    return (
                                        <MintWindow
                                            key={i}
                                            phase={phase}
                                            phaseData={phaseData}
                                            mintProvider={mintProvider}
                                            accountWhitelistInfo={
                                                accountWhitelistInfo
                                            }
                                        />
                                    );
                                }
                            )}
                    </Box>
                </Flex>

                <Box className="" w="100%">
                    <Flex style={{ position: "relative" }} justify="center">
                        <Image
                            // h="515px"
                            w="100%"
                            maxW="485px"
                            objectFit={"cover"}
                            src={
                                projectConfig &&
                                projectConfig["collection_thumbnail_image"]
                            }
                            alt="Hero"
                            display="block"
                        />
                        {!true ? (
                            <button className="collectionBtn">
                                Explore collection <BsBoxArrowUpRight />{" "}
                            </button>
                        ) : (
                            ""
                        )}
                    </Flex>

                    <Box maxW="685px" mx="auto" style={{ marginTop: "30px" }}>
                        <div className="flex sBtw mintDetails">
                            <p>Total minted</p>
                            <p>
                                {mintedAmountPercentage}% ({amountMinted}/
                                {totalNftSupply})
                            </p>
                        </div>
                        <div style={{ marginBottom: "50px" }}>
                            <MintProgressBar
                                progressPercentage={mintedAmountPercentage}
                            />
                        </div>
                        <Flex
                            className="progressDetails"
                            mt="16px"
                            py="38px"
                            px={["16px", "30px"]}
                            gap="20px"
                            align="center"
                            justify="space-evenly"
                        >
                            {/* Increment Button */}
                            <div className="flex sBtw alignCenter increment">


                                <Flex
                                    maxW="100px"
                                    bgColor="#BD3DF0"
                                    flexShrink={"0"}
                                    align="center"
                                    gap="8px"
                                    borderRadius="4px"
                                >
                                    <Box
                                        w="20px"
                                        cursor="pointer"
                                        onClick={() => updateNftAmount(false)}
                                        textAlign="center"
                                        as="button"
                                        h="100%"
                                    >
                                        -
                                    </Box>
                                    <Text
                                        display="flex"
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w="30px"
                                        as="span"
                                    >
                                        {" "}
                                        {nftAmount}
                                    </Text>
                                    <Box
                                        w="20px"
                                        cursor="pointer"
                                        onClick={() => updateNftAmount(true)}
                                        textAlign="center"
                                        as="button"
                                        h="100%"
                                    >
                                        +
                                    </Box>
                                </Flex>
                            </div>
                            {/* Connect wallet button  */}
                            <Box
                                className="connect_wallet"
                                flex="1"
                                textAlign="center"
                                borderRadius={"4px"}
                                w="100%"

                            // flexShirnk="0"
                            >
                                {!account ? (
                                    <Button
                                        rounded={"full"}
                                        onClick={handleConnectClick}
                                        w="100%"
                                    // maxW="300px"
                                    >
                                        Connect Wallet to continue{" "}
                                    </Button>
                                ) : (
                                    <Button
                                        rounded={"full"}
                                        onClick={() => initiateMint(nftAmount)}
                                        w="100%"

                                    // maxW="300px"
                                    >
                                        Mint!
                                    </Button>
                                )}
                            </Box>
                            {/* Quantity */}
                            <Box flexShrink={"0"} w="150px">
                                <div>
                                    {" "}
                                    <span className="mintToken">
                                        Total
                                    </span>:{" "}
                                    <b className="mintBigText">
                                        {" "}
                                        {currentMintPrice} KDA
                                    </b>
                                </div>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
            <Box align="right"></Box>
        </ScreenSize>
    );
};

export default MintHero;

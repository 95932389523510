import {
    Box,
    Flex,
    Icon,
    ListItem,
    Modal,
    Button,
    ModalContent,
    ModalOverlay,
    Text,
    UnorderedList,
    useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { MdClose } from "react-icons/md";

const ApplyForLaunchPad = () => {
    const { onOpen, isOpen, onClose } = useDisclosure();
    return (
        <Box>
            <Text
                cursor={"pointer"}
                fontSize={"0.875rem"}
                fontWeight={"700"}
                onClick={onOpen}
            >
                Apply for Launchpad
            </Text>

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="md"
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="2rem"
                        py="3rem"
                        px="1.5rem"
                        maxW="33.15rem"
                    >
                        <Flex
                            gap=".65rem"
                            justify="space-between"
                            align="center"
                        >
                            <Text fontSize="2rem" fontWeight="700">
                                Apply for Launchpad
                                <Text as="span" boxSize={"1rem"}>
                                    🚀
                                </Text>
                            </Text>

                            <Icon
                                as={MdClose}
                                pos="relative"
                                boxSize={"1.5rem"}
                                onClick={onClose}
                                cursor="pointer"
                            />
                        </Flex>

                        <Box mt="1rem">
                            <Text fontSize="1.75rem" fontweight={700}>
                                How to apply
                            </Text>{" "}
                            <Text fontSize="0.875rem">
                                To apply for the Isoko Launchpad, shoot us a DM
                                over on Twitter (X) with all your following;
                                info:
                            </Text>
                            <UnorderedList mt="1.5rem" spacing={".1rem"}>
                                <ListItem
                                    _notLast={{ mb: "0px" }}
                                    p="0"
                                    fontSize="0.875rem"
                                >
                                    Art or utility focused?
                                </ListItem>
                                <ListItem
                                    _notLast={{ mb: "0px" }}
                                    p="0"
                                    fontSize="0.875rem"
                                >
                                    Project twitter account and name? (if not
                                    DMing from collection account)
                                </ListItem>
                                <ListItem m="0" p="0" fontSize="0.875rem">
                                    Size of collection?{" "}
                                </ListItem>
                            </UnorderedList>
                        </Box>

                        <Flex mt="1.5rem">
                            <Button
                                w="100%"
                                h="auto"
                                bgColor="#BC3CF0"
                                px="1rem"
                                color="white"
                                py="0.38rem"
                                rounded="full"
                                fontSize="0.875rem"
                                as="a"
                                href="https://twitter.com/isokoxyz"
                                target="_blank"
                                _hover={{}}
                                _active={{}}
                            >
                                Open Isoko X
                            </Button>
                        </Flex>
                    </Box>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ApplyForLaunchPad;

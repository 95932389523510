import { useBattleHeroesProvider } from "../CollectionProviders/BattleHeroesProvider";
import { useBoxingBadgerProvider } from "../CollectionProviders/BoxingBadgerProvider";
import { useIslandsOfDamayaProvider } from "../CollectionProviders/IslandsOfDamayaProvider";
import { useIsokoTestCollectionProvider } from "../CollectionProviders/IsokoTestCollectionProvider";
import { useIsokoStackedCollectionProvider } from "../CollectionProviders/IsokoTestStackedCollectionProvider";
import { useKadcarsProvider } from "../CollectionProviders/KadcarsProvider";
import { useSecretGardenOfKadenaProvider } from "../CollectionProviders/SecretGardenOfKadenaProvider";
import { useSecretGardenOfKadenaWeaponsProvider } from "../CollectionProviders/SecretGardenOfKadenaWeapons";
import {
    BUTTER_TYPE,
    SGK_WEAPONS_NAME_KEY,
    KADCARS_PROJECT_NAME_KEY,
    BATTLE_HEROES_PROJECT_NAME_KEY,
    BOXING_BADGER_PROJECT_NAME_KEY,
    ISOKO_TEST_COLLECTION_NAME_KEY,
    ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
    ISOKO_TEST_STACKED_COLLECTION_NAME_KEY,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
} from "../utils/Constants";

function useMintProviderFactory(projectName, collectionName) {
    const KadcarProvider = useKadcarsProvider();
    const BoxingBadgerProvider = useBoxingBadgerProvider();
    const BattleHeroesProvider = useBattleHeroesProvider();
    const IsokoTestProvider = useIsokoTestCollectionProvider();
    const IslandsOfDamayaProvider = useIslandsOfDamayaProvider();
    const IsokoTestStackedProvider = useIsokoStackedCollectionProvider();
    const SecretGardenOfKadenaProvider = useSecretGardenOfKadenaProvider();
    const SecretGardenOfKadenaWeaponsProvider = useSecretGardenOfKadenaWeaponsProvider();

    switch (collectionName) {
        case KADCARS_PROJECT_NAME_KEY:
            return KadcarProvider;
        case BOXING_BADGER_PROJECT_NAME_KEY:
            return BoxingBadgerProvider;
        case ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY:
            return IslandsOfDamayaProvider;
        case SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY:
            return SecretGardenOfKadenaProvider;
        case BATTLE_HEROES_PROJECT_NAME_KEY:
            return BattleHeroesProvider
        case ISOKO_TEST_COLLECTION_NAME_KEY:
            return IsokoTestProvider
        case ISOKO_TEST_STACKED_COLLECTION_NAME_KEY:
            return IsokoTestStackedProvider
        case SGK_WEAPONS_NAME_KEY:
            return SecretGardenOfKadenaWeaponsProvider
        default:
            return null;
    }
}

export default useMintProviderFactory;
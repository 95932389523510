/**
 * This hook is used to update local forge data from firestore
 * @param {object} query - The query to subscribe to
 * @param {function} postProcessFn - A function to run on the data before storing it in localforge
 */

import { useCallback, useEffect, useState } from "react";
import { useQuery } from "./FirestoreService";

const useFirestore = (query, postProcessFn, dataType = null, deps = null) => {
    const queryResult = useQuery(query);
    const [data, setData] = useState(null);

    const updateLocalStore = useCallback(async () => {
        if (queryResult) {
            let queryData = null;

            if (dataType === "doc") {
                queryData = await queryResult.data();
            } else {
                queryData = await queryResult.docs;
            }

            if (postProcessFn) {
                postProcessFn(queryData).then((data) => {
                    // console.log(data)
                    setData(data);
                });
            } else {
                setData(queryData);
            }
        }
    }, [queryResult])

    useEffect(() => {
        updateLocalStore();
    }, [updateLocalStore]);

    return data;
}

export default useFirestore;

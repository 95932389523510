import { SimpleGrid } from "@chakra-ui/react";
import LaunchPadCard from "./LaunchPadCard";
import FolderCard from "./FolderCard";
import { STACKED_TYPE } from "../utils/Constants";

const CollectionCardGroup = ({ cardConfigsObject, cardTypes, onClick }) => {
    return (
        <SimpleGrid columns={[1, 2, 3, 4]} className="upcomingCards">
            {
                cardConfigsObject &&
                Object.entries(cardConfigsObject).map(([projectName, collectionConfig]) => {
                    return (collectionConfig["standard"] === STACKED_TYPE && cardTypes === "marketplace" ?
                        <FolderCard
                            clickable={true}
                            key={projectName}
                            collectionConfig={collectionConfig}
                            cardCallback={onClick}
                        />
                        :
                        <LaunchPadCard
                            type={cardTypes}
                            key={projectName}
                            clickable={true}
                            projectConfig={collectionConfig}
                            cardCallback={onClick}
                        />
                    )
                })
            }
        </SimpleGrid >
    );
}

export default CollectionCardGroup;

import { createContext, useCallback, useEffect, useState } from "react";
import {
    MAINNET_CONFIG_KEY,
    MAINNET_NETWORK_ID,
    NETWORK_ID,
    TESTNET_CONFIG_KEY,
} from "../utils/Constants";
import useFirestore from "../Firestore/useFirestore";
import { collection, getDocs, query } from "firebase/firestore";
import { db, firestoreGetDocument } from "../Firestore/FirestoreService";
import { checkIfNullOrUndefined, tryLoadLocal, trySaveLocal } from "../utils/utils";

const IsokoCollectionsContext = createContext();

const IsokoCollectionsContextProvider = ({ children }) => {
    const [burnNftMap, setBurnNftMap] = useState({});
    const [floorMap, setFloorMap] = useState({});
    const collections = useFirestore(
        query(collection(db, "projects")),
        async (d) => {
            const temp = {};
            await Promise.all(
                d.map(async (project) => {
                    const projectData = project.data();
                    const projectName = projectData["project-name"];
                    const projectCollections = await getDocs(
                        collection(
                            db,
                            `projects/${projectName}/nft-collections`
                        )
                    );
                    projectCollections.docs.forEach((collection) => {
                        const collectionData = collection.data();
                        collectionData.projectName = projectName;
                        if (temp[projectName]) {
                            temp[projectName].push(collectionData);
                        } else {
                            temp[projectName] = [collectionData];
                        }
                    });
                })
            );
            trySaveLocal("collections", temp);
            return temp;
        },
        null
    );

    const getFloorAndBurnNfts = useCallback(async () => {
        if (!checkIfNullOrUndefined(collections) && Object.entries(collections).length > 0) {
            let floors = {};
            let burn = {};
            for (const [projectName, projects] of Object.entries(collections)) {
                for (const collectionConfig of projects) {
                    const collectionName = collectionConfig["policy-info"]["collection-name"];
                    if (collectionConfig["policy-info"] && !checkIfNullOrUndefined(collectionConfig["policy-info"]["burn-wallet"])) {
                        let burnWalletNfts = await firestoreGetDocument(`/users/${collectionConfig["policy-info"]["burn-wallet"]}`);

                        burn[collectionName] = burnWalletNfts[projectName][collectionName];
                    } else {
                        burn[collectionName] = [];
                    }
                }
            }
            setBurnNftMap(burn);
        }
    }, [collections]);

    useEffect(() => {
        getFloorAndBurnNfts();
    }, [getFloorAndBurnNfts]);

    function getParameterFromProjectConfig(
        projectName,
        collectionName,
        parameterName
    ) {
        let collectionConfig = getCollectionConfig(projectName, collectionName);

        return collectionConfig[parameterName];
    }

    function getParameterFromProjectConfigForChildCollection(
        projectName,
        collectionName,
        subCollection,
        parameterName
    ) {
        let collectionConfig = getCollectionConfig(projectName, collectionName);

        return collectionConfig["child-collection-map"][subCollection][parameterName];
    }

    function getParameterFromProjectConfigDirectly(
        collectionConfig,
        parameterName
    ) {
        return collectionConfig[parameterName];
    }

    function getNetworkSpecificParameterFromProjectConfig(
        projectName,
        collectionName,
        parameterName
    ) {
        let collectionConfig = getCollectionConfig(projectName, collectionName);

        if (NETWORK_ID === MAINNET_NETWORK_ID) {
            return collectionConfig[MAINNET_CONFIG_KEY][parameterName];
        }
        return collectionConfig[TESTNET_CONFIG_KEY][parameterName];
    }

    function getNetworkSpecificParameterFromProjectConfigDirectly(
        collectionConfig,
        parameterName
    ) {
        if (NETWORK_ID === MAINNET_NETWORK_ID) {
            return collectionConfig[MAINNET_CONFIG_KEY][parameterName];
        }
        return collectionConfig[TESTNET_CONFIG_KEY][parameterName];
    }

    function getCollectionConfig(projectName, collectionName) {
        let collectionList = collections;

        if (checkIfNullOrUndefined(collectionList) || Object.entries(collectionList).length === 0) {
            collectionList = tryLoadLocal("collections");
        }
        let specifiedCollection = collectionList[projectName]?.filter(
            (collection) => {
                return collection["policy-info"]["collection-name"] === collectionName;
            }
        );

        return specifiedCollection[0];
    }

    return (
        <IsokoCollectionsContext.Provider
            value={{
                collections: collections || tryLoadLocal("collections"),
                burnNftMap,
                getNetworkSpecificParameterFromProjectConfig,
                getNetworkSpecificParameterFromProjectConfigDirectly,
                getCollectionConfig,
                getParameterFromProjectConfig,
                getParameterFromProjectConfigForChildCollection,
                getParameterFromProjectConfigDirectly,
            }}
        >
            {collections && children}
        </IsokoCollectionsContext.Provider>
    );
};

export {
    IsokoCollectionsContextProvider,
    IsokoCollectionsContext
}
import { firestoreGetDocument, firestoreQueryCollection } from "../Firestore/FirestoreService";
import { getBlockHeightForChain } from "../pact/PactUtils";
import { COLLECTION_TRAITS_KEY, COLLECTION_TRAIT_DISTRIBUTION_KEY, NFT_SUPPLY_KEY } from "../utils/Constants";
import { buildNftPath } from "../utils/dbUtils";
import { checkIfNullOrUndefined } from "../utils/utils";

function useStackedCollectionProvider(collectionConfig) {
    const policy = collectionConfig["policy-info"];
    const projectName = policy["project-name"];
    const collectionName = policy["collection-name"];


    async function getNftListingData(nftId, payload) {
        let floor = null;
        let nftData = {};
        let nftMetadata = {};
        const mintProvider = payload["mintProvider"];
        const subCollection = payload["subCollectionId"];
        const getNftTraitStats = payload["getNftTraitStats"];
        const nftDataSnapshot = await firestoreGetDocument(buildNftPath(collectionConfig, nftId, subCollection));
        nftData["nft-id-on-chain"] = nftDataSnapshot["nft-id-on-chain"];

        nftMetadata = mintProvider.getNftData(nftDataSnapshot["nft-id-on-chain"]);
        nftData["name"] = nftMetadata["datum"]["datum"]["name"];
        nftData["rarity_level"] = nftMetadata["rarity_level"];
        nftData["nft-id"] = nftDataSnapshot["nft-id"]
        nftData["nft-uri"] = mintProvider.getNftImage(nftData["nft-id-on-chain"]);
        nftData["formatted_attributes"] = getNftTraitStats(nftMetadata["datum"]["datum"]["attributes"], mintProvider.getSubCollectionId(subCollection));
        nftData["chain-id"] = collectionConfig["policy-info"]["chain-id"];
        nftData["listing-details"] = nftDataSnapshot["listing-details"];
        nftData["status"] = nftDataSnapshot["status"];
        nftData["current-owner"] = nftDataSnapshot["current-owner"];
        nftData["price"] = nftDataSnapshot["listing-details"] && nftDataSnapshot["listing-details"]["price"];

        return nftData;
    }

    async function getCollectionFloor(subCollection) {
        let floor = null
        let currentHeight = await getBlockHeightForChain(policy["chain-id"])

        let docPath = `projects/${projectName}/nft-collections/${collectionName}/NFTs-${subCollection}`;

        // let conditions = ["listing-details.price", "!=", null, "&&", "listing-details.timeout", ">", currentHeight];
        let conditions = ["listing-details.price", "!=", null];
        floor = (await firestoreQueryCollection(docPath, "listing-details.price", conditions, 1))[0];

        if (!checkIfNullOrUndefined(floor)) {
            floor = floor["listing-details"]["price"];
        } else {
            floor = null;
        }

        return floor;
    }

    function getMarketplaceCollectionDetails(payload) {
        let collMetadata = {};
        let nftId = `${payload["subCollectionId"]}-${payload["nftId"]}`;
        let mintProvider = payload["mintProvider"];
        let subCollection = mintProvider.getSubCollectionId(nftId);
        let getParameterFromProjectConfigForChildCollection = payload["getParameterFromProjectConfigForChildCollection"];

        collMetadata["collection_name"] = getParameterFromProjectConfigForChildCollection(
            projectName,
            collectionName,
            subCollection,
            "name"
        );
        collMetadata["collection-name"] = getParameterFromProjectConfigForChildCollection(
            projectName,
            collectionName,
            subCollection,
            "name"
        );
        collMetadata["collection_trait_map"] = getParameterFromProjectConfigForChildCollection(
            projectName,
            collectionName,
            subCollection,
            COLLECTION_TRAIT_DISTRIBUTION_KEY
        );
        collMetadata["collection_traits"] = getParameterFromProjectConfigForChildCollection(
            projectName,
            collectionName,
            subCollection,
            COLLECTION_TRAITS_KEY
        )
        collectionConfig["total_supply"] = getParameterFromProjectConfigForChildCollection(
            projectName,
            collectionName,
            subCollection,
            NFT_SUPPLY_KEY
        )
        collectionConfig["collection_image"] = mintProvider.getNftImage(nftId);

        collMetadata = {
            ...collMetadata,
            ...collectionConfig
        }

        return collMetadata;
    }

    async function processUserNftMaps(nftMap, fullMap) {
        nftMap.forEach((entry) => {
            processUserNftMap(entry, fullMap)
        });

        return nftMap;
    }

    async function processUserNftMap(map, fullMap) {
        let nftCount = 0;
        for (const [user, nfts] of Object.entries(map)) {
            if (Object.values(nfts).length === 0) {
                continue;
            }
            let nftsNew = {};
            if (!fullMap[user]) {
                fullMap[user] = {};
            }
            fullMap[user] = {
                ...fullMap[user],
                ...nfts
            }
        }
        return fullMap;
    }

    function sortNfts(nftsList, currentHeight) {
        let unlistedNfts = null;
        let listedNfts = null;

        unlistedNfts = nftsList.filter((nft) => {
            return (
                checkIfNullOrUndefined(nft["listing-details"]) ||
                checkIfNullOrUndefined(nft["listing-details"]["price"]) ||
                (
                    !checkIfNullOrUndefined(nft["listing-details"]) &&
                    nft["listing-details"]["timeout"] < currentHeight
                )
            )
        });

        listedNfts = nftsList.filter((nft) => {
            return (
                !checkIfNullOrUndefined(nft["listing-details"]) &&
                !checkIfNullOrUndefined(nft["listing-details"]["price"]) &&
                nft["listing-details"]["timeout"] > currentHeight
            );
        });

        return {
            listed: listedNfts,
            unlisted: unlistedNfts
        }
    }

    return {
        sortNfts,
        getNftListingData,
        getCollectionFloor,
        processUserNftMaps,
        getMarketplaceCollectionDetails
    }
}

export default useStackedCollectionProvider;
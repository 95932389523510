import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ScreenSize from "../layouts/ScreenSize";
import { Box, Text, Flex, Image, Button } from "@chakra-ui/react";
import {
    BOXING_BADGER,
    BOXING_BADGER_PROJECT_NAME_KEY,
    ISLANDS_OF_DAMAYA,
    ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
    KADCARS_NFT,
    KADCARS_PROJECT_NAME_KEY,
    SECRET_GARDEN_OF_KADENA,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
} from "../utils/Constants";
// import heroImage from "../../src/assets/images/latest/homeLayer.svg";
// import heroImage from "/images/project_collection_thumbnails/kbadger.png";

const Hero = () => {
    const navigate = useNavigate();

    function handleClickGoToMint() {
        navigate(
            `/mint/${KADCARS_NFT}-${KADCARS_PROJECT_NAME_KEY}`
        );
    }

    return (
        <ScreenSize>
            <Flex
                className="latestDesign"
                mt="40px"
                flexDir={["column", null, "row"]}
                align="center"
                bgColor="#160D1A"
                border="1px"
                borderColor="#5E1E78"
                borderRadius="8px"
                gap="10px"
                justify={"space-between"}
                overflow={"hidden"}
            >
                <Flex
                    w={["100%", null, "50%"]}
                    align="center"
                    justify=""
                    px="20px"
                    py="20px"
                >
                    <Box maxW="600px" mx="auto">
                        <Text
                            fontSize={["32px", null, "66px"]}
                            fontWeight="900"
                            mb="44px"
                        >
                            Kadcars Minting Now!
                        </Text>

                        <Text fontSize="20px" fontWeight="400">
                            Kadcars leads the way with dynamic NFTs, and aims to change how we view NFTs.
                            Long gone are the days when NFTs were all about profile pictures.
                            Kadcars uses NFT technology to allow for upgradeable NFTs that integrate
                            seamlessly with games with the goal of creating an entire thriving ecosystem
                            on the Kadena Blockchain
                        </Text>
                        <Box mt="40px">
                            <Button
                                _hover={{}}
                                _focus={{}}
                                _active={{}}
                                fontSize={["12px", "14px", "16px"]}
                                fontWeight="900"
                                bgColor={"#BC3CF0"}
                                style={{ cursor: "pointer" }}
                                rounded="full"
                                onClick={handleClickGoToMint}
                            >
                                <span style={{ "color": "white" }}>
                                    MINT NOW
                                </span>
                            </Button>
                        </Box>
                    </Box>
                </Flex>
                <Flex w={["100%", null, "50%"]}>
                    <Image
                        src={"https://bafybeifr7rlslett3nd265afl6vceoiebbrnpywwx56ffhn6yigsjcsjru.ipfs.nftstorage.link/"}
                        alt="Hero"
                        h="100%"
                        w="100%"
                        objectFit="cover"
                    />
                </Flex>
            </Flex>
        </ScreenSize>
    );
};

export default Hero;

import { Badge, Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import DummyCar from "../../assets/images/dummyCar.svg";
import CollectionModal from "../ScrollablePopUp/CollectionModal";

const CollectionCard = ({ item }) => {
    const [isSelected, setIsSelected] = useState(item?.isSelected);
    return (
        <CollectionModal>
            <Box
                maxW="200px"
                border="1px"
                borderColor={isSelected ? "#BC3CF0" : "transparent"}
                borderRadius={"8px"}
                bg={isSelected ? "#BC3CF0" : "unset"}
                boxShadow={isSelected ? "0px 1px 8px #BC3CF040" : ""}
                cursor="pointer"
                onClick={() => {
                    setIsSelected(!isSelected);
                }}
                _hover={{ borderColor: "#ABD8ED" }}
            >
                <Box borderTopRadius={"8px"} overflow={"hidden"}>
                    <Image
                        src={DummyCar}
                        alt="Dummy car"
                        w="200px"
                        h="154px"
                        objectFit="cover"
                    />
                </Box>
                <Box p="8px">
                    <Flex>
                        <Text fontSize="14px" color="#EDEDED" fontWeight="700">
                            #3243
                        </Text>
                    </Flex>

                    <Box mt="8px">
                        <Text fontWeight="900" fontSize="14px">
                            143.45 KDA
                        </Text>
                    </Box>
                </Box>
            </Box>
        </CollectionModal>
    );
};

export default CollectionCard;

export const MarketPlaceItemCard = ({ item }) => {
    const [isSelected, setIsSelected] = useState(item?.isSelected);
    return (
        <CollectionModal>
            <Box
                maxW="200px"
                border="1px"
                borderColor={isSelected ? "#BC3CF0" : "transparent"}
                borderRadius={"8px"}
                bg={isSelected ? "#BC3CF0" : "unset"}
                boxShadow={isSelected ? "0px 1px 8px #BC3CF040" : ""}
                cursor="pointer"
                onClick={() => {
                    setIsSelected(!isSelected);
                }}
            >
                <Box borderTopRadius={"8px"} overflow={"hidden"}>
                    <Image
                        src={DummyCar}
                        alt="Dummy car"
                        w="200px"
                        h="154px"
                        objectFit="cover"
                    />
                </Box>
                <Box p="8px">
                    <Flex>
                        <Text fontSize="14px" color="#EDEDED" fontWeight="700">
                            #3243
                        </Text>
                    </Flex>

                    <Box mt="8px">
                        <Text fontWeight="900" fontSize="14px">
                            143.45 KDA
                        </Text>
                    </Box>
                </Box>
            </Box>
        </CollectionModal>
    );
};

export const MarketPlaceOwnedCard = ({ item }) => {
    const [isSelected, setIsSelected] = useState(item?.isSelected);
    return (
        <CollectionModal>
            <Box
                maxW="200px"
                border="1px"
                borderColor={isSelected ? "#BC3CF0" : "transparent"}
                borderRadius={"8px"}
                bg={isSelected ? "#BC3CF0" : "unset"}
                boxShadow={isSelected ? "0px 1px 8px #BC3CF040" : ""}
                cursor="pointer"
                onClick={() => {
                    setIsSelected(!isSelected);
                }}
            >
                <Box borderTopRadius={"8px"} overflow={"hidden"}>
                    <Image
                        src={DummyCar}
                        alt="Dummy car"
                        w="200px"
                        h="154px"
                        objectFit="cover"
                    />
                </Box>
                <Box p="8px">
                    <Flex>
                        <Text fontSize="14px" color="#EDEDED" fontWeight="700">
                            #3243
                        </Text>
                    </Flex>

                    <Box mt="8px">
                        <Text fontWeight="900" fontSize="14px">
                            Owned by you
                        </Text>
                    </Box>
                </Box>
            </Box>
        </CollectionModal>
    );
};

export const CollectionCardProfile = ({ item }) => {
    return (
        <CollectionModal>
            <Box
                maxW="200px"
                border="1px"
                borderColor={"transparent"}
                borderRadius={"8px"}
                bg={"unset"}
                cursor="pointer"
                _hover={{ borderColor: "#ABD8ED" }}
            >
                <Box borderTopRadius={"8px"} overflow={"hidden"} w="100%">
                    <Image
                        src={DummyCar}
                        alt="Dummy car"
                        w="200px"
                        h="154px"
                        objectFit="cover"
                    />
                </Box>
                <Box p="8px">
                    <Flex>
                        <Text fontSize="14px" color="#F5F5F5" fontWeight="900">
                            KADCARS
                        </Text>
                    </Flex>

                    <Flex gap="16px">
                        <Box>
                            <Text
                                fontSize="12px"
                                color="#C2C2C2"
                                fontWeight="700"
                            >
                                TOTAL OWNED
                            </Text>

                            <Text
                                fontSize="14px"
                                color="#F5F5F5"
                                fontWeight="700"
                            >
                                66
                            </Text>
                        </Box>

                        <Box>
                            <Text
                                fontSize="12px"
                                color="#C2C2C2"
                                fontWeight="700"
                                textAlign="right"
                            >
                                FLOOR
                            </Text>
                            <Text
                                fontSize="14px"
                                color="#F5F5F5"
                                textAlign="right"
                                fontWeight="700"
                            >
                                12 KDA
                            </Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </CollectionModal>
    );
};

export const ProfileOwnedItemCard = ({ item }) => {
    const [isSelected, setIsSelected] = useState(item?.isSelected);
    return (
        <CollectionModal>
            <Box
                maxW="200px"
                border="1px"
                borderColor={isSelected ? "#BC3CF0" : "transparent"}
                borderRadius={"8px"}
                bg={isSelected ? "#BC3CF0" : "unset"}
                boxShadow={isSelected ? "0px 1px 8px #BC3CF040" : ""}
                cursor="pointer"
                onClick={() => {
                    setIsSelected(!isSelected);
                }}
            >
                <Box borderTopRadius={"8px"} overflow={"hidden"}>
                    <Image
                        src={DummyCar}
                        alt="Dummy car"
                        w="200px"
                        h="154px"
                        objectFit="cover"
                    />
                </Box>
                <Box p="8px">
                    <Flex>
                        <Text fontSize="14px" color="#EDEDED" fontWeight="700">
                            #3243
                        </Text>
                    </Flex>

                    <Box mt="8px">
                        <Text fontWeight="900" fontSize="14px">
                            Unlisted
                        </Text>
                    </Box>
                </Box>
            </Box>
        </CollectionModal>
    );
};

export const LunchPadCollectionCard = ({ item }) => {
    return (
        <CollectionModal>
            <Box
                maxW="200px"
                border="1px"
                borderColor={"transparent"}
                borderRadius={"8px"}
                bg={"unset"}
                cursor="pointer"
                _hover={{ borderColor: "#ABD8ED" }}
                className="latestDesign"
                overflow="hidden"
            >
                <Box borderTopRadius={"8px"} overflow={"hidden"} w="100%">
                    <Image
                        src={DummyCar}
                        alt="Dummy car"
                        w="100%"
                        h="154px"
                        objectFit="cover"
                    />
                </Box>
                <Box p="8px">
                    <Flex align="center" justify="space-between" gap="4px">
                        <Text
                            fontSize="14px"
                            color="#F5F5F5"
                            fontWeight="900"
                            noOfLines={1}
                        >
                            KADCARS
                        </Text>

                        {!true ? (
                            <Badge
                                fontSize="12px"
                                fontWeight="500"
                                px="12px"
                                py="2px"
                                border="1px"
                                borderColor={"#BD3DF04D"}
                                color="#301903"
                                rounded="32px"
                                bgColor={"#F1A35B"}
                                h="auto"
                                textTransform={"unset"}
                                lineHeight={"16px"}
                            >
                                Coming soon
                            </Badge>
                        ) : (
                            <Badge
                                fontSize="12px"
                                fontWeight="500"
                                px="12px"
                                py="2px"
                                border="1px"
                                borderColor={"#BD3DF04D"}
                                color="#004121"
                                rounded="32px"
                                bgColor={"#26DF85"}
                                h="auto"
                                textTransform={"unset"}
                                lineHeight={"16px"}
                            >
                                Mint now
                            </Badge>
                        )}
                    </Flex>

                    <Flex gap="10px" mt="8px">
                        <Box w="100%">
                            <Text
                                fontSize="12px"
                                color="#C2C2C2"
                                fontWeight="700"
                            >
                                TOTAL ITEMS
                            </Text>

                            <Text
                                fontSize="14px"
                                color="#F5F5F5"
                                fontWeight="700"
                                mt="4px"
                            >
                                2400
                            </Text>
                        </Box>

                        <Box w="100%">
                            <Text
                                fontSize="12px"
                                color="#C2C2C2"
                                fontWeight="700"
                                textAlign="right"
                            >
                                PRICE
                            </Text>
                            <Text
                                fontSize="14px"
                                color="#F5F5F5"
                                textAlign="right"
                                fontWeight="700"
                            >
                                12 KDA
                            </Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </CollectionModal>
    );
};

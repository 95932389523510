import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { BsCheckCircleFill, BsChevronLeft } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbSettingsFilled } from "react-icons/tb";

const TaskOpener = ({ handleShowTask, showTask }) => {
    return (
        <Box>
            <Flex
                cursor="pointer"
                onClick={handleShowTask}
                w="100%"
                bgColor="#39353B"
                maxW="210px"
                pos="fixed"
                right="0px"
                bottom={"100px"}
                p="12px"
                roundedTopLeft={"8px"}
                roundedBottomLeft={"8px"}
                gap="16px"
                align="center"
            >
                <Box>
                    <Icon as={BsChevronLeft} boxSize="24px" />
                </Box>

                <Flex align="center" gap="12px">
                    <Flex className="settingsIcon" align="center" gap="4px">
                        <Icon as={TbSettingsFilled} boxSize="26px" />
                        <Text fontSize="14px" fontWeight={"700"}>
                            12
                        </Text>
                    </Flex>

                    <Flex className="successIcon" align="center" gap="4px">
                        <Icon as={BsCheckCircleFill} boxSize="20px" />
                        <Text fontSize="14px" fontWeight={"700"}>
                            1
                        </Text>
                    </Flex>

                    <Flex className="failedIcon" align="center" gap="4px">
                        <Icon as={RiCloseCircleFill} boxSize="24px" />
                        <Text fontSize="14px" fontWeight={"700"}>
                            2
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default TaskOpener;

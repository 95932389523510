import React, { useContext, useState } from "react";
import {
    Menu,
    MenuButton,
    MenuList,
    Flex,
    Text,
    Box,
    Icon,
    useDisclosure,
} from "@chakra-ui/react";

import { FaArrowDown, FaCaretDown, FaEye, FaEyeSlash } from "react-icons/fa";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { PactContext } from "../pact/PactContextProvider";
import { tryLoadLocal, trySaveLocal } from "../utils/utils";
import { SHOW_TOTAL_BALANCE } from "../utils/Constants";

const Balance = ({ chainMapData, totalBalance }) => {
    const [showBalance, setShowBalance] = useState(() =>
        tryLoadLocal(SHOW_TOTAL_BALANCE)
    );
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { account } = useContext(PactContext);

    const handleShowBalance = () => {
        trySaveLocal(SHOW_TOTAL_BALANCE, !showBalance);
        setShowBalance(!showBalance);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Box position="relative">
            <Flex
                align="center"
                gap="4px"
                marginBottom="-4px"
                borderBottom="4px"
                borderBottomColor={isModalOpen ? "#5E1E78" : "transparent"}
            >
                <Text fontWeight={"700"}>Balance: </Text>{" "}
                <Text
                    marginLeft={"10px"}
                    marginRight={"5px"}
                    className={showBalance ? "" : "blur_text"}
                    fontWeight={"700"}
                >
                    {" "}
                    {totalBalance ? totalBalance : 0.0} KDA{" "}
                </Text>
                <Box>
                    <Icon
                        as={showBalance ? FaEyeSlash : FaEye}
                        cursor="pointer"
                        onClick={() => {
                            handleShowBalance();

                            setIsModalOpen(false);
                        }}
                    />
                </Box>
            </Flex>
            {showBalance && (
                <Box
                    position="absolute"
                    bottom=""
                    className="centerPos"
                    w="100%"
                >
                    <Menu
                        minW="164px"
                        closeOnBlur={true}
                        onClose={handleModalClose}
                    >
                        <MenuButton
                            alignItems="center"
                            as={Flex}
                            _hover={{}}
                            _focus={{}}
                            _active={{}}
                            onClick={() => {
                                setIsModalOpen(!isModalOpen);
                                onOpen();
                            }}
                            cursor="pointer"
                        >
                            {showBalance && (
                                <Flex
                                    align="center"
                                    justify="center"
                                    cursor="pointer"
                                    onClick={() => {
                                        setIsModalOpen(!isModalOpen);
                                        onOpen();
                                    }}
                                >
                                    {!isModalOpen ? (
                                        <BsChevronDown />
                                    ) : (
                                        <BsChevronUp />
                                    )}
                                    {/* <BsChevronDown /> */}
                                </Flex>
                            )}
                        </MenuButton>

                        <MenuList
                            p="0px"
                            maxW="164px"
                            w="100%"
                            top="0px"
                            boxShadow="none"
                            border="none"
                            outline="none"
                            bgColor="transparent"
                        >
                            {account ? (
                                <Box
                                    maxW="164px"
                                    bgColor="#0a0a0a"
                                    px="10px"
                                    py="15px"
                                >
                                    {chainMapData &&
                                        chainMapData.map(
                                            (chainAccountData, index) => {
                                                return (
                                                    <div key={index}>
                                                        {chainAccountData
                                                            .accountData
                                                            .balance > 0 && (
                                                            <BalanceRow
                                                                chainId={
                                                                    chainAccountData.chain
                                                                }
                                                                balance={
                                                                    chainAccountData
                                                                        .accountData
                                                                        .balance
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                </Box>
                            ) : (
                                <Box>
                                    <Text>Connect a Wallet</Text>
                                </Box>
                            )}
                        </MenuList>
                    </Menu>
                </Box>
            )}
        </Box>
    );
};

export default Balance;

const BalanceRow = ({ chainId, balance }) => {
    return (
        <Flex maxW={["100%", "164px"]} mb="4px">
            <Text fontSize="14px">Chain {chainId}:</Text>
            <Text
                fontSize="14px"
                textAlign="right"
                flex="1"
                alignContent={"flex-end"}
            >
                {" "}
                {balance?.toFixed(2)} KDA{" "}
            </Text>
        </Flex>
    );
};

import { useContext } from "react";
import Pact from "pact-lang-api";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import { PactContext } from "../pact/PactContextProvider";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE, ISOKO_CONTRACTS, ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS, ISOKO_NAMESPACE, ISOKO_TEST_STACKED, ISOKO_TEST_STACKED_COLLECTION_NAME_KEY, LOCAL_ACCOUNT_KEY, MAINNET_NETWORK_ID, MARMALADE_TYPE, MINT_PHASES_KEY, NETWORK_ID, STACKED_TYPE } from "../utils/Constants";
import { sgk_weapons_metadata, weapons_md_schema, weapons_rarity_map } from "../project_config_files/weapons_map";
import { checkIfNullOrUndefined, tryLoadLocal } from "../utils/utils";
import { createUniqueTransferCapabilities } from "../pact/PactUtils";

const ISOKO_TEST_STACKED_COLLECTION_CONTRACTS = "n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-battle-heroes-policy";
const ISOKO_TEST_STACKED_COLLECTION_TESTNET_CONTRACTS = "free.sgk-battle-heros-policy";
const ISOKO_TEST_STACKED_COLLECTION_CREATOR_WALLET = "k:04e21d9dbe39517574a3772de910d606baa00a84359ba6c468206c76695650d4"; //TODO CHANGE THISS
const ISOKO_TEST_STACKED_COLLECTION_TESTNET_CREATOR_WALLET = "k:04e21d9dbe39517574a3772de910d606baa00a84359ba6c468206c76695650d4";
const ISOKO_TEST_STACKED_COLLECTION_ADMIN_ADDRESS = "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const ISOKO_TEST_STACKED_COLLECTION_FEE_PERCENTAGE = 0.03;

const ISOKO_TEST_STACKED_COLLECTION_COLLECTION_NAME = "sgk-battle-heroes";
const ISOKO_TEST_STACKED_COLLECTION_TESTNET_COLLECTION_NAME = "sgk-battle-heroes-nft";

function useIsokoStackedCollectionProvider() {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const { getNetworkSpecificParameterFromProjectConfig, getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);

    /**
     * 
     ******************************************
     ********* Marketplace Functions **********
     ******************************************
     *
     */
    async function mint(amount, priceToPay, transactionCompleteCallback = null) {
        let priceToPayContract = 0.97 * priceToPay;
        let chainId = getPolicyInfo()["chain-id"];
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let priceToPayLPFee = getTransactionFee(priceToPay);
        let totalPrice = priceToPay;
        let cantMint = await checkIfUserCantMint(pactContext.account.account, amount);
        console.log(totalPrice)
        console.log(priceToPayLPFee);
        console.log(cantMint);
        if (cantMint) {
            return;
        }

        let pactCode = `(${ISOKO_CONTRACTS}.bulk-mint-weapons-test
            "${pactContext.account.account}" 
            ${amount} 
            (read-keyset "user-ks"))`;
        let pactCodeToTransferFunds = `(coin.transfer "${pactContext.account.account}" 
            "${ISOKO_TEST_STACKED_COLLECTION_CREATOR_WALLET}" ${parseFloat(priceToPayLPFee).toFixed(8)})`;
        pactCode = pactCode + pactCodeToTransferFunds;

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
            purpose: "mint"
        };

        const capDataList = [
            {
                role: "Launchpad fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_TEST_STACKED_COLLECTION_CREATOR_WALLET,
                amount: { decimal: parseFloat(priceToPayLPFee.toFixed(8)) }, //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
                // amount: getPactDecimal(parseFloat(priceToPayLPFee)), //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
            },
            {
                role: "Project mint fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
                amount: { decimal: parseFloat(priceToPayContract.toFixed(8)) },
                // amount: getPactDecimal(priceToPayContract),
            },
        ];
        let caps = createUniqueTransferCapabilities(capDataList);
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(Pact.lang.mkCap(
            "Bulk Rewards Cap",
            "Cap for Bulk Rewards",
            `${ISOKO_NAMESPACE}.isoko-orchestrator.MINT-BULK-REWARDS`,
            [pactContext.account.account, { int: amount }]
        )
        )

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = (
            <p>
                Recruiting {amount} Gnomes to Explore Kadena's Secret Garden for{" "}
                {parseFloat(totalPrice.toFixed(4))} KDA
            </p>
        );

        //For quickbuy
        let signers = [
            {
                publicKey: accountDetails.accountGuard.keys[0],
                clist: caps.map((cap) => {
                    return cap.cap;
                }),
            },
        ];
        let meta = Pact.lang.mkMeta(
            pactContext.account.account,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200
        );
        let bzCommand = JSON.parse(
            Pact.simple.exec.createCommand(
                signers,
                (Date.now() / 1000).toString(),
                pactCode,
                envData,
                meta,
                NETWORK_ID
            ).cmds[0]["cmd"]
        );
        let cmdPubKeyObj = {
            cmd: bzCommand,
            pubKey: accountDetails.accountGuard.keys[0],
        };

        let txData = {
            cmdType: "exec",
            cmd: cmd,
            bzCommand: cmdPubKeyObj,
            previewContent: previewContent,
            transactionMessage: `Buying ${amount} Weapons
                for ${parseFloat(
                totalPrice.toFixed(4)
            )} KDA`,
            callback: transactionCompleteCallback,
            errorHandler: transactionCompleteCallback,
            errorMessage: "",
            shouldSign: true,
        };

        quickBuy.shouldExecuteQuickBuy(txData, totalPrice, chainId);
    }

    /**
     * 
     **************************************************
     ********* Collection Provider Functions **********
     **************************************************
     *
     */
    function getTransactionFee(price) {
        let txFee = parseFloat((price * ISOKO_TEST_STACKED_COLLECTION_FEE_PERCENTAGE).toFixed(8));
        return txFee;
    }

    function getPolicyInfo() {
        let policy = {
            "standard": STACKED_TYPE,
            "project-name": ISOKO_TEST_STACKED,
            "collection-name": ISOKO_TEST_STACKED_COLLECTION_NAME_KEY,
            "policy-name": ISOKO_TEST_STACKED_COLLECTION_CONTRACTS,
            "creator-wallet": ISOKO_TEST_STACKED_COLLECTION_CREATOR_WALLET,
            "chain-id": "8",
            "nft-metadata": weapons_md_schema
        };

        return policy;
    }

    async function checkIfUserCantMint(account, amount) {
        return false;
    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            ISOKO_TEST_STACKED,
            ISOKO_TEST_STACKED_COLLECTION_NAME_KEY,
            MINT_PHASES_KEY
        );

        return mintPhases;
    }

    function getNftImage(nftId) {
        let nftCollection = nftId.split(":")[0];
        let nftGearRarity = weapons_rarity_map[nftCollection.split("-")[4]];
        let nftGearTypeData = sgk_weapons_metadata[nftCollection.split("-")[3]];
        let md = getBaseMd();
        let image = `${md["uri"]["data"]}${nftGearTypeData["type"]}/${nftGearTypeData["type"]}-${nftGearRarity}.png`

        return image;
    }

    async function getNextPrice(amount, accountWlData = null) {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            ISOKO_TEST_STACKED,
            ISOKO_TEST_STACKED_COLLECTION_NAME_KEY,
            MINT_PHASES_KEY
        );
        let currentTime = Date.now();
        let publicSaleWindow = new Date(
            mintPhases["public_sale"]["start_time"]
        );
        let price = 3;
        return price;
    }

    async function getMintedTotal() {
        let chainId = getPolicyInfo()["chain-id"];
        let mintedTotal = 0;
        mintedTotal += await queryBlockChainForData(`(free.collection-data-utility.get-public-minted-supply-test)`, chainId, null, 1000000000)

        return mintedTotal;
    }

    function getAllNftsMetadata() {
        return weapons_md_schema;
    }

    function getNftData(nftId) {
        let imageUri = getNftImage(nftId);
        let nftNum = nftId.split(":")[1];
        let nftCollection = nftId.split(":")[0];

        let nftGearRarity = weapons_rarity_map[nftCollection.split("-")[4]];
        let nftGearTypeData = sgk_weapons_metadata[nftCollection.split("-")[3]];
        let md = getBaseMd();

        md["uri"]["data"] = imageUri;
        md["datum"]["datum"]["name"] = `${nftGearTypeData["name"]} #${nftNum}`;
        md["datum"]["datum"]["attributes"][0]["value"] = nftGearTypeData["name"];
        md["datum"]["datum"]["attributes"][1]["value"] = nftGearRarity;

        return md;
    }

    function getBaseMd() {
        let md = {
            "uri": {
                "scheme": "https",
                "data": "https://sgk-battle-heroes-weapons.nyc3.cdn.digitaloceanspaces.com/"
            },
            "datum": {
                "datum": {
                    "name": "",
                    "attributes": [
                        {
                            "trait_type": "Name",
                            "value": "",
                            "score": 1.0
                        },
                        { "trait_type": "Level", "value": "common" }
                    ],
                    "id": "1"
                },
                "uri": { "scheme": "pact:schema", "data": "nft-datum" }
            },
            "rarity_score": 1.0,
            "rarity_level": "COMMON"
        }

        return md;
    }

    function getSubCollectionId(nftPrefix) {
        return nftPrefix.split("-")[3];
    }

    // async function getNftsForUser(account) {
    //     let chainId = getPolicyInfo()["chain-id"];

    //     let userNfts = [];
    //     for (let i = 1; i <= 32; i++) {
    //         // if (i === 20) {
    //             let nfts = await queryBlockChainForData(`(free.test-weapons-${i}-1.get-nfts-by-owner "${account.account}")`, chainId)
    //             if (!checkIfNullOrUndefined(nfts) && nfts.length > 0) {
    //                 userNfts = userNfts.concat(nfts);
    //             }
    //         // }
    //     }
    //     console.log(userNfts)
    //     let formattedUserNftIds = userNfts.map((nft) => {
    //         let obj = {
    //             nftId: nft["token-id"],
    //         };
    //         return obj;
    //     });

    //     return formattedUserNftIds;
    // }
    async function getNftsForUser(account) {
        let chainId = getPolicyInfo()["chain-id"];
        let userNfts = [];
        let userNftsLocal = await queryBlockChainForData(
            `(free.collection-data-utility.get-nfts-by-owner-test "${account.account}")`,
            chainId,
            null,
            1000000000
        )
        userNftsLocal.forEach((nftGroup) => {
            userNfts = userNfts.concat(nftGroup);
        });
        let formattedUserNftIds = userNfts.map((nft) => {
            let obj = {
                nftId: nft["token-id"],
            };
            return obj;
        });
        console.log(formattedUserNftIds)

        return formattedUserNftIds;
    }

    async function getPhaseParticipationRequirement(account, phase) {
        return "No Special Requirements";;
    }

    async function getAccountWhitelistData(account) {
        return null;
    }

    function getTotalSupply(subCollection) {

    }

    return {
        mint,
        getNextPrice,
        getPolicyInfo,
        getAllNftsMetadata,
        getNftsForUser,
        getAccountWhitelistData,
        getPhaseParticipationRequirement,
        getNftImage,
        getMintedTotal,
        getNftData,
        getSubCollectionId
    };
}

export {
    useIsokoStackedCollectionProvider
}
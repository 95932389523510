import { useContext } from "react";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { PactContext } from "../pact/PactContextProvider";
import { BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, CHAIN_ID_KEY, COLLECTION_HAS_MARKETPLACE, COLLECTION_NAME_KEY, COLLECTION_TRAITS_KEY, COLLECTION_TRAIT_DISTRIBUTION_KEY, DEFAULT_GAS_LIMIT, DEFAULT_GAS_LIMIT_X_CHAIN_EXEC, DEFAULT_GAS_PRICE, FEE_PERCENTAGE, GAS_STATION, ISOKO_ADMIN_ADDRESS, ISOKO_CONTRACTS, ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS, LOCAL_ACCOUNT_KEY, MAINNET_NETWORK_ID, MARMALADE_TYPE, MINT_AMOUNT_LIMIT_KEY, MINT_PHASES_KEY, NETWORK_ID, NEW_ADMIN_ADDRESS, NFT_SUPPLY_KEY, TESTNET_NETWORK_ID } from "../utils/Constants";
import { checkIfFundsOnChainAreSufficient, tryLoadLocal } from "../utils/utils";
import Pact from "pact-lang-api";
import { createUniqueTransferCapabilities } from "../pact/PactUtils";
import { boxingBadgerMetadata } from "../project_config_files/boxing_badger_metadata";
import { toast } from "react-toastify";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";

const BOXING_BADGER_CONTRACTS = "free.boxing-badger-policy";
const BOXING_BADGER_TESTNET_CONTRACTS = "free.boxing-badgers-policy-v2";
const BOXING_BADGER_BASE_URL = "https://boxingbadger.nyc3.cdn.digitaloceanspaces.com/collections/681445606/manifest.json";
const BOXING_BADGER_CREATOR_WALLET = "k:bb7d6f4a96d5bd93b4e1655ddb2bdc835d60bf155ff8243711a02889902d3a0d";
const BOXING_BADGER_TESTNET_CREATOR_WALLET = "k:04e21d9dbe39517574a3772de910d606baa00a84359ba6c468206c76695650d4";
const BOXING_BADGER_ADMIN_ADDRESS = "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const BOXING_BADGER_FEE_PERCENTAGE = 0.05;
const BOXING_BADGER_THUMBNAIL_IMAGE_KEY = "thumbnailUrl";
const BOXING_BADGER_COLLECTION_NAME = "boxing-badger";
const BOXING_BADGER_COLLECTION_NAME_TESTNET = "boxing-badger-v2";

function useBoxingBadgerProvider() {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const { getNetworkSpecificParameterFromProjectConfig, getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);


    /**
     * 
     ******************************************
     ********* Marketplace Functions **********
     ******************************************
     *
     */
    async function mint(amount, priceToPay, transactionCompleteCallback = null) {
        let priceToPayContract = 0.95 * priceToPay;
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let priceToPayLPFee = getTransactionFee(priceToPay);
        let totalPrice = priceToPay;

        let cantMint = await checkIfUserCantMint(pactContext.account.account);
        console.log(pactContext.account.account)
        console.log(cantMint)
        if (cantMint) {
            toast.error("Mint Window not Open for this Account");
            return;
        }

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk ${amount} "${BOXING_BADGER_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk ${amount} "${BOXING_BADGER_COLLECTION_NAME_TESTNET}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        }
        let pactCodeToTransferFunds = `(coin.transfer "${pactContext.account.account}" "${ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS}" ${parseFloat(priceToPayLPFee)})`;
        pactCode = pactCode + pactCodeToTransferFunds;

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        const capDataList = [
            {
                role: "Launchpad fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
                amount: parseFloat(priceToPayLPFee) //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
            },
            {
                role: "Project mint fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: NETWORK_ID === MAINNET_NETWORK_ID ?
                    BOXING_BADGER_CREATOR_WALLET :
                    BOXING_BADGER_TESTNET_CREATOR_WALLET,
                amount: priceToPayContract.toFixed(8)
            },
        ]
        let caps = createUniqueTransferCapabilities(capDataList);
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Account Guard",
                "Account Guard Capability",
                `${NETWORK_ID === MAINNET_NETWORK_ID ?
                    BOXING_BADGER_CONTRACTS :
                    BOXING_BADGER_TESTNET_CONTRACTS}.ACCOUNT-GUARD`,
                [accountDetails.account]
            )
        );

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = (
            <p>Training {amount} Badger to box for {parseFloat((totalPrice).toFixed(4))} KDA</p>
        );

        //For quickbuy
        let signers = [{
            publicKey: accountDetails.accountGuard.keys[0],
            clist: caps.map((cap) => {
                return cap.cap
            })
        }];
        let meta = Pact.lang.mkMeta(
            pactContext.account.account,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200,
        );
        let bzCommand = JSON.parse(Pact.simple.exec.createCommand(signers, (Date.now() / 1000).toString(), pactCode, envData, meta, NETWORK_ID).cmds[0]["cmd"]);
        let cmdPubKeyObj = {
            cmd: bzCommand,
            pubKey: accountDetails.accountGuard.keys[0]
        }

        let txData = {
            cmdType: "exec",
            cmd: cmd,
            bzCommand: cmdPubKeyObj,
            previewContent: previewContent,
            transactionMessage: `Training ${amount} Badger${amount > 1 ? "s" : ""} to box for ${parseFloat(totalPrice.toFixed(4))} KDA`,
            callback: transactionCompleteCallback,
            errorHandler: transactionCompleteCallback,
            errorMessage: "",
            shouldSign: true
        };

        quickBuy.shouldExecuteQuickBuy(txData, totalPrice, chainId);
    }

    async function mintBulk(amount) {
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 100 "${BOXING_BADGER_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 100 "${BOXING_BADGER_COLLECTION_NAME_TESTNET}" "${pactContext.account.account}" (read-keyset "user-ks"))`
        }

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };


        let caps = [];
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(Pact.lang.mkCap("reserved", "reserved",
            "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-orchestrator.ADMIN-OR-COL-OWNER", [BOXING_BADGER_COLLECTION_NAME]))

        const previewContent = (
            <p>Training {amount} Badger to box for  KDA</p>
        );

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        pactContext.sendTransaction(
            cmd,
            previewContent,
            "",
            null,
            null,
            null,
            true
        );
    }

    /**
     * 
     **************************************************
     ********* Collection Provider Functions **********
     **************************************************
     *
     */
    async function checkIfUserCantMint(account) {
        let cantMint = false;
        let isInPrivateWhitelist = getIsInPrivateWhitelist();
        let isInPublicWindow = getIsInPublicWindow();
        let accountHasWhitelist = false;
        console.log(isInPublicWindow)
        console.log(isInPrivateWhitelist)
        let accountWlData = await getAccountWhitelistData(account);
        console.log(accountWlData)
        console.log(account)
        if (accountWlData.role === "private-wl-role") {
            accountHasWhitelist = true;
        }

        if (isInPrivateWhitelist) {
            cantMint = true;
            if (accountHasWhitelist) {
                return false;
            }
        } else if (isInPublicWindow) {
            console.log("public")
            return false;
        } else {
            return true;
        }

        return cantMint;
    }

    function getIsInPrivateWhitelist() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();

        if (currentTime > Date.parse(mintPhases["private_wl"]["start_time"]) &&
            currentTime < Date.parse(mintPhases["private_wl"]["end_time"])) {
            return true;
        }
        return false;
    }

    function getIsInPublicWindow() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();
        console.log(mintPhases)
        if (currentTime > Date.parse(mintPhases["public_sale"]["start_time"])) {
            return true;
        }
        return false;
    }

    function getPolicyInfo() {
        let policy = {
            "standard": MARMALADE_TYPE,
            "project-name": BOXING_BADGER,
            "collection-name": BOXING_BADGER_PROJECT_NAME_KEY,
            "policy-name": BOXING_BADGER_CONTRACTS,
            "creator-wallet": BOXING_BADGER_CREATOR_WALLET,
            "chain-id": getChain(),
            "nft-metadata": boxingBadgerMetadata
        };

        return policy;
    }

    function getChain() {
        let chainId = getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, CHAIN_ID_KEY);

        return chainId;
    }

    function getTransactionFee(price) {
        let txFee = parseFloat((price * BOXING_BADGER_FEE_PERCENTAGE).toFixed(8));
        return txFee;
    }

    function getRoyaltyFeeFromPrice(price) {

    }

    const getTotalSupply = () => {
        let totalSupply = getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, NFT_SUPPLY_KEY);

        return totalSupply;
    }


    function getBestOffer() {

    }

    async function getMintedTotal() {
        let pactCode = `(${BOXING_BADGER_CONTRACTS}.get-minted-supply)`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${BOXING_BADGER_TESTNET_CONTRACTS}.get-minted-supply)`;
        }

        let chainId = getChain();

        let mintedTotal = await queryBlockChainForData(pactCode, chainId);

        return mintedTotal;
    }

    function getNftAmountLimit() {
        return getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, MINT_AMOUNT_LIMIT_KEY);
    }

    function getNonMintedTokens() {

    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, MINT_PHASES_KEY);

        return mintPhases;
    }

    async function getNftsForUser(account) {
        let chainId = getChain();

        let pactCode = `(${BOXING_BADGER_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${BOXING_BADGER_TESTNET_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        }

        let userNfts = await queryBlockChainForData(pactCode, chainId);
        let formattedUserNftIds = userNfts.map((nft) => {
            let obj = {
                nftId: nft["token-id"].split("#")[1]
            }
            return obj
        })

        return formattedUserNftIds;
    }

    function getNextPrice() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, MINT_PHASES_KEY);
        let currentTime = Date.now();
        let publicSaleWindow = new Date(mintPhases["public_sale"]["start_time"]);
        let price = 0;

        if (currentTime < publicSaleWindow) {
            price = NETWORK_ID === MAINNET_NETWORK_ID ? 12.5 : 1.25;
            return price;
        } else {
            price = NETWORK_ID === MAINNET_NETWORK_ID ? 17.5 : 1.75;
            return price;
        }
    }

    function getCollectionName() {
        let collectionName = getParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, COLLECTION_NAME_KEY);

        return collectionName;
    }

    function getNftThumbnailImage(nftId) {
        let image = boxingBadgerMetadata[nftId]["datum"]["datum"][BOXING_BADGER_THUMBNAIL_IMAGE_KEY];
        return image;
    }

    function getNftImage(nftId) {
        let image = boxingBadgerMetadata[nftId]["uri"]["data"];

        return image;
    }

    function getAllNftsMetadata() {
        let fullCollectionMetadata = boxingBadgerMetadata;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            fullCollectionMetadata = boxingBadgerMetadata;
        }

        return fullCollectionMetadata;
    }

    async function getPhaseParticipationRequirement(account, phase) {
        let requirementDescription = "";
        let whitelistInfo = null;

        if (phase === "public_sale") {
            requirementDescription = "No Special Requirements";
            return requirementDescription
        } else if (phase === "private_wl") {
            requirementDescription = "Whitelist Required";
        }

        if (account) {
            whitelistInfo = await getAccountWhitelistData(account);
        } else {
            return requirementDescription
        }

        if (phase === "private_wl") {
            if (whitelistInfo["role"] === "private-wl-role") {
                requirementDescription = "Account is Whitelisted!";
            } else {
                requirementDescription = "Account is Not Whitelisted";
            }
        }

        return requirementDescription;
    }

    async function getAccountWhitelistData(account) {
        let chainId = getChain();
        let pactCode = `(${BOXING_BADGER_CONTRACTS}.get-account-whitelist-info "${account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${BOXING_BADGER_TESTNET_CONTRACTS}.get-account-whitelist-info "${account}")`;
        }

        let response = await queryBlockChainForData(pactCode, chainId);

        return response;
    }

    function getNftData(nftId) {
        let fullMetadataCopy = { ...boxingBadgerMetadata };
        let nftData = fullMetadataCopy[nftId];
        console.log(nftData)
        return nftData;
    }

    function getCollectionTraits() {
        let traitsList = getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, COLLECTION_TRAITS_KEY);

        return traitsList;
    }

    function formatNftTraits(nftTraits) {
        let formattedTraits = {}
        let traitsList = getCollectionTraits();

        nftTraits.forEach((traitObject) => {
            formattedTraits[traitObject["trait_type"]] = traitObject["value"];
        });

        return formattedTraits;
    }

    function formatNftTraitsInNftDataObject(nftData) {
        let formattedTraits = {};
        let formattedSkills = {};
        let traitsList = getCollectionTraits();
        let collectionSkills = getNetworkSpecificParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, "collection_skills");
        let nftTraits = nftData["datum"]["datum"]["attributes"];
        let nftSkills = nftData["datum"]["datum"]["skills"];
        let nftScore = nftData["datum"]["datum"]["rarity_score"];
        console.log(nftData)

        nftTraits.forEach((traitObject) => {
            let traitFrequency = getTraitDistribution(traitObject["trait_type"], traitObject["value"]);

            formattedTraits[traitObject["trait_type"]] = {
                name: traitObject["value"],
                frequency: traitFrequency
            };
        });

        nftSkills.forEach((skillObject) => {
            let skillFrequency = getSkillDistribution(skillObject["id"], skillObject["name"]);
            console.log(skillObject)
            formattedSkills[skillObject["name"]] = {
                name: skillObject["name"],
                frequency: skillFrequency
            };
        })

        let formattedNftData = {
            id: nftData["datum"]["datum"]["id"],
            name: nftData["datum"]["datum"]["name"],
            attributes: formattedTraits,
            // image: nftData["datum"]["datum"]["thumbnailUrl"],
            image: nftData["uri"]["data"],
            rarity_rank: "COMMON",
            rarity_level: nftData["datum"]["datum"]["rarity_level"],
            rarity_score: (nftScore).toFixed(3),
            collection_traits: getCollectionTraits(),
            extras: {
                collection_skills: collectionSkills,
                skills: formattedSkills,
                description: nftData["datum"]["datum"]["description"],
            }
        }

        return formattedNftData;
    }

    function formatNftDataForNftPopup(nftId) {
        let data = getNftData(nftId);
        let formattedData = formatNftTraitsInNftDataObject(data);

        return formattedData;
    }

    function getTraitDistribution(trait, traitValue) {
        let traitCountMap = getParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, COLLECTION_TRAIT_DISTRIBUTION_KEY);
        let totalSupply = getTotalSupply();
        // let traitFrequency = (traitCountMap[trait][traitValue] / traitCountMap[trait]["total"] * 100.0).toFixed(2);
        let traitFrequency = (traitCountMap[trait][traitValue] / totalSupply * 100.0).toFixed(2);

        return traitFrequency;
    }

    function getSkillDistribution(skill, skillValue) {
        let skillCountMap = getParameterFromProjectConfig(BOXING_BADGER, BOXING_BADGER_PROJECT_NAME_KEY, "skill_distribution");
        let skillTotal = 0;
        Object.entries(skillCountMap).forEach(([id, values]) => {
            if (id !== skill) {
                skillTotal += skillCountMap[id]["total"];
            }
        })

        let skillFrequency = (skillCountMap[skill][skillValue] / skillTotal * 100.0).toFixed(2);

        return skillFrequency;
    }

    function getNftRarityScore(nftData) {

    }

    function getCollectionStandard() {
        return MARMALADE_TYPE;
    }

    /**
     * 
     *********************
     ***** Callbacks ***** 
     *********************
     *
     */
    async function transferNftCallback(data) {
        if (data?.result?.success) {
            // isokoContext.on
        } else {

        }
    }

    /**
     * 
     **************************
     ***** Error Handlers ***** 
     **************************
     *
     */
    function transferNftErrorHandler() {

    }

    async function buyNftErrorHandler() {

    }

    function mintNftErrorHandler() {

    }

    return {
        mint,
        mintBulk,
        getNextPrice,
        getChain,
        getNftData,
        getTotalSupply,
        getCollectionStandard,
        getMintedTotal,
        getTransactionFee,
        getNftAmountLimit,
        getNftsForUser,
        getCollectionName,
        getNftImage,
        getNftThumbnailImage,
        getMintPhases,
        getAccountWhitelistData,
        getPhaseParticipationRequirement,
        formatNftTraits,
        formatNftTraitsInNftDataObject,
        formatNftDataForNftPopup,
        getAllNftsMetadata,
        getPolicyInfo,
        getSkillDistribution,
    };
}

export {
    useBoxingBadgerProvider
}
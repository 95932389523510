import { Box, Divider } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import ScreenSize from "../layouts/ScreenSize";
import NftListPageHero from "./NftListPageHero";
import { PactContext } from "../pact/PactContextProvider";
import Activity from "../Activity/Activity";
import { useCollectionProvider } from "../CollectionProviders/CollectionProvider";
import { pollForTransactionProgress } from "../pact/PactUtils";
import { useMarketplaceProviderFactory } from "../MarketplaceProviders/MarketplaceProviderFactory";
import { Back } from "../CommonComponents/BackButton";
import { BUTTER_TYPE, KADCARS_PROJECT_NAME_KEY, STACKED_TYPE } from "../utils/Constants";
import { checkIfNullOrUndefined } from "../utils/utils";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import useFirestore from "../Firestore/useFirestore";
import { buildDocumentQuery } from "../Firestore/FirestoreService";
import { buildNftPath } from "../utils/dbUtils";

const NftSellPage = () => {
    const navigate = useNavigate();
    const { account } = useContext(PactContext);
    const { projectId, nftId, subCollectionId } = useParams();
    const projectName = projectId.split("-")[0];
    const collectionName = projectId.split("-")[1];
    const collectionProvider = useCollectionProvider(projectName, collectionName);
    const collectionConfig = collectionProvider.getConfig();
    const marketplaceProvider = useMarketplaceProviderFactory(collectionProvider.getConfig());
    const [nftData, setNftData] = useState(null);
    const [collectionData, setCollectionData] = useState(null);
    const {
        onOpenSuccessListModal,
        onOpenSuccessDelistModal,
        onOpenSuccessTransferModal,
        onOpenFailedListModal,
        onOpenFailedDelistModal,
        onOpenFailedTransferModal,
        setActiveModalUrl,
        setBackModalUrl
    } = useContext(IsokoDialogContext);
    const nftDocListener = useFirestore(
        buildDocumentQuery(buildNftPath(collectionConfig, nftId, subCollectionId)),
        async function (data) {
            if (!checkIfNullOrUndefined(data) && !checkIfNullOrUndefined(nftData)) {
                formatNftMetadata();
            }
        },
        "doc"
    );

    useEffect(() => {
        if (collectionConfig["standard"] === BUTTER_TYPE || checkIfNullOrUndefined(nftData)) {
            formatNftMetadata();
        }
    }, [nftId]);

    async function formatNftMetadata() {
        let nftMetadata = null;
        let collData = null;

        nftMetadata = await collectionProvider.getNftListingData(nftId, subCollectionId);
        collData = await collectionProvider.getMarketplaceCollectionDetails(nftId, subCollectionId);

        if (checkIfNullOrUndefined(nftMetadata)) {
            if (collectionProvider.getNftManifest instanceof Function) {
                let tokenData = await collectionProvider.getTokenData(nftId);
                nftMetadata = await collectionProvider.getNftManifest(nftId);
                nftMetadata = {
                    ...nftMetadata,
                    ...tokenData
                }
            }
        }

        nftMetadata = {
            ...nftMetadata,
            ...collData
        }

        console.log(nftMetadata)

        setNftData(nftMetadata);
        setCollectionData(collData);
    }

    async function handleActionButtonClicked(action, nftDataForAction = null) {
        let fullNftMetadata = {
            ...nftData,
            ...nftDataForAction,
        };

        console.log(fullNftMetadata)
        switch (action) {
            case "list":
                await marketplaceProvider.list(
                    fullNftMetadata,
                    fullNftMetadata["current-owner"],
                    onListTransactionComplete
                );
                break;
            case "transfer":
                await marketplaceProvider.transfer(
                    fullNftMetadata,
                    fullNftMetadata["receiver"],
                    onTransferTransactionComplete
                );
                break;
            case "withdraw":
                await marketplaceProvider.withdraw(
                    fullNftMetadata,
                    account.account,
                    onWithdrawTransactionComplete
                );
                break;
            case "view":
                if (fullNftMetadata["standard"] === STACKED_TYPE) {
                    let subCollection = collectionProvider.getSubCollection(fullNftMetadata["nft-id-on-chain"]);
                    navigate(`/marketplace/${projectId}/group/${subCollection}/${nftId}`);
                } else {
                    navigate(`/marketplace/${projectId}/${nftId}`);
                }
                break;
            default:
                break;
        }
    }

    async function onListTransactionComplete(data) {
        console.log(data);
        let standard = collectionProvider.getCollectionStandard();

        if (standard === STACKED_TYPE) {
            setActiveModalUrl(`/listNft/${projectId}/${subCollectionId}/${nftId}`);
        } else {
            setActiveModalUrl(`/listNft/${projectId}/${nftId}`);
        }

        if (data?.result?.status === "success") {
            if (collectionProvider.getCollectionStandard() === BUTTER_TYPE) {
                await updateButterNftData();
            }
            onOpenSuccessListModal();
        } else {
            onOpenFailedListModal();
        }
    }

    async function onTransferTransactionComplete(data) {
        console.log(data);
        let standard = collectionProvider.getCollectionStandard();

        if (standard === STACKED_TYPE) {
            setActiveModalUrl(`/listNft/${projectId}/${subCollectionId}/${nftId}`);
        } else {
            setActiveModalUrl(`/listNft/${projectId}/${nftId}`);
        }

        if (data?.result?.status === "success") {
            if (standard === BUTTER_TYPE) {
                updateButterNftData();
            } else if (collectionName === KADCARS_PROJECT_NAME_KEY) {
                await formatNftMetadata();
            }
            onOpenSuccessTransferModal();
        } else {
            onOpenFailedTransferModal();
        }
    }

    async function onWithdrawTransactionComplete(data) {
        console.log(data);
        console.log(nftData)
        if (nftData["standard"] === BUTTER_TYPE) {
            setActiveModalUrl(`/listNft/${projectId}/${nftId}`);
            if (data?.result?.status === "success") {
                onOpenSuccessDelistModal();
                await updateButterNftData();
            } else {
                onOpenFailedDelistModal();
            }
        } else {
            if (data?.requestKeys) {
                if (nftData["standard"] === STACKED_TYPE) {
                    setActiveModalUrl(`/listNft/${projectId}/${subCollectionId}/${nftId}`);
                } else {
                    setActiveModalUrl(`/listNft/${projectId}/${nftId}`);
                }
                let response = await pollForTransactionProgress(data?.requestKeys[0], nftData["chain-id"]);

                if (response === "success") {
                    onOpenSuccessDelistModal();
                } else {
                    onOpenFailedDelistModal();
                }
            } else {
                onOpenFailedDelistModal();
            }
        }
    }

    async function updateButterNftData() {
        let nftMetadata = await collectionProvider.getNftListingData(nftId, subCollectionId);
        let collData = await collectionProvider.getMarketplaceCollectionDetails();
        nftMetadata = {
            ...nftMetadata,
            ...collData
        }
        setNftData(nftMetadata);
    }

    function navigateBackToCollectionMarketplace() {
        navigate(`/marketplace/${projectId}/`);
    }

    return (
        <Box bgColor="#0A0A0A" className="latestDesign">
            <ScreenSize>
                <Back callback={() => navigate(-1)} />

                <NftListPageHero
                    nftData={nftData}
                    collectionData={collectionData}
                    actionButtonCallback={handleActionButtonClicked}
                    backButtonCallback={navigateBackToCollectionMarketplace}
                />

                <Divider bgColor="#5E1E78" my="44px" />

                {/* {nftData && collectionData?.standard === MARMALADE_TYPE && (
                    <Activity
                        queryField={"nftId"}
                        queryValue={nftData["nft-id"]}
                    />
                )} */}
            </ScreenSize>
        </Box>
    );
};

export default NftSellPage;

import { Box, Image, Text } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import { firestoreQueryCollection } from "../Firestore/FirestoreService";
import { useCollectionProvider } from "../CollectionProviders/CollectionProvider";
import { useCollectionProviderFactory } from "../CollectionProviders/CollectionProviderFactory";
import { checkIfNullOrUndefined } from "../utils/utils";

const LaunchPadCard = memo(
    ({ projectConfig, cardCallback, type, clickable, ...props }) => {
        const collectionProvider = useCollectionProvider(
            projectConfig["policy-info"]["project-name"],
            projectConfig["policy-info"]["collection-name"]
        );

        const [collectionFloor, setCollectionFloor] = useState(null);
        const [price, setPrice] = useState(null);

        useEffect(() => {
            async function getCollectionFloor() {
                let floor = await collectionProvider.getCollectionFloor(
                    projectConfig["policy-info"]
                );
                if (!checkIfNullOrUndefined(floor)) {
                    setCollectionFloor(floor);
                }
            }
            projectConfig && type === "marketplace" && getCollectionFloor();
        }, [projectConfig, setCollectionFloor, collectionProvider]);

        useEffect(() => {
            async function getNextPrice() {
                if (collectionProvider.getMintProvider instanceof Function) {
                    let mintProvider = collectionProvider.getMintProvider();
                    if (!checkIfNullOrUndefined(mintProvider)) {
                        let localPrice = await mintProvider.getNextPrice();
                        setPrice(localPrice);
                    }
                }
            }
            collectionProvider && getNextPrice();
        }, [collectionProvider]);

        return (
            <>
                {projectConfig && (
                    <Box
                        className="kadCard latestDesign"
                        style={clickable ? { cursor: "pointer" } : null}
                        onClick={() => {
                            if (clickable) {
                                clickable &&
                                    cardCallback &&
                                    cardCallback(projectConfig);
                            }
                        }}
                        {...props}
                    >
                        <Box className="" px="0.25rem" pt="0.25rem">
                            <Image
                                src={
                                    projectConfig["collection_thumbnail_image"]
                                }
                                alt="nftCard"
                                width={"100%"}
                                height="18.5rem"
                                objectFit={"cover"}
                                className="cardImage"
                                borderTopRightRadius={"0.75rem"}
                                borderTopLeftRadius={"0.75rem"}
                            />
                        </Box>
                        <Box
                            style={{ padding: "16px 16px 16px 21px" }}
                            // bgColor="#160d19"
                        >
                            <Box>
                                <Box className="flex sBtw alignCenter">
                                    {" "}
                                    <Text
                                        className="kadcarsTitle"
                                        fontSize="1rem"
                                        fontWeight="700"
                                        noOfLines={1}
                                    >
                                        {" "}
                                        {projectConfig["collection-name"]}
                                    </Text>
                                    {/* Card type badge variants */}
                                    {/* live badge */}
                                    {type === "marketplace" && (
                                        <Box
                                            className=""
                                            fontSize="12px"
                                            fontWeight={"700"}
                                            px="8px"
                                            py="2px"
                                            border="1px"
                                            borderColor="#E9BEFA"
                                            bgColor="#BC3CF0"
                                            borderRadius={"32px"}
                                            flexShrink={0}
                                        >
                                            {" "}
                                            TRADE NOW{" "}
                                        </Box>
                                    )}
                                    {type === "live" && (
                                        <Box
                                            className=""
                                            fontSize="12px"
                                            fontWeight={"700"}
                                            px="8px"
                                            py="2px"
                                            border="1px"
                                            borderColor="#E9BEFA"
                                            bgColor="green"
                                            borderRadius={"32px"}
                                            flexShrink={0}
                                        >
                                            {" "}
                                            LIVE{" "}
                                        </Box>
                                    )}
                                    {type === "startingSoon" && (
                                        <Box
                                            className="startingSoonBadge"
                                            flexShrink={0}
                                        >
                                            {" "}
                                            COMING SOON{" "}
                                        </Box>
                                    )}
                                    {type === "ended" && (
                                        <Box
                                            className="endedBadge"
                                            flexShrink={0}
                                        >
                                            {" "}
                                            ENDED{" "}
                                        </Box>
                                    )}
                                    {/* Card type badge variants */}
                                </Box>
                                <Box mt="0.5rem" className="totalItems">
                                    <Box className="cardDetails">
                                        <p
                                            style={{
                                                marginBottom: "0px",
                                                fontSize: "12px",
                                            }}
                                        >
                                            TOTAL ITEMS
                                        </p>
                                        <p
                                            className=""
                                            style={{
                                                marginBottom: "0px",
                                                fontSize: "12px",
                                                textAlign: "right",
                                            }}
                                        >
                                            {" "}
                                            {type === "marketplace" && "FLOOR"}
                                            {type === "live" && "PRICE"}
                                        </p>
                                    </Box>
                                    <Box className="cardDetails cardValue">
                                        <Text>
                                            {projectConfig["total_supply"]}
                                        </Text>
                                        <Text className="" textAlign={"right"}>
                                            {type === "marketplace" &&
                                                collectionFloor
                                                ? `${collectionFloor.toFixed(
                                                    1
                                                )} KDA`
                                                : type === "marketplace" &&
                                                "N/A"}
                                            {
                                                type === "live" &&
                                                `${price ? price : "N/A"
                                                } KDA` //TODO: fix this function
                                            }
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
            </>
        );
    }
);

export default LaunchPadCard;

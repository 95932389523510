import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import React from "react";

const events = [
    {
        event: "Concert",
        price: "$50",
        from: "New York",
        to: "Los Angeles",
        date: "2023-05-01",
    },
    {
        event: "Sporting Event",
        price: "$75",
        from: "Chicago",
        to: "Boston",
        date: "2023-06-15",
    },
    {
        event: "Conference",
        price: "$100",
        from: "San Francisco",
        to: "Seattle",
        date: "2023-07-30",
    },
];
const ActivityTable = ({
    tableData = events,
    color = "#5E1E78",
    stripe = "#160D1A",
    headerColor = "#BC3CF0",
    evenColor = "",
}) => {
    return (
        <Box>
            {/* <Text fontSize="36px" fontWeight="700">
                Activity
            </Text> */}
            <Box
                mt="20px"
                border="1px"
                borderColor={color}
                rounded={"10px"}
                overflow={"hidden"}
            >
                <Table overflowX={"auto"}>
                    <Thead px="16px" bgColor={headerColor}>
                        <Tr>
                            <Th
                                pl="16px"
                                py="14px"
                                fontSize="14px"
                                fontWeight="900"
                                color="white"
                                borderBottomColor={color}
                            >
                                Event
                            </Th>
                            <Th
                                pl="16px"
                                py="14px"
                                fontSize="14px"
                                fontWeight="900"
                                color="white"
                                borderBottomColor={color}
                            >
                                Price
                            </Th>
                            <Th
                                pl="16px"
                                py="14px"
                                fontSize="14px"
                                fontWeight="900"
                                color="white"
                                borderBottomColor={color}
                            >
                                From
                            </Th>
                            <Th
                                pl="16px"
                                py="14px"
                                fontSize="14px"
                                fontWeight="900"
                                color="white"
                                borderBottomColor={color}
                            >
                                To
                            </Th>
                            <Th
                                pl="16px"
                                py="14px"
                                fontSize="14px"
                                fontWeight="900"
                                color="white"
                                borderBottomColor={color}
                            >
                                Date
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tableData.map((event, index) => (
                            <Tr
                                key={index}
                                _odd={{ bgColor: stripe }}
                                _even={{ bgColor: evenColor }}
                            >
                                <Td
                                    px="16px"
                                    py="14px"
                                    border={"none"}
                                    fontSize={"14px"}
                                    color=""
                                    fontWeight={700}
                                >
                                    {event.event}
                                </Td>
                                <Td
                                    px="16px"
                                    py="14px"
                                    border={"none"}
                                    fontSize={"14px"}
                                >
                                    {event.price}
                                </Td>
                                <Td
                                    px="16px"
                                    py="14px"
                                    border={"none"}
                                    fontSize={"14px"}
                                >
                                    {event.from}
                                </Td>
                                <Td
                                    px="16px"
                                    py="14px"
                                    border={"none"}
                                    fontSize={"14px"}
                                >
                                    {event.to}
                                </Td>
                                <Td
                                    px="16px"
                                    py="14px"
                                    border={"none"}
                                    fontSize={"14px"}
                                >
                                    {event.date}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default ActivityTable;

import { Box, Flex, Link, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";

import ApplyForLaunchPad from "./ApplyForLaunchPad";
import { ComingSoonComponent } from "./ComingSoonComponent";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import ScreenSize from "../layouts/ScreenSize";

function Footer() {
    const { onOpenFeedbackModal, onOpenReportBug } =
        useContext(IsokoDialogContext);

    const FooterData = {
        company: [
            {
                text: "Report a bug",
                href: "",
                // clickFunction: onOpenFeedbackModal,
                clickFunction: () => { },
            },
            // { text: "Give feedback", href: "", clickFunction: onOpenFeedbackModal },
        ],
        terms: [
            { text: "Launchpad", href: "/launchpad" },
            { text: "Marketplace Collections", href: "/#collections" },
            // { text: "Apply for Launchpad", href: "", btnFuc: () => {} },
        ],
        contact: [
            {
                text: "Terms of service",
                href: "",
            },
            {
                text: "Copyright Policy",
                href: "",
            },
            {
                text: "Privacy Policy",
                href: "",
            },
        ],
    };

    return (
        <ScreenSize>
            <div className="footer latestDesign">
                <div className=""></div>
                <div className="row_container">
                    <Box>
                        <SimpleGrid columns={[1, 2, 2, 3]} spacing={"2.81rem"}>
                            <Box color={"white_1"}>
                                <Text
                                    fontWeight={900}
                                    fontSize={"1rem"}
                                    color={"#BC3CF0"}
                                >
                                    Help us improve
                                </Text>
                                <Stack
                                    spacing={"0.55rem"}
                                    mt="1.11rem"
                                    fontSize={"0.9375rem"}
                                >
                                    {FooterData?.company.map(
                                        ({ text, href, clickFunction }, i) => {
                                            return (
                                                <Box key={text}>
                                                    {clickFunction ? (
                                                        <ComingSoonComponent
                                                            key={i}
                                                            direction={"left"}
                                                        >
                                                            <Box
                                                                fontSize={
                                                                    "0.875rem"
                                                                }
                                                                as="div"
                                                                cursor={
                                                                    "pointer"
                                                                }
                                                                onClick={
                                                                    clickFunction
                                                                }
                                                                fontWeight={
                                                                    "700"
                                                                }
                                                                _hover={{
                                                                    textDecor:
                                                                        "underline",
                                                                    color: "#C2C2C2",
                                                                }}
                                                            >
                                                                {" "}
                                                                {text}{" "}
                                                            </Box>
                                                        </ComingSoonComponent>
                                                    ) : (
                                                        <ComingSoonComponent
                                                            key={i}
                                                            direction={"left"}
                                                        >
                                                            <Link
                                                                // href={href}
                                                                fontSize={
                                                                    "0.875rem"
                                                                }
                                                                fontWeight={
                                                                    "700"
                                                                }
                                                            >
                                                                {" "}
                                                                {text}{" "}
                                                            </Link>
                                                        </ComingSoonComponent>
                                                    )}
                                                </Box>
                                            );
                                        }
                                    )}
                                </Stack>
                            </Box>

                            <Box color={"white_1"}>
                                <Text
                                    fontWeight={900}
                                    fontSize={"1rem"}
                                    color={"#BC3CF0"}
                                >
                                    Marketplace
                                </Text>
                                <Stack
                                    spacing={"0.55rem"}
                                    mt="1.11rem"
                                    fontSize={"0.9375rem"}
                                >
                                    {FooterData?.terms.map(
                                        ({ text, href, btnFuc }, i) => {
                                            return (
                                                <Box key={text}>
                                                    <Link
                                                        key={i}
                                                        href={href}
                                                        fontSize={"0.875rem"}
                                                        fontWeight={"700"}
                                                    >
                                                        {" "}
                                                        {text}{" "}
                                                    </Link>
                                                </Box>
                                            );
                                        }
                                    )}
                                    <ApplyForLaunchPad />
                                </Stack>
                            </Box>

                            <Box color={"white_1"}>
                                <Text
                                    fontWeight={900}
                                    fontSize={"1rem"}
                                    color={"#BC3CF0"}
                                >
                                    Resources
                                </Text>
                                <Stack
                                    spacing={"0.55rem"}
                                    mt="1.11rem"
                                    fontSize={"0.9375rem"}
                                >
                                    {FooterData?.contact.map(
                                        ({ text, href }, i) => {
                                            return (
                                                <ComingSoonComponent
                                                    key={i}
                                                    direction={"left"}
                                                >
                                                    <Link
                                                        // href={href}
                                                        fontSize={"0.875rem"}
                                                        fontWeight={"700"}
                                                    >
                                                        {" "}
                                                        {text}{" "}
                                                    </Link>
                                                </ComingSoonComponent>
                                            );
                                        }
                                    )}
                                </Stack>
                                <Text
                                    style={{
                                        justifySelf: "flex-end",
                                        marginTop: "10px",
                                    }}
                                >
                                    v 3.00
                                </Text>
                            </Box>
                        </SimpleGrid>
                    </Box>
                </div>
            </div>
        </ScreenSize>
    );
}

export default Footer;

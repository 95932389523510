import { Box, Text, Flex, Circle, Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import LaunchPadCard from "./LaunchPadCard";
import UserNFTCard from "./UserNFTCard";
import ScreenSize from "../layouts/ScreenSize";
// import LaunchPadCard from "./LaunchPadCard";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

const UserNFTs = ({
    header,
    collectionName,
    userNftList,
    onCardClickCallback,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (
        <ScreenSize w="100%" pos="relative">
            <Box pos="relative">
                {header && (
                    <Text fontSize="36px" fontWeight="700">
                        Your NFTs
                    </Text>
                )}

                <Box mt="30px" className="upcomingCards">
                    {userNftList && userNftList?.length > 0 ? (
                        userNftList.map((nft, i) => {
                            return (
                                <UserNFTCard
                                    key={i}
                                    cardCallback={onCardClickCallback}
                                    collectionName={collectionName}
                                    nftImage={nft["nft-uri"]}
                                    nftData={nft}
                                />
                            );
                        })
                    ) : (
                        <UserNFTCard collectionName={collectionName} />
                    )}

                    <Box mt="30px" className="upcomingCards">
                        {/* <LaunchPadCard /> */}
                    </Box>
                </Box>
            </Box>
        </ScreenSize>
    );
};

export default UserNFTs;

const paymentProgressData = [
    {
        id: 1,
        status: "done",
        failed: false,
        label: "Transactions submitted",
    },
];

import {
    Box,
    Button,
    CloseButton,
    Flex,
    Icon,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Textarea,
} from "@chakra-ui/react";

import React, { useContext, useState } from "react";
import { IsokoDialogContext} from "../IsokoContextProvider/IsokoDialogContextProvider";
import { GrFormClose } from "react-icons/gr";

const FeedbackModal = () => {
    const { isOpenFeedbackModal, onOpenFeedbackModal, onCloseFeedbackModal } =
        useContext(IsokoDialogContext);
    const [rateValue, setRateValue] = useState(null);
    const [currentStage, setCurrentStage] = useState(1);
    const handleNextStage = (stage) => {
        setCurrentStage(stage);
    };
    return (
        <>
            <Modal
                blockScrollOnMount={false}
                isOpen={isOpenFeedbackModal}
                trapFocus={false}
                size="lg"
                onClose={onCloseFeedbackModal}
                isCentered
            >
                <CloseButton onClick={onCloseFeedbackModal} />
                <ModalOverlay />
                <ModalContent
                    bgColor="transparent"
                    className="latestDesign"
                    w="520px"
                >
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="1.75rem"
                        px="20px"
                    >
                        {" "}
                        <Flex justify={"flex-end"}>
                            <Icon
                                as={GrFormClose}
                                onClick={onCloseFeedbackModal}
                                color={"white"}
                                w="1.25rem"
                                h="1.25rem"
                                cursor={"pointer"}
                            />
                        </Flex>
                        {currentStage === 1 ? (
                            <>
                                <Text
                                    mt="1rem"
                                    fontSize={"1.25rem"}
                                    fontWeight={"900"}
                                    textAlign={"center"}
                                >
                                    How likely are you to recommend Isoko to a
                                    friend?
                                </Text>
                                {/* Rate Boxes */}
                                <Box mt="1rem">
                                    <Flex gap="1.25rem">
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                            (item, i) => {
                                                return (
                                                    <Flex
                                                        w="2.125rem"
                                                        h="2rem"
                                                        _hover={{
                                                            bgColor:
                                                                "rgba(189, 61, 240, 0.30)",
                                                        }}
                                                        border={"1px"}
                                                        borderColor={"#BC3CF0"}
                                                        rounded="8px"
                                                        bgColor={
                                                            rateValue === item
                                                                ? "#BC3CF0"
                                                                : "transparent"
                                                        }
                                                        justify={"center"}
                                                        align={"center"}
                                                        cursor={"pointer"}
                                                        color="white"
                                                        py="0.62px"
                                                        px="1.56px"
                                                        onClick={() =>
                                                            setRateValue(item)
                                                        }
                                                    >
                                                        {item}
                                                    </Flex>
                                                );
                                            }
                                        )}
                                    </Flex>
                                </Box>
                                <Box mt="1rem">
                                    <Flex
                                        align={"center"}
                                        justify={"space-between"}
                                    >
                                        <Box w="100%">
                                            <Text
                                                fontWeight={"900"}
                                                fontSize={"1rem"}
                                            >
                                                Not likely
                                            </Text>
                                        </Box>
                                        <Box w="100%" textAlign={"right"}>
                                            <Text
                                                fontWeight={"900"}
                                                fontSize={"1rem"}
                                            >
                                                Very likely
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                                {/* Submit button */}
                                <Flex marginTop={"1rem"} justify={"flex-end"}>
                                    <Button
                                        outline={"none"}
                                        border="2px"
                                        borderColor="#BD3DF0"
                                        w="fit-content"
                                        px="1rem"
                                        py="0.375rem"
                                        bgColor="#BC3CF0"
                                        _hover={{
                                            bgColor: "rgba(189, 61, 240, 0.33)",
                                        }}
                                        rounded={"full"}
                                        onClick={() => {
                                            handleNextStage(2);
                                        }}

                                    >
                                        Submit
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <Comments
                                handleNextStage={handleNextStage}
                                onCloseFeedbackModal={onCloseFeedbackModal}
                                setRateValue={setRateValue}
                            />
                        )}
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FeedbackModal;

const Comments = ({ handleNextStage, onCloseFeedbackModal, setRateValue }) => {
    const [value, setValue] = React.useState("");

    const handleInputChange = (e) => {
        let inputValue = e.target.value;
        setValue(inputValue);
    };
    return (
        <>
            <Text
                mt="1rem"
                fontSize={"1.25rem"}
                fontWeight={"900"}
                textAlign={"center"}
            >
                How would you improve ISOKO?
            </Text>
            {/* Rate Boxes */}
            <Box mt="1rem">
                <Textarea
                    placeholder="Tell us what we can do to make Isoko better for you "
                    size="sm"
                    resize={"none"}
                    value={value}
                    rounded={"8px"}
                    border={"1px"}
                    borderColor={"#BC3CF0"}
                    h="120px"
                    _focus={{ borderColor: "#BC3CF0", boxShadow: "#BC3CF0" }}
                    _active={{}}
                    _focusVisible={{
                        borderColor: "#BC3CF0",
                        boxShadow: "#BC3CF0",
                    }}
                    onChange={handleInputChange}
                />
            </Box>
            {/* Submit button */}
            <Flex marginTop={"1rem"} justify={"flex-end"} gap="20px">
                <Button
                    outline={"none"}
                    border="none"
                    borderColor="#BD3DF0"
                    w="fit-content"
                    px="1rem"
                    py="0.375rem"
                    fontSize={"0.875rem"}
                    fontWeight={"700"}
                    bgColor="transparent"
                    _hover={{
                        bgColor: "rgba(189, 61, 240, 0.33)",
                    }}
                    rounded={"full"}
                    _placeholder={{ color: "white" }}
                    onClick={() => {
                        handleNextStage(1);
                        onCloseFeedbackModal();
                        setRateValue(null);
                    }}

                >
                    Skip
                </Button>

                <Button
                    outline={"none"}
                    border="1px"
                    borderColor="#BD3DF0"
                    w="fit-content"
                    px="1rem"
                    py="0.375rem"
                    fontSize={"0.875rem"}
                    fontWeight={"700"}
                    bgColor="#BC3CF0"
                    _hover={{
                        bgColor: "rgba(189, 61, 240, 0.33)",
                    }}
                    rounded={"full"}
                    onClick={() => {
                        handleNextStage(1);
                        onCloseFeedbackModal();
                        setRateValue(null);
                    }}
                >
                    Submit
                </Button>
            </Flex>
        </>
    );
};

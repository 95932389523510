import { toast } from "react-toastify";
import { connectKadena, getChain } from "../kadenaInteraction/KadenaApi";
import {
    DEFAULT_CHAIN_ID,
    LOCAL_CHAIN_ID,
    TESTNET_NETWORK_ID,
    IPFS_URL_PREFIX,
    LOCAL_ACCOUNT_KEY,
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_PRICE,
    ROYALTY_INFO_KEY,
    FEE_PERCENTAGE,
    MARMALADE_TYPE,
    STACKED_TYPE,
} from "./Constants";
import { create } from "ipfs-http-client";
import { useContext, useEffect, useRef, useState } from "react";
import localforage from "localforage";
import { PactContext } from "../pact/PactContextProvider";
import { getBalanceForChain } from "../CrossChainOperations/CrossChainBalanceUtils";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import { getBlockHeightForChain } from "../pact/PactUtils";

//Check if given variable is null or undefined
function checkIfNullOrUndefined(variable) {
    if (
        variable === undefined ||
        variable === null ||
        variable === "undefined" ||
        variable === "null" ||
        variable === ""
    ) {
        return true;
    }
    return false;
}

//Load variable from local storage with given key
function tryLoadLocal(key) {
    let val = localStorage.getItem(key);
    if (val == null) {
        return null;
    }
    try {
        return JSON.parse(val);
    } catch (e) {
        return null;
    }
}

function tryRemoveLocal(key) {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        console.log(e);
    }
}

async function tryLoadLocalForage(key) {
    let val = await localforage.getItem(key);
    if (val == null) {
        return null;
    }
    try {
        return val;
    } catch (e) {
        console.log(e);
        return null;
    }
}

//Save variable in local storage with given key
function trySaveLocal(key, val) {
    try {
        localStorage.setItem(key, JSON.stringify(val));
    } catch (e) {
        console.log(e);
        return;
    }
}

async function trySaveLocalForage(key, val) {
    try {
        await localforage.setItem(key, val);
    } catch (e) {
        console.log(e);
        return;
    }
}

//Generic method to create http requests
function makeRequest(method, headers, cmd) {
    var body;
    cmd ? (body = JSON.stringify(cmd)) : (body = "");

    return {
        headers: headers,
        method: method,
        body: body,
    };
}

function mkReq(cmd) {
    return {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(cmd),
    };
}

//Generic async function to parse an http response
async function parseResponse(raw) {
    const rawRes = await raw;
    const res = await rawRes;
    if (res.ok) {
        const resJSON = await rawRes.json();
        return resJSON;
    } else {
        const resTEXT = await rawRes.text();
        return resTEXT;
    }
}

//Returns the genesis date and time
function creationTime() {
    return Math.round(new Date().getTime() / 1000) - 10;
}

//Custom wait function for any timed actions
const wait = async (timeout) => {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
};

function checkIfItemExistsInDropdownList(item, list) {
    const filtered = list.filter((option) => item === option.value);
    return filtered.length > 0;
}

async function confirmTransactionWithNetwork(
    networkUrl,
    method,
    headers,
    signedCmd,
    callback = null
) {
    let localRes = null;

    try {
        localRes = await fetch(
            `${networkUrl}/api/v1/local`,
            makeRequest(method, headers, signedCmd)
        );
    } catch (e) {
        console.log(e);
        toast.error(
            "Confirming transaction with network failed, check your network URL"
        );
        callback && callback();
        return;
    }

    return localRes;
}

async function checkXwalletNetworkAndChainSettings(
    callback = null,
    command = null
) {
    let chainRes = await getChain();
    var res = null;

    // if (networkRes.networkId !== TESTNET_NETWORK_ID) {
    //     toast.error("Please set your eckoWALLET to Testnet");
    //     return res;
    // }

    if (chainRes !== LOCAL_CHAIN_ID) {
        if (
            chainRes !== DEFAULT_CHAIN_ID &&
            parseInt(chainRes) !== DEFAULT_CHAIN_ID
        ) {
            toast.error(`Please select chain ID ${DEFAULT_CHAIN_ID}`);
            return res;
        } else {
            toast.info(
                "You've changed your wallet settings, please reconnect before proceeding",
                { position: "top-center" }
            );
            trySaveLocal(LOCAL_CHAIN_ID, chainRes);
            res = await connectKadena(TESTNET_NETWORK_ID);
            // callback && callback(command ? command : null);

            return res;
        }
    }
}

function pickTokensRandomly(listOfTokens, numberToPick) {
    var selected = [];

    for (let i = 0; i < numberToPick; i++) {
        var index = Math.floor(Math.random() * listOfTokens.length);
        var item = listOfTokens[index];

        listOfTokens.splice(index, 1);
        selected.push(item);
    }

    return selected;
}

async function getIpfsLinks(ipfsPath) {
    const ipfs = create({ url: IPFS_URL_PREFIX });

    const links = [];
    for await (const link of ipfs.ls("ipfs/" + ipfsPath)) {
        links.push(link);
    }

    return links;
}

async function getIpfsDirectoryContents(ipfsCid) {
    var dirContents = [];
    await fetch(IPFS_URL_PREFIX + "/api/v0/ls?arg=/ipfs/" + ipfsCid, {
        method: "POST",
        mode: "cors",
    })
        .then((resp) => {
            return resp.json();
        })
        .then((json) => {
            dirContents.push(json.Objects[0].Links[0].Name);
        });
    return dirContents;
}

const extractObjectFromGLB = async (manifest) => {
    var ipfsLinks = await getIpfsLinks(
        manifest.data[2]["datum"]["art-asset"]["data"].split("//")[1]
    );
    return ipfsLinks;
};

async function formatAssetIpfsUrl(assetCid, type = null) {
    var assetFileName = await getIpfsDirectoryContents(assetCid);
    var formattedUrl =
        IPFS_URL_PREFIX + "/ipfs/" + assetCid + "/" + assetFileName[0];
    return formattedUrl;
}

function useInterval(callback, delay, accountNeeded = false) {
    const { account } = useContext(PactContext);
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (accountNeeded && checkIfNullOrUndefined(account)) {
                savedCallback.current();
                return;
            } else if (accountNeeded && !checkIfNullOrUndefined(account)) {
                return;
            }
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay, account, accountNeeded]);
}

function getLocalBalanceOnChain(chainId) {
    let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
    let targetChainFunds =
        accountDetails.chainMap[parseInt(chainId)].accountData.balance;

    return targetChainFunds;
}

function checkIfFundsOnChainAreSufficient(priceToPay, chainId, costOfGas) {
    let priceWithGas = costOfGas + priceToPay;
    let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
    let currentChainBalance =
        accountDetails.chainMap[parseInt(chainId)].accountData.balance;

    return priceWithGas <= currentChainBalance;
}

async function validateGasStationAvailabilityOnChain(account, chainId) {
    let requiredAmount = DEFAULT_GAS_PRICE * DEFAULT_GAS_LIMIT;
    let chainBalance = await getBalanceForChain(account, chainId);
    console.log(chainBalance)
    if (chainBalance.balance.decimal) {
        chainBalance.balance = parseFloat(chainBalance.balance.decimal);
    }

    if (chainBalance.balance > requiredAmount * 4) {
        return true;
    }

    return false;
}

function getFloatPrecision(floatVal) {
    if (!floatVal || floatVal.toString().indexOf(".") < 0) {
        return 0;
    }
    let decimalPlaces = Math.abs(
        floatVal.toString().indexOf(".") - floatVal.toString().length + 1
    );

    return decimalPlaces;
}

const getTimestamp = () => Math.floor(new Date().getTime() / 1000) - 90;

function shortenAccountKey(accountKey) {
    let account = `${accountKey.slice(0, 7)}...
        ${accountKey.slice(
        accountKey.length - 3,
        accountKey.length
    )}`;

    return account;
}

function checkIfGivenFunctionExists(functionRef) {
    if (typeof functionRef === "function") {
        return functionRef();
    }
    return null;
}

function getAmountByPercentage(price, percentage) {
    return parseFloat((price * percentage).toFixed(8));
}

function getRandomElementsFromList(list, amount) {
    let random = list.sort(() => 0.5 - Math.random()).slice(0, amount);

    return random;
}

function reduceAccountString(value) {
    const editedValue =
        value &&
        value.slice(0, 7) +
        "..." +
        value.slice(
            value.length - 5,
            value.length
        );
    return editedValue;
}

function logLine(message, obj) {
    console.log("----------------------------------------------------------------");
    console.log("----------------------------------------------------------------");
    if (obj === null || obj === undefined || !obj) {
        console.log(message);
    } else {
        log(message, obj);
    }
    console.log("----------------------------------------------------------------");

}

function log(message, obj) {
    console.log("" + message, obj);
}

function useGetNftDetails(nftData, account) {
    const [details, setDetails] = useState([]);
    const { getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);

    useEffect(() => {
        function getDetails() {
            let tempDetails = [];
            tempDetails.push({
                itemName: "OWNER",
                value: nftData["current-owner"] === "" ? "unowned" : reduceAccountString(nftData["current-owner"])
            });

            let royaltyInfo = getParameterFromProjectConfig(
                nftData["policy-info"]["project-name"],
                nftData["policy-info"]["collection-name"],
                ROYALTY_INFO_KEY
            );

            royaltyInfo.forEach((item) => {
                tempDetails.push({
                    itemName: "CREATOR ROYALTIES",
                    value: item["percentage"]
                });
            });

            tempDetails.push({
                itemName: "MARKETPLACE FEE",
                value: FEE_PERCENTAGE
            });
            setDetails(tempDetails);
        }
        nftData && getDetails();
    }, [nftData, setDetails]);

    return details;
}

function useGetNftExpired(nftData) {
    const [nftState, setNftState] = useState(null);

    async function getNftState() {
        let state = false;
        let currentHeight = await getBlockHeightForChain(nftData["chain-id"]);

        if (nftData["standard"] !== MARMALADE_TYPE && nftData["standard"] !== STACKED_TYPE) {
            return false;
        }

        if (nftData["listing-details"]) {
            if (nftData["listing-details"]["timeout"] <= currentHeight) {
                state = true;
            } else {
                state = false;
            }
        }

        return state;
    }

    useEffect(() => {
        async function getState() {
            if (!checkIfNullOrUndefined(nftData)) {
                let state = await getNftState();
                setNftState(state);
            }
        }
        getState();
    }, [nftData, setNftState]);

    return nftState;
}

export {
    wait,
    mkReq,
    logLine,
    getTimestamp,
    useInterval,
    makeRequest,
    creationTime,
    tryLoadLocal,
    trySaveLocal,
    tryRemoveLocal,
    tryLoadLocalForage,
    trySaveLocalForage,
    getIpfsLinks,
    parseResponse,
    pickTokensRandomly,
    formatAssetIpfsUrl,
    extractObjectFromGLB,
    getLocalBalanceOnChain,
    checkIfNullOrUndefined,
    getIpfsDirectoryContents,
    confirmTransactionWithNetwork,
    checkIfItemExistsInDropdownList,
    checkXwalletNetworkAndChainSettings,
    checkIfFundsOnChainAreSufficient,
    validateGasStationAvailabilityOnChain,
    getFloatPrecision,
    shortenAccountKey,
    checkIfGivenFunctionExists,
    getAmountByPercentage,
    getRandomElementsFromList,
    reduceAccountString,
    useGetNftDetails,
    useGetNftExpired
};

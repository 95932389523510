import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import FolderCard from "../Cards/FolderCard";
// import LoadingCard from "../Cards/LoadingCard";

const Minted = () => {
    return (
        <Box pb="4.38rem" id="collections">
            <Box>
                <Text fontSize={["24px", null, "36px"]} fontWeight={"900"}>
                    MINT WEAPONS COLLECTION
                </Text>
            </Box>
            {/* Cards */}

            <Box mt="30px">
                <SimpleGrid columns={[1, 2, 3, 4]} className="upcomingCards">
                    <FolderCard />
                </SimpleGrid>
            </Box>
        </Box>
    );
};

export default Minted;
